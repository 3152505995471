import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, calendar } from '@/utils/icons';
import { required } from '@/utils/rules';
import FinancialMovementBusiness from '@/business/FinancialMovementBusiness';

export default {
  domain: 'account_parcel_payments',
  domainSingle: 'account_parcel_payment',
  business: FinancialMovementBusiness,
  title: 'Movimentações',
  description: 'Lista de as movimentações do sistema',
  routes: {
    list: {
      name: 'financial_movements',
      path: '/financeiro/movimentacoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
  },
  listActions: {
    has: false,
    noShow: true,
    noDestroy: true,
    more: {
      has: false,
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'paid_date',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Banco',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'paid_date',
          formattedName: 'paid_date_formatted',
          label: 'Data',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'type',
          formattedName: 'type',
          label: 'Tipo Mov.',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'account_parcel.account.title',
          formattedName: 'account_parcel.account.title',
          label: 'Descrição',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'payment_method.name',
          formattedName: 'payment_method.name',
          label: 'Forma Pagamento',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'flag.name',
          formattedName: 'flag.name',
          label: 'Bandeira',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'value',
          formattedName: 'value_formatted',
          label: 'Valor',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [
    {
      name: 'total_inputs',
      label: 'Vlr. Entradas',
      color: 'success',
      md: 2,
    },
    {
      name: 'total_outputs',
      label: 'Vlr. Saídas',
      color: 'primary',
      md: 2,
    },
    {
      name: 'balance',
      label: 'Saldo',
      color: 'secondary',
      md: 2,
    },
  ],
};
