import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { filter } from '../../utils/filter';

export const getAll = (server) => {
  server.get('/order_budgets', (schema, request) => {
    const orderBudgets = schema.orderBudgets.all();
    const { page, perPage } = request.queryParams;
    orderBudgets.models = filter(request.queryParams, orderBudgets);
    orderBudgets.models = softDelete(orderBudgets);

    return pagination('order_budgets', orderBudgets, page, perPage);
  });
};