<template>
  <Row>
    <Col md="12">
      <div class="pdv-content--main">
        <div class="pdv-content--main---left">
          <div class="pdv-content--main---left----image">
            <Row>
              <Col md="12" class="py-0">
                <div class="pdv-content--main---resume">
                  <div class="pdv-content--resume---left">
                    <p class="my-0 mt-3">AVISO</p>
                    <div class="pdv-content--resume---left-----info">
                      <h1 style="color: yellow">CAIXA {{ openedBox }}</h1>
                    </div>
                  </div>
                  <div class="pdv-content--resume---right">
                    <p class="my-0 mt-4">SUB TOTAL</p>
                    <div class="pdv-content--resume---left-----info">
                      <h1 v-text="subTotal" />
                    </div>
                  </div>
                  <div class="pdv-content--resume---right">
                    <p class="my-0 mt-4">DESCONTO</p>
                    <div class="pdv-content--resume---left-----info">
                      <h1 v-text="discount" />
                    </div>
                  </div>
                  <div class="pdv-content--resume---right">
                    <p class="my-0 mt-4">TOTAL FINAL</p>
                    <div class="pdv-content--resume---left-----info">
                      <h1 v-text="finalTotal" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div class="pdv-content--main---left----options ml-5">
            <form>
              <div class="pdv-content--main---left----options----item">
                <label for="">Código de Barras (F2)</label>
                <input class="pdv-content--main---left----options----item-----inputs"
                  type="text" v-model="barCode" id="barCode" ref="barCode"
                  @keyup.enter="getByBarCodeProduct"  />
              </div>
              <div class="pdv-content--main---left----options----item">
                <label for="">Quantidade</label>
                <input class="pdv-content--main---left----options----item-----inputs"
                  type="text" v-model="quantity" id="quantity"
                  @keyup.enter="focusInputs(['barCode'])" />
              </div>
              <div class="pdv-content--main---left----options----item">
                <label for="">Valor Unitário</label>
                <input class="pdv-content--main---left----options----item-----inputs"
                  type="text" v-model="unitValue" readonly />
              </div>
              <div class="pdv-content--main---left----options----item">
                <label for="">Valor Total</label>
                <input class="pdv-content--main---left----options----item-----inputs"
                  type="text" v-model="totalValue" readonly />
              </div>
            </form>
          </div>
        </div>
        <div class="pdv-content--main---right ml-5">
          <div class="pdv-content--main---right----top">
            <p>CUPOM</p>
          </div>
          <div class="pdv-content--main---right----table">
            <table>
              <thead>
                <tr>
                  <th><b>Item</b></th>
                  <th><b>Cod. Barras</b></th>
                  <th align="left"><b>Descrição</b></th>
                </tr>
                <tr>
                  <th colspan="2" align="left">
                    <span class="ml-5"><b>Qtdo</b></span>&nbsp;
                    <span><b>UN</b></span>
                  </th>
                  <th align="right">
                    &nbsp;&nbsp;&nbsp;
                    <span><b>Vlr. Uni.</b></span>&nbsp;&nbsp;
                    <span><b>Vlr. Total</b></span>&nbsp;&nbsp;
                  </th>
                </tr>
              </thead>
              <tr><td colspan="3"><div class="line my-2" /></td></tr>
              <tbody v-for="(item, index) in model.items" :key="index">
                <tr>
                  <td>{{ item.item_number }}</td>
                  <td>{{ item.bar_code }}</td>
                  <td>{{ limitCharacters(item.name, 32) }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span class="ml-5">{{ item.quantity }}</span>&nbsp;
                    <span>{{ item.unit_measure }}</span>
                  </td>
                  <td align="right">
                    &nbsp;&nbsp;&nbsp;
                    <span>{{ formatCurrency(item.unit_value, { type: 'decimal' }) }}</span>&nbsp;
                    <span>{{ formatCurrency(item.total_value, { type: 'decimal' }) }}</span>&nbsp;&nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Dialog :dialog="dialogModalSeveralProduct" :maxWidth="500">
        <PdvSeveralProduct slot="content"
            :key="keySeveralProduct"
           @update:dialogModal="dialogModalSeveralProduct = $event"
           @addItemSeveralProduct="addItemSeveralProduct" />
      </Dialog>
    </Col>
  </Row>
</template>

<script>
import { Row, Col, Dialog } from '@/components/vuetify';
import { formatCurrency, limitCharacters, getCompanySettings, randomNumber } from '@/utils';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import SelectContentInputMixin from '@/mixins/SelectContentInputMixin';
import PdvSeveralProduct from './PdvSeveralProduct';

const KEYS = {
  F2: 'barCode',
};

export default {
  name: 'PdvContentMain',
  components: { Row, Col, PdvSeveralProduct, Dialog },
  props: {
    model: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      barCode: '',
      unitValue: formatCurrency(0, { type: 'decimal' }),
      quantity: 1,
      totalValue: formatCurrency(0, { type: 'decimal' }),
      formatCurrency,
      limitCharacters,
      dialogModalSeveralProduct: false,
      keySeveralProduct: randomNumber()
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  computed: {
    subTotal () {
      return formatCurrency(this.model.total_products);
    },
    discount () {
      return formatCurrency(this.model.total_discount_value);
    },
    finalTotal () {
      return formatCurrency(this.model.total_order);
    },
    openedBox () {
      return this.model.items.length ? 'OCUPADO' : 'LIVRE';
    }
  },
  mixins: [InputFocusMixin, SelectContentInputMixin],
  methods: {
    onFocus(ref) {
      this.barCode = '';
      this.focusInputs(this.$refs[ref]);
    },
    getByBarCodeProduct () {
      if (!this.barCode) {
        this.$noty.error('Informe o código de barras');
        this.onFocus(['barCode']);

        return;
      }

      const companySetting = getCompanySettings();

      if (this.barCode == 1 && companySetting.several_product_id) {
        this.keySeveralProduct = randomNumber();
        this.dialogModalSeveralProduct = true;

        return;
      }

      this.$api.products.getByBarCodeOrCodeProductVariation(this.barCode).then((product) => {
        if (product.length === 0) {
          this.$noty.error('Produto não localizado');
          this.onFocus(['barCode']);

          return;
        }

        if (product.length > 1) {
          this.$noty.error('Código de Barras duplicado');
          this.onFocus(['barCode']);

          return;
        }

        this.$emit('addItem', product[0]);
        this.barCode = '';
      });
    },
    actionListener(event) {
      if (KEYS[event.key]) {
        event.preventDefault();
        this.onFocus(KEYS[event.key]);
      }
    },
    addItemSeveralProduct(product) {
      this.onFocus(['barCode']);
      this.$emit('addItem', product);
      this.dialogModalSeveralProduct = false;
    }
  },
  mounted () {
    this.selectContentInput(['quantity', 'barCode']);
    this.onFocus(['barCode']);
  }
};
</script>

<style lang="scss" scoped>
.pdv-content--main {
  display: flex;
  width: 100%;

  .pdv-content--main---left {
    display: flex;
    width: 50%;

    .pdv-content--main---left----image {
      width: 60%;
      height: 450px;
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .pdv-content--main---resume {
        display: flex;
        flex-direction: column;
        width: 100%;

        .pdv-content--resume---left {
          display: flex;
          flex-direction: column;
          width: 100%;

          p {
            color: #FFF;
            font-weight: 800;
            font-size: 20px;
          }

          .pdv-content--resume---left-----info {
            width: 100%;
            background-color: #069;
            border-radius: 12px;
            padding: 19px 10px;

            h1 {
              font-size: 25px;
              color: #FFF;
              text-align: center;
              font-weight: 800;
            }
          }

          @media only screen and (min-width: 1024px) and (max-width: 1279px) {
            p {
              font-size: 15px;
            }

            .pdv-content--resume---left-----info {
              padding: 11px 10px;

              h1 {
                font-size: 20px;
              }
            }
          }

          @media only screen and (min-width: 1280px) and (max-width: 1400px) {
            .pdv-content--resume---left-----info {
              padding: 11px 10px;

              h1 {
                font-size: 25px;
              }
            }
          }
        }

        .pdv-content--resume---right {
          display: flex;
          flex-direction: column;
          width: 100%;

          p {
            color: #FFF;
            font-weight: 800;
          }

          .pdv-content--resume---left-----info {
            width: 100%;
            background-color: #069;
            border-radius: 12px;
            padding: 12px;

            h1 {
              font-size: 35px;
              color: #FFF;
              text-align: center;
              font-weight: 800;
            }
          }

          @media only screen and (min-width: 1024px) and (max-width: 1279px) {
            p {
              font-size: 15px;
            }

            .pdv-content--resume---left-----info {
              padding: 5px;

              h1 {
                font-size: 24px;
              }
            }
          }

          @media only screen and (min-width: 1280px) and (max-width: 1400px) {
            .pdv-content--resume---left-----info {
              padding: 5px;

              h1 {
                font-size: 32px;
              }
            }
          }
        }
      }
    }

    .pdv-content--main---left----options {
      width: 40%;
      height: 450px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .pdv-content--main---left----options----item {
        display: flex;
        flex-direction: column;
        margin-bottom: px;

        label {
          color: #F5DEB3;
          font-size: 20px;
          font-weight: 900;
        }

        .pdv-content--main---left----options----item-----inputs {
          background: #FFF;
          padding: 19px 10px;
          border-radius: 10px;
          font-size: 25px;
          font-weight: 800;
          text-transform: uppercase;
          outline: none;
          -webkit-box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
          -moz-box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
          box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
          margin-bottom: 10px;
        }

        @media only screen and (min-width: 1024px) and (max-width: 1279px) {
          label {
            font-size: 16px;
          }

          .pdv-content--main---left----options----item-----inputs {
            font-size: 20px;
            padding: 10px 10px;
          }
        }

        @media only screen and (min-width: 1280px) and (max-width: 1400px) {
          label {
            font-size: 20px;
          }

          .pdv-content--main---left----options----item-----inputs {
            font-size: 23px;
            padding: 12px 10px;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      .pdv-content--main---left----image {
        height: 350px;
      }

      .pdv-content--main---left----options {
        height: 350px;
      }
    }

    @media only screen and (min-width: 1280px) and (max-width: 1400px) {
     .pdv-content--main---left----image {
        height: 350px;
      }

      .pdv-content--main---left----options {
        height: 350px;
      }
    }
  }

  .pdv-content--main---right {
    width: 50%;
    height: 455px;
    background-color: #FFFFE0;
    border-radius: 15px;

    .pdv-content--main---right----top {
      width: 100%;
      padding: 12px 0 1px 0;
      background-color: #069;
      color: #FFF;
      text-align: center;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      p {
        font-size: 25px;
        font-weight: 800;
      }
    }

    .pdv-content--main---right----table {
      width: 100%;

      table {
        width: 100%;

        thead {
          th {
            font-size: 14px;
            font-weight: 800;
            padding: 0 0px;
          }
        }

        tbody {
          td {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .line {
        height: 1px;
        border-top: 1px #000 dashed;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .pdv-content--main---right {
      height: 330px;
    }
  }

  @media only screen and (min-width: 1280px) and (max-width: 1400px) {
    .pdv-content--main---right {
      height: 385px;
    }
  }
}
</style>
