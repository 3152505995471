import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text,calendar } from '@/utils/icons';
import { typeOperationStock } from '@/utils/options';

export default {
  domain: 'product_variation_stock_movements',
  domainSingle: 'product_variation_stock_movement',
  title: 'Movimentações dos Produtos',
  description: 'Lista de todas as movimentações dos produtos do sistema',
  createBtn: false,
  showInactive: false,
  routes: {
    list: {
      name: 'productsMovements',
      path: '/produtos-movimentacoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
  },
  listActions: {
    has: false,
    noShow: true,
    noDestroy: true,
    showOrigin: true,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'select',
        name: 'movement_type',
        label: 'Tipo Movimeto',
        icon: text,
        md: 12,
        itemText: 'text',
        itemValue: 'value',
        items: {
          data: typeOperationStock
        }
      },
    ]
  },
  fields: [
    {
      title: 'Dados da Movimentação',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'movement_date',
          formattedName: 'movement_date_formatted',
          label: 'Data',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'product_variation.code',
          formattedName: 'product_variation.code',
          label: 'Cod. Produto',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'product_variation.name',
          formattedName: 'product_variation.name',
          label: 'Produto',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'product_variation.bar_code',
          formattedName: 'product_variation.bar_code',
          label: 'Cod. Barras',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'product_variation.reference',
          formattedName: 'product_variation.reference',
          label: 'Referência',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Origem',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'stock.name',
          formattedName: 'stock.name',
          label: 'Estoque',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'movement_type',
          formattedName: 'movement_type',
          label: 'Tipo',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'quantity',
          formattedName: 'quantity_formatted',
          label: 'Qtde',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [
    {
      name: 'quantity_output',
      label: 'Saídas',
      color: 'primary',
      md: 2,
    },
    {
      name: 'quantity_input',
      label: 'Entradas',
      color: 'primary',
      md: 2,
    },
  ]
};
