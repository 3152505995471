<template>
  <div>
    <PageHeader :schema="$schemas.companySetting" />
    <PageContent>
      <ExpansionPanel v-model="panel" title="Movimentações" :icon="$icons.box">
        <v-form v-model="validFormMovements" ref="validFormMovements" lazy-validation>
          <Row v-for="(item, index) in orders" :key="index">
            <Col md="6">
              <TextField v-model="companySetting[item.type]"
                :label="item.label" :placeholder="item.label"
                :rules="[rules.required]" :loading="loadingField" />
            </Col>
            <Col>
              <Button color="secondary" label="Salvar" rounded
                :loading="loadingSave[item.type]" @click="save(item.type)" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>
      <ExpansionPanel v-model="panel" title="Cadastros" :icon="$icons.box" class="mt-2">
        <v-form v-model="validFormRegisters" ref="validFormRegisters" lazy-validation>
          <Row v-for="(item, index) in registers" :key="index">
            <Col md="6">
              <TextField v-model="companySetting[item.type]"
                :label="item.label" :placeholder="item.label"
                :rules="[rules.required]" :loading="loadingField" />
            </Col>
            <Col>
              <Button color="secondary" label="Salvar" rounded
                :loading="loadingSave[item.type]" @click="save(item.type)" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>
      <ExpansionPanel v-model="panel" title="Produtos" :icon="$icons.box" class="mt-2">
        <v-form v-model="validFormProducts" ref="validFormProducts" lazy-validation>
          <Row>
            <Col md="6">
              <CustomSwitch v-model="isProductCodeSequential" inset
                label="Código do Produto Sequencial"
                @click="handleIsProductCodeSequential" />
            </Col>
          </Row>
          <Row v-for="(item, index) in products" :key="index">
            <Col md="6">
              <TextField v-model="companySetting[item.type]"
                :label="item.label" :placeholder="item.label"
                :rules="[rules.required]" :loading="loadingField" />
            </Col>
            <Col>
              <Button color="secondary" label="Salvar" rounded
                :loading="loadingSave[item.type]" @click="save(item.type)" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Button,
  CustomSwitch,
} from '@/components/vuetify';
import { required } from '@/utils/rules';

export default {
  name: 'CreateCompanySetting',
  components: {
    PageHeader,
    PageContent,
    ExpansionPanel,
    Row,
    Col,
    TextField,
    Button,
    CustomSwitch,
  },
  data () {
    return {
      panel: [],
      validFormMovements: false,
      validFormRegisters: false,
      validFormProducts: false,
      rules: {
        required: required
      },
      companySetting: {},
      loadingField: false,
      loadingSave: {},
      isProductCodeSequential: false,
    };
  },
  computed: {
    orders () {
      return [
        { type: 'last_order_sale_number', label: 'Ultimo Número da Venda' },
        { type: 'last_order_purchase_number', label: 'Ultimo Número da Compra' },
        { type: 'last_order_budget_number', label: 'Ultimo Número do Orçamento' },
        { type: 'last_order_sale_devolution_number', label: 'Ultimo Número da Devolução de Venda' },
        { type: 'last_order_purchase_devolution_number', label: 'Ultimo Número da Devolução de Compra' },
        { type: 'last_order_sale_pdv_number', label: 'Ultimo Número da Venda PDV' },
        { type: 'last_order_sale_consigned_number', label: 'Ultimo Número da Venda Consignada' },
        { type: 'last_order_sale_table_number', label: 'Ultimo Número da Venda de Mesa' },
      ];
    },
    registers () {
      return [
        { type: 'last_client_code', label: 'Ultimo Código do Cliente' },
        { type: 'last_provider_code', label: 'Ultimo Código do Fornecedor' },
        { type: 'last_transporter_code', label: 'Ultimo Código da Transportadora' },
        { type: 'last_seller_code', label: 'Ultimo Código do Vendedor' },
        { type: 'last_employee_code', label: 'Ultimo Código do Funcionário' },
      ];
    },
    products () {
      return [
        { type: 'last_product_code', label: 'Ultimo Código do Produto' },
      ];
    }
  },
  methods: {
    async find () {
      const { id } = this.$route.params;
      this.loadingField = true;
      await this.$api.companySettings.show(id).then(({ company_setting }) => {
        this.companySetting = company_setting;
        this.isProductCodeSequential = this.companySetting.is_product_code_sequential;
      }).catch(() => {
        this.$noty.error('Erro ao carregar as Configurações da Empresa!');
      }).finally(() => {
        this.loadingField = false;
      });
    },
    async save (type) {
      const { id } = this.$route.params;
      this.loadingSave[type] = true;
      await this.$api.companySettings.update(id, { [type]: this.companySetting[type] }).then(() => {
        this.$noty.success('Configuração da Empresa atualizada com sucesso!');
        this.find();
      }).catch(() => {
        this.$noty.error('Erro ao realizar a atualização das Configurações da Empresa!');
      }).finally(() => {
        this.loadingSave[type] = false;
      });
    },
    async handleIsProductCodeSequential() {
      this.isProductCodeSequential = !this.isProductCodeSequential;
      this.companySetting.is_product_code_sequential = this.isProductCodeSequential;
      this.save ('is_product_code_sequential');
    }
  },
  mounted () {
    this.find();
  }
};
</script>

<style>

</style>
