export default {
  modalMovements: {
    title: 'Extrato Bancário',
    actions: {
      create: 'Adicionar Movimentação',
      removeMovement: 'Movimentação excluído com sucesso!'
    },
    tooltip: {
      view: 'Visualizar Movimentação',
      destroy: 'Excluir Movimentação'
    },
    bank: {
      description: {
        label: 'Descrição'
      },
      account: {
        label: 'Conta'
      },
      agency: {
        label: 'Agência'
      },
      balance: {
        label: 'Saldo'
      }
    },
    modalCreateMovement: {
      title: {
        new: 'Nova Movimentação',
        view: 'Visualizar Movimentação'
      },
      actions: {
        save: 'Salvar',
        createMovement: 'Movimentação criada com sucesso!'
      },
      form: {
        movement_date: {
          label: 'Data'
        },
        movement_type: {
          label: 'Tipo de Movimentação'
        },
        type_transaction: {
          label: 'Tipo de Transação'
        },
        paymentMethod: {
          label: 'Forma de Pagamento',
          placeholder: 'Seleciona a Forma de Pagamento',
        },
        description: {
          label: 'Descrição da Movimentação',
          placeholder: 'Descrição da Movimentação'
        },
        value: {
          label: 'Valor',
          placeholder: 'Valor da Movimentação'
        }
      },
    }
  }
};
