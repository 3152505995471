import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('stock', {
    name: 'Principal',
    description: 'Estoque principal da empresa',
    ...baseFields,
  });
  server.create('stock', {
    name: 'Filial I',
    description: 'Estoque da Filial Zona Leste',
    ...baseFields,
  });
};
