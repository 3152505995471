<script>
export default {
  name: 'CalculateItemTaxesMixin',
  methods: {
    async calculateItemTaxes(form, clientId) {
      try {
        const payload = {
          client_id: clientId,
          product_variation_id: form.product_variation_id,
          nature_operation_id: form.nature_operation_id,
          stock_id: this.stockId,
          total_without_discount_value: form.sub_total,
          taxpayer_type: 'non_taxpayer',
          is_costumer: false,
          discount_value: form.discount_value,
          another_value: 0,
          freight_value: 0,
          quantity: parseFloat(form.quantity),
        };

        let data = form;
        const res = await this.$api.orders.calculateItemTaxes(payload);

        data.new_item = true;
        data.unit_measure = form.unit_measure.item_value;
        data.ncm = form.ncm.item_value;
        data.cest = form.cest?.item_value;
        data.tax = res;
        data.cfop = res.cfop;
        data.cfop_description = res.cfop.description;
        data.has_stock = res.has_stock;
        data.movement_stock = res.movement_stock;

        return data;
      } catch (err) {
        let message = 'Erro ao Calcular os Impostos';

        if (err.response?.status === 400) {
          message = 'Não existe Natureza de Operação para a UF do Cliente';
        }
        this.$noty.error(message);
        throw err;
      }
    },
  },
};
</script>
