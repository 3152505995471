<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
      <DynamicListPageHeader
        ref="dynamicListPageHeader"
        :schema="schema"
        :searchChips="searchChips"
        :itemsSelectsFilters="itemsSelectsFilters"
        @searchItems="fetchDataApi"
        @closeChip="closeChip" />
    <div v-if="!noResults">
      <v-data-table
        v-model="selected"
        item-key="id"
        class="elevation-1 dynamic-list-data-table"
        :dense="dense"
        :show-expand="schema.showExpand"
        :loading="loading"
        :headers="headers"
        :items="localItems[schema.domain]"
        :options.sync="options"
        :server-items-length="totalLocalItems"
        :single-select="schema.singleSelect"
        :show-select="schema.checkItem"
        :single-expand="singleExpand"
        :expanded="expanded"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ 'items-per-page-options': [5, 7, 10, 20, 50]}"
        >

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="px-1 py-1">
              <Tabs v-model="tab" @setTab="setTab">
                <Tab v-for="item in tabItems" :key="item">{{ item }}</Tab>
              </Tabs>

              <TabsItems v-model="tab">
                <Row class="my-5">
                  <Col v-for="(expandedItem, index) in expandedItems[tab]" :key="index"
                    cols="12" xs="12" sm="12" :md="expandedItem.md || 12" :lg="expandedItem.md || 12">
                    <span v-text="`${expandedItem.label}:`" class="d-block font-weight-bold subtitle-1" />
                    <span v-text="getExpandedFieldValues(item, expandedItem.formattedName)" />
                  </Col>
                </Row>
              </TabsItems>
              <slot name="content-expanded" :props="{ headers, item }" class="px-5 py-5" />
            </div>
          </td>
        </template>

        <template v-for="slot in slots" v-slot:[`item.${slot.field}`]="{ item, index }">
          <slot :name="slot.field" :props="{ item, index }" />
        </template>

        <template v-slot:[`item.use_nickname_formatted`]="{ item }">
          <Chip :label="item.use_nickname_formatted" small :color="colorsUseNickname[item.use_nickname]" />
        </template>

        <template v-slot:[`item.situation_formatted`]="{ item }">
          <Chip :label="item.situation_formatted" small :color="colorsSituation[item.situation]" />
        </template>

        <template v-if="!hasColumnStatusLocal" v-slot:[`item.status_formatted`]="{ item }">
          <Chip :label="item.status_formatted" small :color="colorsStatus[item.status]" dark />
        </template>

        <template v-slot:[`item.actions`]="props">
          <ActionsListPage
            :schema="schema"
            :icons="icons"
            :data-list-props="props"
            @openDialogDestroy="openDialogDestroy"
            @actionMoreActions="actionMoreActions" />
        </template>
      </v-data-table>
      <div>
        <slot name="custom-footer" />
      </div>

      <Row class="mt-5 custom-footer">
        <Col v-for="(item, index) in schema.footer" :key="index" :md="item.md">
          <Alert :color="item.color || 'primary'" class="py-2">
            <h5>{{ item.label }}</h5>
            <h4>{{ localItems[item.name] }}</h4>
          </Alert>
        </Col>
      </Row>
    </div>
    <div v-else class="d-flex flex-column align-center w-100">
      <Picture :src="require('@/assets/no-search.svg')" width="400" />
      <h3>Ops! Sem resultados foi encontrado. Tente novamente.</h3>
    </div>
    <DialogConfirmation :dialog="dialog" :loading="loadingDestroy"
      @noActionDialogConfirmation="clearProps" @yesActionDialogConfirmation="destroy" />

    <DialogConfirmation :dialog="confirmDialogMoreActions" :loading="loadingAction"
      :title="titleConfirmDialogMoreActions" :message="messageConfirmDialogMoreActions"
      @noActionDialogConfirmation="closeConfirmDialogMoreActions"
      @yesActionDialogConfirmation="changeEventConfirmDialogMoreActions" />

    <Dialog :dialog="dialogModalListActions" no-title :maxWidth="modalPropsListActions.maxWidth">
      <component :is="componentModalListActions" slot="content"
      v-bind="modalPropsListActions"  v-on="eventsDialogMoreActions"
      :loadingAction="loadingAction"
      @close="closeModalListActions()" />
    </Dialog>
  </div>
</template>

<script>
import axios from '@/service/';
import { search, view, destroy, dotsVertical, filter, cancel, check } from '@/utils/icons';
import { typeSituation } from '@/utils/options';
import DialogConfirmation from '@/components/DialogConfirmation';
import ActionsListPage from './components/ActionsListPage';
import { Chip, Row, Col, Alert, Tab, Tabs, TabsItems, Picture, Dialog } from '@/components/vuetify';
import DynamicListPageHeader from './components/DynamicListPageHeader';
import { get } from 'lodash';
import { formatDatePT, getText, randomNumber } from '@/utils';
import eventBus from '@/utils/eventBus';

const COLORS_STATUS = Object.freeze({
  pending: 'warning',
  suspended: 'warning',
  done: 'success',
  paid: 'success',
  active: 'success',
  authorized: 'success',
  finished: 'success',
  canceled: 'primary',
  cancelled: 'primary',
  open: 'secondary',
  confirmed: 'secondary',
  closed: 'primary',
  rejected: 'gray',
  processing: 'secondary',
  pair: 'orange',
  partial: 'orange',
  not_usage: 'success',
  partial_usage: 'orange',
  usage: 'secondary',
});

const COLORS_SITUATION = Object.freeze({
  active: 'success',
  disabled: 'light',
});

const COLORS_USE_NICKNAME = Object.freeze({
  yes: 'success',
  no: 'light',
});

export default {
  name: 'DynamicListPage',
  components: {
    DialogConfirmation,
    Chip,
    ActionsListPage,
    DynamicListPageHeader,
    Row,
    Col,
    Alert,
    Tab,
    Tabs,
    TabsItems,
    Picture,
    Dialog,
  },
  props: {
    schema: {
      type: Object,
      required: true
    },
    service: {
      type: Object,
    },
    fixedFilter: {
      type: Boolean,
      default: false,
    },
    fixedQuery: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    fixedFilterParams: {
      type: Object,
      default: () => {},
    },
    fixedQueryParams: {
      type: Object,
      default: () => {},
    },
    slots: {
      type: Array,
      default: () => []
    },
    loadingDialogMoreActions: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['modeDark'],
  data() {
    return {
      expanded: [],
      singleExpand: true,
      tab: 0,
      tabItems: [],
      dialogModalListActions: false,
      loadingAction: false,
      componentModalListActions: null,
      modalPropsListActions: {},
      icons: {
        search: search,
        view: view,
        destroy: destroy,
        dotsVertical: dotsVertical,
        filter: filter,
        cancel: cancel,
        check: check,
      },
      types: {
        typeSituation: typeSituation
      },
      selected: [],
      dialog: false,
      loading: false,
      loadingDestroy: false,
      searchChips: [],
      headers: [],
      options: {},
      localItems: {},
      totalLocalItems: 10,
      noGetDynamicItems: false,
      colorsStatus: COLORS_STATUS,
      colorsSituation: COLORS_SITUATION,
      colorsUseNickname: COLORS_USE_NICKNAME,
      expandedItems: [],
      page: 1,
      itemsPerPage: 10,
      itemsSelectsFilters: {},
      btnSynchronizerLoading: false,
      noResults: false,
      registerSelectedListActions: null,
      confirmDialogMoreActions: false,
      eventsDialogMoreActions: null,
      actionDialogMoreSelected: null,
      eventsConfirmDialogMoreActions: null,
      titleConfirmDialogMoreActions: '',
      messageConfirmDialogMoreActions: '',
    };
  },
  async mounted() {
    if (this.schema.filters.has) {
      await this.mountSelectItemsFilters();
    } else {
      this.fetchDataApi();
    }
    this.setHeaders();
    this.setExpandedFieldsItems();
  },
  computed: {
    paramsPaginator() {
      let params = { page: this.options.page, per_page: this.options.itemsPerPage, sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0] ? 'desc' : 'asc' };

      if (params.sortBy) {
        const arr = params.sortBy.split('_');

        if (arr[arr.length-1] === 'formatted') {
          let sortBy = '';
          arr.forEach((i, index) => {
            if (index < (arr.length - 1)) sortBy += `${i}_`;
          });
          params.sortBy = sortBy.substring(0, sortBy.length - 1);
        }
      }

      return params;
    },
    hasColumnStatusLocal () {
      return this.slots.find(item => item.field === 'status' ||
        item.field === 'status_formatted');
    },
  },
  watch: {
    page () {
      this.routerReplace({ ...this.$route.query, page: this.options.page, per_page: this.options.itemsPerPage });
      this.fetchDataApi();
    },
    itemsPerPage () {
      this.routerReplace({ ...this.$route.query, page: this.options.page, per_page: this.options.itemsPerPage });
      this.fetchDataApi();
    },
  },
  methods: {
    setHeaders () {
      this.schema.fields.forEach(form => {
        if (form.items) {
          form.items.forEach(item => {
            if (item.list) {
              this.headers.push({
                text: item.label,
                value: item.formattedName ? item.formattedName : item.name,
                align: item.align ? item.align : 'start',
                sortable: !item.noSortable || false,
                width: item.width,
              });
            }
          });
        }

        if (form.type === 'address') {
          form.itemsDisplayList.forEach(item => {
            this.headers.push({
              text: item.label,
              value: item.formattedName ? item.formattedName : item.name,
              align: item.align ? item.align : 'start',
              sortable: !item.noSortable || false,
              width: item.width,
            });
          });
        }
      });

      if (this.schema.listActions.status) {
        this.headers.push(
          {
            text: 'Status',
            value: 'status_formatted',
            align: 'start',
            sortable: true,
          }
        );
      }

      if (this.schema.listActions.situation) {
        this.headers.push(
          {
            text: 'Situação',
            value: 'situation_formatted',
            align: 'start',
            sortable: true,
          }
        );
      }

      if (this.schema.listActions.has) {
        this.headers.push({
          text: 'Ações',
          value: 'actions',
          sortable: false,
          align: 'end',
          cellClass: 'action-column',
          with: 100
        });
      }
    },
    mountPaginateParams () {
      this.options = {
        ...this.options,
        page: parseInt(this.$route.query.page) || this.page,
        itemsPerPage: parseInt(this.$route.query.per_page) || this.itemsPerPage,
      };

      return {
        paginate: {
          page: this.$route.query.page || this.page,
          perPage: this.$route.query.per_page || this.itemsPerPage,
        },
        ...this.paramsPaginator.sortBy && {
          sort: {
            sortBy: this.paramsPaginator.sortBy,
            sortDesc: this.paramsPaginator.sortDesc
          }
        },
        fixedFilters: {},
      };
    },
    mountFixedFiltersParamsUrl(fixedFilters) {
      return `&filter[${fixedFilters.name}]=${fixedFilters.value}`;
    },
    mountFixedQueryParamsUrl(fixedQueryParams) {
      return `&${new URLSearchParams(fixedQueryParams).toString()}`;
    },
    routerReplace (query) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query
      }).catch(() => {});
    },
    async index() {
      const params = this.mountPaginateParams();

      return new Promise((resolve, reject) => {
        this.service.index(params).then((res) => {
          const { total } = res.data;
          this.localItems = res.data;
          this.totalLocalItems = total;
          this.loading = false;
          this.noResults = this.localItems[this.schema.domain].length === 0;

          if (!this.schema.showInactive) {
            this.localItems[this.schema.domain] = this.localItems[this.schema.domain].filter((item) => {
              return item.situation === this.$enums.situation.ACTIVE;
            });
          }

          if (this.schema.business != undefined && typeof this.schema.business.beforeList === 'function') {
            this.schema.business.beforeList({
              form: this.localItems,
              schema: this.schema
            });
          }

          resolve(this.localItems);
        }).catch((err) => {
          console.error(`DynamicListPage GetDataFromApi error: ${err}`);
          this.loading = false;
          this.$noty.error('Erro ao receber os itens.');
          reject(err);
        });
      });
    },
    async getAllWithFilters () {
      this.searchChips = [];
      this.mountPaginateParams();
      let url = `filters?domain=${this.schema.domainSingle}`;

      if (this.fixedFilter) {
        url += this.mountFixedFiltersParamsUrl(this.fixedFilterParams);
      }

      if (this.fixedQuery) {
        url += this.mountFixedQueryParamsUrl(this.fixedQueryParams);
      }

      const queryParamsPaginate = ['page', 'per_page', 'sort_by', 'sort',
        'column_between', 'is_between'];

      const selectsFilters = [];
      Object.keys(this.$route.query).forEach((key) => {
        if (queryParamsPaginate.includes(key)) {
          url += `&${key}=${this.$route.query[key]}`;
        }
        this.schema.filters.items.forEach((item) => {
          if (item.name === key && this.$route.query[key]) {
            if (item.type === 'dataPicker' || item.noTypeFilter) {
              url += `&${key}=${this.$route.query[key]}`;
            } else {
              url += `&filter[${key}]=${this.$route.query[key]}`;
            }
            selectsFilters.push({ item, key });
          }
        });
      });

      return new Promise((resolve, reject) => {
        this.service.filters({ url: url.replaceAll('$$', '.') }).then((res) => {
          const { total } = res.data;
          this.localItems = res.data;
          this.totalLocalItems = total;
          this.loading = false;
          this.noResults = this.localItems[this.schema.domain].length === 0;

          if (!this.schema.showInactive) {
            this.localItems[this.schema.domain] = this.localItems[this.schema.domain].filter((item) => {
              return item.situation === this.$enums.situation.ACTIVE;
            });
          }

          if (this.schema.business != undefined && typeof this.schema.business.beforeList === 'function') {
            this.schema.business.beforeList({
              form: this.localItems,
              schema: this.schema
            });
          }

          selectsFilters.forEach((item) => {
            this.setSearchChips(item.item, item.key);
          });
          resolve(this.localItems);
        }).catch((err) => {
          console.error(`DynamicListPage GetDataFromApi error: ${err}`);
          this.loading = false;
          this.$noty.error('Erro ao receber os itens.');
          reject(err);
        });
      });
    },
    fetchDataApi () {
      this.loading = true;

      if (!this.schema.filters.has && !this.fixedFilter) {
        return this.index();
      } else {
        return this.getAllWithFilters();
      }
    },
    closeChip (item) {
      this.searchChips = this.searchChips.filter(data => data.name != item.name);
      this.$refs.dynamicListPageHeader.$children[0].localItem[item.name] = '';
      this.$refs.dynamicListPageHeader.$children[0].handleSubmit();
    },
    openDialogDestroy (item) {
      this.selected.push(item);
      this.dialog = true;
    },
    openConfirmDialogMoreActions (item) {
      this.confirmDialogMoreActions = true;
      this.actionConfirmDialogMoreActions = item;
    },
    closeConfirmDialogMoreActions () {
      this.confirmDialogMoreActions = false;
      this.loadingAction = false;
    },
    async changeEventConfirmDialogMoreActions() {
      this.loadingAction = true;
      await this.$emit(this.eventsConfirmDialogMoreActions.action, this.eventsConfirmDialogMoreActions.data);
    },
    async changeEventDialogMoreActions(data) {
      this.$emit(this.actionDialogMoreSelected, data);
    },
    destroy () {
      if (!this.noGetDynamicItems){
        const { id } = this.selected[0].item;

        this.service.delete(id).then(() => {
          this.$noty.success('Registro excluído com sucesso!');
          this.loadingDestroy = false;
          this.fetchDataApi();
        }).catch((err) => {
          this.$noty.error(err);
        });

        this.clearProps();
      } else {
        this.loadingDestroy = true;
        this.clearProps();
      }
      this.clearProps();
    },
    clearProps () {
      this.selected = [];
      this.loadingDestroy = false;
      this.dialog = false;
    },
    actionMoreActions(data) {
      if (data.moreActionItem.action === 'situation') {
        this.situation(data);

        return;
      }

      if (data.moreActionItem.isConfirmDialog) {
        this.titleConfirmDialogMoreActions = data.moreActionItem.titleDialog;
        this.messageConfirmDialogMoreActions = data.moreActionItem.messageDialog;
        this.openConfirmDialogMoreActions(data.dataListPropsItem);
        this.eventsConfirmDialogMoreActions = {
          action: data.moreActionItem.action,
          data: data.dataListPropsItem.item,
        };

        return;
      }

      if (data.moreActionItem.component && data.moreActionItem.version === 'v2') {
        this.registerSelectedListActions = data;
        this.dialogModalListActions = true;
        this.componentModalListActions = data.moreActionItem.component;
        this.modalPropsListActions = {
          item: data.dataListPropsItem.item,
          maxWidth: data.moreActionItem.maxWidth || 1000,
          key: randomNumber()
        };
        this.eventsDialogMoreActions = {
          handleActionMoreActions: this.handleActionMoreActions,
          [data.moreActionItem.action]: this.changeEventDialogMoreActions,
        };
        this.actionDialogMoreSelected = data.moreActionItem.action;
      } else {
        if (this.schema.listActions.eventBus) {
          eventBus.$emit(data.moreActionItem.action, {
            moreActionItem: data.moreActionItem,
            ...data.dataListPropsItem
          });
        } else {
          this.$emit('actionMoreActions', data);
        }
      }
    },
    handleActionMoreActions(data) {
      this.loadingAction = true;
      this.$emit(data.emitName, data);
    },
    closeModalListActions() {
      if (this.registerSelectedListActions.moreActionItem.updateListWhenClosing) {
        this.fetchDataApi();
      }
      this.dialogModalListActions = false;
      this.loadingAction = false;
    },
    eventsModalListActions({ eventName, data }) {
      this.$emit(eventName, data);
    },
    situation (data) {
      this.loading = true;
      let situation = this.types.typeSituation[0].value;
      data.dataListPropsItem.item.situation === this.types.typeSituation[0].value ? situation = this.types.typeSituation[1].value : situation = this.types.typeSituation[0].value;

      this.service.update(data.dataListPropsItem.item.id, { situation: situation }).then(() => {
        this.fetchDataApi();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$noty.error('Erro ao atualizar a situação.');
      });
    },
    setTab (tab) {
      this.tab = tab;
    },
    setExpandedFieldsItems () {
      this.schema.fields.forEach((form, index) => {
        this.tabItems.push(form.title);
        this.expandedItems[index] = [];

        if (form.items) {
          form.items.forEach(item => {
            if (item.contentExpanded) {
              this.expandedItems[index].push(item);
            }
          });
        }
      });
    },
    getExpandedFieldValues (item, field) {
      return get(item, field);
    },
    async mountSelectItemsFilters () {
      this.schema.filters.items.forEach((item) => {
        if (item.type === 'select' && item.endpoint) {
          axios[item.verb](item.endpoint)
            .then((res) => {
              const items = res.data[item.domain].map((i) => {
                return {
                  id: i.id,
                  text: i[item.itemText],
                  value: i[item.itemValue],
                };
              });
              this.$set(this.itemsSelectsFilters, item.name, items);
            })
            .catch(() => {
            });
        } else if (item.type === 'select' && item.items) {
          this.$set(this.itemsSelectsFilters, item.name, item.items.data);
        }
      });
    },
    setSearchChips(item, key) {
      let formattedValue = null;

      if (item.type === 'select') {
        formattedValue = this.setFormattedValueSelectsFilters(item, key);
      }

      if (item.type !== 'select' || formattedValue) {
        const allReadyExists = this.searchChips.find((chip) => chip.name === key);

        if (!allReadyExists) {
          this.searchChips.push({
            name: item.name,
            label: item.label,
            ...item.type === 'dataPicker' && { formattedValue: formatDatePT(this.$route.query[key]) },
            ...item.type === 'text' && { formattedValue: this.$route.query[key] },
            ...item.type === 'simpleMask' && { formattedValue: this.applyMask (this.$route.query[key], item.inputMask) },
            ...item.type === 'select' && item.items?.data && { formattedValue: getText(item.items.data, this.$route.query[key]) },
            ...item.type === 'select' && !item.items?.data && { formattedValue },
            isPermanent: item.isPermanent,
          });
        }
      }
    },
    setFormattedValueSelectsFilters(item, key) {
      if (item) {
        const formattedName = this.itemsSelectsFilters[key]?.filter((item) => {
          return item.value === this.$route.query[key];
        })[0]?.text;

        return formattedName;
      }
    },
    synchronizerList () {
      this.btnSynchronizerLoading = true;
      this.service.synchronizerList().then(() => {
        this.$noty.success('Sincronizado com sucesso!');
        this.loadingDestroy = false;
        this.fetchDataApi();
        this.btnSynchronizerLoading = false;
      }).catch((err) => {
        this.$noty.error(err);
        this.btnSynchronizerLoading = false;
      });
    },
    applyMask (value, mask) {
      let cleaned = value.replace(/\D/g, '');
      let result = [];
      let valueIndex = 0;

      for (const element of mask) {
        if (element === '#' && valueIndex < cleaned.length) {
          result.push(cleaned[valueIndex]);
          valueIndex++;
        } else {
          result.push(element);
        }
      }

      return result.join('');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  ::-webkit-scrollbar {
    height: 8px;
    border-radius: 20px;
    background-color: #ee9ea9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #FF3D57;
    border-radius: 20px;
  }

  .action-column {
    right: 0px;
    position: sticky;
    padding: 0 5px !important;
    width: 100px;
    // border-top-left-radius: 25px;
    // border-bottom-left-radius: 25px;
  }

  .dynamic-list-data-table {
    border-radius: 25px;
    padding: 0 5px;
  }

  @media only screen and (min-width: 320px) and (max-width: 1280px) {
    .dynamic-list-data-table {
      border-radius: 10px;
    padding: 0 5px;
    }
  }
}

</style>
