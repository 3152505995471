import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, list, calendar } from '@/utils/icons';
import { creditCardControlParcelsStatus } from '@/utils/options';
import CreditCardControlBusiness from '@/business/CreditCardControlBusiness';

export default {
  domain: 'credit_card_controls',
  domainSingle: 'credit_card_control',
  business: CreditCardControlBusiness,
  title: 'Controle de Cartão de Crédito',
  description: 'Lista de todas as Controle de Cartão de Crédito do sistema',
  showInactive: false,
  showExpand: true,
  routes: {
    list: {
      name: 'creditCardControls',
      path: '/controle-cartoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
  },
  listActions: {
    has: true,
    noShow: true,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Parcelas',
          icon: list,
          action: 'openParcels'
        },
      ]
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'select',
        name: 'credit_card_administrator_id',
        label: 'Administradora',
        icon: list,
        md: 6,
        domain: 'credit_card_administrators',
        verb: 'get',
        endpoint: 'credit_card_administrators?page=1&per_page=-1&sort_by=name&sort=asc',
        itemText: 'name',
        itemValue: 'id',
      },
      {
        type: 'select',
        name: 'status',
        label: 'Status',
        icon: list,
        md: 6,
        items: {
          data: creditCardControlParcelsStatus
        }
      },
    ]
  },
  fields: [
    {
      title: 'Dados da Transação',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Data',
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'credit_card_administrator',
          formattedName: 'credit_card_administrator.name',
          label: 'Administradora',
          align: '',
          list: false,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'qtd_parcels',
          formattedName: 'qtd_parcels',
          label: 'Qtd. Parcelas',
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'flag',
          formattedName: 'flag.description',
          label: 'Bandeira',
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'aut',
          formattedName: 'aut',
          label: 'Autorização',
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'value',
          formattedName: 'value_formatted',
          label: 'Valor',
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'paid_parcels',
          formattedName: 'paid_parcels',
          label: 'Parcelas Pagas',
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'status_formatted',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          list: true,
        },
      ]
    }
  ],
  footer: [
    {
      name: 'total_transactions',
      label: 'Qtd. de Transações',
      color: 'primary',
      md: 4,
    },
    {
      name: 'value_total_transactions_available',
      label: 'Total a Receber',
      color: 'primary',
      md: 4,
    },
    {
      name: 'value_total_transactions_receivable',
      label: 'Total Recebido',
      color: 'primary',
      md: 4,
    },
  ]
};
