<template>
  <div style="background: #000">
    <div class="pdv" :style="{'background-image': 'url(' + require('@/assets/background-pdv.jpg') + ')',}">
      <span>
      </span>
      <PdvTop @print="print" />

      <div class="px-2 px-lg-5">
        <PdvHeader ref="PdvHeader" :model="order" />
        <PdvContentMain ref="PdvContentMain" :model="order" :quantity="quantity"
          @addItem="addItem" />
        <!-- <PdvResume ref="PdvResume" :model="order" /> -->
        <PdvActions :model="order" @openModal="openModal" />
      </div>

      <Dialog :dialog="dialogModals" :maxWidth="dialogWidth">
        <component :is="modalComponent" slot="content"
          v-bind="modalComponentProps"
          @confirmDialog="confirmDialog"
          @update:dialogModal="closeModalComponent"
          @activeDialogPdvFinishConfirmation="dialogPdvFinishConfirmation = $event"
          @printForSale="printForSale"
          @emitterNFCeForSale="emitterNFCeForSale" />
      </Dialog>

      <DialogConfirmation :dialog="dialogConfirmation"
        textYesBtn="Sim (Enter)"
        textNoBtn="Não (Esc)"
        :loading="loadingDialogConfirmation"
        @yesActionDialogConfirmation="confirmDialogConfirmation"
        @noActionDialogConfirmation="closeDialogConfirmation"
        v-bind="dialogConfirmationProps" />

      <Dialog :dialog="dialogModalPayments" :maxWidth="1200">
        <Payments slot="content" :model="order" @confirmDialog="save"
          @update:dialogModal="dialogModalPayments = $event"
          :loading="loadingPayments" :key="keyModalPayments" />
      </Dialog>

      <Dialog :dialog="dialogPrintConfirmation" :maxWidth="740">
        <PdvPrintConfirmation slot="content" @confirmDialog="handlePrintConfirmation"
          @update:dialogModal="closePrintConfirmation" :dialog="dialogPrintConfirmation"
          :loading="loadingPrintConfirmation" />
      </Dialog>
    </div>
    <div id="printMe65">
      <div id="printMe65Danfe" v-if="dataProductInvoice">
        <Danfe65 :model="dataProductInvoice" />
      </div>
      <div id="printMeCoupon">
        <Coupon :model="dataCoupon" />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from '@/components/vuetify';
import DialogConfirmation from '@/components/DialogConfirmation';
import PdvActions from '../components/PdvActions';
import PdvContentMain from '../components/PdvContentMain';
import PdvHeader from '../components/PdvHeader';
import PdvTop from '../components/PdvTop';
import PdvClients from '../components/PdvClients';
import PdvProducts from '../components/PdvProducts';
import PdvSales from '../components/PdvSales';
import PdvSellers from '../components/PdvSellers';
import PdvPriceList from '../components/PdvPriceList';
import PdvDiscount from '../components/PdvDiscount';
import PdvRemoveItem from '../components/PdvRemoveItem';
import Order from '@/views/Movements/Orders/model/Order';
import { formatCurrency, getItemNumber, limitCharacters, randomNumber } from '@/utils';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import { orderTypes, operationType, emissionFinality, emissionType, printerType } from '@/utils/enums';
import Payments from '@/components/OrderProductInvoice/Payments';
import Danfe65 from '@/components/Danfe/Danfe65';
import Coupon from '@/components/Danfe/Coupon';
import printJS from 'print-js';
import ProductInvoice from '@/views/TaxOperations/ProductInvoices/model/ProductInvoice';
import PdvPrintConfirmation from '../components/PdvPrintConfirmation';

const WIDTH_COMPONENTS = ['Discount', 'RemoveItem'];
const DIALOG_CONFIRMATION_COMPONENTS = ['CancelSale', 'FinishSale', 'Exit'];
const DIALOG_CONFIRMATION_TYPES = Object.freeze({
  CancelSale: {
    type: 'CancelSale',
    title: 'Cancelar Venda',
    message: 'Deseja Realmente Cancelar a Venda?',
    action: 'resetSale',
  },
  FinishSale: {
    type: 'FinishSale',
    title: 'Finalizar Venda',
    message: 'Deseja Realmente Finalizar a Venda?',
    action: 'finishSale',
  },
  Exit: {
    type: 'Exit',
    title: 'Sair',
    message: 'Deseja Realmente Sair do Pdv?',
    action: 'exit',
  },
  EmitterNFCe: {
    type: 'EmitterNFCe',
    title: 'Emitir a NFC-e',
    message: 'Deseja Emitir a NFC-e?',
    action: 'emitterNFCe',
  }
});
const KEYS = {
  Escape: { name: 'Escape', verifyDisable: false },
  F4: { name: 'Products', verifyDisable: false },
};

const KEYS_CTRL = {
  Y: { name: 'Clients', verifyDisable: '' },
  y: { name: 'Clients', verifyDisable: '' },
  I: { name: 'Sales', verifyDisable: false },
  i: { name: 'Sales', verifyDisable: false },
  Q: { name: 'Sellers', verifyDisable: false },
  q: { name: 'Sellers', verifyDisable: false },
  Z: { name: 'CancelSale', verifyDisable: true },
  z: { name: 'CancelSale', verifyDisable: true },
  X: { name: 'FinishSale', verifyDisable: true },
  x: { name: 'FinishSale', verifyDisable: true },
  Escape: { name: 'Exit', verifyDisable: true },
  F6: { name: 'Discount', verifyDisable: true },
  F7: { name: 'RemoveItem', verifyDisable: true },
};

export default {
  name: 'Pdv',
  components: {
    Dialog,
    DialogConfirmation,
    PdvActions,
    PdvContentMain,
    PdvHeader,
    PdvTop,
    PdvClients,
    PdvProducts,
    PdvSales,
    PdvSellers,
    PdvPriceList,
    PdvDiscount,
    PdvRemoveItem,
    Payments,
    Danfe65,
    PdvPrintConfirmation,
    Coupon,
  },
  data () {
    return {
      order: this.setInitialOrder(),
      utils: {
        limitCharacters: limitCharacters
      },
      dialogModals: false,
      dialogConfirmation: false,
      dialogPrintConfirmation: false,
      dialogWidth: 900,
      modalComponent: null,
      modalComponentProps: {},
      dialogConfirmationProps: {},
      productInvoice: ProductInvoice.model,
      dataProductInvoice: null,
      quantity: 1,
      dialogPdvFinishConfirmation: false,
      loadingDialogConfirmation: false,
      loadingSaleFinish: false,
      dialogModalPayments: false,
      loadingPayments: false,
      loadingPrintConfirmation: false,
      keyModalPayments: 0,
      dataCoupon: {
        company: {
          name: 'Empresa Teste',
          address: {
            street: 'Rua Teste',
            number: '123',
            neighborhood: 'Bairro Teste',
            city: 'Cidade Teste',
            uf: 'UF',
          },
          phone: '1234567890',
          cnpj: '12345678901234',
          ie: '1234567890',
        },
        client: {
          name: 'Cliente Teste',
          person_type: this.$enums.personType.PHYSICAL,
          cpf: '12345678901',
          cnpj: null,
        },
        items: [],
        total: {
          products_value: 0,
          discount_value: 0,
          nf_value: 0,
        },
        payments: [],
      }
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  mixins: [InputFocusMixin],
  methods: {
    setInitialOrder () {
      return {
        ...Order.model,
        type: orderTypes.ORDER_SALE_PDV,
        client: { name: 'Não Informado' },
        seller: { name: 'Não Informado' },
        is_consumer_final: true,
        generate_nfce: true,
      };
    },
    actionListener(event) {
      const actionEscape = () => {
        this.closeModalComponent();
        this.closeDialogConfirmation();

        if (event.ctrlKey && !this.dialogModals && !this.dialogConfirmation) {
          this.openModal({ name: 'Exit' });
        }
      };

      if (!this.dialogModals && !this.dialogConfirmation) {
        if (event.key === 'Enter' && !event.ctrlKey) {
          // console.log(this.$refs.PdvContentMain.quantity);
          // console.log(this.$refs.PdvContentMain.barCode);
        } else if (event.key === 'Escape') {
          actionEscape();
        } else {
          if (KEYS[event.key] || KEYS_CTRL[event.key]) {
            if ((KEYS[event.key]?.verifyDisable || KEYS_CTRL[event.key]?.verifyDisable)
              && this.order.items.length === 0) {
              return;
            }
          }

          if (event.ctrlKey) {
            if (KEYS_CTRL[event.key]) {
              this.openModal({ name: KEYS_CTRL[event.key].name });
            }
          } else {
            if (KEYS[event.key]) {
              this.openModal({ name: KEYS[event.key].name });
            }
          }
        }
      } else if (this.dialogPdvFinishConfirmation) {
        //TODO
      } else {
        if (event.key === 'Escape') {
          actionEscape();
        }
      }
    },
    openModal ({ name }) {
      if (DIALOG_CONFIRMATION_COMPONENTS.includes(name)) {
        this.dialogConfirmation = true;
        this.dialogConfirmationProps = DIALOG_CONFIRMATION_TYPES[name];
      } else {
        this.modalComponent = `Pdv${name}`;
        this.dialogModals = !this.dialogModals;
      }

      if (WIDTH_COMPONENTS.includes(name)) {
        this.dialogWidth = 450;
      } else if (name === 'Finish') {
        this.dialogWidth = 1300;
      } else {
        this.dialogWidth = 1300;
      }
    },
    confirmDialog ({ action, data }) {
      this[action](data);
      this.closeModalComponent();
    },
    closeModalComponent () {
      if (this.dialogModals) {
        this.dialogModals = false;
        this.modalComponent = null;
      }
    },
    confirmDialogConfirmation () {
      if (this.dialogConfirmation) {
        this.closeDialogConfirmation();
        this[this.dialogConfirmationProps.action]();
      }
    },
    closeDialogConfirmation () {
      if (this.dialogConfirmation) {
        this.dialogConfirmation = false;
      }
    },
    setClient(client) {
      this.order.client_id = client.id;
      this.order.client = client;
    },
    setSeller (seller) {
      this.order.seller_id = seller.id;
      this.order.seller = seller;
    },
    addItem (product_variation) {
      const quantity = this.$refs.PdvContentMain.quantity;
      const totalValue = product_variation.price * quantity;

      if (!quantity || quantity <= 0) {
        this.$noty.error('Quantidade do Item não pode ser menor ou igual a 0');

        return false;
      }

      if (!totalValue || totalValue <= 0) {
        this.$noty.error('Valor Total do Item não pode ser menor ou igual a 0');

        return false;
      }

      const payloadCalculateItemTaxes = {
        client_id: null,
        product_variation_id: product_variation.id,
        nature_operation_id: null,
        total_without_discount_value: totalValue,
        taxpayer_type: null,
        is_costumer: true,
        discount_value: 0,
        another_value: 0,
        freight_value: 0,
        quantity,
        is_pdv: true,
      };

      this.$api.orders.calculateItemTaxes(payloadCalculateItemTaxes).then((res) => {
        this.$refs.PdvHeader.description = `${formatCurrency(quantity, { type: 'decimal' })} X ${product_variation.name}`;
        this.$refs.PdvContentMain.unitValue = formatCurrency(product_variation.price, { type: 'decimal' });
        this.$refs.PdvContentMain.totalValue = formatCurrency(totalValue, { type: 'decimal' });
        this.$refs.PdvContentMain.quantity = 1;

        this.order.items.push({
          product_id: product_variation.product_id,
          product_variation_stock_id: null,
          nature_operation_id: null,
          item_number: getItemNumber(this.order.items),
          discount_percentage: 0,
          discount_value: 0,
          sub_total: totalValue,
          weight_liquid_unit: product_variation.liquid_weight,
          weight_gross_unit: product_variation.gross_weight,
          total_weight_liquid: product_variation.liquid_weight * quantity,
          total_weight_gross: product_variation.gross_weight * quantity,
          new_item: true,
          product_variation_id: product_variation.id,
          name: product_variation.name,
          bar_code: product_variation?.bar_code,
          code: product_variation?.code,
          ncm: product_variation.ncm.value,
          cest: product_variation.cest?.value,
          unit_measure: product_variation.unit_measure.value,
          quantity: quantity,
          unit_value: product_variation.price,
          total_value: totalValue,
          tax: res
        });

        this.changeTotalizers();
        this.focusInputs(this.$refs.PdvContentMain.$refs.barCode);
      }).catch((err) => {
        let message = 'Erro ao Calcular os Impostos';

        if (err.response.status === 400) {
          message = err.response.data.error;
        }
        this.$noty.error(message);
      }). finally(() => {
        this.loadingTaxes = false;
      });
    },
    removeItem (item) {
      if (item > this.order.items.length) {
        this.$noty.error('Item não localizado');

        return false;
      }
      this.order.items.splice(item - 1, 1);
      this.changeTotalizers();
    },
    addDiscount (discount) {
      if (discount > this.order.total_order) {
        this.$noty.error('O Valor do Desconto não pode ser maior que o Valor da Venda');

        return false;
      }
      this.order.total_discount_value = discount;
      this.changeTotalizers();
    },
    resetSale () {
      this.order = this.setInitialOrder();
      this.productInvoice = ProductInvoice.model;
      this.$refs.PdvContentMain.unitValue = formatCurrency(0, { type: 'decimal' });
      this.$refs.PdvContentMain.totalValue = formatCurrency(0, { type: 'decimal' });
      this.$refs.PdvContentMain.quantity = 1;
      this.$refs.PdvContentMain.barCode = '';
    },
    finishSale() {
      this.keyModalPayments = randomNumber();
      this.dialogModalPayments = true;
    },
    exit () {
      this.$router.push({ name: 'home' });
    },
    changeTotalizers () {
      let subTotal = 0;

      this.order.items.forEach((item, index) => {
        item.item_number = index + 1;
        subTotal += item.total_value;
      });

      this.order.total_products = subTotal;
      this.order.total_order = subTotal - this.order.total_discount_value;
    },
    save ({ data: { payments }}) {
      this.loadingPayments = true;
      this.order.payments = payments;

      this.$api.orders.createPdv(this.order).then(({ data: { order } }) => {
        this.order.id = order.id;
        this.order.company = order.company;

        this.$noty.success('Venda Realizada com Sucesso');
        this.dialogPrintConfirmation = true;
      }).catch((err) => {
        let message = 'Erro ao Realizar a Venda';

        if (err.response.status === 400) {
          message = err.response.data.error;
        }

        this.$noty.error(message);
      }).finally(() => {
        this.loadingPayments = false;
        this.dialogModalPayments = false;
      });
    },
    emitterNFCe() {
      this.loadingPrintConfirmation = true;

      const payload = {
        operation_type: operationType.OUTPUT,
        emission_type: emissionType.NORMAL,
        emission_finality: emissionFinality.NORMAL,
        printer_type: printerType.NFCE,
        order_id: this.order.id,
        is_pdv: true,
        transmit: true,
      };

      this.$api.orders.generateInvoice(payload).then(({ product_invoice }) => {
        this.$noty.success('Nota fiscal gerada com sucesso');

        if (product_invoice.status === this.$enums.productInvoiceStatus.REJECTED) {
          this.$noty.error(product_invoice.reason);
        }

        this.productInvoice = product_invoice;
        this.dataProductInvoice = product_invoice;
        this.print('printMe65Danfe');
      }).catch(() => {
        this.$noty.error('Erro ao gerar nota fiscal');
      }).finally(() => {
        this.loading = false;
        this.dialogConfirmationFinishSale = false;
        this.loadingPrintConfirmation = false;
        this.dialogPrintConfirmation = false;
        this.resetSale();
      });
    },
    emitterNFCeForSale(item) {
      this.order = item;
      this.dialogPrintConfirmation = true;
    },
    printForSale({ printable, item }) {
      if (printable === 'printMe65Danfe') {
        this.$api.productInvoices.show(item.id).then((res) => {
          this.dataProductInvoice = res.product_invoice;
          this.print(printable);
        }).catch(() => {
          this.$noty.error('Erro ao pegar a nota fiscal');
        });
      } else {
        this.dataCoupon = item;
        this.print(printable);
      }
    },
    print(printable) {
      setTimeout(() => {
        printJS({
          printable,
          type: 'html',
          maxWidth: 402,
          targetStyles: ['*'],
          font_size: '11pt',
          onPrintDialogClose: () => {
          }
        });
        this.dialogPrintConfirmation = false;
      }, 500);
      this.resetSale();
    },
    handlePrintConfirmation(option) {
      if (option === 'nfce') {
        this.emitterNFCe();
      } else if (option === 'coupon') {
        this.dataCoupon = {
          ...this.order,
          total: {
            products_value: this.order.total_products,
            discount_value: this.order.total_discount_value,
            nf_value: this.order.total_order,
          },
        };
        this.print('printMeCoupon');
      }
    },
    closePrintConfirmation() {
      this.dialogPrintConfirmation = false;
      this.resetSale();
    }
  },
  mounted () {
    // this.print();
  }
};
</script>

<style lang="scss" scoped>
.pdv {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1 !important;
}

#printMe65 {
  width: 398px;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
  position: absolute;
  top: 0;
}

.pdv::before{
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1 !important;
}
</style>
