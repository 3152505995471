export const menuItemsMovements = [
  {
    active: false,
    menuGroupName: 'movements',
    icon: 'money',
    title: 'Movimentações',
    items: [
      { icon: 'archiveArrowDownOutline', title: 'Vendas', route: 'order-sales', active: false },
      { icon: 'pencilBoxMultipleOutline', title: 'Compras', route: 'order-purchases', active: false },
      // { icon: 'barcode', title: 'Ordem de Serviços', route: 'order-services', active: false },
      // { icon: 'swapHorizontal', title: 'Orçamentos', route: 'order-budgets', active: false },
      { icon: 'keyboardReturn', title: 'Devoluções de Vendas', route: 'order-sale-devolutions', active: false },
      { icon: 'keyboardReturn', title: 'Devoluções de Compras', route: 'order-purchase-devolutions', active: false },
      { icon: 'archiveArrowDownOutline', title: 'PDV', route: 'order-sales-pdv', active: false },
      { icon: 'table', title: 'Mesas', route: 'order-sales-table', active: false },
      // { icon: 'archiveArrowDownOutline', title: 'Consignados', route: 'order-sales-consigned', active: false },
    ],
  },
];
