<template>
  <v-alert colored-border :border="$attrs.border || 'left'" :color="$attrs.color || 'primary'" elevation="2">
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'Alert'
};
</script>
