import InvoiceSettingSchema from './schemas/InvoiceSettingSchema';
import ListInvoiceSettings from './pages/ListInvoiceSettings';
import CreateInvoiceSetting from './pages/CreateInvoiceSetting';

export default [
  {
    path: InvoiceSettingSchema.routes.list.path,
    name: InvoiceSettingSchema.routes.list.name,
    component: ListInvoiceSettings,
    meta: InvoiceSettingSchema.routes.list.meta,
  },
  {
    path: InvoiceSettingSchema.routes.create.path,
    name: InvoiceSettingSchema.routes.create.name,
    component: CreateInvoiceSetting,
    meta: InvoiceSettingSchema.routes.create.meta
  },
  {
    path: InvoiceSettingSchema.routes.show.path,
    name: InvoiceSettingSchema.routes.show.name,
    component: CreateInvoiceSetting,
    meta: InvoiceSettingSchema.routes.show.meta
  },
];