import CheckControlSchema from './schemas/CheckControlSchema';
import ListCheckControls from './pages/ListCheckControls';
import CreateCheckControl from './pages/CreateCheckControl';

export default [
  {
    path: CheckControlSchema.routes.list.path,
    name: CheckControlSchema.routes.list.name,
    component: ListCheckControls,
    meta: CheckControlSchema.routes.list.meta,
  },
  {
    path: CheckControlSchema.routes.create.path,
    name: CheckControlSchema.routes.create.name,
    component: CreateCheckControl,
    meta: CheckControlSchema.routes.create.meta
  },
  {
    path: CheckControlSchema.routes.show.path,
    name: CheckControlSchema.routes.show.name,
    component: CreateCheckControl,
    meta: CheckControlSchema.routes.show.meta
  },
];
