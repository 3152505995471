<template>
  <v-checkbox
    v-model="value"
    v-bind="$attrs" />
</template>

<script>
export default {
  data () {
    return {
      value: this.$attrs.value,
    };
  },
  watch: {
    value: {
      handler () {
        this.$emit('setCheckBox', this.value || false);
      },
      deep: true,
    }
  },
};
</script>

<style>

</style>
