export default {
  title: 'Informações dos Itens',
  dataList: {
    tooltips: {
      editItem: 'Alterar item',
      removeItem: 'Remover item',
    },
    actions: {
      newItem: {
        label: 'Novo Item'
      }
    },
    taxes: {
      headers: {
        national_simple: 'Simples Nacional',
        base: 'Base',
        aliquot: 'Alíquota',
        value: 'Valor',
        cst: 'CST',
      }
    }
  },
  dialogProductVariations: {
    title: 'Variações do Produto: $productName',
    actions: {
      select: 'Selecionar'
    }
  }
};
