import AllTypeSchema from './schemas/AllTypeSchema';
import ListAllTypes from './pages/ListAllTypes';
import CreateAllType from './pages/CreateAllType';

let routes = [];

Object.keys(AllTypeSchema.routes).forEach((key) => {
  Object.keys(AllTypeSchema.routes[key]).forEach((itemKey) => {
    routes.push({
      path: AllTypeSchema.routes[key][itemKey].path,
      name: AllTypeSchema.routes[key][itemKey].name,
      component: itemKey === 'list' ? ListAllTypes : CreateAllType,
      meta: AllTypeSchema.routes[key][itemKey].meta,
    });
  });
});

export default routes;
