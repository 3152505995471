<template>
  <div>
    <ModalCardDefault :title="'Selecionar a Variação'" @close="$emit('update:dialogModal', false)">
      <DataTable no-search no-sync hide-default-footer :items="productVariations"
        :headers="$schemas.order.create.items.dialogProductVariations.headers" class="px-5">

        <template v-slot:actions="{ props }">
          <Button :label="'Selecionar'" color="secondary" small rounded
            @click="selectProductVariation(props)" />
        </template>
      </DataTable>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  DataTable,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { formatProductVariations } from '@/utils';

export default {
  name: 'ItemsDataListItemsProductVariation',
  components: {
    ModalCardDefault,
    DataTable,
    Button,
  },
  props: {
    product: {
      type: Object,
      required: true,
    }
  },
  computed: {
    l () {
      return '';
    },
    title () {
      return '';
    },
    productVariations () {
      if (this.product) {
        return formatProductVariations(this.product?.product_variations);
      }

      return [];
    }
  },
  methods: {
    selectProductVariation ({ item }) {
      this.product.product_variation = item;
      this.$emit('setProductSelected', this.product);
      this.$emit('update:dialogModal', false);
    }
  },
};
</script>

<style>

</style>
