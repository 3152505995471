<template>
  <div>s
    <ModalCardDefault :title="'Duplicar Estado'" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Selecione o Estado da Duplicação'" :icon="$icons.box" readonly>
        <Select v-model="natureOperationStateUf"
          label="Selecione o Estado" :items="ufs" itemText="text" itemValue="value"
          :rules="[rules.required]" />
      </ExpansionPanel>

      <div slot="actions">
        <Button :label="'Duplicar'" class="mr-3" rounded color="secondary"
          :loading="loadingDuplicateNatureOperationState"
          @click="$emit('duplicateState', natureOperationStateUf)" />
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  Select,
  ExpansionPanel,
  Button,
} from '@/components/vuetify';
import { UF } from '@/utils/options';
import { required } from '@/utils/rules';

export default {
  name: 'DuplicateNatureOperationStates',
  components: {
    ModalCardDefault,
    Select,
    ExpansionPanel,
    Button,
  },
  props: {
    loadingDuplicateNatureOperationState: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      panel: [0],
      natureOperationStateUf: '',
      ufs: UF,
      rules: { required: required },
    };
  }
};
</script>
