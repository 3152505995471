<template>
  <div>
    <PageHeader :schema="$schemas.companySetting" />
    <PageContent>
      <DynamicListPage :schema="$schemas.companySetting" :service="$api.companySettings"
        @actionMoreActions="actionMoreActions" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListCompanySettings',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
  },
  data () {
    return {
      dialog: false,
      loadingConfirm: false,
      companySettingSelected: {},
      actionSelected: null,
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this.companySettingSelected = item;
      this.actionSelected = moreActionItem.action;
      this.dialog = true;
    },
  }
};
</script>
