import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';

export default {
  domain: 'order_services',
  domainSingle: 'order_service',
  title: 'Ordem de Serviços',
  description: 'Lista de todos os Ordem de Serviços do sistema',
  createBtn: 'Novo Ordem de Serviço',
  showInactive: false,
  routes: {
    list: {
      name: 'order-services',
      path: '/ordem-servicos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'order-service-create',
      path: '/ordem-servicos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'order-service-show',
      path: '/ordem-servicos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: [
      ]
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'text',
        name: 'seller',
        label: 'Vendedor',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'customer',
        label: 'Cliente',
        icon: text,
        md: 6,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Ordem de Serviços',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Data',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'number',
          formattedName: 'number',
          label: 'N° Pedido',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'seller',
          formattedName: 'seller.name',
          label: 'Vendedor',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'customer',
          formattedName: 'customer.name',
          label: 'Cliente',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'sub_total_value',
          formattedName: 'sub_total_value_formatted',
          label: 'Sub Total',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'discount_value',
          formattedName: 'discount_value_formatted',
          label: 'Desconto',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'taxes_value',
          formattedName: 'taxes_value_formatted',
          label: 'Impostos',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'total_value',
          formattedName: 'total_value_formatted',
          label: 'Total Final',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};