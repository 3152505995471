<template>
  <div>
    <v-switch v-model="$attrs.value" v-bind="$attrs" v-on="$listeners" :label="label" :hide-details="hideDetails"
    :inset="inset" :hint="hint" :persistent-hint="persistentHint"></v-switch>
  </div>
</template>

<script>
export default {
  name: 'CustomSwitch',
  props: {
    label: {
      type: String,
      default: ''
    },
    inset: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style>

</style>
