import { Response } from 'miragejs';
import { baseFields } from '../../utils/baseFields';

export const create = (server) => {
  server.post('/variations', (schema, request) => {
    const attrs = JSON.parse(request.requestBody);
    const { description } = attrs;

    if ( !description ) {
      return new Response(422, {}, { errors: {
        description: 'The field type is required',
      } });
    }

    const variation = {
      ...attrs,
      ...baseFields,
    };

    const response = schema.variations.create(variation);

    return { variation: response.attrs };
  });
};