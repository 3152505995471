<template>
  <ModalCardDefault :title="l.title" @close="closeModal">
    <ExpansionPanel v-model="panel" :title="l.form.title"
      :icon="$icons.box" readonly>
      <Row>
        <Col md="3">
          <TextField v-model="productInvoiceSelected.nf_number" :label="l.form.fields.number"
            readonly :placeholder="l.form.fields.number" />
        </Col>
        <Col md="9">
          <TextField v-model="productInvoiceSelected.key_nfe" :label="l.form.fields.nfe_key"
            readonly :placeholder="l.form.fields.key_nfe" />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <TextArea v-model="form.justify" :label="l.form.fields.justify"
            :placeholder="l.form.fields.justifyText" :counter="counter"
            :rules="[rules.minCharacters]" />
        </Col>
      </Row>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button :label="l.actions.confirm" color="secondary" rounded class="mr-3"
        @click="correctionLetter" :loading="loading" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Button,
  TextArea,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { minCharacters } from '@/utils/rules';
import { messageErrors } from '@/utils';

export default {
  name: 'CreateCorrectionLetter',
  components: {
    ExpansionPanel,
    Row,
    Col,
    TextField,
    Button,
    TextArea,
    ModalCardDefault,
  },
  props: {
    productInvoiceSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      loading: false,
      dialogModal: false,
      counter: 0,
      rules: {
        minCharacters,
      },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.list.correctionLetter;
    },
    form () {
      return {
        environment: this.l.form[this.productInvoiceSelected.environment],
        series: this.productInvoiceSelected.series,
        initial_number: 1,
        final_number: 1,
        justify: '',
      };
    }
  },
  methods: {
    async correctionLetter () {
      this.loading = true;
      const payload = {
        product_invoice_id: this.productInvoiceSelected.id,
        justify: this.form.justify,
      };
      this.$api.productInvoices.correctionLetter(payload).then((res) => {
        const type = res.status_code === '102' || res.status_code === '135' ? 'success' : 'error';
        this.$noty[type](res.reason);
      }).catch((err) => {
        if (err.response.status === 422) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error('Erro ao criar carta de correção');
        }
      }).finally(() => {
        this.loading = false;
        this.$emit('closeModal', true);
      });
    },
    closeModal () {
      this.$emit('closeModal', false);
    },
  },
};
</script>
