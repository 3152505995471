<script>
export default {
  methods: {
    openOrigin ({ route_name, id }) {
      if (route_name) {
        const routeData = this.$router.resolve({name: route_name, params: { id }});
        window.open(routeData.href, '_blank');
      }
    }
  }
};
</script>
