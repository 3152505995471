<script>
import { snakeCase } from 'lodash';

export default {
  methods: {
    setOrderNumber() {
      if (!this.companySetting[`last_${snakeCase(this.$route.meta.type)}_number`]) {
        return 1;
      }

      return parseInt(this.companySetting[`last_${snakeCase(this.$route.meta.type)}_number`]) + 1;
    }
  }
};
</script>
