// import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import CheckControlSchema from '../schemas/CheckControlSchema';
import { formatCurrency, formatDatePT, getText } from '@/utils';
import { checkControlStatus } from '@/utils/options';

const formatResponse = (item) => {
  item.created_at_formatted = formatDatePT(item.created_at);
  item.paid_date_formatted = formatDatePT(item.paid_date);
  item.compensation_date_formatted = formatDatePT(item.compensation_date);
  item.value_formatted = formatCurrency(item.value);
  item.origin = item.account_parcel_payment?.account_parcel?.account?.title || '';
  item.status_formatted = getText(checkControlStatus, item.status);
};

const CheckControlsService = DynamicService('check_controls',
  CheckControlSchema, { formatResponse });

const CheckControlsCommands = () => ({
});

export default CheckControlsService;
export { CheckControlsService, CheckControlsCommands };
