import { productInvoiceStatus } from '@/utils/enums';
import { formatCurrency } from '@/utils';

export default {
  beforeList: ({ form }) => {
    form.product_invoices.forEach((productInvoice) => {
      productInvoice.destroyDisabled = false;
      productInvoice.disableItemMoreActions = {
        openConfirmationModal: productInvoice.status !== productInvoiceStatus.PENDING && productInvoice.status !== productInvoiceStatus.REJECTED,
        printDanfe: productInvoice.status !== productInvoiceStatus.AUTHORIZED,
        changeNumberNFe: productInvoice.status !== productInvoiceStatus.PENDING && productInvoice.status !== productInvoiceStatus.REJECTED,
        cancelNFe: productInvoice.status !== productInvoiceStatus.AUTHORIZED,
        correctionLetters: productInvoice.status !== productInvoiceStatus.AUTHORIZED,
        events: productInvoice.status === productInvoiceStatus.PENDING,
        downloadXml: productInvoice.status === productInvoiceStatus.PENDING,
      };
    });
    form.value_total_pending = formatCurrency(form.value_total_pending);
    form.value_total_authorized = formatCurrency(form.value_total_authorized);
    form.value_total_rejected = formatCurrency(form.value_total_rejected);
  },
};
