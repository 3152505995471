import SubscriptionSchema from './schemas/SubscriptionSchema';
import ListSubscriptions from './pages/ListSubscriptions';
import CreateSubscription from './pages/CreateSubscription';
import IllustrationInformation from '@/views/Shared/pages/IllustrationInformation';

export default [
  {
    path: SubscriptionSchema.routes.list.path,
    name: SubscriptionSchema.routes.list.name,
    component: ListSubscriptions,
    meta: SubscriptionSchema.routes.list.meta,
  },
  {
    path: SubscriptionSchema.routes.show.path,
    name: SubscriptionSchema.routes.show.name,
    component: CreateSubscription,
    meta: SubscriptionSchema.routes.show.meta
  },
  {
    path: SubscriptionSchema.routes.suspended.path,
    name: SubscriptionSchema.routes.suspended.name,
    component: IllustrationInformation,
    meta: SubscriptionSchema.routes.suspended.meta
  },
];
