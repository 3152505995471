<template>
  <div>
    <v-form v-model="validFormIcms" ref="validFormIcms" lazy-validation>
      <Row class="mt-5">
        <Col md="6">
          <Select v-model="model.icms_cst"
            :label="l.cst.label" :items="icmsCST" @change="changeCst"
            itemText="text" itemValue="value" :rules="[rules.required]" />
        </Col>
        <Col md="6">
          <Select v-model="model.icms_origin"
            :label="l.origin.label" :items="imcsOrigin"
            itemText="text" itemValue="value" :rules="[rules.required]" />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <TextFieldPercent v-model="model.icms_aliquot"
            :placeholder="l.aliquot.placeholder"
            :label="l.aliquot.label" />
        </Col>
        <Col>
          <Select v-model="model.icms_determinate_mode"
            :label="l.determinateMode.label"
            :items="icmsDeterminatonMode" itemText="text" itemValue="value"
            :rules="[rules.required]" />
        </Col>
        <Col md="3">
          <Select v-model="model.icms_exemption_reason"
            :disabled="disabledexemptionReason"
            :label="l.exemptionReason.label"
            :items="icmsExemptionReason" itemText="text" itemValue="value"/>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <CheckBox v-model="model.icms_reduce_value"
            @setCheckBox="setCheckBox($event, 'icms_reduce_value')"
            :label="l.reduceValue.label" class="mt-0"/>
        </Col>
        <Col md="4">
          <CheckBox v-model="model.icms_reduce_base"
            @setCheckBox="setCheckBox($event, 'icms_reduce_base')"
            :label="l.reduceBase.label" class="mt-0"/>
        </Col>
        <Col md="4">
          <CheckBox v-model="model.icms_include_discounts"
            @setCheckBox="setCheckBox($event, 'icms_include_discounts')"
            :label="l.includeDiscounts.label" class="mt-0"/>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <CheckBox v-model="model.icms_include_ipi"
            @setCheckBox="setCheckBox($event, 'icms_include_ipi')"
            :label="l.includeIpi.label" class="mt-0"/>
        </Col>
        <Col md="4">
          <CheckBox v-model="model.icms_include_expenses"
            @setCheckBox="setCheckBox($event, 'icms_include_expenses')"
            :label="l.includeExpenses.label" class="mt-0"/>
        </Col>
        <Col md="4">
          <CheckBox v-model="model.icms_include_freight"
            @setCheckBox="setCheckBox($event, 'icms_include_freight')"
            :label="l.includeFreight.label" class="mt-0"/>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <TextFieldPercent v-model="model.icms_percent_reduce_base"
          :placeholder="l.percentReduceBase.placeholder"
          :label="l.percentReduceBase.label" />
        </Col>
        <Col md="4">
          <TextFieldPercent v-model="model.icms_aliquot_deferment"
            :placeholder="l.aliquotDeferment.placeholder"
            :label="l.aliquotDeferment.label" />
        </Col>
        <Col md="4">
          <TextFieldPercent v-model="model.icms_aliquot_fcp"
            :placeholder="l.aliquotFcp.placeholder"
            :label="l.aliquotFcp.label" />
        </Col>
      </Row>
    </v-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextFieldPercent
} from '@/components/vuetify';
import CheckBox from '@/components/vuetify/CheckBox';
import { required } from '@/utils/rules';
import { icmsCSTSimple, icmsCSTNormal, imcsOrigin, icmsDeterminatonMode, icmsExemptionReason } from '@/utils/options';
import { getCompany } from '@/utils';
import { typeTaxRegime, icmsCst } from '@/utils/enums';

export default {
  name: 'Icms',
  components: {
    Row,
    Col,
    Select,
    TextFieldPercent,
    CheckBox
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      rules: { required: required },
      validFormIcms: false,
      disabledexemptionReason: true,
      icmsCST: icmsCSTSimple,
      imcsOrigin: imcsOrigin,
      icmsDeterminatonMode: icmsDeterminatonMode,
      icmsExemptionReason: icmsExemptionReason
    };
  },
  mounted () {
    this.mountSelectItems();
  },
  computed: {
    l () {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState.form.icms;
    },
    companyTaxRegime () {
      return getCompany().tax_regime;
    },
  },
  methods: {
    async mountSelectItems () {
      this.changeCst();

      if (this.companyTaxRegime === typeTaxRegime.NORMAL_REGIME) {
        this.icmsCST = icmsCSTNormal;
      } else {
        this.icmsCST = icmsCSTSimple;
      }
    },
    changeCst() {
      if (this.model.icms_cst === icmsCst.CST_40 || this.model.icms_cst === icmsCst.CST_400 ) {
        this.disabledexemptionReason = false;
      } else {
        this.disabledexemptionReason = true;
        this.model.icms_exemptionReason = '';
      }
    },
    setCheckBox(value, type) {
      this.model[type] = value || false;
    }
  }
};
</script>
