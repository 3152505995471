import { modelNf } from '@/utils/enums';
import Vue from 'vue';

const setFieldReadonly = (form, propsFields) => {
  Vue.set(form, 'cid_token', '');
  Vue.set(form, 'csc', '');

  propsFields.cid_token.readonly = form.model !== modelNf.NFCE;
  propsFields.csc.readonly = form.model !== modelNf.NFCE;
};

export default {
  changes: {
    model: ({ form, propsFields }) => {
      setFieldReadonly(form, propsFields);
    }
  },
};
