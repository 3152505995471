import { tableStatus } from '@/utils/enums';

export default {
  beforeList: ({ form }) => {
    form.tables.forEach((table) => {
      table.destroyDisabled = false;

      if (table.status === tableStatus.AVAILABLE) {
        table.disableItemMoreActions = {
          openTable: false,
          sellItem: true,
          detailsTable: true,
          closeTable: true,
        };
      } else if (table.status === tableStatus.UNAVAILABLE) {
        table.destroyDisabled = true;
        table.disableItemMoreActions = {
          openTable: true,
          sellItem: false,
          detailsTable: false,
          closeTable: false,
        };
      }
    });
  },
};
