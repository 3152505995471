<template>
  <div style="display: flex; justify-content: flex-end; flex-direction: row">
    <v-menu :disabled="dataListProps.item.disableMoreActions" :nudge-width="230" :nudge-left="35" :nudge-top="10" bottom left offset-y v-if="hasMoreActions">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>{{ icons.dotsVertical }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense v-if="schema.listActions.situation">
          <v-list-item link @click="$emit('actionMoreActions', { dataListProps, i: { action: 'situation' } })">
            <v-list-item-icon class="mr-2">
              <v-icon class="icon-side-bar">{{ icons.check }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-side-bar">{{ getLabelSituation(dataListProps.item.situation) }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list v-for="(moreActionItem, index) in schema.listActions.more.items" :key="index" dense>
          <v-list-item :disabled="getDisabledActionItem(moreActionItem)" link @click="$emit('actionMoreActions', { dataListPropsItem: dataListProps, moreActionItem })">
            <v-list-item-icon class="mr-2">
              <v-icon class="icon-side-bar" :color="moreActionItem.iconColor" :disabled="getDisabledActionItem(moreActionItem)">{{ moreActionItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-side-bar">{{ moreActionItem.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-btn v-if="!schema.listActions.noShow" icon class="my-1" fab color="secondary" x-small
      :to="{ name: routeName, params: { id: dataListProps.item.id } }"
      :disabled="dataListProps.item.showDisabled">
      <v-icon dark>{{ icons.view }}</v-icon>
    </v-btn>

    <v-btn icon v-if="!schema.listActions.noDestroy" class="my-1" fab color="red" x-small
      :disabled="dataListProps.item.destroyDisabled"
      @click="$emit('openDialogDestroy', dataListProps)">
      <v-icon dark>{{ icons.destroy }}</v-icon>
    </v-btn>

    <v-btn icon v-if="schema.listActions.showOrigin && dataListProps.item.origin.route_name" class="my-1" fab color="secondary" x-small
      :disabled="dataListProps.item.originDisabled" title="Abrir origem"
      @click="openOrigin(dataListProps.item.origin)">
      <v-icon dark>{{ $icons.redo }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import OpenOriginMixin from '@/mixins/OpenOriginMixin';

export default {
  name: 'ActionsListPage',
  props: {
    schema: {
      type: Object,
      required: true,
    },
    dataListProps: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    }
  },
  computed: {
    routeName () {
      return this.$route.meta.type ? this.schema.routes[this.$route.meta.type].show.name :
        this.schema.routes?.show?.name;
    },
    hasMoreActions () {
      if (this.schema.schemaShare) {
        return this.$route.meta.moreActions;
      }

      return this.schema.listActions.more.has;
    }
  },
  mixins: [OpenOriginMixin],
  methods: {
    getLabelSituation (situationItem) {
      return situationItem === this.$enums.situation.ACTIVE ? 'Desativar' : 'Ativar';
    },
    getDisabledActionItem (item) {
      if (this.dataListProps.item?.disableItemMoreActions) {
        return this.dataListProps.item?.disableItemMoreActions[item.name];
      }

      return false;
    }
  },
};
</script>
