export default {
  situation: {
    finished: 'Entregue',
    pending: 'Não entregue',
    canceled: 'Cancelado',
    returned: 'Devolvido',
    reversed: 'Estornado',
  },
  generateProductInvoice: {
    titleModal: 'Gerar Nota Fiscal',
    titleForm: 'Configurações',
    fields: {
      documentType: 'Tipo Documento',
      invoiceSettings: 'Configuração Fiscal',
      model: 'Modelo',
      series: 'Série',
      invoiceNumber: 'N° Nota Fiscal',
      emissionForm: 'Forma de Emissão',
      finality: 'Finalidade da NF-e',
      danfeFormat: 'Formato do DANFE',
    },
    actions: {
      generate: 'Gerar NF-e',
    }
  }
};
