<template>
  <div>
    <ModalCardDefault :title="title" @close="$emit('update:dialogModal', false)">
      <ExpansionPanel v-model="panel" :title="'Produtos da Lista de Preços'" :icon="$icons.box" readonly>
        <DataTable no-search :items-per-page="5" :loading="loadingDataTable"
          @getItems="getPriceListProductVariations" :items="productVariations"
          :headers="headers" class="px-5">

          <template v-slot:actions="{ props }">
            <ButtonIcon color="secondary" x-small :icon="$icons.edit"
              :tooltip="'Alterar Preço Final'"
              @click="openDialogUpdateFinalValue(props)" />
            <ButtonIcon color="primary" x-small :icon="$icons.destroy"
              :tooltip="'Excluir'"
              @click="openDialogConfirmation(props)" />
          </template>
        </DataTable>
      </ExpansionPanel>
    </ModalCardDefault>

    <Dialog :dialog="dialogModalUpdateFinalValue" no-title :maxWidth="600">
      <ListProductsUpdate slot="content" :product-variation-selected="priceListProductVariationSelected"
        :loading-update-final-value="loadingUpdateFinalValue"
        @updateFinalValue="updateFinalValue"
        @update:dialogModalUpdateFinalValue="dialogModalUpdateFinalValue = $event" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation"
      :loading="loadingDialogConfirmation" title="Excluir Registro"
      message="Reseja realmente excluir o registro?"
      @yesActionDialogConfirmation="yesActionConfirmation"
      @noActionDialogConfirmation="dialogConfirmation = false" />
  </div>
</template>

<script>
import {
  DataTable,
  ButtonIcon,
  Dialog,
  ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import DialogConfirmation from '@/components/DialogConfirmation';
import ListProductsUpdate from './ListProductsUpdate';

export default {
  name: 'ListProducts',
  components: {
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Dialog,
    ExpansionPanel,
    DialogConfirmation,
    ListProductsUpdate
  },
  props: {
    priceList: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      dialogModal: false,
      dialogConfirmation: false,
      dialogModalUpdateFinalValue: false,
      loadingDialogConfirmation: false,
      loadingUpdateFinalValue: false,
      loadingDataTable: false,
      productVariations: [],
      priceListProductVariationSelected: null
    };
  },
  computed: {
    l () {
      return this.$locales.pt.variations.list.dialogVariationValues;
    },
    title () {
      return 'Lista dos Produtos';
    },
    headers () {
      return [
        { text: 'Produto', value: 'product_variation.name' },
        { text: 'Preço Padrão', value: 'default_value_formatted' },
        { text: 'Preço Final', value: 'final_value_formatted' },
        { text: 'Ações', value: 'actions', width: 120, align: 'right' },
      ];
    }
  },
  watch: {
    priceList() {
      this.getPriceListProductVariations();
    }
  },
  methods: {
    getPriceListProductVariations (pagination = { page: 1, itemsPerPage: 7 }) {
      this.loadingDataTable = true;
      this.$api.priceLists.getPriceListProductVariations(this.priceList.id, pagination).then((res) => {
        this.productVariations = res;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingDataTable = false;
      });
    },
    close () {
      this.dialogModal = false;
    },
    openDialogConfirmation ({ item }) {
      this.priceListProductVariationSelected = item;
      this.dialogConfirmation = true;
    },
    openDialogUpdateFinalValue ({ item }) {
      this.priceListProductVariationSelected = item;
      this.dialogModalUpdateFinalValue = true;
    },
    updateFinalValue(form) {
      this.loadingUpdateFinalValue = true;
      this.$api.priceLists.updatePriceListProductVariations(this.priceListProductVariationSelected.price_list_id,
        this.priceListProductVariationSelected.id, {
          final_value: form.final_value
        }).then(() => {
        this.$noty.success('Registro Excluído com Sucesso');
      }).catch(() => {
        this.$noty.error('Erro ao Excluir o Registro');
      }).finally(() => {
        this.loadingUpdateFinalValue = false;
        this.dialogModalUpdateFinalValue = false;
        this.getPriceListProductVariations();
      });
    },
    yesActionConfirmation () {
      this.loadingDialogConfirmation = true;
      this.$api.priceLists.deletePriceListProductVariations(this.priceListProductVariationSelected.price_list_id,
        this.priceListProductVariationSelected.id).then(() => {
        this.$noty.success('Registro Excluído com Sucesso');
      }).catch(() => {
        this.$noty.error('Erro ao Excluir o Registro');
      }).finally(() => {
        this.loadingDialogConfirmation = false;
        this.dialogConfirmation = false;
        this.getPriceListProductVariations();
      });
    },
  },
  async mounted () {
    this.getPriceListProductVariations();
  },
};
</script>

<style>

</style>
