import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('product', {
    code: '1',
    category: {
      id: 8,
      name: 'Vestidos'
    },
    sub_category: {
      id: 10,
      name: 'Floral'
    },
    name: 'Vestido Floral',
    reference: '001',
    price: '39,00',
    bar_code: '7890000000017',
    quantity: 15,
    ...baseFields,
  });
  server.create('product', {
    code: '2',
    category: {
      id: 9,
      name: 'Shorts'
    },
    sub_category: {
      id: 11,
      name: 'Natureza'
    },
    name: 'Short Natureza',
    reference: '002',
    price: '39,00',
    bar_code: '7890000000017',
    quantity: 15,
    ...baseFields,
  });
};
