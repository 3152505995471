// import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import BilletControlSchema from '../schemas/BilletControlSchema';
import { formatCurrency, formatDatePT, getText } from '@/utils';
import { billetControlStatus } from '@/utils/options';

const formatResponse = (item) => {
  item.created_at_formatted = formatDatePT(item.created_at);
  item.due_date_formatted = formatDatePT(item.due_date);
  item.paid_date_formatted = formatDatePT(item.paid_date);
  item.compensation_date_formatted = formatDatePT(item.compensation_date);
  item.value_formatted = formatCurrency(item.value);
  item.origin = item.account_parcel_payment?.account_parcel?.account?.title || '';
  item.status_formatted = getText(billetControlStatus, item.status);
};

const BilletControlsService = DynamicService('billet_controls',
  BilletControlSchema, { formatResponse });

const BilletControlsCommands = () => ({
});

export default BilletControlsService;
export { BilletControlsService, BilletControlsCommands };
