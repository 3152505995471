<template>
  <div>
    <PageHeader :schema="$schemas.subscription" />
    <PageContent>
      <DynamicFormPage :schema="$schemas.subscription" :service="$api.subscriptions" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicFormPage from '@/components/dynamics/DynamicFormPage';

export default {
  name: 'CreateSubscription',
  components: { PageHeader, PageContent, DynamicFormPage },
  data() {
    return {};
  }
};
</script>
