var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field-integer',_vm._g(_vm._b({attrs:{"label":_vm.label,"properties":{
    id: _vm.id,
    refs: _vm.refs,
    name: _vm.name,
    readonly: _vm.readonly,
    disabled: _vm.disabled,
    outlined: _vm.outlined,
    clearable: _vm.clearable,
    dense: _vm.dense,
    rules: _vm.rules,
  }}},'v-text-field-integer',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }