<template>
  <ModalCardDefault :title="`Fazer Antecipação da Parcela: ${creditCardControlParcelSelected.number}`"
    @close="$emit('closeParcel')">
    <ExpansionPanel v-model="panel" :title="`Dados da Parcela`" :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <Row>
          <Col>
            <p class="red--text font-weight-bold" v-html="alertAnticipation" />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextFieldMoney v-model="localData.value" placeholder="Valor Parcela"
              label="Valor Parcela" :readonly="isPaid" :rules="[rules.money]" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localData.fee_value" placeholder="Valor Tarifa"
              label="Valor Tarifa" :readonly="isPaid" />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextFieldMoney v-model="localData.anticipation_fee_value" placeholder="Valor Tarifa Antecipação"
              label="Valor Tarifa Antecipação" :readonly="isPaid" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localData.final_value" placeholder="Vlr. Final (Vlr. da Parcela - Vlr. Tarifa)"
              label="Vlr. Final (Vlr. da Parcela - Vlr. Tarifa)" :readonly="isPaid"
              :rules="[rules.money]" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button label="Confirmar Antecipação" rounded color="secondary" class="mr-2"
        :disabled="isPaid" @click="confirmAnticipation()" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextFieldMoney,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { now } from '@/utils';
import { money } from '@/utils/rules';

export default {
  name: 'ListParcelEdit',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    TextFieldMoney,
    Button,
  },
  props: {
    creditCardControlParcelSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      validForm: false,
      rules: {
        money,
      },
      localData: {},
    };
  },
  computed: {
    alertAnticipation () {
      let message = '';

      if (new Date(this.localData.compensation_date) > new Date(now())) {
        message = 'Deseja confirmar a alteração da parcela? A data de compensação será alterada para Hoje.';
      }

      return message;
    },
    isPaid () {
      return this.creditCardControlParcelSelected.status === this.$enums.statusCreditCardTransactionParcel.PAID;
    }
  },
  watch: {
    creditCardControlParcelSelected () {
      this.setLocalData();
    }
  },
  methods: {
    setLocalData () {
      this.localData = {
        value: this.creditCardControlParcelSelected.value,
        fee_value: this.creditCardControlParcelSelected.fee_value,
        anticipation_fee_value: this.creditCardControlParcelSelected.anticipation_fee_value,
        final_value: this.creditCardControlParcelSelected.final_value,
        is_anticipation: true,
      };
    },
    confirmAnticipation () {
      if (!this.$refs.validForm.validate()) {
        this.$noty.error('Preencha os dados Corretamente.');

        return false;
      }
      this.$emit('confirmAnticipation', this.localData);
    },
  },
  mounted () {
    this.setLocalData();
  }
};
</script>

<style>

</style>
