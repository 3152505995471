<template>
  <div>
    <ModalCardDefault :title="l.title" @close="$emit('close')">
      <div class="d-flex justify-end mt-5 mb-5">
        <Button :label="l.actions.create" small :icon="$icons.plus" color="secondary"
          rounded class="btn-actions"
          @click="openCreateBoxMovement(typePage.CREATE)" />
      </div>
      <ExpansionPanel v-model="panel" :title="'Movimentação do Caixa'"
        :icon="$icons.box" readonly>
        <DataTable no-search no-sync :items="items"
          :headers="this.schema.movementItems.list.headers">

          <template v-slot:image="{ props: { item } }">
            <div v-html="item.image"></div>
          </template>

          <template v-slot:actions="{ props }">
            <ButtonIcon color="secondary" class="mr-3" x-small :icon="$icons.view"
              :tooltip="l.tooltip.view"
              @click="openShowBoxMovement(props.item, typePage.SHOW)" />
          </template>
        </DataTable>
      </ExpansionPanel>
    </ModalCardDefault>

    <Dialog :dialog="dialogCreateBoxMovement" no-title :maxWidth="1250">
      <CreateBoxMovement :type-page="typePageSelected"
        :box-movement-selected="boxMovementSelected"
        :box-movement="this.boxMovement"
        @closeDialogCreateBoxMovement="closeDialogCreateBoxMovement"
        slot="content" :title="l.title"
        @close="closeDialogCreateBoxMovement" />
    </Dialog>
  </div>
</template>

<script>
import {
  DataTable, ButtonIcon, Button, Dialog, ExpansionPanel,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateBoxMovement from './CreateBoxMovement.vue';
import { typePage } from '@/utils/enums';

export default {
  name: 'ListBoxMovementItems',
  components: {
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Button,
    Dialog,
    ExpansionPanel,
    CreateBoxMovement
  },
  props: {
    boxMovement: {
      type: Object,
      required: true
    },
    movementItems: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      dialogCreateBoxMovement: false,
      boxMovementSelected: {},
      items: this.movementItems,
      typePageSelected: '',
      typePage
    };
  },
  computed: {
    schema () {
      return this.$schemas.box;
    },
    l () {
      return this.$locales.pt.boxes.list.modalMovementItems;
    }
  },
  methods: {
    openShowBoxMovement(BoxMovement, typePage) {
      this.boxMovementSelected = BoxMovement;
      this.typePageSelected = typePage;
      this.dialogCreateBoxMovement = true;
    },
    openCreateBoxMovement(typePage) {
      this.typePageSelected = typePage;
      this.dialogCreateBoxMovement = true;
    },
    closeDialogCreateBoxMovement() {
      this.dialogCreateBoxMovement = false;
      this.find();
    },
    async find() {
      await this.$api.boxes.listMovementItems(this.boxMovement).then((res) => {
        this.items = res.box_movement_items.map((item) => {
          return {
            ...item
          };
        });
        this.dialogBoxMovementItems = true;
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
  }
};
</script>

<style>

</style>
