import { parseStringFloatDecimal } from '@/utils';

const required = (value) => {
  if (value == '0') {
    return true;
  }

  return !!value || 'Obrigatório.';
};
const requiredSelectMultiple = value => !!value.length || 'Obrigatório.';
const requiredAutoComplete = value => !!value.id || 'Obrigatório.';
const money = value => parseStringFloatDecimal(value) || 'Digite valor maior que zero.';
const biggerZero = value => value > 0 || 'Digite valor maior que zero.';
const minCharacters = value => value.length >= 25 || 'No mínimo 25 caracteres';

const fullName = (value) => {
  const regexNomeCompleto = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,19}\b/gi;

  return regexNomeCompleto.test(value) || 'Digite seu nome completo';
};

export {
  required, requiredSelectMultiple, requiredAutoComplete, money,
  biggerZero, minCharacters, fullName
};
