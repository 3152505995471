import TypeInstallmentSchema from './schemas/TypeInstallmentSchema';
import ListTypeInstallments from './pages/ListTypeInstallments';
import CreateTypeInstallment from './pages/CreateTypeInstallment';

export default [
  {
    path: TypeInstallmentSchema.routes.list.path,
    name: TypeInstallmentSchema.routes.list.name,
    component: ListTypeInstallments,
    meta: TypeInstallmentSchema.routes.list.meta,
  },
  {
    path: TypeInstallmentSchema.routes.create.path,
    name: TypeInstallmentSchema.routes.create.name,
    component: CreateTypeInstallment,
    meta: TypeInstallmentSchema.routes.create.meta
  },
  {
    path: TypeInstallmentSchema.routes.show.path,
    name: TypeInstallmentSchema.routes.show.name,
    component: CreateTypeInstallment,
    meta: TypeInstallmentSchema.routes.show.meta
  },
];