import { Response } from 'miragejs';
import { baseFields } from '../../utils/baseFields';

export const create = (server) => {
  server.post('/customers', (schema, { requestBody }) => {
    const attrs = JSON.parse(requestBody);
    const { name, email, cell_phone } = attrs;

    if ( !name || !email || !cell_phone ) {
      return new Response(422, {}, { errors: {
        name: 'The field name is required',
        email: 'The field email is required',
        cell_phone: 'The field cell_phone is required',
      } });
    }

    const customer = {
      ...attrs,
      cell_phone_formatted: cell_phone,
      ...baseFields,
    };

    const response = schema.customers.create(customer);

    return { customer: response.attrs };
  });
};