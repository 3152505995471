<script>
export default {
  name: 'VerifySumDuplicatesMixin',
  methods: {
    verifyTotalDuplicates (duplicates, nf_value, value) {
      const totalDuplicates = duplicates.reduce((value, item) => {
        return value += parseFloat(item.value);
      }, 0);

      if ((parseFloat(totalDuplicates) + parseFloat(value)) > nf_value) {
        this.$noty.error(this.$locales.pt.productInvoices.create.form.bills.noty.duplicateValue);

        return true;
      }

      return false;
    }
  }
};
</script>
