import { Response } from 'miragejs';
import { dateTime } from '../../utils/dateTime';

export const update = (server) => {
  server.put('/stocks/:id', (schema, { params, requestBody }) => {
    const stock = find(params.id, schema);

    if (!stock) {
      return new Response(404, {}, { errors: {} });
    }

    const attrs = JSON.parse(requestBody);
    const { name, description } = attrs;

    if (!name || !description) {
      return new Response(422, {}, { errors: {
        name: 'The field name is required',
        description: 'The field description is required',
      } });
    }

    stock.update({ name, description, updated_at: dateTime });
  });
};