import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import CreditCardControlSchema from '../schemas/CreditCardControlSchema';
import { formatCurrency, formatDatePT, getText } from '@/utils';
import { creditCardControlStatus, creditCardControlParcelsStatus } from '@/utils/options';

const formatResponse = (item) => {
  item.created_at_formatted = formatDatePT(item.created_at);
  item.value_formatted = formatCurrency(item.value);
  item.status_formatted = getText(creditCardControlStatus, item.status);
  item.parcels = item.parcels.map((parcel) => {
    parcel.number_formatted = `${parcel.number}/${item.parcels.length}`;
    parcel.bank_name = parcel.bank?.description;
    parcel.compensation_date_formatted = formatDatePT(parcel.compensation_date);
    parcel.value_formatted = formatCurrency(parcel.value);
    parcel.fee_value_formatted = formatCurrency(parcel.fee_value);
    parcel.anticipation_fee_value_formatted = formatCurrency(parcel.anticipation_fee_value);
    parcel.final_value_formatted = formatCurrency(parcel.final_value);
    parcel.status_formatted = getText(creditCardControlParcelsStatus, parcel.status);

    return parcel;
  });
};

const formatResponseParcels = (parcels) => {
  return parcels.map((parcel) => {
    return {
      ...parcel,
      number_formatted: parcel.number_formatted = `${parcel.number}/${parcels.length}`,
      bank_name: parcel.bank_name = parcel.bank?.description,
      compensation_date_formatted: parcel.compensation_date_formatted = formatDatePT(parcel.compensation_date),
      value_formatted: parcel.value_formatted = formatCurrency(parcel.value),
      fee_value_formatted: parcel.fee_value_formatted = formatCurrency(parcel.fee_value),
      anticipation_fee_value_formatted: parcel.anticipation_fee_value_formatted = formatCurrency(parcel.anticipation_fee_value),
      final_value_formatted: parcel.final_value_formatted = formatCurrency(parcel.final_value),
      status_formatted: parcel.status_formatted = getText(creditCardControlParcelsStatus, parcel.status),
    };
  });
};

const CreditCardControlsService = DynamicService('credit_card_Controls',
  CreditCardControlSchema, { formatResponse });

const CreditCardControlsCommands = () => ({
  async getAllParcels(credit_card_control_id) {
    const res = await axios.get(`credit_card_controls/${credit_card_control_id}/parcels`);

    return formatResponseParcels(res.data.credit_card_control_parcels);
  },
  async updateParcel(credit_card_control_id, credit_card_control_parcel_id, payload) {
    return await axios.put(`credit_card_controls/${credit_card_control_id}/parcels/${credit_card_control_parcel_id}`, payload);
  },
  async reverseParcel(id, payload) {
    return await axios.post(`credit_card_control_parcels/${id}/reverse`, payload);
  },
});

export default CreditCardControlsService;
export { CreditCardControlsService, CreditCardControlsCommands };
