import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import ResellerSchema from '../schemas/ResellerSchema';
import { formatPhone } from '@/utils';

const formatResponse = (item) => {
  item.phone_formatted = formatPhone(item.phone);
};

const ResellersService = DynamicService('resellers',
  ResellerSchema, { formatResponse });

const ResellersCommands = () => ({
  async createCustomer(payload) {
    return await axios.post(`${ResellerSchema.domain}/create-customer`, payload);
  },
});

export default ResellersService;
export { ResellersService, ResellersCommands };
