import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import PriceListSchema from '../schemas/PriceListSchema';
import { formatCurrency, formatDatePT } from '@/utils';

const formatResponse = (item) => {
  item.value_min_formatted = formatCurrency(item.value_min);
  item.value_max_formatted = formatCurrency(item.value_max);
  item.start_date_formatted = formatDatePT(item.start_date);
  item.end_date_formatted = formatDatePT(item.end_date);
  item.all_products_formatted = item.all_products ? 'Sim' : 'Não';
  item.categories = item.categories.map((category) => category.category.name).join(', ');
  item.sub_categories = item.sub_categories.map((subCategory) => subCategory.sub_category.name).join(', ');

  if (item.type_value === 'percentage') {
    item.value_with_adjustment_formatted = `${formatCurrency(item.value_with_adjustment, { type: 'decimal' })}%`;
  } else {
    item.value_with_adjustment_formatted = formatCurrency(item.value_with_adjustment);
  }
};

const formatResponseGetPriceListProductVariations = (data) => {
  const productVariations =  data.price_list_product_variations.map((item) => {
    return {
      ...item,
      default_value_formatted: formatCurrency(item.default_value),
      final_value_formatted: formatCurrency(item.final_value),
    };
  });

  return {
    ...data,
    data: productVariations
  };
};

const PriceListsService = DynamicService('price_lists', PriceListSchema, { formatResponse, removeNullable: false });

const PriceListsCommands = () => ({
  async getPriceListProductVariations(priceListId, { page, itemsPerPage }) {
    const { data } = await axios.get(`/price_lists/${priceListId}/product_variations?page=${page}&per_page=${itemsPerPage}`);

    return await formatResponseGetPriceListProductVariations(data);
  },
  async createPriceListProductVariationBatch(priceListId, params) {
    await axios.post(`/price_lists/${priceListId}/product_variations/batch`, params);
  },
  async updatePriceListProductVariations(priceListId, priceListProductVariationId, params) {
    await axios.put(`/price_lists/${priceListId}/product_variations/${priceListProductVariationId}`, params);
  },
  async deletePriceListProductVariations(priceListId, priceListProductVariationId) {
    await axios.delete(`/price_lists/${priceListId}/product_variations/${priceListProductVariationId}`);
  },
});

export default PriceListsService;
export { PriceListsService, PriceListsCommands };
