<script>
export default {
  data () {
    return {
      companySetting: {}
    };
  },
  methods: {
    async getCompanySettings() {
      this.$api.companies.getCompanySettings().then((res) => {
        this.companySetting = res.data.company_settings[0] || {};
      }).catch(() => {
      });
    }
  },
  mounted () {
    this.getCompanySettings();
  }
};
</script>
