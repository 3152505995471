<template>
  <div>
    <ModalCardDefault :title="l.title" @close="$emit('update:dialogStates', false)">
      <DataTable no-search no-sync :slots="slots" :items="states"
        :headers="this.schema.states.list.headers">

        <template v-slot:image="{ props: { item } }">
          <div v-html="item.image"></div>
        </template>

        <template v-slot:actions="{ props }">
          <ButtonIcon color="secondary" class="mr-3" x-small :icon="$icons.view"
            :tooltip="l.tooltip.view"
            @click="openShowNatureOperationState(props.item, typePage.SHOW)" />
          <ButtonIcon color="success" class="mr-3" x-small :icon="$icons.copy"
            :tooltip="'Duplicar'"
            @click="openDuplicateNatureOperationState(props.item)" />
          <ButtonIcon color="primary" x-small :icon="$icons.destroy"
            :tooltip="l.tooltip.destroy"
            @click="removeItem(props.item)" />
        </template>
      </DataTable>

      <div slot="actions">
        <Button :label="l.actions.create" class="mr-3" rounded color="secondary"
          @click="openCreateNatureOperationState(typePage.CREATE)" />
      </div>
    </ModalCardDefault>

    <Dialog :dialog="dialogCreateNatureOperationState" no-title :maxWidth="1250">
      <CreateNatureOperationState
        :type-page="typePageSelected"
        :nature-operation-state-selected="natureOperationStateSelected"
        :nature-operation-id="this.natureOperationIdSelected"
        @closeDialogCreateNatureOperationState="closeDialogCreateNatureOperationState"
        slot="content" :title="l.title" @close="closeDialogCreateNatureOperationState" />
    </Dialog>

    <Dialog :dialog="dialogDuplicateNatureOperationState" no-title :maxWidth="600">
      <DuplicateNatureOperationState
        :loading-duplicate-nature-operation-state="loadingDuplicateNatureOperationState"
        slot="content"
        @duplicateState="duplicateState"
        @close="closeDialogDuplicateNatureOperationState" />
    </Dialog>
  </div>
</template>

<script>
import { DataTable, ButtonIcon, Dialog, Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import DuplicateNatureOperationState from './DuplicateNatureOperationStates';
import CreateNatureOperationState from './CreateNatureOperationStates';
import { typePage } from '@/utils/enums';

export default {
  name: 'ListNatureOperationStates',
  components: {
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Dialog,
    Button,
    CreateNatureOperationState,
    DuplicateNatureOperationState
  },
  props: {
    states: {
      type: Array,
      required: true
    },
    natureOperationId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      slots: [{ field: 'image' }],
      dialogCreateNatureOperationState: false,
      natureOperationIdSelected: '',
      natureOperationStateSelected: {},
      typePageSelected: '',
      typePage,
      dialogDuplicateNatureOperationState: false,
      loadingDuplicateNatureOperationState: false,
    };
  },
  computed: {
    schema () {
      return this.$schemas.natureOperation;
    },
    l () {
      return this.$locales.pt.natureOperations.list.modalStates;
    }
  },
  methods: {
    async removeItem ({ id, nature_operation_id }) {
      this.$api.natureOperations.deleteState(nature_operation_id, id).then(() => {
        this.$noty.success(this.l.actions.removeState);
        this.$emit('listStates');
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    openShowNatureOperationState (NatureOperationState, typePage) {
      this.natureOperationStateSelected = NatureOperationState;
      this.typePageSelected = typePage;
      this.dialogCreateNatureOperationState = true;
    },
    openCreateNatureOperationState (typePage) {
      this.typePageSelected = typePage;
      this.natureOperationIdSelected = this.natureOperationId;
      this.dialogCreateNatureOperationState = true;
    },
    openDuplicateNatureOperationState (natureOperationState) {
      this.natureOperationIdSelected = this.natureOperationId;
      this.natureOperationStateSelected = natureOperationState;
      this.dialogDuplicateNatureOperationState = true;
    },
    closeDialogCreateNatureOperationState () {
      this.$emit('listStates');
      this.dialogCreateNatureOperationState = false;
    },
    closeDialogDuplicateNatureOperationState () {
      this.$emit('listStates');
      this.dialogDuplicateNatureOperationState = false;
    },
    duplicateState(uf) {
      this.loadingDuplicateNatureOperationState = true;
      this.$api.natureOperations.duplicateState(this.natureOperationIdSelected,
        this.natureOperationStateSelected.id, uf).then(() => {
        this.$noty.success('Estado Duplicado com Sucesso!');
        this.$emit('listStates');
      }).catch((err) => {
        if (err.response.status === 422) {
          this.$noty.error(err.response.data.error);

          return;
        }
        this.$noty.error('Erro ao Duplicar Estado!');
      }).finally(() => {
        this.loadingDuplicateNatureOperationState = false;
        this.dialogDuplicateNatureOperationState = false;
      });
    }
  }
};
</script>

<style>

</style>
