<template>
  <ModalCardDefault title="Lista de Ações" @close="close">
    <ExpansionPanel v-model="panel" title="Escolha uma das opções" :icon="$icons.box" readonly>
      <div class="d-flex justify-center">
        <Button label="Imprimir DANFE" class="mr-3"
          rounded color="secondary" @click="handleAction('printDanfe')" />
        <Button label="Download DANFE" class="mr-3"
          rounded color="success" @click="handleAction('downloadDanfe')" />
        <!-- <Button label="Enviar por E-mail" class="mr-3"
          rounded color="success" @click="handleAction('')" />
        <Button label="Visualizar Espelho NFe" class="mr-3"
          rounded color="orange" @click="handleAction('')" /> -->
      </div>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import { ExpansionPanel, Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import ProductInvoiceActionsMixin from '@/mixins/ProductInvoiceActionsMixin';

export default {
  name: 'ActionsModal',
  components: {
    ExpansionPanel,
    ModalCardDefault,
    Button,
  },
  props: {
    productInvoiceSelected: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    panel: [0],
    validForm: false,
    loading: false,
  }),
  mixins: [ProductInvoiceActionsMixin],
  methods: {
    handleAction(action) {
      if (action === 'printDanfe') {
        this.printDanfe();
      } else if (action === 'downloadDanfe') {
        this.downloadDanfe();
      }
      this.close();
    },
    async close() {
      this.$emit('close');
    },
  },
};
</script>
