<script>
export default {
  methods: {
    async printDanfe() {
      let resourceApi = 'nfe/danfe';

      if (this.productInvoiceSelected.model == this.$enums.modelNf.NFCE) {
        resourceApi = 'nfce/danfce';
      }

      this.$api.productInvoices.danfe(this.productInvoiceSelected.id, this.productInvoiceSelected.model).then(() => {
        window.open(`${process.env.VUE_APP_URL_WEB}/${resourceApi}/${this.productInvoiceSelected.key_nfe}`);
      });
    },
    async downloadDanfe() {
      let resourceApi = 'nfe/danfe-download';

      if (this.productInvoiceSelected.model == this.$enums.modelNf.NFCE) {
        resourceApi = 'nfce/danfce-download';
      }
      this.$api.productInvoices.danfe(this.productInvoiceSelected.id, this.productInvoiceSelected.model).then(() => {
        window.open(`${process.env.VUE_APP_URL_WEB}/${resourceApi}/${this.productInvoiceSelected.key_nfe}`);
      });
    },
  }
};
</script>
