<template>
  <Main />
</template>

<script>
import Main from '@/views/Main/Main';

export default {
  name: 'App',
  components: {
    Main
  },
  data: () => ({
  }),
};
</script>

<style lang="scss">
$data-table-border-radius: 15px;
.v-navigation-drawer__content {
    height: 100%;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.noty_body li {
  font-size: 13px !important;
}
</style>
