<template>
  <ModalCardDefault title="Lista das Parcelas"
    @close="$emit('close')">
    <ExpansionPanel v-model="panel" :title="'Lista das Parcelas'" :icon="$icons.box" readonly>
      <DataTable no-search no-sync :items-per-page="6"
        :items="parcels"
        :headers="headers" class="px-5" :slots="slotsItems">

        <template v-slot:status_formatted="{ props: { item } }">
          <Chip :label="item.status_formatted" small :color="getColorChip(item.status)" />
        </template>

        <template v-slot:actions="{ props: { item } }">
          <v-menu :nudge-width="150" bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ $icons.dotsVertical }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-list-item link @click="openParcelEdit(item)"
                  :disabled="parcelIsPaid(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon class="icon-side-bar" small
                      :disabled="parcelIsPaid(item)">{{ $icons.check }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-side-bar">Receber Parcela</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="openParcelReverse(item)"
                 :disabled="!parcelIsPaid(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon class="icon-side-bar" color="primary" small
                      :disabled="!parcelIsPaid(item)">{{ $icons.cancel }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-side-bar">Estornar Parcela</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </DataTable>
    </ExpansionPanel>

    <Dialog :dialog="dialogModalParcel" no-title :maxWidth="1000">
      <ListParcelEdit slot="content"
        :credit-card-control-parcel-selected="creditCardControlParcelSelected"
        @closeParcel="closeParcelEdit" />
    </Dialog>

    <DialogConfirmation :dialog="dialogParcelReverse" :loading="loadingParcelReverse"
      :title="'Estornar Parcela'"
      :message="'Deseja Realmente Estornar a Parcela?'"
      @noActionDialogConfirmation="dialogParcelReverse = false"
      @yesActionDialogConfirmation="confirmParcelReverse" />
  </ModalCardDefault>
</template>

<script>
import {
  DataTable,
  ExpansionPanel,
  Chip,
  Dialog,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import ListParcelEdit from './ListParcelEdit';
import DialogConfirmation from '@/components/DialogConfirmation';

export default {
  name: 'ListParcels',
  components: {
    ModalCardDefault,
    DataTable,
    ExpansionPanel,
    Chip,
    Dialog,
    ListParcelEdit,
    DialogConfirmation,
  },
  props: {
    creditCardControlSelected: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      slotsItems: [
        { field: 'status_formatted' },
      ],
      creditCardControlParcelSelected: {},
      dialogModalParcel: false,
      dialogParcelReverse: false,
      loadingParcelReverse: false,
      parcels: [],
    };
  },
  computed: {
    headers () {
      return [
        {
          text: 'Parcela',
          value: 'number_formatted'
        },
        {
          text: 'Compensação',
          value: 'compensation_date_formatted'
        },
        {
          text: 'Banco',
          value: 'bank.description'
        },
        {
          text: 'Valor',
          value: 'value_formatted'
        },
        {
          text: 'Tarifa',
          value: 'fee_value_formatted'
        },
        {
          text: 'Tarifa Antecipação',
          value: 'anticipation_fee_value_formatted'
        },
        {
          text: 'Valor Final',
          value: 'final_value_formatted'
        },
        {
          text: 'Status',
          value: 'status_formatted'
        },
        {
          text: 'Ações',
          value: 'actions'
        },
      ];
    },
  },
  watch: {
    creditCardControlSelected () {
      this.getAllParcels();
    }
  },
  methods: {
    getAllParcels () {
      this.$api.creditCardControls.getAllParcels(this.creditCardControlSelected.id).then((res) => {
        this.parcels = res;
      });
    },
    getColorChip (status) {
      switch (status) {
      case 'paid':
        return 'success';
      case 'pending':
        return 'warning';
      default:
        return 'primary';
      }
    },
    openParcelEdit (item) {
      this.creditCardControlParcelSelected = item;
      this.dialogModalParcel = true;
    },
    openParcelReverse (item) {
      this.creditCardControlParcelSelected = item;
      this.dialogParcelReverse = true;
    },
    closeParcelEdit () {
      this.creditCardControlParcelSelected = {};
      this.dialogModalParcel = false;
      this.getAllParcels();
    },
    parcelIsPaid (item) {
      return item.status === this.$enums.statusCreditCardTransactionParcel.PAID;
    },
    confirmParcelReverse () {
      const payload = {
        number: this.creditCardControlParcelSelected.number,
        compensation_date: this.creditCardControlParcelSelected.compensation_date,
        bank_id: this.creditCardControlParcelSelected.bank_id,
        value: this.creditCardControlParcelSelected.value,
        fee_value: this.creditCardControlParcelSelected.fee_value,
        anticipation_fee_value: this.creditCardControlParcelSelected.anticipation_fee_value,
        final_value: this.creditCardControlParcelSelected.final_value,
        status: this.$enums.statusCreditCardControlParcel.REVERSED,
        is_anticipation: false,
      };
      this.$api.creditCardControls.updateParcel(
        this.creditCardControlParcelSelected.credit_card_control_id,
        this.creditCardControlParcelSelected.id,
        payload).then(() => {
        this.$noty.success('Parcela estronada com sucesso');
      }).catch(() => {
        this.$noty.error('Erro ao estronar parcela');
      }).finally(() => {
        this.creditCardControlParcelSelected = {};
        this.dialogParcelReverse = false;
        this.getAllParcels();
      });
    }
  },
  mounted () {
    this.getAllParcels();
  }
};
</script>

<style>

</style>
