import { Response } from 'miragejs';
import { dateTime } from '../../utils/dateTime';

export const update = (server) => {
  server.put('/companies/:id', (schema, { params, requestBody }) => {
    const company = find(params.id, schema);

    if (!company) {
      return new Response(404, {}, { errors: {} });
    }

    const attrs = JSON.parse(requestBody);
    const { name, email, cell_phone } = attrs;

    if ( !name || !email || !cell_phone ) {
      return new Response(422, {}, { errors: {
        name: 'The field name is required',
        email: 'The field email is required',
        cell_phone: 'The field cell_phone is required',
      } });
    }

    company.update({ name, email, cell_phone, updated_at: dateTime });
  });
};