import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';

export const getAll = (server) => {
  server.get('/nature_operations', (schema, request) => {
    const natureOperations = schema.natureOperations.all();
    const { page, perPage } = request.queryParams;

    natureOperations.models = softDelete(natureOperations);

    return pagination('nature_operations', natureOperations, page, perPage);
  });
};