/* eslint-disable */
import Vue from 'vue'
const { format, getDaysInMonth, startOfMonth, endOfMonth, getDayOfYear,
  getYear, getWeek } = require('date-fns');
import { formatInTimeZone } from 'date-fns-tz';

export const getText = (items, value) => {
  let text = '';
  items.forEach((item) => {
    if(item.value == value) text = item.text
  })

  return text;
}

export const getValue = (items, text) => {
  let value = '';
  items.forEach((item) => {
    if(item.text == text) value = item.value
  })

  return value;
}

export const formatCurrency = (value, params = false) => {
  value = parseFloat(value || 0);

  if(!params)
    return `R$ ${value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}`;

  if(params.type === 'percent')
    return `${value.toFixed(params.precision ? params.precision : 2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}%`;

  if(params.type === 'decimal')
    return `${value.toFixed(params.precision ? params.precision : 2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}`;

  if(params.type === 'no_decimal')
    return "R$ ";
}

export const formatDatePT = (date) => {
  if (date) {
    if (date.length >= 10) {
      return date.substring(8,10)+"/"+date.substring(5,7)+"/"+date.substring(0,4);
    }
  }
}

export const getHourInDateTime = (dateTime) => {
  return dateTime.substring(10,16)
}

export const formatPhone = (value) => {
  if(value){
    if(value.length === 10){
      return `(${value.substring(0,2)})${value.substring(2,6)}-${value.substring(6,10)}`;
    }else if(value.length === 11){
      return `(${value.substring(0,2)})${value.substring(2,7)}-${value.substring(7,11)}`;
    }else{
      return '-';
    }
  }

  return '-';
}

export const formatCpfCnpj = (value) => {
  if(value){
    if(value.length === 14) {
      return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(12, 14)}`;
    }else if(value.length === 11) {
      return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
    }
  }

  return '';
}

export const formatZipCode = (value) => {
  if(value){
    return `${value.substring(0, 2)}.${value.substring(2, 5)}-${value.substring(5, 8)}`;
  }

  return '-';
}

export const alertConfirmation = (title, text, type, showCancelButton = true) => {
  return new Promise((resolve, reject) => {
    Vue.prototype.$fire({
      title,
      text,
      type,
      showCancelButton,
    }).then((res) => {
      if(res.value === undefined) resolve(false);
      resolve(true);
    }).catch((err) => {
      reject(err);
    });
  })
}

export function formatDate(date, getHours = false) {
  if (!date) return null;
  const d = new Date(new Date(date).toUTCString().substr(0, 25));
  if (getHours) {
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} - ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  }
  return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
}

export function formatDateEN(date, getHours = false) {
  if (!date) return null;
  const d = new Date(new Date(date).toUTCString().substr(0, 25));

  if (getHours) {
    return `${d.getFullYear()}-${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0')} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  }

  return `${date.substring(6,10)}-${date.substring(3,5)}-${date.substring(0,2)}`;
}

export function newDateEN(date) {
  if (!date) return null;
  const d = new Date(new Date(date).toUTCString().substr(0, 25));
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
}

export function getTypeCustomer() {
  return getCustomer().type;
}

export function getTypeUser() {
  return getUser().type;
}

export function getCustomer() {
  return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.user`)).customer || {};
}

export function getCompany() {
  return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.user`)).company || {};
}

export function getCompanySettings() {
  return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.companySetting`)) || {};
}

export function getCompanyIdLogged() {
  return localStorage.getItem(`${process.env.VUE_APP_NAME}.companyIdLogged`) || '';
}

export function getUser() {
  return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.user`)) || {};
}

export function getUserSuper() {
  return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.super.user`)) || {};
}

export function getToken() {
  return localStorage.getItem(`${process.env.VUE_APP_NAME}.token`);
}

export function getTokenSuper() {
  return localStorage.getItem(`${process.env.VUE_APP_NAME}.super.token`);
}

export function messageErrors(err) {
  let message = '';

  if(err.status === 422 && err.data.errors){
    Object.keys(err.data.errors).forEach(function(key) {
      message += `<li class="item-error">${err.data.errors[key]}</li>`;
    });
  } else if(err.status === 422 || err.status === 400 && err.data.error){
    message += `<li class="item-error">${err.data.error}</li>`;
  }else{
    message += `<li class="item-error">Erro desconhecido, tente novamente.</li>`;
  }

  return message;
}

export function moneyOptions(precision = 2) {
  return {
    locale: "pt-BR",
    prefix: "",
    suffix: "",
    length: 11,
    precision: precision
  }
}

export function parseStringFloatDecimal(value) {
  value = value.replace('.', '')
  value = value.replace(',', '.')
  return value > 0;
}

export function verifyItemSigleVariation(productVariation) {
  if(productVariation.variation_values.length === 1) {
    return productVariation.variation_values[0].variation.global === 'yes' ? true : false
  }else{
    return false;
  }
}

export function limitCharacters(string, length = 50) {
  if(string.length <= length)
    return string;

  return `${string.substr(0, length)}...`;
}

export const isLogged = localStorage.getItem(`${process.env.VUE_APP_NAME}.access_token`) ? true : false;

export const typePageOptions = {
  list: 'list',
  create: 'create',
  show: 'show',
  statement: 'statement'
}

export function mountParamsRequestFilter(params, filter, search_global = false) {
  let payload = {};
  if(search_global) {
    return {
      ...params,
      page: params?.page || 1,
      per_page: params?.per_page || 10,
      filter,
      search_global,
      customFields: customFields.map((item) => {
        return { field: item }
      }
    )}
  }else{
    return {
      ...params,
      page: params?.page || 1,
      per_page: params?.per_page || 10,
      filter,
      search_global,
      customFields: Object.keys(customFields).customFields.map((key) => {
        // console.log(key)
        return { [key]: key }
      })
    }
  }
}

export function onlyNumbers(str) {
  return str.replace(/\D/g, "");
}

export function enableMirage() {
  return process.env.VUE_APP_MIRAGE === 'true';
}

export function getItemNumber (items) {
  return items.length + 1;
}

export const routeName = {
  'stock-movement': null,
  'order-sale': 'order-sale-show',
  'order-purchase': 'order-purchase-show',
  'order-budget': 'order-budget-show',
  'order-sale-devolution': 'order-sale-devolution-show',
  'order-purchase-devolution': 'order-purchase-devolution-show',
};

export function formatProductVariations(productVariations) {
  return productVariations.map((product_variation) => {
    const product_variation_values = product_variation.product_variation_values.map((item) => {
      return `${item.variation_value.variation.description}: ${item.variation_value.description}`;
    });

    product_variation_values.sort((a, b) => {
      return a.localeCompare(b);
    })

    return {
      id: product_variation.id,
      code: product_variation.code,
      bar_code: product_variation.bar_code,
      name: product_variation_values.length ? `${product_variation.name} - ${product_variation_values.join(', ')}` : product_variation.name,
      price: product_variation.price,
      price_formatted: formatCurrency(product_variation.price),
      price_wholesale: product_variation.price_wholesale,
      price_wholesale_formatted: formatCurrency(product_variation.price_wholesale),
      quantity: product_variation.quantity,
      quantity_formatted: formatCurrency(product_variation.quantity, { type: 'decimal' }),
      product_variation_values: product_variation.product_variation_values,
      product_variation_stocks: product_variation.product_variation_stocks,
      weight: product_variation.weight,
      reference: product_variation.product?.reference,
      ncm: product_variation.product?.ncm,
      cest: product_variation.product?.cest,
      unit_measure: product_variation.product?.unit_measure,
      gross_weight: product_variation.gross_weight,
      liquid_weight: product_variation.liquid_weight,
    };
  });
}

export const formatBooleanInYesNo = (value) => {
  return value ? 'yes' : 'no';
}

export const formatYesNoInBoolean = (value) => {
  return value === 'yes' || false;
}

export const transformArrayToListSelect = (arr, params = ['name', 'id']) => {
  return arr.map((item) => {
    return {
      text: item[params[0]],
      value: item[params[1]],
      ...item.value && { item_value: item.value },
      ...params.includes('all') && { item },
    };
  });
}

export const removeDefaultKeysPayload = (data, removeNullable = true) => {
  const remove = (item) => {
    delete item.company_id;
    delete item.situation;
    delete item.created_by;
    delete item.updated_by;
    delete item.deleted_by;
    delete item.created_at;
    delete item.updated_at;
    delete item.deleted_at;

    if (removeNullable) {
      Object.keys(item).forEach((key) => {
        if (!item[key]) {
          delete item[key];
        }
      });
    }
  }

  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item) => {
        removeDefaultKeysPayload(item, removeNullable)
      })
    } else if (typeof data[key] !== 'string' && typeof data[key] !== 'number' && typeof data[key] !== 'boolean') {
      if (data[key]) {
        removeDefaultKeysPayload(data[key], removeNullable);
      }
    } else {
      remove(data);
    }
  });

  return data;
}

export const getHour = () => {
  return new Date().toLocaleTimeString();
}

export const checkUuid = (uuid) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuid)
}

export const translateOperationTypeSefaz = (operationType) => {
  return operationType === operationType.INPUT ? 0 : 1;
}

export const insertEmptyItemOptions = (items, keys = { text: '', value: null }) => {
  if (items.length === 0) {
    return items;
  }
  return [keys, ...items];
}

export const now = () => {
  return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
}

export const randomNumber = () => {
  return Math.floor(Math.random() * 100);
}

export const getCurrentPeriod = () => {
  const date = new Date();
  const tz = 'America/Sao_Paulo';
  const currentDateEN = formatInTimeZone(date, tz, 'yyyy-MM-dd');
  const currentDateTimeEN = formatInTimeZone(date, tz, 'yyyy-MM-dd H:mm:ss');
  const currentDateBR = formatInTimeZone(date, tz, 'dd/MM/yyyy');
  const currentDateTimeBR = formatInTimeZone(date, tz, 'dd/MM/yyyy H:mm:ss');
  const currentMonth = formatInTimeZone(date, tz, 'MM');
  const numberOfDays = getDaysInMonth(date);
  const startPeriod = format(startOfMonth(date), 'yyyy-MM-dd');
  const endPeriod = currentDateEN;
  const startPeriodFormatted = format(startOfMonth(date), 'dd/MM/yyyy');
  const endPeriodFormatted = currentDateBR;
  const currentDayOfYear = getDayOfYear(date);
  const currentWeek = getWeek(date);
  const currentYear = getYear(date);

  return {
    currentDateEN,
    currentDateTimeEN,
    currentDateBR,
    currentDateTimeBR,
    currentMonth,
    numberOfDays,
    currentDayOfYear,
    currentWeek,
    currentYear,
    currentPeriod: {
      start: startPeriod,
      end: endPeriod,
      startFormatted: startPeriodFormatted,
      endFormatted: endPeriodFormatted,
    }
  }
}
