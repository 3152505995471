<template>
  <div>
     <ModalCardDefault :title="l.title" @close="$emit('close')">
        <ExpansionPanel v-model="panel" :title="'Variações'" :icon="$icons.box" readonly>
          <Row class="">
            <Col v-for="(variation, index) in variations" :key="index" md="4">
              <Select v-model="selectVariationValue[variation.id]" :label="variation.description" :items="variation.values"
                item-value="id" item-text="description" @change="setFilter($event)" return-object />
            </Col>
            <Col>
              <Button :label="'Limpar Pesquisa'" small class="mr-3 mt-1" rounded color="success"
              @click="clearFilters()"/>
            </Col>
          </Row>
          <DataTable no-search :items-per-page="5" :items="productVariations"
            :headers="this.schema.variations.list.headers" :loading="loadingDataTable"
            @getItems="getProductVariations" :slots="listSlots">
            <template v-slot:name="{ props: { item } }">
              <span v-html="item.name" />
            </template>
            <template v-slot:actions="{ props }">
              <v-menu :nudge-width="230" :nudge-left="35" :nudge-top="10" bottom left offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ $icons.dotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-list dense>
                    <v-list-item link @click="openModal({ type: typeComponentModal.EDIT_VARIATION, item: props.item })">
                      <v-list-item-icon class="mr-2">
                        <v-icon color="warning" small class="icon-side-bar">{{ $icons.edit }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-side-bar">Editar Variação</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="openModal({ type: typeComponentModal.LIST_STOCK_MOVEMENTS, item: props.item })">
                      <v-list-item-icon class="mr-2">
                        <v-icon color="secondary" small class="icon-side-bar">{{ $icons.swapHorizontal }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-side-bar">Movimentações da Variação</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="openModal({ type: typeComponentModal.LIST_VARIATION_STOCKS, item: props.item })">
                      <v-list-item-icon class="mr-2">
                        <v-icon color="secondary" small class="icon-side-bar">{{ $icons.box }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-side-bar">Ver Estoque</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="openModal({ type: typeComponentModal.DELETE_VARIATION, item: props.item })">
                      <v-list-item-icon class="mr-2">
                        <v-icon color="primary" small class="icon-side-bar">{{ $icons.destroy }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-side-bar">Excluir Variação</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </template>
          </DataTable>

        </ExpansionPanel>
        <Row class="mt-5 custom-footer">
          <Col md="3">
            <Alert color="primary" class="py-2">
              <h5><b>Total de Variações</b></h5>
              <h4>{{ totalVariations }}</h4>
            </Alert>
          </Col>
          <Col md="3">
            <Alert color="primary" class="py-2">
              <h5><b>Total em Estoque</b></h5>
              <h4>{{ totalStock }}</h4>
            </Alert>
          </Col>
          <Col md="3">
            <Alert color="primary" class="py-2">
              <h5><b>Total Valor Varejo</b></h5>
              <h4>{{ totalPrice }}</h4>
            </Alert>
          </Col>
          <Col md="3">
            <Alert color="primary" class="py-2">
              <h5><b>Total Valor Atacado</b></h5>
              <h4>{{ totalPriceWholesale }}</h4>
            </Alert>
          </Col>
        </Row>

        <div slot="actions" class="d-flex justify-end" style="width: 100%">
          <Button :label="l.actions.create" class="mr-3" rounded color="secondary"
            @click="openModal({ type: typeComponentModal.CREATE_VARIATION })" />
        </div>
     </ModalCardDefault>

    <Dialog :dialog="dialogModal" no-title :maxWidth="propsModal.maxWidth">
      <component :is="componentModal" slot="content" v-bind="propsModal"
        :key="keyModal" v-on="eventsModal" @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import {
  DataTable,
  ButtonIcon,
  Button,
  Dialog,
  ExpansionPanel,
  Row,
  Col,
  Alert,
  Select,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateVariation from './CreateVariation';
import EditVariation from './EditVariation';
import DeleteVariation from './DeleteVariation';
import ListVariationStocks from './ListVariationStocks';
import ListProductVariationStockMovements from './ListProductVariationStockMovements';
import { typeComponentModalVariations } from '@/utils/enums';
import { randomNumber, formatCurrency } from '@/utils';
import { clone } from 'lodash';

const COMPONENT_MODAL = Object.freeze({
  [typeComponentModalVariations.CREATE_VARIATION]: CreateVariation,
  [typeComponentModalVariations.EDIT_VARIATION]: EditVariation,
  [typeComponentModalVariations.DELETE_VARIATION]: DeleteVariation,
  [typeComponentModalVariations.LIST_VARIATION_STOCKS]: ListVariationStocks,
  [typeComponentModalVariations.LIST_STOCK_MOVEMENTS]: ListProductVariationStockMovements,
});

export default {
  name: 'ListVariations',
  components: {
    DataTable,
    ButtonIcon,
    Button,
    Dialog,
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    Alert,
    Select,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    variations: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      panel: [0],
      dialogModal: false,
      loadingDataTable: false,
      componentModal: null,
      listSlots: [
        { field: 'name' },
        { field: 'actions' },
      ],
      productVariations: [],
      propsModal: {},
      eventsModal: {},
      selectVariationValue: {},
      keyModal: 0,
      filters: [],
      totalVariations: 0,
      totalStock: 0,
      totalPrice: 0,
      totalPriceWholesale: 0,
    };
  },
  computed: {
    schema () {
      return this.$schemas.product;
    },
    l () {
      return this.$locales.pt.products.list.modalVariations;
    },
    typeComponentModal () {
      return typeComponentModalVariations;
    },
  },
  watch: {
    product: {
      handler () {
        this.getProductVariations();
      },
      deep: true,
    },
  },
  methods: {
    getProductVariations(pagination) {
      this.loadingDataTable = !this.loadingDataTable;
      this.$api.products.getAllProductVariationsByProductId(this.product.id, this.filters, pagination).then((res) => {
        this.productVariations = res;
        this.totalVariations = res.total_variations;
        this.totalStock = res.total_stock;
        this.totalPrice = formatCurrency(res.total_price);
        this.totalPriceWholesale = formatCurrency(res.total_price_wholesale);
      }).catch(() => {
        this.$noty.error(this.l.noty.errorGetAllVariations);
      }).finally(() => {
        this.loadingDataTable = !this.loadingDataTable;
      });
    },
    async setFilter(item) {
      function addOrReplace(filters, newItem) {
        let alreadyExists = {};

        filters.forEach(filter => {
          alreadyExists[filter.variation_id] = filter;
        });
        alreadyExists[newItem.variation_id] = newItem;

        return Object.values(alreadyExists);
      }
      this.filters = addOrReplace(this.filters, { description: item.description, variation_id: item.variation_id, id: item.id });
      this.getProductVariations();
    },
    openModal(params) {
      this.keyModal = randomNumber();
      this.componentModal = COMPONENT_MODAL[params.type];
      this.dialogModal = true;
      this.propsModal = {
        ...params.type === this.typeComponentModal.CREATE_VARIATION && { maxWidth: 1200, product: this.product },
        ...params.type === this.typeComponentModal.EDIT_VARIATION && { maxWidth: 850, productVariation: params.item },
        ...params.type === this.typeComponentModal.DELETE_VARIATION && { maxWidth: 450, id: params.item.id },
        ...params.type === this.typeComponentModal.LIST_VARIATION_STOCKS && { maxWidth: 600, productVariationId: params.item.id },
        ...params.type === this.typeComponentModal.LIST_STOCK_MOVEMENTS && {
          maxWidth: 1100,
          title: this.$locales.pt.products.list.modalMovements.title.replace('$productVariation', params.item.name),
          productVariationId: params.item.id,
          headers: clone(this.$schemas.product.list.movements.headers),
          productVariation: params.item,
        },
      };

      if (params.type === this.typeComponentModal.LIST_STOCK_MOVEMENTS) {
        this.propsModal.headers.pop();
      }
      this.eventsModal = {
        ...params.type === this.typeComponentModal.CREATE_VARIATION && { getProductVariations: this.getProductVariations },
        ...params.type === this.typeComponentModal.DELETE_VARIATION && { delete: this.delete },
      };
    },
    delete (productVariationId) {
      this.$api.products.deleteProductVariation(productVariationId).then(() => {
        this.$noty.success(this.l.noty.successDeleteVariation);
        this.getProductVariations();
      }).catch((error) => {
        let message = this.$locales.pt.default.alerts.error;

        if (error.response.status === 422) {
          message = error.response.data.error;
        }
        this.$noty.error(message);
      }).finally(() => {
        this.dialogModal = false;
      });
    },
    clearFilters() {
      this.filters = [];
      this.selectVariationValue = {};
      this.getProductVariations();
    },
    closeModal(refreshList = false) {
      if (refreshList) {
        this.getProductVariations();
      }
      this.keyModal = 0;
      this.dialogModal = false;
    }
  },
};
</script>
