<template>
  <v-card :class="['mt-2 py-3 px-3 page-content']" :color="$store.getters.getDarkMode.value ? '#333' : ''" elevation="2">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'PageContent',
  props: {
    schema: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      modeDark: {
        value: false,
        label: 'Modo Escuro'
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  min-height: calc(100vh - 265px);
  border-radius: 25px;
  background-color: #FFF;
  padding: 25px !important;
}

@media only screen and (min-width: 320px) and (max-width: 1280px) {
  .page-content {
    min-height: calc(100vh - 200px);
    border-radius: 10px;
    padding: 15px !important;
  }
}
</style>
