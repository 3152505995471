import { typePageOptions } from '@/utils';
import { text, numeric, list } from '@/utils/icons';
import { required } from '@/utils/rules';
import { typeNf, environmentNf, modelNf } from '@/utils/options';
import InvoiceSettingsBusiness from '@/business/InvoiceSettingsBusiness';

export default {
  domain: 'invoice_settings',
  domainSingle: 'invoice_setting',
  business: InvoiceSettingsBusiness,
  title: 'Configurações Fiscais',
  description: 'Lista de todas as Configurações Fiscais do sistema',
  createBtn: 'Nova Configuração',
  showInactive: false,
  routes: {
    list: {
      name: 'invoice-settings',
      path: '/configuracoes-fiscais',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'invoice-setting-create',
      path: '/configuracoes-fiscais/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'invoice-setting-show',
      path: '/configuracoes-fiscais/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Inutilizar Números',
          icon: numeric,
          action: 'disableNumbers',
          component: 'DisableNumbers'
        },
        {
          label: 'Eventos Fiscais',
          icon: list,
          action: 'invoiceEvents',
          component: 'ListEvents'
        }
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Configuração Fiscal',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'invoice_type',
          formattedName: 'invoice_type_formatted',
          label: 'Tipo da Nota',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          items: typeNf,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'select',
          name: 'environment',
          formattedName: 'environment_formatted',
          label: 'Ambiente',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          items: environmentNf,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'select',
          name: 'model',
          formattedName: 'model_formatted',
          label: 'Modelo',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          items: modelNf,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'integer',
          name: 'series',
          formattedName: 'series',
          label: 'Séries',
          default: 1,
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'integer',
          name: 'current_number',
          formattedName: 'current_number',
          label: 'Número Atual',
          default: 1,
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'cid_token',
          formattedName: 'cid_token',
          label: 'Cid Token',
          align: '',
          md: '2',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'csc',
          formattedName: 'csc',
          label: 'CSC',
          align: '',
          md: '4',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'checkBox',
          name: 'main',
          formattedName: 'main_formatted',
          label: 'Principal',
          align: '',
          md: '12',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [],
  listEvents: {
    headers: [
      {
        text: 'Data',
        value: 'created_at',
      },
      {
        text: 'Evento',
        value: 'type',
      },
      {
        text: 'Código',
        value: 'status_code',
      },
      {
        text: 'Messagem',
        value: 'message',
      },
      {
        text: 'Protocolo',
        value: 'protocol',
      }
    ]
  }
};
