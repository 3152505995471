export default {
  modalVariations: {
    title: 'Lista das Variações',
    tooltips: {
      listStockMovements: 'Ver Movimentações',
      listVariationStocks: 'Ver Estoques',
      deleteVariation: 'Excluir Variação',
    },
    actions: {
      create: 'Nova Variação',
      close: 'Fechar',
    },
    noty: {
      successDeleteVariation: 'Variação excluída com Sucesso',
      errorGetAllVariations: 'Erro ao listar Variações.',
      errorDeleteVariation: 'Erro ao excluir a Variação.',
    },
    createVariation: {
      title: 'Nova Variação',
      actions: {
        create: 'Salvar',
        close: 'Fechar',
        insert: 'Inserir',
      },
      fields: {
        labels: {
          variation: 'Selecione a Variação',
          stock: 'Selecione o Estoque',
          priceList: 'Sel. Lista de Preços',
          typeAdjustment: 'Tipo de Ajuste',
          typeValue: 'Tipo de Valor',
          finalValue: 'Valor Final',
          value: 'Selecione o Valor da Variação',
          code: 'Código',
          name: 'Nome',
          reference: 'Referência',
          bar_code: 'Cod. Barras',
          price: 'Preço Varejo',
          priceWholesale: 'Preço Atacado',
          quantity: 'Qtde.',
          liquidWeight: 'Peso Líquido (gramas)',
          grossWeight: 'Peso Bruto (gramas)',
        }
      },
      noty: {
        successCreateVariation:  'Variação Criada com Sucesso',
        errorGetVariations:  'Erro ao pegar as Variações.',
        errorGetStocks:  'Erro ao pegar os Estoques.',
        errorGetVariationValues:  'Erro ao pegar os Valores das Variações.',
        variationValueAlreadyExists:  'A Variação $variation já foi inserida.',
        variationStockAlreadyExists:  'O Estoque $stock já foi inserido.',
        errorVariationNotEmpty:  'Você deve inserir pelo menos uma Variação',
        errorStockNotEmpty:  'Você deve inserir pelo menos um Estoque',
        errorCreateVariation: 'Erro ao criar a Variação',
        errorStockRequired: 'O Estoque é obrigatório',
      }
    },
    deleteVariation: {
      title: 'Excluir Variação?',
      messageConfirmation: 'Deseja realente excluir a Variação?',
      actions: {
        yes: 'Sim',
        no: 'Não',
      },
    },
    viewVariationStocks: {
      title: 'Estoques',
      actions: {
        close: 'Fechar',
      },
      noty: {
        errorGetProductVariationStocks:  'Erro ao listar os Estoques.',
      }
    }
  },
  modalMovements: {
    title: 'Movimentações do Produto: $productVariation',
  }
};
