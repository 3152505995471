<template>
  <v-navigation-drawer v-model="drawer" :absolute="height < 500"
    :permanent="height >= 500" elevation="1" :class="['content-sidebar', $store.getters.getDarkMode.value ? 'side-bar-dark-mode' : 'side-bar-no-dark-mode']">
    <template v-slot:prepend>
      <v-list-item two-line>
        <!-- <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/women/81.jpg">
        </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title style="color: #FFFFFF">{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle><Chip label="Ativo" x-small color="success" /></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card :class="['mx-auto content-sidebar', $store.getters.getDarkMode.value ? 'side-bar-active-dark-mode' : 'side-bar-active-no-dark-mode']" style="height: 100%;" elevation="0" tile>
      <v-list dense>
        <v-list-group v-model="item.active" v-for="item in items" :key="item.title" no-action dense class="mt-2">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :title="item.subtitle" style="color: #FFFFFF">
                <Icon :icon="$icons[item.icon]" class="mr-3 icon-menu" dense />
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :class="[getClassMenuActive(child.active), $store.getters.getDarkMode.value ? 'side-bar-dark-mode' : 'side-bar-no-dark-mode']" :key="child.title" link @click="menuNavigator(item, child)">
            <v-list-item-content>
              <v-list-item-title color="primary" :prepend-icon="item.icon">
                <Icon :icon="$icons[child.icon]" class="mr-3 icon-menu" dense />
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>

  </v-navigation-drawer>
</template>

<script>
import Icon from '@/components/vuetify/Icon';
import { Chip } from '@/components/vuetify';
import BreakPointMixin from '@/mixins/BreakPointMixin';
import items from '../items';
import { getUser } from '@/utils';

export default {
  name: 'SideBar',
  components: { Icon, Chip },
  props: {
    modeDark: {
      type: Object,
      default: () => {},
      require: true
    }
  },
  data() {
    return {
      items: items,
      drawer: false,
    };
  },
  mounted() {
    this.openMenuGroup();
  },
  computed: {
    user () {
      return getUser();
    }
  },
  mixins: [BreakPointMixin],
  methods: {
    menuNavigator(item, child) {
      localStorage.setItem(`${process.env.VUE_APP_NAME}.menuGroupName`, item.menuGroupName);

      this.items.forEach((i) => {
        i.items.forEach((c) => {
          c.active = false;
        });
      });

      item.active = true;
      child.active = true;

      this.$router.push({ name: child.route, query: child.params?.query, }).catch(() => {
      });
    },
    drawerEvent() {
      this.drawer = !this.drawer;
    },
    getClassMenuActive(value) {
      if (value) {
        return this.modeDark.value ? 'childActiveDark' : 'childActiveLight';
      }

      return '';
    },
    openMenuGroup () {
      const menuGroupName = localStorage.getItem(`${process.env.VUE_APP_NAME}.menuGroupName`);

      if (menuGroupName) {
        this.items.forEach((item) => {
          if (item.menuGroupName === menuGroupName) {
            item.active = true;
            item.items.forEach((c) => {
              c.active = false;
              if (c.route === this.$route.name || c.route === this.$route.meta.childrenRoute) c.active = true;
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.side-bar-active-dark-mode {
  background-color: #333 !important;
  color: #FFFFFF;
}

.side-bar-active-no-dark-mode {
  background-color: #292F4C !important;
  color: #FFFFFF;
}

.side-bar-dark-mode {
  background-color: #222 !important;
  color: #FFFFFF;
}

.side-bar-no-dark-mode {
  background-color: #292F4C !important;
  color: #FFFFFF;
}

.content-sidebar {
  border-right: 1px solid #292F4C;
  z-index: 2;
  min-width: 230px !important;
  min-height: calc(100vh - 155px) !important;
}

.v-list-item--active {
  border-left: 5px solid #FF3D57;
  background-color: #10131F;
  color: #FFF !important;
}
.item-active {
  background: #ebebeb !important;
  color: #FFF !important;
}

.v-list-group__items > .v-list-item {
  padding-left: 35px !important;
  color: #FFF !important;
  background-color: #30405b;
}

.childActiveLight {
  background-color: #3e4a60 !important;
  color: #FFF !important;
  border-left: 3px solid #FFCC02;
}

.childActiveDark {
  background-color: #666666;
  color: #FFF !important;
}

.icon-menu {
  color: #FFF !important;
}
</style>
