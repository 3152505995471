import { typePageOptions } from '@/utils';
import { text, box, plusOutline } from '@/utils/icons';
import { required, money } from '@/utils/rules';
import { typeAdjustmentPriceList, typeYesNoBoolean, typeValuePriceList } from '@/utils/options';
import { getCurrentPeriod } from '@/utils';
import PriceListBusiness from '@/business/PriceListBusiness';

export default {
  domain: 'price_lists',
  domainSingle: 'price_list',
  business: PriceListBusiness,
  title: 'Lista de Preços',
  description: 'Lista de todos as Lista de Preços do sistema',
  createBtn: 'Nova Lista de Preços',
  showInactive: false,
  showExpand: true,
  routes: {
    list: {
      name: 'priceLists',
      path: '/lista-precos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'priceListCreate',
      path: '/lista-precos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'priceListShow',
      path: '/lista-precos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          icon: box,
          name: 'productsList',
          label: 'Produtos',
          action: 'productsList'
        },
        {
          icon: plusOutline,
          name: 'addProduct',
          label: 'Adicionar Produto',
          action: 'addProduct'
        },
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Lista dee Preço',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'category_ids',
          formattedName: 'categories',
          label: 'Categoria',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=category&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id',
          clearable: true,
          multiple: true,
          contentExpanded: true,
        },
        {
          type: 'select',
          name: 'sub_category_ids',
          formattedName: 'sub_categories',
          label: 'Sub Categoria',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=sub-category&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id',
          clearable: true,
          multiple: true,
          contentExpanded: true,
        },
        {
          type: 'money',
          name: 'value_min',
          formattedName: 'value_min_formatted',
          label: 'Valor Mínimo Produto',
          align: '',
          md: '2',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'value_max',
          formattedName: 'value_max_formatted',
          label: 'Valor Máximo Produto',
          align: '',
          md: '2',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'dataPicker',
          name: 'start_date',
          formattedName: 'start_date_formatted',
          label: 'Data Inicial',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          itemValueDefault: true,
          default: getCurrentPeriod().currentDateEN,
        },
        {
          type: 'dataPicker',
          name: 'end_date',
          formattedName: 'end_date_formatted',
          label: 'Data Final',
          align: '',
          md: '2',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'type_adjustment',
          formattedName: 'type_adjustment',
          label: 'Tipo de Ajuste',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: typeAdjustmentPriceList,
        },
        {
          type: 'select',
          name: 'all_products',
          formattedName: 'all_products_formatted',
          label: 'Todos os Produtos',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          items: typeYesNoBoolean,
          itemValueDefault: true,
          default: false,
        },
        {
          type: 'select',
          name: 'type_value',
          formattedName: 'type_value',
          label: 'Tipo Valor',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: typeValuePriceList,
        },
        {
          type: 'money',
          name: 'value_with_adjustment',
          formattedName: 'value_with_adjustment_formatted',
          label: 'Valor Desconto/Acréscimo',
          align: '',
          md: '3',
          rules: [required, money],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: '',
          length: 11,
          precision: 2,
          empty: null,
        },
      ],
    },
  ],
  footer: [],
};
