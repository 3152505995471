import { routes as auth } from '@/views/Auth';
import { routes as customers } from '@/views/Settings/Customers';
import { routes as companies } from '@/views/Settings/Companies';
import { routes as companySettings } from '@/views/Settings/CompanySettings';
import { routes as subscriptions } from '@/views/Settings/Subscriptions';
import { routes as natureOperations } from '@/views/Settings/NatureOperations';
import { routes as invoiceSettings } from '@/views/Settings/InvoiceSettings';
import { routes as home } from '@/views/Home/Dashboard';
import { routes as resellers } from '@/views/Home/Resellers';
import { routes as users } from '@/views/Settings/Users';
import { routes as allTypes } from '@/views/AllTypes';
import { routes as registers } from '@/views/GeneralRegisters/Registers';
import { routes as typeInstallments } from '@/views/GeneralRegisters/TypeInstallments';
import { routes as accountingAccounts } from '@/views/GeneralRegisters/AccountingAccounts';
import { routes as costCenters } from '@/views/GeneralRegisters/CostCenters';
import { routes as stocks } from '@/views/Products/Stocks';
import { routes as variations } from '@/views/Products/Variations';
import { routes as products } from '@/views/Products/Products';
import { routes as priceLists } from '@/views/Products/PriceLists';
import { routes as stockMovements } from '@/views/Products/StockMovements';
import { routes as productVariationStockMovements } from '@/views/Products/ProductMovements';
import { routes as balances } from '@/views/Products/Balances';
import { routes as orders } from '@/views/Movements/Orders';
import { routes as orderServices } from '@/views/Movements/OrderServices';
import { routes as tables } from '@/views/Operations/Tables';
import { routes as accounts } from '@/views/Financial/Accounts';
import { routes as financialMovements } from '@/views/Financial/Movements';
import { routes as banks } from '@/views/Financial/Banks';
import { routes as boxes } from '@/views/Financial/Boxes';
import { routes as openTitles } from '@/views/Financial/OpenTitles';
import { routes as creditCardAdministrators } from '@/views/Financial/CreditCardAdministrators';
import { routes as creditCardControls } from '@/views/Financial/CreditCardControls';
import { routes as checkControls } from '@/views/Financial/CheckControls';
import { routes as billetControls } from '@/views/Financial/BilletControls';
import { routes as creditLetters } from '@/views/Financial/CreditLetters';
import { routes as productInvoices } from '@/views/TaxOperations/ProductInvoices';
import { routes as serviceInvoices } from '@/views/TaxOperations/ServiceInvoices';
import { routes as pdv } from '@/views/Operations/PDV';
import { routes as cashFlows } from '@/views/Planning/CashFlows';
import { routes as reports } from '@/views/Reports';

export default [
  ...auth,
  ...customers,
  ...companies,
  ...companySettings,
  ...subscriptions,
  ...natureOperations,
  ...invoiceSettings,
  ...home,
  ...resellers,
  ...users,
  ...allTypes,
  ...registers,
  ...typeInstallments,
  ...accountingAccounts,
  ...costCenters,
  ...stocks,
  ...variations,
  ...products,
  ...priceLists,
  ...stockMovements,
  ...productVariationStockMovements,
  ...balances,
  ...orders,
  ...orderServices,
  ...tables,
  ...accounts,
  ...financialMovements,
  ...banks,
  ...boxes,
  ...openTitles,
  ...creditCardAdministrators,
  ...creditCardControls,
  ...checkControls,
  ...billetControls,
  ...creditLetters,
  ...productInvoices,
  ...serviceInvoices,
  ...pdv,
  ...cashFlows,
  ...reports,
];
