<template>
  <v-menu v-model="menu" :max-width="650" bottom left offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center mb-3">
        <Chip
          :label="qtdFilters.toString()" x-small color="success"
          class="mr-2" />
        <Button v-bind="attrs" v-on="on" label="FILTROS" text :icon="$icons.filter"
          @click="menu = !menu" />
      </div>
    </template>

    <v-card>
      <v-card-title>
        <h3>Filtro de pesquisa</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" :md="item.md" :lg="item.md"
            v-for="(item, index) in items" :key="index" class="columns_search_list_page">
            <component
              :is="getComponent(item)"
              v-bind="getPropsComponent(item)"
              v-model="localItem[item.name]"
              @keyup.enter="handleSubmit()" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-4 py-4 d-flex justify-end">
        <Button label="Limpar" text color="primary" @click="clearFilters" />
        <Button label="Pesquisar" color="primary" :icon="$icons.search" @click="handleSubmit()" />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash';
import { getCurrentPeriod, getCompanySettings } from '@/utils';
import { Select, Chip, Button, DataPicker, TextField} from '@/components/vuetify';
import TextFieldSimpleMask from '@/components/vuetify/TextFieldSimpleMask';

const TYPES_COMPONENT = Object.freeze({
  text: TextField,
  select: Select,
  dataPicker: DataPicker,
  simpleMask: TextFieldSimpleMask,
});

export default {
  name: 'SearchListPage',
  components: { Button, Chip },
  props: {
    schema: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemsSelectsFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      menu: false,
      localItem: {},
      qtdFilters: 0,
    };
  },
  computed: {
    companySettings() {
      return getCompanySettings();
    },
  },
  methods: {
    getComponent(item) {
      return TYPES_COMPONENT[item.type];
    },
    setDefaultValuesFilters () {
      let betweenFilters = {};

      const setData = (key, value) => {
        if (this.companySettings.default_periods_filters) {
          if (this.companySettings.default_periods_filters[this.schema.domainSingle] === 'daily') {
            value = getCurrentPeriod().currentPeriod.end;
          }
        }

        _.set(this.localItem, key, value);
      };

      const setBetweenFilters = (key, value) => {
        betweenFilters[key] = value;
      };

      if (Object.keys(this.$route.query).length === 0) {
        this.items.forEach((item) => {
          if (item.isBetween) {
            setData(item.name, item.defaultValue);
            setBetweenFilters(item.name, item.defaultValue);
          }
        });
      } else {
        Object.keys(this.$route.query).forEach((key) => {
          this.items.forEach((item) => {
            if (item.name === key && item.isBetween) {
              setData('initial_date', this.$route.query.initial_date);
              setData('final_date', this.$route.query.final_date);
              setBetweenFilters(key, this.$route.query[key]);
            } else if (item.name === key && !item.isBetween) {
              setData(key, this.$route.query[key]);
            }
          });
        });
      }

      this.routerReplace({
        ...betweenFilters,
        ...this.schema.filters.isBetween && {
          is_between: true,
          column_between: 'created_at'
        },
        ...this.localItem,
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10
      });

      this.$emit('searchItems', {
        filters: this.$route.query
      });
      this.setQtdFilters();
    },
    getPropsComponent(item) {
      return {
        label: item.label,
        icon: item.icon,
        ...item.type === 'text' && { type: item.type, },
        ...item.type === 'select' && { items: this.itemsSelectsFilters[item.name], clearable: true },
        ...item.type === 'dataPicker' && { noInitial: true, },
        ...item.type === 'simpleMask' && { name: item.name, clearable: item.clearable, inputMask: item.inputMask, outputMask: item.outputMask, applyAfter: item.applyAfter, empty: item.empty, alphanumeric: item.alphanumeric },
      };
    },
    handleSubmit (changeAction = false) {
      if (!changeAction) {
        this.menu = false;
      }

      if (this.schema.business != undefined && typeof this.schema.business.beforeFilters === 'function') {
        this.schema.business.beforeFilters({
          form: this.localItem,
          schema: this.schema
        });
      }

      this.routerReplace({
        ...this.$route.query,
        ...this.localItem,
      });
      this.$emit('searchItems', {
        filters: this.$route.query
      });
      this.setQtdFilters();
    },
    clearFilters() {
      Object.keys(this.items).forEach((key) => {
        if (!this.items[key].isBetween) {
          this.localItem[this.items[key].name] = '';
        }

        if (!this.localItem[this.items[key].name]) {
          delete this.localItem[this.items[key].name];
        }
      });

      if (this.schema.filters.isBetween) {
        this.localItem.initial_date = getCurrentPeriod().currentPeriod.start;
        this.localItem.final_date = getCurrentPeriod().currentPeriod.end;
      }
      this.routerReplace({
        ...this.localItem,
        ...this.schema.filters.isBetween && {
          is_between: true,
          column_between: 'created_at'
        }
      });
      this.qtdFilters = Object.keys(this.localItem).length;
      this.menu = false;
      this.handleSubmit();
    },
    routerReplace (query) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query
      }).catch(() => {
      });
    },
    setQtdFilters () {
      this.qtdFilters = 0;
      Object.keys(this.localItem).forEach((key) => {
        if (this.localItem[key]) {
          this.qtdFilters++;
        }
      });
    },
  },
  async mounted () {
    this.setDefaultValuesFilters();
  }
};
</script>
<style lang="scss">
.columns_search_list_page {
  .v-text-field__details {
    display: none !important;
  }
}
</style>
