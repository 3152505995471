import { baseFields } from '../../utils/baseFields';
import { Response } from 'miragejs';

export const create = (server) => {
  server.post('/nature_operations', (schema, { requestBody }) => {
    const attrs = JSON.parse(requestBody);
    const { name, type, movement_stock } = attrs;

    if ( !name || !type || !movement_stock ) {
      return new Response(422, {}, { errors: {
        name: 'The field name is required',
        type: 'The field type is required',
        movement_stock: 'The field movement_stock is required',
      } });
    }

    const nature_operation = {
      ...attrs,
      ...baseFields,
    };

    const response = schema.natureOperations.create(nature_operation);

    return { nature_operation: response.attrs };
  });
};