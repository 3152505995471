export default {
  movementType: {
    input: 'Entrada',
    output: 'Saída'
  },
  tooltips: {
    origin: 'Abrir Origem',
  },
  origin: {
    'stock-movement': 'Manual',
    'order-sale': 'Pedido de Vendas',
    'order-purchase': 'Pedido de Compras',
    'order-sale-devolution': 'Devolução de Vendas',
    'order-purchase-devolution': 'Devolução de Compras',
    'order-sale-pdv': 'PDV',
  }
};
