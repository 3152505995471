import { getCurrentPeriod, getHour } from '@/utils';

class TableSale {
  get model () {
    return {
      table_id: null,
      employee_id: null,
      client_id: null,
      date_hour_open: getCurrentPeriod().currentDateTimeEN,
      hour_open: getHour(),
      date_hour_closed: '',
      hour_closed: '',
      client_qtd: 1,
      total_value: 0,
    };
  }
  get validations () {
    return {};
  }
  get validationsErrors () {
    return {};
  }
  get reset() {
    return {
      ...this.model,
    };
  }
}

export default new TableSale();
