var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalCardDefault',{attrs:{"title":"Produtos"},on:{"close":_vm.close}},[_c('ExpansionPanel',{attrs:{"title":"Produtos do Balanço","icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('Row',[_c('Col',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.measureByBarCode.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('TextField',{ref:"RefBarCode",attrs:{"label":"Código de barras","rules":[_vm.rules.required]},model:{value:(_vm.barCode),callback:function ($$v) {_vm.barCode=$$v},expression:"barCode"}}),_c('CheckBox',{attrs:{"label":"Somente os produtos faltantes"},on:{"setCheckBox":_vm.setFilterMissingProducts},model:{value:(_vm.filterMissingProducts),callback:function ($$v) {_vm.filterMissingProducts=$$v},expression:"filterMissingProducts"}})],1)],1)],1),_c('Row',[_c('Col')],1),_c('DataTable',{attrs:{"no-search":"","items":_vm.balanceItems,"headers":_vm.headers,"items-per-page":5,"loading":_vm.loading,"slots":_vm.slotsItems},on:{"getItems":_vm.getBalanceItems},scopedSlots:_vm._u([{key:"price_retail",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(_vm.utils.formatCurrency(item.price_retail))+" ")]}},{key:"price_wholesale",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(_vm.utils.formatCurrency(item.price_wholesale))+" ")]}},{key:"quantity",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(_vm.utils.formatCurrency(item.quantity, { type: 'decimal' }))+" ")]}},{key:"quantity_measured",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(_vm.utils.formatCurrency(item.quantity_measured, { type: 'decimal' }))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.props.item;
return [_c('ButtonIcon',{attrs:{"x-small":"","color":"success","tooltip":"Aferir item","icon":_vm.$icons.check},on:{"click":function($event){return _vm.measure(item, 1)}}})]}}],null,true)})],1),_c('Row',{staticClass:"mt-5 custom-footer"},[_c('Col',{attrs:{"md":"4"}},[_c('Alert',{staticClass:"py-2",attrs:{"color":"primary"}},[_c('h5',[_c('b',[_vm._v("Total Volumes Balanço")])]),_c('h4',[_vm._v(_vm._s(_vm.totalizers.quantityVolumes))])])],1),_c('Col',{attrs:{"md":"4"}},[_c('Alert',{staticClass:"py-2",attrs:{"color":"primary"}},[_c('h5',[_c('b',[_vm._v("Total Volumes Aferidos")])]),_c('h4',[_vm._v(_vm._s(_vm.totalizers.quantityVolumesMeasured))])])],1),_c('Col',{attrs:{"md":"4"}},[_c('Alert',{staticClass:"py-2",attrs:{"color":"primary"}},[_c('h5',[_c('b',[_vm._v("Saldo Volumes")])]),_c('h4',[_vm._v(_vm._s(_vm.totalizers.balance))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }