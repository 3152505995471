<template>
  <div>
    <Divider class="my-5 px-5" weight-bold />
    <DataTable no-search no-sync hide-default-footer :items="variationValuesItems"
      :headers="$schemas.product.variations.list.headersVariationValues" class="px-5">
    </DataTable>
    <Divider class="my-5 px-5" weight-bold />
  </div>
</template>

<script>
import {
  Divider,
  DataTable
} from '@/components/vuetify';

export default {
  name: 'ListVariationValues',
  components: {
    Divider,
    DataTable,
  },
  props: {
    variationValuesItems: {
      type: Array,
      required: true,
    }
  }
};
</script>

<style>

</style>