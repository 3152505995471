import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { formatPhone } from '@/utils';

export const getAll = (server) => {
  server.get('/companies', (schema, request) => {
    const companies = schema.companies.all();
    const { page, perPage } = request.queryParams;

    companies.models = softDelete(companies);
    companies.models = companies.models.map((item) => {
      return {
        ...item.attrs,
        cell_phone_formatted: formatPhone(item.cell_phone),
      };
    });

    return pagination('companies', companies, page, perPage);
  });
};