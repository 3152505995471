import { typePageOptions } from '@/utils';
import { text, users, list } from '@/utils/icons';
import { required } from '@/utils/rules';
import { typeTaxPayer, typePersonType, months } from '@/utils/options';
import { personType} from '@/utils/enums';
import { cell_phone, cnpj, cpf } from '@/utils/masks';
import RegisterBusiness from '@/business/RegisterBusiness';
import ListVehicles from '@/views/GeneralRegisters/Registers/pages/components/ListVehicles';

export default {
  domain: 'registers',
  domainSingle: 'register',
  business: RegisterBusiness,
  title: 'Cadatros',
  description: 'Lista de todos os Cadatros do sistema',
  createBtn: 'Novo Cadastro',
  showInactive: false,
  schemaShare: true,
  formAddress: true,
  routes: {
    client: {
      list: {
        name: 'clients',
        path: '/clientes',
        meta: {
          typePage: typePageOptions.list,
          requiresAuth: true,
          type: 'client',
          title: 'Clientes',
          description: 'Lista de todos os Clientes cadastrados no sistema',
        },
      },
      create: {
        name: 'clientCreate',
        path: '/clientes/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true, type: 'client', title: 'Clientes', description: 'Formulário para cadastrar um Cliente no sistema.' },
      },
      show: {
        name: 'clientShow',
        path: '/clientes/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true, type: 'client', title: 'Clientes', description: 'Dados cadastrais do Cliente selecionado', },
      }
    },
    employee: {
      list: {
        name: 'employees',
        path: '/funcionarios',
        meta: { typePage: typePageOptions.list, requiresAuth: true, type: 'employee', title: 'Funcionários', description: 'Lista de todos os Funcionários cadastrados no sistema', },
      },
      create: {
        name: 'employeeCreate',
        path: '/funcionarios/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true, type: 'employee', title: 'Funcionários', description: 'Formulário para cadastrar um Funcionário no sistema.' },
      },
      show: {
        name: 'employeeShow',
        path: '/funcionarios/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true, type: 'employee', title: 'Funcionários', description: 'Dados cadastrais do Funcionário selecionado', },
      }
    },
    seller: {
      list: {
        name: 'sellers',
        path: '/vendedores',
        meta: { typePage: typePageOptions.list, requiresAuth: true, type: 'seller', title: 'Vendedores', description: 'Lista de todos os Vendedores cadastrados no sistema', },
      },
      create: {
        name: 'sellerCreate',
        path: '/vendedores/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true, type: 'seller', title: 'Vendedores', description: 'Formulário para cadastrar um Vendedor no sistema.' },
      },
      show: {
        name: 'sellerShow',
        path: '/vendedores/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true, type: 'seller', title: 'Vendedores', description: 'Dados cadastrais do Vendedor selecionado', },
      }
    },
    provider: {
      list: {
        name: 'providers',
        path: '/fornecedores',
        meta: { typePage: typePageOptions.list, requiresAuth: true, type: 'provider', title: 'Fornecedores', description: 'Lista de todos os Fornecedores cadastrados no sistema', },
      },
      create: {
        name: 'providerCreate',
        path: '/fornecedores/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true, type: 'provider', title: 'Fornecedores', description: 'Formulário para cadastrar um Fornecedor no sistema.' },
      },
      show: {
        name: 'providerShow',
        path: '/fornecedores/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true, type: 'provider', title: 'Fornecedores', description: 'Dados cadastrais do Fornecedor selecionado', },
      }
    },
    transporter: {
      list: {
        name: 'transporters',
        path: '/transportadoras',
        meta: {
          typePage: typePageOptions.list,
          requiresAuth: true,
          type: 'transporter',
          title: 'Transportadoras',
          description: 'Lista de todas as Transportadoras cadastradas no sistema',
          moreActions: true,
        },
      },
      create: {
        name: 'transporterCreate',
        path: '/transportadoras/novo',
        meta: {
          typePage: typePageOptions.create,
          requiresAuth: true,
          type: 'transporter',
          title: 'Transportadoras',
          description: 'Formulário para cadastrar uma Transportadora no sistema.'
        },
      },
      show: {
        name: 'transporterShow',
        path: '/transportadoras/:id',
        meta: {
          typePage: typePageOptions.show,
          requiresAuth: true,
          type: 'transporter',
          title: 'Transportadoras',
          description: 'Dados cadastrais da Transportadora selecionada'
        },
      }
    },
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Veículos',
          icon: list,
          name: 'openListVehicles',
          action: 'openListVehicles',
          component: ListVehicles,
          version: 'v2',
          updateListWhenClosing: true,
          maxWidth: 600,
        },
      ]
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'text',
        name: 'name',
        label: 'Nome',
        icon: text,
        md: 12,
      },
      {
        type: 'text',
        name: 'email',
        label: 'E-mail',
        icon: text,
        md: 6,
      },
      {
        type: 'simpleMask',
        name: 'cell_phone',
        label: 'Celular',
        icon: text,
        md: 6,
        inputMask: '(##)#####-####',
        outputMask: '###########',
        clearable: true,
      },
      {
        type: 'simpleMask',
        name: 'cnpj',
        label: 'CNPJ',
        icon: text,
        md: 6,
        inputMask: '##.###.###/####-##',
        outputMask: '##############',
        clearable: true,
      },
      {
        type: 'simpleMask',
        name: 'cpf',
        label: 'CPF',
        icon: text,
        md: 6,
        inputMask: '###.###.###-##',
        outputMask: '###########',
        clearable: true,
      },
      {
        type: 'select',
        name: 'birth_month',
        label: 'Mês Aniverśario',
        icon: list,
        md: 6,
        itemText: 'text',
        itemValue: 'value',
        noTypeFilter: true,
        items: {
          data: months
        }
      },
    ]
  },
  fields: [
    {
      title: 'Dados Principais',
      icon: users,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'code',
          formattedName: 'code',
          label: 'Código',
          align: '',
          md: '2',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          counter: 50,
          width: 100,
        },
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
          width: 150,
        },
        {
          type: 'text',
          name: 'social_name',
          formattedName: 'social_name',
          label: 'Nome Fantasia',
          align: '',
          md: '5',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          counter: 100,
          width: 150,
        },
        {
          type: 'select',
          name: 'person_type',
          formattedName: 'person_type_formatted',
          label: 'Tipo da Pessoa',
          align: '',
          md: '2',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: typePersonType,
          default: personType.PHYSICAL,
          itemValueDefault: true,
          itemText: 'text',
          itemValue: 'value',
          width: 150,
        },
        {
          type: 'simpleMask',
          name: 'cnpj',
          formattedName: 'document',
          label: 'Documento',
          labelForm: 'CNPJ',
          align: '',
          md: '2',
          rules: [],
          inputMask: cnpj,
          outputMask: '##############',
          list: true,
          readonly: false,
          clearable: false,
          disabled: false,
          width: 180,
        },
        {
          type: 'simpleMask',
          name: 'ie',
          formattedName: 'ie',
          label: 'Inscrição Estadual',
          align: '',
          md: '2',
          rules: [],
          inputMask: '###################',
          outputMask: '###################',
          list: false,
          readonly: false,
          disabled: false,
          width: 180,
        },
        {
          type: 'simpleMask',
          name: 'cpf',
          formattedName: 'cpf_formatted',
          label: 'CPF',
          align: '',
          md: '2',
          rules: [],
          inputMask: cpf,
          outputMask: '###########',
          list: false,
          readonly: false,
          clearable: false,
          disabled: false,
        },
        {
          type: 'simpleMask',
          name: 'rg',
          formattedName: 'rg',
          label: 'RG',
          align: '',
          md: '2',
          rules: [],
          inputMask: '###################',
          outputMask: '###################',
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'simpleMask',
          name: 'birth_date',
          formattedName: 'birth_date_formatted',
          label: 'Aniversário',
          align: '',
          md: '2',
          rules: [],
          inputMask: '##/##',
          outputMask: '##/##',
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'observations',
          formattedName: 'observations',
          label: 'Observações',
          align: '',
          md: '12',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          counter: 250,
          width: 150,
        },
        {
          type: 'select',
          name: 'taxpayer_type',
          formattedName: 'taxpayer_type_formatted',
          label: 'Tipo de Contribuinte',
          align: '',
          md: '6',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: typeTaxPayer,
          itemText: 'text',
          itemValue: 'value',
        },
      ],
    },
    {
      type: 'address',
      readonly: true,
      items: [],
      itemsDisplayList: [
        {
          name: 'address.city',
          formattedName: 'address.city_formatted',
          label: 'Cidade/UF',
          width: 100,
        }
      ]
    },
    {
      title: 'Dados de Contato',
      icon: users,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'contact_name',
          formattedName: 'contact_name',
          label: 'Contato',
          align: '',
          md: '4',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          width: 150,
        },
        {
          type: 'text',
          name: 'email',
          formattedName: 'email',
          label: 'Email',
          align: '',
          md: '4',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          width: 150,
        },
        {
          type: 'simpleMask',
          name: 'cell_phone',
          formattedName: 'cell_phone_formatted',
          label: 'Celular',
          align: '',
          md: '4',
          rules: [],
          inputMask: cell_phone,
          outputMask: '###########',
          list: true,
          readonly: false,
          clearable: false,
          disabled: false,
          width: 150,
        },
      ],
    },
  ],
  footer: []
};
