<template>
  <div>
    <PageHeader :schema="$schemas.natureOperation" />
    <PageContent>
      <DynamicListPage :schema="$schemas.natureOperation"
        :service="$api.natureOperations" @actionMoreActions="actionMoreActions"
        :slots="slotsItems" >
        <template v-slot:is_pdv_formatted="{ props: { item } }">
          <Chip small :label="item.is_pdv_formatted" :color="item.is_pdv ? 'success' : 'primary'" />
        </template>
      </DynamicListPage>
    </PageContent>

    <Dialog :dialog="dialogStates" no-title>
      <ListNatureOperationStates slot="content"
        :nature-operation-id="this.natureOperationSelected.id" :states="states"
        @listStates="listStates"
        @update:dialogStates="dialogStates = $event" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog, Chip} from '@/components/vuetify';
import ListNatureOperationStates from './components/ListNatureOperationStates';

export default {
  name: 'ListNatureOperations',
  components: { PageHeader, PageContent, DynamicListPage, Dialog, ListNatureOperationStates, Chip },
  data () {
    return {
      dialogStates: false,
      states: [],
      natureOperationSelected: {},
      slotsItems: [
        { field: 'is_pdv_formatted' }
      ]
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this.natureOperationSelected = item;
      this[moreActionItem.action](item);
    },
    async listStates () {
      this.$api.natureOperations.listStates(this.natureOperationSelected.id).then((res) => {
        this.states = res.nature_operation_states.map((item) => {
          return {
            ...item,
            image: `<img src="${require(`@/assets/states/${item.uf}.png`)}" />`,
          };
        });
        this.dialogStates = true;
      }).catch((err) => {
        this.$noty.error(err);
      });
    }
  }
};
</script>
