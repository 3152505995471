
<script>
import { typeStockMovements } from '@/utils/enums';

export default {
  methods: {
    getColorChip (movement_type) {
      return movement_type === typeStockMovements.INPUT ? 'success' : 'error';
    },
    getMovementType (movement_type) {
      return this.l.movementType[movement_type];
    },
    getOriginLabel (origin, originable) {
      let number = '';

      if (origin.type !== 'stock-movement') {
        number = `- Nº: ${originable.order_number}`;
      }

      return `<span>${this.$locales.pt.stockMovements.list.origin[origin.type]} ${number}</span>`;
    },
  }
};
</script>

<style>

</style>
