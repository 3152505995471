<template>
  <div>
    <ModalCardDefault :title="l.title.title" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="titleCard"
        :icon="$icons.box" readonly>
        <v-form v-model="validForm" ref="validForm" lazy-validation>
          <Row class="mt-0">
            <Col md="6">
            <DataPicker v-model="boxMovement.date_open"
              :label="l.form.date_open.label"
              :rules="[rules.required]"
              :readonly="isReadonlyClose" />
            </Col>
            <Col md="6">
            <DataPicker v-model="boxMovement.date_close"
              :label="l.form.date_close.label"
              :rules="[]"
              :readonly="isReadonly" />
            </Col>
            <Col md="6">
            <Select v-model="boxMovement.employee_id"
              :placeholder="l.form.employee.placeholder"
              :label="l.form.employee.label" :items="employees"
              item-text="name" item-value="id"
              :rules="[rules.required]"
              :readonly="isReadonlyClose" />
            </Col>
            <Col md="6">
              <TextFieldMoney v-model="boxMovement.balance"
                :label="l.form.balance.label"
                :placeholder="l.form.balance.placeholder"
                :readonly="true" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

      <div slot="actions">
        <Button :label="l.actions.save" class="mr-3" rounded color="secondary"
        @click="save()" :disabled="false"/>
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextFieldMoney,
  DataPicker,
  Button,
  ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { messageErrors } from '@/utils';
import { required } from '@/utils/rules';
import { typePage, statusBoxMovements } from '@/utils/enums';
import BoxMovement from '../../model/BoxMovement';

export default {
  name: 'OpenOrClosedBox',
  components: {
    ModalCardDefault,
    Row,
    Col,
    Select,
    TextFieldMoney,
    DataPicker,
    Button,
    ExpansionPanel
  },
  props: {
    typePage: {
      type: String,
      required: true
    },
    box: {
      type: Object,
      required: true
    },
    boxMovementLast: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      panel: [0],
      titleCard: '',
      validForm: false,
      rules: { required: required },
      employees: [],
      isReadonly: false,
      isReadonlyClose: false,
      boxMovement: BoxMovement.model
    };
  },
  computed: {
    l() {
      return this.$locales.pt.boxes.list.modalBoxMovementsOpenOrClose;
    },
  },
  watch: {
    boxMovement() {
      this.GetEmployees();

      if (this.typePage === statusBoxMovements.OPEN) {
        this.titleCard = this.l.title.open;
        this.isReadonlyClose = false;
        this.isReadonly = true;
      } else {
        this.find();
        this.titleCard = this.l.title.close;
        this.isReadonlyClose = true;
      }
    },
    typePage() {
      if (this.typePage === statusBoxMovements.OPEN) {
        this.resetForm();
      } else {
        this.find();
      }
    }
  },
  methods: {
    async find() {
      this.boxMovement = this.boxMovementLast;
    },
    async save() {
      if (!this.$refs.validForm.validate()) {
        return false;
      }
      this.boxMovement = {
        ...this.boxMovement,
        box_id: this.box.id
      };

      if (this.typePage === statusBoxMovements.OPEN) {
        this.$api.boxes.createBoxMovement(this.box.id, this.boxMovement).then(() => {
          this.$noty.success(this.l.actions.box_open);
          this.$emit('close');
          this.resetForm();
        }).catch((err) => {
          this.$noty.error(messageErrors(err.response));
        });
      } else {
        this.$api.boxes.updateBoxMovement(this.boxMovement.id, this.boxMovement).then(() => {
          this.$noty.success(this.l.actions.box_close);
          this.$emit('close');
          this.resetForm();
        }).catch((err) => {
          this.$noty.error(messageErrors(err.response));
        });
      }
    },
    async GetEmployees() {
      await this.$api.registers.index({ filters: { type: 'employee' },
        per_page: 100 }).then(({ data: { registers } }) => {
        this.employees = registers;
      });
    },
    resetForm() {
      this.boxMovement = BoxMovement.model;
      this.titleCard = this.l.title.open;
      this.isReadonly = true;
    }
  },
  mounted() {
    this.GetEmployees();

    if (this.typePage === typePage.CREATE) {
      this.titleCard = this.l.title.open;
    } else {
      this.titleCard = this.l.title.close;
      this.find();
    }
  }
};
</script>

<style>

</style>
