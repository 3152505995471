<template>
  <div>
    <ModalCardDefault :title="'Alterar Registro'" @close="$emit('update:dialogModalUpdateFinalValue', false)">
      <ExpansionPanel v-model="panel" :title="'Alterar o Preço Final'" :icon="$icons.box" readonly>
        <TextFieldMoney v-model="form.final_value" :placeholder="'Preço Final'"
            :label="'Preço Final'" />
      </ExpansionPanel>

      <template v-slot:actions="{ }">
        <Button :label="'Confirmar'" color="secondary"
          :icon="$icons.check" rounded class="mr-2"
          :loading="loadingUpdateFinalValue" @click="openDialogConfirmation()" />
      </template>
    </ModalCardDefault>

    <DialogConfirmation :dialog="dialogConfirmation"
      message="Reseja realmente alterar o registro?" title="Alterar o Preço Final"
      :loading="loadingUpdateFinalValue"
      @yesActionDialogConfirmation="yesActionConfirmation"
      @noActionDialogConfirmation="dialogConfirmation = false" />
  </div>
</template>

<script>
import {
  ExpansionPanel,
  TextFieldMoney,
  Button
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import DialogConfirmation from '@/components/DialogConfirmation';

export default {
  name: 'ListProductsUpdate',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    DialogConfirmation,
    TextFieldMoney,
    Button
  },
  props: {
    productVariationSelected: {
      type: Object,
      required: true,
    },
    loadingUpdateFinalValue: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      panel: [0],
      dialogModal: false,
      dialogConfirmation: false,
      loadingDialogConfirmation: false,
      form: {}
    };
  },
  watch: {
    productVariationSelected () {
      this.form = this.productVariationSelected;
    }
  },
  methods: {
    openDialogConfirmation () {
      this.dialogConfirmation = true;
    },
    yesActionConfirmation () {
      this.dialogConfirmation = false;
      this.$emit('updateFinalValue', this.form);
    }
  },
  async mounted () {
    this.form = this.productVariationSelected;
  },
};
</script>
