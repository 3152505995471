import StockMovementSchema from './schemas/StockMovementSchema';
import ListStockMovements from './pages/ListStockMovements';

export default [
  {
    path: StockMovementSchema.routes.list.path,
    name: StockMovementSchema.routes.list.name,
    component: ListStockMovements,
    meta: StockMovementSchema.routes.list.meta,
  },
];