<template>
  <div>
    <DataTable class="mt-5 custom-table-items-orders" no-search no-sync :items="items"
      :headers="$schemas.order.create.taxes.headers" :slots="slotsItems"
      :slotsHeaders="slotsItems" show-expand>
      <template v-for="(slot, index) in slotsItems" v-slot:[`${slot.field}Header`]="header">
        <ItemsDataListTaxesHeader :header="header" :key="index" />
      </template>
      <template v-for="(slot, index) in slotsItems" v-slot:[`${slot.field}`]="{ props: { item } }">
        <ItemsDataListTaxesItem :item="item.tax[slot.field]" :key="index"
          :type="slot.field" />
      </template>

      <template v-slot:content-expanded="{ props: { item } }">
        <Row class="px-5 pt-5">
          <Col class="" v-for="(tax, index) in itemsExpanded" :key="index">
            <div class="item-header">
              <p class="d-block font-weight-bold subtitle-1">{{ tax.text }}</p>
              <div>
                <span v-text="l.headers.base" />
                <span v-text="l.headers.aliquot" />
                <span v-text="l.headers.value" />
                <span v-if="hasCts(tax.value)" v-text="l.headers.cst" />
              </div>
            </div>
          </Col>
        </Row>
        <Row class="px-5 pb-5">
          <Col class="" v-for="(tax, index) in itemsExpanded" :key="index">
            <div class="d-flex justify-space-between px-5">
            <span>{{ formatCurrency(item.tax[tax.value].base, { type: 'decimal' }) }}</span>
            <span>{{ formatCurrency(item.tax[tax.value].aliquot, { type: 'decimal' }) }}</span>
            <span>{{ formatCurrency(item.tax[tax.value].value, { type: 'decimal' }) }}</span>
            <span v-if="hasCts(tax.value)"><b>{{item.tax[tax.value].cst}}</b></span>
          </div>
          </Col>
        </Row>
      </template>
    </DataTable>>
  </div>
</template>

<script>
import {
  DataTable,
  Row,
  Col,
} from '@/components/vuetify';
import ItemsDataListTaxesHeader from './ItemsDataListTaxesHeader';
import ItemsDataListTaxesItem from './ItemsDataListTaxesItem';
import ItemsDataListTaxesMixin from '@/mixins/ItemsDataListTaxesMixin';
import { formatCurrency } from '@/utils';

export default {
  name: 'ItemsDataListTaxes',
  components: {
    DataTable,
    ItemsDataListTaxesHeader,
    ItemsDataListTaxesItem,
    Row,
    Col,
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.items.dataList.taxes;
    },
    itemsExpanded () {
      return [
        {
          text: 'IPI',
          value: 'ipi',
          sortable: false,
        },
        {
          text: 'PIS',
          value: 'pis',
          sortable: false,
        },
        {
          text: 'COFINS',
          value: 'cofins',
          sortable: false,
        },
        {
          text: 'Consumidor Final',
          value: 'final_consumer',
          sortable: false,
        },
        {
          text: 'Valor Aproximados',
          value: 'approximates_value',
          sortable: false,
        },
      ];
    }
  },
  mixins: [ItemsDataListTaxesMixin],
  data () {
    return {
      slotsItems: [
        { field: 'national_simple' },
        { field: 'icms' },
        { field: 'icms_st' },
      ],
      formatCurrency,
    };
  },
};
</script>

<style lang="scss">
.custom-table-items-orders {
  .text-start {
    padding: 0px 5px !important;
  }
}

.item-header {
  justify-content: center;
  text-align: center;
  border-left: 1px solid #CCC;
  padding: 0 20px;

  p {
    font-weight: bold;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
</style>
