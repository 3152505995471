<template>
  <ModalCardDefault title="Produtos" @close="$emit('close')">
      <ExpansionPanel v-model="panel" title="Lista dos Produtos do Estoque" :icon="$icons.box" readonly>
        <DataTable no-search :items="productVariations"
          :headers="headers" :slots="slotsItems" :items-per-page="5" :loading="loading"
          @getItems="getMovements">
          <template v-slot:quantity_by_stock="{ props: { item } }">
            <span>{{ getQuantityByStock(item) }}</span>
          </template>

          <template v-slot:custom-header>
            <Row>
            <Col>
                <TextField v-model="searchCode" label='Código' @keyup="debouncedSearch('code')" />
              </Col>
              <Col>
                <TextField v-model="searchName" label='Nome' @keyup="debouncedSearch('name')"  />
              </Col>
              <Col>
                <TextField v-model="searchReference" label='Referência' @keyup="debouncedSearch('reference')" />
              </Col>
            </Row>
          </template>
        </DataTable>
      </ExpansionPanel>
    </ModalCardDefault>
</template>

<script>
import {
  DataTable,
  ExpansionPanel,
  TextField,
  Row,
  Col,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { debounce } from 'lodash';

export default {
  name: 'ListProductsByStock',
  components: {
    DataTable,
    ExpansionPanel,
    ModalCardDefault,
    TextField,
    Row,
    Col,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      panel: [0],
      productVariations: [],
      loading: false,
      headers: [
        { text: 'Cod.', value: 'code' },
        { text: 'Cod. Barras', value: 'bar_code' },
        { text: 'Ref.', value: 'reference' },
        { text: 'Nome', value: 'name' },
        { text: 'Preço Varejo', value: 'price_formatted' },
        { text: 'Preço Atacado', value: 'price_wholesale_formatted' },
        { text: 'Estoque', value: 'quantity_by_stock' },
      ],
      slotsItems: [{ field: 'quantity_by_stock' }],
      search: '',
      searchColumn: '',
      searchName: '',
      searchCode: '',
      searchReference: '',
    };
  },
  watch: {
    item: {
      handler () {
        this.getMovements();
      },
      deep: true,
    },
  },
  created() {
    this.debouncedSearch = debounce(this.searchProducts, 700);
  },
  methods: {
    getMovements(pagination) {
      this.loading = true;
      this.$api.stocks.getAllProductVariationsByStockId({ stockId: this.item.id, pagination, searchColumn: this.searchColumn, search: this.search})
        .then((data) => {
          this.productVariations = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getQuantityByStock(item) {
      return item.product_variation_stocks.filter((stock) => stock.stock_id === this.item.id)[0]?.quantity || 0;
    },
    searchProducts(column) {
      if (column === 'name') {
        this.searchCode = '';
        this.searchReference = '';
      } else if (column === 'code') {
        this.searchName = '';
        this.searchReference = '';
      } else if (column === 'reference') {
        this.searchName = '';
        this.searchCode = '';
      }
      this.search = this.searchName || this.searchCode || this.searchReference;
      this.searchColumn = column;
      this.getMovements();
    }
  },
};
</script>
