<template>
  <div style="width: 100%">
    <v-chip v-bind="$attrs" v-on="$listeners" :close="close" @click:close="chip1 = false" :style="customStyle">
      <v-icon v-if="$attrs.icon" left>{{ $attrs.icon }}</v-icon>
      {{ label }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    label: {
      type: String,
      required: true
    },
    close: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      chip1: true,
    };
  }
};
</script>

<style>

</style>
