import { formatCurrency } from '@/utils';

export default {
  beforeList: ({ form }) => {
    form.orders.forEach((order) => {
      const statusDisabled = ['finished', 'canceled', 'returned', 'reversed'];
      order.destroyDisabled = statusDisabled.includes(order.status);
      order.disableItemMoreActions = {
        generateInvoice: order.product_invoice_id || !order.is_delivered  ? true : false,
        showInvoice: !order.product_invoice_id ? true : false,
        duplicateOrder: false,
        devolutionOrder: !['finished'].includes(order.status),
        reverseOrder: !['finished'].includes(order.status),
        print: false,
        download: false,
      };
    });
    form.value_total_pending = formatCurrency(form.value_total_pending);
    form.value_total_delivered = formatCurrency(form.value_total_delivered);
  },
};
