import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('subscription', {
    initial_date: '2022-01-01',
    final_date: '2022-06-30',
    company: 'MGE Tech',
    status: 'active',
    ...baseFields,
  });
  server.create('subscription', {
    initial_date: '2022-01-03',
    final_date: '2022-09-30',
    company: 'Alpha Coder',
    status: 'suspended',
    ...baseFields,
  });
  server.create('subscription', {
    initial_date: '2022-01-03',
    final_date: '2022-09-30',
    company: 'Quadros Love',
    status: 'canceled',
    ...baseFields,
  });
};
