<template>
  <div>
    <PageHeader :schema="$schemas.table" />
    <PageContent>
      <DynamicListPage :schema="$schemas.table" :service="$api.tables"
        :slots="slotsItems" ref="listTables">
        <template v-slot:status_formatted="{ props: { item } }">
          <Chip :label="item.status_formatted" small :color="getColorStatus(item.status)" dark />
        </template>
      </DynamicListPage>
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @close="closeModal" @confirm="confirm" @openPayment="openPayment" />
    </Dialog>

    <Dialog :dialog="dialogModalPayments" :maxWidth="1200">
      <Payments slot="content" :model="tableSelected" @confirmDialog="setPayments"
        @update:dialogModal="dialogModalPayments = $event" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import Payments from '@/components/OrderProductInvoice/Payments';
import { Dialog, Chip } from '@/components/vuetify';
import MoreActionsMixin from '@/mixins/MoreActionsMixin.js';
import TableSchema from '../schemas/TableSchema.js';
import OpenTable from './components/OpenTable';
import DetailsTable from './components/DetailsTable';
import { getCurrentPeriod, messageErrors } from '@/utils';

const COLOR_STATUS = Object.freeze({
  available: 'green',
  unavailable: 'red',
});

const MODAL_COMPONENTS = Object.freeze({
  openTable: OpenTable,
  detailsTable: DetailsTable,
});

export default {
  name: 'ListTables',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog,
    Chip,
    Payments,
  },
  data () {
    return {
      dialogModal: false,
      dialogModalPayments: false,
      modalProps: {
        maxWidth: 1280,
        table: {},
        key: null
      },
      tableSelected: {},
      componentModal: null,
      slotsItems: [
        { field: 'status_formatted' }
      ],
      payloadOpenClose: {}
    };
  },
  mixins: [MoreActionsMixin(TableSchema)],
  methods: {
    getColorStatus (status) {
      return COLOR_STATUS[status];
    },
    openClosedTable ({ moreActionItem, item }) {
      this.dialogModal = true;
      this.tableSelected = item;
      this.modalProps = {
        table: item,
        key: item.id,
        maxWidth: 900,
        type: moreActionItem.name
      };
      this.componentModal = MODAL_COMPONENTS.openTable;
    },
    detailsTable ({ moreActionItem, item }) {
      this.dialogModal = true;
      this.modalProps = {
        table: item,
        key: item.id,
        maxWidth: 900,
        type: moreActionItem.name
      };
      this.componentModal = MODAL_COMPONENTS.detailsTable;
    },
    closeModal () {
      this.dialogModal = false;
      this.modalProps = { table: {}, key: null };
      this.tableSelected = {};
      this.payloadOpenClose = {};
      this.componentModal = null;
      this.dialogModalPayments = false,
      this.$refs.listTables.index();
    },
    async openCloseTableRequest () {
      this.$api.tables.openCloseTable(this.payloadOpenClose).then(() => {
        this.$noty.success('Realizado com sucesso!');
        this.$refs.listTables.index();
        this.closeModal();
      }).catch((err) => {
        if (err.response.status === 422 || err.response.status === 400) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error('Error, tente novamente!');
        }
      });
    },
    confirm (data) {
      this.mountPayloadOpenClose(data);
      this.openCloseTableRequest();
    },
    openPayment (data) {
      this.mountPayloadOpenClose(data);
      this.tableSelected = {
        ...this.tableSelected,
        total_products: this.tableSelected.total_value,
        total_discount_value: 0,
        total_order: this.tableSelected.total_value,
      };
      this.dialogModalPayments = true;
    },
    mountPayloadOpenClose (data) {
      this.payloadOpenClose = {
        type: data.type,
        table_id: data.tableSale.table_id,
        employee_id: data.tableSale.employee_id,
        client_id: data.tableSale.client_id,
        date_hour_open: data.type === this.$enums.tableStatusType.OPEN_TABLE ? getCurrentPeriod().currentDateTimeEN : null,
        ...data.type === this.$enums.tableStatusType.CLOSE_TABLE && { date_hour_closed: getCurrentPeriod().currentDateTimeEN },
        client_qtd: data.tableSale.client_qtd,
        total_value: data.tableSale.total_value,
      };
    },
    setPayments ({ data: { payments } }) {
      if (payments.length <= 0) {
        this.$noty.error('É necessário informar os pagamentos!');

        return;
      }
      this.payloadOpenClose.payments = payments;
      this.openCloseTableRequest();
      this.closeModal();
    }
  },
};
</script>
