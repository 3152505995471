import DynamicService from '@/service/DynamicService';
import CostCenterSchema from '../schemas/CostCenterSchema';

const formatResponse = () => {
};

const formatRequest = (item) => {
  return item;
};

const CostCentersService = DynamicService('cost_centers', CostCenterSchema, { formatResponse, formatRequest });

export default CostCentersService;
