<template>
  <ModalCardDefault title="Finalizar" @close="close">
    <ExpansionPanel v-model="panel" title="Finalizar Balanço." :icon="$icons.box" readonly>
      <Row>
        <Col>
          <CheckBox v-model="resetProductNotChanged"
            label="Zerar os produtos não alterados?"
            @setCheckBox="setResetProductNotChanged" />
        </Col>
      </Row>
    </ExpansionPanel>

    <div slot="actions">
      <Button label="Confirmar"  @click="finishBalance"
        class="mr-3" rounded color="secondary" :loading="loadingAction" />
    </div>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  CheckBox,
  Row,
  Col,
  Button
} from '@/components/vuetify';
export default {
  name: 'FinishBalance',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    CheckBox,
    Row,
    Col,
    Button
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panel: [0],
      resetProductNotChanged: false,
    };
  },
  methods: {
    setResetProductNotChanged(value) {
      this.resetProductNotChanged = value || false;
    },
    finishBalance() {
      this.$emit('handleActionMoreActions', {
        emitName: 'finishBalance',
        item: this.item,
        resetProductNotChanged: this.resetProductNotChanged
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
