import { typePageOptions } from '@/utils';
import { text, list } from '@/utils/icons';
import { required } from '@/utils/rules';
import BankBusiness from '@/business/BankBusiness';

export default {
  domain: 'banks',
  domainSingle: 'bank',
  business: BankBusiness,
  title: 'Bancos',
  description: 'Lista de todos os Bancos do sistema',
  createBtn: 'Novo Banco',
  showInactive: false,
  routes: {
    list: {
      name: 'banks',
      path: '/bancos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'bank-create',
      path: '/bancos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'bank-show',
      path: '/bancos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Extrato Bancário',
          icon: list,
          action: 'listMovements'
        }
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Banco',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'number',
          formattedName: 'number',
          label: 'Número',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'agency',
          formattedName: 'agency',
          label: 'Agência',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'account',
          formattedName: 'account',
          label: 'Conta',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'money',
          name: 'balance',
          formattedName: 'balance_formatted',
          label: 'Saldo',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
      ],
    },
  ],
  footer: [],
  movements: {
    list: {
      headers: [
        {
          text: 'Data',
          value: 'movement_date_formatted',
        },
        {
          text: 'Tipo',
          value: 'movement_type_formatted',
        },
        {
          text: 'Descrição',
          value: 'description',
        },
        {
          text: 'Origem',
          value: 'originable_type_formatted',
        },
        {
          text: 'Forma de Pagamento',
          value: 'payment_method_formatted',
        },
        {
          text: 'Valor',
          value: 'value_formatted',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ]
    }
  }
};
