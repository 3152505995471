<template>
  <v-tab>
    <slot />
  </v-tab>
</template>

<script>
export default {
  name: 'Tab'
};
</script>
