<template>
  <ExpansionPanel v-model="panel" :title="'Parcelas'" :icon="$icons.box" readonly>
    <Row>
      <Col md="12">
        <DataTable class="mt-5" no-search no-sync :items.sync="model.parcels"
          :headers="this.$schemas.account.create.installments.headers" show-expand
          :slots="slotsItems" hide-default-footer :items-per-page="-1">

          <template v-slot:content-expanded="{ props: { item } }">
            <Tabs v-model="tab" @setTab="setTab">
              <Tab v-for="item in tabItems" :key="item">{{ item }}</Tab>
            </Tabs>

            <TabsItems v-model="tab">
              <component :is="componentTabItem" :item="item"/>
            </TabsItems>
          </template>

         <template v-slot:status="{ props: { item } }">
            <Chip :color="getColorStatus(item)"
              :label="getStatus(item)" small />
          </template>
          <template v-slot:actions="{ props: { item } }">
            <div>
              <ButtonIcon v-if="displayAction(item)" color="warning" x-small :icon="$icons.edit"
                :tooltip="'Alterar Parcela'" @click="openInstallmentEdit(item)"  />
              <ButtonIcon v-if="displayAction(item)" color="secondary" x-small :icon="$icons.cashPlus"
                :tooltip="'Fazer Pagamento'" @click="openInstallmentPaymentModal(item)"  />
            </div>
          </template>
        </DataTable>
      </Col>
    </Row>

    <Dialog :dialog="dialogModalInstallment" :maxWidth="800">
      <InstallmentEdit slot="content" :parcel="parcelSelected"
        @update:dialogModal="dialogModalInstallment = $event"
        @save="save" />
    </Dialog>

    <Dialog :dialog="dialogModalPayments" :maxWidth="1200">
      <Payments slot="content" :model="parcelPaymentSelected" @confirmDialog="makePayment"
        @update:dialogModal="dialogModalPayments = $event"
        :loading="loadingPayments" permitted-partial-payment :key="keyModalPayments" />
    </Dialog>
  </ExpansionPanel>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  DataTable,
  Chip,
  Dialog,
  ButtonIcon,
  Tab,
  Tabs,
  TabsItems,
} from '@/components/vuetify';
import DialogConfirmation from '@/components/DialogConfirmation';
import { required } from '@/utils/rules';
import { formatDatePT, formatCurrency, messageErrors, randomNumber } from '@/utils';
import { statusAccounts } from '@/utils/options';
import TypePageMixin from '@/mixins/TypePageMixin';
import { statusAccounts as statusAccountsEnum } from '@/utils/enums';
import InstallmentEdit from './InstallmentEdit';
import InstallmentTabPayments from './InstallmentTabPayments';
import Payments from '@/components/OrderProductInvoice/Payments';
import { clone } from 'lodash';

const TAB_COMPONENT = [
  InstallmentTabPayments,
];

export default {
  name: 'TitleInformation',
  components: {
    ExpansionPanel,
    Row,
    Col,
    DataTable,
    DialogConfirmation,
    ButtonIcon,
    Chip,
    Dialog,
    InstallmentEdit,
    Tab,
    Tabs,
    TabsItems,
    Payments,
  },
  props: {
    model: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      rules: {
        required: required
      },
      utils: { formatDatePT, formatCurrency },
      options: {
        status: statusAccounts
      },
      dialogConfirmation: false,
      loadingConfirmation: false,
      messageConfirmation: '',
      slotsItems: [
        { field: 'status' },
        { field: 'delayed_days' },
      ],
      parcelSelected: {},
      parcelPaymentSelected: {},
      dialogModalInstallment: false,
      tab: 0,
      tabItems: ['Pagamentos'],
      expandedItems: [1,2],
      componentTabItem: InstallmentTabPayments,
      dialogModalPayments: false,
      loadingPayments: false,
      keyModalPayments: 0
    };
  },
  mounted () {
  },
  computed: {
    l () {
      return this.$locales.pt.accounts.create.form.installments;
    },
    isPaid () {
      return this.model.status === statusAccountsEnum.PAID || false;
    },
  },
  mixins:  [TypePageMixin],
  methods: {
    getStatus(item) {
      if (item.total_paid_value> 0 && item.total_paid_value < item.total_value) {
        return 'Parcial';
      }

      return this.l.list.status[item.status];
    },
    getColorStatus(item) {
      if (item.total_paid_value > 0 && item.total_paid_value < item.total_value) {
        return 'orange';
      }

      const color = {
        [statusAccountsEnum.PAID]: 'success',
        [statusAccountsEnum.PENDING]: 'warning',
        [statusAccountsEnum.CANCELED]: 'primary',
      };

      return color[item.status];
    },
    openInstallmentEdit (parcel) {
      this.parcelSelected = parcel;
      this.dialogModalInstallment = true;
    },
    save(parcel) {
      this.$api.accounts.updateParcel(this.model.id, parcel.id, parcel)
        .then(() => {
          this.dialogModalInstallment = false;
          this.$noty.success('Realizado com sucesso!');
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.$noty.error(messageErrors(err.response));
          } else if ( err.response.status === 422) {
            this.$noty.error(messageErrors(err.response));
          } else if ( err.response.status === 404) {
            this.$noty.error('Parcela não encontrada!');
          } else {
            this.$noty.error('Erro ao atualizar parcela!');
          }
        }).finally(() => {
          this.$emit('findAccount');
        });
    },
    displayAction (item) {
      return item.status === statusAccountsEnum.PENDING && item.id || false;
    },
    setTab (tab) {
      this.tab = tab;
      this.componentTabItem = TAB_COMPONENT[tab];
    },
    openInstallmentPaymentModal(item) {
      this.parcelSelected = item;
      const parcel = clone(item);

      this.parcelPaymentSelected = {
        ...parcel,
        type: this.model.type,
        client_id: this.model.register_id,
        total_order: parseFloat(parcel.total_value) - parseFloat(item.total_paid_value),
        total_products: parseFloat(parcel.total_value) - parseFloat(item.total_paid_value),
        total_discount_value: 0,
      };
      this.keyModalPayments = randomNumber();
      this.dialogModalPayments = true;
    },
    makePayment({ data: { payments } }) {
      this.save({
        id: this.parcelSelected.id,
        due_date: this.parcelSelected.due_date,
        paid_date: this.parcelSelected.paid_date,
        value: this.parcelSelected.value,
        fees: this.parcelSelected.fees,
        discount: this.parcelSelected.discount,
        total_value: this.parcelSelected.total_value,
        payments: payments.map(item => ({
          account_parcel_id: this.parcelSelected.id,
          payment_method_id: item.payment_method.id,
          credit_letter_id: item.credit_letter_id,
          flag_id: item.flag?.id,
          paid_date: item.paid_date,
          compensation_date: this.parcelSelected.compensation_date ? this.parcelSelected.compensation_date : null,
          quantity_installments: item.quantity_installments,
          aut: item.aut,
          value: item.value,
        })),
      });
      this.dialogModalPayments = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.item-input {
  height:  30px !important
}
</style>
