import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('orderSale', {
    number: '1',
    seller: {
      id: 1,
      name: 'José Roberto'
    },
    customer: {
      id: 22,
      name: 'Renato Costa'
    },
    sub_total_value: '50,00',
    discount_value: '10,00',
    taxes_value: '5,00',
    total_value: '45,00',
    ...baseFields,
  });
};
