<template>
  <v-form v-model="validForm" ref="validForm" lazy-validation class="px-5">
    <Row class="mt-5">
      <!-- <Col md="3">
        <TextField v-model="form.name" :label="l.fields.labels.name" :rules="[rules.required]" />
      </Col> -->
      <Col md="2">
        <TextField v-model="form.bar_code" :label="l.fields.labels.bar_code" />
      </Col>
      <Col md="2">
        <TextField v-model="form.code" :label="l.fields.labels.code"
          :readonly="codeIsReadonly" />
      </Col>
      <Col md="2">
        <TextFieldMoney v-model="form.price" :label="l.fields.labels.price" :rules="[rules.required]" />
      </Col>
      <Col md="2">
        <TextFieldMoney v-model="form.price_wholesale" :label="l.fields.labels.priceWholesale" :rules="[rules.required]" />
      </Col>
      <Col md="4">
        <Select v-model="priceListSelected" :label="l.fields.labels.priceList"
          :items="priceLists" item-text="name" item-value="id" return-object
          clearable @change="setPriceListSelected" />
      </Col>
      <!-- <Col md="2">
        <TextFieldMoney v-model="finalValue" :label="l.fields.labels.finalValue"
          :rules="[rules.required]" @change="setFinalValue" :readonly="finalValueReadonly" />
      </Col> -->
    </Row>
    <Row>
      <Col md="2">
        <TextFieldInteger v-model="form.liquid_weight" :label="l.fields.labels.liquidWeight"
          prefix="" :rules="[rules.required]" />
      </Col>
      <Col md="2">
        <TextFieldInteger v-model="form.gross_weight" :label="l.fields.labels.grossWeight"
          prefix="" :rules="[rules.required]" />
      </Col>
      <Col md="3">
        <Select v-model="variationStockCreated.stock" :label="l.fields.labels.stock"
          :items="stocks" :rules="[rules.required]"
          item-text="name" item-value="id" return-object />
      </Col>
      <Col md="2">
        <TextFieldMoney v-model="variationStockCreated.quantity" :label="l.fields.labels.quantity"
          prefix="" :rules="[rules.required]" />
      </Col>
      <Col>
        <Button :label="l.actions.insert" class="mt-1 mr-3" rounded color="primary" @click="insertStock" />
      </Col>
    </Row>
  </v-form>
</template>

<script>
import {
  Row,
  Col,
  TextField,
  TextFieldMoney,
  TextFieldInteger,
  Select,
  Button
} from '@/components/vuetify';
import CreateVariationMixin from './mixins/CreateVariationMixin';
import { typeAdjustmentPriceList, typeValuePriceList } from '@/utils/options';

export default {
  name: 'FormProductVariationDetails',
  components: {
    Row,
    Col,
    TextField,
    TextFieldMoney,
    TextFieldInteger,
    Select,
    Button
  },
  props: {
    productVariation: {
      type: Object,
      required: true,
    },
    stocks: {
      type: Array,
      required: true,
    },
    priceLists: {
      type: Array,
      required: true,
    },
    codeIsReadonly: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      validForm: false,
      options: {
        typeAdjustmentPriceList,
        typeValuePriceList
      },
      form: this.productVariation,
      validFormVariationStock: false,
      variationStockCreated: {
        stock: null,
        quantity: 1
      },
      priceListSelected: {},
      finalValue: this.productVariation.price,
      finalValueReadonly: true,
    };
  },
  mixins: [CreateVariationMixin],
  methods: {
    insertStock () {
      if (!this.variationStockCreated.stock) {
        console.log(this.l);
        this.$noty.error(this.l.noty.errorStockRequired);

        return;
      }
      this.$emit('insertStock', this.variationStockCreated);
    },
    setPriceListSelected (priceListSelected) {
      if (!priceListSelected) {
        this.finalValueReadonly = true;
        this.finalValue = this.productVariation.price;
        this.$emit('setPriceList', null);

        return;
      }
      this.finalValueReadonly = false;
      const multiplicationFactor = priceListSelected.type_adjustment === this.$enums.typeAdjustmentPriceList.ADDITION ? 1 : -1;

      if (priceListSelected.type_value === this.$enums.typeValuePriceList.PERCENTAGE) {
        this.finalValue = this.productVariation.price + (multiplicationFactor * (this.productVariation.price * priceListSelected.value_with_adjustment / 100));
      } else {
        this.finalValue = this.productVariation.price + (multiplicationFactor * priceListSelected.value_with_adjustment);
      }

      this.$emit('setPriceList', { id: priceListSelected.id, value_final: this.finalValue });
    },
    setFinalValue () {
      if (this.priceListSelected) {
        this.$emit('setPriceList', { id: this.priceListSelected.id, final_value: this.finalValue });
      }
    }
  }
};
</script>

<style>

</style>
