import DynamicService from '@/service/DynamicService';
import OrderServiceSchema from '../schemas/OrderServiceSchema';
import { formatCurrency, formatDatePT } from '@/utils';

const formatResponse = (item) => {
  item.created_at_formatted = formatDatePT(item.created_at);
  item.sub_total_value_formatted = formatCurrency(item.sub_total_value);
  item.discount_value_formatted = formatCurrency(item.discount_value);
  item.taxes_value_formatted = formatCurrency(item.taxes_value);
  item.total_value_formatted = formatCurrency(item.total_value);
};

const OrderServicesService = DynamicService('order_services', OrderServiceSchema, { formatResponse });

export default OrderServicesService;