<template>
  <div>
    <ModalCardDefault :title="'Adicionar Produto'" @close="$emit('update:dialogItems', false)">
      <ExpansionPanel v-model="panel" :title="'Informações do Produto'" :icon="$icons.box" readonly>
        <Row>
          <Col>
            <DataTable class="mt-5" no-search no-sync :items.sync="itemsTable"
              :headers="headersOrderSaleItems" :slots="slotsItems" show-expand>
              <template v-slot:qtd_devolution="{ props: { index, item } }">
                <TextFieldPercent v-model="items[index].qtd_devolution"
                  :outlined="false" class="item-input" :readonly="setReadonlyQtdDevolution(item)" />
              </template>
              <template v-slot:qtd_returned="{ props: { item } }">
                <span>{{ utils.formatCurrency(item.qtd_returned, { type: 'decimal' }) }}</span>
              </template>
              <template v-slot:quantity="{ props: { item } }">
                <span>{{ utils.formatCurrency(item.quantity, { type: 'decimal' }) }}</span>
              </template>
              <template v-slot:created_at="{ props: { item } }">
                <span>{{ utils.formatDatePT(item.created_at) }}</span>
              </template>
              <template v-slot:total_value="{ props: { item } }">
                <span>{{ utils.formatCurrency(item.total_value) }}</span>
              </template>
              <template v-slot:content-expanded="{ props: { item } }">
                <Row class="py-10">
                  <Col>
                    <span v-text="'Código do Produto:'" class="d-block font-weight-bold subtitle-1" />
                    <span v-text="item.code" />
                  </Col>
                  <Col>
                    <span v-text="'Valor Uni.:'" class="d-block font-weight-bold subtitle-1" />
                    <span v-text="utils.formatCurrency(item.unit_value)" />
                  </Col>
                  <Col>
                    <span v-text="'Sub Total:'" class="d-block font-weight-bold subtitle-1" />
                    <span v-text="utils.formatCurrency(item.sub_total)" />
                  </Col>
                </Row>
              </template>
            </DataTable>
          </Col>
        </Row>
      </ExpansionPanel>

      <div slot="actions" class="d-flex justify-end" style="width: 100%">
        <Button :label="'Inserir'" class="mr-3" rounded color="secondary"
          @click="addItems" />
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Button,
  Row,
  Col,
  DataTable,
  TextFieldPercent,
} from '@/components/vuetify';
import { formatCurrency, formatDatePT } from '@/utils';

export default {
  name: 'ItemsDataListItemsProductOrderDevolutionItems',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Button,
    Row,
    Col,
    DataTable,
    TextFieldPercent,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    itemsDevolutions: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      utils: {
        formatCurrency,
        formatDatePT
      },
      slotsItems: [
        { field: 'qtd_devolution' },
        { field: 'qtd_returned' },
        { field: 'quantity' },
        { field: 'created_at' },
        { field: 'total_value' },
      ],
    };
  },
  computed: {
    headersOrderSaleItems() {
      return [
        {
          text: 'Devolver',
          value: 'qtd_devolution',
          width: 120
        },
        {
          text: 'Devolvido',
          value: 'qtd_returned',
          width: 110
        },
        {
          text: 'Data Venda',
          value: 'created_at',
          width: 90
        },
        {
          text: 'Venda',
          value: 'order.order_number',
          width: 90
        },
        {
          text: 'Item',
          value: 'item_number'
        },
        {
          text: 'Nome',
          value: 'name',
          width: 320
        },
        {
          text: 'Qtde.',
          value: 'quantity',
          width: 90
        },
        {
          text: 'Vlr. Total',
          value: 'total_value',
          width: 150
        },
      ];
    },
    itemsTable() {
      const items = this.items.map((item) => {
        let qtd_returned = 0;

        this.itemsDevolutions.forEach((itemDevolution) => {
          if (itemDevolution.id === item.id) {
            qtd_returned += itemDevolution.qtd_devolution;
          }
        });

        return {
          ...item,
          qtd_returned,
        };
      });

      return items;
    },
  },
  methods: {
    async addItems() {
      this.$emit('addItemsOrderSale');
    },
    setReadonlyQtdDevolution(item) {
      return item.qtd_returned >= item.quantity;
    }
  },
};
</script>

<style lang="scss" scoped>
.item-input {
  height:  30px !important
}
</style>
