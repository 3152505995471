<template>
  <div>
    <PageHeader :schema="$schemas.typeInstallment" />
    <PageContent>
      <DynamicListPage :schema="$schemas.typeInstallment"
        :service="$api.typeInstallments" :slots="slotItems">

        <template v-slot:use_input_formatted="{ props: { item: { use_input, use_input_formatted } } }">
          <Chip :label="use_input_formatted" :color="use_input ? 'success' : 'primary'" />
        </template>
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Chip } from '@/components/vuetify';

export default {
  name: 'ListTypeInstallments',
  components: { PageHeader, PageContent, DynamicListPage, Chip },
  data () {
    return {
      slotItems: [{ field: 'use_input_formatted' }]
    };
  }
};
</script>
