<template>
  <v-dialog
    v-model="dialog"
    persistent
    :width="maxWidth"
    :max-width="maxWidth"
  >
    <ModalCardDefault :title="title" @close="$emit('noActionDialogConfirmation')" :actions="{ close: textNoBtn }">
      <slot class="mt-5" />
      <h3 class="mt-5" v-text="message" />
      <template v-slot:actions>
        <Button :label="textYesBtn" color="secondary" rounded :dark="colorButtons.noBtn === 'red'"
          @click="$emit('yesActionDialogConfirmation')" class="mr-3" :loading="loading" />
      </template>
    </ModalCardDefault>
  </v-dialog>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import { Button } from '@/components/vuetify';
export default {
  name: 'DialogConfirmation',
  components: { ModalCardDefault, Button },
  props: {
    title: {
      type: String,
      default: 'Deseja realmente excluir este registro?'
    },
    maxWidth: {
      type: Number,
      default: 480
    },
    dialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    colorButtons: {
      type: Object,
      default: () => {
        return {
          noBtn: 'red',
          yesBtn: 'green'
        };
      }
    },
    textYesBtn: {
      type: String,
      default: 'Sim'
    },
    textNoBtn: {
      type: String,
      default: 'Não'
    }
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  methods: {
    actionListener(event) {
      if (this.dialog) {
        if (event.key === 'Escape' && !event.ctrlKey) {
          this.$emit('noActionDialogConfirmation');

          return;
        } else if (event.key === 'Enter' && !event.ctrlKey) {
          this.$emit('yesActionDialogConfirmation');

          return;
        }
      }
    }
  }
};
</script>
