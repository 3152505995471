import DynamicService from '@/service/DynamicService';
import TypeInstallmentSchema from '../schemas/TypeInstallmentSchema';
import { formatCurrency, formatBooleanInYesNo } from '@/utils';
import { typePage } from '@/utils/enums';
import { clone } from 'lodash';

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.use_input_formatted = item.use_input ? 'Sim' : 'Não';
    item.input_percentage_formatted = formatCurrency(item.input_percentage, { type: 'decimal' });
    item.discount_on_total_formatted = formatCurrency(item.discount_on_total, { type: 'decimal' });
    item.input_percentage_formatted = formatCurrency(item.input_percentage, { type: 'decimal' });
  };

  const formatShow = ({ type_installment }) => {
    type_installment.use_input = formatBooleanInYesNo(type_installment.use_input);
  };

  type === typePage.LIST ? formatList(item) : formatShow(item);
};

const formatRequest = (data) => {
  const item = clone(data);

  return item;
};

const TypeInstallmentsService = DynamicService('type_installments',
  TypeInstallmentSchema, {
    formatResponse,
    formatRequest,
    removeNullable: false
  });

export default TypeInstallmentsService;
