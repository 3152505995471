import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { filter } from '../../utils/filter';

export const getAll = (server) => {
  server.get('/order_services', (schema, request) => {
    const orderServices = schema.orderServices.all();
    const { page, perPage } = request.queryParams;
    orderServices.models = filter(request.queryParams, orderServices);
    orderServices.models = softDelete(orderServices);

    return pagination('order_services', orderServices, page, perPage);
  });
};