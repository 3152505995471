<template>
  <v-app>
    <div style="height: 100%" v-if="!isLoginRegisterPdvPage">
      <NavBar :mode-dark="modeDark" @openCloseSideBar="openCloseSideBar" @setModeDark="setModeDark" />

      <v-card style="min-height: calc(100vh - 65px)" class="mt-0"
        :dark="modeDark.value">
        <div :class="['main', $store.getters.getDarkMode.value ? 'main-dark-mode' : 'main-no-dark-mode']">
          <SideBar ref="refsSideBar" :mode-dark="modeDark" />
          <ContentMain :mode-dark="modeDark" />
        </div>
      </v-card>
    </div>

    <router-view v-else></router-view>

    <div>
      <QuickMenu :dialog="dialogQuickMenu" :type="typeQuickMenu" />
    </div>
  </v-app>
</template>

<script>
import eventBus from '@/utils/eventBus';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import ContentMain from './components/ContentMain';
import QuickMenu from './components/QuickMenu';

export default {
  name: 'App',
  components: {
    NavBar,
    SideBar,
    ContentMain,
    QuickMenu,
  },
  data: () => ({
    appName: process.env.VUE_APP_NAME,
    modeDark: {
      value: false,
      label: 'Modo Escuro'
    },
    dialogQuickMenu: false,
    typeQuickMenu: null,
    dialog: false,
  }),
  provide() {
    return {
      modeDark: this.modeDark
    };
  },
  created () {
    window.addEventListener('beforeunload', this.confirmLeaving);
  },
  mounted() {
    eventBus.$on('handleQuickMenu', this.handleQuickMenu);

    if (!localStorage.getItem(`${this.appName}.themeMode`)) localStorage.setItem(`${this.appName}.themeMode`, 'light');
    localStorage.getItem(`${this.appName}.themeMode`) === 'dark' ? this.modeDark.value = true : this.modeDark.value = false;

    // const userLogged = getUser();
    // if (userLogged.type === 'super') {
    //   this.dialog = true;
    // }
  },
  computed: {
    isLoginRegisterPdvPage() {
      return this.$route.name === 'login' || this.$route.name === 'register'
         || this.$route.name === 'pdv';
    }
  },
  watch: {
    ['modeDark.value']() {
      if (this.modeDark.value) {
        this.modeDark.label = 'Modo Claro';
        localStorage.setItem(`${this.appName}.themeMode`, 'dark');
      } else {
        this.modeDark.label = 'Modo Escuro';
        localStorage.setItem(`${this.appName}.themeMode`, 'light');
      }
    },
  },
  methods: {
    openCloseSideBar() {
      this.$refs.refsSideBar.drawerEvent();
    },
    handleQuickMenu(data) {
      this.typeQuickMenu = data.type;
      this.dialogQuickMenu = !this.dialogQuickMenu;
    },
    setModeDark(data) {
      this.modeDark = data;
      this.$store.dispatch('setDarkMode', this.modeDark);
    },
    confirmLeaving (evt) {
      if (this.$route.meta.typePage !== this.$enums.typePage.LIST
        && this.$route.name !== 'login' && this.$route.name !== 'register') {
        const unsaved_changes_warning = 'You have unsaved changes. Are you sure you wish to leave?';
        evt.returnValue = unsaved_changes_warning;

        return unsaved_changes_warning;
      }
    }
  },
  beforeDestroy() {
    eventBus.$off('handleQuickMenu');
  },
};
</script>

<style scope>
.main-dark-mode {
  background-color: #333 !important;
}

.main-no-dark-mode {
  background-color: #292F4C !important;
}

.main {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #292F4C;
}
</style>
