import CashFlowSchema from './schemas/CashFlowSchema';
import ListCashFlows from './pages/ListCashFlows';
import CreateCashFlow from './pages/CreateCashFlow';

export default [
  {
    path: CashFlowSchema.routes.list.path,
    name: CashFlowSchema.routes.list.name,
    component: ListCashFlows,
    meta: CashFlowSchema.routes.list.meta,
  },
  {
    path: CashFlowSchema.routes.create.path,
    name: CashFlowSchema.routes.create.name,
    component: CreateCashFlow,
    meta: CashFlowSchema.routes.create.meta
  },
  {
    path: CashFlowSchema.routes.show.path,
    name: CashFlowSchema.routes.show.name,
    component: CreateCashFlow,
    meta: CashFlowSchema.routes.show.meta
  },
];
