<template>
  <div>
    <Divider class="my-5 px-5" weight-bold />
    <DataTable no-search no-sync hide-default-footer :items="variationStocksItems"
      :headers="$schemas.product.variations.list.headersVariationStocks" class="px-5">
    </DataTable>
    <Divider class="my-5 px-5" weight-bold />
  </div>
</template>

<script>
import {
  Divider,
  DataTable,
} from '@/components/vuetify';

export default {
  name: 'ListProductVariationStocks',
  components: {
    Divider,
    DataTable,
  },
  props: {
    variationStocksItems: {
      type: Array,
      required:  true,
    }
  }
};
</script>
