<template>
  <div>
    <h4 class="title-section" v-text="l.title" />
    <Row class="mt-0 border-all-row">
      <Col md="6" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.contributorData" />
        <p v-if="isAuthorized" v-html="model.additional_information" :style="isAuthorized ? 'height: 130px' : ''" />
        <TextArea v-else v-model="model.additional_information" :rows="4" :outlined="false" />
      </Col>
      <Col md="6" class="py-0 d-flex flex-column justify-center border-left-col">
        <p class="caption" v-text="l.taxData" />
        <p v-if="isAuthorized" v-html="model.additional_tax_information" :style="isAuthorized ? 'height: 130px' : ''" />
        <TextArea v-else v-model="model.additional_tax_information" :rows="4" :outlined="false" />
      </Col>
    </Row>
  </div>
</template>

<script>
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';

import {
  Row,
  Col,
  TextArea,
} from '@/components/vuetify';

export default {
  name: 'AdditionalData',
  components: {
    Row,
    Col,
    TextArea,
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.additionalData;
    },
  },
  mixins: [CreateUpdateProductInvoiceMixin],
};
</script>

<style lang="scss" scoped>
.textfield-money-custom {
  margin: -12px 0 0 0 !important;
  padding: 0 !important;
  height: 32px !important;
}

.select-custom {
  margin-top: -10px !important;
  height: 30px !important;
}
</style>
