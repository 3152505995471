export default {
  actions: {
    downloadXmlFileBatch: 'Download XML em Lote',
  },
  correctionLetter: {
    title: 'Carta de Correção',
    form: {
      title: 'Dados da Carta Correção',
      fields: {
        number: 'Número',
        nfKey: 'Chave da NF-e',
        justify: 'Justificativa',
        justifyText: 'Digite as suas Justificativas',
      }
    },
    actions: {
      confirm: 'Confirmar',
    },
    noty: {
    }
  },
  cancel: {
    title: 'Cancelar NF-e',
    form: {
      title: 'Dados do Cancelamento',
      fields: {
        number: 'Número',
        nfKey: 'Chave da NF-e',
        justify: 'Justificativa',
        justifyText: 'Digite as suas Justificativas',
      }
    },
    actions: {
      confirm: 'Confirmar',
    },
    noty: {
    }
  },
  downloadXml: {
    title: 'Download Xml',
    form: {
      title: 'Clique no Link Abaixo',
    }
  },
  downloadXmlBatch: {
    title: 'Download Xml em Lote',
    form: {
      title: 'Preencha as informações abaixo',
      environment: 'Ambiente',
      period: 'Mês/Ano',
    },
    noty: {
      error: 'Erro ao gerar o arquivo',
      notFiles: 'Não existem arquivos para serem baixados'
    },
    actions: {
      confirm: 'Download',
    }
  },
  changeNumberNFe: {
    title: 'Alterar Número da NF-e',
    form: {
      title: 'Preencha as informações abaixo',
      number: 'Número',
    },
    noty: {
      success: 'Número da NF-e alterado com sucesso',
      error: 'Erro ao alterar o número da NF-e',
    },
    actions: {
      confirm: 'Confirmar',
    }
  },
  events: {
    title: 'Lista dos Eventos',
    dataTable: {
      title: 'Eventos',
      headers: [
        {
          text: 'Data',
          value: 'created_at_formatted',
        },
        {
          text: 'Evento',
          value: 'event',
        },
        {
          text: 'Status Code',
          value: 'status_code',
        },
        {
          text: 'Mensagem',
          value: 'message',
        },
        {
          text: 'Protocolo',
          value: 'protocol',
        }
      ],
      types: {
        cancel_nfe: 'Cancelamento NF-e',
        cancel_nfce: 'Cancelamento NFC-e',
        transmit_nfe: 'Tramissão de NF-e',
        transmit_nfce: 'Tramissão de NFC-e',
        correction_letter: 'Carta de Correção',
      }
    },
    actions: {
    },
    noty: {
    }
  },
  correctionLetters: {
    title: 'Lista Cartas de Correção',
    dataTable: {
      title: 'Cartas de Correção',
      headers: [
        {
          text: 'Data',
          value: 'created_at_formatted',
        },
        {
          text: 'Jutificativa',
          value: 'justify',
        },
        {
          text: 'N° Evento',
          value: 'event_number',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Mensagem',
          value: 'reason',
        },
        {
          text: 'Protocolo',
          value: 'protocol',
        }
      ]
    },
    actions: {
      create: 'Nova Carta de Correção',
    },
    noty: {
    }
  }
};
