export const menuItemsPlanning = [
  {
    active: false,
    menuGroupName: 'planning',
    icon: 'finance',
    title: 'Planejamento',
    items: [
      { icon: 'money', title: 'Fluxo de Caixa', route: 'cashFlows', active: false },
    ],
  },
];
