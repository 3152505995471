export default {
  title: 'Informações de Frete',
  form: {
    freight: {
      label: 'Selecione o Frete',
      placeholder: 'Selecione o Frete',
    },
    total_freight: {
      label: 'Valor Frete',
      placeholder: 'Valor Frete',
    },
    total_expense: {
      label: 'Valor Despesas',
      placeholder: 'Valor Despesas',
    },
    include_total_in_freight: {
      label: 'Incluir Frete no Total',
    }
  },
};