<template>
  <ExpansionPanel v-model="panel" :title="title" :icon="$icons.box" readonly>
    <v-form v-model="validFormInformation" ref="validFormInformation" lazy-validation>
      <Row>
        <Col md="4">
          <AutoComplete v-model="model.register" :placeholder="l.fields.register.placeholder"
            :label="l.fields.register.label" :rules="rulesRegisters" return-object
            domain="register" resultDomain="registers"
            :search-by="['name', 'social_name', 'cpf', 'cnpj', 'phone', 'cell_phone']" item-text-data-serve="social_name"
            title="Pesquise por: Nome, E-mail, Telefone, CPF e CNPJ"
            item-value-data-serve="id" condition-or
            @change="changeAutoComplete($event, 'register', 'register_id')"
            :readonly="isPaid" />
        </Col>
        <Col md="4">
          <TextField v-model="model.title" :placeholder="l.fields.title.placeholder"
            :label="l.fields.title.label" :rules="[rules.required]"
            :readonly="isPaid" />
        </Col>
        <Col md="2">
          <TextFieldMoney v-model="model.value" :placeholder="l.fields.value.placeholder"
            :label="l.fields.value.label" :readonly="isPaid || disableValue" :rules="[rules.money]"
            @blur="changeValue" />
        </Col>
        <Col md="2">
          <TextFieldMoney v-model="model.difference" :placeholder="l.fields.difference.placeholder"
            :label="l.fields.difference.label" readonly :rules="[rules.required]" />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <TextFieldMoney v-model="model.total_value" :placeholder="l.fields.totalValue.placeholder"
          :label="l.fields.totalValue.label" readonly :rules="[rules.money]" />
        </Col>
        <Col md="2">
          <DataPicker v-model="model.emission_date" :placeholder="l.fields.emissionDate.placeholder"
            :label="l.fields.emissionDate.label" :rules="[rules.required]" :readonly="isPaid"
            @change="changeEmissionDate" :clearable="!isPaid" />
        </Col>
        <Col md="2">
          <Select v-model="model.type_installment" :placeholder="l.fields.typeInstallment.placeholder"
            :label="l.fields.typeInstallment.label" :items="type_installments" :rules="[rules.required]"
            return-object :readonly="isPaid || disableTypeInstallments"
            @change="$emit('generateInstallments', $event)" />
        </Col>
        <Col md="3">
          <Select v-model="model.accounting_account" :placeholder="l.fields.accountingAccount.placeholder"
            :label="l.fields.accountingAccount.label" :items="accounting_accounts" :rules="[]"
            return-object :readonly="isPaid" :clearable="!isPaid" />
        </Col>
        <Col md="3">
          <Select v-model="model.cost_center" :placeholder="l.fields.costCenter.placeholder"
            :label="l.fields.costCenter.label" :items="cost_centers" :rules="[]"
            return-object :readonly="isPaid" :clearable="!isPaid" />
        </Col>
      </Row>
    </v-form>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@/components/vuetify/ExpansionPanel';
import {
  Select,
  TextField,
  TextFieldMoney,
  DataPicker,
  Row,
  Col,
  AutoComplete,
} from '@/components/vuetify';
import { required, money } from '@/utils/rules';
import TypePageMixin  from '@/mixins/TypePageMixin';
import { typePage } from '@/utils/enums';
import { statusAccounts } from '@/utils/options';
import { getText } from '@/utils';

export default {
  name: 'Installments',
  components: {
    ExpansionPanel,
    Select,
    Row,
    Col,
    TextField,
    TextFieldMoney,
    DataPicker,
    AutoComplete,
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    registers: {
      type: Array,
      required: true
    },
    type_installments: {
      type: Array,
      required: true
    },
    accounting_accounts: {
      type: Array,
      required: true
    },
    cost_centers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      validFormInformation: false,
      rules: {
        required: required,
        money: money,
      },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.accounts.create.form.information;
    },
    isPaid () {
      return this.model.status === this.$enums.statusAccounts.PAID || false;
    },
    disableValue () {
      return this.typePage === typePage.SHOW || false;
    },
    title () {
      const classStatus = 'ml-2 py-1 px-2 caption rounded-pill';
      const status = getText(statusAccounts, this.model.status);
      let titleInfo = '';

      switch (this.model.status) {
      case this.$enums.statusAccounts.PAID: titleInfo = `<span class="${classStatus} success white--text" >${status}</span>`; break;
      case this.$enums.statusAccounts.PENDING: titleInfo = `<span class="${classStatus} warning white--text" >${status}</span>`; break;
      case this.$enums.statusAccounts.PAIR: titleInfo = `<span class="${classStatus} orange white--text" >${status}</span>`; break;
      }

      return titleInfo;
    },
    disableTypeInstallments () {
      return false;
    },
    rulesRegisters () {
      return this.typePage === typePage.SHOW ? [] : [this.rules.required];
    },
  },
  mixins: [TypePageMixin],
  methods: {
    changeAutoComplete (itemSelected, type, key) {
      this.model[key] = itemSelected.id || '';
      this.model[type] = itemSelected;
    },
    changeValue () {
      if (this.$router.currentRoute.name === this.$schemas.account.routes.accountPayment.create.name ||
        this.$router.currentRoute.name === this.$schemas.account.routes.accountReceivable.create.name) {
        this.model.total_value = this.model.value;
        this.$emit('generateInstallments', this.model.type_installment);
      }
    },
    changeEmissionDate () {
      this.$emit('generateInstallments', this.model.type_installment);
    }
  }
};
</script>
