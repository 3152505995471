<script>
import { Chip, Row, Col } from '@/components/vuetify';
import { HTTP_CODE } from '@/utils/enums';

const STATUS_CHIPS = {
  LOADING: {
    label: 'Aguardando...',
    color: 'warning',
    status: 'loading'
  },
  NOT_FOUND: {
    label: 'Certificado não Localizado',
    color: 'primary',
    status: 'notFound'
  },
  UNAUTHORIZED: {
    label: 'Erro ao buscar status da SEFAZ',
    color: 'primary',
    status: 'unauthorized'
  }
};

export default {
  components: { Chip, Row, Col },
  data () {
    return {
      propsChips: STATUS_CHIPS.LOADING,
      messageDataCompany: '*É preciso completar os dados da empresa para cadastrar um Certificado Digital'
    };
  },
  methods: {
    async getStatusSefaz () {
      this.$api.invoiceSettings.getStatusSefaz().then((res) => {
        this.propsChips = {
          label: res.reason,
          ...res.status_code === '107' && { color: 'success' },
          ...res.status_code !== '107' && { color: 'primary' },
        };
      }).catch((err) => {
        if (err.response.status === HTTP_CODE.NOT_FOUND) {
          this.propsChips = STATUS_CHIPS.NOT_FOUND;
        } else if (err.response.status === HTTP_CODE.BAD_REQUEST) {
          this.propsChips = STATUS_CHIPS.UNAUTHORIZED;
        }
      });
    },
  },
  async mounted () {
    await this.getStatusSefaz();
  }
};
</script>
