import CustomerSchema from './schemas/CustomerSchema';
import ListCustomers from './pages/ListCustomers';
import CreateCustomer from './pages/CreateCustomer';

export default [
  {
    path: CustomerSchema.routes.list.path,
    name: CustomerSchema.routes.list.name,
    component: ListCustomers,
    meta: CustomerSchema.routes.list.meta,
  },
  {
    path: CustomerSchema.routes.create.path,
    name: CustomerSchema.routes.create.name,
    component: CreateCustomer,
    meta: CustomerSchema.routes.create.meta
  },
  {
    path: CustomerSchema.routes.show.path,
    name: CustomerSchema.routes.show.name,
    component: CreateCustomer,
    meta: CustomerSchema.routes.show.meta
  },
];