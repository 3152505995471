<template>
  <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
    <Row>
      <Col md="2">
        <Alert color="primary">
          <h3 v-text="l.totals.value" />
          <h4 v-text="utils.formatCurrency(model.value)" />
        </Alert>
      </Col>
      <Col md="2">
        <Alert color="primary">
          <h3 v-text="l.totals.difference" />
          <h4 v-text="utils.formatCurrency(model.difference)" />
        </Alert>
      </Col>
      <Col md="2">
        <Alert color="primary">
          <h3 v-text="l.totals.totalValue" />
          <h4 v-text="utils.formatCurrency(model.total_value)" />
        </Alert>
      </Col>
      <Col md="2">
        <Alert color="primary">
          <h3 v-text="l.totals.totalPaid" />
          <h4 v-text="utils.formatCurrency(model.paid_value_parcels)" />
        </Alert>
      </Col>
      <Col md="2">
        <Alert color="primary">
          <h3 v-text="l.totals.balance" />
          <h4 v-text="utils.formatCurrency(balance)" />
        </Alert>
      </Col>
    </Row>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@/components/vuetify/ExpansionPanel';
import Row from '@/components/vuetify/Row';
import Col from '@/components/vuetify/Col';
import {
  Alert,
} from '@/components/vuetify';
import { formatCurrency } from '@/utils';

export default {
  name: 'Totalizers',
  components: {
    ExpansionPanel,
    Row,
    Col,
    Alert
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      utils: {
        formatCurrency
      }
    };
  },
  computed: {
    l () {
      return this.$locales.pt.accounts.create.form.totalizers;
    },
    balance () {
      return this.model.total_value - this.model.paid_value_parcels;
    }
  },
  methods: {
  }
};
</script>
