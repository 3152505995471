import DynamicService from '@/service/DynamicService';
import VehicleSchema from '../schemas/VehicleSchema';

const formatResponse = () => {
};

const formatRequest = (item) => {
  return item;
};

const VehiclesService = DynamicService('vehicles', VehicleSchema, { formatResponse,formatRequest });

export default VehiclesService;
