import { dateTime } from '../../utils/dateTime';
import { Response } from 'miragejs';

export const update = (server) => {
  server.put('/nature_operations/:id', (schema, request) => {
    const { id } = request.params;
    const natureOperation = schema.natureOperations.find(id);

    if (!natureOperation) {
      return new Response(404, {}, { errors: {} });
    }

    const attrs = JSON.parse(request.requestBody);
    const { name, type, movement_stock } = attrs;

    if ( !name ) {
      return new Response(422, {}, { errors: {
        name: 'The field name is required',
      } });
    }

    natureOperation.update({ name, type, movement_stock, updated_at: dateTime });
  });
};