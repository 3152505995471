import Vue from 'vue';

const setFieldReadonly = (form, propsFields) => {
  Vue.set(form, 'category_ids', '');
  Vue.set(form, 'sub_category_ids', '');
  Vue.set(form, 'value_min', 0);
  Vue.set(form, 'value_max', 0);
  propsFields.category_ids.readonly = form.all_products;
  propsFields.sub_category_ids.readonly = form.all_products;
  propsFields.value_min.readonly = form.all_products;
  propsFields.value_max.readonly = form.all_products;
};

export default {
  changes: {
    all_products: ({ form, propsFields }) => {
      setFieldReadonly(form, propsFields);
    }
  },
};
