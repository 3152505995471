import { typePageOptions } from '@/utils';
import { accountTie, list } from '@/utils/icons';
import { required } from '@/utils/rules';

export default {
  domain: 'subscriptions',
  domainSingle: 'subscription',
  title: 'Assinaturas',
  description: 'Lista de todas as assinaturas do cliente',
  createBtn: false,
  routes: {
    list: {
      name: 'settings-subscriptions',
      path: '/configuracoes/assinaturas',
      meta: { typePage: typePageOptions.list, requiresAuth: true, }
    },
    show: {
      name: 'settings-subscription-show',
      path: '/configuracoes/assinaturas/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    },
    suspended: {
      name: 'settings-subscription-suspended',
      path: '/configuracoes/assinaturas-suspensa',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    }
  },
  listActions: {
    has: true,
    noShow: true,
    noUpdate: true,
    noDestroy: true,
    more: {
      has: true,
      items: [
        {
          label: 'Ciclos',
          icon: list,
          action: 'listCycles'
        },
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Assinatura',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'dataPicker',
          name: 'start_date',
          formattedName: 'start_date_formatted',
          label: 'Data Inicial',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
        },
        {
          type: 'dataPicker',
          name: 'end_date',
          formattedName: 'end_date_formatted',
          label: 'Data Final',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
        },
        {
          type: 'text',
          name: 'company.name',
          formattedName: 'company',
          label: 'Empresa',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
        },
        {
          type: 'text',
          name: 'plan.name',
          formattedName: 'plan',
          label: 'Plano',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
