<template>
  <ModalCardDefault :title="l.title" @close="$emit('closeModal')">
    <ExpansionPanel v-model="panel" :title="l.form.title"
      :icon="$icons.box" readonly>
      <Row>
        <Col md="6">
          <Select v-model="form.environment" :label="l.form.environment"
            :placeholder="l.form.environment" :items="environmentItems" />
        </Col>
        <Col md="6">
          <DataPicker v-model="form.period" type="month" :label="l.form.period" />
        </Col>
      </Row>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button :label="l.actions.confirm" color="secondary" rounded class="mr-3"
        @click="downloadXmlBatch" :loading="loading" :icon="$icons.download" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  Select,
  DataPicker,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'DownloadXmlBatch',
  components: {
    ExpansionPanel,
    Row,
    Col,
    Select,
    DataPicker,
    Button,
    ModalCardDefault,
  },
  data () {
    return {
      panel: [0],
      loading: false,
      environmentItems: [
        { text: 'Produção', value: 'production' },
      ]
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.list.downloadXmlBatch;
    },
    form () {
      return {
        environment: 'production',
        period: '',
      };
    }
  },
  methods: {
    async downloadXmlBatch () {
      this.loading = true;
      this.$api.productInvoices.downloadXmlBatch(this.form).then((res) => {
        window.open(res.url_download, '_blank');
      }).catch((err) => {
        let message = this.l.noty.error;

        if (err.response.status === this.$enums.HTTP_CODE.BAD_REQUEST ) {
          message = this.l.noty.notFiles;
        }
        this.$noty.error(message);
      }).finally(() => {
        this.loading = false;
        this.$emit('closeModal');
      });
    },
  },
};
</script>
