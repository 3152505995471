<template>
  <div>
    <!-- <span class="d-block mt-3"><b>{{ l.title }}</b></span> -->
    <Row class="border border-dark">
      <Col md="12" class="d-flex flex-column px-0">
        <div class="d-flex w-100">
          <span style="font-size: 10px; font-weight: bold; width: 3%" v-text="'Cod.'" />
          <span style="font-size: 10px; font-weight: bold; width: 35%" v-text="'Produto'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'NCM'" />
          <span style="font-size: 10px; font-weight: bold; width: 3%" v-text="'CST'" />
          <span style="font-size: 10px; font-weight: bold; width: 3%" v-text="'CFOP'" />
          <span style="font-size: 10px; font-weight: bold; width: 3%" v-text="'UNI'" />
          <span style="font-size: 10px; font-weight: bold; width: 4%" v-text="'Quant.'" />
          <span style="font-size: 10px; font-weight: bold; width: 6%" v-text="'Vlr. Uni.'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'Vlr. Total'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'Vlr. B. ICMS'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'Vlr. ICMS'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'Vlr. IPI'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'ALIQ. ICMS'" />
          <span style="font-size: 10px; font-weight: bold; width: 7%" v-text="'ALIQ. IPI'" />
        </div>
      </Col>
    </Row>
    <Row class="" style="min-height: 210px">
      <Col md="12" class="d-flex flex-column px-0" >
        <div class="d-flex w-100" style="height: 13px" v-for="(item, index) in model.items" :key="index">
          <span style="font-size: 10px; width: 3%" v-text="item.code" />
          <span style="font-size: 10px; width: 35%" v-text="item.name" />
          <span style="font-size: 10px; width: 7%" v-text="item.ncm" />
          <span style="font-size: 10px; width: 3%" v-text="item.tax.icms.cst" />
          <span style="font-size: 10px; width: 3%" v-text="item.cfop" />
          <span style="font-size: 10px; width: 3%" v-text="item.unit_measure" />
          <span style="font-size: 10px; width: 4%" v-text="getValue(item.quantity)" />
          <span style="font-size: 10px; width: 6%" v-text="getValue(item.unit_value)" />
          <span style="font-size: 10px; width: 7%" v-text="getValue(item.total_value)" />
          <span style="font-size: 10px; width: 7%" v-text="getValue(item.tax.icms.base)" />
          <span style="font-size: 10px; width: 7%" v-text="getValue(item.tax.icms.value)" />
          <span style="font-size: 10px; width: 7%" v-text="getValue(item.tax.ipi.value)" />
          <span style="font-size: 10px; width: 7%" v-text="getValue(item.tax.icms.aliquot)" />
          <span style="font-size: 10px; width: 7%" v-text="getValue(item.tax.ipi.aliquot)" />
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
} from '@/components/vuetify';
import { formatCurrency } from '@/utils';

export default {
  name: 'Danfe55Items',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.items;
    },
  },
  methods: {
    getValue (value) {
      return formatCurrency(value, { type: 'decimal' });
    }
  }
};
</script>
