export default {
  domain: 'vehicles',
  domainSingle: 'vehicle',
  headersList: [
    {
      text: 'Placa',
      value: 'plate'
    },
    {
      text: 'UF',
      value: 'uf'
    },
    {
      text: 'RNTC',
      value: 'rntc'
    },
    {
      text: 'Ações',
      value: 'actions',
      align: 'right'
    }
  ]
};
