class NatureOperationState {
  get model() {
    return {
      cfop: '',
      uf: '',
      description: '',
      destiny_location: '',
      taxes: {
        icms_cst: '',
        icms_origin: 0,
        icms_aliquot: 0,
        icms_determinate_mode: '',
        icms_exemption_reason: '',
        icms_reduce_value: false,
        icms_reduce_base: false,
        icms_include_discounts: false,
        icms_include_ipi: false,
        icms_include_expenses: false,
        icms_include_freight: false,
        icms_percent_reduce_base: 0,
        icms_aliquot_deferment: 0,
        icms_aliquot_fcp: 0,
        icms_st_determinate_mode: '',
        icms_st_aliquot_mva: 0,
        icms_st_aliquot_icms_internal: 0,
        icms_st_percent_reduce_base: 0,
        icms_st_reduce_value: false,
        icms_st_reduce_base: false,
        icms_st_highlight_icms_own: false,
        icms_st_difal_per_st: false,
        icms_st_include_discounts: false,
        icms_st_include_ipi: false,
        icms_st_include_expenses: false,
        icms_st_include_freight: false,
        icms_st_number_fecop: '',
        icms_st_aliquot_fecop: 0,
        icms_st_aliquot_fcp: 0,
        icms_st_value_pmpf: 0,
        final_consumer_aliquot_interstate: 0,
        final_consumer_aliquot_icms_internal: 0,
        final_consumer_icms_internal_base: false,
        final_consumer_aliquot_fcp: 0,
        final_consumer_aliquot_operation: 0,
        final_consumer_icms_not_uf_origin: false,
        ipi_cst: '',
        ipi_aliquot: 0,
        ipi_class_framework: '',
        ipi_code_framework: '',
        ipi_include_discounts: false,
        ipi_include_expenses: false,
        ipi_include_freight: false,
        pis_cst: '',
        pis_aliquot: 0,
        pis_percent_reduce_base: 0,
        pis_reduce_base: false,
        pis_include_discounts: false,
        pis_include_ipi: false,
        pis_include_expenses: false,
        pis_include_freight: false,
        cofins_cst: '',
        cofins_aliquot: 0,
        cofins_percent_reduce_base: 0,
        cofins_reduce_base: false,
        cofins_include_discounts: false,
        cofins_include_ipi: false,
        cofins_include_expenses: false,
        cofins_include_freight: false,
        additional_information_info_contributor: '',
        additional_information_info_tax: '',
      }
    };
  }
}

export default new NatureOperationState();
