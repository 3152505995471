<template>
  <div>
    <PageHeader :schema="$schemas.creditLetter" />
    <PageContent>
      <DynamicListPage :schema="$schemas.creditLetter" :service="$api.creditLetters"
        @actionMoreActions="actionMoreActions" ref="listCreditLetters"  />
    </PageContent>

    <DialogConfirmation :dialog="dialogConfirmation"
      @yesActionDialogConfirmation="cancelCreditLetter"
      @noActionDialogConfirmation="closeDialogConfirmation"
      message="Deseja Cancelar a Carta de Crédito" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DialogConfirmation from '@/components/DialogConfirmation';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { messageErrors } from '@/utils';

export default {
  name: 'ListCreditLetters',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    DialogConfirmation
  },
  data() {
    return {
      dialogConfirmation: false,
      itemSelected: null,
    };
  },
  methods: {
    actionMoreActions ({ dataListPropsItem: { item }, moreActionItem }) {
      this.itemSelected = item;
      this[moreActionItem.action](moreActionItem);
    },
    closeDialogConfirmation() {
      this.dialogConfirmation = false;
    },
    openCancelCreditLetter() {
      this.dialogConfirmation = true;
    },
    cancelCreditLetter() {
      this.$api.creditLetters.cancel(this.itemSelected.id).then(() => {
        this.$noty.success('Carta de Crédito Cancelada com Sucesso');
        this.$refs.listCreditLetters.fetchDataApi();
      }).catch((err) => {
        if (err.response.status === 400) {
          this.$noty.error(messageErrors(err.response));
        } else if (err.response.status === 404) {
          this.$noty.error('Carta de Crédito não encontrada!');
        } else {
          this.$noty.error('Erro ao cancelar a Carta de Crédito!');
        }
      }).finally(() => {
        this.dialogConfirmation = false;
      });
    }
  }
};
</script>
