import { typePageOptions } from '@/utils';
import { accountTie } from '@/utils/icons';
import { required } from '@/utils/rules';
import { cell_phone, cnpj } from '@/utils/masks';
import { getTypeUser } from '@/utils';
import { TYPE_USER } from '@/utils/enums';

export default {
  domain: 'customers',
  domainSingle: 'customer',
  title: 'Clientes',
  description: 'Lista de todos os clientes cadastrados no sistema',
  createBtn: getTypeUser() === TYPE_USER.RESELLER ? 'Novo Cliente' : false,
  routes: {
    list: {
      name: 'settings-customers',
      path: '/configuracoes/clientes',
      meta: { typePage: typePageOptions.list, requiresAuth: true, }
    },
    create: {
      name: 'settings-customer-create',
      path: '/configuracoes/clientes/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true, }
    },
    show: {
      name: 'settings-customer-show',
      path: '/configuracoes/clientes/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: true,
    noUpdate: true,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Cliente',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'user_name',
          formattedName: 'user_name',
          label: 'Nome',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'text',
          name: 'user_email',
          formattedName: 'user_email',
          label: 'Email',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'password',
          name: 'user_password',
          formattedName: 'user_password',
          label: 'Senha',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          clearable: false,
          disabled: false,
        },
      ],
    },
    {
      title: 'Dados da Empresa',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'company_name',
          formattedName: 'company_name',
          label: 'Nome',
          align: '',
          md: '5',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'simpleMask',
          name: 'company_cnpj',
          formattedName: 'company_cnpj_formatted',
          label: 'CNPJ',
          align: '',
          md: '4',
          rules: [required],
          inputMask: cnpj,
          outputMask: '##############',
          list: false,
          readonly: false,
          clearable: false,
          disabled: false,
        },
        {
          type: 'simpleMask',
          name: 'company_phone',
          formattedName: 'company_phone',
          label: 'Celular',
          align: '',
          md: '3',
          rules: [required],
          inputMask: cell_phone,
          outputMask: '###########',
          list: true,
          readonly: false,
          clearable: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
