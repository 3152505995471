<template>
  <div>
    <PageHeader :schema="schema" />
    <PageContent>
      <Row>
        <Col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <!-- <div>
            <h1>Vendas</h1>
            <apexchart type="line" :options="chartOptionsLine" :series="seriesLine"></apexchart>
          </div> -->
        </Col>
        <Col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <!-- <div>
            <h1>Novos Clientes</h1>
            <apexchart type="bar" :options="chartOptionsBar" :series="seriesBar"></apexchart>
          </div> -->
        </Col>
        <Col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <!-- <div class="d-flex flex-column align-center d-md-block">
            <h1>Mais Vendidos</h1>
            <apexchart type="pie" :options="chartOptionsPie" :series="seriesPie"></apexchart>
          </div> -->
        </Col>
      </Row>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import HomeSchema from '../schemas/HomeSchema';
import { Row, Col } from '@/components/vuetify';

export default {
  name: 'Home',
  components: { PageHeader, PageContent, Row, Col },
  props: {},
  data() {
    return {
      schema: HomeSchema,
      seriesLine: [{
        name: 'Vendas',
        data: [30, 40, 80, 50, 100, 500, 35]
      }],
      chartOptionsLine: {
        chart: {
          id: 'vuechart-exampless'
        },
        xaxis: {
          categories: ['Jan/2023', 'Fev/2023', 'Mar/2023', 'Abr/2023', 'Mai/2023', 'Jun/2023', 'Jul/2023']
        }
      },
      seriesBar: [{
        name: 'series-1',
        data: [15, 30, 18, 12, 11, 25, 17]
      }],
      chartOptionsBar: {
        chart: {
          id: 'vuechart-exampless'
        },
        xaxis: {
          categories: ['Jan/2023', 'Fev/2023', 'Mar/2023', 'Abr/2023', 'Mai/2023', 'Jun/2023', 'Jul/2023']
        }
      },
      seriesPie: [44, 55, 13, 43, 22],
      chartOptionsPie: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['iPhone 14', 'MacBook Pro', 'Samsung S', 'Air Pods', 'iPhone 11'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  },
  mounted () {
    this.$api.auth.me().then((res) => {
      localStorage.setItem(`${process.env.VUE_APP_NAME}.companySetting`, JSON.stringify(res.data.company.settings));
    }).catch(() => {
    });
  }
};
</script>
