<template>
  <div>
    <PageHeader :schema="$schemas.variation" />
    <PageContent>
      <DynamicListPage :schema="$schemas.variation" :service="$api.variations"
        @actionMoreActions="actionMoreActions" />
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @update:dialogModal="dialogModal = $event" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog } from '@/components/vuetify';
import ListVariationValuesVue from './components/ListVariations/ListVariationValues';

export default {
  name: 'ListVariations',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog
  },
  data () {
    return {
      dialogModal: false,
      modalProps: {},
      componentModal: null,
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    variationValues (item) {
      this.modalProps = {
        maxWidth: 600,
        variation: item,
      };

      this.dialogModal = true;
      this.componentModal = ListVariationValuesVue;
    }
  }
};
</script>
