<template>
  <ModalCardDefault :title="l.title" @close="$emit('closeModal')">
    <ExpansionPanel v-model="panel" :title="l.form.title"
      :icon="$icons.box" readonly>
      <Row>
        <Col md="3">
          <TextField v-model="form.environment" :label="l.form.environment"
            readonly :placeholder="l.form.name" />
        </Col>
        <Col md="3">
          <TextField v-model="form.series" :label="l.form.series" readonly
            :placeholder="l.form.series" />
        </Col>
        <Col md="3">
          <TextFieldInteger v-model="form.initial_number" :label="l.form.initialNumber"
            :placeholder="l.form.initialNumber" />
        </Col>
        <Col md="3">
          <TextFieldInteger v-model="form.final_number" :label="l.form.finalNumber"
            :placeholder="l.form.final_number" />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <TextArea v-model="form.justify" :label="l.form.justify" :placeholder="l.form.justifyText"
            :counter="counter" :rules="[rules.minCharacters]" />
        </Col>
      </Row>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button :label="l.actions.confirm" color="secondary" rounded class="mr-3"
        @click="disableNumbers" :loading="loading" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Button,
  TextFieldInteger,
  TextArea,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { minCharacters } from '@/utils/rules';
import { messageErrors } from '@/utils';

export default {
  name: 'DisableNumbers',
  components: {
    ExpansionPanel,
    Row,
    Col,
    TextField,
    Button,
    TextFieldInteger,
    TextArea,
    ModalCardDefault,
  },
  props: {
    invoiceSettingSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      loading: false,
      dialogModal: false,
      counter: 0,
      rules: {
        minCharacters,
      },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.invoiceSettings.list.disableNumbers;
    },
    form () {
      return {
        environment: this.l.form[this.invoiceSettingSelected.environment],
        series: this.invoiceSettingSelected.series,
        initial_number: 1,
        final_number: 1,
        justify: '',
      };
    }
  },
  methods: {
    async disableNumbers () {
      this.loading = true;
      const payload = {
        invoice_setting_id: this.invoiceSettingSelected.id,
        initial_number: this.form.initial_number,
        final_number: this.form.final_number,
        justify: this.form.justify,
      };

      this.$api.invoiceSettings.disableNumbers(payload).then((res) => {
        const type = res.status_code === '102' ? 'success' : 'error';
        this.$noty[type](res.reason);
      }).catch((err) => {
        if (err.response.status === 422) {
          this.$noty.error(messageErrors(err.response));
        } else {
          this.$noty.error(this.l.noty.error);
        }
      }).finally(() => {
        this.loading = false;
        this.$emit('closeModal');
      });
    },
  },
};
</script>
