var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(!_vm.noSearch)?_c('v-col',[_c('TextField',{ref:"searchDataTable",staticClass:"content-appointments--boddy---right----customers-----search-customer",attrs:{"refs":"inputDataTable","label":"pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-col',[_vm._t("custom-header")],2)],1),(_vm.noSync)?_c('v-data-table',{class:[{'row-pointer': _vm.singleSelect}],attrs:{"dense":_vm.dense,"item-key":_vm.itemKey,"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.localItems,"single-select":_vm.singleSelect,"show-select":_vm.showSelect,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items-per-page":_vm.itemsPerPage,"show-expand":_vm.showExpand,"footer-props":{ 'items-per-page-options': [5, 7, 10, 20, 50]}},on:{"click:row":_vm.clickRow,"input":_vm.handleAction},scopedSlots:_vm._u([_vm._l((_vm.slotsHeaders),function(slot){return {key:("header." + (slot.field)),fn:function(header){return [_vm._t(((slot.field) + "Header"),null,null,header)]}}}),_vm._l((_vm.slots),function(slot){return {key:("item." + (slot.field)),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t(slot.field,null,{"props":{ item: item, index: index }})]}}}),{key:"item.actions",fn:function(props){return [_vm._t("actions",null,{"props":props})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"px-1 py-1"},[_vm._t("content-expanded",null,{"props":{ headers: headers, item: item }})],2)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('v-data-table',{class:['data-table', {'row-pointer': _vm.singleSelect}],attrs:{"dense":_vm.dense,"item-key":_vm.itemKey,"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.localItems,"options":_vm.options,"server-items-length":_vm.totalLocalItems,"single-select":_vm.singleSelect,"show-select":_vm.showSelect,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items-per-page":_vm.itemsPerPage,"show-expand":_vm.showExpand,"disable-sort":_vm.disableSort,"footer-props":{ 'items-per-page-options': [5, 7, 10, 20, 50]}},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.clickRow,"input":_vm.handleAction},scopedSlots:_vm._u([_vm._l((_vm.slotsHeaders),function(slot){return {key:("header." + (slot.field)),fn:function(ref){
var header = ref.header;
return [_vm._t(((slot.field) + "Header"),null,null,header)]}}}),_vm._l((_vm.slots),function(slot){return {key:("item." + (slot.field)),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t(slot.field,null,{"props":{ item: item, index: index }})]}}}),{key:"item.actions",fn:function(props){return [_vm._t("actions",null,{"props":props})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"px-1 py-1"},[_vm._t("content-expanded",null,{"props":{ headers: headers, item: item }})],2)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',[_c('v-col',[_vm._t("custom-footer")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }