<template>
  <div style="position: absolut; bottom: ; width: 100%;">
    <span class="d-block mt-3"><b>{{ l.title }}</b></span>
    <Row class="border border-dark" style="min-height: 100px">
      <Col md="6" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.contributorData" />
        <span class="" v-text="model.additional_information" />
      </Col>
      <Col md="6" class="d-flex flex-column">
        <span class="" v-text="l.taxData" />
        <span class="" v-text="model.additional_tax_information" />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55AdditionalData',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.additionalData;
    },
  },
};
</script>
