<template>
  <div>
    <Row class="mt-5">
      <Col md="4">
        <TextFieldPercent v-model="model.final_consumer_aliquot_interstate"
          :placeholder="l.aliquotInterstate.placeholder"
          :label="l.aliquotInterstate.label" />
      </Col>
      <Col md="4">
        <TextFieldPercent v-model="model.final_consumer_aliquot_icms_internal"
          :placeholder="l.aliquotIcmsInternal.placeholder"
          :label="l.aliquotIcmsInternal.label" />
      </Col>
      <Col md="4">
        <CheckBox v-model="model.final_consumer_icms_internal_base"
          @setCheckBox="setCheckBox($event, 'final_consumer_icms_internal_base')"
          :label="l.icmsInternalBase.label" class="mt-0"/>
      </Col>
    </Row>
    <Row>
      <Col md="4">
        <TextFieldPercent v-model="model.final_consumer_aliquot_fcp"
          :placeholder="l.aliquotFcp.placeholder"
          :label="l.aliquotFcp.label" />
      </Col>
      <Col md="4">
        <TextFieldPercent v-model="model.final_consumer_aliquot_operation"
          :placeholder="l.aliquotOperation.placeholder"
          :label="l.aliquotOperation.label" />
      </Col>
      <Col md="4">
        <CheckBox v-model="model.final_consumer_icms_not_uf_origin"
          @setCheckBox="setCheckBox($event, 'final_consumer_icms_not_uf_origin')"
          :label="l.icmsNotUfOrigin.label" class="mt-0"/>
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
  TextFieldPercent
} from '@/components/vuetify';
import CheckBox from '@/components/vuetify/CheckBox';
import { required } from '@/utils/rules';

export default {
  name: 'FinalConsumer',
  components: {
    Row,
    Col,
    TextFieldPercent,
    CheckBox
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      rules: { required: required },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState.form.final_consumer;
    },
  },
  methods: {
    setCheckBox(value, type) {
      this.model[type] = value || false;
    }
  }
};
</script>
