<template>
  <div>
    <h4 class="title-section" v-text="l.title" />
    <Row class="mt-0 border-all-row">
      <Col md="12" class="py-0 d-flex flex-column justify-center">
        <ItemsDataList :client-id="model.client.client_id" :items="model.items"
          :nature-operation-id="model.nature_operation_id" :stock-required="false"
          @addItem="addItem" @removeItem="removeItem"
          :disabled-new-item-button="isAuthorized" :no-destroy="isAuthorized" />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
} from '@/components/vuetify';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import ItemsDataList from '@/components/OrderProductInvoice/ItemsDataList';
import OrderProductInvoiceMixin from '@/mixins/OrderProductInvoiceMixin';

export default {
  name: 'Items',
  components: {
    Row,
    Col,
    ItemsDataList,
  },
  data () {
    return {
      items: []
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.items;
    },
  },
  watch: {
    'model.items': {
      handler () {
        this.calculateTotals();
      },
      deep: true,
    }
  },
  mixins: [CreateUpdateProductInvoiceMixin, OrderProductInvoiceMixin],
};
</script>

<style lang="scss" scoped>
.textfield-money-custom {
  margin: -12px 0 0 0 !important;
  padding: 0 !important;
  height: 32px !important;
}

.select-custom {
  margin-top: -10px !important;
  height: 30px !important;
}
</style>
