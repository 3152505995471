import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('customer', {
    name: 'latin Uyshe',
    email: 'Latin@mail.com',
    cell_phone: '84988481941',
    ...baseFields,
  });
};
