import Vue from 'vue';

export default {
  changes: {
    use_input: ({ form, schema, ref }) => {
      schema.fields[0].items.forEach((field) => {
        if (field.name === 'input_percentage') {
          if (form.use_input === 'no') {
            ref[field.name][0].setDisabled(true);
            field.rules = [];
          } else {
            ref[field.name][0].setDisabled(false);
          }
          Vue.set(form, 'input_percentage', 0);
        }
      });
    }
  },
};
