<template>
  <ModalCardDefault :title="l.title" @close="$emit('close')">
    <ExpansionPanel v-model="panel" title="Lista dos Veículos" :icon="$icons.box" readonly>
      <DataTable no-search no-sync hide-default-footer :items="vehicles"
        :headers="$schemas.vehicle.headersList" class="px-5">

        <template v-slot:actions="{ props: { item } }">
          <ButtonIcon color="red" x-small :icon="$icons.destroy"
            @click="removeVehicle(item.id)" :tooltip="l.tooltips.remove" />
        </template>
      </DataTable>
    </ExpansionPanel>

    <Dialog :dialog="dialogModal" no-title :maxWidth="600">
      <CreateVehicles slot="content" :register-selected-id="item.id"
        @update:dialogModal="dialogModal = $event" @insertVehicleInList="insertVehicleInList" />
    </Dialog>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button :label="l.actions.create" class="mr-3" rounded color="secondary"
        @click="openCreateVehicle" />
    </div>
  </ModalCardDefault>
</template>

<script>
import {
  DataTable,
  ButtonIcon,
  Button,
  Dialog,
  ExpansionPanel,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateVehicles from './CreateVehicles';

export default {
  name: 'ListVehicles',
  components: {
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Button,
    Dialog,
    CreateVehicles,
    ExpansionPanel,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      dialogModal: false,
      vehicles: []
    };
  },
  computed: {
    l () {
      return this.$locales.pt.vehicles.list;
    },
  },
  watch: {
    item () {
      this.vehicles = this.item.vehicles;
    }
  },
  methods: {
    removeVehicle (id) {
      this.$api.vehicles.delete(id).then(() => {
        this.$noty.success(this.$locales.pt.vehicles.list.noty.successDelete);
        this.vehicles = this.vehicles.filter(vehicle => vehicle.id !== id);
      }).catch(() => {
        this.$noty.error(this.$locales.pt.vehicles.list.noty.errorDelete);
      });
    },
    insertVehicleInList (vehicle) {
      this.vehicles.push(vehicle);
      this.dialogModal = false;
    },
    openCreateVehicle () {
      this.dialogModal = true;
    },
  },
  mounted () {
    this.vehicles = this.item.vehicles;
  }
};
</script>

<style>

</style>
