<template>
  <ModalCardDefault :title="'Selecione uma das opções'" :displayNoButton="false">
    <template v-slot:actions>
      <Button color="success"
        rounded @click="nfce" class="mr-3" :loading="loading">
        <span class="text-button">Emitir NFC-e
            <span class="text-button font-weight-black yellow--text">(Crtl + B)</span>
          </span>
      </Button>
      <Button color="secondary"
        rounded @click="coupon" class="mr-3">
        <span class="text-button">Cupom não Fiscal
            <span class="text-button font-weight-black yellow--text">(Crtl + M)</span>
          </span>
      </Button>
      <Button color="primary"
        rounded @click="$emit('update:dialogModal', false)">
        <span class="text-button">Fechar
            <span class="text-button font-weight-black yellow--text">(Esc)</span>
          </span>
      </Button>
    </template>
  </ModalCardDefault>
</template>

<script>
import { Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import InputFocusMixin from '@/mixins/InputFocusMixin';

export default {
  name: 'PdvPrintConfirmation',
  components: { ModalCardDefault, Button },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  mixins: [InputFocusMixin],
  methods: {
    nfce() {
      this.$emit('confirmDialog', 'nfce');
    },
    coupon() {
      this.$emit('confirmDialog', 'coupon');
    },
    actionListener(event) {
      if (this.dialog) {
        if (event.ctrlKey) {
          if (event.key === 'm' || event.key === 'M') {
            this.$emit('confirmDialog', 'coupon');
          } else if (event.key === 'b' || event.key === 'B') {
            this.$emit('confirmDialog', 'nfce');
          }
        }

        if (event.key === 'Escape') {
          this.$emit('update:dialogModal', false);
        }
      }

    }
  },
};
</script>
