import DynamicService from '@/service/DynamicService';
import MovementSchema from '../schemas/MovementSchema';
import { formatCurrency, formatDatePT } from '@/utils';

const formatResponse = (item) => {
  item.type = item.account_parcel.account.type === 'account-receivable' ? 'Entrada' : 'Saída';
  item.value_formatted = formatCurrency(item.value);
  item.paid_date_formatted = formatDatePT(item.paid_date);
};

const formatRequest = () => {

};

const FinancialMovementsService = DynamicService('account_parcel_payments', MovementSchema, { formatResponse, formatRequest });

export default FinancialMovementsService;
