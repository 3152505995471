<template>
  <div>
    <PageHeader :schema="$schemas.invoiceSetting" />
    <PageContent>
      <Button :label="l.actions.certificate" rounded color="secondary"
        v-if="propsChips.status !== 'unauthorized'"
        @click="openDialog('Certificate', 'certificateData')" small
        :icon="$icons.certificate" class="py-4" />
      <DynamicListPage :schema="$schemas.invoiceSetting"
        :service="$api.invoiceSettings" @actionMoreActions="actionMoreActions"
        :slots="slotsItems">
        <template v-slot:main_formatted="{ props: { item } }">
          <Chip small :label="item.main_formatted" :color="getColorMain(item.main)" />
        </template>
        <template v-slot:environment_formatted="{ props: { item } }">
          <Chip small :label="item.environment_formatted" :color="getColorEnvironment(item.environment)" />
        </template>
      </DynamicListPage>
      <Row>
        <Col>
          <Chip v-bind="propsChips" class="p-5" :icon="$icons.web" />
          <small v-if="propsChips.status === 'unauthorized'"
           class="mt-5 d-block">{{ messageDataCompany }}</small>
        </Col>
      </Row>

      <Dialog :dialog="dialogModal" :max-width="1100">
        <template v-slot:content>
          <component :is="componentModal" v-bind="propsComponentModal"
            v-on="eventsComponentModal" />
        </template>
      </Dialog>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import {
  Chip,
  Button,
  Dialog,
} from '@/components/vuetify';
import Certificate from '../components/Certificate';
import DisableNumbers from '../components/DisableNumbers';
import ListEvents from '../components/ListEvents';
import GetStatusSefazMixin from '@/mixins/GetStatusSefazMixin';

export default {
  name: 'ListInvoiceSettings',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Chip,
    Button,
    Dialog,
    Certificate,
    DisableNumbers,
    ListEvents
  },
  data () {
    return {
      dialogModal: false,
      invoiceSettingSelected: {},
      componentModal: null,
      propsComponentModal: {},
      eventsComponentModal: {},
      slotsItems: [
        { field: 'main_formatted' },
        { field: 'environment_formatted' },
      ]
    };
  },
  computed: {
    l () {
      return this.$locales.pt.invoiceSettings.list;
    }
  },
  mixins: [GetStatusSefazMixin],
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this.invoiceSettingSelected = item;
      this.openDialog(moreActionItem.component, moreActionItem.action);
    },
    openDialog (component, action) {
      this.componentModal = component;
      this.propsComponentModal = {
        ...action === 'certificate' && {},
        ...(action === 'disableNumbers' || action === 'invoiceEvents') &&
          { invoiceSettingSelected: this.invoiceSettingSelected },
      };

      this.eventsComponentModal = {
        closeModal: this.closeModal,
      };
      this.dialogModal = !this.dialogModal;
    },
    closeModal (certificateUploadSuccess) {
      if (certificateUploadSuccess) {
        this.propsChips = {
          label: 'Aguardando...',
          color: 'warning'
        };
        this.getStatusSefaz();
      }
      this.dialogModal = !this.dialogModal;
    },
    getColorMain (main) {
      return main ? 'success' : '';
    },
    getColorEnvironment (environment) {
      return environment === this.$enums.ENVIRONMENT_INVOICE.production ? 'success' : 'orange';
    }
  },
};
</script>
