<template>
  <ModalCardDefault :title="'Diversos'" :displayNoButton="false">
    <TextFieldMoney v-model="value" ref="refValue" placeholder="Valor"
      label="Valor" />

    <template v-slot:actions>
      <Button color="secondary"
        rounded @click="confirm" class="mr-3">
        <span class="text-button">Confirmar
            <span class="text-button font-weight-black yellow--text">(Enter)</span>
          </span>
      </Button>
      <Button color="primary"
        rounded @click="$emit('update:dialogModal', false)">
        <span class="text-button">Fechar
            <span class="text-button font-weight-black yellow--text">(Esc)</span>
          </span>
      </Button>
    </template>
  </ModalCardDefault>
</template>

<script>
import { TextFieldMoney, Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import { getCompanySettings } from '@/utils';

export default {
  name: 'PdvSeveralProduct',
  components: { ModalCardDefault, TextFieldMoney, Button },
  data () {
    return {
      value: 0
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  mixins: [InputFocusMixin],
  methods: {
    confirm () {
      if (!this.value || this.value == 0) {
        this.$noty.error('Digite o valor');

        return false;
      }

      const companySetting = getCompanySettings();
      this.$api.products.getProductVariationById(companySetting.several_product_id).then((res) => {
        this.$emit('addItemSeveralProduct', {
          ...res,
          price: this.value,
          ncm: res.product.ncm,
          cest: res.product.cest,
          unit_measure: res.product.unit_measure,
        });
        this.value = 0;
      });
    },
    actionListener(event) {
      if (event.key === 'Enter') {
        // this.confirm();
      }
    }
  },
  mounted () {
    this.focusInputs(this.$refs.refValue.$children[0].$children[0]);
  }
};
</script>
