import eventBus from '@/utils/eventBus';

export default (schema) => {
  return {
    mounted() {
      schema.listActions.more.items.forEach(item => {
        eventBus.$on(item.action, this[item.action]);
      });
    }
  };
};
