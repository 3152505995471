<template>
  <div>
    <ModalCardDefault :title="title" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Lista dos Movimentos'" :icon="$icons.box" readonly>
        <DataTable no-search :items="movements"
          :headers="headers" :slots="slotsItems" :items-per-page="5" :loading="loading"
          @getItems="getMovements">

          <template v-slot:movement_type="{ props: { item: { movement_type } } }">
            <Chip small :color="getColorChip(movement_type)"
              :label="l.movementType[movement_type]" />
          </template>

          <template v-slot:actions="{ props: { item: { origin } } }">
            <ButtonIcon v-if="origin.route_name" x-small color="secondary" :icon="$icons.redo" :tooltip="l.tooltips.origin"
              @click="openOrigin(origin)" />
          </template>
        </DataTable>
      </ExpansionPanel>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  DataTable,
  ButtonIcon,
  Chip,
  ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import OpenOriginMixin from '@/mixins/OpenOriginMixin';
import ListStockMovementsMixin from '@/views/Products/StockMovements/mixins/ListStockMovementsMixin';

export default {
  name: 'ListMovements',
  components: {
    DataTable,
    ButtonIcon,
    Chip,
    ExpansionPanel,
    ModalCardDefault
  },
  props: {
    stockSelected: {
      type: Object,
      default: () => {},
    },
    productVariation: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      slotsItems: [
        { field: 'movement_type' },
      ],
      movements: [],
      loading: false,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.stocks.list.modalMovements;
    },
  },
  watch: {
    stockSelected: {
      handler () {
        this.getMovements();
      },
      deep: true,
    },
    productVariation: {
      handler () {
        this.getMovements();
      },
      deep: true,
    },
  },
  mixins: [OpenOriginMixin, ListStockMovementsMixin],
  methods: {
    getMovements(pagination) {
      this.loading = true;

      if (this.stockSelected) {
        this.$api.stocks.getAllMovementsByStockId(this.stockSelected.id, pagination).then((res) => {
          this.movements = res;
        }).catch(() => {
          this.$noty.error(this.l.noty.errorGetAllVariations);
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$api.stocks.getAllMovementsByProductVariationId(this.productVariation.id, pagination).then((res) => {
          this.movements = res;
        }).catch(() => {
          this.$noty.error(this.l.noty.errorGetAllVariations);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
  mounted () {
    this.getMovements();
  },
};
</script>

<style>

</style>
