var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field-simplemask',_vm._g(_vm._b({attrs:{"label":_vm.label,"properties":{
    id: _vm.id,
    refs: _vm.refs,
    name: _vm.name,
    readonly: _vm.readonly,
    disabled: _vm.localDisabled,
    outlined: _vm.outlined,
    clearable: _vm.clearable,
    dense: _vm.dense,
    rules: _vm.rules,
    'prepend-icon': _vm.icon,
    counter: _vm.counter
  },"options":{
    inputMask: _vm.inputMask,
    outputMask: _vm.outputMask,
    applyAfter: _vm.applyAfter,
    empty: _vm.empty,
    alphanumeric: _vm.alphanumeric,
  }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-text-field-simplemask',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }