import { Response } from 'miragejs';
import { baseFields } from '../../utils/baseFields';

export const create = (server) => {
  server.post('/products', (schema, request) => {
    const attrs = JSON.parse(request.requestBody);
    const { name, price, quantity } = attrs;

    if ( !name || price || quantity ) {
      return new Response(422, {}, { errors: {
        name: 'The field type is required',
        price: 'The field price is required',
        quantity: 'The field quantity is required',
      } });
    }

    const product = {
      ...attrs,
      ...baseFields,
    };

    const response = schema.products.create(product);

    return { product: response.attrs };
  });
};