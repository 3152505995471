<template>
  <div>
    <PageHeader :schema="$schemas.subscription" />
    <PageContent>
      <DynamicListPage ref="DynamicListPage" :schema="$schemas.subscription"
        :service="$api.subscriptions" @actionMoreActions="actionMoreActions" />

      <Row class="mt-10">
        <Col md="6">
          <h2 class="font-weight-black">Mais eficiência na sua rotina com o plano ideal para alavancar o seu negócio</h2>
          <h4 class="mt-3">Mais eficiência na sua rotina com o plano ideal para alavancar o seu negócio
Simplifique a operação da sua empresa com um sistema de gestão prático para o seu melhor desempenho.</h4>
        </Col>
        <Col md="2"></Col>
        <Col md="2">
          <Card style="border: 1px solid #CCC">
          <div class="d-flex flex-column align-center">
            <h2 style="color: #000">Mensal | Anual</h2>
            <CustomSwitch v-model="period" inset @click="setPeriod" />
            <span style="text-align: center">Com os nossos planos anuais seu negócio economiza muito mais</span>
          </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col v-for="(plan, index) in plans" :key="index">
          <div class="d-flex flex-column align-center mt-10">
            <Card min-width="250px" style="border: 1px solid #CCC">
              <h2 v-text="plan.name" class="font-weight-bold" style="color: #000" />
              <h4 v-text="plan.description" />
              <h1 class="font-weight-black mt-5" style="color: #000">
                {{ utils.formatCurrency(plan.price) }} <span class="font-weight-regular text-h6" style="color: #999">/{{ getPeriodFormatted(plan.period) }}</span>
              </h1>
              <div class="d-flex flex-column align-center mt-5">
                <Button :label="getActionLabel()" rounded :color="getActionColor()" large
                  @click="openModalCreateSubscription(plan)" />
              </div>
              <Divider class="my-5" />
              <div v-for="(planSetting, index) in plan.plan_settings" :key="index" class="d-flex align-center mt-2" style="color: #000">
                <Icon :icon="getIcon(planSetting.label)" class="mr-3" />
                <span v-text="`${planSetting.description}`" class="mt-1" />
              </div>
              <Divider class="my-5" />
              <div class="d-flex flex-column justify-center mt-2">
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Frente de Caixa (PDV)
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Emissão de NF-e e NFC-e
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Gestão de Produtos e Estoque
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Contas a pagar e a Receber
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Suporte via e-mail e whatsapp
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Fluxo de Caixa
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Relatórios
                </span>
                <span class="mt-3">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  Operações Fiscais
                </span>
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
        <component :is="componentModal" slot="content" v-bind="modalProps"
          @close="closeModal" />
      </Dialog>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import {
  Row,
  Col,
  CustomSwitch,
  Card,
  Button,
  Divider,
  Icon,
  Dialog,
} from '@/components/vuetify';
import CreateSubscription from './components/CreateSubscription';
import CyclesSubscription from './components/CyclesSubscription';
import { formatCurrency } from '@/utils';

const PERIOD = Object.freeze({
  monthly: 'monthly',
  annual: 'annual'
});

export default {
  name: 'ListSubscriptions',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Row,
    Col,
    CustomSwitch,
    Card,
    Button,
    Divider,
    Icon,
    Dialog,
  },
  data () {
    return {
      dialogModal: false,
      utils: {
        formatCurrency
      },
      componentModal: null,
      period: false,
      periodHint: 'Mensal',
      plans: [],
      planSelected: {},
      subscriptionSelected: {},
      modalProps: {}
    };
  },
  computed: {
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    setPeriod () {
      this.period = !this.period;
      this.period ? this.getPlans(PERIOD.annual) : this.getPlans (PERIOD.monthly);
    },
    async getPlans (period = PERIOD.monthly) {
      this.$api.plans.index({ paginate: { page: 1, perPage: 10 }, sort: { sortBy: 'order', sortDesc: 'asc' }}).then(({ data }) => {
        this.plans = data.plans.filter(plan => plan.period === period && plan.label !== 'free');
      });
    },
    getIcon (label) {
      let icon = '';

      switch (label) {
      case 'user': icon = this.$icons.users; break;
      case 'nfe': icon = this.$icons.fileDocumentCheckOutline; break;
      case 'nfce': icon = this.$icons.receiptTextCheckOutline; break;
      case 'products': icon = this.$icons.box; break;
      }

      return icon;
    },
    getActionLabel () {
      return 'Assinar Agora';
    },
    getActionColor () {
      return 'secondary';
    },
    openModalCreateSubscription (plan) {
      this.componentModal = CreateSubscription;
      this.modalProps = {
        planSelected: plan,
        maxWidth: 750
      };
      this.planSelected = plan;
      this.dialogModal = true;
    },
    closeModal () {
      this.componentModal = null;
      this.planSelected = {};
      this.subscriptionSelected = {};
      this.modalProps = {};
      this.dialogModal = false;
      this.$refs.DynamicListPage.index();
    },
    listCycles (item) {
      this.componentModal = CyclesSubscription;
      this.modalProps = {
        subscriptionSelected: item,
        maxWidth: 1100
      };
      this.subscriptionSelected = item;
      this.dialogModal = true;
    },
    getPeriodFormatted (period) {
      switch (period) {
      case 'monthly': return 'mês';
      case 'annual': return 'ano';
      }
    }
  },
  async mounted () {
    await this.getPlans();
  }
};
</script>
