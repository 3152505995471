<template>
  <div>
    <h4 class="title-section" v-text="l.title" />
    <v-form v-model="validTransporter" ref="formTransporter" lazy-validation>
      <Row class="mt-0 border-all-row">
        <Col md="4" class="py-0">
          <p class="caption mt-2" v-text="l.name" />
          <p v-if="isAuthorized" v-html="model.transporter.name || '&nbsp;'" />
          <Select v-else v-model="transporterId" :items="transporters"
            :outlined="false" item-value="id" item-text="name"
            @change="changeTransporter($event)" clearable class="margin-top-custom-1" />
        </Col>
        <Col md="3" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.document" />
          <p v-if="isAuthorized" v-text="model.transporter.document_formatted" />
          <TextFieldSimpleMask v-else v-model="model.transporter.document"
            :outlined="false" :input-mask="getMaskDocument('input', model.transporter.person_type)"
            :output-mask="getMaskDocument('output', model.transporter.person_type)"
            class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.ie" />
          <p v-if="isAuthorized" v-text="model.transporter.ie" />
          <TextFieldInteger v-else v-model="model.transporter.ie" :outlined="false"
            class="margin-top-custom-3" />
        </Col>
        <Col md="3" class="py-0 border-left-col">
          <p class="caption mt-2" v-text="l.freightModality" />
          <p v-if="isAuthorized" v-text="model.transporter.freight_modality_formatted" />
          <Select v-else v-model="model.transporter.freight_modality"
          :items="options.freights" :outlined="false"
          class="margin-top-custom-1" :rules="[rules.required]" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.zipCode" />
          <p v-if="isAuthorized" v-html="model.transporter.zip_code_formatted || '&nbsp;'" />
          <TextFieldSimpleMask v-else v-model="model.transporter.zip_code" :outlined="false"
            :input-mask="masks.zipCode" output-mask="########"
            class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.country" />
          <p v-if="isAuthorized" v-text="model.transporter.country" />
          <TextField v-else v-model="model.transporter.country"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.uf" />
          <p v-if="isAuthorized" v-text="model.transporter.uf" />
          <TextField v-else v-model="model.transporter.uf"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="3" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.city" />
          <p v-if="isAuthorized" v-text="model.transporter.city" />
          <TextField v-else v-model="model.transporter.city"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.neighborhood" />
          <p v-if="isAuthorized" v-text="model.transporter.neighborhood" />
          <TextField v-else v-model="model.transporter.neighborhood"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.street" />
          <p v-if="isAuthorized" v-html="model.transporter.street || '&nbsp;'" />
          <TextField v-else v-model="model.transporter.street"
            :outlined="false" class="margin-top-custom-1"  />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.number" />
          <p v-if="isAuthorized" v-text="model.transporter.number" />
          <TextField v-else v-model="model.transporter.number"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.complement" />
          <p v-if="isAuthorized" v-text="model.transporter.complement" />
          <TextField v-else v-model="model.transporter.complement"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.plate" />
          <p v-if="isAuthorized" v-text="model.transporter.vehicle_plate" />
          <Select v-else v-model="vehicleSelected" :items="vehicles"
            :outlined="false" class="margin-top-custom-1"
            :rules="[rules.required]"
            item-value="id" item-text="plate"
            @change="changeVehicle" return-object clearable />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.vehicleUf" />
          <p v-if="isAuthorized" v-text="model.transporter.vehicle_uf" />
          <TextField v-else v-model="model.transporter.vehicle_uf"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.rntc" />
          <p v-if="isAuthorized" v-html="model.transporter.vehicle_rntc || '&nbsp;'" />
          <TextField v-else v-model="model.transporter.vehicle_rntc"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.liquidWeight" />
          <p v-if="isAuthorized" v-text="model.transport.liquid_weight" />
          <TextFieldInteger v-else v-model="model.transport.liquid_weight"
            :outlined="false" :rules="[rules.required]"
            class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.grossWeight" />
          <p v-if="isAuthorized" v-text="model.transport.gross_weight" />
          <TextFieldInteger v-else v-model="model.transport.gross_weight"
            :outlined="false" :rules="[rules.required]"
            class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.brand" />
          <p v-if="isAuthorized" v-text="model.transport.brand" />
          <TextField v-else v-model="model.transport.brand"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.specie" />
          <p v-if="isAuthorized" v-text="model.transport.specie" />
          <TextField v-else v-model="model.transport.specie"
            :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0 border-left-col">
          <p class="caption mt-2" v-text="l.quantity" />
          <p v-if="isAuthorized" v-text="model.transport.quantity" />
          <TextFieldPercent v-else v-model="model.transport.quantity"
            :outlined="false" class="margin-top-custom-3"
            :rules="[rules.required]" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.freightInclude" />
          <p v-if="isAuthorized" v-html="model.transport.freight_include ? 'Sim' : 'Não'" />
          <CheckBox v-else v-model="model.transport.freight_include"
            @setCheckBox="setFreightInclude" class="my-0 py-0" style="height: 22px" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.freightValue" />
          <p v-if="isAuthorized" v-text="model.transport.freight_value_formatted" />
          <TextFieldMoney v-else v-model="model.transport.freight_value"
            :outlined="false" @blur="calculateTotals()"
            class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.othersExpenses" />
          <p v-if="isAuthorized" v-text="model.transport.other_expenses_formatted" />
          <TextFieldMoney v-else v-model="model.transport.other_expenses"
            :outlined="false" @blur="calculateTotals()"
            class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.safeValue" />
          <p v-if="isAuthorized" v-text="model.transport.safe_value_formatted" />
          <TextFieldMoney v-else v-model="model.transport.safe_value"
            :outlined="false" @blur="calculateTotals()"
            class="margin-top-custom-3" />
        </Col>
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.cte" />
          <p v-if="isAuthorized" v-text="model.transport.cte" />
          <TextField v-else v-model="model.transport.cte" :outlined="false"
            class="margin-top-custom-1"  />
        </Col>
      </Row>
    </v-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  TextField,
  Select,
  CheckBox,
  TextFieldSimpleMask,
  TextFieldMoney,
  TextFieldPercent,
  TextFieldInteger
} from '@/components/vuetify';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import { freights } from '@/utils/options';
import { personType } from '@/utils/enums';

export default {
  name: 'Transporter',
  components: {
    Row,
    Col,
    TextField,
    Select,
    CheckBox,
    TextFieldSimpleMask,
    TextFieldMoney,
    TextFieldPercent,
    TextFieldInteger
  },
  props: {
    transporters: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      validTransporter: false,
      options: { freights },
      vehicles: [],
      vehicleSelected: {},
      transporterId: '',
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.transporter;
    },
  },
  watch: {
    'model.transporter' () {
      this.transporterId = this.model.transporter.transporter_id;

      if (this.model.transporter.vehicle_id) {
        const vehicle = {
          id: this.model.transporter.vehicle_id,
          plate: this.model.transporter.vehicle_plate
        };
        this.vehicles = [vehicle];
        this.vehicleSelected = vehicle;
      }
    },
  },
  mixins: [CreateUpdateProductInvoiceMixin],
  methods: {
    setFreightInclude (value) {
      this.model.transport.freight_include = value;
      this.calculateTotals();
    },
    changeTransporter (item) {
      if (item) {
        const transporter = this.transporters.find((transporter) => {
          return transporter.id === this.transporterId;
        });
        transporter.person_type === personType.PHYSICAL ?
          transporter.document = transporter.cpf : transporter.document = transporter.cnpj;

        const freight_modality = this.model.transporter.freight_modality;
        this.model.transporter = {
          product_invoice_id: this.model.id,
          freight_modality,
          transporter_id: transporter.id,
          ...transporter,
          ...transporter.address,
          ...!transporter.id && {
            ie: '',
            vehicle_id: null,
            vehicle_plate: null,
            vehicle_uf: null,
            vehicle_rntc: null,
          },
        };
        delete this.model.transporter.address;

        this.vehicles = [];

        if (transporter.vehicles) {
          this.vehicleSelected = {};
          this.vehicles = transporter.vehicles;
        }
      } else {
        this.model.transporter = {};
        this.vehicles = [];
      }
    },
    changeVehicle () {
      this.model.transporter.vehicle_id = this.vehicleSelected.id;
      this.model.transporter.vehicle_plate = this.vehicleSelected.plate;
      this.model.transporter.vehicle_uf = this.vehicleSelected.uf;
      this.model.transporter.vehicle_rntc = this.vehicleSelected.rntc;
    },
  },
};
</script>
