<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      :nudge-right="40"
      :disabled="disabled || readonly"
      offset-y
      min-width="auto">

      <template v-slot:activator="{ on, attrs }">
        <TextField
          v-model="dateFormatted"
          v-on="on"
          v-bind="attrs"
          :outlined="outlined"
          :dense="dense"
          :label="label"
          :icon="icon"
          :readonly="true"
          :disabled="disabled"
          :clearable="clearable"
          :rules="rules" />
      </template>
      <v-date-picker
        v-model="picker"
        v-on="$listeners"
        :readonly="readonly"
        :disabled="disabled"
        :type="type"
        @input="menu = false" />
    </v-menu>
  </div>
</template>

<script>
import { formatDate, formatDateEN } from '@/utils';
import TextField from '@/components/vuetify/TextField';

export default {
  name: 'DataPicker',
  components: { TextField },
  props: {
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'date',
    },
  },
  watch: {
    picker: {
      handler() {
        this.dateFormatted = formatDate(this.picker) || '';

        if (this.type === 'month') {
          this.dateFormatted = this.dateFormatted.substr(3, 7);
        }

      },
      deep: true
    },
    $attrs: {
      handler() {
        this.getDate();
      },
      deep: true
    },
    dateFormatted: {
      handler() {
        if (!this.dateFormatted) {
          this.picker = null;
          this.$attrs.value = null;
          this.dateFormatted = null;
          this.$emit('clearDate');
        }
      },
      deep: true
    },
  },
  mounted() {
    this.getDate();
  },
  data() {
    return {
      menu: false,
      dateFormatted: '',
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  methods: {
    getDate() {
      this.dateFormatted = formatDate(this.$attrs.value);
      this.picker = formatDateEN(this.dateFormatted);
    }
  }
};
</script>
