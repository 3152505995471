import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';

export default {
  domain: 'account_parcels',
  domainSingle: 'account_parcel',
  title: 'Títulos em Aberto',
  description: 'Lista de todos os Títulos em Aberto do sistema',
  showInactive: false,
  routes: {
    list: {
      name: 'open-titles',
      path: '/titulos-abertos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'open-title-create',
      path: '/titulos-abertos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'open-title-show',
      path: '/titulos-abertos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: [
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Título',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'title',
          formattedName: 'title',
          label: 'N° Título',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'due_date',
          formattedName: 'due_date_formatted',
          label: 'Vencimento',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        // {
        //   type: 'text',
        //   name: 'paid_date',
        //   formattedName: 'paid_date_formatted',
        //   label: 'Pagamento',
        //   align: '',
        //   md: '2',
        //   rules: [required],
        //   list: true,
        //   readonly: false,
        //   disabled: false,
        // },
        // {
        //   type: 'text',
        //   name: 'compensation_date',
        //   formattedName: 'compensation_date_formatted',
        //   label: 'Compensação',
        //   align: '',
        //   md: '2',
        //   rules: [required],
        //   list: true,
        //   readonly: false,
        //   disabled: false,
        // },
        {
          type: 'text',
          name: 'payment_method_id',
          formattedName: 'payment_method.name',
          label: 'Forma Pagamento',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'bank_id',
          formattedName: 'bank.name',
          label: 'Conta',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        // {
        //   type: 'text',
        //   name: 'value',
        //   formattedName: 'value_formatted',
        //   label: 'Valor',
        //   align: '',
        //   md: '2',
        //   rules: [required],
        //   list: true,
        //   readonly: false,
        //   disabled: false,
        // },
        // {
        //   type: 'text',
        //   name: 'discount',
        //   formattedName: 'discount_formatted',
        //   label: 'Desconto',
        //   align: '',
        //   md: '2',
        //   rules: [required],
        //   list: true,
        //   readonly: false,
        //   disabled: false,
        // },
        {
          type: 'text',
          name: 'total_value',
          formattedName: 'total_value_formatted',
          label: 'Total',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
