import BoxSchema from './schemas/BoxSchema';
import ListBoxes from './pages/ListBoxes.vue';
import CreateBox from './pages/CreateBox.vue';

export default [
  {
    path: BoxSchema.routes.list.path,
    name: BoxSchema.routes.list.name,
    component: ListBoxes,
    meta: BoxSchema.routes.list.meta,
  },
  {
    path: BoxSchema.routes.create.path,
    name: BoxSchema.routes.create.name,
    component: CreateBox,
    meta: BoxSchema.routes.create.meta
  },
  {
    path: BoxSchema.routes.show.path,
    name: BoxSchema.routes.show.name,
    component: CreateBox,
    meta: BoxSchema.routes.show.meta
  },
];