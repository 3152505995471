<template>
  <ModalCardDefault :title="l.title" @close="$emit('update:dialogBillDuplicate', false)">
    <v-form v-model="validForm" ref="validForm" lazy-validation class="px-5 pt-5">
      <Row>
        <Col md="6">
          <DataPicker v-model="form.due_date" :label="l.fields.dueDate"
            :rules="[rules.required]" />
        </Col>
        <Col md="6">
          <TextFieldMoney v-model="form.value" :label="l.fields.value"
            :rules="[rules.required]" />
        </Col>
      </Row>
    </v-form>

    <div slot="actions" class="d-flex justify-end">
      <Button :label="l.actions.add" class="mr-3" rounded color="secondary"
        @click="addDuplicate" />
    </div>
  </ModalCardDefault>
</template>

<script>
import {
  Row,
  Col,
  TextFieldMoney,
  Button,
  DataPicker
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required } from '@/utils/rules';

export default {
  name: 'BillsCreateDuplicate',
  components: {
    ModalCardDefault,
    Row,
    Col,
    TextFieldMoney,
    Button,
    DataPicker
  },
  props: {},
  data () {
    return {
      rules: {
        required
      },
      validForm: true,
      form: {
        due_date: '',
        value: '',
      }
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.bills.duplicates;
    },
  },
  methods: {
    addDuplicate () {
      if (!this.$refs.validForm.validate()) {
        return false;
      }

      this.$emit('addDuplicate', this.form);
      this.$refs.validForm.reset();
      this.form = {
        due_date: '',
        value: '',
      };
    }
  }
};
</script>
