<template>
  <v-expansion-panels
    v-bind="$attrs"
    v-on="$listeners"
    v-model="$attrs.value"
    multiple>
    <v-expansion-panel :readonly="$attrs.readonly" class="expansion-panel">
      <v-expansion-panel-header v-if="!noHeader">
        <div class="d-flex align-center">
          <Icon :icon="$attrs.icon" class="mr-3" />
          <h3 v-html="$attrs.title"></h3>
        </div>

      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Icon from '@/components/vuetify/Icon';

export default {
  name: 'ExpansionPanel',
  components: { Icon },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    focusable: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      panel: [0]
    };
  }
};
</script>

<style lang="scss" scoped>
.expansion-panel {
  border-radius: 25px !important;
  background-color: #EFEFEF;
}
</style>
