import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, barcode, calendar, check, cancel } from '@/utils/icons';
import { required } from '@/utils/rules';
import ListBalanceItems from '@/views/Products/Balances/pages/components/ListBalanceItems';
import FinishBalance from '@/views/Products/Balances/pages/components/FinishBalance';
import BalanceBusiness from '@/business/BalanceBusiness';

export default {
  domain: 'balances',
  domainSingle: 'balance',
  business: BalanceBusiness,
  title: 'Balanços',
  description: 'Lista de todos os Balanços do sistema',
  createBtn: 'Novo Balanço',
  showInactive: false,
  showExpand: true,
  routes: {
    list: {
      name: 'balances',
      path: '/balancos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'balanceCreate',
      path: '/balancos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'balanceShow',
      path: '/balancos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          icon: barcode,
          name: 'listBalanceItems',
          label: 'Items do Balanço',
          action: 'listBalanceItems',
          component: ListBalanceItems,
          version: 'v2',
          updateListWhenClosing: true,
          maxWidth: 1200,
        },
        {
          icon: check,
          iconColor: 'success',
          name: 'finishBalance',
          label: 'Finalizar Balanço',
          action: 'finishBalance',
          updateListWhenClosing: true,
          maxWidth: 500,
          component: FinishBalance,
          version: 'v2',
        },
        {
          icon: cancel,
          iconColor: 'primary',
          name: 'cancelBalance',
          label: 'Cancelar Balanço',
          action: 'cancelBalance',
          updateListWhenClosing: true,
          maxWidth: 1200,
          isConfirmDialog: true,
          titleDialog: 'Cancelar Balanço',
          messageDialog: 'Deseja realmente cancelar o balanço?',
        }
      ]
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'select',
        name: 'stock_id',
        label: 'Estoques',
        icon: text,
        md: 12,
        itemText: 'name',
        itemValue: 'id',
        domain: 'stocks',
        verb: 'get',
        endpoint: 'filters?domain=stock&page=1&per_page=-1',
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Balanço',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'quantity_items',
          formattedName: 'quantity_items',
          label: 'Qtd. Itens',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'quantity_volumes',
          formattedName: 'quantity_volumes',
          label: 'Qtd. Volumes',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'total_price_retail',
          formattedName: 'total_price_retail_formatted',
          label: 'Tot. Vlr. Varejo',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'total_price_wholesale',
          formattedName: 'total_price_wholesale_formatted',
          label: 'Tot. Vlr. Atacado',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'quantity_volumes_measured',
          formattedName: 'quantity_volumes_measured',
          label: 'Qtd. Volumes Aferido',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          noForm: true,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_price_retail_measured',
          formattedName: 'total_price_retail_measured_formatted',
          label: 'Tot. Vlr. Varejo Aferido',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          noForm: true,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_price_wholesale_measured',
          formattedName: 'total_price_wholesale_measured_formatted',
          label: 'Tot. Vlr. Atacado Aferido',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          noForm: true,
          contentExpanded: true,
        },
        {
          type: 'select',
          name: 'stock_id',
          formattedName: 'stock.name',
          label: 'Estoque',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'stocks',
            endpoint: 'stocks',
            queryParams: '',
          },
          itemText: 'name',
          itemValue: 'id',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
          contentExpanded: false,
        },
      ],
    },
  ],
  footer: [],
};
