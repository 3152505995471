<template>
  <ModalCardDefault :title="'Cartas de Crédito'" :displayNoButton="false">
    <Row>
      <Col>
        <ExpansionPanel v-model="panel" :title="'Lista das Cartas de Crédito'"
          :icon="$icons.box" readonly>
          <DataTable no-search no-sync :items="creditLetters"
            :headers="headers" :loading="loadingDataTable"
            show-select single-select @setSelected="setSelected">
          </DataTable>
        </ExpansionPanel>
      </Col>
    </Row>

    <template v-slot:actions>
      <Button color="primary" rounded @click="cancel" class="mr-3"
        label="Cancelar" />
      <Button color="secondary" rounded @click="confirm" class="mr-3"
          label="Confimar" :disabled="actionDisabled" />
    </template>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Row,
  Col,
  DataTable,
  Button,
} from '@/components/vuetify';

export default {
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    DataTable,
    Button,
  },
  props: {
    registerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      panel: [0],
      creditLetters: [],
      loadingDataTable: false,
      selected: null
    };
  },
  computed: {
    headers() {
      return [
        { text: 'Data', value: 'created_at_formatted' },
        { text: 'Número', value: 'number' },
        { text: 'Valor', value: 'value_formatted' },
        { text: 'Valor Usado', value: 'usage_value_formatted' },
        { text: 'Saldo', value: 'balance_formatted' },
      ];
    },
    actionDisabled() {
      return !this.selected || false;
    }
  },
  methods: {
    async getCreditLetters() {
      this.loadingDataTable = true;
      this.$api.creditLetters.filters({ url: `filters?domain=credit_letter&filter[register_id]=${this.registerId}&page=1&per_page=-1` }).then(({ data }) => {
        this.creditLetters = data.credit_letters;
      }).catch(() => {

      }).finally(() => {
        this.loadingDataTable = false;
      });
    },
    confirm() {
      this.$emit('confirmCreditLetter', this.selected);
    },
    setSelected(item) {
      this.selected = item[0];
    },
    cancel() {
      this.$emit('cancelCreditLetter');
    }
  },
  async mounted() {
    await this.getCreditLetters();
  }
};
</script>

<style>

</style>
