import CreditLetterSchema from './schemas/CreditLetterSchema';
import ListCreditLetters from './pages/ListCreditLetters';
import CreateCreditLetter from './pages/CreateCreditLetter';

export default [
  {
    path: CreditLetterSchema.routes.list.path,
    name: CreditLetterSchema.routes.list.name,
    component: ListCreditLetters,
    meta: CreditLetterSchema.routes.list.meta,
  },
  {
    path: CreditLetterSchema.routes.create.path,
    name: CreditLetterSchema.routes.create.name,
    component: CreateCreditLetter,
    meta: CreditLetterSchema.routes.create.meta
  },
  {
    path: CreditLetterSchema.routes.show.path,
    name: CreditLetterSchema.routes.show.name,
    component: CreateCreditLetter,
    meta: CreditLetterSchema.routes.show.meta
  },
];
