<template>
  <div>
    <Row style="border: 1px solid #000">
      <Col md="8" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.natureOperation" />
        <span class="" v-text="'Vendas'" />
      </Col>
      <Col md="4" class="d-flex flex-column justify-content-center">
        <span class="" v-text="l.protocolNumber" />
        <span class="" v-html="getValue(model.protocol_number)" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="3" class="border-right border-dark d-flex flex-column justify-content-center">
        <span class="" v-text="l.ie" />
        <span class="" v-html="getValue(model.emitter.ie)" />
      </Col>
      <Col md="4" class="border-right border-dark d-flex flex-column justify-content-center">
        <span class="" v-text="l.ie_st" />
        <span class="" v-html="getValue(model.emitter.ie_st)" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column justify-content-center">
        <span class="" v-text="l.suframa" />
        <span class="" v-html="getValue(model.emitter.suframa)" />
      </Col>
      <Col md="3" class="d-flex flex-column justify-content-center">
        <span class="" v-text="l.cnpj" />
        <span class="" v-html="getValue(model.emitter.cnpj)" />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55GeneralInfos',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.infosGenerals;
    }
  },
  methods: {
    getValue (value) {
      return value || '&nbsp;';
    },
  }
};
</script>
