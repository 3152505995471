import { Response } from 'miragejs';
import { dateTime } from '../../utils/dateTime';

export const update = (server) => {
  server.put('/products/:id', (schema, request) => {
    const { id } = request.params;
    const products = schema.products.find(id);

    if (!products) {
      return new Response(404, {}, { errors: {} });
    }

    const attrs = JSON.parse(request.requestBody);
    const { name, price, quantity } = attrs;

    if ( !name || price || quantity ) {
      return new Response(422, {}, { errors: {
        name: 'The field type is required',
        price: 'The field price is required',
        quantity: 'The field quantity is required',
      } });
    }

    products.update({ ...attrs, name, price, quantity, updated_at: dateTime });
  });
};