<template>
  <div>
    <ModalCardDefault :title="l.title" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Extrato'" :icon="$icons.box" readonly>
        <DataTable no-search :items-per-page="6"
          :items="movements" :slots="slotItems"
          :headers="this.schema.movements.list.headers" disable-sort
          @getItems="$emit('listMovements', $event)" :loading="loadingTable">

          <template v-slot:movement_type_formatted="{ props: { item } }">
            <Chip :label="item.movement_type_formatted" small
              :color="getColorChip(item.movement_type)" />
          </template>

          <template v-slot:value_formatted="{ props: { item } }">
            <span :class="['font-weight-bold', {'success--text': item.movement_type === $enums.typeStockMovements.INPUT,
              'red--text': item.movement_type === $enums.typeStockMovements.OUTPUT}]">
              {{ item.value_formatted }}
            </span>
          </template>

          <template v-slot:actions="{ props }">
            <ButtonIcon color="secondary" class="mr-3" x-small :icon="$icons.view"
              :tooltip="l.tooltip.view"
              @click="openShowBankMovement(props.item, typePage.SHOW)" />
          </template>
        </DataTable>

        <Row>
          <Col>
            <Alert color="primary">
              <h4 class="font-weight-bold">Banco</h4>
              <h4 v-text="this.bank.description" />
            </Alert>
          </Col>
          <Col>
            <Alert color="primary">
              <h4 class="font-weight-bold">Agência</h4>
              <h4 v-text="this.bank.agency" />
            </Alert>
          </Col>
          <Col>
            <Alert color="primary">
              <h4 class="font-weight-bold">Conta</h4>
              <h4 v-text="this.bank.account" />
            </Alert>
          </Col>
          <Col>
            <Alert color="primary">
              <h4 class="font-weight-bold">Saldo</h4>
              <h4 v-text="this.bank.balance_formatted" />
            </Alert>
          </Col>
        </Row>
      </ExpansionPanel>

      <template v-slot:actions>
        <Button :label="l.actions.create" :icon="$icons.plus" color="secondary"
          rounded class="btn-actions mr-3"
          @click="openCreateBankMovement(typePage.CREATE)" />
      </template>
    </ModalCardDefault>

    <Dialog :dialog="dialogCreateBankMovement" no-title :maxWidth="1250">
      <CreateBankMovement :type-page="typePageSelected"
        :bank-movement-selected="bankMovementSelected"
        :bank-id="this.bank.id"
        @closeDialogCreateBankMovement="closeDialogCreateBankMovement"
        slot="content" :title="l.title"
        @close="closeDialogCreateBankMovement" />
    </Dialog>
  </div>
</template>

<script>
import {
  Row, Col, DataTable, ButtonIcon,
  Button, Dialog, ExpansionPanel,Chip, Alert
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateBankMovement from './CreateBankMovement';
import { typePage } from '@/utils/enums';

export default {
  name: 'ListBankMovements',
  components: {
    Row,
    Col,
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Button,
    Dialog,
    ExpansionPanel,
    CreateBankMovement,
    Chip, Alert
  },
  props: {
    movements: {
      type: Object,
      required: true
    },
    bank: {
      type: Object,
      required: true
    },
    loadingTable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      dialogCreateBankMovement: false,
      bankMovementSelected: {},
      typePageSelected: '',
      typePage,
      slotItems: [{ field: 'movement_type_formatted' }, { field: 'value_formatted' }],
    };
  },
  computed: {
    schema () {
      return this.$schemas.bank;
    },
    l () {
      return this.$locales.pt.banks.list.modalMovements;
    }
  },
  methods: {
    openShowBankMovement (BankMovement, typePage) {
      this.bankMovementSelected = BankMovement;
      this.typePageSelected = typePage;
      this.dialogCreateBankMovement = true;
    },
    openCreateBankMovement(typePage) {
      this.typePageSelected = typePage;
      this.dialogCreateBankMovement = true;
    },
    closeDialogCreateBankMovement() {
      this.$emit('listMovements');
      this.dialogCreateBankMovement = false;
    },
    getColorChip (item) {
      return item === this.$enums.typeStockMovements.INPUT ? 'success' : 'primary';
    }
  }
};
</script>

<style>

</style>
