<template>
  <ModalCardDefault title="Alterar Desconto" @close="$emit('close', false)">
    <v-form v-model="validFormDiscount" ref="validFormDiscount" lazy-validation>
      <ExpansionPanel v-model="panel" title="Valor do Desconto" :icon="$icons.box"
        readonly>
        <Row>
          <Col>
            <TextFieldMoney v-model="discount" label="Desconto"  />
            <ul>
              <li><span>Os itens que já possuem descontos não serão afetados</span></li>
              <li><span>Ao alterar o valor do desconto, os itens que não possuem descontos terão o valor do desconto recalculado.</span></li>
            </ul>
          </Col>
        </Row>
      </ExpansionPanel>
    </v-form>

    <div slot="actions">
      <Button label="Confirmar"  @click="editDiscount"
        class="mr-3" rounded color="secondary" />
    </div>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextFieldMoney,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'OrderDiscountEditModal',
  components: {
    ExpansionPanel,
    ModalCardDefault,
    Row,
    Col,
    TextFieldMoney,
    Button,
  },
  props: {
  },
  data () {
    return {
      panel: [0],
      validFormDiscount: false,
      discount: 0
    };
  },
  methods: {
    async editDiscount() {
      this.$emit('changeDiscount', this.discount);
      this.resetLocalForm();
    },
    resetLocalForm () {
      this.localForm = {};
    },
  },
};
</script>

<style lang="scss">

</style>
