/* eslint-disable no-unused-vars */
export default {
  beforeList: ({ form }) => {
  },
  beforeForm: ({ route, form }) => {
    form = {
      ...form,
      ...route.meta.payloadFixed
    };
  },
  afterUpdated: () => {
  },
  beforeChangeSelects: {
    columns: ({ apiData, route }) => {
      const columns = [];
      apiData.report_settings.filter((reportSetting) => reportSetting.domain === route.meta.payloadFixed.domain).map((item) => {
        return item.columns.map((column) => {

          columns.push(
            {
              name: column.column_name,
              label: column.label,
            }
          );
        });
      });
      console.log(columns);

      return {
        report_setting_columns: columns
      };
    }
  },
};
