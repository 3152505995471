import DynamicService from '@/service/DynamicService';
import ServiceInvoiceSchema from '../schemas/ServiceInvoiceSchema';
import { serviceInvoicesStatus } from '@/utils/options';
import { getText, formatDatePT, formatCurrency } from '@/utils';

const formatResponse = (item) => {
  item.key = '2316 5421 6846 3165 7451 6545 1654';
  item.status_formatted = getText(serviceInvoicesStatus, item.status);
  item.emission_date_formatted = formatDatePT(item.emission_date);
  item.total_formatted = formatCurrency(item.total);
};

const ServiceInvoicesService = DynamicService('service_invoices', ServiceInvoiceSchema, { formatResponse });

export default ServiceInvoicesService;