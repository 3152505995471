<template>
  <div>
    <PageHeader :schema="$schemas.box" />
    <PageContent>
      <DynamicListPage :schema="$schemas.box"
      :service="$api.boxes" @actionMoreActions="actionMoreActions"
      ref="listBoxes"/>
    </PageContent>

    <Dialog :dialog="dialogMovements" no-title: :maxWidth="1250">
      <ListBoxMovements slot="content"
        :box="this.boxSelected" :movements="movements"
        @listMovements="listMovements"
        @close="closeModal" />
    </Dialog>
    <Dialog :dialog="dialogOpenOrCloseBoxMovements" no-title: :maxWidth="800">
      <OpenOrClosedBox slot="content" :type-page="typePageBoxMovement"
        :box="boxSelected"
        :box-movement-last="this.boxMovementLast"
        @listMovements="listMovements"
        @close="closeModalBoxMovement" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import Dialog from '@/components/vuetify/Dialog';
import ListBoxMovements from './components/ListBoxMovements';
import OpenOrClosedBox from './components/OpenOrClosedBox';

export default {
  name: 'ListBoxes',
  components: {
    PageHeader, PageContent, DynamicListPage,
    Dialog, ListBoxMovements, OpenOrClosedBox
  },
  data () {
    return {
      dialogMovements: false,
      dialogOpenOrCloseBoxMovements: false,
      movements: [],
      boxSelected: {},
      boxMovementLast: {},
      typePageBoxMovement: '',
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this.boxSelected = item;
      this[moreActionItem.action](item);
    },
    async listMovements() {
      this.$api.boxes.listMovements(this.boxSelected.id).then((res) => {
        this.movements = res.box_movements.map((item) => {
          return {
            ...item
          };
        });
        this.dialogMovements = true;
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    async closeModal() {
      await this.$refs.listBoxes.getAll();
      this.dialogMovements = false;
    },
    async openBoxMovement() {
      this.typePageBoxMovement = 'open';
      this.boxMovementLast = {};
      this.dialogOpenOrCloseBoxMovements = true;
    },
    async closeBoxMovementLast() {
      this.$api.boxes.listBoxMovementLast(this.boxSelected.id).then((res) => {
        this.typePageBoxMovement = 'close';
        this.boxMovementLast = res;
        this.dialogOpenOrCloseBoxMovements = true;
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    async closeModalBoxMovement() {
      await this.$refs.listBoxes.getAll();
      this.dialogOpenOrCloseBoxMovements = false;
    },
  }
};
</script>
