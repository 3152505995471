<template>
  <div>
    <PageHeader :schema="$schemas.creditCardAdministrator" />
    <PageContent>
      <DynamicListPage :schema="$schemas.creditCardAdministrator" :service="$api.creditCardAdministrators" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListCreditCardAdministratorsLists',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
  },
};
</script>
