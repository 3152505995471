import BilletControlSchema from './schemas/BilletControlSchema';
import ListBilletControls from './pages/ListBilletControls';
import CreateBilletControl from './pages/CreateBilletControl';

export default [
  {
    path: BilletControlSchema.routes.list.path,
    name: BilletControlSchema.routes.list.name,
    component: ListBilletControls,
    meta: BilletControlSchema.routes.list.meta,
  },
  {
    path: BilletControlSchema.routes.create.path,
    name: BilletControlSchema.routes.create.name,
    component: CreateBilletControl,
    meta: BilletControlSchema.routes.create.meta
  },
  {
    path: BilletControlSchema.routes.show.path,
    name: BilletControlSchema.routes.show.name,
    component: CreateBilletControl,
    meta: BilletControlSchema.routes.show.meta
  },
];
