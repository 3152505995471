import { typePageOptions } from '@/utils';
import { text, users, check } from '@/utils/icons';
import { required } from '@/utils/rules';
import ReportBusiness from '@/business/ReportBusiness';
import { orientationReport } from '@/utils/options';
import GenerateReport from '@/views/Reports/pages/components/GenerateReport';

export default {
  domain: 'reports',
  domainSingle: 'report',
  business: ReportBusiness,
  title: 'Relatórios',
  description: 'Lista dos Relatórios do sistema',
  showInactive: true,
  routes: {
    reportClient: {
      list: {
        name: 'report-clients',
        path: '/relatorios/clientes',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'reportClient',
          payloadFixed: {
            filter: 'domain',
            value: 'register',
          },
          title: 'Relatório de Clientes',
          description: 'Lista de todos os Relatório de Clientes cadastrados no sistema',
        }
      },
      create: {
        name: 'report-client-create',
        path: '/relatorios/clientes/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'reportClient',
          payloadFixed: {
            domain: 'register',
            filter_values: [{ filter: 'type', value: 'client' }]
          },
          title: 'Relatório de Clientes',
          description: 'Lista de todos os Relatório de Clientes cadastrados no sistema',
        }
      },
    },
  },
  listActions: {
    has: true,
    noShow: true,
    noDestroy: false,
    situation: false,
    more: {
      has: true,
      items: [
        {
          icon: check,
          name: 'listBalanceItems',
          label: 'Gerar Relatório',
          action: 'generateReport',
          component: GenerateReport,
          version: 'v2',
          maxWidth: 800,
        },
      ]
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'text',
        name: 'name',
        label: 'Nome',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'description',
        label: 'Descrição',
        icon: text,
        md: 6,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Cadastro',
      icon: users,
      openGroup: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome do Relatório',
          align: '',
          md: '8',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'select',
          name: 'orientation',
          formattedName: 'orientation_formatted',
          label: 'Orientação',
          align: '',
          md: '4',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: orientationReport,
          default: false,
          itemValueDefault: true,
        },
        {
          type: 'select',
          name: 'columns',
          formattedName: 'columns',
          label: 'Colunas',
          align: '',
          md: '12',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'report_setting_columns',
            endpoint: 'filters',
            queryParams: 'domain=report_setting&per_page=-1',
          },
          newItem: {
            has: false,
          },
          itemText: 'label',
          itemValue: 'name',
          isBeforeChangeSelect: true,
          multiple: true,
          returnObject: true,
        },
      ],
    },
  ],
  footer: []
};
