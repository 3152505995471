<template>
  <div>
    <PageHeader :schema="$schemas.billetControl" />
    <PageContent>
      <DynamicListPage :schema="$schemas.billetControl"
        :service="$api.billetControls" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListBilletControls',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
  },
};
</script>
