<template>
  <v-card flat tile style="z-index: 3;">
    <v-toolbar :color="isSuper ? 'primary' : ''" :dark="modeDark.value" class="toolbar">
      <v-app-bar-nav-icon @click="$emit('openCloseSideBar')"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ typeUser }}</v-toolbar-title>
      <Row>
        <Col cols="12" md="7">
          <Select v-model="companyIdLogged" label="Empresa"
          :items="companies" item-text="name" item-value="id"
          @change="openDialogConfirmation" />
        </Col>
      </Row>
      <v-spacer></v-spacer>
      <CustomSwitch :label="modeDarkLocal.label" v-model="modeDarkLocal.value" class="mt-6 mr-5" @click="setModeDark()" />
      <NavBarSettings />
    </v-toolbar>

    <DialogConfirmation :dialog="dialogConfirmation"
      title="Deseja trocar de empresa?"
      message="Ao trocar de empresa, você será redirecionado para a página inicial."
      @noActionDialogConfirmation="closeDialogConfirmation"
      @yesActionDialogConfirmation="changeCompany" />
  </v-card>
</template>

<script>
import { Select, Row, Col } from '@/components/vuetify';
import DialogConfirmation from '@/components/DialogConfirmation';
import CustomSwitch from '@/components/vuetify/CustomSwitch';
import NavBarSettings from './NavBarSettings';
import { getUserSuper, getCompany, getTypeUser, getUser } from '@/utils';
import { TYPE_USER } from '@/utils/enums';

export default {
  name: 'NavBar',
  components: {
    CustomSwitch,
    NavBarSettings,
    Select,
    Row,
    Col,
    DialogConfirmation
  },
  props: {
    modeDark: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      modeDarkLocal: {
        label: 'Modo Escuro',
        value: false
      },
      companyIdLogged: '',
      companyIdLoggedTemp: '',
      companies: [],
      dialogConfirmation: false
    };
  },
  computed: {
    nameCompany () {
      let company = getCompany();
      const user = getUser();

      if (getTypeUser() === TYPE_USER.DISTRIBUTOR || getTypeUser() === TYPE_USER.RESELLER) {
        company = user;
      }

      return company.name || process.env.VUE_APP_COMPANY;
    },
    isSuper () {
      return getUserSuper().type === 'super';
    },
    typeUser () {
      let typeUser = '';

      switch (getTypeUser()) {
      case TYPE_USER.DISTRIBUTOR: typeUser = 'Distribuidor: '; break;
      case TYPE_USER.RESELLER: typeUser = 'Revendedor: '; break;
      case TYPE_USER.SUPER: typeUser = ''; break;
      case TYPE_USER.ADMIN: typeUser = ''; break;
      case TYPE_USER.MANAGER: typeUser = ''; break;
      case TYPE_USER.USER: typeUser = ''; break;
      }

      return typeUser;
    },
  },
  watch: {
    ['modeDark.value']: {
      handler() {
        this.modeDarkLocal = this.modeDark;
      },
      deep: true,
    },
  },
  methods: {
    setModeDark() {
      this.modeDarkLocal.value = !this.modeDarkLocal.value;
      this.$emit('setModeDark', this.modeDarkLocal);
    },
    openDialogConfirmation() {
      this.dialogConfirmation = true;
    },
    closeDialogConfirmation() {
      this.dialogConfirmation = false;
      this.companyIdLogged = this.companyIdLoggedTemp;
    },
    changeCompany() {
      this.companyIdLoggedTemp = this.companyIdLogged;
      this.$store.dispatch('setCompanyIdLogged', this.companyIdLogged);
      window.location.href = '/';
    },
  },
  mounted() {
    this.companies = this.$store.getters.getCompaniesByCustomer;
    this.companyIdLogged = this.$store.getters.getCompanyIdLogged;
    this.companyIdLoggedTemp = this.$store.getters.getCompanyIdLogged;
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  box-shadow: none;
  -webkit-box-shadow: 0px 3px 5px -3px rgba(41,47,76,0.58);
  -moz-box-shadow: 0px 3px 5px -3px rgba(41,47,76,0.58);
  box-shadow: 0px 3px 5px -3px rgba(41,47,76,0.58) !important;
}
</style>
