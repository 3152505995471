<template>
  <Row>
    <Col md="12" class="py-0">
      <div class="pdv-content--main---actions py-2 px-2">
        <Button small rounded color="secondary"
          @click="$emit('openModal', { name: 'Products' })">
          <span class="text-button">Produtos
            <span class="text-button font-weight-black yellow--text">(F4)</span>
          </span>
        </Button>
        <Button small rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Clients' })">
          <span class="text-button">Clientes
            <span class="text-button font-weight-black yellow--text">(Ctrl + Y)</span>
          </span>
        </Button>
        <Button small rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Sales' })">
          <span class="text-button">Vendas
            <span class="text-button font-weight-black yellow--text">(Ctrl + I)</span>
          </span>
        </Button>
        <Button small rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Discount' })" :disabled="notOpenBox">
          <span class="text-button">Desconto
            <span :class="['text-button font-weight-black', { 'yellow--text': !notOpenBox }]">(Ctrl + F6)</span>
          </span>
        </Button>
        <Button small rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'RemoveItem' })" :disabled="notOpenBox">
          <span class="text-button">Remover Item
            <span :class="['text-button font-weight-black', { 'yellow--text': !notOpenBox }]">(Ctrl + F7)</span>
          </span>
        </Button>
        <Button small rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Sellers' })">
          <span class="text-button">Vendedores
            <span class="text-button font-weight-black yellow--text">(Ctrl + Q)</span>
          </span>
        </Button>
        <!-- <Button label="Abrir/Fechar Caixa" rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Sellers' })" />
        <Button label="Suprimento" rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Sellers' })" />
        <Button label="Sangria" rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Sellers' })" />
        <Button label="Lista de Preço" rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'PriceList' })" /> -->
        <Button small rounded color="secondary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'CancelSale' })" :disabled="notOpenBox">
          <span class="text-button">Cancelar Venda
            <span :class="['text-button font-weight-black', { 'yellow--text': !notOpenBox }]">(Ctrl + Z)</span>
          </span>
        </Button>
        <Button small rounded color="orange" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'FinishSale' })" :disabled="notOpenBox">
          <span class="text-button">Finalizar Venda
            <span :class="['text-button font-weight-black', { 'red--text': !notOpenBox }]">(Ctrl + X)</span>
          </span>
        </Button>
        <Button small rounded color="primary" class="mx-2 my-1"
          @click="$emit('openModal', { name: 'Exit' })" :disabled="!notOpenBox">
          <span class="text-button">Sair
            <span :class="['text-button font-weight-black', { 'yellow--text': notOpenBox }]">(Ctrl + Esc)</span>
          </span>
        </Button>
      </div>
    </Col>
  </Row>
</template>

<script>
import { Row, Col, Button } from '@/components/vuetify';

export default {
  name: 'PdvActions',
  components: { Row, Col, Button },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    notOpenBox () {
      return this.model.items.length === 0 || false;
    }
  },
};
</script>

<style lang="scss" scoped>
.pdv-content--main---actions {
  width: calc(100% - 44px);
  background-color: #FFF;
  border-radius: 12px;
  position: absolute;
  bottom: 0;
  min-height: 100px;
  text-align: center;
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .pdv-content--main---actions {
    width: calc(100% - 15px);
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1400px) {
  .pdv-content--main---actions {
    width: calc(100% - 15px);
  }
}
</style>
