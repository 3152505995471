<template>
  <div>
    <ModalCardDefault :title="title" @close="$emit('update:dialogModal', false)">
      <ExpansionPanel v-model="panel" :title="'Valores da Variação'" :icon="$icons.box" readonly>
        <DataTable no-search no-sync :items-per-page="7" :items="variationValues"
          :headers="$schemas.variation.list.dialogVariationValues.headers" class="px-5">

          <template v-slot:actions="{ props }">
            <ButtonIcon color="primary" x-small :icon="$icons.destroy"
              :tooltip="l.list.tooltips.delete"
              @click="openDialogConfirmation(props)" />
          </template>
        </DataTable>
      </ExpansionPanel>

      <template v-slot:actions="{ }">
        <Button :label="l.list.actions.newValue" color="secondary"
          :icon="$icons.plus" rounded class="mr-2" @click="dialogModal = true" />
      </template>
    </ModalCardDefault>

    <Dialog :dialog="dialogModal" no-title :maxWidth="600">
      <CreateVariationValues slot="content" :variation="variation"
        @close="close()" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation"
      :loading="loadingDialogConfirmation" title="Excluir Registro"
      message="Reseja realmente excluir o registro?"
      @yesActionDialogConfirmation="deleteVariationValue"
      @noActionDialogConfirmation="dialogConfirmation = false" />
  </div>
</template>

<script>
import {
  DataTable,
  ButtonIcon,
  Dialog,
  Button,
  ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateVariationValues from './CreateVariationValues';
import DialogConfirmation from '@/components/DialogConfirmation';

export default {
  name: 'ListVariationValues',
  components: {
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Dialog,
    Button,
    CreateVariationValues,
    ExpansionPanel,
    DialogConfirmation
  },
  props: {
    variation: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      dialogModal: false,
      dialogConfirmation: false,
      loadingDialogConfirmation: false,
      variationValues: [],
      variationValueSelected: {},
    };
  },
  computed: {
    l () {
      return this.$locales.pt.variations.list.dialogVariationValues;
    },
    title () {
      return `${this.l.title}: ${this.variation.description}`;
    }
  },
  watch: {
    variation () {
      this.getVariationValues();
    }
  },
  methods: {
    getVariationValues () {
      this.$api.variationValues.getByVariationId(this.variation.id).then((res) => {
        this.variationValues = res.data.variation_values;
      }).catch(() => {
      }).finally(() => {
      });
    },
    close () {
      this.dialogModal = false;
      this.getVariationValues();
    },
    openDialogConfirmation ({ item }) {
      this.variationValueSelected = item;
      this.dialogConfirmation = true;
    },
    deleteVariationValue () {
      this.loadingDialogConfirmation = true;
      this.$api.variationValues.delete(this.variationValueSelected.id).then(() => {
        this.$noty.success(this.$locales.pt.default.alerts.deletedRegister);
      }).catch(() => {
        this.$noty.error(this.$locales.pt.default.alerts.errorDeletedRegister);
      }).finally(() => {
        this.dialogConfirmation = false;
        this.loadingDialogConfirmation = false;
        this.getVariationValues();
      });
    },
  },
  async mounted () {
    await this.getVariationValues();
  },
};
</script>

<style>

</style>
