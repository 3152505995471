<template>
  <v-form v-model="validFormVariation" ref="validFormVariation" lazy-validation class="px-5 pt-5">
    <Row>
      <Col md="5">
        <Select v-model="variationValueCreated.variation" :label="l.fields.labels.variation"
          :items="variations" :rules="[rules.required]" item-text="description"
          item-value="id" @change="setVariation" return-object />
      </Col>
      <Col md="5">
        <AutoComplete v-model="variationValueCreated.variationValue" :placeholder="l.fields.labels.value"
          :label="l.fields.labels.value" :rules="[rules.required]" return-object
          domain="variation_value" resultDomain="variation_values" item-value-data-serve="id"
          item-text-data-serve="description" :search-by="['description']"
          title="Pesquise o valor da variação" :fixed-value-search="{ variation_id: variationValueCreated.variation.id }"
          @change="changeVariationValue($event)" />
      </Col>
      <Col>
        <Button :label="l.actions.insert" class="mt-1 mr-3" rounded color="primary" @click="insertVariation" />
      </Col>
    </Row>
  </v-form>
</template>

<script>
import {
  Select,
  Row,
  Col,
  Button,
  AutoComplete,
} from '@/components/vuetify';
import CreateVariationMixin from './mixins/CreateVariationMixin';

export default {
  name: 'FormVariationValue',
  components: {
    Button,
    Row,
    Col,
    Select,
    AutoComplete,
  },
  props: {
    variations: {
      type: Array,
      required: true,
    },
    variationValues: {
      type: Array,
      required: true,
    }
  },
  data () {
    return {
      validFormVariation: false,
      variationValueCreated: {
        variation: {
          id: ''
        },
        variationValue: {},
      }
    };
  },
  computed: {
    l () {
      return this.$locales.pt.products.list.modalVariations.createVariation;
    },
  },
  mixins: [CreateVariationMixin],
  methods: {
    insertVariation() {
      if (!this.$refs.validFormVariation.validate()) {
        return false;
      }

      if (!this.variationValueCreated?.variation?.id || !this.variationValueCreated?.variationValue?.id) {
        this.$noty.error('Selecione a Variação e o Valor da Variação');

        return false;
      }

      this.$emit('insertVariation', this.variationValueCreated);
    },
    changeVariationValue(item) {
      this.variationValueCreated.variationValue = item;
    },
    setVariation() {
      this.variationValueCreated.variationValue = {};
    }
  }
};
</script>
