<template>
  <v-col :cols="$attrs.cols" :md="$attrs.md">
    <slot />
  </v-col>
</template>

<script>
export default {
  name: 'Col',
  props: {
    colspan: {
      type: Number,
      default: 1
    }
  }
};
</script>
