import ProductInvoiceSchema from './schemas/ProductInvoiceSchema';
import ListProductInvoices from './pages/ListProductInvoices';
import CreateProductInvoice from './pages/CreateProductInvoice';

export default [
  {
    path: ProductInvoiceSchema.routes.list.path,
    name: ProductInvoiceSchema.routes.list.name,
    component: ListProductInvoices,
    meta: ProductInvoiceSchema.routes.list.meta,
  },
  {
    path: ProductInvoiceSchema.routes.create.path,
    name: ProductInvoiceSchema.routes.create.name,
    component: CreateProductInvoice,
    meta: ProductInvoiceSchema.routes.create.meta
  },
  {
    path: ProductInvoiceSchema.routes.show.path,
    name: ProductInvoiceSchema.routes.show.name,
    component: CreateProductInvoice,
    meta: ProductInvoiceSchema.routes.show.meta
  },
];