<template>
  <div class="item-header">
    <p>{{ header.text }}</p>
    <div>
      <span v-text="l.headers.base" />
      <span v-text="l.headers.aliquot" />
      <span v-text="l.headers.value" />
      <span v-if="hasCts(header.value)" v-text="l.headers.cst" />
    </div>
  </div>
</template>

<script>
import ItemsDataListTaxesMixin from '@/mixins/ItemsDataListTaxesMixin';

export default {
  name: 'ItemsDataListTaxesHeader',
  components: {},
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {};
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.items.dataList.taxes;
    },
  },
  mixins: [ItemsDataListTaxesMixin]
};
</script>

<style lang="scss" scoped>
.item-header {
  justify-content: center;
  text-align: center;
  border-left: 1px solid #CCC;
  padding: 0 20px;

  p {
    font-weight: bold;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
</style>
