<template>
  <v-tabs-items v-model="this.$attrs.value" transition>
    <slot />
  </v-tabs-items>
</template>

<script>
export default {
  name: 'TabsItems',
};
</script>
