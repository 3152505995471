import DynamicService from '@/service/DynamicService';
import axios from '@/service';

const ProductVariationSchema = {
  domain: 'product_variations',
  domainSingle: 'product_variation',
  title: 'Variação de Produto',
  routes: []
};

const formatResponse = (item) => {
  item;
};

const ProductVariationsService = DynamicService('product_variations', ProductVariationSchema, { formatResponse, removeNullable: false });

const ProductVariationsCommands = () => ({
  async getTotalizers() {
    return await axios.post('/product_variations/totalizers', {});
  },
  async getProductVariationsByProductId(productId) {
    return await axios.get(`filters?domain=product_variation&filter[product_id]=${productId}`);
  },
});

export default ProductVariationsService;
export { ProductVariationsService, ProductVariationsCommands };
