<template>
  <ModalCardDefault :title="l.title" @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" :title="l.title"
      :icon="$icons.box" readonly>
        <Row>
          <Col md="6">
            <FileField :label="l.certificate"
              :placeholder="l.certificate" @getFile="getFile" />
          </Col>
          <Col md="6">
            <TextField v-model="form.password" :label="l.password"
              :placeholder="l.certificate" type="password" />
          </Col>
          <Col md="6">
          </Col>
        </Row>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button :label="l.actions.send" color="secondary" rounded class="mr-3"
        @click="uploadCertificate" :loading="loading" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  FileField,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'CertificateUpload',
  components: {
    ExpansionPanel,
    Row,
    Col,
    TextField,
    FileField,
    Button,
    ModalCardDefault
  },
  data () {
    return {
      panel: [0],
      loading: false,
      form: {},
      password: null,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.invoiceSettings.list.dialogCertificate.upload;
    },
  },
  methods: {
    uploadCertificate () {
      this.loading = true;
      this.$api.invoiceSettings.uploadCertificate(this.form).then((res) => {
        this.certificate = res;
        this.$noty.success(this.l.success);
        this.$emit('uploadSuccess');
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      });
    },
    getFile (file) {
      this.form.certificate = file;
    }
  },
};
</script>
