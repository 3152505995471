<template>
  <div>
    <ModalCardDefault :title="`Detalhes: ${table.description}`" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Detalhes da Mesa'" :icon="$icons.box" readonly>
        <DataTable no-search no-sync :items="items"
          :headers="headers" :slots="[]" :loading="false"
          :items-per-page="5">

          <template v-slot:actions="{ props: { item } }">
            <ButtonIcon color="primary" x-small :icon="$icons.destroy"
            :tooltip="'Remover'" @click="openDialogConfirmation(item)" />
          </template>
        </DataTable>

        <Row>
          <Col md="3">
            <Alert color="primary" class="py-2">
              <h5 class="font-weight-bold">Total da Mesa</h5>
              <h4>{{ table.total_value_formatted }}</h4>
            </Alert>
          </Col>
        </Row>
      </ExpansionPanel>

        <template v-slot:actions="{ }">
          <Button :label="'Vender'" color="secondary"
            :icon="$icons.plusOutline" rounded class="mr-2" @click="openCloseModalProduct()" />
        </template>
    </ModalCardDefault>

    <Dialog :dialog="dialogModal" no-title :maxWidth="1000">
      <ProductTable slot="content" :table="table"
        @close="openCloseModalProduct" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation" :loading="loadingDestroy"
      :title="`Remover Item ${tableSaleItemSelected.item_number}`" :message="'Deseja realmente remover o item da mesa?'"
      @noActionDialogConfirmation="noActionDialogConfirmation"
      @yesActionDialogConfirmation="yesActionDialogConfirmation" />
  </div>
</template>

<script>
import {
  Button,
  ExpansionPanel,
  DataTable,
  ButtonIcon,
  Row,
  Col,
  Alert,
  Dialog,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import DialogConfirmation from '@/components/DialogConfirmation';
import TableSale from '../../model/TableSale';
import ProductTable from '../components/ProductTable';

export default {
  name: 'DetailsTable',
  components: {
    ModalCardDefault,
    Button,
    ExpansionPanel,
    DataTable,
    ButtonIcon,
    Row,
    Col,
    Alert,
    Dialog,
    ProductTable,
    DialogConfirmation,
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      validFormTableSale: false,
      tableSale: TableSale.model,
      headers: [
        { text: 'Item', value: 'item_number', sortable: false },
        { text: 'Produto', value: 'product_variation.name_formatted', sortable: false },
        { text: 'Preço Uni.', value: 'unit_value_formatted', sortable: false },
        { text: 'Quantidade.', value: 'quantity_formatted', sortable: false },
        { text: 'Sub Total', value: 'sub_total_formatted', sortable: false },
        { text: 'Desconto', value: 'discount_formatted', sortable: false },
        { text: 'Total', value: 'total_value_formatted', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      dialogModal: false,
      dialogConfirmation: false,
      loadingDestroy: false,
      tableSaleItemSelected: {}
    };
  },
  computed: {
    items () {
      return this.table.table_sale?.items || [];
    }
  },
  methods: {
    openCloseModalProduct (closeModal = false) {
      this.dialogModal = !this.dialogModal;

      if (closeModal) {
        this.$emit('close');
      }
    },
    openDialogConfirmation (item) {
      this.dialogConfirmation = true,
      this.tableSaleItemSelected = item;
    },
    noActionDialogConfirmation () {
      this.dialogConfirmation = false,
      this.loadingDestroy = false,
      this.tableSaleItemSelected = {};
    },
    yesActionDialogConfirmation () {
      this.deleteTableSaleItem();
    },
    deleteTableSaleItem () {
      this.loadingDestroy = true;
      this.$api.tables.deleteTableSaleItem(this.table.id, this.table.table_sale.id,
        this.tableSaleItemSelected.id).then(() => {
      }).catch(() => {
        this.$noty.error('Erro ao remover produto');
      }).finally(() => {
        this.noActionDialogConfirmation();
        this.$emit('close', true);
      });
    }
  },
  mounted () {
    this.tableSale.table_id = this.table.id;
  },
};
</script>

<style>

</style>
