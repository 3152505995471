<template>
  <ModalCardDefault :title="l.titleModal" @close="close">
    <ExpansionPanel v-model="panel" :title="l.titleForm" :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <Row>
          <Col md="4">
            <Select v-model="form.id" :label="l.fields.invoiceSettings"
              :placeholder="l.fields.invoiceSettings" :items="invoiceSettings"
              item-text='description' item-value='id' return-object
              @change="setInvoiceSetting" :rules="[rules.required]" />
          </Col>
          <Col md="2">
            <Select v-model="form.operation_type" :label="l.fields.documentType"
              :placeholder="l.fields.documentType" :items="options.typeDocument" :rules="[rules.required]" />
          </Col>
          <Col md="2">
            <TextField v-model="form.model" :label="l.fields.model"
              :placeholder="l.fields.model" :rules="[rules.required]" />
          </Col>
          <Col md="2">
            <TextField v-model="form.series" :label="l.fields.series"
              :placeholder="l.fields.series" :rules="[rules.required]" />
          </Col>
          <Col md="2">
            <TextField v-model="form.nf_number" :label="l.fields.invoiceNumber"
              :placeholder="l.fields.invoiceNumber" :rules="[rules.required]" />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Select v-model="form.emission_type" :label="l.fields.emissionForm"
              :placeholder="l.fields.emissionForm" :items="options.emissionType"
              :rules="[rules.required]" />
          </Col>
          <Col md="4">
            <Select v-model="form.emission_finality" :label="l.fields.finality"
              :placeholder="l.fields.finality" :items="options.emissionFinality"
              :rules="[rules.required]" />
          </Col>
          <Col md="4">
            <Select v-model="form.printer_type" :label="l.fields.danfeFormat"
              :placeholder="l.fields.danfeFormat" :items="options.printerType"
              :rules="[rules.required]" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button :label="'Gerar e Transmitir NF-e'" class="mr-3"
        rounded color="success" @click="generateInvoice({ transmit: true })" :loading="loadingGenerateTransmit" />
      <Button :label="l.actions.generate" class="mr-3"
        rounded color="secondary" @click="generateInvoice({ transmit: false })" :loading="loading" />
    </div>

    <Dialog :dialog="dialogActions" no-title :maxWidth="700">
      <ActionsModal slot="content" :product-invoice-selected="productInvoiceSelected"
        @close="closeActions" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation"
      @yesActionDialogConfirmation="showInvoice"
      @noActionDialogConfirmation="closeDialogConfirmation"
      title="Visualizar Nota Fiscal"
      message="Deseja visualizar a Nota Fiscal Gerada?" />
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import DialogConfirmation from '@/components/DialogConfirmation';
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Select,
  Button,
  Dialog,
} from '@/components/vuetify';
import { required } from '@/utils/rules';
import {
  typeDocument,
  printerType,
  emissionFinality,
  emissionType,
} from '@/utils/options';
import {
  operationType,
  emissionType as emissionTypeEnum,
  emissionFinality as emissionFinalityEnum,
} from '@/utils/enums';
import ProductInvoiceActionsMixin from '@/mixins/ProductInvoiceActionsMixin';
import ActionsModal from './ActionsModal';

export default {
  name: 'GenerateProductInvoice',
  components: {
    ModalCardDefault,
    DialogConfirmation,
    ExpansionPanel,
    Row,
    Col,
    TextField,
    Select,
    Button,
    Dialog,
    ActionsModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    invoiceSettings: {
      type: Array,
      required: true,
    },
    invoiceSettingSelected: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    panel: [0],
    validForm: false,
    loading: false,
    loadingGenerateTransmit: false,
    dialogConfirmation: false,
    dialogActions: false,
    rules: {
      required: required
    },
    form: {
      invoiceSettingSelected: '',
      operation_type: '',
      model: '',
      series: '',
      nf_number: '',
      emission_type: '',
      emission_finality: '',
      printer_type: '',
    },
    loadingConfirmationTransmit: false,
    productInvoiceSelected: null,
  }),
  computed: {
    l () {
      return this.$locales.pt.orders.list.generateProductInvoice;
    },
    options() {
      return {
        typeDocument,
        printerType,
        emissionFinality,
        emissionType,
      };
    }
  },
  watch: {
  },
  mixins: [ProductInvoiceActionsMixin],
  methods: {
    setInvoiceSetting (invoiceSettingSelected) {
      if (invoiceSettingSelected.invoice_type === this.$enums.invoiceType.NFCE) {
        this.options.printerType = printerType.filter((printerType) => {
          return printerType.value === this.$enums.printerType.NFCE;
        });
      } else {
        this.options.printerType = printerType;
      }

      let operation_type = operationType.OUTPUT;
      let emission_finality = emissionFinalityEnum.NORMAL;
      const typePageOrders = this.$enums.typePageOrders;

      if (this.$route.meta.type === typePageOrders.ORDER_PURCHASE ||
        this.$route.meta.type === typePageOrders.ORDER_SALE_DEVOLUTION) {
        operation_type = operationType.INPUT;
        emission_finality = emissionFinalityEnum.DEVOLUTION;
      }

      this.form = {
        id: invoiceSettingSelected.id,
        operation_type,
        model: invoiceSettingSelected.model,
        series: invoiceSettingSelected.series,
        nf_number: this.order.invoice_number || Number(invoiceSettingSelected.current_number) + 1,
        emission_type: emissionTypeEnum.NORMAL,
        emission_finality,
        printer_type: invoiceSettingSelected.invoice_type === this.$enums.invoiceType.NFCE ? this.$enums.printerType.NFCE : this.$enums.printerType.PORTRAIT,
        environment: invoiceSettingSelected.environment,
      };
    },
    async generateInvoice ({ transmit }) {
      if (!this.$refs.validForm.validate()) {
        return false;
      }

      if (transmit) {
        this.loadingGenerateTransmit = true;
      } else {
        this.loading = true;
      }
      const payload = {
        ...this.form,
        order_id: this.order.id,
        invoice_setting_id: this.form.id,
      };

      const promiseGenerateInvoice = new Promise((resolve, reject) => {
        this.$api.orders.generateInvoice(payload).then((res) => {
          resolve(res);
        }).catch((err) => {
          this.$noty.error('Erro ao gerar nota fiscal');
          reject(err);
        });
      });

      promiseGenerateInvoice.then(({ product_invoice }) => {
        this.productInvoiceSelected = product_invoice;

        if (transmit) {
          const promiseTransmitNFe = new Promise((resolve, reject) => {
            this.$api.productInvoices.transmit({ nf_ids: [this.productInvoiceSelected.id] }).then((res) => {
              resolve(res);
            }).catch((err) => {
              this.$noty.error('Erro ao transmitir nota fiscal');
              reject(err);
            });
          });

          return promiseTransmitNFe;
        } else {
          this.$noty.success('Nota Gerada com sucesso');
          this.dialogConfirmation = true;
        }

      }).then(({ data }) => {
        if (data.protocol.status_code === '100' || data.protocol.status_code === '104') {
          this.productInvoiceSelected.key_nfe = data.protocol.key_nfe;
          this.$noty.success('NFe transmitida com sucesso');
          this.dialogActions = true;
        } else {
          this.$noty.error(data.protocol.reason);
          this.dialogConfirmation = true;
        }
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
        this.loadingGenerateTransmit = false;
      });
    },
    showInvoice () {
      this.dialogConfirmation = false;
      this.close();
      this.$router.push({
        name: this.$schemas.productInvoice.routes.show.name,
        params: {
          id: this.productInvoiceSelected.id,
        },
      });
    },
    closeDialogConfirmation () {
      this.dialogConfirmation = false;
      this.close();
    },
    close () {
      this.$refs.validForm.reset();
      this.form = {
        invoiceSettingSelected: '',
        operation_type: '',
        model: '',
        series: '',
        nf_number: '',
        emission_type: '',
        emission_finality: '',
        printer_type: '',
      };
      this.$emit('update:dialogModal', false);
    },
    closeActions () {
      this.dialogActions = false;
      this.close();
    },
  },
  mounted() {
    this.setInvoiceSetting(this.invoiceSettingSelected);
  }
};
</script>

<style>

</style>
