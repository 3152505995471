import BalanceSchema from './schemas/BalanceSchema';
import ListBalances from './pages/ListBalances';
import CreateBalance from './pages/CreateBalance';

export default [
  {
    path: BalanceSchema.routes.list.path,
    name: BalanceSchema.routes.list.name,
    component: ListBalances,
    meta: BalanceSchema.routes.list.meta,
  },
  {
    path: BalanceSchema.routes.create.path,
    name: BalanceSchema.routes.create.name,
    component: CreateBalance,
    meta: BalanceSchema.routes.create.meta
  },
  {
    path: BalanceSchema.routes.show.path,
    name: BalanceSchema.routes.show.name,
    component: CreateBalance,
    meta: BalanceSchema.routes.show.meta
  },
];
