<template>
  <div>
     <ModalCardDefault :title="`Novo Cliente para o Revendedor: ${resellerSelected.name}`" @close="close">
        <v-form v-model="formValid" ref="formValid" lazy-validation v-on:submit.prevent="register()">
          <ExpansionPanel v-model="panel" :title="'Dados do Cliente'" :icon="$icons.box" readonly>
            <Row>
              <Col>
                <TextField v-model="form.company.name" label="Razão Social"
                  :rules="[rules.required]" />
              </Col>
              <Col>
                <TextFieldSimpleMask v-model="form.company.cnpj" label="CNPJ"
                  :input-mask="masks.cnpj" output-mask="##############"
                    :rules="[rules.required]" />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField v-model="form.user.name" label="Nome do Contato"
                  :rules="[rules.required]" />
              </Col>
              <Col>
                <TextField v-model="form.user.email" label="E-mail do Contato"
                  :rules="[rules.required]" />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextFieldSimpleMask v-model="form.company.cell_phone" label="Celular/Whatsapp"
                  :input-mask="masks.cell_phone" output-mask="###########"
                  :rules="[rules.required]" />
              </Col>
              <Col>
                <TextField v-model="form.user.password" label="Senha"
                  type="password" v-on:keyup.enter="register()"
                  :rules="[rules.required]" />
              </Col>
            </Row>
          </ExpansionPanel>
        </v-form>

        <div slot="actions" class="d-flex justify-end" style="width: 100%">
          <Button :label="'Salvar'" class="mr-3" rounded color="secondary"
            @click="register()" />
        </div>
     </ModalCardDefault>
  </div>
</template>

<script>
import {
  TextField,
  TextFieldSimpleMask,
  ExpansionPanel,
  Row,
  Col,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { cnpj, cell_phone } from '@/utils/masks';
import { messageErrors } from '@/utils';
import { required } from '@/utils/rules';

export default {
  name: 'CreateCustomer',
  components: {
    TextField,
    TextFieldSimpleMask,
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    Button,
  },
  props: {
    resellerSelected: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      formValid: false,
      masks: {
        cnpj,
        cell_phone
      },
      rules: {
        required
      },
      dialogModal: false,
      loadingDataTable: false,
      form: {
        'company': {
          'name': 'MGE Tech',
          'cnpj': '11578224000100',
          'cell_phone': '84988481941'
        },
        'user': {
          'name': 'Renan Reis',
          'email': 'reistr85@gmail.com',
          'password': 're851120'
        }
      }
    };
  },
  computed: {
    headers () {
      return [];
    }
  },
  watch: {
  },
  methods: {
    register () {
      if (this.$refs.formValid.validate()) {
        this.loading = true;
        this.$api.resellers.createCustomer(this.form).then(() => {
          this.loading = false;
        }).catch((err) => {
          this.loading = false;

          if (err.response.status === 422) {
            this.$noty.error(messageErrors(err.response));
          } else if (err.response.status === 400) {
            this.$noty.error(err.response.data.error);
          } else {
            this.$noty.error('Erro ao Criar o Cliente');
          }
        });
      }
    },
    close () {
      this.$refs.formValid.reset();
      this.$emit('close');
    }
  },
  mounted () {
  }
};
</script>
