export default {
  title: 'Pagamentos',
  headers: [
    {
      text: 'Data',
      value: 'created_at_formatted',
    },
    {
      text: 'Forma de Pagamento',
      value: 'payment_method.name',
    },
    {
      text: 'Parcelamento',
      value: 'type_installment.name',
    },
    {
      text: 'Bandeira',
      value: 'flag.name',
    },
    {
      text: 'Aut.',
      value: 'aut',
    },
    {
      text: 'Valor Pago',
      value: 'value_formatted',
    }
  ],
};
