class BoxMovement {
  get model() {
    return {
      movement_date: '',
      movement_type: '',
      payment_method_id: '',
      value: 0,
    };
  }
}

export default new BoxMovement();
