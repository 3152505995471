import { required } from '@/utils/rules';

export default {
  beforeList: ({ form }) => {
    form.all_types.forEach((order) => {
      if (order.is_global) {
        order.destroyDisabled = true;
        order.showDisabled = true;
      } else {
        order.destroyDisabled = false;
      }
    });
  },
  beforeForm: ({ route, propsFields }) => {
    const routesName = ['unitMeasurement', 'ncm', 'cest'];
    const rules = {
      unitMeasurement: { counter: 2, maxLength: 2 },
      ncm: { counter: 8, maxLength: 8 },
      cest: { counter: 7, maxLength: 7 },
    };

    if (routesName.includes(route.meta.type)) {
      propsFields.value.rules = [required];
      propsFields.value.readonly = false;
      propsFields.value.counter = rules[route.meta.type].counter;
      propsFields.value.maxLength = rules[route.meta.type].maxLength;
    }
  },
  afterUpdated: () => {
  },
};
