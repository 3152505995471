import ReportSchema from './schemas/ReportSchema';
import ListReports from './pages/ListReports';
import CreateReport from './pages/CreateReport';

let routes = [];

Object.keys(ReportSchema.routes).forEach((key) => {
  Object.keys(ReportSchema.routes[key]).forEach((itemKey) => {
    routes.push({
      path: ReportSchema.routes[key][itemKey].path,
      name: ReportSchema.routes[key][itemKey].name,
      component: itemKey === 'list' ? ListReports : CreateReport,
      meta: ReportSchema.routes[key][itemKey].meta,
    });
  });
});

export default routes;
