import DynamicService from '@/service/DynamicService';
import RegisterSchema from '../schemas/RegisterSchema';
import { formatPhone, formatCpfCnpj, getText } from '@/utils';
import { typePage, personType } from '@/utils/enums';
import { typeTaxPayer, typePersonType } from '@/utils/options';
import { clone } from 'lodash';

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.cell_phone_formatted = formatPhone(item.cell_phone);
    item.cnpj_formatted = formatCpfCnpj(item.cnpj);
    item.cpf_formatted = formatCpfCnpj(item.cpf);
    item.person_type_formatted = getText(typePersonType, item.person_type);
    item.taxpayer_type_formatted = getText(typeTaxPayer, item.taxpayer_type);
    item.address = {
      ...item.address,
      city_formatted: `${item.address.city}/${item.address.uf}`
    };
  };

  const formatShow = (register) => {
    if (register.person_type === personType.PHYSICAL) {
      register.social_name = null;
    }
  };

  type === typePage.LIST ? formatList(item) : formatShow(item.register);
};

const formatRequest = (data, type = typePage.CREATE) => {
  const item = clone(data);

  if (type === typePage.SHOW) {
    delete item.address?.addressable_type;
    delete item.address?.addressable_id;
    delete item.address?.situation;
    delete item.address?.deleted_at;
    delete item.address?.created_at;
    delete item.address?.updated_at;
    delete item.type;
  }

  return item;
};

const RegistersService = DynamicService('registers', RegisterSchema, { formatResponse, formatRequest, notRemoveDefaultKeys: true });

export default RegistersService;
