<template>
  <ModalCardDefault title="Devolver Pedido" @close="close">
    <ExpansionPanel v-model="panel" title="Confirme as opções abaixo" :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <Row>
          <Col>
            <Select
              v-model="natureOperationId"
              label="Selecione a Natureza da Operação"
              :rules="[rules.required]"
              :items="natureOperations" />
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckBox v-model="generateInvoice"
              label="Gerar NF-e de Devolução?"
              @setCheckBox="setGenerateInvoice" />
          </Col>
          <Col>
            <CheckBox v-model="transmitInvoice"
              label="Transmitir NF-e de Devolução?"
              @setCheckBox="setTransmitInvoice" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <div slot="actions">
      <Button label="Confirmar"  @click="finishDevolutionOrder"
        class="mr-3" rounded color="secondary" :loading="loadingAction" />
    </div>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  CheckBox,
  Row,
  Col,
  Button,
  Select,
} from '@/components/vuetify';
import { required } from '@/utils/rules';

export default {
  name: 'GenerateDevolutionOrder',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    CheckBox,
    Row,
    Col,
    Button,
    Select,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    loadingAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panel: [0],
      validForm: false,
      rules: {
        required,
      },
      generateInvoice: false,
      transmitInvoice: false,
      natureOperations: [],
      natureOperationId: '',
    };
  },
  methods: {
    getNatureOperation() {
      return this.$api.natureOperations.index().then(({ data: { nature_operations } }) => {
        console.log(nature_operations);
        this.natureOperations = nature_operations.filter((item) => {
          return item.type === this.$enums.typeNatureOperation.DEVOLUTION_SALES || item.type === this.$enums.typeNatureOperation.DEVOLUTION_PURCHASE;
        }).map((item) => ({
          text: item.name,
          value: item.id,
        }));
      });
    },
    setGenerateInvoice(value) {
      this.generateInvoice = value || false;
    },
    setTransmitInvoice(value) {
      this.transmitInvoice = value || false;

      if (this.transmitInvoice) {
        this.generateInvoice = true;
      }
    },
    finishDevolutionOrder() {
      if (!this.$refs.validForm.validate()) {
        return false;
      }
      this.$emit('handleActionMoreActions', {
        emitName: 'devolutionOrder',
        nature_operation_id: this.natureOperationId,
        order_id: this.item.id,
        generateInvoice: this.generateInvoice,
        transmitInvoice: this.transmitInvoice,
      });
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.getNatureOperation();
  },
};
</script>
