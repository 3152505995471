import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';

export const getAll = (server) => {
  server.get('/stocks', (schema, request) => {
    const stocks = schema.stocks.all();
    const { page, perPage } = request.queryParams;

    stocks.models = softDelete(stocks);

    return pagination('stocks', stocks, page, perPage);
  });
};