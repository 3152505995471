<template>
  <div>
    <PageHeader :schema="$schemas.stock" />
    <PageContent :schema="$schemas.stock">
      <Row>
        <Col>
          <Button color="primary" label="Entrada/Saída Produtos" rounded @click="createStockMovement" />
        </Col>
      </Row>
      <DynamicListPage ref="DynamicListPage" :schema="$schemas.stock" :service="$api.stocks"
        @actionMoreActions="actionMoreActions" />
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog, Row, Col, Button } from '@/components/vuetify';
import ListMovements from './components/ListStocks/ListMovements';
import CreateStockMovement from './components/ListStocks/CreateStockMovement';
import { limitCharacters } from '@/utils';

const TYPE_COMPONENT_MODAL = Object.freeze({
  listMovements: ListMovements,
  createStockMovement: CreateStockMovement
});

export default {
  name: 'ListStocks',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog,
    Row,
    Col,
    Button,
  },
  data () {
    return {
      dialogModal: false,
      componentModal: null,
      stockSelected: null,
      modalProps: {},
      stocks: [],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.stocks.list;
    },
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    movements (item) {
      this.stockSelected = item;
      this.componentModal = TYPE_COMPONENT_MODAL.listMovements;
      this.modalProps = {
        maxWidth: 1200,
        title: this.l.modalMovements.title.replace('$stock', this.stockSelected.name),
        headers: this.$schemas.stock.list.movements.headers,
        stockSelected: this.stockSelected,
      };
      this.dialogModal = true;
    },
    async createStockMovement() {
      if (this.stocks.length === 0) {
        const { data: { stocks } } = await this.$api.stocks.filters({ url: 'stocks?all-companies=true' });
        this.stocks = stocks.map((stock) => {
          return {
            ...stock,
            name: `${stock.name} - ${limitCharacters(stock.company.name, 20)}`,
          };
        });
      }

      this.componentModal = TYPE_COMPONENT_MODAL.createStockMovement;
      this.modalProps = {
        maxWidth: 800,
        stocks: this.stocks,
      };
      this.dialogModal = true;

    },
    closeModal () {
      this.dialogModal = false;
      this.componentModal = null;
      this.modalProps = {};
      this.stockSelected = null;
      this.$refs.DynamicListPage.fetchDataApi();
    },
  },
};
</script>
