import { render, staticRenderFns } from "./PdvRemoveItem.vue?vue&type=template&id=fa332cc6&scoped=true&"
import script from "./PdvRemoveItem.vue?vue&type=script&lang=js&"
export * from "./PdvRemoveItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa332cc6",
  null
  
)

export default component.exports