<template>
  <ModalCardDefault title="Produtos" @close="close">
    <ExpansionPanel v-model="panel" title="Produtos do Balanço" :icon="$icons.box" readonly>
      <Row>
        <Col>
          <v-form v-model="valid" ref="form" @submit.prevent="measureByBarCode" lazy-validation>
            <TextField v-model="barCode" ref="RefBarCode" label="Código de barras" :rules="[rules.required]" />
            <CheckBox v-model="filterMissingProducts" @setCheckBox="setFilterMissingProducts" label="Somente os produtos faltantes" />
          </v-form>
        </Col>
      </Row>
      <Row>
        <Col>

        </Col>
      </Row>
      <DataTable no-search :items="balanceItems"
        :headers="headers" :items-per-page="5" :loading="loading"
        :slots="slotsItems" @getItems="getBalanceItems">
        <template v-slot:[`price_retail`]="{ props: { item } }">
          {{ utils.formatCurrency(item.price_retail) }}
        </template>
        <template v-slot:[`price_wholesale`]="{ props: { item } }">
          {{ utils.formatCurrency(item.price_wholesale) }}
        </template>
        <template v-slot:[`quantity`]="{ props: { item } }">
          {{ utils.formatCurrency(item.quantity, { type: 'decimal' }) }}
        </template>
        <template v-slot:[`quantity_measured`]="{ props: { item } }">
          {{ utils.formatCurrency(item.quantity_measured, { type: 'decimal' }) }}
        </template>
        <template v-slot:[`actions`]="{ props: { item } }">
          <ButtonIcon x-small color="success" tooltip="Aferir item" :icon="$icons.check" @click="measure(item, 1)" />
        </template>
      </DataTable>
    </ExpansionPanel>
    <Row class="mt-5 custom-footer">
      <Col md="4">
        <Alert color="primary" class="py-2">
          <h5><b>Total Volumes Balanço</b></h5>
          <h4>{{ totalizers.quantityVolumes }}</h4>
        </Alert>
      </Col>
      <Col md="4">
        <Alert color="primary" class="py-2">
          <h5><b>Total Volumes Aferidos</b></h5>
          <h4>{{ totalizers.quantityVolumesMeasured }}</h4>
        </Alert>
      </Col>
      <Col md="4">
        <Alert color="primary" class="py-2">
          <h5><b>Saldo Volumes</b></h5>
          <h4>{{ totalizers.balance }}</h4>
        </Alert>
      </Col>
    </Row>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  DataTable,
  Row,
  Col,
  Alert,
  ButtonIcon,
  TextField,
  CheckBox,
} from '@/components/vuetify';
import { formatDatePT, formatCurrency, messageErrors } from '@/utils';
import { required } from '@/utils/rules';

export default {
  name: 'ListBalanceItems',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    DataTable,
    Row,
    Col,
    Alert,
    ButtonIcon,
    TextField,
    CheckBox,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    panel: [0],
    valid: false,
    utils: {
      formatDatePT,
      formatCurrency,
    },
    rules : {
      required,
    },
    loading: false,
    slotsItems: [
      { field: 'price_retail' },
      { field: 'price_wholesale' },
      { field: 'quantity' },
      { field: 'quantity_measured' },
    ],
    balanceItems: [],
    totalizers: {
      quantityVolumesMeasured: 0,
    },
    barCode: null,
    filterMissingProducts: false,
  }),
  computed: {
    headers () {
      return [
        { text: 'Cod.', value: 'code', },
        { text: 'Produto', value: 'name', },
        { text: 'Cod. Barras', value: 'bar_code', },
        { text: 'Vlr. Varejo.', value: 'price_retail', },
        { text: 'Vlr. Atacado', value: 'price_wholesale', },
        { text: 'Qtd.', value: 'quantity', },
        { text: 'Aferido.', value: 'quantity_measured', },
        { text: 'Ações.', value: 'actions', },
      ];
    },
  },
  methods: {
    getBalanceItems(pagination) {
      this.loading = true;
      this.$api.balances.getAllBalanceItemsByBalanceId(this.item.id, this.filterMissingProducts, pagination).then((res) => {
        this.balanceItems = res;
      }).catch(() => {
        this.$noty.error('Erro ao pegar os produtos do balanço');
      }).finally(() => {
        this.loading = false;
      });
    },
    measure(item, quantity) {
      this.$api.balances.measure(item.balance_id, item.id, quantity).then(({ data: { balance } }) => {
        this.$noty.success('Item aferido com sucesso');
        this.getBalanceItems();
        this.getQuantityVolumesMeasured(balance);
      }).catch((err) => {
        this.changeErrors(err);
      });
    },
    measureByBarCode() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$api.balances.measureByBarCode(this.item.id, this.barCode).then(({ data: { balance } }) => {
        this.$noty.success('Item aferido com sucesso');
        this.getBalanceItems();
        this.getQuantityVolumesMeasured(balance);
      }).catch((err) => {
        this.changeErrors(err);
      }).finally (() => {
        this.barCode = null;
        this.valid = false;
        this.$refs.form.resetValidation();
      });
    },
    getQuantityVolumesMeasured(item) {
      this.totalizers = {
        quantityVolumesMeasured: item.quantity_volumes_measured,
        quantityVolumes: item.quantity_volumes,
        balance: item.quantity_volumes_measured - item.quantity_volumes,
      };
    },
    changeErrors(err) {
      if (err.response.status === 422) {
        this.$noty.error(messageErrors(err.response));
      } else if (err.response.status === 400 || err.response.status === 404) {
        this.$noty.error(err.response.data.error);
      } else {
        this.$noty.error('Erro ao aferir item');
      }
    },
    setFilterMissingProducts(value) {
      this.filterMissingProducts = value || false;
      this.getBalanceItems();
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.getQuantityVolumesMeasured(this.item);
  },
};
</script>
