<template>
  <div>
    <ModalCardDefault :title="title" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="''" :icon="$icons.box" readonly>
        <Row>
          <Col>
            <TextField label='Valor' v-model="form.description" />
          </Col>
        </Row>
      </ExpansionPanel>

      <template v-slot:actions="{}">
        <Button :label="l.actions.save" color="secondary" :icon="$icons.save"
          rounded class="mr-2" @click="save()" :loading="loading" />
      </template>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Button
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'CreateVariationValues',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    TextField,
    Button
  },
  props: {
    variation: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      variationValues: [],
      form: {
        variation_id: '',
        description: '',
      },
      loading: false,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.variations.list.dialogVariationValues.create;
    },
    title () {
      return `${this.l.title} ${this.variation.description}`;
    }
  },
  methods: {
    getVariationValues () {
      this.$api.variationValues.getByVariationId(this.variation.id).then((res) => {
        this.variationValues = res.data.variation_values;
      }).catch(() => {
      }).finally(() => {
      });
    },
    save () {
      this.loading = true;
      this.$api.variationValues.create({ ...this.form, variation_id: this.variation.id }).then(() => {
        this.$noty.success(this.l.noty.successSave);
        this.form = {
          variation_id: '',
          description: '',
        };
        this.$emit('close');
      }).catch(() => {
        this.$noty.success(this.l.noty.errorSave);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  async mounted () {
    await this.getVariationValues();
  },
};
</script>

<style>

</style>
