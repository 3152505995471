import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { filter } from '../../utils/filter';

export const getAll = (server) => {
  server.get('/products', (schema, request) => {
    const products = schema.products.all();
    const { page, perPage } = request.queryParams;
    products.models = filter(request.queryParams, products);
    products.models = softDelete(products);

    return pagination('products', products, page, perPage);
  });
};