import axios from '@/service';
import DynamicService from '@/service/DynamicService';

const VariationValueSchema = {
  domain: 'variation_values',
  domainSingle: 'variation_value'
};

const formatResponse = () => {
};

const VariationValuesService = DynamicService('variation_values', VariationValueSchema, { formatResponse }
);

const VariationValuesCommands = () => ({
  async getByVariationId(variationId) {
    return await axios.get(`filters?domain=${VariationValueSchema.domainSingle}&search_global=true&filter[variation_id]=${variationId}&page=1&per_page=-1`);
  }
});

export default VariationValuesService;
export { VariationValuesService, VariationValuesCommands };
