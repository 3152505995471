<template>
  <div>
    <v-divider v-bind="$attrs" :class="[{'weight-bold': weightBold}]"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    weightBold: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.weight-bold {
  border: 1px solid #CCC
}
</style>
