import DynamicService from '@/service/DynamicService';
import ProductVariationStockMovementSchema from '../schemas/ProductVariationStockMovementSchema';
import { formatDatePT, formatCurrency } from '@/utils';

const formatResponse = (item) => {
  item;
  item.movement_date_formatted = formatDatePT(item.movement_date);
  item.quantity_formatted = formatCurrency(item.quantity, { type: 'decimal' });
};

const ProductVariationStockMovementsService = DynamicService('product_variation_stock_movements', ProductVariationStockMovementSchema, { formatResponse });

export default ProductVariationStockMovementsService;
