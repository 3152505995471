<script>
import { required } from '@/utils/rules';

export default {
  data () {
    return {
      rules: { required: required },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.products.list.modalVariations.createVariation;
    },
  },
};
</script>