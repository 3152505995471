<template>
  <div>
    <PageHeader :schema="$schemas.serviceInvoice" />
    <PageContent>
      <DynamicListPage :schema="$schemas.serviceInvoice" :service="$api.serviceInvoices" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListServiceInvoices',
  components: { PageHeader, PageContent, DynamicListPage },
};
</script>
