<template>
  <ModalCardDefault :title="l.title" @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" title="Dados do Veículo" :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation class="px-5 pt-5">
        <Row>
          <Col md="4">
            <TextField v-model="vehicle.plate" :label="l.fields.plate.label"
              :rules="[rules.required]" :counter="7" />
          </Col>
          <Col md="4">
            <Select v-model="vehicle.uf" :label="l.fields.uf.label" :items="ufs" :rules="[rules.required]" />
          </Col>
          <Col md="4">
            <TextField v-model="vehicle.rntc" :label="l.fields.rntc.label" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button :label="l.actions.create" class="mr-3" rounded color="secondary"
        @click="saveVehicle" />
    </div>
  </ModalCardDefault>
</template>

<script>
import {
  Button,
  Row,
  Col,
  Select,
  TextField,
  ExpansionPanel,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required } from '@/utils/rules';
import { UF } from '@/utils/options';

export default {
  name: 'CreateVehicles',
  components: {
    ModalCardDefault,
    Button,
    Row,
    Col,
    Select,
    TextField,
    ExpansionPanel,
  },
  props: {
    registerSelectedId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      validForm: true,
      rules: { required: required },
      ufs: UF,
      vehicle: {},
    };
  },
  computed: {
    l () {
      return this.$locales.pt.vehicles.create;
    },
  },
  methods: {
    saveVehicle() {
      if (!this.$refs.validForm.validate()) {
        return false;
      }

      this.$api.vehicles.create({ ...this.vehicle, register_id: this.registerSelectedId }).then(({ vehicle }) => {
        this.$noty.success(this.$locales.pt.vehicles.list.noty.successCreate);
        this.$emit('insertVehicleInList', { ...this.vehicle, id: vehicle.id});
      }).catch(() => {
        this.$noty.error(this.$locales.pt.vehicles.list.noty.errorCreate);
      });
    },
  },

};
</script>

<style>

</style>
