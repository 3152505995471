import ResellerSchema from './schemas/ResellerSchema';
import ListResellers from './pages/ListResellers';
import CreateReseller from './pages/CreateReseller';

export default [
  {
    path: ResellerSchema.routes.list.path,
    name: ResellerSchema.routes.list.name,
    component: ListResellers,
    meta: ResellerSchema.routes.list.meta,
  },
  {
    path: ResellerSchema.routes.create.path,
    name: ResellerSchema.routes.create.name,
    component: CreateReseller,
    meta: ResellerSchema.routes.create.meta
  },
  {
    path: ResellerSchema.routes.show.path,
    name: ResellerSchema.routes.show.name,
    component: CreateReseller,
    meta: ResellerSchema.routes.show.meta
  },
];
