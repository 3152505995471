import CreditCardControlSchema from './schemas/CreditCardControlSchema';
import ListCreditCardControls from './pages/ListCreditCardControls';

export default [
  {
    path: CreditCardControlSchema.routes.list.path,
    name: CreditCardControlSchema.routes.list.name,
    component: ListCreditCardControls,
    meta: CreditCardControlSchema.routes.list.meta,
  },
];
