<template>
  <div>
    <PageHeader :schema="$schemas.customer" />
    <PageContent>
      <DynamicListPage :schema="$schemas.customer" :service="$api.customers" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListCustomers',
  components: { PageHeader, PageContent, DynamicListPage }
};
</script>
