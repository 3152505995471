<template>
  <DataTable class="my-5" no-search no-sync :items.sync="item.payments"
    :headers="headers" hide-default-footer :items-per-page="-1" />
</template>

<script>
import {
  DataTable,
} from '@/components/vuetify';

export default {
  name: 'InstallmentTabPayments',
  components: {
    DataTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headers () {
      return [
        {
          text: 'Pagamento',
          value: 'paid_date_formatted',
        },
        {
          text: 'Compensação',
          value: 'compensation_date_formatted',
        },
        {
          text: 'Forma de Pagamento',
          value: 'payment_method.description',
        },
        {
          text: 'Bandeira',
          value: 'flag.description',
        },
        {
          text: 'Aut',
          value: 'aut',
        },
        {
          text: 'Parcelas',
          value: 'quantity_installments',
        },
        {
          text: 'Valor',
          value: 'value_formatted',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ];
    }
  },
};
</script>

<style>

</style>
