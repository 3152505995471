<template>
  <v-file-input
    v-model="file"
    :outlined="outlined"
    :dense="dense"
    hide-details="auto">
  </v-file-input>
</template>

<script>
export default {
  name: 'FileField',
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      file: {}
    };
  },
  watch: {
    file () {
      this.$emit('getFile', this.file);
    }
  }
};
</script>
