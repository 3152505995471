import { typePageOptions } from '@/utils';
import { text, list } from '@/utils/icons';
import { required } from '@/utils/rules';

export default {
  domain: 'variations',
  domainSingle: 'variation',
  title: 'Variações',
  description: 'Lista de todos as Variações do sistema',
  createBtn: 'Nova Variação',
  showInactive: false,
  routes: {
    list: {
      name: 'variations',
      path: '/variacoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'variationCreate',
      path: '/variacoes/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'variationShow',
      path: '/variacoes/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Valores da Variação',
          icon: list,
          action: 'variationValues'
        },
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Variação',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [],
  list: {
    dialogVariationValues: {
      headers: [
        {
          text: 'Valores da Variação',
          value: 'description',
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end',
        },
      ]
    }
  }
};
