<template>
  <div>
    <PageHeader :schema="$schemas.company" />
    <PageContent>
      <DynamicListPage :schema="$schemas.company" :service="$api.companies"
        @actionMoreActions="actionMoreActions" />
    </PageContent>

    <DialogConfirmation :dialog="dialog" :loading="loadingConfirm"
      :title="'Fazer Login'"
      :message="`Deseja Relamente fazer o Login com a ${companySelected.name} ?`"
      @noActionDialogConfirmation="dialog = $event"
      @yesActionDialogConfirmation="confirmLogin" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import DialogConfirmation from '@/components/DialogConfirmation';
import AuthService from '@/views/Auth/services/AuthService';
import { getTokenSuper, getUserSuper } from '@/utils';

export default {
  name: 'ListCompanies',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    DialogConfirmation
  },
  data () {
    return {
      dialog: false,
      loadingConfirm: false,
      companySelected: {},
      actionSelected: null,
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this.companySelected = item;
      this.actionSelected = moreActionItem.action;
      this.dialog = true;
    },
    async confirmLogin () {
      await this[this.actionSelected]();
    },
    async loginAsUser () {
      this.loadingConfirm = true;
      this.$api.companies.loginAs(this.companySelected.id)
        .then((res) => {
          AuthService.setLocalStorage(res.data);
          window.location = process.env.VUE_APP_URL;
        })
        .catch(() => {
          this.$message.error('Erro ao fazer login');
        })
        .finally(() => {
          this.dialog = false;
          this.loadingConfirm = false;
        });
    },
    async logoutAsUser () {
      AuthService.setLocalStorage({
        'token': getTokenSuper(),
        'token_type': 'bearer',
        'expires_in': '252000',
        'user': getUserSuper(),
      });
      window.location = process.env.VUE_APP_URL;
    }
  }
};
</script>
