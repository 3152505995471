<template>
  <div>
    <v-tooltip v-model="show" top :color="toolTipColor">
      <template v-slot:activator="{ on }">
        <v-autocomplete
          v-bind="$attrs"
          v-on="on"
          :clearable="clearable"
          :chips="false"
          :items="localItems.data"
          :loading="loading"
          :search-input.sync="search"
          no-filter
          persistent-counter
          persistent-placeholder
          :ref="refs"
          :id="id"
          :label="label"
          :placeholder="placeholder"
          :rules="rules"
          :dense="dense"
          :readonly="readonly"
          :outlined="outlined"
          :disabled="disabled"
          :prepend-icon="icon"
          :item-text="itemText"
          :item-value="itemValue"
          :multiple="multiple"
          :return-object="returnObject"
          @change="changeAutoComplete" />
      </template>
      <span>{{ title }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import axios from '@/service';
import { getCompany } from '@/utils';

export default {
  name: 'AutoComplete',
  props: {
    id: {
      type: String,
      default: '',
    },
    refs: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemTextDataServe: {
      type: String,
      default: '',
    },
    itemValueDataServe: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    verb: {
      type: String,
      default: 'get',
    },
    domain: {
      type: String,
      default: '',
    },
    resultDomain: {
      type: String,
      default: '',
    },
    searchBy: {
      type: Array,
      default: () => [],
    },
    toolTipColor: {
      type: String,
      default: 'primary',
    },
    initLocalItems: {
      type: Array,
      default: () => [{ text: '', value: '' }],
    },
    title: {
      type: String,
      default: 'Pesquisar',
    },
    identify: {
      type: String,
      default: '',
    },
    formatItemName: {
      type: Function
    },
    conditionOr: {
      type: Boolean,
      default: false,
    },
    fixedValueSearch: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      search: '',
      localItems: {},
      searchItems: [],
      show: false,
    };
  },
  computed: {
    pathLocalStorage () {
      const company = getCompany();

      return `${process.env.VUE_APP_NAME}-${company.cnpj}.auto-complete@${this.$route.name}@${this.domain}@${this.identify}`;
    },
  },
  watch: {
    $attrs () {
      if (!this.$attrs.value?.text) {
        return;
      }

      this.localItems = { data: [{ text: this.$attrs.value?.text, value: this.$attrs.value?.value }] };
    },
    initLocalItems () {
      // this.localItems = { data: this.initLocalItems };
    },
    search () {
      this.debounceInput(this.search);
    },
    searchItems: {
      handler() {
        this.$set(this.localItems, 'data', [
          // ...this.initLocalItems,
          ...this.searchItems,
        ]);
      },
      deep: true,
    }
  },
  methods: {
    debounceInput: debounce(function (data) {
      if (data) {
        this.loading = true;
        let filters = '&page=1&per_page=-1';
        this.searchBy.forEach(item => {
          filters += `&filter[${item}]=${data}`;
        });

        if (this.fixedValueSearch) {
          filters += `&${Object.entries(this.fixedValueSearch)
            .map(([key, value]) => `filter[${key}]=${encodeURIComponent(value)}`)
            .join('&')}`;
        }

        let condition = '';

        if (this.conditionOr) {
          condition += '&condition=or';
        }

        axios[this.verb](`filters?domain=${this.domain}${condition}${filters}`).then((res) => {
          this.searchItems = res.data[this.resultDomain].map((item) => {
            let data = {};

            if (this.formatItemName) {
              data = {
                ...item,
                ...this.formatItemName(item)
              };
            } else {
              data = {
                ...item,
                [this.itemText]: item[this.itemTextDataServe],
                [this.itemValue]: item[this.itemValueDataServe]
              };
            }

            return data;
          });

        }).catch(() => {
        }).finally(() => {
          this.loading = false;
        });
      }
      this.$emit('getItems', data);
    }, 700),
    changeAutoComplete (data) {
      if (!data) {
        this.$emit('change', null);

        return;
      }

      // let dataCache = JSON.parse(localStorage.getItem(this.pathLocalStorage));
      // if (!dataCache) {
      //   dataCache = [data];
      // } else {
      //   const registerAlreadyExists = dataCache.find(item => {
      //     return item[this.itemValue] === data[this.itemValue];
      //   });
      //   if (!registerAlreadyExists) {
      //     dataCache.push(data);
      //   }
      // }

      // localStorage.setItem(this.pathLocalStorage, JSON.stringify(dataCache));
      this.$emit('change', data);
    },
  },
  mounted () {

    // this.loading = true;
    // setTimeout(() => {
    //   const dataCache = JSON.parse(localStorage.getItem(this.pathLocalStorage));
    //   if (!this.initLocalItems.length && dataCache) {
    //     this.localItems = { data: dataCache };
    //   }

    //   if (this.$attrs.value?.value) {
    //     this.localItems.data.push(this.$attrs.value);
    //   }
    //   this.loading = false;
    // }, 1000);
  },
};
</script>

<style>

</style>
