<template>
  <div class="danfe">
    <div class="danfe-header my-2">
      <p v-text="model.emitter.name" />
      <p v-text="`${model.emitter.street}
        ${model.emitter.number || ''} ${model.emitter.neighborhood}
        ${model.emitter.city}/${model.emitter.uf}`" />
      <p v-text="`Fone: ${model.emitter.phone}`" />
      <p v-text="`CNPJ: ${model.emitter.cnpj} - IE: ${model.emitter.ie}`" />
    </div>
    <div class="line" />
    <div class="danfe-description my-2">
      <p><b>DANFE NFC-e - Documento Auxiliar da Nota Fiscal Eletrônica para Consumidor Final</b></p>
      <p><b>Não permite aproveitamento de crédito ICMS</b></p>
    </div>
    <div class="line" />
    <div class="danfe-items my-2">
      <table>
        <thead>
          <tr>
            <th width="50"><p><b>Item</b></p></th>
            <th width="50"><p><b>Cod.</b></p></th>
            <th width="320"><p><b>Descrição</b></p></th>
          </tr>
          <tr>
            <th colspan="2">
              <p>
                <span class="ml-5"><b>Qtdo</b></span>&nbsp;
                <span><b>UN</b></span>
              </p>
            </th>
            <th align="right">
              <p>
                &nbsp;&nbsp;&nbsp;
                <span><b>Vlr. Uni.</b></span>
                &nbsp;
                <span><b>Vlr. Total</b></span>
              </p>
            </th>
          </tr>
        </thead>
        <tr><td colspan="3"><div class="line my-2" /></td></tr>
        <tbody v-for="(item, index) in model.items" :key="index" class="my-2">
          <tr>
            <td><p>{{ index + 1 }}</p></td>
            <td><p>{{ item.code }}</p></td>
            <td><p>{{ utils.limitCharacters(item.name, 38) }}</p></td>
          </tr>
          <tr>
            <td colspan="2">
              <p>
                <span class="ml-5">{{ item.quantity }}</span>&nbsp;
                <span>{{ item.unit_measure }}</span>
              </p>
            </td>
            <td align="right">
              <p>
                &nbsp;&nbsp;&nbsp;
                <span>{{ utils.formatCurrency(item.unit_value, { type: 'decimal' }) }}</span>
                &nbsp;
                <span>{{ utils.formatCurrency(item.total_value, { type: 'decimal' }) }}</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="line" />
    <div class="danfe-resume-payment my-2">
      <table>
        <tr>
          <td align="left"><p>Qtd. Itens</p></td>
          <td align="right"><p>{{ quantityItems }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Sub Total R$</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total.products_value, { type: 'decimal' }) }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Deconto R$</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total.discount_value, { type: 'decimal' }) }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Valor Total R$</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total.nf_value, { type: 'decimal' }) }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Forma Pagamento</p></td>
          <td align="right"><p>Valor Pago</p></td>
        </tr>
        <tr v-for="(payment, index) in model.payments" :key="index">
          <td align="left"><p>{{ payment.payment_method.name }}</p></td>
          <td align="right"><p>{{ utils.formatCurrency(payment.value, { type: 'decimal' }) }}</p></td>
        </tr>
      </table>
    </div>
    <div class="line" />
    <div class="danfe-taxes my-2">
      <table>
        <tr>
          <td align="left"><p>Informações dos Tributos Totais Incidentes</p></td>
        </tr>
        <tr>
          <td align="left"><p>(Lei Federal 12.741-2012)</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total.tax_approximates_value) }}</p></td>
        </tr>
      </table>
    </div>
    <div class="line" />
    <div class="danfe-client my-2">
      <table>
        <tr>
          <td align="left">
            <span v-html="client"></span>
          </td>
        </tr>
      </table>
    </div>
    <div class="line" />
    <div class="danfe-infos my-2">
      <table>
        <tr>
          <td align="center">
            <p>Número {{ model.nf_number }} Série {{ model.series }} Emissão {{ emissionDate }}</p>
            <p>Via Consumidor</p>
            <p><b>{{ l.consult }}</b></p>
            <p>{{ l.site }}</p>
            <p class="mt-5"><b>{{ l.key }}</b></p>
            <p>{{ model.key_nfe }}</p>
          </td>
        </tr>
      </table>
    </div>
    <div class="line mt-5" />
    <div class="danfe-qr-code my-2">
      <p>Consulta via QR Code</p>
      <canvas ref="qrcodeCanvas"></canvas>
      <p class="mt-5">Protocolo de Autorização {{ model.protocol_number }} {{ emissionDate }}</p>
    </div>
  </div>
</template>

<script>
import { limitCharacters, formatCurrency, formatDatePT, getHourInDateTime } from '@/utils';
import QRCode from 'qrcode';

export default {
  name: 'Danfe65',
  components: {
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.header;
    },
    quantityItems () {
      return this.model.items.length;
    },
    emissionDate () {
      return `${formatDatePT(this.model.emission_date)} - ${getHourInDateTime(this.model.emission_date)}`;
    },
    client() {
      if (!this.model.client && !this.model.client_document) {
        return 'Cliente Não Informado';
      }

      if (this.model.client_document) {
        const labelDocument = this.model.client_document.length === 11 ? 'CPF' : 'CNPJ';

        return `<p><b>${labelDocument}:</b> ${this.model.client_document}</p>`;
      }

      if (this.model.client) {
        let labelDocument = 'CNPJ';
        let typeDocument =  'cnpj';

        if (this.model.client.person_type === this.$enums.personType.PHYSICAL) {
          labelDocument = 'CPF';
          typeDocument = 'cpf';
        }

        return `<p><b>${labelDocument}:</b> ${this.model.client[typeDocument]} - <b>Nome:</b> ${this.model.client?.name}</p>`;
      }

      return '';
    },
  },
  data () {
    return {
      utils: {
        limitCharacters,
        formatCurrency,
      },
    };
  },
  methods: {
    generateQRCode() {
      if (this.model.qr_code) {
        QRCode.toCanvas(this.$refs.qrcodeCanvas, this.model.qr_code, (error) => {
          if (error) console.error(error);
        });
      }
    },
  },
  mounted() {
    this.generateQRCode();
  },
};
</script>

<style lang="scss" scoped>
.danfe {
  text-align: center;

  .line {
    height: 1px;
    border-top: 1px #000 dashed;
  }

  p {
    line-height: 18px;
    margin: 3px 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    p {
      font-size: 10px;
      margin: 0 2px;
    }
  }

  .danfe-items {
    width: 100%;
    text-align: left;
    padding: 0 1px;

    table {
      tbody {
        margin-top: 15px;
      }
    }
  }

  .danfe-resume-payment, .danfe-taxes {
    width: 100%;
    text-align: left;
    padding: 0 1px;
  }
}
</style>
