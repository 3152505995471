import DynamicService from '@/service/DynamicService';
import CreditCardAdministratorSchema from '../schemas/CreditCardAdministratorSchema';
import { formatCurrency, formatCpfCnpj } from '@/utils';

const formatResponse = (item) => {
  item.cnpj_formatted = formatCpfCnpj(item.cnpj);
  item.fee_formatted = formatCurrency(item.fee, { type: 'percent' });
  item.anticipation_fee_formatted = formatCurrency(item.anticipation_fee, { type: 'percent' });
  item.additional_fee_formatted = formatCurrency(item.additional_fee);
};

const CreditCardAdministratorsService = DynamicService('credit_card_administrators',
  CreditCardAdministratorSchema, { formatResponse });

export default CreditCardAdministratorsService;
