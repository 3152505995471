<template>
  <ModalCardDefault :title="`Título ${parcel.title}`" @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" :title="'Dados do Título'" :icon="$icons.box"
      readonly>
      <v-form v-model="installmentValidEditForm" ref="installmentValidEditForm" lazy-validation>
        <Row>
          <Col md="4">
            <DataPicker v-model="form.due_date" :placeholder="'Vencimento'"
              :label="'Vencimento'" :rules="[rules.required]" :clearable="!isPaid"
              :readonly="isPaid" />
          </Col>
          <Col md="4">
            <DataPicker v-model="form.paid_date" :placeholder="'Pagamento'"
              :label="'Pagamento'" @clearDate="clearDate('paid_date')"
              :clearable="!isPaid" :readonly="isPaid" />
          </Col>
          <Col md="4">
            <TextFieldMoney v-model="form.value" :placeholder="'Valor'"
              :label="'Valor'" :rules="[rules.money]"
              readonly />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <TextFieldMoney v-model="form.fees" :placeholder="'Juros/Multa'"
              :label="'Juros/Multa'" @change="changeValue('fees')"
              :readonly="isPaid" />
          </Col>
          <Col md="4">
            <TextFieldMoney v-model="form.discount" :placeholder="'Desconto'"
              :label="'Desconto'" @change="changeValue('discount')"
              :readonly="isPaid" />
          </Col>
          <Col md="4">
            <TextFieldMoney v-model="form.total_value" :placeholder="'Valor Total'"
              :label="'Valor Total'" readonly />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button color="secondary" class="mr-3" label="Salvar" rounded
        @click="save()" :disabled="isPaid" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  Button,
  Row,
  Col,
  ExpansionPanel,
  DataPicker,
  TextFieldMoney,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required, biggerZero, money } from '@/utils/rules';
import { formatCurrency } from '@/utils';

export default {
  name: 'InstallmentEdit',
  components: {
    ModalCardDefault,
    Button,
    Row,
    Col,
    ExpansionPanel,
    DataPicker,
    TextFieldMoney,
  },
  props: {
    parcel: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      installmentValidEditForm: false,
      rules: {
        required,
        biggerZero,
        money
      },
      utils: {
        formatCurrency
      },
      form: {
        id: '',
        due_date: '',
        paid_date: '',
        value: 0,
        fees: 0,
        discount: 0,
        total_value: 0,
      },
    };
  },
  computed: {
    isPaid () {
      return this.parcel.status === this.$enums.statusAccountParcels.PAID;
    },
  },
  watch: {
    parcel (val) {
      this.mountForm(val);
    },
  },
  methods: {
    clearDate (field) {
      this.form[field] = null;
    },
    save () {
      if (this.$refs.installmentValidEditForm.validate()) {
        this.$emit('save', {
          id: this.form.id,
          due_date: this.form.due_date,
          paid_date: this.form.paid_date,
          value: this.form.value,
          fees: this.form.fees,
          discount: this.form.discount,
          total_value: this.form.total_value,
          payments: [],
        });
      }
    },
    changeValue (type) {
      if (type === 'fees') {
        this.form.discount = 0;
        this.form.total_value = parseFloat(this.form.value) + parseFloat(this.form.fees);
      } else if (type === 'discount') {
        this.form.fees = 0;
        this.form.total_value = parseFloat(this.form.value) - parseFloat(this.form.discount);
      }
    },
    mountForm (data) {
      this.form = {
        id: data.id,
        payment_method_id: data.payment_method_id,
        bank_id: data.bank_id,
        due_date: data.due_date,
        paid_date: data.paid_date,
        value: data.value,
        fees: data.fees,
        discount: data.discount,
        total_value: data.total_value,
      };
    }
  },
  mounted () {
    this.mountForm(this.parcel);
  }
};
</script>
