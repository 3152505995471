<template>
  <div>
    <span class="d-block mt-3"><b>{{ l.title }}</b></span>
    <Row class="border border-dark">
      <Col md="6" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.name" />
        <span class="" v-text="model.client.name" />
      </Col>
      <Col md="4" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.cnpj" />
        <span class="" v-text="document" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.emissionDate" />
        <span class="" v-text="emissionDate" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.address" />
        <span class="" v-text="model.client.street" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.number" />
        <span class="" v-text="model.client.number" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.neighborhood" />
        <span class="" v-text="model.client.neighborhood" />
      </Col>
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.zipCode" />
        <span class="" v-text="zipCode" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.inputOutputHour" />
        <span class="" v-text="model.input_output_emission_hour" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.city" />
        <span class="" v-text="model.client.city" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.phone" />
        <span class="" v-text="phone" />
      </Col>
      <Col md="1" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.uf" />
        <span class="" v-text="model.client.uf" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.ie" />
        <span class="" v-text="model.client.ie" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.inputOutputDate" />
        <span class="" v-text="receiptDate" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.emissionHour" />
        <span class="" v-text="model.input_output_receipt_hour" />
      </Col>
    </Row>
  </div>
</template>

<script>
import { formatDatePT, formatCpfCnpj, formatPhone, formatZipCode } from '@/utils';

import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55Client',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.client;
    },
    emissionDate () {
      return formatDatePT(this.model.emission_date);
    },
    receiptDate () {
      return formatDatePT(this.model.receipt_date);
    },
    document () {
      return formatCpfCnpj(this.model.client.document);
    },
    phone () {
      return formatPhone(this.model.client.phone);
    },
    zipCode () {
      return formatZipCode(this.model.client.zip_code);
    },
  },
};
</script>
