<template>
  <div>
    <PageHeader :schema="$schemas.serviceInvoice" />
    <PageContent>
      ServiceInvoice
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';

export default {
  name: 'CreateServiceInvoice',
  components: { PageHeader, PageContent },
};
</script>
