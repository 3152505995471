import ServiceInvoiceSchema from './schemas/ServiceInvoiceSchema';
import ListServiceInvoices from './pages/ListServiceInvoices';
import CreateServiceInvoice from './pages/CreateServiceInvoice';

export default [
  {
    path: ServiceInvoiceSchema.routes.list.path,
    name: ServiceInvoiceSchema.routes.list.name,
    component: ListServiceInvoices,
    meta: ServiceInvoiceSchema.routes.list.meta,
  },
  {
    path: ServiceInvoiceSchema.routes.create.path,
    name: ServiceInvoiceSchema.routes.create.name,
    component: CreateServiceInvoice,
    meta: ServiceInvoiceSchema.routes.create.meta
  },
  {
    path: ServiceInvoiceSchema.routes.show.path,
    name: ServiceInvoiceSchema.routes.show.name,
    component: CreateServiceInvoice,
    meta: ServiceInvoiceSchema.routes.show.meta
  },
];