<template>
  <div>
    <PageHeader :schema="$schemas.register" :titleCustom="title" :descriptionCustom="description"
      :create-route-name="createRouteName" :create-btn="createBtn" />
    <PageContent>
      <DynamicListPage :schema="$schemas.register" :service="$api.registers" fixed-filter
        :fixedFilterParams="fixedFilterParams" :key="keyComponent"
        :slots="slotsItems">
        <template v-slot:document="{ props: { item } }">
          {{ item.person_type === 'J' ? item.cnpj_formatted : item.cpf_formatted }}
        </template>
      </DynamicListPage>
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @update:dialogModal="dialogModal = $event" v-on="modalEvents" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog } from '@/components/vuetify';

const CREATE_BTN = {
  clientCreate: 'Novo Cliente',
  employeeCreate: 'Novo Funcionário',
  providerCreate: 'Novo Fornecedor',
  transporterCreate: 'Nova Transportadora',
};

export default {
  name: 'ListRegisters',
  components: { PageHeader, PageContent, DynamicListPage, Dialog },
  data () {
    return {
      dialogModal: false,
      componentModal: null,
      slotsItems: [{ field: 'document' }],
      modalProps: {
        maxWidth: 600,
        vehicles: []
      },
      modalEvents: {},
      registerSelectedId: '',
      keyComponent: 0,
    };
  },
  computed: {
    createRouteName () {
      return this.$schemas.register.routes[this.$route.meta.type].create.name;
    },
    createBtn () {
      return CREATE_BTN[this.$schemas.register.routes[this.$route.meta.type].create.name];
    },
    fixedFilterParams () {
      return { name: 'type', value: this.$route.meta.type, fixedFilter: true };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    }
  },
  watch: {
    title () {
      this.keyComponent++;
    }
  },
};
</script>
