<template>
  <div>
    <ExpansionPanel v-model="panel" :title="'Desconto da Venda'" :icon="$icons.box" readonly>
      <v-form v-model="validFormFreight" ref="validFormFreight" lazy-validation>
        <Row>
          <Col cols="12" xs="12" sm="12" :md="3" :lg="3">
            <TextFieldMoney v-model="order.total_discount_value" :placeholder="'Valor do Desconto'"
              :label="'Valor do Desconto'" @input="updateInfosOrderSale"
              :readonly="true" />
          </Col>
          <Col cols="12" xs="12" sm="12" :md="9" :lg="9">
            <Button label="Alterar desconto" color="primary" rounded
              @click="openDialog('dialogOrderEditModalModal')" />
            <Button label="Zerar descontos" color="secondary" rounded
              @click="openDialog('dialogConfirmation')" class="ml-2" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <Dialog :dialog="dialogOrderEditModalModal" :max-width="600">
      <OrderDiscountEditModal slot="content" @changeDiscount="changeDiscount"
      @close="dialogOrderEditModalModal = false" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirmation"
      title="Zerar descontos"
      message="Deseja realmente zerar todos os descontos?"
      @noActionDialogConfirmation="dialogConfirmation = false"
      @yesActionDialogConfirmation="resetDiscount" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  ExpansionPanel,
  TextFieldMoney,
  Button,
  Dialog
} from '@/components/vuetify';
import DialogConfirmation from '@/components/DialogConfirmation';
import { required } from '@/utils/rules';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';
import OrderDiscountEditModal from './OrderDiscountEditModal.vue';

export default {
  name: 'OrderFreight',
  components: {
    ExpansionPanel,
    Row,
    Col,
    TextFieldMoney,
    Button,
    OrderDiscountEditModal,
    Dialog,
    DialogConfirmation
  },
  inject: ['propsComponents'],
  mixins: [UpdateInfosOrderSaleMixin],
  data () {
    return {
      panel: [0],
      validFormFreight: false,
      rules: {
        required: required
      },
      dialogOrderEditModalModal: false,
      dialogConfirmation: false,
    };
  },
  computed: {
  },
  methods: {
    openDialog(dialog) {
      this[dialog] = true;
    },
    changeDiscount(discount) {
      const discountTotal = parseFloat(discount);
      let totalUnitNotDiscount = 0;

      this.order.items.forEach(item => {
        if (parseFloat(item.discount_value) === 0) {
          totalUnitNotDiscount += parseFloat(item.quantity);
        }
      });

      const discountForUnit = parseFloat(totalUnitNotDiscount) > 0 ? parseFloat(discountTotal) / parseFloat(totalUnitNotDiscount) : 0;

      this.order.items.forEach(item => {
        if (parseFloat(item.discount_value) === 0) {
          item.discount_value = parseFloat(discountForUnit) * parseFloat(item.quantity);
          item.total_value = parseFloat(item.unit_value) * parseFloat(item.quantity) - parseFloat(item.discount_value);
        } else {
          item.total_value = parseFloat(item.unit_value) * parseFloat(item.quantity) - parseFloat(item.discount_value);
        }
      });

      this.updateInfosOrderSale();
      this.dialogOrderEditModalModal = false;
    },
    resetDiscount() {
      this.order.items.forEach(item => {
        item.discount_value = 0;
        item.total_value = parseFloat(item.unit_value) * parseFloat(item.quantity);
      });
      this.dialogConfirmation = false;
      this.updateInfosOrderSale();
    }
  }
};
</script>
