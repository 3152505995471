<template>
  <div>
    <span class="d-block mt-3"><b>{{ l.title }}</b></span>
    <Row class="border border-dark">
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.icmsBase" />
        <span class="" v-text="icmsBaseCalculate" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.icmsValue" />
        <span class="" v-text="icmsValue" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.icmsSubsBase" />
        <span class="" v-text="icmsStBaseCalculate" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.icmsSubsValue" />
        <span class="" v-text="icmsStValue" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.productsValue" />
        <span class="" v-text="productsValue" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.freightValue" />
        <span class="" v-text="freightValue" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.safeValue" />
        <span class="" v-text="safeValue" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.discount" />
        <span class="" v-text="discountValue" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.otherExpenses" />
        <span class="" v-text="otherExpenses" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.approximatesTaxes" />
        <span class="" v-text="taxApproximatesValue" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.ipiValue" />
        <span class="" v-text="ipiValue" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.nfeTotal" />
        <span class="" v-text="nfValue" />
      </Col>
    </Row>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils';

import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55Taxes',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.taxes;
    },
    icmsBaseCalculate () {
      return formatCurrency(this.model.total.icms_base_calculate, { type: 'decimal' });
    },
    icmsValue () {
      return formatCurrency(this.model.total.icms_value);
    },
    icmsStBaseCalculate () {
      return formatCurrency(this.model.total.icms_st_base_calculate, { type: 'decimal' });
    },
    icmsStValue () {
      return formatCurrency(this.model.total.icms_st_value,);
    },
    productsValue () {
      return formatCurrency(this.model.total.products_value);
    },
    freightValue () {
      return formatCurrency(this.model.total.freight_value);
    },
    safeValue () {
      return formatCurrency(this.model.total.safe_value, { type: 'decimal' });
    },
    discountValue () {
      return formatCurrency(this.model.total.discount_value);
    },
    otherExpenses () {
      return formatCurrency(this.model.total.other_expenses, { type: 'decimal' });
    },
    taxApproximatesValue () {
      return formatCurrency(this.model.total.tax_approximates_value,);
    },
    ipiValue () {
      return formatCurrency(this.model.total.ipi_value);
    },
    nfValue () {
      return formatCurrency(this.model.total.nf_value);
    },
  },
};
</script>
