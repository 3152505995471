<template>
  <div>
    <PageHeader :schema="$schemas.reseller" />
    <PageContent>
      <DynamicListPage :schema="$schemas.reseller" :service="$api.resellers"
        @actionMoreActions="actionMoreActions" />
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import {
  Dialog
} from '@/components/vuetify';
import ListCustomers from './components/ListCustomers';
import CreateCustomer from './components/CreateCustomer';

export default {
  name: 'ListResellers',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog,
  },
  data () {
    return {
      dialogModal: false,
      componentModal: null,
      modalProps: {
      }
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    openModalListCustomers (item) {
      this.dialogModal = true;
      this.componentModal = ListCustomers;
      this.modalProps = {
        resellerSelected: item,
        maxWidth: 900
      };
      console.log(item);
    },
    openModalCreateCustomer (item) {
      this.dialogModal = true;
      this.componentModal = CreateCustomer;
      this.modalProps = {
        resellerSelected: item,
        maxWidth: 900
      };
    },
    closeModal () {
      this.dialogModal = false;
    }
  }
};
</script>
