<template>
  <ModalCardDefault :title="title" @close="$emit('close')">
    <ExpansionPanel v-model="panel" :title="'Lista das Movimentações do Produto'" :icon="$icons.box" readonly>
      <DataTable no-search :items-per-page="5" :items="productVariationStockMovements"
        :headers="headers" :loading="loadingDataTable" :slots="slotsItems"
        @getItems="getProductVariationStockMovements">

        <template v-slot:created_at="{ props: { item: { created_at } } }">
          <span>{{ utils.formatDatePT(created_at) }}</span>
        </template>
        <template v-slot:movement_type="{ props: { item: { movement_type } } }">
          <Chip small :color="getColorChip(movement_type)"
            :label="getMovementType(movement_type)" />
        </template>
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  DataTable,
  ExpansionPanel,
  Chip,
} from '@/components/vuetify';
import { typeStockMovements } from '@/utils/enums';
import { formatDatePT } from '@/utils';

export default {
  name: 'ListProductVariationStockMovements',
  components: {
    DataTable,
    ExpansionPanel,
    ModalCardDefault,
    Chip,
  },
  props: {
    productVariationId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      utils: {
        formatDatePT
      },
      loadingDataTable: false,
      productVariationStockMovements: [],
    };
  },
  computed: {
    headers () {
      return [
        { text: 'Descrição', value: 'description' },
        { text: 'Data', value: 'created_at' },
        { text: 'Tipo', value: 'movement_type' },
        { text: 'Saldo Anterior', value: 'previous_balance_product_variation' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Saldo Atual', value: 'current_balance_product_variation' },
      ];
    },
    slotsItems() {
      return [
        { field: 'created_at' },
        { field: 'movement_type' },
      ];
    },
  },
  watch: {
    productVariationId: {
      handler () {
        this.getProductVariationStockMovements();
      },
      deep: true,
    },
  },
  methods: {
    getProductVariationStockMovements (pagination) {
      this.loadingDataTable = !this.loadingDataTable;
      this.$api.products.getProductVariationStockMovementsByProductVariationId(this.productVariationId, pagination).then((res) => {
        this.productVariationStockMovements = res;
      }).catch(() => {
        this.$noty.error('Erro ao buscar os movimentos de estoque do Produto.');
      }).finally(() => {
        this.loadingDataTable = !this.loadingDataTable;
      });
    },
    getColorChip (movement_type) {
      return movement_type === typeStockMovements.INPUT ? 'success' : 'error';
    },
    getMovementType (movementType) {
      return movementType === this.$enums.typeStockMovements.INPUT ? 'Entrada' : 'Saída';
    },
  },
  mounted () {
    this.getProductVariationStockMovements();
  }
};
</script>
