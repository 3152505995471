<template>
  <div>
    <ModalCardDefault :title="l.title.details + ' - ' + this.box.description" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="l.title.list" :icon="$icons.box" readonly>
        <DataTable no-search no-sync :items="movements"
          :headers="this.schema.movements.list.headers">

          <template v-slot:image="{ props: { item } }">
            <div v-html="item.image"></div>
          </template>

          <template v-slot:actions="{ props }">
            <ButtonIcon color="secondary" class="mr-3" x-small :icon="$icons.view"
              :tooltip="l.tooltip.view"
              @click="openShowBoxMovementItems(props.item, typePage.SHOW)" />
          </template>
        </DataTable>
      </ExpansionPanel>
    </ModalCardDefault>

    <Dialog :dialog="dialogBoxMovementItems" no-title :maxWidth="1250">
      <ListBoxMovementItems slot="content"
        :box-movement="this.boxMovement" :movement-items="boxMovementItemSelected"
        @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import {
  DataTable, ButtonIcon, Dialog, ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { typePage } from '@/utils/enums';
import ListBoxMovementItems from './ListBoxMovementItems';

export default {
  name: 'ListBoxMovements',
  components: {
    ModalCardDefault,
    DataTable,
    ButtonIcon,
    Dialog,
    ExpansionPanel,
    ListBoxMovementItems
  },
  props: {
    movements: {
      type: Array,
      required: true
    },
    box: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      dialogBoxMovementItems: false,
      boxSelected: this.box,
      boxMovement: {},
      boxMovementItemSelected: {},
      typePageSelected: '',
      typePage
    };
  },
  computed: {
    schema () {
      return this.$schemas.box;
    },
    l () {
      return this.$locales.pt.boxes.list.modalMovements;
    }
  },
  methods: {
    async openShowBoxMovementItems(BoxMovementItems, typePage) {
      this.boxMovement = {
        id: BoxMovementItems.id,
        box_id: this.boxSelected.id
      };
      await this.$api.boxes.listMovementItems(this.boxMovement).then((res) => {
        this.boxMovementItemSelected = res.box_movement_items.map((item) => {
          return {
            ...item
          };
        });
        this.typePageSelected = typePage;
        this.dialogBoxMovementItems = true;
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    async listMovementItems() {
      await this.$api.boxes.listMovementItems(this.boxMovement).then((res) => {
        this.boxMovementItemSelected = res.box_movement_items.map((item) => {
          return {
            ...item
          };
        });
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    async closeModal() {
      await this.$emit('listMovements');
      this.dialogBoxMovementItems = false;
    }
  }
};
</script>

<style>

</style>
