import axios from '@/service';

const AuthService = {
  login(payload) {
    return new Promise((resolve, reject) => {
      axios.post('auth/login', payload).then((res) => {
        this.setLocalStorage(res.data);
        resolve(res);
      }).catch((err) => {
        this.clearLocalStorage();
        reject(err);
      });
    });
  },
  register(payload) {
    return new Promise((resolve, reject) => {
      axios.post('auth/register', payload).then((res) => {
        this.login({ email: payload.user.email, password: payload.user.password }).then(() => {
          window.location = process.env.VUE_APP_URL;
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  me() {
    return new Promise((resolve, reject) => {
      axios.post('auth/me').then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  logout() {
    return new Promise((resolve) => {
      this.clearLocalStorage();
      resolve(true);
    });
  },
  setLocalStorage(data) {
    const { token, user } = data;
    localStorage.setItem(`${process.env.VUE_APP_NAME}.token`, token);
    localStorage.setItem(`${process.env.VUE_APP_NAME}.user`, JSON.stringify(user));
    localStorage.setItem(`${process.env.VUE_APP_NAME}.companyIdLogged`, user.company.id);

    const companies = user.customer.companies.map((company) => {
      return {
        id: company.id,
        name: company.name,
      };
    });
    localStorage.setItem(`${process.env.VUE_APP_NAME}.companiesByCustomer`, JSON.stringify(companies));

    if (user.type === 'super') {
      localStorage.setItem(`${process.env.VUE_APP_NAME}.super.token`, token);
      localStorage.setItem(`${process.env.VUE_APP_NAME}.super.user`, JSON.stringify(user));
    }
  },
  clearLocalStorage() {
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.token`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.user`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.companyIdLogged`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.companiesByCustomer`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.companySetting`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.super.token`);
    localStorage.removeItem(`${process.env.VUE_APP_NAME}.super.user`);
  }
};

export default AuthService;
