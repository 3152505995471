import { typePageOptions } from '@/utils';
import { text, users } from '@/utils/icons';
import { required } from '@/utils/rules';
import { swapHorizontal, box } from '@/utils/icons';
import ListProductsByStock from '@/views/Products/Stocks/pages/components/ListStocks/ListProductsByStock';

export default {
  domain: 'stocks',
  domainSingle: 'stock',
  title: 'Estoques',
  description: 'Lista de todos os Estoques do sistema',
  createBtn: 'Novo Estoque',
  showInactive: false,
  routes: {
    list: {
      name: 'stocks',
      path: '/estoques',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'stockCreate',
      path: '/estoques/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'stockShow',
      path: '/estoques/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: true,
    more: {
      has: true,
      items: [
        {
          label: 'Movimentações',
          icon: swapHorizontal,
          action: 'movements'
        },
        {
          icon: box,
          name: 'listProducts',
          label: 'Produtos',
          action: 'listProducts',
          component: ListProductsByStock,
          version: 'v2',
          updateListWhenClosing: false,
        }
      ]
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'text',
        name: 'name',
        label: 'Nome',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'description',
        label: 'Descrição',
        icon: text,
        md: 6,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Estoque',
      icon: users,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'money',
          name: 'total_available',
          formattedName: 'total_available_formatted',
          label: 'Total Disponível',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
          clearable: false,
          prefix: '',
          length: 11,
          precision: 2,
          empty: null,
        },

        {
          type: 'checkBox',
          name: 'main',
          formattedName: 'main_formatted',
          label: 'Principal',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false
        },
      ],
    },
  ],
  footer: [],
  list: {
    movements: {
      headers: [
        {
          text: 'Descrição',
          value: 'description',
        },
        {
          text: 'Data',
          value: 'movement_date_formatted',
        },
        {
          text: 'Tipo',
          value: 'movement_type',
        },
        {
          text: 'Saldo Anterior.',
          value: 'previous_balance_stock_formatted',
        },
        {
          text: 'Qtde',
          value: 'quantity_formatted',
        },
        {
          text: 'Saldo Atual.',
          value: 'current_balance_stock_formatted',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ],
    }
  }
};
