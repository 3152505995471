import { typePageOptions } from '@/utils';
import { text, check, close, list } from '@/utils/icons';
import { required } from '@/utils/rules';
import TableBusiness from '@/business/TableBusiness';

export default {
  domain: 'tables',
  domainSingle: 'table',
  business: TableBusiness,
  title: 'Mesas',
  description: 'Lista de todas as Mesas do sistema',
  createBtn: 'Nova Mesa',
  showInactive: false,
  routes: {
    list: {
      name: 'tables',
      path: '/mesas',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'tableCreate',
      path: '/mesas/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'tableShow',
      path: '/mesas/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    eventBus: true,
    more: {
      has: true,
      items: [
        {
          icon: check,
          name: 'openTable',
          label: 'Abrir Mesa',
          action: 'openClosedTable',
          iconColor: 'success',
        },
        {
          icon: list,
          name: 'detailsTable',
          label: 'Detalhes',
          action: 'detailsTable',
          iconColor: 'secondary',
        },
        {
          icon: close,
          name: 'closeTable',
          label: 'Fechar Mesa',
          action: 'openClosedTable',
          iconColor: 'primary',
        }
      ]
    },
  },
  filters: {
    has: false
  },
  fields: [
    {
      title: 'Dados da Mesa',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'client',
          formattedName: 'client',
          label: 'Cliente',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true
        },
        {
          type: 'text',
          name: 'employee',
          formattedName: 'employee',
          label: 'Garçom',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true
        },
        {
          type: 'text',
          name: 'total_value',
          formattedName: 'total_value_formatted',
          label: 'Valor Total',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true
        },
      ],
    },
  ],
  footer: [],
  list: {}
};
