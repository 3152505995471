import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { filter } from '../../utils/filter';

export const getAll = (server) => {
  server.get('/stock-movements', (schema, request) => {
    const stockMovements = schema.stockMovements.all();
    const { page, perPage } = request.queryParams;

    stockMovements.models = filter(request.queryParams, stockMovements);
    stockMovements.models = softDelete(stockMovements);

    return pagination('stockMovements', stockMovements, page, perPage);
  });
};