import { typePageOptions } from '@/utils';
import { accountTie, users } from '@/utils/icons';

export default {
  domain: 'company_settings',
  domainSingle: 'company_setting',
  title: 'Configurações da Empresa',
  description: 'Lista de todas as configurações da empresa',
  createBtn: false,
  showExpand: true,
  routes: {
    list: {
      name: 'settings-company-settings',
      path: '/configuracoes/empresa-configuracoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true, }
    },
    show: {
      name: 'settings-company-setting-show',
      path: '/configuracoes/empresa-configuracoes/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: true,
    more: {
      has: false,
      items: [
        {
          name: 'lastOrderSaleNumber',
          label: 'Ultimo Nº da Venda',
          icon: users,
          action: 'lastOrderSaleNumber',
        },
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Movimentações',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'is_order_number_sequential',
          label: 'Sequencial',
          list: true,
        },
        {
          type: 'text',
          name: 'last_order_sale_number',
          label: 'Ultimo Nº da Venda',
          list: true,
        },
        {
          type: 'text',
          name: 'last_order_purchase_number',
          label: 'Ultimo Nº da Compra',
          list: true,
        },
        {
          type: 'text',
          name: 'last_order_sale_pdv_number',
          label: 'Ultimo Nº da Venda PDV',
          list: true,
          md: '3',
        },
        {
          type: 'text',
          name: 'last_order_purchase_devolution_number',
          label: 'Ultimo Nº da Compra Devolução',
          list: true,
          md: '3',
        },
        {
          type: 'text',
          name: 'last_order_budget_number',
          label: 'Ultimo Nº do Orçamento',
          list: false,
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'last_order_sale_devolution_number',
          label: 'Ultimo Nº da Venda Devolução',
          list: false,
        },
        {
          type: 'text',
          name: 'last_order_sale_consigned_number',
          label: 'Ultimo Nº da Venda Consignada',
          list: false,
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'last_order_sale_table_number',
          label: 'Ultimo Nº da Venda Mesa',
          list: false,
          md: '3',
          contentExpanded: true,
        },
      ],
    },
  ],
  footer: []
};
