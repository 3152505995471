import { baseFields } from '../utils//baseFields';

export default (server) => {
  server.create('allType', {
    name: 'Dinheiro',
    description: 'Pagamento em Dinheiro',
    type: 'paymentMethod',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'Cartão de Crédito',
    description: 'Pagamento com Cartão de Crédito',
    type: 'paymentMethod',
    value: null,
    ...baseFields,
    situation: 'inactive',
    situation_formatted: 'Inativo',
  });
  server.create('allType', {
    name: 'PIX',
    description: 'Pagamento em PIX',
    type: 'paymentMethod',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'UN',
    description: 'Unidade',
    type: 'unitMeasurement',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'KG',
    description: 'Kilograma',
    type: 'unitMeasurement',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'Visa Crédito',
    description: 'Cartão de Crédito Visa',
    type: 'flag',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'MasterCard',
    description: 'Cartão de Crédito MasterCard',
    type: 'flag',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'Vestidos',
    description: 'Vestidos Feminino',
    type: 'category',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'Shorts',
    description: 'Shorts Feminino',
    type: 'category',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'Florais',
    description: 'Estampado Florais',
    type: 'subCategory',
    value: null,
    ...baseFields,
  });
  server.create('allType', {
    name: 'Natureza',
    description: 'Estampado Natureza',
    type: 'subCategory',
    value: null,
    ...baseFields,
  });
};
