const zipCode = '##.###-###';
const cell_phone = '(##)#####-####';
const hour = '##:##';
const date = '##/##/####';
const cpf = '###.###.###-##';
const cnpj = '##.###.###/####-##';

export {
  zipCode,
  cell_phone,
  hour,
  date,
  cpf,
  cnpj,
};
