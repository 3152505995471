<template>
  <div>
    <PageHeader :schema="$schemas.allType" :create-route-name="createRouteName"
      :create-btn="createBtn" :titleCustom="title" :descriptionCustom="description" />
    <PageContent>
      <DynamicListPage :schema="$schemas.allType" :service="$api.allTypes" fixed-filter
        :fixedFilterParams="fixedFilterParams" :slots="slotsItems"
        :key="keyComponent">
        <template v-slot:value="{ props: { item } }">
          <span v-if="displayValue(item.type)">{{ item.value }}</span>
        </template>
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { kebabCase } from 'lodash';

const CREATE_BTN = {
  unitMeasurementCreate: 'Nova Unidade de Medida',
  flagCreate: 'Nova Bandeira',
  categoryCreate: 'Nova Categoria',
  subCategoryCreate: 'Nova Sub Categoria',
  ncmCreate: 'Novo NCM',
  cestCreate: 'Novo CEST',
};

export default {
  name: 'ListAllTypes',
  components: { PageHeader, PageContent, DynamicListPage },
  data () {
    return {
      slotsItems: [{ field: 'value' }],
      keyComponent: 0,
    };
  },
  computed: {
    createRouteName () {
      return this.$schemas.allType.routes[this.$route.meta.type].create.name;
    },
    createBtn () {
      return CREATE_BTN[this.$schemas.allType.routes[this.$route.meta.type].create.name];
    },
    fixedFilterParams () {
      return { name: 'type', value: kebabCase(this.$route.meta.type), fixedFilter: true };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    },
  },
  watch: {
    title () {
      this.keyComponent++;
    }
  },
  methods: {
    displayValue (type) {
      return [
        this.$enums.typeAllTypes.NCM,
        this.$enums.typeAllTypes.CEST,
        this.$enums.typeAllTypes.UNIT_MEASURE,
      ].includes(type);
    }
  }
};
</script>
