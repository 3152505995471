<template>
  <div>
    <PageHeader :schema="$schemas.account" :create-route-name="createRouteName"
      :titleCustom="title" :descriptionCustom="description" :create-btn="createBtn" />
    <PageContent>
      <DynamicListPage :schema="$schemas.account" :service="$api.accounts" fixed-filter
        :fixedFilterParams="fixedFilterParams" :slots="slotsItems"
        :key="keyComponent">
        <template v-slot:difference="{ props: { item } }">
          <span :class="[{'primary--text': item.difference < 0}]">{{ getDifference(item.difference) }}</span>
        </template>
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { formatCurrency } from '@/utils';
import { kebabCase } from 'lodash';

const CREATE_BTN = {
  accountPaymentCreate: 'Novo Pagamento',
  accountReceivableCreate: 'Novo Recebimento',
};

export default {
  name: 'ListAccounts',
  components: { PageHeader, PageContent, DynamicListPage },
  data () {
    return {
      slotsItems: [{ field: 'difference' }],
      keyComponent: 0,
    };
  },
  computed: {
    createRouteName () {
      return this.$schemas.account.routes[this.$route.meta.type].create.name;
    },
    createBtn () {
      return CREATE_BTN[this.$schemas.account.routes[this.$route.meta.type].create.name];
    },
    fixedFilterParams () {
      return { name: 'type', value: kebabCase(this.$route.meta.type), fixedFilter: true };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    }
  },
  watch: {
    title () {
      this.keyComponent++;
    }
  },
  methods: {
    getDifference (difference) {
      return formatCurrency(difference);
    }
  }
};
</script>
