export default {
  title: 'Veículos',
  actions: {
    create: 'Novo',
    close: 'Fechar'
  },
  tooltips: {
    remove: 'Excluir Veículo'
  },
  noty: {
    errorList: 'Erro ao listar os Veículos',
    errorCreate: 'Erro ao criar o Veículo',
    errorDelete: 'Erro ao excluir o Veículo',
    successCreate: 'Veículo criado com sucesso',
    successDelete: 'Veículo excluído com sucesso',
  }
};
