<template>
  <Card toolbar color="grey lighten-2" style="background: #F6F7FB">
    <div slot="toolbar"><h2 v-html="title" /></div>

    <div class="content-main-modal-card-default">
      <slot />
    </div>

    <div slot="actions" class="d-flex justify-end px-5" style="width: 100%">
      <slot name="actions" />
      <Button :label="actions.close" rounded color="primary" @click="$emit('close')"
        v-if="displayNoButton" />
    </div>
  </Card>
</template>

<script>
import {
  Card,
  Button
} from '@/components/vuetify';

export default {
  name: 'ModalCardDefault',
  components: {
    Card,
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    actions: {
      type: Object,
      default: () => {
        return {
          close: 'Fechar',
        };
      },
    },
    displayNoButton: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  actionListener(event) {
    if (event.key === 'Esc') {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    background-color: #ee9ea9;
  }

::-webkit-scrollbar-thumb {
  background-color: #FF3D57;
  border-radius: 20px;
}

.content-main-modal-card-default {
  padding: 3px 10px;
  max-height: 600px !important;
  overflow: auto;
  overflow-x: hidden;
}
</style>
