export default {
  modalMovements: {
    title: 'Movimentações do Estoque: $stock',
    tooltips: {
      viewMovement: 'Ver Movimentação',
      origin: 'Abrir Origem',
    },
    actions: {
      close: 'Fechar',
    },
    noty: {
      successCreateStockMovement: 'Movimentação Realizada com Sucesso',
      errorCreateStockMovement: 'Erro ao Fazer a Movimentação',
    },
    movementType: {
      input: 'Entrada',
      output: 'Saída'
    },
    createStockMovement: {
      description: 'Operação Realizada Manualmente'
    },
    origin: {
      stockMovement: 'Manual',
      orderSale: 'Pedido de Vendas',
      orderPurchase: 'Pedido de Compras',
    },
    modalListHeader: {
      title: 'Entrada e Saída de Produto',
      form: {
        fields: {
          stock: {
            label: 'Estoque',
          },
          movementType: {
            label: 'Tipo de Movimentação',
          },
          barCode: {
            label: 'Cod. Barras',
          },
          productVariation: {
            label: 'Selecione o Produto',
          },
          quantity: {
            label: 'quantidade',
          },
        }
      },
      actions: {
        save: 'Salvar',
        close: 'Fechar',
      },
    }
  },
};