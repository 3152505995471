import { dateTime } from '../../utils/dateTime';
import { Response } from 'miragejs';

export const destroy = (server) => {
  server.delete('/order_services/:id', (schema, request) => {
    const { id } = request.params;
    const orderService = schema.order_sales.find(id);

    if (!orderService) {
      return new Response(404, {}, { errors: {} });
    }
    orderService.update({ updated_at: dateTime, deleted_at: dateTime });
  });
};