import { typePageOptions } from '@/utils';
import { text, swapHorizontal } from '@/utils/icons';
import { required, money } from '@/utils/rules';
import { pencilBoxMultipleOutline, box } from '@/utils/icons';
import { typeHasVariation } from '@/utils/options';
import ProductBusiness from '@/business/ProductBusiness';

export default {
  domain: 'products',
  domainSingle: 'product',
  business: ProductBusiness,
  title: 'Produtos',
  description: 'Lista de todos os Produtos do sistema',
  createBtn: 'Novo Produto',
  showInactive: false,
  routes: {
    list: {
      name: 'products',
      path: '/produtos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'productCreate',
      path: '/produtos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'productShow',
      path: '/produtos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Variações',
          icon: pencilBoxMultipleOutline,
          name: 'listVariations',
          action: 'listVariations',
        },
        {
          label: 'Estoques',
          icon: box,
          name: 'listVariationStocks',
          action: 'listVariationStocks',
        },
        {
          label: 'Movimentações',
          icon: swapHorizontal,
          name: 'stockMovements',
          action: 'stockMovements',
        },
        {
          label: 'Movimentar Estoque',
          icon: swapHorizontal,
          name: 'stockMove',
          action: 'stockMove',
        }
      ]
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'select',
        name: 'category_id',
        label: 'Categoria',
        icon: text,
        md: 6,
        itemText: 'name',
        itemValue: 'id',
        domain: 'all_types',
        verb: 'get',
        endpoint: 'filters?domain=all_type&filter[type]=category&page=1&per_page=-1',
      },
      {
        type: 'select',
        name: 'sub_category_id',
        label: 'Sub Categoria',
        icon: text,
        md: 6,
        itemText: 'name',
        itemValue: 'id',
        domain: 'all_types',
        verb: 'get',
        endpoint: 'filters?domain=all_type&filter[type]=sub-category&page=1&per_page=-1',
      },
      {
        type: 'text',
        name: 'reference',
        label: 'Referência',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'bar_code',
        label: 'Cod. Barras',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'code',
        label: 'Código',
        icon: text,
        md: 6,
      },
      {
        type: 'select',
        name: 'productVariations$$productVariationStocks$$stock_id',
        label: 'Estoque',
        icon: text,
        md: 6,
        itemText: 'name',
        itemValue: 'id',
        domain: 'stocks',
        verb: 'get',
        endpoint: 'filters?domain=stock&page=1&per_page=-1',
      },
      {
        type: 'text',
        name: 'name',
        label: 'Produto',
        icon: text,
        md: 12,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Produto',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'code',
          formattedName: 'code',
          label: 'Código',
          align: '',
          md: '2',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '6',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'unit_measure_id',
          formattedName: 'unit_measure.name',
          label: 'Unidade Medida',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=unit-measurement&page=1&per_page=-1',
          },
          newItem: {
            has: false,
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          name: 'employer_id',
          formattedName: 'employer.name',
          label: 'Fornecedor',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          clearable: true,
          items: [],
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'registers',
            endpoint: 'filters',
            queryParams: 'domain=register&filter[type]=provider&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          name: 'category_id',
          formattedName: 'category.name',
          label: 'Categoria',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          clearable: true,
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=category&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          name: 'sub_category_id',
          formattedName: 'sub_category.name',
          label: 'Sub Categoria',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          clearable: true,
          items: [],
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=sub-category&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'text',
          name: 'reference',
          formattedName: 'reference',
          label: 'Referência',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'bar_code',
          formattedName: 'bar_code',
          label: 'Cód. Barras',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'money',
          name: 'price',
          formattedName: 'price_formatted',
          label: 'Preço Varejo',
          align: '',
          md: '3',
          rules: [required, money],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'price_wholesale',
          formattedName: 'price_wholesale_formatted',
          label: 'Preço Atacado',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'cost',
          formattedName: 'cost_formatted',
          label: 'Preço de Custo',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'liquid_weight',
          formattedName: 'liquid_weight_formatted',
          label: 'Peso Líquido (gramas)',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: '',
          length: 11,
          precision: 0,
          empty: null,
        },
        {
          type: 'money',
          name: 'gross_weight',
          formattedName: 'gross_weight_formatted',
          label: 'Peso Bruto (gramas)',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: '',
          length: 11,
          precision: 0,
          empty: null,
        },
        {
          type: 'select',
          name: 'ncm_id',
          formattedName: 'ncm.name',
          label: 'NCM',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=ncm&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          name: 'cest_id',
          formattedName: 'cest.name',
          label: 'CEST',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          clearable: true,
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'all_types',
            endpoint: 'filters',
            queryParams: 'domain=all_type&filter[type]=cest&page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          name: 'has_variation',
          formattedName: 'has_variation_formatted',
          label: 'Tipo Variação',
          align: '',
          md: '2',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: typeHasVariation,
          default: false,
          itemValueDefault: true,
        },
        {
          type: 'select',
          name: 'price_list_ids',
          formattedName: 'price_list_ids',
          label: 'Lista de Preço',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          clearable: true,
          multiple: true,
          items: [],
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'price_lists',
            endpoint: 'price_lists',
            queryParams: 'page=1&per_page=-1',
          },
          itemText: 'name',
          itemValue: 'id'
        },
      ],
    },
  ],
  footer: [
    // {
    //   name: 'total_price',
    //   label: 'Total Valor Varejo',
    //   color: 'primary',
    //   md: 3,
    // },
    // {
    //   name: 'total_price_wholesale',
    //   label: 'Total Valor Atacado',
    //   color: 'primary',
    //   md: 3,
    // },
    // {
    //   name: 'qtd_unique_products',
    //   label: 'Qtd. Prod. Únicos',
    //   color: 'primary',
    //   md: 3,
    // },
    // {
    //   name: 'qtd_variations',
    //   label: 'Qtd. Prod. Variações',
    //   color: 'primary',
    //   md: 3,
    // },
    // {
    //   name: 'qtd_volumes_unique_products',
    //   label: 'Qtd. Vol. Prod. Únicos',
    //   color: 'primary',
    //   md: 3,
    // },
    // {
    //   name: 'qtd_volumes_variations',
    //   label: 'Qtd. Vol. Prod. Variações',
    //   color: 'primary',
    //   md: 3,
    // },
  ],
  variations: {
    list: {
      headers: [
        {
          text: 'Código',
          value: 'code',
        },
        {
          text: 'Cod. Barras',
          value: 'bar_code',
        },
        {
          text: 'Variação',
          value: 'name',
        },
        {
          text: 'Preço Varejo',
          value: 'price_formatted',
        },
        {
          text: 'Preço Atacado',
          value: 'price_wholesale_formatted',
        },
        {
          text: 'Qtde.',
          value: 'quantity_formatted',
        },
        {
          text: 'Ações.',
          value: 'actions',
          align: 'end',
        },
      ],
      headersVariationValues:  [
        {
          text: 'Variação',
          value: 'variation_name',
        },
        {
          text: 'Valor da Variação',
          value: 'variation_value_name',
        },
      ],
      headersVariationStocks:  [
        {
          text: 'Estoque',
          value: 'stock_name',
        },
        {
          text: 'Quantidade',
          value: 'quantity',
        },
      ],
      headersViewProductVariations:  [
        {
          text: 'Estoque',
          value: 'description',
        },
        {
          text: 'Quantidade',
          value: 'quantity',
        },
      ]
    }
  },
  list: {
    movements: {
      headers: [
        {
          text: 'Origem',
          value: 'origin.label',
        },
        {
          text: 'Data',
          value: 'movement_date_formatted',
        },
        {
          text: 'Estoque',
          value: 'stock.name',
        },
        {
          text: 'Tipo',
          value: 'movement_type',
        },
        {
          text: 'Saldo Anterior.',
          value: 'previous_balance_product_variation_formatted',
        },
        {
          text: 'Qtde',
          value: 'quantity_formatted',
        },
        {
          text: 'Saldo Atual.',
          value: 'current_balance_product_variation_formatted',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ],
    }
  }
};
