<template>
  <ModalCardDefault :title="l.title" @close="$emit('closeModal')">
    <ExpansionPanel v-model="panel" :title="l.form.title"
      :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation>
      <Row>
        <Col md="12">
          <TextField v-model="form.nf_number" :label="l.form.number"
            :placeholder="l.form.number" :rules="[rules.required]" />
        </Col>
      </Row>
      </v-form>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button :label="l.actions.confirm" color="secondary" rounded class="mr-3"
        @click="changeNumberNFe" :loading="loading" :icon="$icons.download" />
    </template>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required } from '@/utils/rules';

export default {
  name: 'ChangeNumberNFe',
  components: {
    ExpansionPanel,
    Row,
    Col,
    TextField,
    Button,
    ModalCardDefault,
  },
  props: {
    productInvoiceSelected: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      validForm: false,
      loading: false,
      rules: {
        required,
      },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.list.changeNumberNFe;
    },
    form () {
      return {
        nf_number: this.productInvoiceSelected.nf_number,
      };
    }
  },
  methods: {
    async changeNumberNFe () {
      if (!this.$refs.validForm.validate()) {
        return false;
      }
      this.loading = true;
      this.$api.productInvoices.changeNumberNFe(this.productInvoiceSelected.id, this.form).then(() => {
        this.$noty.success(this.l.noty.success);
      }).catch(() => {
        this.$noty.error(this.l.noty.error);
      }).finally(() => {
        this.loading = false;
        this.$emit('closeModal');
      });
    },
  },
};
</script>
