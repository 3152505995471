export default {
  title: 'Informações da Transportadora',
  form: {
    register: {
      label: 'Selecione a Transportadora',
      placeholder: 'Selecione o Frete',
    },
    specie: {
      label: 'Espécie',
      placeholder: 'Espécie',
    },
    brand: {
      label: 'Marca',
      placeholder: 'Marca',
    },
    cte: {
      label: 'N° Conhecimento',
      placeholder: 'N° Conhecimento',
    },
    quantity: {
      label: 'Quantidade',
      placeholder: 'Quantidade',
    },
    liquid_weight: {
      label: 'Peso Líquido (gramas)',
      placeholder: 'Peso Líquido (gramas)',
    },
    gross_weight: {
      label: 'Peso Bruto  (gramas)',
      placeholder: 'Peso Bruto  (gramas)',
    },
    plate: {
      label: 'Placa',
      placeholder: 'Placa',
    },
    uf: {
      label: 'UF',
      placeholder: 'UF',
    },
    rntc: {
      label: 'RNTC',
      placeholder: 'RNTC',
    }
  },
};
