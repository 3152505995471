import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { filter } from '../../utils/filter';

export const getAll = (server) => {
  server.get('/order_purchases', (schema, request) => {
    const orderPurchases = schema.orderPurchases.all();
    const { page, perPage } = request.queryParams;
    orderPurchases.models = filter(request.queryParams, orderPurchases);
    orderPurchases.models = softDelete(orderPurchases);

    return pagination('order_purchases', orderPurchases, page, perPage);
  });
};