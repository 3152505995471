<template>
  <div>
    <ModalCardDefault :title="'Nova Assinatura'" @close="$emit('close')">
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <ExpansionPanel v-model="panel" :title="`Plano: ${planSelected.name}`"
          :icon="$icons.box" readonly>
          <Row class="">
            <Col>
              <p>{{ planSelected.description }}</p>
            </Col>
          </Row>
        </ExpansionPanel>

        <ExpansionPanel v-model="panel" :title="`Informações de Pagamento`"
          :icon="$icons.box" readonly class="mt-5">
          <Row class="">
            <Col>
              <Select v-model="form.payment_method" label="Forma de Pagamento"
                :items="paymentMethods" :rules="[rules.required]" />
            </Col>
            <Col>
              <TextField v-model="form.number" label="Nº Cartão" :rules="[rules.required]" />
            </Col>
          </Row>
          <Row class="">
            <Col>
              <TextField v-model="form.exp_month" label="Mês" placeholder="12" :rules="[rules.required]"
                :counter="2" :maxLength="2" />
            </Col>
            <Col>
              <TextField v-model="form.exp_year" label="Ano" placeholder="2023"
                :counter="4" :maxLength="4" :rules="[rules.required]" />
            </Col>
            <Col>
              <Select v-model="form.brand" label="Bandeiras"
                placeholder="Visa" :items="flags" :rules="[rules.required]" />
            </Col>
            <Col>
              <TextField v-model="form.cvv" label="CVV" placeholder="123"
                :counter="3" :maxLength="3" :rules="[rules.required]" />
            </Col>
          </Row>
        </ExpansionPanel>

        <ExpansionPanel v-model="panel" :title="`Informações de Cliente`"
          :icon="$icons.box" readonly class="mt-5">
          <Row class="">
            <Col>
              <TextField v-model="form.customer.name" label="Nome Completo" placeholder="Nome Completo"
                :rules="[rules.required, rules.fullName]" />
            </Col>
            <Col>
              <TextFieldSimpleMask v-model="form.customer.document" label="CPF" placeholder="CPF"
                :rules="[rules.required]" :inputMask="'###.###.###-##'" :outputMask="'###########'" />
            </Col>
            <Col>
              <TextFieldSimpleMask v-model="form.customer.cell_phone" label="Telefone" placeholder="Telefone"
                :rules="[rules.required]" :inputMask="'(##)#####-####'" :outputMask="'###########'" />
            </Col>
          </Row>
        </ExpansionPanel>
      </v-form>

      <div slot="actions" class="d-flex justify-end" style="width: 100%">
        <Button :label="'Confirmar'" class="mr-3" rounded color="secondary"
          :loading="loadingForm" @click="createSubscription()" />
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Button,
  ExpansionPanel,
  Row,
  Col,
  TextField,
  Select,
  TextFieldSimpleMask,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { getCustomer } from '@/utils';
import { required, fullName } from '@/utils/rules';

export default {
  name: 'CreateSubscription',
  components: {
    Button,
    ExpansionPanel,
    Row,
    Col,
    ModalCardDefault,
    TextField,
    Select,
    TextFieldSimpleMask,
  },
  props: {
    planSelected: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      validForm: true,
      rules: {
        required,
        fullName
      },
      form: {
        plan_id: this.planSelected.id,
        payment_method: '',
        number: '',
        exp_month: '',
        exp_year: '',
        brand: '',
        cvv: '',
        customer: {
          name: '',
          document: '',
          cell_phone: ''
        }
      },
      loadingForm: false
    };
  },
  computed: {
    paymentMethods () {
      return [
        { text: 'Cartão de Crédito', value: 'credit_card' },
        { text: 'Boleto', value: 'billed' },
      ];
    },
    flags () {
      return [
        { text: 'Elo', value: 'Elo' },
        { text: 'Mastercard', value: 'Mastercard' },
        { text: 'Visa', value: 'Visa' },
        { text: 'Amex', value: 'Amex' },
        { text: 'Hipercard', value: 'Hipercard' },
      ];
    }
  },
  methods: {
    async createSubscription () {
      if (this.$refs.validForm.validate()) {
        this.loadingForm = true;
        this.$api.subscriptions.create(this.form).then(() => {
          this.$noty.success('Sucesso ao fazer a assinatura');
          this.loadingForm = false;
          this.$emit('close');
        }).catch((err) => {
          this.loadingForm = false;
          this.$noty.error(err);
        });
      }
    }
  },
  mounted () {
    this.form.customer = getCustomer();
  }
};
</script>

<style>

</style>
