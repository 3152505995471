export default {
  title: 'Informações do Título',
  form: {
    information: {
      title: 'Informações do Título',
      fields: {
        client: {
          label: 'Cliente',
          placeholder: 'Cliente',
        },
        title: {
          label: 'Título',
          placeholder: 'Título',
        },
        value: {
          label: 'Valor do Título',
          placeholder: 'Valor do Título',
        },
        difference: {
          label: 'Desconto/Acréscimo',
          placeholder: 'Desconto/Acréscimo',
        },
        totalValue: {
          label: 'Valor Total do Título',
          placeholder: 'Valor Total do Título',
        },
        emissionDate: {
          label: 'Data de Emissão',
          placeholder: 'Data de Emissão',
        },
        typeInstallment: {
          label: 'Tipo de Parcelamento',
          placeholder: 'Tipo de Parcelamento',
        },
        accountingAccount: {
          label: 'Conta Contábel',
          placeholder: 'Conta Contábel',
        },
        costCenter: {
          label: 'Centro de Custo',
          placeholder: 'Centro de Custo',
        },
      }
    },
    installments: {
      title: 'Parcelas',
      list: {
        status: {
          pending: 'Aberta',
          paid: 'Paga',
          delayed: 'Atrasada'
        }
      },
      modalConfirmation: {
        message: {
          paid: 'Esta ação irá quitar a parcela.',
          pending: 'Esta ação irá estornar o pagamento da parcela.',
        }
      },
      noty: {
        paidParcelSuccess: 'Parcela atualizada com sucesso'
      }
    },
    totalizers: {
      title: 'Totalizadores',
      totals: {
        value: 'Valor',
        difference: 'Diferença',
        totalValue: 'Valor Total',
        totalPaid: 'Valor Pago',
        balance: 'Saldo',
      }
    },
    actions: {
      save: 'Salvar',
      cancel: 'Cancelar',
      duplicate: 'Duplicar',
      back: 'Voltar',
    },
  },
  noty: {
    validationUpdateParcels: 'Preencha todas as informações para quitar uma parcela.',
  }
};
