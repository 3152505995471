<template>
  <ModalCardDefault :title="l.title" @close="$emit('close', false)">
    <ExpansionPanel v-model="panel" :title="'Formulário'" :icon="$icons.box" readonly>
      <FormVariationValue :variations="variations" :variation-values="variationValues"
        @insertVariation="insertVariation" />
      <ListVariationValues :variation-values-items="variationValuesItems" />
      <FormProductVariationDetails ref="FormProductVariationDetails"
        :product-variation="productVariation" :variation-stock-created="variationStockCreated"
        :stocks="stocks" :price-lists="priceLists" @insertStock="insertStock"
        :code-is-readonly="codeIsReadonly" @setPriceList="setPriceList" />
      <ListProductVariationStocks :variation-stocks-items="variationStocksItems" />
    </ExpansionPanel>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button :label="l.actions.create" class="mr-3"
        rounded color="secondary" @click="save" :loading="loading" />
    </div>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Button,
} from '@/components/vuetify';
import FormVariationValue from './CreateVariation/FormVariationValue';
import ListVariationValues from './CreateVariation/ListVariationValues';
import FormProductVariationDetails from './CreateVariation/FormProductVariationDetails';
import ListProductVariationStocks from './CreateVariation/ListProductVariationStocks';

export default {
  name: 'CreateVariation',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Button,
    FormVariationValue,
    ListVariationValues,
    FormProductVariationDetails,
    ListProductVariationStocks,
  },
  props: {
    product: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      loading: false,
      companySetting: {},
      codeIsReadonly: false,
      variations: [],
      stocks: [],
      priceLists: [],
      variationValues: [],
      variationValuesItems: [],
      variationStocksItems: [],
      productVariation: {
        product_id: this.product.id,
        code: this.product.code,
        name: this.product.name,
        bar_code: '',
        price: this.product.price,
        price_wholesale: this.product.price_wholesale,
        liquid_weight: this.product.liquid_weight,
        gross_weight: this.product.gross_weight,
      },
      variationValueCreated: {
        variation: null,
        variationValue: null,
      },
      variationStockCreated: {
        stock: null,
        quantity: 1,
      },
      priceListSelected: null,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.products.list.modalVariations.createVariation;
    },
  },
  methods: {
    async getCompanySettings () {
      this.$api.companySettings.index().then((res) => {
        this.companySetting = res.data.company_settings[0];

        if (this.companySetting.is_product_code_sequential) {
          this.productVariation.code = parseInt(this.companySetting.last_product_code) + 1;
          this.codeIsReadonly = true;
        }
      }).catch(() => {
        this.$noty.error('Erro ao buscar configurações da empresa.');
      });
    },
    getVariations () {
      this.$api.variations.index().then((res) => {
        this.variations = res.data.variations;
      }).catch(() => {
        this.$noty.error(this.l.noty.errorGetVariations);
      });
    },
    getStocks () {
      this.$api.stocks.index().then((res) => {
        this.stocks = res.data.stocks;
      }).catch(() => {
        this.$noty.error(this.l.noty.errorGetStocks);
      });
    },
    getPriceLists () {
      this.$api.priceLists.index().then((res) => {
        this.priceLists = res.data.price_lists;
      }).catch(() => {
        this.$noty.error(this.l.noty.errorGetStocks);
      });
    },
    insertVariation (variationValueCreated) {
      this.variationValueCreated = variationValueCreated;
      const variationValueAlreadyExists = this.variationValuesItems.filter((item) => {
        return item.variation_name === this.variationValueCreated.variation.description;
      });

      if (variationValueAlreadyExists.length) {
        this.$noty.error(this.l.noty.variationValueAlreadyExists.replace('$variation', variationValueAlreadyExists[0].variation_name));

        return false;
      }

      this.variationValuesItems.push({
        variation_id: this.variationValueCreated.variation.id,
        variation_name: this.variationValueCreated.variation.description,
        variation_value_id: this.variationValueCreated.variationValue.id,
        variation_value_name: this.variationValueCreated.variationValue.description,
      });
    },
    insertStock (variationStockCreated) {
      this.variationStockCreated = variationStockCreated;

      const variationStockAlreadyExists = this.variationStocksItems.filter((item) => {
        return item.stock_name === this.variationStockCreated.stock.name;
      });

      if (variationStockAlreadyExists.length) {
        this.$noty.error(this.l.noty.variationStockAlreadyExists.replace('$stock', variationStockAlreadyExists[0].stock_name));

        return false;
      }

      this.variationStocksItems.push({
        stock_id: this.variationStockCreated.stock.id,
        stock_name: this.variationStockCreated.stock.name,
        quantity: this.variationStockCreated.quantity,
      });
    },
    setPriceList (data) {
      this.priceListSelected = data;
    },
    save () {
      if (!this.$refs.FormProductVariationDetails.$refs.validForm.validate()) {
        return false;
      }

      if (this.variationValuesItems.length === 0) {
        this.$noty.error(this.l.noty.errorVariationNotEmpty);

        return false;
      }

      if (this.variationStocksItems.length === 0) {
        this.$noty.error(this.l.noty.errorStockNotEmpty);

        return false;
      }

      this.productVariation = {
        ...this.productVariation,
        variations: this.variationValuesItems,
        stocks: this.variationStocksItems,
      };

      this.loading = true;
      this.$api.products.createProductVariation({ ...this.productVariation, price_list:  this.priceListSelected}).then(() => {
        this.$noty.success(this.l.noty.successCreateVariation);
        this.$emit('getProductVariations');
        this.$emit('close', false);
      }).catch(() => {
        this.$noty.error(this.l.noty.errorCreateVariation);
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  async mounted() {
    await this.getCompanySettings();
    await this.getVariations();
    await this.getStocks();
    await this.getPriceLists();
  }
};
</script>
