<template>
  <Row>
    <Col md="12">
      <div class="d-flex">
        <Button :label="l.save" class="mr-3" rounded color="secondary"
          :loading="loading" @click="$emit('save')" :disabled="isPaid" />
        <Button :label="l.back" class="mr-3" rounded color="light"
          @click="$router.back()" />
      </div>
    </Col>
  </Row>
</template>

<script>
import { Row, Col, Button } from '@/components/vuetify';

export default {
  name: 'Totalizers',
  components: {
    Row,
    Col,
    Button
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      panel: [0],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.accounts.create.form.actions;
    },
    isPaid () {
      return this.model.status === this.$enums.statusAccounts.PAID || false;
    },
  },
};
</script>
