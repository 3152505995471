import { typePageOptions } from '@/utils';
import { text, list, check, close } from '@/utils/icons';
import { required } from '@/utils/rules';
import BoxesBusiness from '@/business/BoxesBusiness';

export default {
  domain: 'boxes',
  domainSingle: 'box',
  title: 'Caixas',
  description: 'Lista de todos os Caixas do sistema',
  business: BoxesBusiness,
  createBtn: 'Novo Caixa',
  showInactive: false,
  routes: {
    list: {
      name: 'boxes',
      path: '/caixas',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'box-create',
      path: '/caixas/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'box-show',
      path: '/caixas/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          icon: check,
          name: 'openBoxMovement',
          label: 'Abertura do caixa',
          iconColor: 'success',
          action: 'openBoxMovement'

        },
        {
          icon: close,
          name: 'closeBoxMovementLast',
          label: 'Fechamento do caixa',
          iconColor: 'red',
          action: 'closeBoxMovementLast',
        },
        {
          icon: list,
          label: 'Detalhes do caixa',
          iconColor: 'secondary',
          action: 'listMovements'
        }
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Caixa',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '6',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'money',
          name: 'balance',
          formattedName: 'balance_formatted',
          label: 'Saldo',
          align: '',
          md: '6',
          rules: [required],
          list: true,
          readonly: true,
          disabled: true,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '6',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true
        },
      ],
    },
  ],
  footer: [],
  movements: {
    list: {
      headers: [
        {
          text: 'Data de Abertura',
          value: 'date_open_formatted',
        },
        {
          text: 'Data de Fechamento',
          value: 'date_close_formatted',
        },
        {
          text: 'Funcionário',
          value: 'employee.name',
        },
        {
          text: 'Saldo',
          value: 'balance_formatted',
        },
        {
          text: 'Status',
          value: 'status_formatted',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ]
    }
  },

  movementItems: {
    list: {
      headers: [
        {
          text: 'Data',
          value: 'movement_date_formatted',
        },
        {
          text: 'Tipo',
          value: 'movement_type_formatted',
        },
        {
          text: 'Descrição',
          value: 'description',
        },
        {
          text: 'Origem',
          value: 'originable_type_formatted',
        },
        {
          text: 'Forma de Pagamento',
          value: 'payment_method_formatted',
        },
        {
          text: 'Valor',
          value: 'value_formatted',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ]
    }
  }
};
