import { check } from '@/utils/icons';
import { login } from '@/locales/pt-BR/auth';
import { register } from '@/locales/pt-BR/auth';
import { list as listBanks } from '@/locales/pt-BR/banks';
import { list as listBoxes } from '@/locales/pt-BR/boxes';
import { list as listNatureOperations } from '@/locales/pt-BR/natureOperations';
import { list as listVariations } from '@/locales/pt-BR/variations';
import { list as listProducts } from '@/locales/pt-BR/products';
import { list as listMovements } from '@/locales/pt-BR/stocks';
import { list as listStockMovements } from '@/locales/pt-BR/stockMovements';
import { list as listVehicles } from '@/locales/pt-BR/vehicles';
import { create as createVehicles } from '@/locales/pt-BR/vehicles';
import {
  list as listOrders,
  details as detailsCreateOrder,
  freight as freightCreateOrder,
  items as itemsCreateOrder,
  transporter as transporterCreateOrder,
  devolutions as devolutionsCreateOrder,
  payments as paymentsCreateOrder,
} from '@/locales/pt-BR/orders';
import { create as createAccountReceivable } from '@/locales/pt-BR/accountReceivables';
import { create as createAccountPayment } from '@/locales/pt-BR/accountPayments';
import { create as createAccounts } from '@/locales/pt-BR/accounts';
import {
  list as listProductInvoices,
  create as createProductInvoice
} from '@/locales/pt-BR/productInvoices';
import { list as listInvoiceSettings } from '@/locales/pt-BR/invoiceSettings';

export default {
  default: {
    alerts: {
      createdRegister: 'Registro criado com sucesso',
      updatedRegister: 'Registro atualizado com sucesso',
      deletedRegister: 'Registro excluído com sucesso',
      errorCreatingRegister: 'Erro ao criar o registro',
      errorDeletedRegister: 'Erro ao excluir o registro',
      success: 'Realizado com sucesso',
      error: 'Error, tente novamente',
    },
    dynamicListPage: {
      listActions: {
        icon: check,
        label: 'Ativar/Desativar',
        action: 'situation',
      }
    },
    dynamicFormPage: {},
  },
  auth: {
    login,
    register,
  },
  banks: {
    list: listBanks,
  },
  boxes: {
    list: listBoxes,
  },
  natureOperations: {
    list: listNatureOperations,
  },
  variations: {
    list: listVariations,
  },
  products: {
    list: listProducts,
  },
  stocks: {
    list: listMovements,
  },
  stockMovements: {
    list: listStockMovements,
  },
  orders: {
    list: listOrders,
    create: {
      details: detailsCreateOrder,
      freight: freightCreateOrder,
      items: itemsCreateOrder,
      transporter: transporterCreateOrder,
      devolutions: devolutionsCreateOrder,
      payments: paymentsCreateOrder,
    }
  },
  accountReceivables: {
    create: createAccountReceivable
  },
  accountPayments: {
    create: createAccountPayment
  },
  accounts: {
    create: createAccounts
  },
  vehicles: {
    list: listVehicles,
    create: createVehicles,
  },
  productInvoices: {
    list: listProductInvoices,
    create: createProductInvoice,
  },
  invoiceSettings: {
    list: listInvoiceSettings,
  },
};
