import { createServer, Model, Factory } from 'miragejs';

import customers from './seeds/customers';
import companies from './seeds/companies';
import subscriptions from './seeds/subscriptions';
import natureOperations from './seeds/natureOperations';
import users, { factoryUsers } from './seeds/users';
import allTypes from './seeds/allTypes';
import registers, { factoryRegisters } from './seeds/registers';
import stocks from './seeds/stocks';
import variations from './seeds/variations';
import products from './seeds/products';
import stockMovements from './seeds/stockMovements';
import orderSales from './seeds/orderSales';
import orderPurchases from './seeds/orderPurchases';
import orderBudgets from './seeds/orderBudgets';
import orderServices from './seeds/orderServices';

import customersRoutes from './routes/customers/';
import companiesRoutes from './routes/companies/';
import subscriptionsRoutes from './routes/subscriptions/';
import natureOperationsRoutes from './routes/natureOperations/';
import usersRoutes from './routes/users/';
import authRoutes from './routes/auth';
import allTypesRoutes from './routes/allTypes/';
import registersRoutes from './routes/registers/';
import stocksRoutes from './routes/stocks/';
import variationsRoutes from './routes/variations/';
import productsRoutes from './routes/products/';
import stockMovementsRoutes from './routes/stockMovements/';
import orderSalesRoutes from './routes/orderSales/';
import orderPurchasesRoutes from './routes/orderPurchases/';
import orderBudgetsRoutes from './routes/orderBudgets/';
import orderServicesRoutes from './routes/orderServices/';

export function makeServer({ environment = 'development' } = {}) {
  let server = createServer({
    environment,
    models: {
      customer: Model,
      company: Model,
      subscription: Model,
      natureOperation: Model,
      user: Model,
      allType: Model,
      register: Model,
      stock: Model,
      variation: Model,
      product: Model,
      stockMovement: Model,
      orderSale: Model,
      orderPurchase: Model,
      orderBudget: Model,
      orderService: Model,
    },
    factories: {
      user: Factory.extend(factoryUsers),
      register: Factory.extend(factoryRegisters),
    },
    seeds(server) {
      customers(server);
      companies(server);
      subscriptions(server);
      natureOperations(server);
      users(server);
      allTypes(server);
      registers(server);
      stocks(server);
      variations(server);
      products(server);
      stockMovements(server);
      orderSales(server);
      orderPurchases(server);
      orderBudgets(server);
      orderServices(server);
    },
    routes() {
      this.namespace = '/api/v1';
      customersRoutes(this);
      companiesRoutes(this);
      subscriptionsRoutes(this);
      natureOperationsRoutes(this);
      authRoutes(this);
      usersRoutes(this);
      allTypesRoutes(this);
      registersRoutes(this);
      stocksRoutes(this);
      variationsRoutes(this);
      productsRoutes(this);
      stockMovementsRoutes(this);
      orderSalesRoutes(this);
      orderPurchasesRoutes(this);
      orderBudgetsRoutes(this);
      orderServicesRoutes(this);
    },
  });

  return server;
}