<template>
  <ModalCardDefault title="Gerar Relatório" @close="close">
    <ExpansionPanel v-model="panel" title="Informações do Relatório" :icon="$icons.box" readonly>
      <Row class="mt-2">
        <Col>
          <h2 class="mb-2">Relatório{{ item.name }}</h2>
          <span class="red--text">Ao clicar no botão "Gerar" o relatório começará a ser preparadom, assim que finalizarmos você receberá um e-mail com o link do relatório.</span>
        </Col>
      </Row>
    </ExpansionPanel>

    <div slot="actions">
      <Button label="Gerar"  @click="generateReport"
        class="mr-3" rounded color="secondary" :loading="loadingGenerate" />
    </div>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Row,
  Col,
  Button
} from '@/components/vuetify';
export default {
  name: 'FinishBalance',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    Button
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      panel: [0],
      resetProductNotChanged: false,
      loadingGenerate: false
    };
  },
  methods: {
    generateReport() {
      this.loadingGenerate = true;
      console.log(this.item.id);
      this.$api.reports.generateReport(this.item.id).then(() => {
        this.$noty.success('Relatório gerado com sucesso!');
      }).catch(() => {
        this.$noty.error('Erro ao gerar relatório!');
      }).finally(() => {
        this.loadingGenerate = false;
        this.$emit('close');
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
