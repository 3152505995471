import DynamicService from '@/service/DynamicService';
import PlanSchema from '../schemas/PlanSchema';

const formatResponse = (item) => {
  item;
};

const PlansService = DynamicService('plans', PlanSchema, { formatResponse });

export default PlansService;
