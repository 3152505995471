<template>
  <div>
     <ModalCardDefault :title="`Clientes do Revendedor: ${resellerSelected.name}`" @close="$emit('close')">
        <ExpansionPanel v-model="panel" :title="'Lista dos Clientes'" :icon="$icons.box" readonly>
          <DataTable no-search :items="customers" :items-per-page="6"
            :headers="headers"
            @getItems="$emit('getCustomers', $event)">
          </DataTable>

        </ExpansionPanel>
     </ModalCardDefault>
  </div>
</template>

<script>
import {
  DataTable,
  ExpansionPanel,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'ListCustomers',
  components: {
    DataTable,
    ModalCardDefault,
    ExpansionPanel,
  },
  props: {
    resellerSelected: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      dialogModal: false,
      loadingDataTable: false,
      customers: {}
    };
  },
  computed: {
    headers () {
      return [
        { text: 'Nome', value: 'name' },
        { text: 'Contato', value: 'cell_phone' },
      ];
    },
  },
  watch: {
    resellerSelected () {
      this.getCustomers();
    }
  },
  methods: {
    getCustomers (syncOptions = {}) {
      this.$api.customers.getCustomers(this.resellerSelected.id, syncOptions).then((res) => {
        console.log(res);
        this.customers = {
          ...res,
          data: res.customers
        };
        this.dialogMovements = true;
      }).catch((err) => {
        this.$noty.error(err);
      });
    }
  },
  mounted () {
    this.getCustomers();
  }
};
</script>
