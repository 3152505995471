<script>
export default {
  methods: {
    focusInputs(ref) {
      setTimeout(() => {
        ref.focus();
      }, 100);
    },
  }
};
</script>
