export default {
  actions: {
    certificate: 'Certificado Digital'
  },
  dialogCertificate: {
    title: 'Certificado Digital',
    certificateData: {
      title: 'Dados do Certificado Digital',
      name: 'Razão Social',
      cnpj: 'CNPJ',
      initialDate: 'Data Inicial',
      finalDate: 'Data Final',
      situation: 'Situação',
      active: 'Certificado Ativo',
      expired: 'Certificado Expirado',
    },
    actions: {
      upload: 'Enviar Certificado'
    },
    upload: {
      title: 'Dados do Certificado Digital',
      certificate: 'Selecione o Certificado',
      password: 'Senha',
      success: 'Certificado Enviado com Sucesso',
      actions: {
        send: 'Enviar'
      },
    }
  },
  disableNumbers: {
    title: 'Inutilizar Números',
    form: {
      title: 'Preencha as Informações Abaixo',
      environment: 'Ambiente',
      series: 'Série',
      initialNumber: 'Número Inicial',
      finalNumber: 'Número Final',
      justify: 'Justificativa',
      justifyText: 'Digite suas justificativas',
      production: 'Produção',
      homologation: 'Homologação',
    },
    actions: {
      confirm: 'Confirmar'
    },
    noty: {
      error: 'Erro ao Inutilizar Números',
    }
  },
  listEvents: {
    title: 'Eventos Fiscais',
    dataTable: {
      title: 'Lista de Todos os Eventos',
      disable_number: 'Inutilização de Número',
    },
    actions: {
      confirm: 'Confirmar'
    },
  },
};
