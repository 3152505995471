/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { personType, typeRegister, taxPayer } from '@/utils/enums';
import { required } from '@/utils/rules';

const fieldsLegalReadonly = ['cnpj', 'ie', 'social_name'];
const fieldsPhysicalReadonly = ['cpf', 'rg'];
const fieldsLegalClientRules = {
  cnpj: [required],
  ie: [required],
  social_name: [required],
  taxpayer_type: [required],
  cpf: [],
};
const fieldsPhysicalClientRules = {
  cnpj: [],
  ie: [],
  social_name: [],
  taxpayer_type: [required],
  cpf: [required],
};
const fieldsNotClientRules = {
  cnpj: [],
  ie: [],
  social_name: [],
  taxpayer_type: [],
  cpf: [],
};

const setFieldReadonly = (form, propsFields, fields, value) => {
  fields.forEach((itemName) => {
    propsFields[itemName].readonly = value;
    if (value) Vue.set(form, itemName, null);
  });
};

const setRules = (form, propsFields, fields, condition) => {
  Object.keys(fields).forEach((key) => {
    if (condition) {
      propsFields[key].rules = fields[key];
    }
  });
};

const ieIsRequired = (form) => {
  return form.taxpayer_type === taxPayer.TAXPAYER &&  form.person_type === personType.LEGAL;
};

const handlerFunctions = (form, propsFields, route) => {
  setFieldReadonly(form, propsFields, fieldsPhysicalReadonly, form.person_type === personType.LEGAL);
  setFieldReadonly(form, propsFields, fieldsLegalReadonly, form.person_type === personType.PHYSICAL);
  setRules(form, propsFields, fieldsPhysicalClientRules, route.meta.type === typeRegister.CLIENT && form.person_type === personType.PHYSICAL);
  setRules(form, propsFields, fieldsLegalClientRules, route.meta.type === typeRegister.CLIENT && form.person_type === personType.LEGAL);
  setRules(form, propsFields, fieldsNotClientRules, route.meta.type !== typeRegister.CLIENT);
};

export default {
  beforeForm: ({ form, propsFields, route }) => {

    if (!ieIsRequired(form)) {
      fieldsLegalClientRules.ie = [];
    }
    handlerFunctions(form, propsFields, route);
  },
  beforeFilters: ({ form }) => {
  },
  changes: {
    person_type: ({ form, propsFields, route }) => {
      handlerFunctions(form, propsFields, route);
    },
    taxpayer_type: ({ form, propsFields }) => {
      Vue.set(propsFields, 'ie', { ...propsFields.ie, rules: ieIsRequired(form) ? [required] : [] });

      if (!ieIsRequired(form)) {
        Vue.set(form, 'ie', null);
      }
    },
  },
};
