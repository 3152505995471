import { statusBoxes } from '@/utils/enums';

export default {
  beforeList: ({ form }) => {
    form.boxes.forEach((boxes) => {
      if (boxes.status === statusBoxes.OPEN) {
        boxes.disableItemMoreActions = {
          openBoxMovement: true,
          closeBoxMovementLast: false,
        };
      } else {
        boxes.disableItemMoreActions = {
          openBoxMovement: false,
          closeBoxMovementLast: true,
        };
      }
    });
  },
};
