import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, list, calendar } from '@/utils/icons';
import CheckControlBusiness from '@/business/CheckControlBusiness';
import { required, money } from '@/utils/rules';
import { checkControlStatus } from '@/utils/options';

export default {
  domain: 'check_controls',
  domainSingle: 'check_control',
  business: CheckControlBusiness,
  title: 'Controle de Cheques',
  description: 'Lista de todos os Cheques do sistema',
  showInactive: false,
  createBtn: 'Novo Cheque',
  showExpand: true,
  routes: {
    list: {
      name: 'check_controls',
      path: '/controle-cheques',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'check_control-create',
      path: '/controle-cheques/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'check_control-show',
      path: '/controle-cheques/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'text',
        name: 'number',
        label: 'Número',
        icon: list,
        md: 6,
      },
      {
        type: 'text',
        name: 'value',
        label: 'Valor',
        icon: list,
        md: 6,
      },
      {
        type: 'select',
        name: 'status',
        label: 'Status',
        icon: list,
        md: 12,
        items: {
          data: checkControlStatus
        }
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Cheque',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'dataPicker',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Data',
          rules: [required],
          itemValueDefault: true,
          default: getCurrentPeriod().currentDateEN,
          clearable: false,
          align: '',
          md: '2',
          list: true,
        },
        {
          type: 'dataPicker',
          name: 'paid_date',
          formattedName: 'paid_date_formatted',
          label: 'Depositar',
          md: '2',
          clearable: true,
          align: '',
          list: true,
        },
        {
          type: 'dataPicker',
          name: 'compensation_date',
          formattedName: 'compensation_date_formatted',
          label: 'Compensação',
          md: '2',
          clearable: true,
          align: '',
          list: true,
        },
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '6',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'number',
          formattedName: 'number',
          label: 'Número',
          align: '',
          md: '2',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
        {
          type: 'select',
          name: 'bank_id',
          formattedName: 'bank.description',
          label: 'Banco',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'banks',
            endpoint: 'banks',
            queryParams: 'page=1&per_page=-1',
          },
          newItem: {
            has: false,
          },
          itemText: 'description',
          itemValue: 'id',
          contentExpanded: true,
        },
        {
          type: 'money',
          name: 'value',
          formattedName: 'value_formatted',
          label: 'Valor',
          align: '',
          md: '3',
          rules: [required, money],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'select',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          items: checkControlStatus,
          default: 'pending',
          itemValueDefault: true,
        },
      ]
    }
  ],
  footer: [
    {
      name: 'total_check',
      label: 'Qtd. de Cheques',
      color: 'primary',
      md: 4,
    },
    {
      name: 'value_total_check_available',
      label: 'Total a Receber',
      color: 'primary',
      md: 4,
    },
    {
      name: 'value_total_check_receivable',
      label: 'Total Recebido',
      color: 'primary',
      md: 4,
    },
  ]
};
