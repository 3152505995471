import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('orderPurchase', {
    number: '1',
    seller: {
      id: 1,
      name: 'Antônio José'
    },
    customer: {
      id: 22,
      name: 'Roberto Cruz'
    },
    sub_total_value: '50,00',
    discount_value: '10,00',
    taxes_value: '5,00',
    total_value: '45,00',
    ...baseFields,
  });
};
