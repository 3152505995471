<template>
  <ModalCardDefault :title="l.title"
    @close="cancel" :actions="{ close: l.actions.cancel }">
    <v-form ref="validForm" lazy-validation>
      <ExpansionPanel v-model="panel" :title="'Informações Gerais'" :icon="$icons.box" readonly>
        <Row>
          <Col md="6">
            <Select v-model="model.operation_type" :items="options.typeDocument"
              :label="l.typeNFe" :placeholder="l.typeNFe" :rules="[rules.required]" />
          </Col>
          <Col md="6">
            <Select v-model="model.invoice_setting_id" :items="invoiceSettings"
              :label="l.taxConfig" :placeholder="l.taxConfig"
              item-value="id" item-text="description" @change="changeInvoiceSetting"
              :rules="[rules.required]" />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Select v-model="model.emission_type" :items="options.emissionType"
              :label="l.emissionForm" :placeholder="l.emissionForm"
              :rules="[rules.required]" />
          </Col>
          <Col md="6">
            <Select v-model="model.printer_type" :items="options.printerType"
              :label="l.danfeForm" :placeholder="l.danfeForm"
              :rules="[rules.required]" />
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <TextField v-model="model.model" :label="l.model"
              :placeholder="l.model" class="textfield-custom"
              :rules="[rules.required]" />
          </Col>
          <Col md="2">
            <TextField v-model="model.series" :label="l.series"
              :placeholder="l.series" class="textfield-custom"
              :rules="[rules.required]" />
          </Col>
          <Col md="2">
            <TextField v-model="model.nf_number" :label="l.nfNumber"
              :placeholder="l.nfNumber" class="textfield-custom"
              :rules="[rules.required]" />
          </Col>
          <Col md="6">
            <Select v-model="model.emission_finality" :items="options.emissionFinality"
              :label="l.finality" :placeholder="l.finality"
              :rules="[rules.required]" />
          </Col>
        </Row>
      </ExpansionPanel>
    </v-form>

    <div slot="actions">
      <Button :label="l.actions.confirm" color="secondary" rounded
        @click="confirm" class="mr-3" />
    </div>
  </ModalCardDefault>
</template>

<script>
import {
  Row,
  Col,
  Select,
  ExpansionPanel,
  TextField,
  Button
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import { typeDocument, printerType, emissionFinality, emissionType } from '@/utils/options';
import { required } from '@/utils/rules';

export default {
  name: 'InfosGeneralsModal',
  components: {
    Row,
    Col,
    Select,
    ModalCardDefault,
    ExpansionPanel,
    TextField,
    Button
  },
  props: {
  },
  data () {
    return {
      validForm: false,
      panel: [0],
      options: {
        typeDocument,
        printerType,
        emissionFinality,
        emissionType,
      },
      rules: {
        required
      },
      invoiceSettings: [],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.infosGenerals.modal;
    }
  },
  mixins: [CreateUpdateProductInvoiceMixin],
  methods: {
    async getInvoicesSettings () {
      this.$api.invoiceSettings.index().then(({ data }) => {
        this.invoiceSettings = data.invoice_settings;
      });
    },
    changeInvoiceSetting () {
      const invoiceSetting = this.invoiceSettings.find((item) => {
        return item.id === this.model.invoice_setting_id;
      });

      this.model.environment = invoiceSetting.environment;
      this.model.model = invoiceSetting.model;
      this.model.series = invoiceSetting.series;
      this.model.nf_number = parseInt(invoiceSetting.current_number) + 1;
    },
    cancel () {
      // this.model.operation_type = 'output';
      // this.model.invoice_setting_id = 'c645d2b9-b9f0-4899-b969-91ac6d9e9f70';
      // this.model.emission_type = 'normal';
      // this.model.printer_type = 'portrait';
      // this.model.model = '55';
      // this.model.series = '9';
      // this.model.nf_number = '13';
      // this.model.emission_finality = 'normal';
      // this.model.environment = 'homologation';
      this.$router.push({ name: 'product-invoices' });
    },
    confirm () {
      if (!this.$refs.validForm.validate()) {
        return false;
      }
      this.$emit('update:dialog', false);
    }
  },
  async mounted () {
    await this.getInvoicesSettings();
  }
};
</script>
