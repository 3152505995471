<template>
  <div>
    <h4 class="title-section" v-text="l.title" />
    <v-form v-model="validBill" ref="formBill" lazy-validation>
      <Row class="mt-0 border-all-row" style="min-height: 335px">
        <Col md="4" class="py-0 border-right-col">
          <Row class="mt-0">
            <Col md="12" class="py-0">
              <p class="caption mt-2" v-text="l.number" />
              <p v-if="isAuthorized" v-text="model.bill.number" />
              <TextField v-else v-model="model.bill.number" :outlined="false"
                :rules="[rules.required]" class="margin-top-custom-1" />
            </Col>
            <Col md="12" class="py-0 border-y-col">
              <p class="caption mt-2" v-text="l.originalValue" />
              <p v-if="isAuthorized" v-text="model.bill.original_value_formatted" />
              <TextFieldMoney v-else v-model="model.bill.original_value"
                :outlined="false" readonly class="margin-top-custom-1" />
            </Col>
            <Col md="12" class="py-0">
              <p class="caption mt-2" v-text="l.discountValue" />
              <p v-if="isAuthorized" v-text="model.bill.discount_value_formatted" />
              <TextFieldMoney v-else v-model="model.bill.discount_value"
                :outlined="false" readonly class="margin-top-custom-1" />
            </Col>
            <Col md="12" class="py-0 border-y-col">
              <p class="caption mt-2" v-text="l.liquidValue" />
              <p v-if="isAuthorized" v-text="model.bill.liquid_value_formatted" />
              <TextFieldMoney v-else v-model="model.bill.liquid_value"
                :outlined="false" readonly class="margin-top-custom-1" />
            </Col>
            <Col md="12" class="py-0">
              <p class="caption mt-2" v-text="l.paymentMethod" />
              <p v-if="isAuthorized" v-text="model.bill.payment_method.name" />
              <Select v-else v-model="model.bill.payment_method_id"
                :items="paymentMethods" item-value="id" item-text="name"
                :outlined="false" class="margin-top-custom-3"
                :rules="[rules.required]"
                @change="setPayments" />
            </Col>
          </Row>
        </Col>
        <Col md="8" class="py-0">
          <Row class="mt-0">
            <Col md="12" class="py-0">
                <div class="d-flex justify-end mt-3">
                  <Button v-if="!isAuthorized" label="Nova Duplicata" color="secondary" rounded
                    class="button-custom" :icon="$icons.plus" small @click="openBillDuplicate"
                    :disabled="disabledBtnDuplicates" />
                </div>
                <DataTable class="mt-5" no-search no-sync :items="model.bill.duplicates"
                  :headers="headers" :slots="slotsItems" :items-per-page="10">

                  <template v-slot:value="{ props: { item } }">
                    {{ formatCurrency(item.value) }}
                  </template>
                  <template v-slot:due_date="{ props: { item } }">
                    {{ formatDatePT(item.due_date) }}
                  </template>
                  <template v-slot:actions="{ props }">
                    <ButtonIcon color="primary" x-small :icon="$icons.destroy" @click="removeItem(props)" :tooltip="l.tooltips.removeDuplicate" />
                  </template>
                </DataTable>
            </Col>
          </Row>
        </Col>
      </Row>
    </v-form>

    <Dialog :dialog="dialogBillDuplicate" no-title :maxWidth="600">
      <BillsCreateDuplicate slot="content" @addDuplicate="addDuplicate"
        @update:dialogBillDuplicate="dialogBillDuplicate = $event" />
    </Dialog>
  </div>
</template>

<script>
import {
  Row,
  Col,
  TextField,
  Select,
  TextFieldMoney,
  DataTable,
  Button,
  ButtonIcon,
  Dialog,
} from '@/components/vuetify';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import VerifySumDuplicatesMixin from '../../mixins/VerifySumDuplicatesMixin';
import { formatCurrency, formatDatePT } from '@/utils';
import BillsCreateDuplicate from './BillsCreateDuplicate';

export default {
  name: 'Bills',
  components: {
    Row,
    Col,
    TextField,
    Select,
    TextFieldMoney,
    DataTable,
    Button,
    ButtonIcon,
    Dialog,
    BillsCreateDuplicate,
  },
  props: {
    paymentMethods: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      validBill: false,
      formatCurrency: formatCurrency,
      formatDatePT: formatDatePT,
      slotsItems: [
        { field: 'value' },
        { field: 'due_date' },
      ],
      dialogBillDuplicate: false
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.bills;
    },
    headers () {
      return [
        {
          text: 'Número',
          value: 'number'
        },
        {
          text: 'Vencimento',
          value: 'due_date'
        },
        {
          text: 'Valor',
          value: 'value'
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end'
        }
      ];
    },
    disabledBtnDuplicates () {
      return this.model.bill.liquid_value <= 0;
    }
  },
  mixins: [CreateUpdateProductInvoiceMixin, VerifySumDuplicatesMixin],
  methods: {
    openBillDuplicate () {
      this.dialogBillDuplicate = true;
    },
    removeItem ({ item, index }) {
      if (!item.new_item)  {
        this.model.bill.deleted_duplicates.push({ id: item.id });
      }
      this.model.bill.duplicates.splice(index, 1);
      this.changeDuplicates();
    },
    addDuplicate ({ due_date, value }) {
      if (this.verifyTotalDuplicates(this.model.bill.duplicates,
        this.model.total.nf_value, value)) {
        return false;
      }

      this.model.bill.duplicates.push({ number: 1, due_date, value, new_item: true });
      this.changeDuplicates();
      this.dialogBillDuplicate = false;
    },
    changeDuplicates () {
      this.model.bill.duplicates = this.model.bill.duplicates.map((item, index) => {
        return {
          ...item,
          number: (index + 1)
        };
      });
    },
    setPayments (item) {
      const paymentMethodSelected = this.paymentMethods.find((paymentMethod) => {
        return paymentMethod.id === item;
      });

      this.model.payments = [];
      this.model.payments.push({
        payment_method_id: paymentMethodSelected.id,
        type_installment_id: '4850f581-54ab-4640-a679-490745543ad3',
        flag_id: null,
        aut: null,
        value: this.model.bill.liquid_value,
      });
    }
  }
};
</script>
