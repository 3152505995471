<template>
  <div>
    <PageHeader :schema="$schemas.balance" />
    <PageContent>
      <DynamicListPage ref="ListBalances" :schema="$schemas.balance"
        :service="$api.balances"
        @finishBalance="finishBalance" @cancelBalance="cancelBalance">
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListBalances',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
  },
  data () {
    return {
      loadingDialogMoreActions: false,
    };
  },
  methods: {
    finishBalance({ item, resetProductNotChanged }) {
      this.$api.balances.finish(item.id, resetProductNotChanged)
        .then(() => {
          this.$refs.ListBalances.fetchDataApi();
        }).finally(() => {
          this.$refs.ListBalances.closeModalListActions();
        });
    },
    cancelBalance(data) {
      this.$api.balances.cancel(data.id)
        .then(() => {
          this.$refs.ListBalances.fetchDataApi();
        }).finally(() => {
          this.$refs.ListBalances.closeConfirmDialogMoreActions();
        });
    }
  },
};
</script>
