<template>
  <ModalCardDefault :title="'Remover Item'" :displayNoButton="false">
    <TextFieldInteger v-model="item" ref="refItem" id="refItem" placeholder="Item"
      label="Item" />

    <template v-slot:actions>
      <Button color="secondary"
        rounded @click="confirm" class="mr-3">
        <span class="text-button">Confirmar
            <span class="text-button font-weight-black yellow--text">(Enter)</span>
          </span>
      </Button>
      <Button color="primary"
        rounded @click="$emit('update:dialogModal', false)">
        <span class="text-button">Fechar
            <span class="text-button font-weight-black yellow--text">(Esc)</span>
          </span>
      </Button>
    </template>
  </ModalCardDefault>
</template>

<script>
import { TextFieldInteger, Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import SelectContentInputMixin from '@/mixins/SelectContentInputMixin';

export default {
  name: 'PdvRemoveItem',
  components: { ModalCardDefault, TextFieldInteger, Button },
  data () {
    return {
      item: 0
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  mixins: [InputFocusMixin, SelectContentInputMixin],
  methods: {
    confirm () {
      if (!this.item || this.item == 0) {
        this.$noty.error('Digite o item que deseja remover');

        return false;
      }
      this.$emit('confirmDialog', { action: 'removeItem', data: parseInt(this.item) });
    },
    actionListener(event) {
      if (event.key === 'Enter') {
        this.confirm();
      }
    }
  },
  mounted () {
    this.focusInputs(this.$refs.refItem.$children[0].$children[0]);
    this.selectContentInput(['refItem']);
  }
};
</script>

<style lang="scss" scoped>

</style>
