<template>
  <ModalCardDefault title="Movimentos" @close="close">
    <ExpansionPanel v-model="panel" title="Todos os Movimentos" :icon="$icons.box" readonly>
      <DataTable no-search :items="cashFlowItems"
        :headers="headers" :items-per-page="7" :loading="loading"
        :slots="slotsItems" @getItems="getCashFlowItems">
        <template v-slot:[`date`]="{ props: { item: { date } } }">
          {{ utils.formatDatePT(date) }}
        </template>
        <template v-slot:[`value`]="{ props: { item: { movement_type, value } } }">
          <span :class="[getClass(movement_type)]"><b>{{ utils.formatCurrency(value) }}</b></span>
        </template>
        <template v-slot:[`origin`]="{ props: { item } }">
          <ButtonIcon color="secondary" x-small :icon="$icons.openInNew"
            @click="setOrigin(item)" tooltip="Abrir a origem" />
        </template>
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import { ExpansionPanel, DataTable, ButtonIcon } from '@/components/vuetify';
import { formatDatePT, formatCurrency } from '@/utils';
import OpenOriginMixin from '@/mixins/OpenOriginMixin';

export default {
  name: 'ListMovements',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    DataTable,
    ButtonIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  watch: {
    item: {
      handler () {
        this.getCashFlowItems();
      },
      deep: true,
    },
  },
  data: () => ({
    panel: [0],
    utils: {
      formatDatePT,
      formatCurrency,
    },
    loading: false,
    slotsItems: [
      { field: 'date' },
      { field: 'value' },
      { field: 'origin' },
    ],
    cashFlowItems: [],
  }),
  computed: {
    headers () {
      return [
        { text: 'Data', value: 'date', },
        { text: 'Descrição', value: 'description', },
        { text: 'Valor', value: 'value', },
        { text: 'Origem', value: 'origin', align: 'end' },
      ];
    },
  },
  mixins: [OpenOriginMixin,],
  methods: {
    getCashFlowItems(pagination) {
      this.loading = true;
      this.$api.cashFlows.getAllCashFlowItemsByCashFlowId(this.item.id, pagination).then((res) => {
        this.cashFlowItems = res;
      }).catch(() => {
        this.$noty.error('Erro ao pegar os movimentos');
      }).finally(() => {
        this.loading = false;
      });
    },
    close () {
      this.$emit('close');
    },
    getClass (movementType) {
      return {
        'primary--text': movementType === this.$enums.cashFlowItemMovementType.OUTPUT,
        'success--text': movementType === this.$enums.cashFlowItemMovementType.INPUT,
      };
    },
    setOrigin(item) {
      let routeName = null;

      if (item.originable_type === 'App\\Models\\AccountParcel') {
        routeName = item.movement_type === this.$enums.cashFlowItemMovementType.INPUT ? 'accountReceivableShow' : 'accountPaymentShow';
      } else if (item.originable_type === 'App\\Models\\AccountParcelPayment') {
        routeName = 'accountParcelPaymentShow';
      }
      this.openOrigin({ route_name: routeName, id: item.originable.account_id });
    },
  },
  mounted () {
    this.getCashFlowItems();
  },
};
</script>

<style>

</style>
