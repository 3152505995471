export default {
  dialogVariationValues: {
    title: 'Lista dos Valores da Variação',
    list: {
      tooltips: {
        delete: 'Excluir',
      },
      actions: {
        newValue: 'Novo Valor',
      },
      noty: {
        successDeleteVariationValue: 'Variação excluída com Sucesso',
        errorDeleteVariationValue: 'Erro ao excluir a Variação.',
      },
    },
    create: {
      title: 'Novo Valor para Variação:',
      noty: {
        successSave: 'Valor cadastrado com Sucesso',
        errorSave: 'Erro ao cadastrar o Valor.',
      },
      actions: {
        save: 'Salvar',
      }
    }
  },
};
