var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalCardDefault',{attrs:{"title":"Movimentos"},on:{"close":_vm.close}},[_c('ExpansionPanel',{attrs:{"title":"Todos os Movimentos","icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('DataTable',{attrs:{"no-search":"","items":_vm.cashFlowItems,"headers":_vm.headers,"items-per-page":7,"loading":_vm.loading,"slots":_vm.slotsItems},on:{"getItems":_vm.getCashFlowItems},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var date = ref.props.item.date;
return [_vm._v(" "+_vm._s(_vm.utils.formatDatePT(date))+" ")]}},{key:"value",fn:function(ref){
var ref_props_item = ref.props.item;
var movement_type = ref_props_item.movement_type;
var value = ref_props_item.value;
return [_c('span',{class:[_vm.getClass(movement_type)]},[_c('b',[_vm._v(_vm._s(_vm.utils.formatCurrency(value)))])])]}},{key:"origin",fn:function(ref){
var item = ref.props.item;
return [_c('ButtonIcon',{attrs:{"color":"secondary","x-small":"","icon":_vm.$icons.openInNew,"tooltip":"Abrir a origem"},on:{"click":function($event){return _vm.setOrigin(item)}}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }