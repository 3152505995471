<template>
  <div>
    <PageHeader :schema="$schemas.creditCardControl" />
    <PageContent>
      <DynamicListPage ref="listCreditCardControls"
        :schema="$schemas.creditCardControl" :service="$api.creditCardControls"
          @actionMoreActions="actionMoreActions" />
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="modalProps.componentModal" slot="content" v-bind="modalProps"
        @close="closeDialog" v-on="modalEvents"
        @updateListControls="getCreditCardControls" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog, Row, Col, Alert } from '@/components/vuetify';
import ListParcels from '../components/ListParcels';

export default {
  name: 'ListCreditCardControls',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog,
    Row,
    Col,
    Alert
  },
  data () {
    return {
      creditCardControlSelected: {},
      propsModal: {},
      modalEvents: {},
      modalProps: false,
      componentModal: null,
      dialogModal: false
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem, moreActionItem } = data;
      this.creditCardControlSelected = dataListPropsItem.item;
      this.dialogModal = true;
      this.modalProps = {
        ...moreActionItem.action === 'openParcels' && {
          maxWidth: 1200,
          creditCardControlSelected: this.creditCardControlSelected,
          componentModal: ListParcels,
        },
      };
    },
    async getCreditCardControls () {
      await this.$refs.listCreditCardControls.index().then((res) => {
        this.creditCardControlSelected = res.credit_card_transactions.find((item) => item.id === this.creditCardControlSelected.id);
        this.modalProps = {
          ...this.modalProps,
          creditCardControlSelected: this.creditCardControlSelected,
        };
      });
    },
    closeDialog () {
      this.creditCardControlSelected = {};
      this.dialogModal = false;
      this.modalProps = {};
      this.componentModal = {};
      this.$refs.listCreditCardControls.fetchDataApi();
    }
  },
};
</script>
