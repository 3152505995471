import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import TableSchema from '../schemas/TableSchema';
import { getText, formatCurrency } from '@/utils';
import { tableStatus } from '@/utils/options';

const formatResponse = (item) => {
  item.status_formatted = getText(tableStatus, item.status);
  item.total_value_formatted = formatCurrency(item.total_value);

  item.table_sale?.items?.forEach((table_sale_item, index) => {
    table_sale_item.item_number = index + 1;
    table_sale_item.product_variation.name_formatted = table_sale_item.product_variation.name;
    table_sale_item.unit_value_formatted = formatCurrency(table_sale_item.unit_value);
    table_sale_item.quantity_formatted = formatCurrency(table_sale_item.quantity, { type: 'decimal' });
    table_sale_item.sub_total_formatted = formatCurrency(table_sale_item.sub_total);
    table_sale_item.discount_formatted = formatCurrency(table_sale_item.discount);
    table_sale_item.total_value_formatted = formatCurrency(table_sale_item.total_value);
  });
};

const TablesService = DynamicService('tables', TableSchema, { formatResponse });

const TablesCommands = () => ({
  async openCloseTable(payload) {
    await axios.post(`${TableSchema.domain}/open-close`, payload);
  },
  async createTableSaleItem(tableId, tableSaleId, payload) {
    await axios.post(`${TableSchema.domain}/${tableId}/table-sales/${tableSaleId}/table-sale-items`, payload);
  },
  async deleteTableSaleItem(tableId, tableSaleId, tableSaleItemId) {
    await axios.delete(`${TableSchema.domain}/${tableId}/table-sales/${tableSaleId}/table-sale-items/${tableSaleItemId}`);
  },
});

export default TablesService;
export { TablesCommands, TablesService };
