<script>
export default {
  methods: {
    generatePDF (domain, path, payload, forceDownload = false) {
      this.$api[domain].generatePDF(path, payload).then((res) => {
        if (!forceDownload) {
          const blob = new Blob([res], {
            type: 'application/pdf',
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        } else {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.pdf');
          document.body.appendChild(link);
          link.click();
        }
      }).catch(() => {
      });
    },
    async generatePDFToPrint(domain, path, payload) {
      const isBlob = false;
      this.$api[domain].generatePDF(path, payload, isBlob).then((res) => {
        const byteCharacters = atob(res);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const pdfWindow = window.open(url);

        pdfWindow.addEventListener('load', function () {
          pdfWindow.focus();
          pdfWindow.print();
        });
      }).catch((error) => {
        console.error('Erro ao tentar imprimir o PDF:', error);
      });
    }
  }
};
</script>
