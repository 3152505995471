import { pagination } from '../../utils/pagination';
import { softDelete } from '../../utils/softDelete';
import { filter } from '../../utils/filter';

export const getAll = (server) => {
  server.get('/variations', (schema, request) => {
    const variations = schema.variations.all();
    const { page, perPage } = request.queryParams;

    variations.models = filter(request.queryParams, variations);
    variations.models = softDelete(variations);

    return pagination('variations', variations, page, perPage);
  });
};