import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import ProductInvoiceSchema from '../schemas/ProductInvoiceSchema';
import {
  formatPhone,
  getText,
  formatDatePT,
  formatCpfCnpj,
  formatCurrency,
  getHourInDateTime,
  formatZipCode
} from '@/utils';
import { typePage, finalConsumer, personType, ENVIRONMENT_INVOICE, modelNf as modelNfEnum } from '@/utils/enums';
import { productInvoicesStatus, modelNf, typeDocument, taxPayerShort, intermediaryIndicatorShort, presenceIndicator, freights } from '@/utils/options';
import { clone } from 'lodash';

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.client_name_formatted = item.client?.name || 'Não informado';
    item.model_formatted = getText(modelNf, item.model);
    item.emission_date_formatted = formatDatePT(item.emission_date);
    item.status_formatted = getText(productInvoicesStatus, item.status);
    item.operation_type_formatted = getText(typeDocument, item.operation_type);
    item.total.nf_value_formatted = formatCurrency(item.total.nf_value);
    item.environment_formatted = item.environment === ENVIRONMENT_INVOICE.homologation ? 'Homologação' : 'Produção';
    item.events = item.events.map(event => {
      return {
        ...event,
        created_at_formatted: formatDatePT(event.created_at),
      };
    });
    item.correction_letters = item.correction_letters.map(correction_letter => {
      return {
        ...correction_letter,
        created_at_formatted: formatDatePT(correction_letter.created_at)
      };
    });
  };

  const formatShow = (product_invoice) => {
    product_invoice.emission_date_formatted = formatDatePT(product_invoice.emission_date);
    product_invoice.receipt_date_formatted = formatDatePT(product_invoice.receipt_date);
    product_invoice.intermediary_indicator_formatted = getText(intermediaryIndicatorShort, product_invoice.intermediary_indicator);
    product_invoice.presence_indicator_formatted = getText(presenceIndicator, product_invoice.presence_indicator);
    product_invoice.emitter.cell_phone = formatPhone(product_invoice.emitter.cell_phone);
    product_invoice.emitter.cnpj = formatCpfCnpj(product_invoice.emitter.cnpj);

    if (product_invoice.client) {
      product_invoice.client.document = product_invoice.client?.person_type === personType.PHYSICAL
        ? product_invoice.client?.cpf : product_invoice.client?.cnpj;
      product_invoice.client.document_formatted = product_invoice.client?.person_type === personType.PHYSICAL
        ? formatCpfCnpj(product_invoice.client?.cpf) : formatCpfCnpj(product_invoice.client?.cnpj);
      product_invoice.client.zip_code_formatted = formatZipCode(product_invoice.client?.zip_code);
      product_invoice.client.phone_formatted = formatPhone(product_invoice.client.phone);
      product_invoice.client.taxpayer_type_formatted = getText(taxPayerShort, product_invoice.client?.taxpayer_type);
      product_invoice.client.rg_ie = product_invoice.client?.person_type === personType.PHYSICAL
        ? product_invoice.client.rg : product_invoice.client?.ie;
    }

    product_invoice.input_output_emission_hour = getHourInDateTime(product_invoice.emission_date);
    product_invoice.input_output_receipt_hour = getHourInDateTime(product_invoice.receipt_date);
    product_invoice.final_consumer === finalConsumer.FINAL_CONSUMER ?
      product_invoice.final_consumer = true : product_invoice.final_consumer = false;

    product_invoice.bill.original_value_formatted = formatCurrency(product_invoice.bill.original_value);
    product_invoice.bill.discount_value_formatted = formatCurrency(product_invoice.bill.discount_value);
    product_invoice.bill.liquid_value_formatted = formatCurrency(product_invoice.bill.liquid_value);
    product_invoice.bill.deleted_duplicates = [];
    product_invoice.deleted_items = [];

    product_invoice.total.icms_base_calculate_formatted = formatCurrency(product_invoice.total.icms_base_calculate);
    product_invoice.total.icms_value_formatted = formatCurrency(product_invoice.total.icms_value);
    product_invoice.total.icms_st_base_calculate_formatted = formatCurrency(product_invoice.total.icms_st_base_calculate);
    product_invoice.total.icms_st_value_formatted = formatCurrency(product_invoice.total.icms_st_value);
    product_invoice.total.products_value_formatted = formatCurrency(product_invoice.total.products_value);
    product_invoice.total.discount_value_formatted = formatCurrency(product_invoice.total.discount_value);
    product_invoice.total.tax_approximates_value_formatted = formatCurrency(product_invoice.total.tax_approximates_value);
    product_invoice.total.ipi_value_formatted = formatCurrency(product_invoice.total.ipi_value);
    product_invoice.total.nf_value_formatted = formatCurrency(product_invoice.total.nf_value);

    product_invoice.transport.other_expenses_formatted = formatCurrency(product_invoice.transport.other_expenses);
    product_invoice.transport.freight_value_formatted = formatCurrency(product_invoice.transport.freight_value);
    product_invoice.transport.safe_value_formatted = formatCurrency(product_invoice.transport.safe_value);
    product_invoice.transport.freight_value_formatted = formatCurrency(product_invoice.transport.freight_value);
    product_invoice.transport.other_expenses_formatted = formatCurrency(product_invoice.transport.other_expenses);
    product_invoice.transport.gross_weight = 150;

    product_invoice.items = product_invoice.items.map((item) => {
      return {
        ...item,
        new_item: false
      };
    });

    if (product_invoice.transporter) {
      product_invoice.transporter.document = product_invoice.transporter?.person_type === personType.PHYSICAL
        ? product_invoice.transporter?.cpf : product_invoice.transporter?.cnpj;
      product_invoice.transporter.document_formatted = formatCpfCnpj(product_invoice.transporter?.document);
      product_invoice.transporter.freight_modality_formatted = getText(freights, product_invoice.transporter?.freight_modality);
      product_invoice.transporter.zip_code_formatted = formatZipCode(product_invoice.transporter?.zip_code);
    } else {
      product_invoice.transporter = { freight_modality: 'no_freight' };
    }
  };

  type === typePage.LIST ? formatList(item) : formatShow(item.product_invoice);
};

const formatRequest = (data) => {
  const payload = clone(data);

  const mountDateTime = (date, time) => {
    return `${date.substr(0, 10)} ${time}`;
  };

  payload.emission_date = mountDateTime(payload.emission_date,
    payload.input_output_emission_hour);
  payload.receipt_date = mountDateTime(payload.receipt_date,
    payload.input_output_receipt_hour);
  payload.final_consumer ? payload.final_consumer = finalConsumer.FINAL_CONSUMER :
    payload.final_consumer = finalConsumer.NORMAL;

  payload.client = {
    client_id: payload.client.id,
    ...payload.client,
    ...payload.client.address
  };
  payload.client.id = '';
  payload.client.address = {};
  payload.client.vehicles = [];

  return payload;
};

const formatResponseGetListSelects = (data) => {
  return data;
};

const ProductInvoicesService = DynamicService(ProductInvoiceSchema.domain,
  ProductInvoiceSchema, { formatResponse, formatRequest, removeNullable: false });

const ProductInvoicesCommands = () => ({
  async getListSelects() {
    return await formatResponseGetListSelects(
      axios.post(`/${ProductInvoiceSchema.domain}/get_list_selects`));
  },
  async transmit(payload) {
    return await axios.post(`/${ProductInvoiceSchema.domain}/transmit`, payload);
  },
  async correctionLetter(payload) {
    const { data } = await axios
      .post(`/${ProductInvoiceSchema.domain}/correction-letter`, payload);

    return data;
  },
  async cancelNfe(payload) {
    const { data } = await axios
      .post(`/${ProductInvoiceSchema.domain}/cancel`, payload);

    return data;
  },
  async downloadXml(payload) {
    const { data } = await axios
      .post(`/${ProductInvoiceSchema.domain}/download-xml`, payload);

    return data;
  },
  async downloadXmlBatch(payload) {
    const { data } = await axios
      .post(`/${ProductInvoiceSchema.domain}/download-xml-batch`, payload);

    return data;
  },
  async changeNumberNFe(id, payload) {
    const { data } = await axios
      .put(`/${ProductInvoiceSchema.domain}/${id}/change-nf-number`, payload);

    return data;
  },
  async danfe(id, model) {
    let resourceApi = 'danfe';

    if (model == modelNfEnum.NFCE) {
      resourceApi = 'danfce';
    }
    const { data } = await axios.post(`/${ProductInvoiceSchema.domain}/${resourceApi}`, { id });

    return data;
  }
});

export default ProductInvoicesService;
export { ProductInvoicesService, ProductInvoicesCommands };
