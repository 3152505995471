<template>
  <ModalCardDefault :title="'Duplicar Venda'" @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" :title="'Duplicar Venda'" :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <Row>
          <Col>
            <CheckBox v-model="duplicateItems" @setCheckBox="setDuplicateItems"
              label="Deseja Duplicar os Itens da Venda?" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button label="Duplicar" class="mr-3" rounded color="success"
      @click="changeDuplicateOrder()" />
    </div>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Row,
  Col,
  CheckBox,
  Button,
} from '@/components/vuetify';
import { clone } from 'lodash';

export default {
  name: 'DuplicateOrderConfirmation',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    CheckBox,
    Button,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      panel: [0],
      validForm: false,
      loading: false,
      duplicateItems: false,
      orderSelected: clone(this.order),
    };
  },
  methods: {
    changeDuplicateOrder() {
      this.$router.push({
        name: this.$schemas.order.routes[this.$route.meta.type].create.name,
        params: {
          id: this.orderSelected.id
        },
        query: {
          duplicateOrder: true,
          duplicateItems: this.duplicateItems,
        }
      });
    },
    setDuplicateItems (value) {
      this.duplicateItems = value || false;
    },
  },
  mounted() {
  }
};
</script>

<style>

</style>
