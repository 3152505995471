import { getCurrentPeriod } from '@/utils';
import { accountTypes } from '@/utils/enums';

class Account {
  get model () {
    return {
      type: accountTypes.ACCOUNT_PAYMENT,
      register_id: '',
      register: '',
      title: '',
      value: 0,
      difference: 0,
      total_value: 0,
      paid_value_parcels: 0,
      emission_date: getCurrentPeriod().currentDateEN,
      type_installment: null,
      accounting_account: null,
      cost_center: null,
      parcels: [],
      initial_parcel: 0,
      paid_parcels: [],
      qtd_parcels_paid: 0,
      recalculate_parcels: true,
    };
  }
  get validations () {
    return {};
  }
  get validationsErrors () {
    return {};
  }
}

export default new Account();
