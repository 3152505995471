import { statusCheckControl } from '@/utils/enums';
import { formatCurrency } from '@/utils';

export default {
  beforeList: ({ form }) => {
    form.check_controls.forEach((check_control) => {
      check_control.destroyDisabled = check_control.status === statusCheckControl.PAID;
    });

    const calculateValues = (items, status) => {
      return items.reduce((acc, item) => {
        if (item.status === status) {
          acc += item.value;
        }

        return acc;
      }, 0);
    };

    form.total_check = formatCurrency(form.check_controls.length, { type: 'decimal' });
    form.value_total_check_available = formatCurrency(calculateValues(
      form.check_controls, statusCheckControl.PENDING));
    form.value_total_check_receivable = formatCurrency(calculateValues(
      form.check_controls, statusCheckControl.PAID));
  },
};
