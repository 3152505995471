<template>
  <div>
    <PageHeader :schema="$schemas.productInvoice" />
    <PageContent>
      <Button :label="l.actions.downloadXmlFileBatch" rounded color="secondary"
        @click="openDialog('DownloadXmlBatch', 'downloadXmlBatch')" small :icon="$icons.download" class="py-4" />

      <DynamicListPage ref="DynamicListPage" :schema="$schemas.productInvoice"
        :service="$api.productInvoices"  @actionMoreActions="actionMoreActions" />
      <Row>
        <Col>
          <Chip v-bind="propsChips" class="p-5" :icon="$icons.web" />
          <small v-if="propsChips.status === 'unauthorized'"
           class="mt-5 d-block">{{ messageDataCompany }}</small>
        </Col>
      </Row>
    </PageContent>

    <DialogConfirmation :dialog="dialogConfirmationTransmit"
      :title="'Transmitir NFe'" :message="'Deseja Realmente Transmitir esta NFe?'"
      @noActionDialogConfirmation="closeConfirmationModal" @yesActionDialogConfirmation="transmit"
      :loading="loadingConfirmationTransmit" />

    <Dialog :dialog="dialogModal" :max-width="propsComponentModal.width">
      <template v-slot:content>
        <component :is="componentModal" v-bind="propsComponentModal"
          v-on="eventsComponentModal" />
      </template>
    </Dialog>

    <div id="printMe55" v-if="modelDanfe === $enums.modelPrintDanfe.printMe55" class="d-none">
      <Danfe55 :model="productInvoice" />
    </div>
    <div id="printMe65" v-else class="d-none" style="width: 700px !important;">
      <Danfe65 :model="productInvoice" />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import DialogConfirmation from '@/components/DialogConfirmation';
import Danfe55 from '@/components/Danfe/Danfe55';
import Danfe65 from '@/components/Danfe/Danfe65';
import ProductInvoice from '../model/ProductInvoice';
import { Dialog, Button } from '@/components/vuetify';
import ListCorrectionLetters from '../components/ListProductInvoice/ListCorrectionLetters';
import CreateCorrectionLetter from '../components/ListProductInvoice/CreateCorrectionLetter';
import CancelNFe from '../components/ListProductInvoice/CancelNFe';
import ListEvents from '../components/ListProductInvoice/ListEvents';
import DownloadXmlBatch from '../components/ListProductInvoice/DownloadXmlBatch';
import ChangeNumberNFe from '../components/ListProductInvoice/ChangeNumberNFe';
import GetStatusSefazMixin from '@/mixins/GetStatusSefazMixin';
import ProductInvoiceActionsMixin from '@/mixins/ProductInvoiceActionsMixin';

const ACTIONS_MODAL = Object.freeze({
  downloadXmlBatch: 'downloadXmlBatch',
  events: 'events',
  correctionLetters: 'correctionLetters',
  changeNumberNFe: 'changeNumberNFe',
});

export default {
  name: 'ListProductInvoices',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    DialogConfirmation,
    Danfe55,
    Danfe65,
    Dialog,
    ListCorrectionLetters,
    CreateCorrectionLetter,
    CancelNFe,
    DownloadXmlBatch,
    ChangeNumberNFe,
    ListEvents,
    Button,
  },
  data () {
    return {
      productInvoice: ProductInvoice.model,
      dialogConfirmationTransmit: false,
      loadingConfirmationTransmit: false,
      productInvoiceSelected: {},
      dialogModal: false,
      componentModal: null,
      propsComponentModal: {},
      eventsComponentModal: {},
      modelDanfe: null,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.list;
    }
  },
  mixins: [GetStatusSefazMixin, ProductInvoiceActionsMixin],
  methods: {
    actionMoreActions ({ dataListPropsItem: { item }, moreActionItem }) {
      this.productInvoiceSelected = item;

      if (!moreActionItem.component) {
        this[moreActionItem.action]();

        return;
      }
      this.openDialog(moreActionItem.component, moreActionItem.action);
    },
    openDialog (component, action) {
      this.componentModal = component;
      this.propsComponentModal = {
        ...action !== ACTIONS_MODAL.downloadXmlBatch && {
          width: 1100,
          productInvoiceSelected: this.productInvoiceSelected
        },
        ...action === ACTIONS_MODAL.downloadXmlBatch || action === ACTIONS_MODAL.changeNumberNFe && {
          width: 600,
        },
        ...action === ACTIONS_MODAL.changeNumberNFe && {
          width: 600,
          productInvoiceSelected: this.productInvoiceSelected
        },
        ...action === ACTIONS_MODAL.transmitNFe && {
          width: 400,
        },
        ...action === ACTIONS_MODAL.correctionLetters && {
          width: 1100,
          correctionLetters: this.productInvoiceSelected.correction_letters || [],
        },
        ...action === ACTIONS_MODAL.events && {
          width: 1100,
          events: this.productInvoiceSelected.events || [],
        },
      };

      this.eventsComponentModal = {
        closeModal: this.closeModal,
      };
      this.dialogModal = !this.dialogModal;
    },
    closeModal () {
      this.dialogModal = !this.dialogModal;
      this.$refs.DynamicListPage.fetchDataApi();
    },
    closeConfirmationModal () {
      this.dialogConfirmationTransmit = false;
    },
    openConfirmationModal () {
      this.dialogConfirmationTransmit = !this.dialogConfirmationTransmit;
    },
    async transmit () {
      this.loadingConfirmationTransmit = true;
      this.$api.productInvoices.transmit({ nf_ids: [this.productInvoiceSelected.id] }).then(({ data }) => {
        this.loadingConfirmationTransmit = false;

        if (data.protocol.status_code === '100' || data.protocol.status_code === '104') {
          this.$noty.success('NFe transmitida com sucesso');
        } else {
          this.$noty.error(data.protocol.reason);
        }
      }).catch((err) => {
        let message = err.response.data.error;

        if (err.response.status === this.$enums.HTTP_CODE.SERVER_ERROR) {
          message = 'Erro ao transmitir a NFe';
        }

        this.$noty.error(message);
      }).finally(() => {
        this.loadingConfirmationTransmit = false;
        this.openConfirmationModal();
        this.$refs.DynamicListPage.fetchDataApi();
      });
    },
    downloadXml() {
      const blob = new Blob([this.productInvoiceSelected.xml], {type: 'application/xml'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.productInvoiceSelected.key_nfe}.xml`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>
