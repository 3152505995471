<template>
  <div>
    <PageHeader :schema="$schemas.product" />
    <PageContent>
      <DynamicListPage ref="ListProducts" :schema="$schemas.product" :service="$api.products"
        @actionMoreActions="actionMoreActions" fixed-query
        :fixed-query-params="fixedQueryParams" />

      <Row>
        <Col>
          <Alert color="primary" class="py-2">
            <h5>Qtde. Ítens</h5>
            <h4 v-text="totalizers.totalItems" />
          </Alert>
        </Col>
        <Col>
          <Alert color="primary" class="py-2">
            <h5>Qtde. Volumes</h5>
            <h4 v-text="totalizers.totalVolumes" />
          </Alert>
        </Col>
        <Col>
          <Alert color="primary" class="py-2">
            <h5>Vlr. Total Varejo</h5>
            <h4 v-text="formatCurrency(totalizers.priceRetail)" />
          </Alert>
        </Col>
        <Col>
          <Alert color="primary" class="py-2">
            <h5>Vlr. Total Atacado</h5>
            <h4 v-text="formatCurrency(totalizers.priceWholesale)" />
          </Alert>
        </Col>
      </Row>
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog, Row, Col, Alert } from '@/components/vuetify';
import ListVariations from './components/ListVariations';
import ListVariationStocks from './components/ListVariationStocks';
import CreateStockMovement from '@/views/Products/Stocks/pages/components/ListStocks/CreateStockMovement';
import ListProductVariationStockMovements from './components/ListProductVariationStockMovements';
import { formatCurrency } from '@/utils';

export default {
  name: 'ListProducts',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog,
    Row,
    Col,
    ListVariations,
    ListProductVariationStockMovements,
    Alert,
  },
  data () {
    return {
      dialogModal: false,
      productSelected: [],
      componentModal: null,
      modalProps: {},
      modalEvents: {},
      stocks: [],
      link: '',
      totalizers: {
        totalItems: 0,
        totalVolumes: 0,
        priceRetail: 0,
        priceWholesale: 0,
      },
      loadingTotalizers: true,
      formatCurrency,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.products.list;
    },
    fixedQueryParams () {
      return { no_relations: true };
    }
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    listVariations (item) {
      this.$api.variations.index().then(({ data: { variations } }) => {
        this.componentModal = ListVariations;
        this.productSelected = item;
        this.dialogModal = !this.dialogModal;
        this.modalProps = { maxWidth: 1100, product: this.productSelected, variations: variations };
      }).catch(() => {
        this.$noty.error('Erro ao carregar as Variações');
      });
    },
    async listVariationStocks(item) {
      this.productSelected = item;
      this.$api.productVariations.getProductVariationsByProductId(this.productSelected.id)
        .then(({ data: { product_variations } }) => {
          this.componentModal = ListVariationStocks;
          this.dialogModal = !this.dialogModal;
          this.modalProps = {
            maxWidth: 600,
            productVariationId: product_variations[0].id
          };
        }).catch(() => {
          this.$noty.error('Erro ao carregar as Variação');
        });
    },
    stockMovements(item) {
      this.productSelected = item;
      this.$api.productVariations.getProductVariationsByProductId(this.productSelected.id)
        .then(({ data: { product_variations } }) => {
          this.componentModal = ListProductVariationStockMovements;
          this.dialogModal = !this.dialogModal;
          this.modalProps = {
            maxWidth: 1100,
            title: product_variations[0].name,
            productVariationId: product_variations[0].id
          };
        }).catch(() => {
          this.$noty.error('Erro ao carregar as Variação');
        });

    },
    stockMove(item) {
      const openComponent = () => {
        this.componentModal = CreateStockMovement;
        this.productSelected = item;
        this.dialogModal = !this.dialogModal;

        this.modalProps = {
          stocks: this.stocks,
          productSelected: this.productSelected,
        };
      };

      if (this.stocks.length === 0) {
        this.$api.stocks.index().then((res) => {
          this.stocks = res.data.stocks;
        }).catch(() => {
          this.$noty.error('Erro ao carregar os Estoques');
        }).finally(() => {
          openComponent();
        });
      } else {
        openComponent();
      }

    },
    closeModal () {
      this.dialogModal = false;
      this.componentModal = null;
      this.productSelected = {};
      this.modalProps = {};
      this.$refs.ListProducts.fetchDataApi();
      this.getTotalizers();
    },
    getTotalizers() {
      this.$api.productVariations.getTotalizers().then(({ data }) => {
        this.totalizers = {
          totalItems: data.total_items,
          totalVolumes: data.total_volumes,
          priceRetail: data.price_retail,
          priceWholesale: data.price_wholesale,
        };
      }).catch(() => {
        this.$noty.error('Erro ao carregar os Totalizadores');
      }).finally(() => {
        this.loadingTotalizers = false;
      });
    },
  },
  mounted() {
    this.getTotalizers();
  }
};
</script>
