<template>
  <div>
    <v-form v-model="validInfoGenerals" ref="formInfoGenerals" lazy-validation>
      <Row class="border-all-row">
        <Col md="8" class="py-0 d-flex flex-column justify-center border-right-col">
          <p class="caption" v-text="l.natureOperation" />
          <p v-if="isAuthorized" v-text="model.nature_operation.name" />
          <Select v-else v-model="natureOperationId" :items="natureOperations"
            :outlined="false" class="select-custom" item-value="id" item-text="name"
            :rules="[rules.required]" @change="setNatureOperation"
            :readonly="natureOperationIsReadonly" />
        </Col>
        <Col class="py-0 d-flex flex-column justify-center">
          <p class="caption" v-text="l.protocolNumber" />
          <p class="font-weight-regular" v-html="getValue(model.protocol_number)" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="3" class="py-0 d-flex flex-column justify-center ">
          <p class="caption" v-text="l.ie" />
          <p class="font-weight-regular" v-html="getValue(model.emitter.ie)" />
        </Col>
        <Col md="3" class="py-0 border-x-col d-flex flex-column justify-center">
          <p class="caption" v-text="l.ie_st" />
          <p class="font-weight-regular" v-html="getValue(model.emitter.ie_st)" />
        </Col>
        <Col md="3" class="py-0 d-flex flex-column justify-center border-right-col">
          <p class="caption" v-text="l.suframa" />
          <p class="font-weight-regular" v-html="getValue(model.emitter.suframa)" />
        </Col>
        <Col md="3" class="py-0 d-flex flex-column justify-center">
          <p class="caption" v-text="l.cnpj" />
          <p class="font-weight-regular" v-html="getValue(model.emitter.cnpj)" />
        </Col>
      </Row>
    </v-form>

    <DialogConfirmation :dialog="dialogConfirmation"
      @yesActionDialogConfirmation="redirectCompany"
      @noActionDialogConfirmation="closeDialogConfirmation"
      title="Não é possível criar uma Nota Fiscal"
      message="Para criar uma nova Nota Fiscal é preciso cadastrar todos os dados da sua Empresa. Deseja fazer isso agora?" />

    <Dialog :dialog="dialog">
      <InfosGeneralsModal slot="content" :model="model"
        @update:dialog="dialog = $event" />
    </Dialog>
  </div>
</template>

<script>
import DialogConfirmation from '@/components/DialogConfirmation';
import {
  Row,
  Col,
  Select,
  Dialog,
} from '@/components/vuetify';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import InfosGeneralsModal from './InfosGeneralsModal';
import TypePageMixin from '@/mixins/TypePageMixin';
import { typePage } from '@/utils/enums';
import { getCompany } from '@/utils';

export default {
  name: 'InfosGenerals',
  components: {
    Row,
    Col,
    Select,
    Dialog,
    InfosGeneralsModal,
    DialogConfirmation,
  },
  props: {
    natureOperations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      validInfoGenerals: false,
      dialog: false,
      natureOperationId: null,
      dialogConfirmation: false,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.infosGenerals;
    },
    companyId () {
      return getCompany().id;
    },
    natureOperationIsReadonly () {
      return this.typePage === typePage.SHOW;
    },
  },
  watch: {
    'model.nature_operation_id' () {
      this.natureOperationId = this.model.nature_operation_id;
    }
  },
  mixins: [CreateUpdateProductInvoiceMixin, TypePageMixin],
  methods: {
    setNatureOperation () {
      this.model.nature_operation_id = this.natureOperationId;
      this.$emit('setNatureOperation', this.natureOperationId);
    },
    redirectCompany () {
      this.$router.push({ name: this.$schemas.company.routes.show.name,
        params: { id: this.companyId } });
    },
    closeDialogConfirmation () {
      this.dialogConfirmation = false;
      this.$router.push({ name: this.$schemas.productInvoice.routes.list.name });
    }
  },
  async mounted () {
    const { data: { company: { address } } } = await this.$api.auth.me();
    setTimeout(() => {
      if (!address) {
        this.dialogConfirmation = true;
      }
    }, 500);

    if (this.typePage === typePage.CREATE) {
      this.dialog = true;
    }
  }
};
</script>
