<template>
  <div>
    <PageHeader :schema="$schemas.priceList" />
    <PageContent>
      <DynamicListPage :schema="$schemas.priceList" :service="$api.priceLists"
        @actionMoreActions="actionMoreActions" />
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @update:dialogModal="dialogModal = $event" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Dialog } from '@/components/vuetify';
import ListProducts from './components/ListPrices/ListProducts';
import AddProduct from './components/ListPrices/AddProduct';

export default {
  name: 'ListPriceLists',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Dialog,
    ListProducts,
    AddProduct,
  },
  data () {
    return {
      dialogModal: false,
      modalProps: {
        maxWidth: 1000,
        priceList: null,
      },
      componentModal: null,
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    productsList (item) {
      this.modalProps = {
        maxWidth: 1000,
        priceList: item,
      };

      this.dialogModal = true;
      this.componentModal = ListProducts;
    },
    addProduct(item) {
      this.modalProps = {
        maxWidth: 1000,
        priceList: item,
      };

      this.dialogModal = true;
      this.componentModal = AddProduct;
    }
  }
};
</script>
