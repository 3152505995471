<template>
  <div>
    <PageHeader :schema="$schemas.accountingAccount" />
    <PageContent>
      <DynamicListPage :schema="$schemas.accountingAccount"
        :service="$api.accountingAccounts" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListAccountingAccounts',
  components: { PageHeader, PageContent, DynamicListPage },
};
</script>
