<template>
  <div>
    <PageHeader :schema="$schemas.report" :titleCustom="title" :descriptionCustom="description" />
    <PageContent>
      <DynamicFormPage :schema="$schemas.report" :service="$api.reports" :fixed-payload="fixedPayload" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicFormPage from '@/components/dynamics/DynamicFormPage';

export default {
  name: 'CreateReports',
  components: { PageHeader, PageContent, DynamicFormPage },
  computed: {
    fixedPayload () {
      return { ...this.$route.meta.payloadFixed };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    }
  }
};
</script>
