import router from '@/router';
import axios from './index';
import { messageErrors, getText, removeDefaultKeysPayload } from '@/utils';
import { typeSituation } from '@/utils/options';
import { typePage } from '@/utils/enums';

const DynamicService = (endpoint, schema, options) => ({
  get schema() {
    return schema;
  },
  get options() {
    return options;
  },
  mountFilter(filter) {
    let paramsFilter = '';
    Object.keys(filter).forEach((key) => {
      paramsFilter += `&filter[${key}]=${filter[key]}`;
    });

    return paramsFilter;
  },
  mountPaginateParamsUrl(endpoint, paginate, sort) {
    let url = `${endpoint}?page=${paginate.page}&per_page=${paginate.perPage}`;

    if (sort) {
      url += `&sort_by=${sort.sortBy}&sort=${sort.sortDesc}`;
    }

    return url;
  },
  async index(data = { paginate: { page: 1, perPage: 10 }, sort: null }) {
    const { paginate, sort } = data;
    let items = {};
    let url = this.mountPaginateParamsUrl(endpoint, paginate, sort);
    await axios.get(url).then((res) => {
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        if (res.data?.data) {
          res.data.data[schema.domain].forEach((item) => {
            options.formatResponse(item);
          });
        } else {
          res.data[schema.domain].forEach((item) => {
            options.formatResponse(item);
            item.situation_formatted = getText(typeSituation, item.situation);
          });
        }
      }

      items = res;
    }).catch((err) => {
      console.error(`DynamicService Index error: ${err}`);
    });

    return items;
  },
  async filters({ url }) {
    let items = {};
    await axios.get(url).then((res) => {
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        if (res.data?.data) {
          res.data.data[schema.domain].forEach((item) => {
            options.formatResponse(item);
          });
        } else {
          res.data[schema.domain].forEach((item) => {
            options.formatResponse(item);
            item.situation_formatted = getText(typeSituation, item.situation);
          });
        }
      }

      items = res;
    }).catch((err) => {
      console.error(`DynamicService Index error: ${err}`);
    });

    return items;
  },
  async show(id) {
    return new Promise((resolve, reject) => {
      let item = {};
      axios.get(`${endpoint}/${id}`).then((res) => {
        item = res.data;

        if (options.formatResponse && typeof options.formatResponse === 'function') {
          options.formatResponse(item, typePage.SHOW);
        }
        resolve(item);
      }).catch((err) => {
        console.error(`DynamicService Show error: ${err}`);

        if (err.response.status == 404) {
          router.push({ name: this.schema.routes.list.name });
          reject(`${this.schema.title} não localizado`);
        }
        reject(messageErrors(err.response));
      });
    });
  },
  async create(params) {
    return new Promise((resolve, reject) => {
      if (options.formatRequest && typeof options.formatRequest === 'function') {
        params = options.formatRequest(params);
      }

      if (!options.notRemoveDefaultKeys) {
        params = removeDefaultKeysPayload(params, this.options.removeNullable);
      }
      axios.post(`${endpoint}`, params).then(({ data }) => {
        resolve(data);
      }).catch((err) => {
        reject(messageErrors(err.response));
      });
    });
  },
  async update(id, params) {
    return new Promise((resolve, reject) => {
      if (options.formatRequest && typeof options.formatRequest === 'function') {
        params = options.formatRequest(params, typePage.SHOW);
      }

      if (!options.notRemoveDefaultKeys) {
        params = removeDefaultKeysPayload(params, this.options.removeNullable);
      }

      axios.put(`${endpoint}/${id}`, params).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(messageErrors(err.response));
      });
    });
  },
  async createOrUpdateFile(id, params, type = 'post') {
    return new Promise((resolve, reject) => {
      let payload = new FormData();
      payload.append('file', true);

      Object.keys(params).forEach((key) => {
        payload.append(String(key), params[key]);
      });

      const method_put = type === 'put' ? '?_method=PUT' : '';
      const url = `${endpoint}/${id}${method_put}`;

      axios.post(url, payload).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(messageErrors(err.response));
      });
    });
  },
  async delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${endpoint}/${id}`).then(() => {
        resolve(true);
      }).catch((err) => {
        console.log(messageErrors(err.response));
        reject(messageErrors(err.response));
      });
    });
  },
  async generatePDF(path, payload, isBlob = true) {
    return new Promise((resolve, reject) => {
      axios.post(`${endpoint}/${path}`, payload, { ...isBlob && { responseType: 'blob' } }).then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(messageErrors(err.response));
      });
    });
  },
  async synchronizerList() {
    return new Promise((resolve, reject) => {
      const payload = {
        domain: schema.domainSingle,
        page: 1,
        per_page: 10,
      };
      axios.post('synchronizer-caches', payload).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(messageErrors(err.response));
      });
    });
  }
});

export default DynamicService;
