<template>
  <div>
    <PageHeader :schema="$schemas.register" :titleCustom="title" :descriptionCustom="description" />
    <PageContent>
      <DynamicFormPage :schema="$schemas.register" :service="$api.registers" :fixed-payload="fixedPayload" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicFormPage from '@/components/dynamics/DynamicFormPage';

export default {
  name: 'CreateRegister',
  components: { PageHeader, PageContent, DynamicFormPage },
  computed: {
    fixedPayload () {
      return { type: this.$route.meta.type };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    }
  }
};
</script>
