export default {
  domain: '',
  title: 'Página Inicial',
  description: '',
  createBtn: '',
  routes: {
    home: {
      name: 'home',
      path: '/',
      meta: { typePage: null, requiresAuth: true, }
    },
  },
};