<template>
  <ModalCardDefault :title="l.title" @close="$emit('closeModal')">
    <ExpansionPanel v-model="panel" :title="l.dataTable.title"
      :icon="$icons.box" readonly>
      <DataTable no-search no-sync :items="events"
        :headers="this.l.dataTable.headers" :slots="slots" :loading="loadingDataTable">
        <template v-slot:event="{ props: { item } }">
          <span v-text="l.dataTable.types[item.type]" />
        </template>
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  DataTable,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'ListEvents',
  components: {
    ExpansionPanel,
    ModalCardDefault,
    DataTable,
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      loadingDataTable: false,
      slots: [{ field: 'event' }],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.list.events;
    },
  },
  methods: {
  },
};
</script>
