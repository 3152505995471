<template>
  <div>
    <span class="d-block mt-3"><b>{{ l.title }}</b></span>
    <Row class="border border-dark">
      <Col md="4" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.name" />
        <span class="" v-text="model.transporter.name" />
      </Col>
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.document" />
        <span class="" v-text="document" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.ie" />
        <span class="" v-text="model.transporter.ie" />
      </Col>
      <Col md="3" class="d-flex flex-column">
        <span class="" v-text="l.freightModality" />
        <span class="" v-text="model.modality_freight" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.zipCode" />
        <span class="" v-text="zipCode" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.country" />
        <span class="" v-text="model.transporter.country" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.uf" />
        <span class="" v-text="model.transporter.uf" />
      </Col>
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.city" />
        <span class="" v-text="model.transporter.city" />
      </Col>
      <Col md="3" class="d-flex flex-column">
        <span class="" v-text="l.neighborhood" />
        <span class="" v-text="model.transporter.neighborhood" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.street" />
        <span class="" v-text="model.transporter.street" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.number" />
        <span class="" v-text="model.transporter.number" />
      </Col>
      <Col md="3" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.complement" />
        <span class="" v-text="model.transporter.complement" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.plate" />
        <span class="" v-text="model.transporter.vehicle_plate" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.vehicleUf" />
        <span class="" v-text="model.transporter.vehicle_uf" />
      </Col>
    </Row>
    <Row class="border-right border-left border-bottom border-dark">
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.rntc" />
        <span class="" v-text="model.transporter.vehicle_rntc" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.liquidWeight" />
        <span class="" v-text="model.transport.liquid_weight" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.grossWeight" />
        <span class="" v-text="model.transport.gross_weight" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.brand" />
        <span class="" v-text="model.transport.brand" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span class="" v-text="l.specie" />
        <span class="" v-text="model.transport.specie" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span class="" v-text="l.quantity" />
        <span class="" v-text="model.transport.quantity" />
      </Col>
    </Row>
  </div>
</template>

<script>
import { personType } from '@/utils/enums';
import { formatCpfCnpj, formatZipCode } from '@/utils';

import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55Transporter',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.transporter;
    },
    document () {
      let document = '';
      this.model.transporter.person_type === personType.PHYSICAL ?
        document = this.model.transporter.cpf :
        document = this.model.transporter.cnpj;

      return formatCpfCnpj(document);
    },
    zipCode () {
      return formatZipCode(this.model.transporter.zip_code);
    },
  },
};
</script>
