<script>
import IsDevolutionMixin from '@/views/Movements/Orders/mixins/IsDevolutionMixin';
import  { v4 as uuidv4 } from 'uuid';

export default {
  mixins: [IsDevolutionMixin],
  methods: {
    addItem(data) {
      if (data.new_item) {
        data.id = uuidv4();
      }

      if (this.isDevolution && !this.model.is_custom_items) {
        this.model.items.push(...data.map((item) => {
          return {
            ...item,
            quantity: item.qtd_devolution,
            devolution_origin_order_id: item.order_id,
            devolution_origin_order_item_id: item.id,
            devolution_origin_quantity: item.quantity,
            new_item: true,
          };
        }));
      } else {
        this.model.items.push(data);
      }

      this.updateItem();
    },
    editItem({ index, data }) {
      this.removeItem ({ index, item: data });
      this.addItem(data);
    },
    removeItem ({ index, item }) {
      this.model.items.splice(index, 1);
      this.model.deleted_items.push({ id: item.id });
      this.updateItem();
    },
    updateItem () {
      this.model.items = this.model.items.map((item, index) => {
        return {
          ...item,
          item_number: (index + 1)
        };
      });
    },
  }
};
</script>
