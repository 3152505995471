export const menuItemsGeneralRegisters = [
  {
    active: false,
    menuGroupName: 'generalRegisters',
    icon: 'plus',
    title: 'Cadastros',
    items: [
      { icon: 'accountTie', title: 'Clientes', route: 'clients', active: false },
      { icon: 'accountCreditCardOutline', title: 'Fornecedores', route: 'providers', active: false },
      { icon: 'car', title: 'Transportadoras', route: 'transporters', active: false },
      { icon: 'accountWrench', title: 'Vendedores', route: 'sellers', active: false },
      { icon: 'accountWrench', title: 'Funcionários', route: 'employees', active: false },
    ],
  },
];
