export const menuItemsTaxOperations = [
  {
    active: false,
    menuGroupName: 'taxOperations',
    icon: 'receiptTextOutline',
    title: 'Notas Fiscais',
    subtitle: 'Operações Fiscais',
    items: [
      { icon: 'fileDocumentCheckOutline', title: 'NF Produtos (NF-e)', route: 'product-invoices', active: false },
      { icon: 'receiptTextCheckOutline', title: 'NF Serviços (NFS-e)', route: 'service-invoices', active: false },
    ],
  },
];
