<template>
  <div>
    <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
      <Row class="mt-0 border-all-row">
        <Col md="12" class="py-0 d-flex flex-column justify-center">
          <ItemsDataList :client-id="model.client_id" :items="model.items"
            :is-custom-items="model.is_custom_items"
            :nature-operation-id="model.nature_operation_id" :stock-required="true"
            :stock-id="model.stock_id" :price-list-id="model.price_list_id"
            @addItem="addItem" @editItem="editItem" @removeItem="removeItem" :is-wholesale="model.is_wholesale"
            :no-destroy="noDestroy" :disabled-new-item-button="isDelivered" />
        </Col>
      </Row>
    </ExpansionPanel>
  </div>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
} from '@/components/vuetify';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';
import ItemsDataList from '@/components/OrderProductInvoice/ItemsDataList';
import OrderProductInvoiceMixin from '@/mixins/OrderProductInvoiceMixin';

export default {
  name: 'OrderItems',
  components: {
    ExpansionPanel,
    Row,
    Col,
    ItemsDataList,
  },
  inject: ['propsComponents'],
  data () {
    return {
      panel: [0],
      tab: null,
      tabItems: ['Itens do Pedido', 'Impostos'],
      validFormFreight: false,
      localForm: this.form,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.items;
    },
    propsSaleItems () {
      return {
        form: this.propsComponents.form,
        products: this.propsComponents.products,
      };
    },
    noDestroy () {
      return this.model.status === this.$enums.statusOrders.FINISHED;
    },
  },
  watch: {
    'model.items': {
      handler () {
        this.updateInfosOrderSale();
      },
      deep: true,
    }
  },
  mixins: [UpdateInfosOrderSaleMixin, OrderProductInvoiceMixin],
};
</script>
