import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import BankSchema from '../schemas/BankSchema';
import { formatCurrency, getText, formatDatePT } from '@/utils';
import { typeInputOutput, originTypeBankMovements } from '@/utils/options';

const formatResponse = (item) => {
  item.balance_formatted = formatCurrency(item.balance);
};

const formatResponseListMovements = (data) => {
  data.bank_movements.forEach((item) => {
    item.movement_date_formatted = formatDatePT(item.movement_date);
    item.movement_type_formatted = getText(typeInputOutput, item.movement_type);
    item.originable_type_formatted = getText(originTypeBankMovements, item.originable_type);
    item.payment_method_formatted = item.payment_method.name;
    item.value_formatted = formatCurrency(item.value);
  });

  return data;
};

const BanksService = DynamicService('banks', BankSchema, { formatResponse });

const BankMovementsCommands = () => ({
  async createMovement(bank_id, bank_movement) {
    return await axios.post(`banks/${bank_id}/movements`, bank_movement);
  },
  async listMovements(bank_id, syncOptions = {}) {
    const { page, itemsPerPage } = syncOptions;
    const { data } = await axios.get(`banks/${bank_id}/movements?&page=${page || 1}&per_page=${itemsPerPage || 6}`);

    return formatResponseListMovements(data);
  },
  async deleteMovement(bank_id, bank_movement_id) {
    return await axios.delete(`banks/${bank_id}/movements/${bank_movement_id}`);
  },
});

export default BanksService;
export { BanksService, BankMovementsCommands };
