<template>
  <div>
    <ModalCardDefault :title="'Resumo Financeiro'" @close="$emit('close')">
        <div class="content-internal">
          <Row>
            <Col>
              <ExpansionPanel v-model="panel" :title="'Movimentações'" :icon="$icons.box" readonly>
                <div class="content-interna--item">
                  <span>Entradas</span>
                  <span v-text="formatCurrency(summary.movements.inputs)" />
                </div>
                <div class="content-interna--item">
                  <span>Suprimentos</span>
                  <span v-text="formatCurrency(summary.movements.supply)" />
                </div>
                <div class="content-interna--item">
                  <span>Sangria</span>
                  <span v-text="formatCurrency(summary.movements.withdrawal)" />
                </div>
                <div class="content-interna--item">
                  <span>Saídas</span>
                  <span v-text="formatCurrency(summary.movements.output)" />
                </div>
                <div class="content-interna--item">
                  <span>Total Geral</span>
                  <span v-text="formatCurrency(summary.movements.total)" />
                </div>
              </ExpansionPanel>
              <ExpansionPanel class="mt-5" v-model="panel" :title="'Formas de Pagamentos'" :icon="$icons.box" readonly>
                <div class="content-interna--item" v-for="(item, index) in summary.payment_methods" :key="index">
                  <span v-text="item.payment_method_name" />
                  <span v-text="formatCurrency(item.total_value)" />
                </div>
              </ExpansionPanel>
            </Col>
          </Row>
        </div>

    </ModalCardDefault>
  </div>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import { ExpansionPanel, Row, Col } from '@/components/vuetify';
import { formatCurrency } from '@/utils';

export default {
  name: 'FinancialSummary',
  components: { ModalCardDefault, ExpansionPanel, Row, Col },
  props: {
    summary: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      panel: [0],
      formatCurrency,
    };
  },
};
</script>

<style scoped lang="scss">
.content-internal {
  padding: 20px;

  .content-interna--item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
</style>
