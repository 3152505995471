import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import CreditLetterSchema from '../schemas/CreditLetterSchema';
import { formatCurrency, getText, formatDatePT } from '@/utils';
import { creditLetterStatus } from '@/utils/options';

const formatResponse = (item) => {
  item.created_at_formatted = formatDatePT(item.created_at);
  item.value_formatted = formatCurrency(item.value);
  item.usage_value_formatted = formatCurrency(item.usage_value);
  item.balance_formatted = formatCurrency(item.balance);
  item.status_formatted = getText(creditLetterStatus, item.status);
};

const CreditLettersService = DynamicService('credit_letters',
  CreditLetterSchema, { formatResponse });

const CreditLettersCommands = () => ({
  async cancel(id) {
    await axios.post(`${CreditLetterSchema.domain}/${id}/cancel`);
  },
});

export default CreditLettersService;
export { CreditLettersService, CreditLettersCommands };
