<template>
  <div>
    <div :class="['content-main-title', height >= 500 ? 'd-flex' : '']">
      <div>
        <p class="title my-0" v-text="title" />
        <h5 :class="['subtitle', height < 500 ? 'mb-5' : '']" v-text="description" />
      </div>

      <div>
        <slot />
        <Button
          color="primary"
          v-if="(schema.createBtn && typePage === typePageOptions.list) || createBtn"
          rounded
          :label="createBtnLabel"
          :icon="icons.plusOutline"
          :class="[height < 500 ? 'btnWidth' : '']"
          @click="$router.push({ name: createBtnRouteName })" />

        <Button
          color="light"
          v-if="typePage === typePageOptions.create || typePage === typePageOptions.show"
          label="Voltar"
          rounded
          :icon="icons.arrowLeft"
          :class="[height < 500 ? 'btnWidth' : '']"
          @click="$router.back()" />
      </div>
    </div>
    <Divider class="mt-2" />
  </div>
</template>

<script>
import TypePageMixin from '@/mixins/TypePageMixin';
import { plusOutline, arrowLeft } from '@/utils/icons';
import Button from '@/components/vuetify/Button';
import Divider from '@/components/vuetify/Divider';
import BreakPointMixin from '@/mixins/BreakPointMixin';

export default {
  name: 'PageHeader',
  components: { Button, Divider },
  props: {
    schema: {
      type: Object,
      required: true
    },
    createBtn: {
      type: String,
      default: null
    },
    createRouteName: {
      type: String,
      default: null
    },
    titleCustom: {
      type: String,
      default: null
    },
    descriptionCustom: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      icons: {
        plusOutline: plusOutline,
        arrowLeft: arrowLeft,
      }
    };
  },
  computed: {
    createBtnLabel() {
      return this.createBtn || this.schema.createBtn;
    },
    createBtnRouteName() {
      return this.createRouteName || this.schema.routes.create.name;
    },
    title() {
      return this.titleCustom || this.schema.title;
    },
    description() {
      return this.descriptionCustom || this.schema.description;
    },
  },
  mixins: [TypePageMixin, BreakPointMixin],
};
</script>

<style scoped>
.btnWidth {
  width: 100% !important;
}

.title {
  font-size: 35px !important;
}

@media only screen and (min-width: 320px) and (max-width: 1280px) {
  .title {
    font-size: 25px !important;
  }
}
</style>
