export default {
  title: 'Novo Veículo',
  fields: {
    plate: {
      label: 'Placa'
    },
    uf: {
      label: 'UF'
    },
    rntc: {
      label: 'RNTC'
    },
  },
  actions: {
    create: 'Salvar',
    close: 'Fechar'
  },
  noty: {
    errorList: 'Erro ao Criar o Veículo',
  }
};
