import NatureOperationSchema from './schemas/NatureOperationSchema';
import ListNatureOperations from './pages/ListNatureOperations';
import CreateNatureOperation from './pages/CreateNatureOperation';

export default [
  {
    path: NatureOperationSchema.routes.list.path,
    name: NatureOperationSchema.routes.list.name,
    component: ListNatureOperations,
    meta: NatureOperationSchema.routes.list.meta,
  },
  {
    path: NatureOperationSchema.routes.create.path,
    name: NatureOperationSchema.routes.create.name,
    component: CreateNatureOperation,
    meta: NatureOperationSchema.routes.create.meta
  },
  {
    path: NatureOperationSchema.routes.show.path,
    name: NatureOperationSchema.routes.show.name,
    component: CreateNatureOperation,
    meta: NatureOperationSchema.routes.show.meta
  },
];