export const menuItemsProducts = [
  {
    active: false,
    menuGroupName: 'products',
    icon: 'box',
    title: 'Produtos',
    items: [
      { icon: 'evPlugType1', title: 'Categorias', route: 'categories', params: { query: { type: 'category' } }, active: false },
      { icon: 'evPlugType2', title: 'SubCategorias', route: 'subCategories', params: { query: { type: 'sub-category' } }, active: false },
      { icon: 'pencilBoxMultipleOutline', title: 'Lista de Preços', route: 'priceLists', active: false },
      { icon: 'barcode', title: 'Produtos', route: 'products', active: false },
      { icon: 'pencilBoxMultipleOutline', title: 'Variações', route: 'variations', active: false },
      { icon: 'archiveArrowDownOutline', title: 'Estoques', route: 'stocks', active: false },
      { icon: 'swapHorizontal', title: 'Mov. Estoques', route: 'stockMovements', active: false },
      { icon: 'swapHorizontal', title: 'Mov. Produtos', route: 'productsMovements', active: false },
      { icon: 'scaleUnbalanced', title: 'Balanços', route: 'balances', active: false },
    ],
  },
];
