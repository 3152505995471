<template>
  <div>
    <PageHeader :schema="$schemas.productVariationStockMovement" />
    <PageContent>
      <DynamicListPage :schema="$schemas.productVariationStockMovement"
        :service="$api.productVariationStockMovements" :slots="listSlots">

        <template v-slot:movement_type="{ props: { item: { movement_type } } }">
          <Chip small :color="getColorChip(movement_type)"
            :label="l.movementType[movement_type]" />
        </template>
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Chip } from '@/components/vuetify';

export default {
  name: 'ListStockMovements',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Chip,
  },
  data () {
    return {
      listSlots: [
        { field: 'movement_type' },
      ]
    };
  },
  computed: {
    l () {
      return this.$locales.pt.stockMovements.list;
    },
  },
  methods: {
    getColorChip (movementType) {
      return movementType === 'input' ? 'success' : 'error';
    },
  }
};
</script>
