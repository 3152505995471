import { getUser, getUserSuper } from '@/utils';

export default {
  beforeList: ({ form }) => {
    const user = getUser();
    const userSuper = getUserSuper();

    form.companies.forEach((order) => {
      order.disableItemMoreActions = {
        loginAsUser: user.type != userSuper.type,
        logoutAsUser: user.type === userSuper.type,
      };
    });
  },
};
