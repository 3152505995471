<template>
  <div>
    <ModalCardDefault :title="'Ciclos da Assinatura'" @close="$emit('close')">
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <ExpansionPanel v-model="panel" :title="`Ciclos`"
          :icon="$icons.box" readonly>
          <DataTable no-search no-sync hide-default-footer :items="subscriptionSelected.cycles"
            :headers="headers" :loading="loadingDataTable">
          </DataTable>
          <Row class="">
            <Col>

            </Col>
          </Row>
        </ExpansionPanel>
      </v-form>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  DataTable,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { formatCurrency } from '@/utils';

export default {
  name: 'CyclesSubscription',
  components: {
    ExpansionPanel,
    Row,
    Col,
    ModalCardDefault,
    DataTable,
  },
  props: {
    subscriptionSelected: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      utils: {
        formatCurrency,
      },
      validForm: true,
      loadingDataTable: false,
    };
  },
  computed: {
    headers () {
      return [
        { text: 'Cicle', value: 'current_cycle_cycle' },
        { text: 'Período', value: 'interval_formatted' },
        { text: 'Início', value: 'current_cycle_start_at_formatted' },
        { text: 'Fim', value: 'current_cycle_end_at_formatted' },
        { text: 'Data Pagamento Atual', value: 'current_cycle_billing_at_formatted' },
        { text: 'Data Próximo Pagamento', value: 'next_billing_at_formatted' },
        { text: 'Valor', value: 'price_formatted' },
        { text: 'Status', value: 'current_cycle_status_formatted' },
      ];
    }
  },
  methods: {
  },
  mounted () {
  }
};
</script>

<style>

</style>
