<template>
  <div>
    <PageHeader :schema="$schemas.bank" />
    <PageContent>
      <DynamicListPage :schema="$schemas.bank"
      :service="$api.banks" @actionMoreActions="actionMoreActions"
      ref="listBanks"/>
    </PageContent>

    <Dialog :dialog="dialogMovements" no-title: :maxWidth="1250">
      <ListBankMovements slot="content"
        :bank="bankSelected"
        :movements="movements" :loadingTable="loadingTable"
        @listMovements="listMovements"
        @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import Dialog from '@/components/vuetify/Dialog';
import ListBankMovements from './components/ListBankMovements';

export default {
  name: 'ListBanks',
  components: { PageHeader, PageContent, DynamicListPage, Dialog, ListBankMovements },
  data () {
    return {
      dialogMovements: false,
      movements: [],
      bankSelected: {},
      loadingTable: false,
    };
  },
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this.bankSelected = item;
      this[moreActionItem.action](item);
    },
    async listMovements(syncOptions = {}) {
      this.loadingTable = true;
      this.$api.banks.listMovements(this.bankSelected.id, syncOptions).then((res) => {
        this.movements = {
          ...res,
          data: res.bank_movements
        };
        this.dialogMovements = true;
      }).catch((err) => {
        this.$noty.error(err);
      }).finally(() => {
        this.loadingTable = false;
      });
    },
    async closeModal() {
      await this.$refs.listBanks.index();
      this.bankSelected = {};
      this.dialogMovements = false;
    }
  }
};
</script>
