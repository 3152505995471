/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const darModeLocalStorage = localStorage.getItem(`${process.env.VUE_APP_NAME}.themeMode`);

const state = {
  darkMode: {
    value: darModeLocalStorage === 'dark',
    label: darModeLocalStorage === 'dark' ? 'Modo Escuro' : 'Modo Claro'
  },
  companiesByCustomer: JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.companiesByCustomer`)) || [],
  companyIdLogged: localStorage.getItem(`${process.env.VUE_APP_NAME}.companyIdLogged`)
};

const mutations = {
  SET_DARK_MODE(state, payload) {
    state.darkMode = payload;
  },
  SET_COMPANIES_BY_CUSTOMER(state, payload) {
    state.companiesByCustomer = payload;
  },
  SET_COMPANY_ID_LOGGED(state, payload) {
    state.companyIdLogged = payload;
  }
};

const actions = {
  setDarkMode(context, value) {
    context.commit('SET_DARK_MODE', value);
  },
  setCompaniesByCustomer(context, value) {
    context.commit('SET_COMPANIES_BY_CUSTOMER', value);
  },
  setCompanyIdLogged(context, value) {
    localStorage.setItem(`${process.env.VUE_APP_NAME}.companyIdLogged`, value);
    context.commit('SET_COMPANY_ID_LOGGED', value);
  }
};

const getters = {
  getDarkMode(state) {
    return state.darkMode;
  },
  getCompaniesByCustomer(state) {
    return state.companiesByCustomer;
  },
  getCompanyIdLogged(state) {
    return state.companyIdLogged;
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
