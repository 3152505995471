export const TYPE_USER = Object.freeze({
  ADMIN: 'admin',
  MANAGER: 'manager',
  USER: 'user',
  SUPER: 'super',
  DISTRIBUTOR: 'distributor',
  RESELLER: 'reseller',
  CUSTOMER: 'customer',
});

export const TYPE_CUSTOMER = Object.freeze({
  DISTRIBUTOR: 'distributor',
  RESELLER: 'reseller',
  CUSTOMER: 'customer',
});

export const typeYesNo = Object.freeze({
  YES: 'yes',
  NO: 'no',
});

export const situation = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

export const status = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  SUSPENDED: 'suspended',
});

export const typePage = Object.freeze({
  LIST: 'list',
  CREATE: 'create',
  SHOW: 'show',
});

export const typeComponentModalVariations = Object.freeze({
  CREATE_VARIATION: 'createVariation',
  EDIT_VARIATION: 'editVariation',
  DELETE_VARIATION: 'deleteVariation',
  LIST_VARIATION_STOCKS: 'listVariationStocks',
  LIST_STOCK_MOVEMENTS: 'listStockMovements',
});

export const typeStockMovements = Object.freeze({
  INPUT: 'input',
  OUTPUT: 'output',
});

export const operationType = Object.freeze({
  INPUT: 'input',
  OUTPUT: 'output',
});

export const freights = Object.freeze({
  ISSUER: 'issuer',
  RECIPIENT: 'recipient',
  THIRD_PARTY: 'third_party',
  OWN_ISSUER: 'own_issuer',
  OWN_RECIPIENT: 'own_recipient',
  NO_FREIGHT: 'no_freight',
});

export const orderTypes = Object.freeze({
  ORDER_SALE: 'order-sale',
  ORDER_PURCHASE: 'order-purchase',
  ORDER_BUDGET: 'order-budget',
  ORDER_SALE_DEVOLUTION: 'order-sale-devolution',
  ORDER_PURCHASE_DEVOLUTION: 'order-purchase-devolution',
  ORDER_SALE_PDV: 'order-sale-pdv',
  ORDER_SALE_CONSIGNED: 'order-sale-consigned',
});

export const accountTypes = Object.freeze({
  ACCOUNT_PAYMENT: 'account-payment',
  ACCOUNT_RECEIVABLE: 'account-receivable',
});

export const statusAccounts = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
  DELAYED: 'delayed',
  PAIR: 'pair',
});

export const statusAccountParcels = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
  PARTIAL: 'partial'
});

export const statusAccountParcelPayments = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
});

export const deliveredSituation = Object.freeze({
  DELIVERED: 'delivered',
  NOT_DELIVERED: 'notDelivered',
});

export const typeAllTypes = Object.freeze({
  PAYMENT_METHOD: 'payment-method',
  UNIT_MEASURE: 'unit-measurement',
  CATEGORY: 'category',
  SUB_CATEGORY: 'sub-category',
  NCM: 'ncm',
  CEST: 'cest',
});

export const finalConsumer = Object.freeze({
  NORMAL: 'normal',
  FINAL_CONSUMER: 'final_consumer',
});

export const emissionFinality = Object.freeze({
  NORMAL: 'normal',
  COMPLEMENTARY: 'complementary',
  ADJUSTMENT: 'adjustment',
  DEVOLUTION: 'devolution',
});

export const emissionType = Object.freeze({
  NORMAL: 'normal',
});

export const printerType = Object.freeze({
  NO_GENERATE: 'no_generate',
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
  SIMPLIFIED: 'simplified',
  NFCE: 'nfce',
  NFCE_MSG: 'nfce_msg',
});

export const invoiceType = Object.freeze({
  NFE: 'nce',
  NFCE: 'nfce',
  NFSE: 'nfse',
});

export const personType = Object.freeze({
  PHYSICAL: 'F',
  LEGAL: 'J',
});

export const typeMovementsInputOutput = Object.freeze({
  INPUT: 'input',
  OUTPUT: 'output',
});

export const productInvoiceStatus = Object.freeze({
  PENDING: 'pending',
  PROCESSING: 'processing',
  AUTHORIZED: 'authorized',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
});

export const HTTP_CODE = Object.freeze({
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  CREATED: 201,
  SUCCESS: 200,
  NOT_CONTENT: 204,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
});

export const ENVIRONMENT_INVOICE = Object.freeze({
  production: 'production',
  homologation: 'homologation',
});

export const ENVIRONMENT_SEFAZ = Object.freeze({
  production: 1,
  homologation: 2,
});

export const typeNatureOperation = Object.freeze({
  SALES: 'sales',
  PURCHASES: 'purchases',
  DEVOLUTION_SALES: 'devolution-sales',
  DEVOLUTION_PURCHASES: 'devolution-purchases',
});

export const statusBoxes = Object.freeze({
  OPEN: 'open',
  CLOSED: 'closed',
});

export const statusBoxMovements = Object.freeze({
  OPEN: 'open',
  CLOSED: 'closed',
});

export const typeTaxRegime = Object.freeze({
  NATIONAL_SIMPLE: 'national_simple',
  NATIONAL_SIMPLE_EXCESS: 'national_simple_excess',
  NORMAL_REGIME: 'normal_regime'
});

export const typeRegister = Object.freeze({
  EMPLOYEE: 'employee',
  CLIENT: 'client',
  PROVIDER: 'provider',
  SELLER: 'seller',
});

export const icmsCst = Object.freeze({
  CST_101: '101',
  CST_102: '102',
  CST_103: '103',
  CST_201: '201',
  CST_202: '202',
  CST_203: '203',
  CST_300: '300',
  CST_400: '400',
  CST_500: '500',
  CST_900: '900',
  CST_00: '00',
  CST_10: '10',
  CST_20: '20',
  CST_30: '30',
  CST_40: '40',
  CST_41: '41',
  CST_50: '50',
  CST_51: '51',
  CST_60: '60',
  CST_70: '70',
  CST_90: '90',
});

export const typePaymentMethod = Object.freeze({
  CASH: 'cash',
  CREDIT_CARD: 'credit_card',
  DEBIT_CARD: 'debit_card',
  CHECK: 'check',
  STORE_CARD: 'store_card',
  FOOD_STAMPS: 'food_stamps',
  FOOD_TICKET: 'food_ticket',
  SNACK_TICKET: 'snack_ticket',
  GIFT_TICKET: 'gift_ticket',
  FUEL_TICKET: 'fuel_ticket',
  OTHERS: 'others',
  LETTER_CREDIT: 'letter_credit',
});

export const statusCreditCardControlParcel = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
  REVERSED: 'reversed',
});

export const statusCreditCardTransactionParcel = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
});

export const statusCheckControl = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
});

export const statusBilletControl = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
});

export const modelNf = {
  NFE: '55',
  NFCE: '65',
};

export const modelPrintDanfe = Object.freeze({
  printMe55: 'printMe55',
  printMe65: 'printMe65',
});

export const taxPayer = {
  TAXPAYER: 'taxpayer',
  EXEMPT_TAXPAYER: 'exempt_taxpayer',
  NON_TAXPAYER: 'non_taxpayer',
};

export const tableStatus = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
};

export const tableStatusType = {
  OPEN_TABLE: 'openTable',
  CLOSE_TABLE: 'closeTable',
};

export const typePageOrders = {
  ORDER_SALE: 'orderSale',
  ORDER_PURCHASE: 'orderPurchase',
  ORDER_BUDGET: 'orderBudget',
  ORDER_SALE_DEVOLUTION: 'orderSaleDevolution',
  ORDER_PURCHASE_DEVOLUTION: 'orderPurchaseDevolution',
  ORDER_SALE_PDV: 'orderSalePdv',
  ORDER_CONSIGNED: 'orderConsigned',
  ORDER_TABLE: 'orderSaleTable',
};

export const typeValuePriceList = {
  REAL: 'real',
  PERCENTAGE: 'percentage',
};

export const typeAdjustmentPriceList = {
  ADDITION: 'addition',
  DISCOUNT: 'discount',
};

export const CashFlowTypes = {
  PENDING: 'pending',
  PAID: 'paid',
};

export const creditLetterStatus = {
  NOT_USAGE: 'not_usage',
  PARTIAL_USAGE: 'partial_usage',
  USAGE: 'usage',
  CANCELED: 'cancelled',
};

export const cashFlowItemMovementType = {
  INPUT: 'input',
  OUTPUT: 'output',
};

export const statusOrders = Object.freeze({
  PENDING: 'pending',
  FINISHED: 'finished',
  CANCELED: 'cancelled',
  RETURNED: 'returned',
  REVERSED: 'reversed',
});
