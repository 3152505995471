<template>
  <div>
    <PageHeader :schema="$schemas.stockMovement" />
    <PageContent>
      <DynamicListPage :schema="$schemas.stockMovement"
        :service="$api.stockMovements" :slots="listSlots">

        <template v-slot:movement_type="{ props: { item: { movement_type } } }">
          <Chip small :color="getColorChip(movement_type)"
            :label="l.movementType[movement_type]" />
        </template>

        <template v-slot:custom_actions="{ props: { item: { origin } } }">
          <ButtonIcon v-if="origin.route_name" x-small color="secondary"
            :icon="$icons.redo" :tooltip="l.tooltips.origin"
            @click="openOrigin(origin)" />
        </template>
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { ButtonIcon, Chip } from '@/components/vuetify';
import OpenOriginMixin from '@/mixins/OpenOriginMixin';
import ListStockMovementsMixin from '../mixins/ListStockMovementsMixin';

export default {
  name: 'ListStockMovements',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    ButtonIcon,
    Chip,
  },
  data () {
    return {
      listSlots: [
        { field: 'origin.label' },
        { field: 'movement_type' },
        { field: 'custom_actions' },
      ]
    };
  },
  computed: {
    l () {
      return this.$locales.pt.stockMovements.list;
    },
  },
  mixins: [OpenOriginMixin, ListStockMovementsMixin],
};
</script>
