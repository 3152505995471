<template>
  <div style="text-align: center">
    <Row style="border: 1px solid #000">
      <Col md="5" class="border-right border-dark d-flex flex-column justify-content-center">
        <span class="d-block" v-text="model.emitter.name" />
        <span v-text="`${model.emitter.street} ${model.emitter.number || ''} ${model.emitter.complement || ''}`" />
        <span class="ml-2 d-block" v-text="model.emitter.neighborhood" />
        <span class="ml-2" v-text="phone" />
      </Col>
      <Col md="3" class="border-right border-dark d-flex flex-column justify-content-center">
        <span class="d-block lead" v-text="l.danfe" />
        <span class="d-block" style="font-size: 12px" v-text="l.danfeDescription" />
        <Row>
          <Col md="6" class="text-right">
            <span class="" v-text="l.input" />
            <span class=" d-block" v-text="l.output" />
          </Col>
          <Col md="2" class="text-left">
            <span class="d-block text-center border-right border-dark"
              style="border: 1px solid #999; width: 20px; height: 25px; margin-top: 12px"
              v-html="model.operation_type" />
          </Col>
        </Row>
        <span class="d-block" v-text="`${l.number} ${model.nf_number} - ${l.series} ${model.series}`" />

      </Col>
      <Col md="4" class="d-flex flex-column justify-content-center">
        <span class="d-block" v-text="`${l.key} ${model.key_nfe || ''}`" />
        <span class="d-block" v-text="l.consult" />
        <span class="d-block" v-text="l.site" />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
} from '@/components/vuetify';
import { formatPhone } from '@/utils';

export default {
  name: 'Danfe55Header',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.header;
    },
    phone () {
      return formatPhone(this.model.emitter.phone || '99999999999');
    }
  },
};
</script>
