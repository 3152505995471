import { statusBilletControl } from '@/utils/enums';
import { formatCurrency } from '@/utils';

export default {
  beforeList: ({ form }) => {
    form.billet_controls.forEach((billet_control) => {
      billet_control.destroyDisabled = billet_control.status === statusBilletControl.PAID;
    });

    const calculateValues = (items, status) => {
      return items.reduce((acc, item) => {
        if (item.status === status) {
          acc += item.value;
        }

        return acc;
      }, 0);
    };

    form.total_billet = formatCurrency(form.billet_controls.length, { type: 'decimal' });
    form.value_total_billet_available = formatCurrency(calculateValues(
      form.billet_controls, statusBilletControl.PENDING));
    form.value_total_billet_receivable = formatCurrency(calculateValues(
      form.billet_controls, statusBilletControl.PAID));
  },
};
