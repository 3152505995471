<template>
  <div>
    <span class="d-block mt-3"><b>Fatura</b></span>
    <Row class="border border-dark">
      <Col md="4" class="border-right border-dark d-flex flex-column">
        <span v-text="l.paymentMethod" />
        <span v-text="model.bill.payment_method.name" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span v-text="l.number" />
        <span v-text="model.bill.number" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span v-text="l.originalValue" />
        <span v-text="originalValue" />
      </Col>
      <Col md="2" class="border-right border-dark d-flex flex-column">
        <span v-text="l.discountValue" />
        <span v-text="discountValue" />
      </Col>
      <Col md="2" class="d-flex flex-column">
        <span v-text="l.liquidValue" />
        <span v-text="liquidValue" />
      </Col>
    </Row>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils';

import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55Bill',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.bills;
    },
    originalValue () {
      return formatCurrency(this.model.bill.original_value);
    },
    discountValue () {
      return formatCurrency(this.model.bill.discount_value);
    },
    liquidValue () {
      return formatCurrency(this.model.bill.liquid_value);
    },
  },
};
</script>
