<template>
  <ModalCardDefault title="Editar Variação" @close="close">
    <ExpansionPanel v-model="panel" :title="'Formulário'" :icon="$icons.box" readonly>
      <v-form v-model="validFormVariation" ref="validFormVariation" lazy-validation class="px-5 pt-5">
        <Row v-for="(item, i) in productVariationValues" :key="i">
          <Col md="6">
            <Select v-model="item.variation_value.variation.id" label="Variação" :items="variations" :rules="[rules.required]"
              item-text="description" item-value="id" return-object readonly />
          </Col>
          <Col md="6">
            <Select v-model="item.variation_value_id" label="Valor da Variação"
              :items="getVariationValues(item, item.variation_value.variation.description)"
              item-text="description" item-value="id" :rules="[rules.required]" return-object
              :loading="loadingVariationValue" @change="changeVariationValue($event, item)" />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField v-model="form.code" label="Código" :rules="[]" />
          </Col>
          <Col>
            <TextField v-model="form.bar_code" label="Cod. Barras" :rules="[]" />
          </Col>
          <Col>
            <TextFieldMoney v-model="form.price" label="Preço Varejo"
              prefix="R$" :rules="[rules.required]" />
          </Col>
          <Col>
            <TextFieldMoney v-model="form.price_wholesale" label="Preço Atacado"
              prefix="R$" :rules="[]" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button label="Confirmar" class="mr-3"
        rounded color="secondary" @click="save" :loading="loading" />
    </div>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Button,
  Row,
  Col,
  Select,
  TextField,
  TextFieldMoney,
} from '@/components/vuetify';
import { required } from '@/utils/rules';

export default {
  name: 'EditVariation',
  components: {
    Row,
    Col,
    ModalCardDefault,
    ExpansionPanel,
    Button,
    Select,
    TextField,
    TextFieldMoney,
  },
  props: {
    productVariation: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      panel: [0],
      loading: false,
      loadingVariationValue: false,
      validFormVariation: false,
      rules: { required },
      variations: [],
      variationValues: [],
      productVariationValues: [],
      form: {
        code: null,
        bar_code: null,
        price: 0,
        price_wholesale: 0,
        product_variation_values: []
      },
    };
  },
  methods: {
    async getProductVariationValues() {
      this.$api.products.getProductVariationValuesByProductVariationId(this.productVariation.id).then((res) => {
        this.productVariationValues = res;
      }).catch(() => {
        this.$noty.error('Erro ao pegar as variações');
      });
    },
    async getVariations () {
      this.$api.variations.index().then((res) => {
        this.variations = res.data.variations;
        this.setValuesForm();
      }).catch(() => {
        this.$noty.error(this.l.noty.errorGetVariations);
      });
    },
    getVariationValues(item) {
      const variation = this.variations.find((variation) => {
        return variation.id === item.variation_value.variation_id;
      });

      if (!variation) {
        return [];
      }

      return variation.values;
    },
    setValuesForm() {
      this.form = {
        ...this.form,
        code: this.productVariation.code,
        bar_code: this.productVariation.bar_code,
        price: this.productVariation.price,
        price_wholesale: this.productVariation.price_wholesale,
        product_variation_values: this.productVariation.variation_value_ids.map((item, index) => {
          return {
            variation_value_id: item,
            variation_id: this.productVariation.variation_ids[index]
          };
        })
      };
    },
    changeVariationValue(item) {
      const index = this.form.product_variation_values.findIndex(i => i.variation_id === item.variation_id);

      if (index !== -1) {
        this.form.product_variation_values[index].variation_value_id = item.id;
      } else {
        this.form.product_variation_values.push({
          variation_value_id: item.id,
          variation_id: item.variation_id
        });
      }
    },
    save() {
      if (!this.$refs.validFormVariation.validate()) {
        this.$noty.error('Erro ao salvar, verifique os campos obrigatórios!');

        return false;
      }

      this.loading = true;
      this.$api.productVariations.update(this.productVariation.id, this.form).then(() => {
        this.$noty.success('Atualizado com sucesso!');
        this.$emit('close', true);
      }).catch((err) => {
        this.$noty.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    close() {
      this.$emit('close', false);
    }
  },
  async mounted() {
    await this.getVariations();
    await this.getProductVariationValues();
  }
};
</script>
