import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import BoxSchema from '../schemas/BoxSchema';
import { formatCurrency, getText, formatDate } from '@/utils';
import { typeInputOutputBox, originTypeBoxMovements, statusBoxMovements } from '@/utils/options';

const formatResponse = (item) => {
  item.balance_formatted = formatCurrency(item.balance);
  item.status_formatted = getText(statusBoxMovements, item.status);
};

const formatResponseListMovements = (item) => {
  item.box_movements.forEach((item) => {
    item.date_open_formatted = formatDate(item.date_open);
    item.date_close_formatted = formatDate(item.date_close);
    item.balance_formatted = formatCurrency(item.balance);
    item.status_formatted = getText(statusBoxMovements, item.status);
  });

  return item;
};

const formatResponseBoxMovementLast = (item) => {
  item.date_open_formatted = formatDate(item.date_open);
  item.date_close_formatted = formatDate(item.date_close);
  item.balance_formatted = formatCurrency(item.balance);
  item.status_formatted = getText(statusBoxMovements, item.status);

  return item;
};

const formatResponseListMovementItems = (item) => {
  item.box_movement_items.forEach((item) => {
    item.movement_date_formatted = formatDate(item.movement_date);
    item.movement_type_formatted = getText(typeInputOutputBox, item.movement_type);
    item.originable_type_formatted = getText(originTypeBoxMovements, item.originable_type);
    item.payment_method_formatted = item.payment_method.name;
    item.value_formatted = formatCurrency(item.value);
  });

  return item;
};

const BoxesService = DynamicService('boxes', BoxSchema, { formatResponse });

const BoxMovementsCommands = () => ({
  async createMovementItem(box_movement, box_movement_item) {
    const box_id = box_movement.box_id;
    const box_movement_id = box_movement.id;

    return await axios.post(`boxes/${box_id}/movements/${box_movement_id}/items`, box_movement_item);
  },
  async createBoxMovement(box_id, box_movement) {
    return await axios.post(`boxes/${box_id}/movements/`, box_movement);
  },
  async updateBoxMovement(box_id, box_movement) {
    return await axios.put(`boxes/${box_id}/movements/${box_movement.id}/`, box_movement);
  },
  async listMovements(box_id) {
    const { data } = await axios.get(`boxes/${box_id}/movements`);

    return formatResponseListMovements(data);
  },
  async listBoxMovementLast(box_id) {
    const { data } = await axios.get(`boxes/${box_id}/movements/last`);

    return formatResponseBoxMovementLast(data.box_movement);
  },
  async deleteMovement(box_id, box_movement_id) {
    return await axios.delete(`boxes/${box_id}/movements/${box_movement_id}`);
  },
  async listMovementItems(box_movement) {
    const box_id = box_movement.box_id;
    const box_movement_id = box_movement.id;
    const { data } = await axios.get(`boxes/${box_id}/movements/${box_movement_id}/items`);

    return formatResponseListMovementItems(data);
  },
});

export default BoxesService;
export { BoxesService, BoxMovementsCommands };
