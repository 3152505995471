<template>
  <ModalCardDefault :title="'Lista de Preços'" @close="$emit('update:dialogModal', false)">
    <DataTable no-search no-sync hide-default-footer :items="priceList"
      :headers="headers" :slots="slots" @setSelected="setSelected"
      show-select single-select>
      <template v-slot:name="{ props: { item } }">
        {{ item.name }}
      </template>
    </DataTable>

    <template v-slot:actions>
      <Button label="Confirmar" color="secondary"
        rounded @click="$emit('yesAction')" class="mr-3" />
    </template>
  </ModalCardDefault>
</template>

<script>
import { DataTable, Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';

export default {
  name: 'PdvPriceList',
  components: { ModalCardDefault, DataTable, Button },
  data () {
    return {
      slots: [
        { field: 'name' },
      ],
      priceList: [],
      selected: {}
    };
  },
  computed: {
    headers () {
      return [
        { text: 'Nome', value: 'name' },
      ];
    }
  },
  methods: {
    getPriceList () {
      this.$api.priceLists.index()
        .then(({ data: { price_lists } }) => {
          this.priceList = price_lists;
        });
    },
    setSelected (item) {
      this.selected = item[0];
    }
  },
  mounted () {
    this.getPriceList();
  }
};
</script>
