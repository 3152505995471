import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';
import { cnpj } from '@/utils/masks';

export default {
  domain: 'credit_card_administrators',
  domainSingle: 'credit_card_administrator',
  title: 'Administradoras de Cartão de Crédito',
  description: 'Lista de todas as Administradoras de Cartão de Crédito do sistema',
  createBtn: 'Nova Administradora',
  showInactive: false,
  routes: {
    list: {
      name: 'credit-card-administrators',
      path: '/administratora-cartoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'credit-card-administrator-create',
      path: '/administratora-cartoes/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'credit-card-administrator-show',
      path: '/administratora-cartoes/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Administradora de Cartão de Crédito',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'simpleMask',
          name: 'cnpj',
          formattedName: 'cnpj_formatted',
          label: 'CNPJ',
          align: '',
          md: '3',
          rules: [],
          inputMask: cnpj,
          outputMask: '##############',
          list: true,
          readonly: false,
          clearable: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'bank_id',
          formattedName: 'bank.description',
          label: 'Banco',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'banks',
            endpoint: 'banks',
            queryParams: '',
          },
          itemText: 'description',
          itemValue: 'id'
        },
        {
          type: 'percent',
          name: 'fee',
          formattedName: 'fee_formatted',
          label: 'Taxa',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'percent',
          name: 'anticipation_fee',
          formattedName: 'anticipation_fee_formatted',
          label: 'Taxa Antecipação',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'additional_fee',
          formattedName: 'additional_fee_formatted',
          label: 'Taxa Adicional',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
      ],
    },
  ],
  footer: [],
};
