import OpenTitleSchema from './schemas/OpenTitleSchema';
import ListOpenTitles from './pages/ListOpenTitles';
import CreateOpenTitle from './pages/CreateOpenTitle';

export default [
  {
    path: OpenTitleSchema.routes.list.path,
    name: OpenTitleSchema.routes.list.name,
    component: ListOpenTitles,
    meta: OpenTitleSchema.routes.list.meta,
  },
  {
    path: OpenTitleSchema.routes.create.path,
    name: OpenTitleSchema.routes.create.name,
    component: CreateOpenTitle,
    meta: OpenTitleSchema.routes.create.meta
  },
  {
    path: OpenTitleSchema.routes.show.path,
    name: OpenTitleSchema.routes.show.name,
    component: CreateOpenTitle,
    meta: OpenTitleSchema.routes.show.meta
  },
];