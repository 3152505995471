<script>
export default {
  methods: {
    selectContentInput(ids) {
      ids.forEach((id) => {
        document.querySelector(`#${id}`).addEventListener('click', function() {
          this.select();
        });
      });
    },
  }
};
</script>
