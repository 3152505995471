import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import ReportSchema from '../schemas/ReportSchema';
import { clone } from 'lodash';
import { typePage } from '@/utils/enums';

const formatResponse = () => {
};

const formatRequest = (data, type = typePage.CREATE) => {
  type;
  const payload = clone(data);

  return {
    ...payload,
    columns: payload.columns.map((column, index) => {
      return {
        ...column,
        type: 'string',
        order: index + 1
      };
    })
  };
};

const ReportsCommands = () => ({
  async generateReport(reportId) {
    return  await axios.post(`${ReportSchema.domain}/${reportId}/generate`);
  },
});

const ReportsService = DynamicService('reports', ReportSchema, { formatResponse, formatRequest });

export default ReportsService;
export { ReportsService, ReportsCommands };
