import { typePageOptions } from '@/utils';
import { text, users } from '@/utils/icons';
import { required } from '@/utils/rules';
import AllTypeBusiness from '@/business/AllTypeBusiness';

export default {
  domain: 'all_types',
  domainSingle: 'all_type',
  business: AllTypeBusiness,
  title: 'Tipos Gerais',
  description: 'Lista de cadastros gerais do sistema',
  showInactive: true,
  routes: {
    paymentMethod: {
      list: {
        name: 'paymentMethods',
        path: '/forma-pagamentos',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'paymentMethod', title: 'Formas de Pagamentos', description: 'Lista de todas as Formas de Pagamentos cadastradas no sistema', }
      },
      create: {
        name: 'paymentMethodCreate',
        path: '/forma-pagamentos/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'paymentMethod', title: 'Formas de Pagamentos', description: 'Lista de todas as Formas de Pagamentos cadastradas no sistema', }
      },
      show: {
        name: 'paymentMethodShow',
        path: '/forma-pagamentos/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'paymentMethod', title: 'Formas de Pagamentos', description: 'Lista de todas as Formas de Pagamentos cadastradas no sistema', }
      }
    },
    unitMeasurement: {
      list: {
        name: 'unitMeasurements',
        path: '/unidade-medidas',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'unitMeasurement', title: 'Unidade de Medidas', description: 'Lista de todas as Unidade de Medidas cadastradas no sistema', }
      },
      create: {
        name: 'unitMeasurementCreate',
        path: '/unidade-medidas/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'unitMeasurement', title: 'Unidade de Medidas', description: 'Lista de todas as Unidade de Medidas cadastradas no sistema', }
      },
      show: {
        name: 'unitMeasurementShow',
        path: '/unidade-medidas/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'unitMeasurement', title: 'Unidade de Medidas', description: 'Lista de todas as Unidade de Medidas cadastradas no sistema', }
      }
    },
    flag: {
      list: {
        name: 'flags',
        path: '/flags',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'flag', title: 'Bandeiras', description: 'Lista de todas as Bandeiras cadastradas no sistema', }
      },
      create: {
        name: 'flagCreate',
        path: '/flags/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'flag', title: 'Bandeiras', description: 'Lista de todas as Bandeiras cadastradas no sistema', }
      },
      show: {
        name: 'flagShow',
        path: '/flags/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'flag', title: 'Bandeiras', description: 'Lista de todas as Bandeiras cadastradas no sistema', }
      }
    },
    category: {
      list: {
        name: 'categories',
        path: '/categorias',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'category', title: 'Categorias',  description: 'Lista de todas as Categorias cadastradas no sistema', }
      },
      create: {
        name: 'categoryCreate',
        path: '/categorias/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'category', title: 'Categorias',  description: 'Lista de todas as Categorias cadastradas no sistema', }
      },
      show: {
        name: 'categoryShow',
        path: '/categorias/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'category', title: 'Categorias',  description: 'Lista de todas as Categorias cadastradas no sistema', }
      }
    },
    subCategory: {
      list: {
        name: 'subCategories',
        path: '/sub-categorias',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'subCategory', title: 'Sub Categorias',  description: 'Lista de todas as Sub Categorias cadastradas no sistema', }
      },
      create: {
        name: 'subCategoryCreate',
        path: '/sub-categorias/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'subCategory', title: 'Sub Categorias',  description: 'Lista de todas as Sub Categorias cadastradas no sistema', }
      },
      show: {
        name: 'subCategoryShow',
        path: '/sub-categorias/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'subCategory', title: 'Sub Categorias',  description: 'Lista de todas as Sub Categorias cadastradas no sistema', }
      }
    },
    ncm: {
      list: {
        name: 'ncms',
        path: '/ncms',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'ncm', title: 'NCMs',  description: 'Lista de todos os NCMs cadastradas no sistema', }
      },
      create: {
        name: 'ncmCreate',
        path: '/ncms/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'ncm', title: 'NCMs',  description: 'Lista de todos os NCMs cadastradas no sistema', }
      },
      show: {
        name: 'ncmShow',
        path: '/ncms/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'ncm', title: 'NCMs',  description: 'Lista de todos os NCMs cadastradas no sistema', }
      }
    },
    cest: {
      list: {
        name: 'cests',
        path: '/cests',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'cest', title: 'CESTs',  description: 'Lista de todos os CESTs cadastradas no sistema', }
      },
      create: {
        name: 'cestCreate',
        path: '/cests/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'cest', title: 'CESTs',  description: 'Lista de todos os CESTs cadastradas no sistema', }
      },
      show: {
        name: 'cestShow',
        path: '/cests/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'cest', title: 'CESTs',  description: 'Lista de todos os CESTs cadastradas no sistema', }
      }
    },
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    situation: false,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'text',
        name: 'name',
        label: 'Nome',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'description',
        label: 'Descrição',
        icon: text,
        md: 6,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Cadastro',
      icon: users,
      openGroup: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Decrição',
          align: '',
          md: '6',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'value',
          formattedName: 'value',
          label: 'Valor',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: true,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
