<template>
  <div>
    <ModalCardDefault :title="titleCard" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Informações Gerais'" :icon="$icons.box" readonly>
        <v-form v-model="validFormInfGeneral" ref="validFormInfGeneral" lazy-validation>
          <Row>
            <Col md="2">
              <Select v-model="natureOperationState.uf"
                label="UF" :items="ufs" itemText="text" itemValue="value"
                :rules="[rules.required]" />
            </Col>
            <Col md="3">
              <TextFieldInteger v-model="natureOperationState.cfop"
                label="CFOP" :rules="[rules.required]" />
            </Col>
            <Col md="4">
              <TextField v-model="natureOperationState.description"
                label="Descrição da Natureza de Operação"
                :rules="[rules.required]" />
            </Col>
            <Col md="3">
              <Select v-model="natureOperationState.destiny_location"
                label="Local de Destino"
                :items="destinationLocationIdentifier"
                itemText="text" itemValue="value" :rules="[rules.required]" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

      <ExpansionPanel v-model="panel" :title="'Configurações'" :icon="$icons.box"
        readonly class="mt-5">
        <Row>
          <Col md="12">
            <Tabs v-model="tab" @setTab="setTab">
              <Tab v-for="item in tabItems" :key="item">{{ item }}</Tab>
            </Tabs>
            <TabsItems v-model="tab">
              <component :is="componentTabItem" :model="natureOperationState.taxes"/>
            </TabsItems>
          </Col>
        </Row>
      </ExpansionPanel>

      <div slot="actions">
        <Button :label="l.actions.save" class="mr-3" rounded color="secondary"
          @click="save()" />
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextField,
  TextFieldInteger,
  Button,
  ExpansionPanel
} from '@/components/vuetify';
import Tab from '@/components/vuetify/Tab';
import Tabs from '@/components/vuetify/Tabs';
import TabsItems from '@/components/vuetify/TabsItems';
import ModalCardDefault from '@/components/ModalCardDefault';
import { UF, destinationLocationIdentifier } from '@/utils/options';
import { messageErrors } from '@/utils';
import { required } from '@/utils/rules';
import Icms from './CreateNatureOperationStates/Icms';
import IcmsSt from './CreateNatureOperationStates/IcmsSt';
import FinalConsumer from './CreateNatureOperationStates/FinalConsumer';
import Ipi from './CreateNatureOperationStates/Ipi';
import Pis from './CreateNatureOperationStates/Pis';
import Cofins from './CreateNatureOperationStates/Cofins';
import AdditionalInformation from './CreateNatureOperationStates/AdditionalInformation';
import NatureOperationState from '../../model/NatureOperationState';
import { typePage } from '@/utils/enums';

const TAB_COMPONENT = [
  Icms,
  IcmsSt,
  FinalConsumer,
  Ipi,
  Pis,
  Cofins,
  AdditionalInformation
];

export default {
  name: 'CreateNatureOperationState',
  components: {
    ModalCardDefault,
    Row,
    Col,
    Select,
    TextField,
    TextFieldInteger,
    Tab,
    Tabs,
    TabsItems,
    Button,
    ExpansionPanel
  },
  props: {
    typePage: {
      type: String,
      required: true
    },
    natureOperationStateSelected: {
      type: Object,
      required: false
    },
    natureOperationId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      panel: [0],
      titleCard: '',
      validFormInfGeneral: false,
      natureOperationState: NatureOperationState.model,
      ufs: UF,
      destinationLocationIdentifier: destinationLocationIdentifier,
      rules: { required: required },
      tab: 0,
      tabItems: ['ICMS', 'ICMS ST', 'ICMS CONS. FINAL', 'IPI', 'PIS', 'COFINS', 'INFORMAÇOES ADICIONAIS'],
      componentTabItem: Icms, IcmsSt, FinalConsumer, Ipi, Pis, Cofins, AdditionalInformation,
      componentValidate: false
    };
  },
  computed: {
    l () {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState;
    },
  },
  watch: {
    natureOperationStateSelected () {
      this.find();
    },
    typePage () {
      if (this.typePage === typePage.CREATE) {
        this.natureOperationState = NatureOperationState.model;
        this.titleCard = this.l.title.new;
      } else {
        this.titleCard = this.l.title.edit;
      }
    }
  },
  methods: {
    setTab (tab) {
      this.tab = tab;
      this.componentTabItem = TAB_COMPONENT[tab];
    },
    async find () {
      const { nature_operation_id, id } = this.natureOperationStateSelected;
      this.$api.natureOperations.getState(nature_operation_id, id).then((res) => {
        this.natureOperationState = res.data.nature_operation_state;
      }).catch((err) => {
        this.$noty.error(messageErrors(err.response));
      });
    },
    async save () {
      // if (!this.$refs.validFormInfGeneral.validate() ||
      //   !this.validateForm('validFormIcms') ||
      //   !this.validateForm('validFormicmsSt') ||
      //   !this.validateForm('validFormIpi') ||
      //   !this.validateForm('validFormPis') ||
      //   !this.validateForm('validFormCofins')) {
      //   return false;
      // }
      if (this.typePage === typePage.CREATE) {
        this.$api.natureOperations.createState(this.natureOperationId, this.natureOperationState).then(() => {
          this.$noty.success(this.l.actions.createState);
          this.$emit('closeDialogCreateNatureOperationState');
        }).catch((err) => {
          this.$noty.error(messageErrors(err.response));
        });
      } else {
        this.$api.natureOperations.updateState(this.natureOperationState).then(() => {
          this.$noty.success(this.l.actions.updateState);
          this.$emit('closeDialogCreateNatureOperationState');
        }).catch((err) => {
          this.$noty.error(messageErrors(err.response));
        });
      }
    },
    validateForm(form) {
      const refForm = this.$refs.componentValidate.$refs[form];

      if (refForm) {
        return refForm.validate();
      }
    }
  },
  mounted () {
    this.titleCard = this.l.title.new;

    if (this.typePage === typePage.SHOW) {
      this.find();
    }
  }
};
</script>

<style>

</style>
