export { default as routes } from './routes';

export const menuItemsAllTypes = [
  {
    active: false,
    menuGroupName: 'allTypes',
    icon: 'allTypes',
    title: 'Tipos Gerais',
    items: [
      { icon: 'plus', title: 'Unidades de Medidas', route: 'unitMeasurements', active: false },
      { icon: 'plus', title: 'Ncms', route: 'ncms', active: false },
      { icon: 'plus', title: 'Cests', route: 'cests', active: false },
      { icon: 'plus', title: 'Formas de Pagamentos', route: 'paymentMethods', active: false },
      { icon: 'handshakeOutline', title: 'Parcelamentos', route: 'type-installments', active: false },
      { icon: 'briefcaseAccountOutline', title: 'Conta Contábel', route: 'accounting-accounts', active: false },
      { icon: 'setCenter', title: 'Centro de Custo', route: 'cost-centers', active: false },
      { icon: 'plus', title: 'Bandeiras Cartões', route: 'flags', active: false },
    ],
  },
];
