var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field-money',_vm._g(_vm._b({attrs:{"label":_vm.label,"properties":{
    id: _vm.id,
    refs: _vm.refs,
    name: _vm.name,
    prefix: _vm.prefix,
    suffix: _vm.suffix,
    readonly: _vm.readonly,
    disabled: _vm.localDisabled,
    outlined: _vm.outlined,
    clearable: _vm.clearable,
    dense: _vm.dense,
    rules: _vm.rules,
    loading: _vm.loading,
  },"options":{
    locale: 'pt-BR',
    length: _vm.length,
    precision: _vm.precision,
    empty: _vm.empty,
  }}},'v-text-field-money',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }