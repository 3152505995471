export default {
  domain: 'orders',
  domainSingle: 'order',
  business: null,
  routes: {
    pdv: {
      name: 'pdv',
      path: '/frente-de-loja',
      meta: {},
    },
  },
};
