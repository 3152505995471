<template>
  <Card toolbar color="grey lighten-2">
    <div slot="toolbar"><h2>{{ l.title }}</h2></div>
    <h2 v-text="l.messageConfirmation" />
    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button :label="l.actions.yes" class="mr-3" rounded color="secondary" @click="$emit('delete', id)" />
      <Button :label="l.actions.no" rounded color="primary" @click="$emit('close')" />
    </div>
  </Card>
</template>

<script>
import {
  Card,
  Button
} from '@/components/vuetify';

export default {
  name: 'DeleteVariation',
  components: {
    Card,
    Button,
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  computed: {
    l () {
      return this.$locales.pt.products.list.modalVariations.deleteVariation;
    },
  },
};
</script>

<style>

</style>
