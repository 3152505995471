import DynamicService from '@/service/DynamicService';
import OpenTitleSchema from '../schemas/OpenTitleSchema';
import { formatDatePT, formatCurrency } from '@/utils';
import { typePage } from '@/utils/enums';

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.due_date_formatted = formatDatePT(item.due_date);
    item.paid_date_formatted = formatDatePT(item.paid_date);
    item.compensation_date_formatted = formatDatePT(item.compensation_date);
    item.value_formatted = formatCurrency(item.value);
    item.discount_formatted = formatCurrency(item.discount);
    item.total_value_formatted = formatCurrency(item.total_value);
  };

  const formatShow = (parcel) => {
    console.log('parcel', parcel);
  };
  type === typePage.LIST ? formatList(item) : formatShow(item.account);
};

const OpenTitlesService = DynamicService('open_titles', OpenTitleSchema, { formatResponse });

export default OpenTitlesService;