import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import CashFlowSchema from '../schemas/CashFlowSchema';
import { formatCurrency, formatDatePT, getText } from '@/utils';
import { cashFlowTypes } from '@/utils/options';
import { typePage } from '@/utils/enums';

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.start_date_formatted = formatDatePT(item.start_date);
    item.end_date_formatted = formatDatePT(item.end_date);
    item.start_balance_formatted = formatCurrency(item.start_balance);
    item.total_entry_formatted = formatCurrency(item.total_entry);
    item.total_exit_formatted = formatCurrency(item.total_exit);
    item.end_balance_formatted = formatCurrency(item.end_balance);
    item.includes_manual_launch_and_transfer = item.includes_manual_launch_and_transfer ? 'yes' : 'no';
    item.includes_manual_launch_and_transfer_formatted = item.includes_manual_launch_and_transfer ? 'Sim' : 'Não';
    item.is_only_selected_banks = item.is_only_selected_banks ? 'yes' : 'no';
    item.is_only_selected_banks_formatted = item.is_only_selected_banks ? 'Sim' : 'Não';
    item.type_formatted = getText(cashFlowTypes, item.type);
    item.banks_list_formatted = item.banks_list;
  };

  const formatShow = ({ cash_flow }) => {
    cash_flow.bank_ids = cash_flow.bank_ids.split(',');
  };

  type === typePage.LIST ? formatList(item) : formatShow(item);
};

const formatResponseGetAllCashFlowItemsByCashFlowId = ({ data: { cash_flow_items } }) => {
  return cash_flow_items.map((cash_flow_item) => {
    return {
      ...cash_flow_item,
    };
  });
};

const CashFlowsService = DynamicService('cash_flows',
  CashFlowSchema, { formatResponse });

const CashFlowsCommands = () => ({
  async getAllCashFlowItemsByCashFlowId(cashFlowId, pagination) {
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page :1;
    const result = await axios.get(`filters?domain=cash_flow_item&search_global=true&filter[cash_flow_id]=${cashFlowId}&sort=asc&sort_by=created_at&per_page=${itemsPerPage || 5}&page=${page || 1}`);

    return {
      ...result.data,
      data: formatResponseGetAllCashFlowItemsByCashFlowId(result)
    };
  }
});

export default CashFlowsService;
export { CashFlowsService, CashFlowsCommands };
