<template>
  <ModalCardDefault :title="l.title" @close="$emit('closeModal')">
    <ExpansionPanel v-model="panel" :title="l.dataTable.title"
      :icon="$icons.box" readonly>

      <DataTable no-search no-sync :slots="slots" :items="events"
        :headers="$schemas.invoiceSetting.listEvents.headers" :items-per-page="7">

        <template v-slot:created_at="{ props: { item } }">
          {{ utils.formatDatePT(item.created_at) }}
        </template>
        <template v-slot:type="{ props: { item } }">
          {{ typeEvent(item.type) }}
        </template>
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  DataTable,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { formatDatePT } from '@/utils';

export default {
  name: 'ListEvents',
  components: {
    ExpansionPanel,
    ModalCardDefault,
    DataTable,
  },
  props: {
    invoiceSettingSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      loading: true,
      slots: [
        { field: 'type' },
        { field: 'created_at' },
      ],
      utils: {
        formatDatePT
      },
      events: []
    };
  },
  computed: {
    l () {
      return this.$locales.pt.invoiceSettings.list.listEvents;
    },
  },
  watch: {
    invoiceSettingSelected () {
      this.getAllEvents();
    }
  },
  methods: {
    async getAllEvents () {
      const payload = {
        invoice_setting_id: this.invoiceSettingSelected.id,
      };
      this.$api.invoiceSettings.getAllEvents(payload).then((res) => {
        this.events = res;
      }).catch(() => {

      });
    },
    typeEvent (type) {
      return this.l.dataTable[type];
    }
  },
  mounted () {
    this.getAllEvents();
  }
};
</script>
