import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, calendar, list } from '@/utils/icons';
import { required } from '@/utils/rules';
import AccountBusiness from '@/business/AccountBusiness';

export default {
  domain: 'accounts',
  domainSingle: 'account',
  business: AccountBusiness,
  title: 'Contas',
  description: 'Lista de todas as Contas do sistema',
  showInactive: true,
  createBtn: 'Nova Conta',
  routes: {
    accountPayment: {
      list: {
        name: 'accountPayments',
        path: '/pagamentos',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'accountPayment', title: 'Pagamentos', description: 'Lista de todos os Pagamentos cadastrados no sistema', }
      },
      create: {
        name: 'accountPaymentCreate',
        path: '/pagamentos/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'accountPayment', title: 'Pagamentos', description: 'Lista de todos os Pagamentos cadastrados no sistema', }
      },
      show: {
        name: 'accountPaymentShow',
        path: '/pagamentos/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'accountPayment', title: 'Pagamentos', description: 'Lista de todos os Pagamentos cadastrados no sistema', }
      }
    },
    accountReceivable: {
      list: {
        name: 'accountReceivables',
        path: '/recebimentos',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: 'accountReceivable', title: 'Recebimentos', description: 'Lista de todos os Recebimentos cadastrados no sistema', }
      },
      create: {
        name: 'accountReceivableCreate',
        path: '/recebimentos/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: 'accountReceivable', title: 'Recebimentos', description: 'Lista de todos os Recebimentos cadastrados no sistema', }
      },
      show: {
        name: 'accountReceivableShow',
        path: '/recebimentos/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: 'accountReceivable', title: 'Recebimentos', description: 'Lista de todos os Recebimentos cadastrados no sistema', }
      }
    },
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: true,
    more: {
      has: false,
      items: [
      ]
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'text',
        name: 'title',
        label: 'Título',
        icon: text,
        md: 6,
      },
      {
        type: 'select',
        name: 'status',
        label: 'Status',
        icon: list,
        md: 6,
        itemText: 'text',
        itemValue: 'value',
        items: {
          // data: statusAccounts
          data: [
            { text: 'Aberto', value: 'pending' },
            { text: 'Pago', value: 'paid' },
          ]
        }
      },
      {
        type: 'text',
        name: 'register$$name',
        label: 'Nome do Cadastro',
        icon: text,
        md: 12,
      },
    ]
  },
  fields: [
    {
      title: 'Dados da Conta a Receeber',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'title',
          formattedName: 'title',
          label: 'Título',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          with: 110,
        },
        {
          type: 'text',
          name: 'register_id',
          formattedName: 'register.name',
          label: 'Cadastro',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          width: 250,
        },
        {
          type: 'text',
          name: 'emission_date',
          formattedName: 'emission_date_formatted',
          label: 'Emissão',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          with: 110,
        },
        {
          type: 'text',
          name: 'total_value',
          formattedName: 'total_value_formatted',
          label: 'Valor Total',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          with: 110,
        },
        {
          type: 'text',
          name: 'paid_value_parcels',
          formattedName: 'paid_value_parcels_formatted',
          label: 'Valor Pago',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          with: 110,
        },
        {
          type: 'text',
          name: 'paid_value_parcels_pending',
          formattedName: 'paid_value_parcels_pending_formatted',
          label: 'Falta Pagar',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          with: 110,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          with: 110,
        },
      ],
    },
  ],
  footer: [
    {
      name: 'total_value_receivable',
      label: 'Vlr. Total',
      color: 'primary',
      md: 3,
      type: 'recebimentos',
    },
    {
      name: 'total_value_receivable_paid',
      label: 'Vlr. Pago',
      color: 'info',
      md: 3,
      type: 'recebimentos',
    },
    {
      name: 'value_receivable_pending',
      label: 'Falta Pagar',
      color: 'warning',
      md: 3,
      type: 'recebimentos',
    },
    {
      name: 'total_value_payment',
      label: 'Vlr. Total',
      color: 'primary',
      md: 3,
      type: 'pagamentos',
    },
    {
      name: 'total_value_payment_paid',
      label: 'Vlr. Pago',
      color: 'info',
      md: 3,
      type: 'pagamentos',
    },
    {
      name: 'value_payment_pending',
      label: 'Falta Pagar',
      color: 'warning',
      md: 3,
      type: 'pagamentos',
    },
  ],
  create: {
    installments: {
      headers: [
        {
          text: 'Título',
          value: 'title',
        },
        {
          text: 'Vencimento',
          value: 'due_date_formatted',
        },
        {
          text: 'Pagamento',
          value: 'paid_date_formatted',
        },
        {
          text: 'Sub Total',
          value: 'value_formatted',
        },
        {
          text: 'Diferença',
          value: 'difference_formatted',
        },
        {
          text: 'Total',
          value: 'total_value_formatted',
        },
        {
          text: 'Falta Pagar',
          value: 'total_paid_value_formatted',
          width: 140
        },
        {
          text: 'Status',
          value: 'status',

        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ]
    }
  }
};
