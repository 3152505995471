export default {
  beforeList: ({ form }) => {
    form.banks.forEach((bank) => {
      if (bank.is_small_box) {
        bank.destroyDisabled = true;
        bank.showDisabled = true;
      } else {
        bank.destroyDisabled = false;
      }
    });
  },
};
