export default {
  title: 'Histórico de Devoluções',
  headers: [
    {
      text: 'Data da Devolução',
      value: 'devolution_date_formatted',
    },
    {
      text: 'N° Ordem de Devolução',
      value: 'devolution.order_number',
    },
    {
      text: 'Item',
      value: 'item.item_number',
    },
    {
      text: 'Produto',
      value: 'item.name',
    },
    {
      text: 'Quantidade',
      value: 'quantity',
    }
  ],
};
