<template>
  <div class="d-flex justify-space-between px-5">
    <span>{{ formatCurrency(item.base, { type: 'decimal' }) }}</span>
    <span>{{ formatCurrency(item.aliquot, { type: 'decimal' }) }}</span>
    <span>{{ formatCurrency(item.value, { type: 'decimal' }) }}</span>
    <span v-if="hasCts(type)"><b>{{item.cst}}</b></span>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils';
import ItemsDataListTaxesMixin from '@/mixins/ItemsDataListTaxesMixin';

export default {
  name: 'ItemsDataListTaxesItem',
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      formatCurrency: formatCurrency,
    };
  },
  mixins: [ItemsDataListTaxesMixin]
};
</script>
