import Vue from 'vue';
import axios from '@/service';
import { typePage } from '@/utils/enums';
import { getCompany, formatCurrency } from '@/utils';

const setFieldReadonly = (form, propsFields) => {
  if (form.has_variation) {
    Vue.set(form, 'bar_code', '');
    Vue.set(form, 'code', '');
  }
  propsFields.code.readonly = form.has_variation;
  propsFields.bar_code.readonly = form.has_variation;
};

export default {
  beforeList: ({ form }) => {
    form.products.forEach((product) => {
      product.disableItemMoreActions = {
        listVariations: !product.has_variation,
        listVariationStocks: !!product.has_variation,
        stockMovements: !!product.has_variation,
        stockMove: !!product.has_variation,
      };
    });

    form.total_price = formatCurrency(form.total_price);
    form.total_price_wholesale = formatCurrency(form.total_price_wholesale);
  },
  beforeForm: async ({ form, propsFields, route }) => {
    setFieldReadonly(form, propsFields);

    if (route.meta.typePage === typePage.SHOW) {
      propsFields.has_variation.readonly = true;
    }

    const res = await axios.get('company_settings');
    const companySetting = res.data.company_settings[0] || {};

    if (companySetting.is_product_code_sequential) {
      if (route.meta.typePage === typePage.CREATE) {
        const product_code_prefix = '';
        Vue.set(form, 'code', `${product_code_prefix}${parseInt(companySetting.last_product_code) + 1}`);
      }
      propsFields.code.readonly = true;
    }
  },
  afterUpdated: ({ id }) => {
    const company = getCompany();
    const itemLocalStorage = `${process.env.VUE_APP_NAME}-${company.cnpj}.auto-complete@order-sale-create@product@`;
    let dataCache = JSON.parse(localStorage.getItem(itemLocalStorage));

    if (dataCache) {
      dataCache.forEach((item, index) => {
        if (item.id === id) {
          dataCache.splice(index, 1);
        }
      });
      localStorage.setItem(itemLocalStorage, JSON.stringify(dataCache));
    }
  },
  changes: {
    has_variation: ({ form, propsFields }) => {
      setFieldReadonly(form, propsFields);
    }
  },
};
