import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import CompanySchema from '../schemas/CompanySchema';
import { formatPhone, formatCpfCnpj, getText } from '@/utils';
import { typeTaxRegime } from '@/utils/options';

const formatResponse = (item) => {
  item.cell_phone_formatted = formatPhone(item.cell_phone);
  item.cnpj_formatted = formatCpfCnpj(item.cnpj);
  item.tax_regime_formatted = getText(typeTaxRegime, item.tax_regime);
};

const CompaniesService = DynamicService('companies', CompanySchema, { formatResponse });

const CompaniesCommands = () => ({
  async loginAs(companyId) {
    return await axios.post('auth/login-as', { company_id: companyId });
  },
  async getCompanySettings() {
    return await axios.get('company_settings');
  },
});

export default CompaniesService;
export { CompaniesService, CompaniesCommands };
