import CompanySettingSchema from './schemas/CompanySettingSchema';
import ListCompanySettings from './pages/ListCompanySettings';
import CreateCompanySetting from './pages/CreateCompanySetting';

export default [
  {
    path: CompanySettingSchema.routes.list.path,
    name: CompanySettingSchema.routes.list.name,
    component: ListCompanySettings,
    meta: CompanySettingSchema.routes.list.meta,
  },
  {
    path: CompanySettingSchema.routes.show.path,
    name: CompanySettingSchema.routes.show.name,
    component: CreateCompanySetting,
    meta: CompanySettingSchema.routes.show.meta,
  },
];
