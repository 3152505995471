<template>
  <v-tooltip top :color="$attrs.color">
    <template v-slot:activator="{ on }">
      <v-btn fab icon v-bind="$attrs" v-on="on" @click="$emit('click')">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ButtonIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  }
};
</script>

<style>

</style>
