<template>
  <v-row>
    <slot />
  </v-row>
</template>

<script>
export default {
  name: 'Row'
};
</script>
