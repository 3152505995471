<template>
  <div>
    <ModalCardDefault :title="'Adicionar Produto'" @close="$emit('close', false)">
      <ExpansionPanel v-model="panel" :title="'Pesquise o Produto'" :icon="$icons.box" readonly>
        <Row>
          <Col md="8">
            <TextField v-model="search" label="Pesquise o Produto" />
          </Col>
          <Col md="4">
            <Button :label="'Pesquisar'" class="mr-3" rounded color="secondary"
              @click="searchItem" />
          </Col>
        </Row>
      </ExpansionPanel>
      <ExpansionPanel v-model="panel" :title="'Items a serem devolvidos'"
        :icon="$icons.box" readonly class="mt-3">
        <Row>
          <Col>
            <DataTable class="mt-5" no-search no-sync :items.sync="itemsDevolution"
              :headers="headersOrderSaleItems" show-expand
              :slots="slotsItems" item-key="key">
              <template v-slot:quantity_devolution="{ props: { item } }">
                <span>{{ utils.formatCurrency(item.quantity_devolution, { type: 'decimal' }) }}</span>
              </template>
              <template v-slot:quantity="{ props: { item } }">
                <span>{{ utils.formatCurrency(item.quantity, { type: 'decimal' }) }}</span>
              </template>
              <template v-slot:total_value="{ props: { item } }">
                <span>{{ utils.formatCurrency(item.total_value) }}</span>
              </template>
              <template v-slot:content-expanded="{ props: { item } }">
                <Row class="py-10">
                  <Col md="3">
                    <span v-text="'Valor Uni.:'" class="d-block font-weight-bold subtitle-1" />
                    <span v-text="utils.formatCurrency(item.unit_value)" />
                  </Col>
                  <Col md="3">
                    <span v-text="'Sub Total:'" class="d-block font-weight-bold subtitle-1" />
                    <span v-text="utils.formatCurrency(item.sub_total)" />
                  </Col>
                </Row>
              </template>
            </DataTable>
          </Col>
        </Row>
      </ExpansionPanel>

      <div slot="actions" class="d-flex justify-end" style="width: 100%">
        <Button :label="'Inserir'" class="mr-3" rounded color="secondary"
          @click="addItems" />
      </div>
    </ModalCardDefault>

    <Dialog :dialog="dialogItems" :max-width="1150">
      <ItemsDataListItemsProductOrderDevolutionItems
        :key="keyComponent"
        :items="searchOrderItemsDevolution"
        :itemsDevolutions="itemsDevolution"
        @addItemsOrderSale="addItemsOrderSale" slot="content"
        @update:dialogItems="dialogItems = $event" />
    </Dialog>
  </div>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  Button,
  Row,
  Col,
  DataTable,
  Dialog,
  TextField,
} from '@/components/vuetify';
import ItemsDataListItemsProductOrderDevolutionItems from './ItemsDataListItemsProductOrderDevolutionItems';
import { formatCurrency, randomNumber } from '@/utils';

export default {
  name: 'ItemsDataListItemsProductOrderDevolution',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Button,
    Row,
    Col,
    DataTable,
    Dialog,
    ItemsDataListItemsProductOrderDevolutionItems,
    TextField,
  },
  props: {
    registerId: {
      type: String,
      required: true
    },
    natureOperationId: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      utils: {
        formatCurrency,
      },
      slotsItems: [
        { field: 'qtd_devolution' },
        { field: 'quantity_devolution' },
        { field: 'quantity' },
        { field: 'total_value' },
      ],
      dialogItems: false,
      search: '',
      searchOrderItemsDevolution: [],
      itemsDevolution: [],
      keyComponent: randomNumber(),
      loadingTaxes: false,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.products.list.modalVariations;
    },
    headersOrderSaleItems () {
      return [
        {
          text: 'Item',
          value: 'item_number'
        },
        {
          text: 'Código.',
          value: 'code'
        },
        {
          text: 'Nome',
          value: 'name',
          width: 350
        },
        {
          text: 'Qtde.',
          value: 'quantity'
        },
        {
          text: 'Vlr. Total',
          value: 'total_value',
          width: 150
        },
      ];
    },
  },
  methods: {
    async searchItem () {
      this.$api.orders.getOrderItemToDevolution({ register_id: this.registerId, search: this.search }).then(({ order_items }) => {
        this.searchOrderItemsDevolution = order_items;

        if (order_items.length === 0) {
          this.$noty.error('Nenhum item encontrado');
          this.search = '';

          return;
        } else if (order_items.length === 1) {
          const item = order_items[0];
          delete item.order;

          const itemDuplicate = this.getItemDuplicated(item);
          item.qtd_devolution = 1;

          if (itemDuplicate) {
            const qtd = this.calculateQtdItemDuplicate(item, itemDuplicate.quantity);

            if (!qtd) {
              return;
            }
            this.removeItemDuplicated(item.id);
            item.qtd_devolution = qtd;
          }

          this.calculateTaxes(item);
        } else {
          this.keyComponent = randomNumber();
          this.dialogItems = true;
        }
        this.search = '';
      });
    },
    async addItemsOrderSale() {
      this.keyComponent = randomNumber();
      this.dialogItems = false;

      this.searchOrderItemsDevolution.filter((item) => {
        return item.qtd_devolution > 0;
      }).forEach((item) => {
        if (item.qtd_devolution > item.quantity) {
          this.$noty.error(`A quantidade a ser devolvida não pode ser maior que a quantidade do item: ${item.item_number}`);
        } else {
          const itemDuplicate = this.getItemDuplicated(item);

          if (itemDuplicate) {
            const qtd = this.calculateQtdItemDuplicate(item, itemDuplicate.quantity);

            if (!qtd) {
              return;
            }
            this.removeItemDuplicated(item.id);
            item.qtd_devolution = qtd;
          }

          delete item.order;
          this.calculateTaxes(item);
        }
      });
    },
    async addItems () {
      this.$emit('addItem', this.itemsDevolution);
    },
    async calculateTaxes(item) {
      this.loadingTaxes = true;
      this.$api.orders.calculateItemTaxes({
        client_id: this.registerId,
        product_variation_id: item.product_variation_id,
        nature_operation_id: this.natureOperationId,
        total_without_discount_value: item.qtd_devolution * item.unit_value,
        taxpayer_type: 'non_taxpayer',
        is_costumer: true,
        discount_value: 0,
        another_value: 0,
        freight_value: 0,
        quantity: item.quantity
      }).then((res) => {
        this.itemsDevolution.push({
          ...item,
          key: this.itemsDevolution.length + 1,
          order_item_id: item.id,
          item_number: this.itemsDevolution.length + 1,
          quantity: item.qtd_devolution,
          sub_total: item.qtd_devolution * item.unit_value,
          total_value: item.qtd_devolution * item.unit_value,
          tax: res
        });
      }).catch((err) => {
        let message = 'Erro ao Calcular os Impostos';

        if (err.response.status === 400) {
          message ='Não existe Natureza de Operação para a UF do Cliente';
        }
        this.$noty.error(message);
      }). finally(() => {
        this.loadingTaxes = false;
      });
    },
    removeItemDuplicated(id) {
      const index = this.itemsDevolution.findIndex(item => item.id === id);

      if (index !== -1) {
        this.itemsDevolution.splice(index, 1);
      }
    },
    getItemDuplicated(item) {
      return this.itemsDevolution.find((itemDevolution) => {
        return itemDevolution.id === item.id;
      });
    },
    calculateQtdItemDuplicate(item, quantity) {
      const qtd = parseFloat(item.qtd_devolution) + parseFloat(quantity);

      if (qtd > item.quantity) {
        const msgError = `A quantidade a ser devolvida não pode ser maior que a quantidade do item: ${item.item_number}`;
        this.$noty.error(msgError);

        return false;
      }

      return qtd;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-card {
  margin-top: 15px;
  padding: 35px 18px;
  background: #F1F1F1;
  border-radius: 20px
}

.item-input {
  height:  30px !important
}
</style>
