export const menuItemsOperations = [
  {
    active: false,
    menuGroupName: 'operations',
    icon: 'networkPos',
    title: 'Operações',
    items: [
      { icon: 'table', title: 'Mesas', route: 'tables', active: false },
      { icon: 'swapHorizontal', title: 'PDV', route: 'pdv', active: false },
    ],
  },
];
