import ProductVariationStockMovementSchema from './schemas/ProductVariationStockMovementSchema';
import ListProductMovements from './pages/ListProductMovements';

export default [
  {
    path: ProductVariationStockMovementSchema.routes.list.path,
    name: ProductVariationStockMovementSchema.routes.list.name,
    component: ListProductMovements,
    meta: ProductVariationStockMovementSchema.routes.list.meta,
  },
];
