<template>
  <ModalCardDefault title="Uso da Carta de Crédito" @close="$emit('close', false)">
    <ExpansionPanel v-model="panel" title="Lista do Uso da Carta de Crédito" :icon="$icons.box" readonly>
      <DataTable no-search :items-per-page="15" :items="histories"
        :headers="headers" no-sync hide-default-footer :slots="listSlots">
        <template v-slot:created_at="{ props: { item } }">
          <span>{{ utils.formatDatePT(item.created_at) }}</span>
        </template>
        <template v-slot:origin="{ props: { item } }">
          <span>{{ getOrigin(item) }}</span>
        </template>
        <template v-slot:value="{ props: { item } }">
          <span>{{ utils.formatCurrency(item.value) }}</span>
        </template>
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import { ExpansionPanel, DataTable } from '@/components/vuetify';
import { formatCurrency, formatDatePT } from '@/utils';

export default {
  name: 'UsageHistoric',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    DataTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      panel: [0],
      utils: {
        formatCurrency,
        formatDatePT,
      },
    };
  },
  methods: {
    getOrigin(item) {
      let origin = '';

      switch (item.originable.type) {
      case this.$enums.orderTypes.ORDER_SALE:
        origin = `Pedido de Venda: ${item.originable.order_number}`;
        break;
      case this.$enums.orderTypes.ORDER_PURCHASE:
        origin = `Pedido de Compras: ${item.originable.order_number}`;
        break;
      case this.$enums.orderTypes.ORDER_SALE_DEVOLUTION:
        origin = `Devolução de Venda: ${item.originable.order_number}`;
        break;
      case this.$enums.orderTypes.ORDER_PURCHASE_DEVOLUTION:
        origin = `Devolução de Compra: ${item.originable.order_number}`;
        break;
      case this.$enums.orderTypes.ORDER_SALE_PDV:
        origin = `PDV: ${item.originable.order_number}`;
        break;
      default:
        origin = 'Não identificado';
        break;
      }

      return origin;
    },
  },
  computed: {
    headers () {
      return [
        { text: 'Data de Uso', value: 'created_at' },
        { text: 'Origem', value: 'origin' },
        { text: 'Valor', value: 'value' },
      ];
    },
    listSlots() {
      return [
        { field: 'created_at' },
        { field: 'origin' },
        { field: 'value' },
      ];
    },
    histories () {
      return this.item.histories;
    },
  },
};
</script>
