class BoxMovement {
  get model() {
    return {
      date_open: '',
      date_close: '',
      employee_id: '',
      balance: 0,
    };
  }
}

export default new BoxMovement();
