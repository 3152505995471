import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, cashClock } from '@/utils/icons';
import { required } from '@/utils/rules';
import { typeYesNo, cashFlowTypes } from '@/utils/options';
import CashFlowBusiness from '@/business/CashFlowBusiness';
import CashFlowMovements from '@/views/Planning/CashFlows/pages/components/ListMovements';

export default {
  domain: 'cash_flows',
  domainSingle: 'cash_flow',
  title: 'Fluxo de Caixa',
  description: 'Lista de todas as Fluxo de Caixa do sistema',
  business: CashFlowBusiness,
  createBtn: 'Novo Fluxo de Caixa',
  showInactive: false,
  showExpand: true,
  routes: {
    list: {
      name: 'cashFlows',
      path: '/fluxo-de-caixas',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'cashFlowCreate',
      path: '/fluxo-de-caixas/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'cashFlowShow',
      path: '/fluxo-de-caixas/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          icon: cashClock,
          name: 'listMovements',
          label: 'Movimentos',
          action: 'listMovements',
          component: CashFlowMovements,
          version: 'v2',
          updateListWhenClosing: false,
        }
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Fluxo de Caixa',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'dataPicker',
          name: 'start_date',
          formattedName: 'start_date_formatted',
          label: 'Início',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          itemValueDefault: true,
          default: getCurrentPeriod().currentDateEN,
        },
        {
          type: 'dataPicker',
          name: 'end_date',
          formattedName: 'end_date_formatted',
          label: 'Fim',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          itemValueDefault: true,
          default: getCurrentPeriod().currentDateEN,
        },
        {
          type: 'text',
          name: 'start_balance',
          formattedName: 'start_balance_formatted',
          label: 'Valor Inicial',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'total_entry',
          formattedName: 'total_entry_formatted',
          label: 'Total Entradas',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'total_exit',
          formattedName: 'total_exit_formatted',
          label: 'Total Saídas',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'text',
          name: 'end_balance',
          formattedName: 'end_balance_formatted',
          label: 'Saldo',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          noForm: true,
        },
        {
          type: 'select',
          name: 'includes_manual_launch_and_transfer',
          formattedName: 'includes_manual_launch_and_transfer_formatted',
          label: 'Incluir Lançamentos Manuais e Transferências',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
          items: typeYesNo,
          itemValueDefault: true,
          default: 'yes',
        },
        {
          type: 'select',
          name: 'is_only_selected_banks',
          formattedName: 'is_only_selected_banks_formatted',
          label: 'Somente os Bancos Selecionados',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
          items: typeYesNo,
          itemValueDefault: true,
          default: 'no',
        },
        {
          type: 'select',
          name: 'type',
          formattedName: 'type_formatted',
          label: 'Tipo',
          align: '',
          md: '2',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
          items: cashFlowTypes,
        },
        {
          type: 'text',
          name: 'banks_list',
          formattedName: 'banks_list_formatted',
          label: 'Lista dos Bancos',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
          noForm: true,
        },
        {
          type: 'select',
          name: 'bank_ids',
          formattedName: 'bank.description',
          label: 'Bancos',
          align: '',
          md: '7',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: false,
            verb: 'get',
            domain: 'banks',
            endpoint: 'banks',
            queryParams: '',
          },
          itemText: 'description',
          itemValue: 'id',
          multiple: true,
        }
      ],
    },
  ],
  footer: [],
};
