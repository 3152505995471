import AccountingAccountSchema from './schemas/AccountingAccountSchema';
import ListAccountingAccounts from './pages/ListAccountingAccounts';
import CreateAccountingAccount from './pages/CreateAccountingAccount';

export default [
  {
    path: AccountingAccountSchema.routes.list.path,
    name: AccountingAccountSchema.routes.list.name,
    component: ListAccountingAccounts,
    meta: AccountingAccountSchema.routes.list.meta,
  },
  {
    path: AccountingAccountSchema.routes.create.path,
    name: AccountingAccountSchema.routes.create.name,
    component: CreateAccountingAccount,
    meta: AccountingAccountSchema.routes.create.meta
  },
  {
    path: AccountingAccountSchema.routes.show.path,
    name: AccountingAccountSchema.routes.show.name,
    component: CreateAccountingAccount,
    meta: AccountingAccountSchema.routes.show.meta
  },
];