export default {
  form: {
    actions: {
      save: 'Salvar'
    },
    header: {
      danfe: 'DANFE',
      danfeDescription: 'Documento Fiscal de Nota Fiscal Eletrônica',
      input: '0-Entrada',
      output: '1-Saída',
      number: 'N°',
      series: 'SÉRIE:',
      key: 'CHAVE DE ACESSO: ',
      consult: 'Consulta de autenticidade no portal nacional da NF-e',
      site: 'www.nfe.fazenda.gov.br/portal ou no site da Sefaz Autorizadora',
    },
    infosGenerals: {
      natureOperation: 'Natureza de Operação',
      protocolNumber: 'Protocolo de Autorização',
      cnpj: 'CNPJ',
      ie: 'IE',
      ie_st: 'Inscrição Estadual do Subst. Tributário',
      suframa: 'SUFRAMA',
      modal: {
        title: 'Configurações Iniciais da NFe',
        typeNFe: 'Tipo da NFe',
        taxConfig: 'Configuração Fiscal',
        model: 'Modelo',
        series: 'Série',
        nfNumber: 'N° da NFe',
        emissionForm: 'Forma de Emissão',
        finality: 'Finalidade',
        danfeForm: 'Formato DANFE',
        actions: {
          cancel: 'Cancelar',
          confirm: 'Confirmar'
        }
      }
    },
    client: {
      title: 'Destinatário/Remetente',
      name: 'Nome/Razão Social',
      cnpj: 'CNPJ/CPF',
      emissionDate: 'Data de Emissão',
      emissionHour: 'Hora de Emissão',
      address: 'Logradouro',
      number: 'Número',
      neighborhood: 'Bairro',
      zipCode: 'CEP',
      inputOutputDate: 'Data Entrada/Saída',
      city: 'Município',
      phone: 'Fone',
      uf: 'UF',
      ie: 'IE/RG',
      inputOutputHour: 'Hora Entrada/Saída',
      finalConsumer: 'Consumidor Final',
      typeTaxPayer: 'Tipo de Contribuinte',
      presenceIndicator: 'Indicador de Presença',
      intermediaryIndicator: 'Indicador de Intermediador',
    },
    bills: {
      title: 'Fatura/Duplicata',
      number: 'Número',
      originalValue: 'Valor Original',
      discountValue: 'Desconto',
      liquidValue: 'Valor Líquido',
      paymentMethod: 'Forma de Pagamento',
      tooltips: {
        removeDuplicate: 'Excluir duplicata'
      },
      duplicates: {
        title: 'Nova Duplicata',
        fields: {
          number: 'Número',
          dueDate: 'Vencimento',
          value: 'Valor'
        },
        actions: {
          add: 'Adicionar'
        }
      },
      noty: {
        duplicateValue: 'O somatório das duplicatas é maior que o total da NFe'
      }
    },
    taxes: {
      title: 'Cálculo dos Impostos',
      icmsBase: 'Base do ICMS',
      icmsValue: 'Vlr. do ICMS',
      icmsSubsBase: 'Base ICMS Sub.',
      icmsSubsValue: 'Vlr. do ICMS Sub.',
      ipiValue: 'Vlr. IPI',
      productsValue: 'Vlr. Total Produtos',
      freightValue: 'Vlr. do Frete',
      safeValue: 'Vlr. do Seguro',
      discount: 'Desconto',
      otherExpenses: 'Outras Despesas',
      approximatesTaxes: 'Trib. Aproxim.',
      nfeTotal: 'Total da NFe',
    },
    transporter: {
      title: 'Transportadora/Volumes Transportados',
      name: 'Nome/Razão Social',
      document: 'CNPJ/CPF',
      ie: 'Insc. Estadual',
      freightModality: 'Frente por Conta',
      zipCode: 'CEP',
      country: 'País',
      uf: 'UF',
      city: 'Município',
      neighborhood: 'Bairro',
      street: 'Logradouro',
      number: 'Número',
      complement: 'Complemento',
      plate: 'Placa Veículo',
      vehicleUf: 'UF Veículo',
      rntc: 'Código RNTC',
      liquidWeight: 'Peso Líquido',
      grossWeight: 'Peso Bruto',
      brand: 'Marca',
      specie: 'Espécie',
      quantity: 'Quantidade',
      freightInclude: 'Incluir Frete',
      freightValue: 'Frete',
      othersExpenses: 'Outras Despesas',
      safeValue: 'Valor Seguro',
      cte: 'Número Conhecimento',
    },
    items: {
      title: 'Dados dos Produtos',
      buttonAddItem: 'Novo Item',
      dataList: {
        modalProduct: {
          products: 'Selecione o Produto'
        }
      }
    },
    additionalData: {
      title: 'Dados Adicionais',
      contributorData: 'Informações para o Contribuinte',
      taxData: 'Informações para o Fisco',
    }
  },
  noty: {
  }
};
