<template>
  <div>
    <v-form v-model="validFormicmsSt" ref="validFormicmsSt" lazy-validation>
      <Row class="mt-5">
        <Col>
        <Select v-model="model.icms_st_determinate_mode"
          :label="l.determinateMode.label"
          :items="icmsStDeterminatonMode" itemText="text" itemValue="value"
          :rules="[rules.required]" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
        <TextFieldPercent v-model="model.icms_st_aliquot_mva"
          :placeholder="l.aliquotMva.placeholder"
          :label="l.aliquotMva.label" />
        </Col>
        <Col md="4">
        <TextFieldPercent v-model="model.icms_st_aliquot_icms_internal"
          :placeholder="l.aliquotIcmsInternal.placeholder"
          :label="l.aliquotIcmsInternal.label" />
        </Col>
        <Col md="4">
        <TextFieldPercent v-model="model.icms_st_percent_reduce_base"
          :placeholder="l.percentReduceBase.placeholder"
          :label="l.percentReduceBase.label" />
        </Col>
      </Row>
      <Row>
        <Col md="3">
        <CheckBox v-model="model.icms_st_reduce_value"
          @setCheckBox="setCheckBox($event, 'icms_st_reduce_value')"
          :label="l.reduceValue.label" class="mt-0" />
        </Col>
        <Col md="3">
        <CheckBox v-model="model.icms_st_reduce_base"
          @setCheckBox="setCheckBox($event, 'icms_st_reduce_base')"
          :label="l.reduceBase.label" class="mt-0" />
        </Col>
        <Col md="3">
        <CheckBox v-model="model.icms_st_highlight_icms_own"
          @setCheckBox="setCheckBox($event, 'icms_st_highlight_icms_own')"
          :label="l.highlightIcmsOwn.label" class="mt-0" />
        </Col>
        <Col md="3">
        <CheckBox v-model="model.icms_st_difal_per_st"
          @setCheckBox="setCheckBox($event, 'icms_st_difal_per_st')"
          :label="l.difalPerST.label" class="mt-0" />
        </Col>
      </Row>
      <Row>
        <Col md="3">
        <CheckBox v-model="model.icms_st_include_discounts"
          @setCheckBox="setCheckBox($event, 'icms_st_include_discounts')"
          :label="l.includeDiscounts.label" class="mt-0" />
        </Col>
        <Col md="3">
        <CheckBox v-model="model.icms_st_include_ipi"
          @setCheckBox="setCheckBox($event, 'icms_st_include_ipi')"
          :label="l.includeIpi.label" class="mt-0" />
        </Col>
        <Col md="3">
        <CheckBox v-model="model.icms_st_include_expenses"
          @setCheckBox="setCheckBox($event, 'icms_st_include_expenses')"
          :label="l.includeExpenses.label" class="mt-0" />
        </Col>
        <Col md="3">
        <CheckBox v-model="model.icms_st_include_freight"
          @setCheckBox="setCheckBox($event, 'icms_st_include_freight')"
          :label="l.includeFreight.label" class="mt-0" />
        </Col>
      </Row>
      <Row>
        <Col md="3">
        <TextFieldPercent v-model="model.icms_st_number_fecop"
          :placeholder="l.numberFecop.placeholder"
          :label="l.numberFecop.label" />
        </Col>
        <Col md="3">
        <TextFieldPercent v-model="model.icms_st_aliquot_fecop"
          :placeholder="l.valueFecop.placeholder"
          :label="l.valueFecop.label" />
        </Col>
        <Col md="3">
        <TextFieldPercent v-model="model.icms_st_aliquot_fcp"
          :placeholder="l.aliquotFcp.placeholder"
          :label="l.aliquotFcp.label" />
        </Col>
        <Col md="3">
        <TextFieldPercent v-model="model.icms_st_value_pmpf"
          :placeholder="l.valuePmpf.placeholder"
          :label="l.valuePmpf.label" />
        </Col>
      </Row>
    </v-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextFieldPercent
} from '@/components/vuetify';
import CheckBox from '@/components/vuetify/CheckBox';
import { required } from '@/utils/rules';
import { icmsStDeterminatonMode } from '@/utils/options';

export default {
  name: 'IcmsSt',
  components: {
    Row,
    Col,
    Select,
    TextFieldPercent,
    CheckBox
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: { required: required },
      validFormicmsSt: false,
      icmsStDeterminatonMode: icmsStDeterminatonMode,
    };
  },
  computed: {
    l() {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState.form.icmsSt;
    },
  },
  methods: {
    setCheckBox(value, type) {
      console.log('value', value);
      console.log('type', type);
      this.model[type] = value || false;
    }
  }
};
</script>
