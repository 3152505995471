<template>
  <div>
    <div class="mb-3">
      <slot name="custom-header" />
    </div>

    <div class="mb-5 d-flex flex-column-reverse justify-space-between flex-md-row">
      <div class="d-flex flex-wrap  justify-start">
        <div v-for="(item, index) in searchChips" :key="index" :style="[$vuetify.breakpoint.name === 'xs' ? {width: '100%'} : '']">
          <Chip
            v-if="!item.noChip"
            dense
            :close="!item.isPermanent"
            class="mr-2 mb-2"
            :label="`${item.label}: ${item.formattedValue}`"
            @click:close="$emit('closeChip', item)" :customStyle="$vuetify.breakpoint.name === 'xs' ? 'width: 100%; margin-bottom: 5px': ''" />
        </div>
      </div>

      <div>
        <SearchListPage ref="searchListPage" :schema="schema"
          :items="schema.filters.items"
          :itemsSelectsFilters="itemsSelectsFilters"
          v-if="schema.filters.has" @searchItems="searchItems" />
      </div>
    </div>
  </div>
</template>

<script>
import Chip from '@/components/vuetify/Chip';
import SearchListPage from './SearchListPage';

export default {
  name: 'DynamicListPageHeader',
  components: { Chip, SearchListPage },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    searchChips: {
      type: Array,
      required: true,
    },
    itemsSelectsFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    searchItems (form) {
      this.$emit('searchItems', form);
    }
  }
};
</script>

<style>

</style>
