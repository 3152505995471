<template>
  <div>
    <PageHeader :schema="$schemas.cashFlow" />
    <PageContent>
      <DynamicListPage :schema="$schemas.cashFlow" :service="$api.cashFlows"
          :slots="slotItems">
        <template v-slot:start_balance_formatted="{ props: { item } }">
          <span :class="[getClassValues(item, 'start_balance')]"><b>{{ item.start_balance_formatted }}</b></span>
        </template>
        <template v-slot:total_entry_formatted="{ props: { item } }">
          <span :class="[getClassValues(item, 'total_entry')]"><b>{{ item.total_entry_formatted }}</b></span>
        </template>
        <template v-slot:total_exit_formatted="{ props: { item } }">
          <span :class="[getClassValues(item, 'total_exit')]"><b>{{ item.total_exit_formatted }}</b></span>
        </template>
        <template v-slot:end_balance_formatted="{ props: { item } }">
          <span :class="[getClassValues(item, 'end_balance')]"><b>{{ item.end_balance_formatted }}</b></span>
        </template>
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

export default {
  name: 'ListCashFlows',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
  },
  data () {
    return {
      slotItems: [
        { field: 'start_balance_formatted' },
        { field: 'total_entry_formatted' },
        { field: 'total_exit_formatted' },
        { field: 'end_balance_formatted' },
      ],
    };
  },
  methods: {
    getClassValues(item, field) {
      return {
        'primary--text': (field === 'total_exit' && item[field] !== 0) || item[field] < 0,
        'secondary--text': item[field] === 0,
        'success--text': item[field] > 0,
      };
    },
  },
};
</script>
