import { dateTime } from '../../utils/dateTime';
import { Response } from 'miragejs';

export const destroy = (server) => {
  server.delete('/variations/:id', (schema, request) => {
    const { id } = request.params;
    const variation = schema.variations.find(id);

    if (!variation) {
      return new Response(404, {}, { errors: {} });
    }
    variation.update({ updated_at: dateTime, deleted_at: dateTime });
  });
};