<template>
  <div>
    <span class="d-block mt-3"><b>Duplicatas</b></span>
    <Row class="border border-dark">
      <Col md="4" :class="['d-flex justify-content-between',
        { 'border-right border-dark': ((index % 3) === 0 ||
        (index % 3) === 1)  }]"
        v-for="(item, index) in duplicates" :key="index">
        <div class="d-flex flex-column" style="width: 33%">
          <span v-if="index <= 2" v-text="'Dupl.'" />
          <span v-text="item.number" />
        </div>
        <div class="d-flex flex-column" style="width: 33%">
          <span v-if="index <= 2"  v-text="'Venc.'" />
          <span v-text="getDueDate(item.due_date)" />
        </div>
        <div class="d-flex flex-column" style="width: 33%">
          <span v-if="index <= 2"  v-text="'Valor'" />
          <span v-text="getValue(item.value)" />
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { formatCurrency, formatDatePT } from '@/utils';

import {
  Row,
  Col,
} from '@/components/vuetify';

export default {
  name: 'Danfe55BillDuplicates',
  components: {
    Row,
    Col,
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.bills;
    },
    duplicates () {
      // const duplicateItem = { number: '', due_date: '', value: '' };
      // const duplicates = this.model.bill.duplicates;
      // if (this.model.bill.duplicates.length === 1) {
      //   duplicates.push(duplicateItem);
      //   duplicates.push(duplicateItem);
      // } else if (this.model.bill.duplicates.length === 2) {
      //   duplicates.push(duplicateItem);
      // }
      // return duplicates;
      return [];
    },
    originalValue () {
      return formatCurrency(this.model.bill.original_value);
    },
    discountValue () {
      return formatCurrency(this.model.bill.discount_value);
    },
    liquidValue () {
      return formatCurrency(this.model.bill.liquid_value);
    },
  },
  methods: {
    getDueDate (date) {
      return formatDatePT(date);
    },
    getValue (value) {
      if (!value) {
        return null;
      }

      return formatCurrency(value);
    }
  }
};
</script>
