<template>
  <v-dialog
    v-model="dialog"
    persistent
    :width="maxWidth"
    :max-width="maxWidth"
  >
    <v-card style="border-radius: 30px; background-color: #F6F7FB !important">
      <v-card-text class="px-0 py-0">
        <slot name="content" />
      </v-card-text>

      <v-card-actions class="mt-3 d-flex justify-end" v-if="!noActions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    },
    minHeight: {
      type: Number,
      default: 600
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style lang="scss">
.v-dialog {
  border-radius: 30px !important
}
</style>
