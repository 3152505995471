<template>
  <ModalCardDefault :title="l.title" @close="$emit('closeModal')">
    <ExpansionPanel v-model="panel" :title="l.dataTable.title"
      :icon="$icons.box" readonly>
      <DataTable no-search no-sync
        :items="productInvoiceSelected.correction_letters"
        :headers="this.l.dataTable.headers" :slots="[]" :loading="loadingDataTable">
      </DataTable>
    </ExpansionPanel>

    <div slot="actions" class="d-flex justify-end" style="width: 100%">
      <Button :label="l.actions.create" class="mr-3" rounded color="secondary"
        @click="dialogModal = true" />
    </div>

    <Dialog :dialog="dialogModal" :max-width="1100">
      <template v-slot:content>
        <CreateCorrectionLetter :product-invoice-selected="productInvoiceSelected"
          @closeModal="closeModal" />
      </template>
    </Dialog>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  DataTable,
  Button,
  Dialog,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CreateCorrectionLetter from './CreateCorrectionLetter';

export default {
  name: 'ListCorrectionLetters',
  components: {
    ExpansionPanel,
    ModalCardDefault,
    DataTable,
    Button,
    Dialog,
    CreateCorrectionLetter,
  },
  props: {
    productInvoiceSelected: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      panel: [0],
      loadingDataTable: false,
      dialogModal: false,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.list.correctionLetters;
    },
  },
  methods: {
    closeModal (refresh) {
      this.dialogModal = false;

      if (refresh) {
        this.$emit('closeModal');
      }
    }
  },
};
</script>
