<template>
  <ModalCardDefault :title="'Produtos'"  @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" :title="'Lista de Todos os Produtos'" :icon="$icons.box" readonly>
      <DataTable ref="productsDataTable" :items-per-page="7" :items="products"
        :headers="headers" :slots="slots" show-select single-select :loading="loading"
        key-data="product_variations" @setSelected="setSelected" @getItems="getProducts">
        <template v-slot:name="{ props: { item } }">
          {{ item.name }}
        </template>
        <template v-slot:price="{ props: { item } }">
          {{ utils.formatCurrency(item.price) }}
        </template>
      </DataTable>

    </ExpansionPanel>
    <template v-slot:actions>
      <Button color="secondary"
        rounded @click="confirm" class="mr-3">
        <span class="text-button">Confirmar
            <span class="text-button font-weight-black yellow--text">(Enter)</span>
          </span>
      </Button>
    </template>
  </ModalCardDefault>
</template>

<script>
import { DataTable, ExpansionPanel, Button } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import { formatCurrency, formatProductVariations } from '@/utils';

export default {
  name: 'PdvProducts',
  components: { ModalCardDefault, DataTable, ExpansionPanel, Button },
  data () {
    return {
      panel: [0],
      slots: [
        { field: 'name' },
        { field: 'price' },
      ],
      utils: {
        formatCurrency,
      },
      products: [],
      selected: null,
      loading: false,
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  computed: {
    headers () {
      return [
        { text: 'Cod. Barras', value: 'bar_code' },
        { text: 'Nome', value: 'name' },
        { text: 'Unidade', value: 'product.unit_measure.value' },
        { text: 'Estoque', value: 'quantity' },
        { text: 'Preço', value: 'price' },
      ];
    },
  },
  mixins: [InputFocusMixin],
  methods: {
    getProducts(pagination = { page: 1, itemsPerPage: 7 }) {
      const { page, itemsPerPage, filter } = pagination;

      let urlFilter = '';

      if (filter) {
        urlFilter = `filter[name]=${filter}&filter[code]=${filter}&filter[bar_code]=${filter}&filter[reference]=${filter}&condition=or`;
      }

      this.loading = true;
      this.$api.productVariations.filters({ url: `filters?domain=product_variation&page=${page}&per_page=${itemsPerPage}&${urlFilter}` })
        .then(({ data }) => {
          this.products = data;
          this.products = {
            ...this.products,
            product_variations: formatProductVariations(this.products.product_variations),
          };
        }).finally(() => {
          this.loading = false;
        });
    },
    setSelected (item) {
      this.selected = item[0];
    },
    confirm () {
      if (!this.selected) {
        this.$noty.error('Selecione um produto');

        return;
      }
      this.$emit('confirmDialog', { action: 'addItem', data: this.selected });
    },
    actionListener(event) {
      if (event.key === 'Enter') {
        this.confirm();
      }
    }
  },
  mounted () {
    this.focusInputs(this.$refs.productsDataTable.$refs.searchDataTable.$refs.inputDataTable);
  }
};
</script>
