<script>
export default {
  name: 'SetDiscountAndWeightMixin',
  methods: {
    setDiscountAndWeight (type) {
      if (this.localForm.quantity > 0) {
        this.localForm.total_weight_liquid = parseFloat(this.localForm.weight_liquid_unit) * parseFloat(this.localForm.quantity);
        this.localForm.total_weight_gross = parseFloat(this.localForm.weight_gross_unit) * parseFloat(this.localForm.quantity);
      }

      if (this.localForm.unit_value > 0) {
        const total = this.localForm.quantity * this.localForm.unit_value;

        if (type === 'value') {
          this.localForm.total_value = total - this.localForm.discount_value;
          this.localForm.discount_percentage = (this.localForm.discount_value * 100) / total;
        } else {
          const discount = (total * (this.localForm.discount_percentage / 100));
          this.localForm.discount_value = discount;
        }
        this.localForm.sub_total = total;
        this.localForm.total_value = total - this.localForm.discount_value;
      }
    },
  },
};
</script>
