import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { typeOperationStock } from '@/utils/options';
import StockMovementBusiness from '@/business/StockMovementBusiness';

export default {
  domain: 'stock_movements',
  domainSingle: 'stock_movement',
  business: StockMovementBusiness,
  title: 'Movimentações dos Estoques',
  description: 'Lista de todas as movimentações dos estoques do sistema',
  createBtn: false,
  showInactive: false,
  routes: {
    list: {
      name: 'stockMovements',
      path: '/estoque-movimentacoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
  },
  listActions: {
    has: false,
    noShow: true,
    noDestroy: true,
    showOrigin: true,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'select',
        name: 'stock_id',
        label: 'Estoque',
        icon: text,
        md: 6,
        itemText: 'name',
        itemValue: 'id',
        service: {
          has: true,
          optional: true,
          verb: 'get',
          domain: 'stocks',
          endpoint: 'stocks',
          queryParams: '',
        },
      },
      {
        type: 'select',
        name: 'movement_type',
        label: 'Tipo Movimeto',
        icon: text,
        md: 6,
        itemText: 'text',
        itemValue: 'value',
        items: {
          data: typeOperationStock
        }
      },
      // {
      //   type: 'select',
      //   name: 'originable_type',
      //   label: 'Origem',
      //   icon: text,
      //   md: 12,
      //   itemText: 'text',
      //   itemValue: 'value',
      //   items: {
      //     data: filterOriginStockMovements
      //   }
      // },
    ]
  },
  fields: [
    {
      title: 'Dados da Movimentação',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Origem',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'movement_date',
          formattedName: 'movement_date_formatted',
          label: 'Data',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'stock.name',
          formattedName: 'stock.name',
          label: 'Estoque',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'movement_type',
          formattedName: 'movement_type',
          label: 'Tipo',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'previous_balance_stock',
          formattedName: 'previous_balance_stock_formatted',
          label: 'Saldo Anterior',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'quantity',
          formattedName: 'quantity_formatted',
          label: 'Qtde',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'current_balance_stock',
          formattedName: 'current_balance_stock_formatted',
          label: 'Saldo Atual',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [
    {
      name: 'total_outputs',
      label: 'Saídas',
      color: 'primary',
      md: 2,
    },
    {
      name: 'total_inputs',
      label: 'Entradas',
      color: 'primary',
      md: 2,
    },
  ]
};
