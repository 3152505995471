<template>
  <div>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel v-for="(group, iGroup) in fields" :key="iGroup"
          :readonly="group.readonly" class="expansion-panel">
          <v-expansion-panel-header>
            <h3>
              <Icon :icon="group.type === 'address' ? $icons.address : group.icon"
                class="mr-3" />
              {{ group.type === 'address' ? 'Dados de Endereço' : group.title }}
            </h3>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" :lg="item.md"
                v-for="(item, iItem) in group.items" :key="iItem">
                <component v-model="localItem[item.name]" :ref="item.name"
                  :is="typesComponents[item.type]" v-if="!item.noForm"
                  v-bind="propsFields[item.name]" v-on="getEvents(item)" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>

          <v-expansion-panel-content v-if="group.type === 'address'">
            <AddressFormPage ref="AddressFormPage" :address="address"
            :address-current="addressCurrent" @setAddressByZipCode="setAddressByZipCode" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="mt-5">
        <Button label="Voltar" ref="btnBack" color="light" class="mr-2" rounded
          :icon="$icons.arrowLeft" @click="$router.back()" />
        <Button label="Salvar" ref="btnSave" v-if="!schema.listActions.noUpdate
          || (typePage === typePageOptions.create && schema.listActions.noUpdate)"
          color="primary"  rounded :icon="$icons.save" :loading="loadingSave"
          @click="save" :disabled="isDisabledSave" />
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from '@/service/';
import { mask } from 'vue-the-mask';
import TypePageMixin from '@/mixins/TypePageMixin';
import typesComponents from '@/utils/typesComponents';
import { DataTable, Button, Icon } from '@/components/vuetify';
import AddressFormPage from './components/AddressFormPage';
import { clone } from 'lodash';

export default {
  name: 'DynamicFormPage',
  components: { Icon, Button, AddressFormPage, DataTable },
  props: {
    schema: {
      type: Object,
      required: true
    },
    service: {
      type: Object,
      required: true
    },
    fixedPayload: {
      type: Object,
      default: () => {}
    }
  },
  directives: { mask },
  data() {
    return {
      value: 0,
      panel: [],
      valid: true,
      form: {},
      typesComponents: typesComponents,
      loadingSave: false,
      isDisabledSave: false,
      localItem: {},
      address: {},
      addressCurrent: {},
      itemsSelect: {},
      itemsChildren: {},
      propsFields: {},
      loadingForm: false,
    };
  },
  async mounted() {
    this.loadingForm = true;
    await this.mountItemsSelects();
    await this.getItemsChildren();
    this.schema.fields.forEach((group) => {
      group.items.forEach((item) => {
        this.$set(this.propsFields, item.name, {
          label: item.labelForm ?? item.label,
          readonly: this.setReadOnly(item.readonly),
          disabled: item.disabled,
          rules: item.rules,
          loading: this.loadingForm,
          ref: item.name,
          ...item.type === 'text' && { type: item.type, counter: item.counter, maxLength: item.counter },
          ...item.type === 'password' && { type: item.type, counter: item.counter, maxLength: item.counter },
          ...item.type === 'select' && { items: this.itemsSelect[item.name], default: item.default, itemText: item.itemText, itemValue: item.itemValue, multiple: item.multiple, clearable: item.clearable, newItem: item.newItem, returnObject: item.returnObject },
          ...item.type === 'autoComplete' && {
            items: this.itemsSelect[item.name],
            default: item.default,
            itemText: item.itemText,
            itemValue: item.itemValue,
            multiple: item.multiple,
            clearable: item.clearable,
            domain: item.domain,
            resultDomain: item.resultDomain,
            searchBy: item.searchBy,
            itemTextDataServe: item.itemTextDataServe,
            title: item.title,
            itemValueDataServe: item.itemValueDataServe,
            conditionOr: item.conditionOr,
          },
          ...item.type === 'percent' && { clearable: item.clearable, suffix: item.suffix, length: item.length, precision: item.precision, empty: item.empty },
          ...item.type === 'money' && { clearable: item.clearable, prefix: item.prefix, suffix: item.suffix, length: item.length, precision: item.precision, empty: item.empty },
          ...item.type === 'integer' && { name: item.name, clearable: item.clearable, inputMask: item.inputMask, outputMask: item.outputMask, applyAfter: item.applyAfter, empty: item.empty },
          ...item.type === 'simpleMask' && { name: item.name, clearable: item.clearable, inputMask: item.inputMask, outputMask: item.outputMask, applyAfter: item.applyAfter, empty: item.empty, alphanumeric: item.alphanumeric },
          ...item.type === 'dataPicker' && { name: item.name, noInitial: item.noInitial, now: item.now, clearable: item.clearable },
          ...item.type === 'checkBox' && { type: item.type, counter: item.counter, maxLength: item.counter },
        });
      });
    });

    await this.setValuesDefault();
    await this.typePage === this.typePageOptions.show ? this.show() : this.handlerBeforeForm();
  },
  computed: {
    fields () {
      return this.schema.fields.map((item) => {
        return {
          ...item,
          items: item.items.filter((field) => {
            return !field.noForm;
          })
        };
      });
    },
  },
  mixins: [TypePageMixin],
  methods: {
    getEvents (item) {
      return {
        change: () => this.changeBusiness(item),
        ...item.type === 'checkBox' && { setCheckBox: () => this.setCheckBox(item) },
        ...item.type === 'dataPicker' && { clearDate: () => this.clearDateDataPicker(item) },
        ...item.type === 'autoComplete' && { change: ($event) => this.changeAutoComplete(item, $event) }
      };
    },
    setCheckBox(value) {
      this.localItem[value.name] = !this.localItem[value.name];
    },
    changeAutoComplete(item, value) {
      this.localItem[item.name] = value;
    },
    async show() {
      const { id } = this.$route.params;

      this.service.show(id).then((res) => {
        let form = {};
        this.schema.fields.forEach((group) => {
          if (group.type === 'address' && this.typePage === this.typePageOptions.create) {
            this.isDisabledSave = true;
          }

          if (group.items) {
            group.items.forEach((item) => {
              form[item.name] = res[this.schema.domainSingle][item.name];
              if (item.type === 'select' && item.multiple) form[item.name] = res[this.schema.domainSingle][item.name];
            });
          }
        });
        this.localItem = form;
        this.address = res[this.schema.domainSingle].address || {};
        this.addressCurrent = clone(this.address);
        this.handlerBeforeForm();
      }).catch((err) => {
        this.$noty.error(err);
      });
    },
    save() {
      let errorForm = false;

      if (!this.$refs.form.validate()) {
        errorForm = true;
      }

      if (this.$refs.AddressFormPage) {
        if (!this.$refs.AddressFormPage[0].$refs.formAddress.validate()) {
          errorForm = true;
        }
      }

      if (!errorForm) {
        this.loadingSave = true;

        if  (this.schema.business){
          if ( typeof this.schema.business.beforeSave === 'function' ) {
            const result = this.schema.business.beforeSave({
              form: this.localItem,
              noty: this.$noty
            });

            if (result === 'error') {
              this.loadingSave = false;

              return;
            }
          }
        }

        this.typePage === this.typePageOptions.create ? this.create() : this.update();
      }
    },
    create() {
      if (this.schema.formAddress) this.localItem.address = this.address;
      this.service.create({ ...this.localItem, ...this.fixedPayload }).then((res) => {
        this.$noty.success(this.$locales.pt.default.alerts.createdRegister);
        this.$router.back();
        this.loadingSave = false;

        if  (this.schema.business){
          if ( typeof this.schema.business.afterCreated === 'function' ) {
            this.schema.business.afterCreated(this.localItem, { resultServer: res });
          }
        }
      }).catch((err) => {
        this.loadingSave = false;
        this.$noty.error(err);
      });
    },
    update() {
      const { id } = this.$route.params;
      if (this.schema.formAddress) this.localItem.address = this.address;

      this.service.update(id, { ...this.localItem, ...this.fixedPayload }).then((res) => {
        this.$noty.success(this.$locales.pt.default.alerts.updatedRegister);
        this.$router.back();
        this.loadingSave = false;

        if  (this.schema.business){
          if ( typeof this.schema.business.afterUpdated === 'function' ) {
            this.schema.business.afterUpdated( {
              id,
              form: this.localItem,
              resultServer: res,
              fixedPayload: this.fixedPayload
            });
          }
        }
      }).catch((err) => {
        this.$noty.error(err);
        this.loadingSave = false;
      });
    },
    setAddressByZipCode(address) {
      if (!address)
        return;

      let uf = {
        text: address.uf,
        value: address.uf,
        code_state: address.code_state,
      };

      if (!uf.text) {
        uf = null;
      }

      this.address = { ...this.address, ...address, uf };

      this.localItem.address = this.address;
      this.isDisabledSave = false;
    },
    async mountItemsSelects() {
      let promises = [];
      let itemsSelects = [];
      this.schema.fields.forEach((field, index) => {
        if (field.openGroup) {
          this.panel.push(index);
        }

        if (field.type === 'address') {
          this.panel.push(index);
        }

        if (field.items) {
          itemsSelects = field.items.filter((item) => {
            return item.type === 'select' && item.service?.has;
          });

          field.items.forEach((item) => {
            if (item.type === 'select' && !item.service?.has) {
              this.$set(this.itemsSelect, item.name, item.items);
            }
          });
        }
      });
      promises = itemsSelects.map(async (item) => {
        const res = await axios[item.service.verb](`${item.service.endpoint}?${item.service.queryParams}`);
        let data = res.data;

        if (item.isBeforeChangeSelect) {
          data = this.schema.business?.beforeChangeSelects[item.name]({
            apiData: res.data,
            schema: this.schema,
            route: this.$route,
          });
        }

        return {
          ...data,
          domain: item.service.domain,
          name: item.name,
          itemText: item.itemText,
          itemValue: item.itemValue,
          optional: item.service.optional,
          clearable: item.clearable,
        };
      });
      const result = await Promise.all(promises);
      result.forEach((res) => {
        const items = res[res.domain].map((i) => {
          return { [res.itemText]: i[res.itemText], [res.itemValue]: i[res.itemValue] };
        });
        if (res.optional && !res.clearable) items.unshift({ [res.itemText]: '', [res.itemValue]: 0 });
        this.itemsSelect[res.name] = items;
      });
    },
    changeBusiness(item) {
      if (this.schema.business?.changes && this.schema.business?.changes[item.name]) {
        this.schema.business?.changes[item.name]({
          form: this.localItem,
          schema: this.schema,
          route: this.$route,
          changeItem: item,
          refs: this.$refs,
          propsFields: this.propsFields
        });
      }
    },
    getItemsChildren() {
      this.schema.fields.forEach((field) => {
        if (field.type === 'dataTable') {
          axios[field.service.verb](`${field.service.endpoint}?${field.service.queryParams}`).then((res) => {
            this.$set(this.itemsChildren, 'all_types', res.data);
          }).catch(() => {
          });
        }
      });
    },
    handlerBeforeForm() {
      if (this.schema.business?.beforeForm) {
        this.schema.business?.beforeForm({
          form: this.localItem,
          schema: this.schema,
          route: this.$route,
          ref: this.$refs,
          propsFields: this.propsFields,
          itemsSelects: this.itemsSelect
        });
      }
    },
    setReadOnly (readonly) {
      if (readonly instanceof Object) {
        if (this.typePage === this.typePageOptions.show) {
          return readonly.update;
        }

        return readonly.create;
      }

      return readonly;
    },
    setValuesDefault () {
      this.schema.fields.forEach((group) => {
        group.items?.forEach((item) => {
          this.propsFields[item.name].loading = false;

          if (item.itemValueDefault) {
            this.localItem[item.name] = item.default;
          }
        });
      });
    },
    clearDateDataPicker (item) {
      this.localItem[item.name] = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.expansion-panel {
  border-radius: 25px !important;
  background-color: #EFEFEF;
  padding: 25px !important;
}
</style>
