export default {
  modalBoxMovementsOpenOrClose: {
    title: {
      title: 'Abertura e Fechamento de Caixa',
      open: 'Abrir Caixa',
      close: 'Fechar Caixa'
    },
    form: {
      date_open: {
        label: 'Data de Abertura',
        placeholder: 'Data de Abertura'
      },
      date_close: {
        label: 'Data de Fechamento',
        placeholder: 'Data de Fechamento'
      },
      employee: {
        label: 'Funcionário',
        placeholder: 'Funcionário'
      },
      balance: {
        label: 'Saldo',
        placeholder: 'Saldo'
      }
    },
    actions: {
      save: 'Salvar',
      box_open: 'Caixa aberto com sucesso',
      box_close: 'Caixa fechado com sucesso'
    }
  },
  modalMovements: {
    title: {
      details: 'Detalhes do Caixa',
      list: 'Abertura e Fechamento'
    },
    actions: {
      create: 'Adicionar Movimentação',
      removeMovement: 'Movimentação excluído com sucesso!'
    },
    tooltip: {
      view: 'Visualizar Movimentações',
      destroy: 'Excluir Movimentação'
    },
    box: {
      description: {
        label: 'Descrição'
      },
      balance: {
        label: 'Saldo'
      }
    }
  },
  modalMovementItems: {
    title: 'Movimentações do Caixa',
    actions: {
      create: 'Adicionar Movimentação',
      removeMovement: 'Movimentação excluído com sucesso!'
    },
    tooltip: {
      view: 'Visualizar Movimentação',
      destroy: 'Excluir Movimentação'
    },
    box: {
      description: {
        label: 'Descrição'
      },
      balance: {
        label: 'Saldo'
      }
    },
    modalCreateMovementItem: {
      title: {
        new: 'Nova Movimentação',
        view: 'Visualizar Movimentação'
      },
      actions: {
        save: 'Salvar',
        createMovementItem: 'Movimentação criada com sucesso!'
      },
      form: {
        movement_date: {
          label: 'Data'
        },
        movement_type: {
          label: 'Tipo de Movimentação'
        },
        type_transaction: {
          label: 'Tipo de Transação'
        },
        paymentMethod: {
          label: 'Forma de Pagamento',
          placeholder: 'Seleciona a Forma de Pagamento',
        },
        description: {
          label: 'Descrição da Movimentação',
          placeholder: 'Descrição da Movimentação'
        },
        value: {
          label: 'Valor',
          placeholder: 'Valor da Movimentação'
        }
      },
    }
  }
};
