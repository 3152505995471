<template>
  <div class="d-flex align-center">
    <v-select
      hide-details="auto"
      v-bind="$attrs"
      v-on="$listeners"
      :ref="refs"
      :id="id"
      :type="type"
      :label="label"
      :rules="rules"
      :dense="dense"
      :readonly="readonly"
      :outlined="outlined"
      :disabled="disabled"
      :prepend-icon="icon"
      :loading="loading"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :multiple="multiple"
      :clearable="clearable"
      :class="[{'mr-2': newItem.has}]">
    </v-select>
    <ButtonIcon v-if="newItem.has" x-small color="success" :icon="$icons.plusOutline"
      :tooltip="'Novo Item'" @click="dialog = true" />

    <Dialog :dialog="dialog" no-title :maxWidth="1236">
      <div slot="content">
        <Card toolbar color="grey lighten-2" style="background: #F6F7FB">
          <div slot="toolbar"><h2>Novo Item</h2></div>

          <div class="content-main-modal-card-default">
            <ExpansionPanel v-model="panel" :title="'Novo Item'"
              :icon="$icons.box" readonly>
            </ExpansionPanel>
          </div>

          <div slot="actions" class="d-flex justify-end px-5" style="width: 100%">
            <Button :label="'Fechar'" rounded color="primary" @click="dialog = false" />
          </div>
        </Card>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { ButtonIcon, Dialog, Card, Button } from './';
import ExpansionPanel from './ExpansionPanel';

export default {
  name: 'Select',
  props: {
    id: {
      type: String,
      default: '',
    },
    refs: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    counter: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    newItem: {
      type: Object,
      default: () => {
        return {
          has: false
        };
      },
    },
  },
  components: {
    ButtonIcon,
    Dialog,
    Card,
    Button,
    ExpansionPanel,
  },
  data () {
    return {
      panel: [0],
      dialog: false,
    };
  }
};
</script>
