import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';

export default {
  domain: 'service_invoices',
  domainSingle: 'service_invoice',
  title: 'NF-e de Serviço',
  description: 'Lista de todos os Nota Fiscal de Serviço do sistema',
  createBtn: 'Nova NF-e de Serviço',
  showInactive: false,
  routes: {
    list: {
      name: 'service-invoices',
      path: '/nota-fiscal-services',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'service-invoice-create',
      path: '/nota-fiscal-services/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'service-invoice-show',
      path: '/nota-fiscal-services/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: [
      ]
    },
  },
  filters: {
    has: true,
    items: [
      {
        type: 'text',
        name: 'number_title',
        label: 'N° Títutlo',
        icon: text,
        md: 6,
      },
      {
        type: 'select',
        name: 'register_id',
        label: 'Cadastro',
        icon: text,
        md: 6,
        items: []
      },
    ]
  },
  fields: [
    {
      title: 'Dados da Nota Fiscal de Serviço',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'emission_date',
          formattedName: 'emission_date_formatted',
          label: 'Emissão',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'number',
          formattedName: 'number',
          label: 'Número',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'key',
          formattedName: 'key',
          label: 'Chave',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'client_id',
          formattedName: 'client.name',
          label: 'Cliente',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'total',
          formattedName: 'total_formatted',
          label: 'Valor Total',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};