<template>
  <div>
    <ModalCardDefault :title="l.modalListHeader.title" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Informações Gerais'" :icon="$icons.box" readonly>
        <v-form v-model="validForm" ref="validForm" lazy-validation class="px-5 pt-5">
          <Row>
            <Col md="6">
              <Select v-model="form.stock_ids"
                :label="l.modalListHeader.form.fields.stock.label" :items="stocks"
                item-text="name" item-value="id" multiple :rules="[rules.required]" />
            </Col>
            <Col md="6">
              <Select v-model="form.movement_type"
                :label="l.modalListHeader.form.fields.movementType.label"
                :items="typeOperationStock" />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <TextField v-model="form.bar_code"
                :label='l.modalListHeader.form.fields.barCode.label'
                v-on:keyup.enter="getProductVariationByBarCode"
                :loading="loadingBarCode" @keyup="keyupBarCode" :readonly="isReadonly" />
            </Col>
            <Col md="6">
              <TextField v-if="isReadonly" v-model="productSelected.name"
                label='Produto' :readonly="isReadonly" />
              <AutoComplete v-else v-model="form.product_variation" :placeholder="l.modalListHeader.form.fields.productVariation.label"
                :label="l.modalListHeader.form.fields.productVariation.label" return-object
                domain="product_variation" resultDomain="product_variations"
                :search-by="['code', 'name', 'reference', 'bar_code']" item-text-data-serve="name"
                title="Pesquise por: Código, Nome, Referência, e Código de Barras"
                item-value-data-serve="id" :formatItemName="formatItemName"
                @change="changeProductVariation" :readonly="isReadonly"
                condition-or />
            </Col>
            <Col md="3">
              <TextFieldMoney v-model="form.quantity"
                :label="l.modalListHeader.form.fields.quantity.label"
                :rules="[rules.money]" prefix="" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

      <div slot="actions" >
        <Button :label="l.modalListHeader.actions.save" :loading="loadingSave"
          rounded class="mr-3" color="secondary" @click="save" />
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Button,
  Row,
  Col,
  TextField,
  Select,
  TextFieldMoney,
  ExpansionPanel,
  AutoComplete,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required, money } from '@/utils/rules';
import { typeOperationStock } from '@/utils/options';
import { typeStockMovements } from '@/utils/enums';

export default {
  name: 'CreateStockMovement',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Button,
    Row,
    Col,
    TextField,
    Select,
    TextFieldMoney,
    AutoComplete,
  },
  props: {
    stocks: {
      type: Array,
      required: true,
    },
    productSelected: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      panel: [0],
      validForm: false,
      dialogModal: true,
      loadingBarCode: false,
      loadingSave: false,
      rules: {
        required: required,
        money: money
      },
      typeOperationStock: typeOperationStock,
      form: {
        stock_ids: [],
        product_variation: null,
        bar_code: '',
        quantity: 1,
        movement_type: typeStockMovements.INPUT
      },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.stocks.list.modalMovements;
    },
    isReadonly () {
      return !!this.productSelected;
    }
  },
  methods: {
    getProductVariationByBarCode () {
      this.loadingBarCode = !this.loadingBarCode;
      this.$api.products.getProductVariationByBarCode(this.form.bar_code)
        .then((res) => {
          if (res.data.to <= 0) {
            this.$noty.error('Produto não localizado');
          }

          this.form.product_variation = res.data.product_variations[0];
          this.save();
        })
        .catch(() =>  {})
        .finally(() => {
          this.loadingBarCode = !this.loadingBarCode;
        });
    },
    changeProductVariation (item) {
      this.form.bar_code = item.bar_code;

      if (!item.id) {
        this.$noty.error('Produto não localizado');
      }
      this.form.product_variation = item;
    },
    keyupBarCode () {
      this.form.product_variation = null;
    },
    save () {
      if (this.$refs.validForm.validate()) {
        let error = false;

        if (!this.form.product_variation?.id && !this.form.bar_code) {
          this.$noty.error('Produto não localizado');
          error = true;
        }
        error;
        this.loadingSave = true;
        const product_variation_id = this.form.product_variation?.id;
        delete this.form.product_variation;
        const payload = {
          ...this.form,
          product_variation_id,
          description: this.l.createStockMovement.description,
        };

        this.$api.stocks.createStockMovement(payload).then(() => {
          this.$noty.success(this.l.noty.successCreateStockMovement);

          if (!this.isReadonly) {
            this.form.bar_code = '';
            this.form.product_variation = null;
          }
        }).catch((err) => {
          if (err.response.status === 400) {
            this.$noty.error(err.response.data.error);

            return;
          }
          this.$noty.error(this.l.noty.errorCreateStockMovement);
        }).finally(() => {
          this.loadingSave = false;
          this.form.quantity = 1;
        });
      }
    },
    formatItemName (item) {
      let variations = '';
      item.product_variation_values.forEach((productVariationValue) => {
        variations += `${productVariationValue.variation_value.description} - `;
      });
      variations = variations.slice(0, -2);

      return {
        ...item.product.has_variation && { text: `${item.name}: ${variations}` },
        ...!item.product.has_variation && { text: `${item.name}` },
        value: item.id
      };
    },
    getProductVariationByProductId() {
      this.$api.productVariations.getProductVariationsByProductId(this.productSelected.id)
        .then(({ data: { product_variations } }) => {
          this.form.bar_code = this.productSelected.bar_code;
          this.form.product_variation = product_variations[0];
        }).catch(() => {
          this.$noty.error('Erro ao carregar as Variações');
        });
    }
  },
  mounted () {
    if (this.stocks.length === 1) {
      this.form.stock_id = this.stocks[0].id;
    }

    if (this.isReadonly) {
      this.getProductVariationByProductId();
    }
  }
};
</script>

<style>

</style>
