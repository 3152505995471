import DynamicService from '@/service/DynamicService';
import CompanySettingSchema from '../schemas/CompanySettingSchema';
import { typePage } from '@/utils/enums';

const orders = [
  'last_order_sale_number',
  'last_order_purchase_number',
  'last_order_budget_number',
  'last_order_sale_devolution_number',
  'last_order_purchase_devolution_number',
  'last_order_sale_pdv_number',
  'last_order_sale_consigned_number',
  'last_order_sale_table_number',
];

const getValueDefault = (isSequential, value) => {
  if (isSequential) {
    return value || 0;
  }
};

const formatResponse = (item, type = typePage.LIST) => {
  const formatShow = (item) => {
    orders.forEach((order) => {
      item[order] = getValueDefault('is_order_number_sequential',
        item[order]);
    });
  };

  const formatList = (item) => {
    orders.forEach((order) => {
      item[order] = getValueDefault('is_order_number_sequential',
        item[order]);
    });
    item.is_order_number_sequential = item.is_order_number_sequential ? 'Sim' : 'Não';
  };

  type === typePage.LIST ? formatList(item) : formatShow(item.company_setting);
};

const CompanySettingsService = DynamicService('company_settings', CompanySettingSchema, {
  formatResponse,
  notRemoveDefaultKeys: true
});

const CompanySettingsCommands = () => ({
});

export default CompanySettingsService;
export { CompanySettingsService, CompanySettingsCommands };
