<template>
  <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
    <Row>
      <Col md="12">
        <DataTable no-search no-sync hide-default-footer :items="itemsDevolutions"
          :headers="l.headers" :loading="false">
        </DataTable>
      </Col>
    </Row>
  </ExpansionPanel>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  DataTable
} from '@/components/vuetify';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';

export default {
  name: 'OrderDevolutions',
  components: {
    ExpansionPanel,
    Row,
    Col,
    DataTable
  },
  mixins: [UpdateInfosOrderSaleMixin],
  data () {
    return {
      panel: [0],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.devolutions;
    },
    itemsDevolutions () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.model.devolution_histories.sort((a, b) => a.item_number - b.item_number);
    },
  },
};
</script>
