import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import BalanceSchema from '../schemas/BalanceSchema';
import { formatCurrency, getText } from '@/utils';
import { typePage } from '@/utils/enums';
import { statusBalance } from '@/utils/options';

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    item.total_price_retail_formatted = formatCurrency(item.total_price_retail);
    item.total_price_wholesale_formatted = formatCurrency(item.total_price_wholesale);
    item.total_price_retail_measured_formatted = formatCurrency(item.total_price_retail_measured);
    item.total_price_wholesale_measured_formatted = formatCurrency(item.total_price_wholesale_measured);
    item.status_formatted = getText(statusBalance, item.status);
  };

  const formatShow = () => {
  };

  type === typePage.LIST ? formatList(item) : formatShow(item);
};

const formatResponseGetAllBalanceItemsByBalanceId = ({ data: { balance_items } }) => {
  return balance_items.map((balance_item) => {
    return {
      ...balance_item,
    };
  });
};

const BalancesService = DynamicService('balances',
  BalanceSchema, { formatResponse });

const BalancesCommands = () => ({
  async getAllBalanceItemsByBalanceId(balanceId, missingProduct, pagination) {
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page : 1;

    let url = `${BalanceSchema.domain}/${balanceId}/balance-items?per_page=${itemsPerPage || 5}&page=${page || 1}`;

    if (missingProduct) {
      url += `&missing-product=${missingProduct}`;
    }
    const result = await axios.get(url);

    return {
      ...result.data,
      data: formatResponseGetAllBalanceItemsByBalanceId(result)
    };
  },
  async measure(balanceId, balanceItemId) {
    return  await axios.post(`${BalanceSchema.domain}/${balanceId}/balance-items/${balanceItemId}/measure`);
  },
  async measureByBarCode(balanceId, barCode) {
    return  await axios.post(`${BalanceSchema.domain}/${balanceId}/balance-items/measure/by-bar-code`, { bar_code: barCode });
  },
  async finish(balanceId, resetProductNotChanged) {
    await axios.put(`${BalanceSchema.domain}/${balanceId}/finish`, { reset_product_not_changed: resetProductNotChanged });
  },
  async cancel(balanceId) {
    await axios.put(`${BalanceSchema.domain}/${balanceId}/cancel`);
  }
});

export default BalancesService;
export { BalancesService, BalancesCommands };
