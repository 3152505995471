import { formatCurrency } from '@/utils';
import { typeStockMovements } from '@/utils/enums';

export default {
  beforeList: ({ form }) => {
    let total_outputs = 0;
    let total_inputs = 0;
    form.stock_movements.forEach((item) => {
      if (item.movement_type === typeStockMovements.OUTPUT) {
        total_outputs += item.quantity;
      } else {
        total_inputs += item.quantity;
      }
    });
    form.total_outputs = formatCurrency(total_outputs, { type: 'decimal' });
    form.total_inputs = formatCurrency(total_inputs, { type: 'decimal' });
  },
};
