import AccountSchema from './schemas/AccountSchema';
import ListAccounts from './pages/ListAccounts';
import CreateAccount from './pages/CreateAccount';

let routes = [];

Object.keys(AccountSchema.routes).forEach((key) => {
  Object.keys(AccountSchema.routes[key]).forEach((itemKey) => {
    routes.push({
      path: AccountSchema.routes[key][itemKey].path,
      name: AccountSchema.routes[key][itemKey].name,
      component: itemKey === 'list' ? ListAccounts : CreateAccount,
      meta: AccountSchema.routes[key][itemKey].meta,
    });
  });
});

export default routes;
