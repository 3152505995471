export const menuItemsFinancial = [
  {
    active: false,
    menuGroupName: 'financial',
    icon: 'financial',
    title: 'Financeiro',
    items: [
      { icon: 'box', title: 'Movimentações', route: 'financial_movements', active: false },
      { icon: 'bank', title: 'Bancos', route: 'banks', active: false },
      { icon: 'accountCreditCardOutline', title: 'Admin Cartões', route: 'credit-card-administrators', active: false },
      { icon: 'box', title: 'Caixas', route: 'boxes', active: false },
      { icon: 'cashMinus', title: 'Pagamentos', route: 'accountPayments', active: false },
      { icon: 'cashPlus', title: 'Recebimentos', route: 'accountReceivables', active: false },
      { icon: 'cashClock', title: 'Títulos em Aberto', route: 'open-titles', active: false },
      { icon: 'creditCardSearchOutline', title: 'Controle Cartões', route: 'creditCardControls', active: false },
      { icon: 'checkbook', title: 'Controle Cheques', route: 'check_controls', active: false },
      { icon: 'ticketPercentOutline', title: 'Controle Boletos', route: 'billet_controls', active: false },
      { icon: 'accountCreditCardOutline', title: 'Carta de Créditos', route: 'creditLetters', active: false },
    ],
  },
];
