export default {
  modalStates: {
    title: 'Lista das Natureza de Operações por Estado',
    actions: {
      create: 'Criar',
      removeState: 'Estado excluído com sucesso!'
    },
    tooltip: {
      view: 'Visualizar Estado',
      destroy: 'Excluir Estado'
    },
    modalCreateState: {
      title: {
        new: 'Novo Estado',
        edit: 'Editar Estado'
      },
      actions: {
        save: 'Salvar',
        createState: 'Estado criado com sucesso!',
        updateState: 'Estado atualizado com sucesso!'
      },
      form: {
        icms: {
          cst: {
            label: 'CST'
          },
          origin: {
            label: 'Origem',
          },
          simple: {
            label: 'Simples',
          },
          aliquot: {
            label: 'Alíquota',
            placeholder: 'Alíquota',
          },
          determinateMode: {
            label: 'Modalidade determinação da BC do ICMS',
          },
          exemptionReason: {
            label: 'Motivo de Desoneração',
          },
          reduceValue: {
            label: 'Reduzir Valor do ICMS'
          },
          reduceBase: {
            label: 'Reduzir Base do ICMS'
          },
          includeDiscounts: {
            label: 'Incluir Desconto no Cálculo do ICMS'
          },
          includeIpi: {
            label: 'Incluir IPI no Cálculo do ICMS'
          },
          includeExpenses: {
            label: 'Incluir Despesas no Cálculo do ICMS'
          },
          includeFreight: {
            label: 'Incluir Frete no Cálculo do ICMS'
          },
          percentReduceBase: {
            label: '% Redução da Base',
            placeholder: '% Redução da Base'
          },
          aliquotDeferment: {
            label: 'Alíquota de Diferimento',
            placeholder: 'Alíquota de Diferimento'
          },
          aliquotFcp: {
            label: 'Alíquota de FCP',
            placeholder: 'Alíquota de FCP'
          }
        },
        icmsSt: {
          determinateMode: {
            label: 'Modalidade determinação da BC do ICMS ST',
          },
          aliquotMva: {
            label: 'MVA',
            placeholder: 'MVA'
          },
          aliquotIcmsInternal: {
            label: 'Alíquota ICMS Interno',
            placeholder: 'Alíquota ICMS Interno'
          },
          percentReduceBase: {
            label: '% Redução da Base',
            placeholder: '% Redução da Base'
          },
          reduceValue: {
            label: 'Reduzir Valor do ICMS ST'
          },
          reduceBase: {
            label: 'Reduzir Base do ICMS ST'
          },
          highlightIcmsOwn: {
            label: 'Destacar ICMS ST Próprio'
          },
          difalPerST: {
            label: 'Difal por ST'
          },
          includeDiscounts: {
            label: 'Incluir Desconto no Cálculo do ICMS ST'
          },
          includeIpi: {
            label: 'Incluir IPI no Cálculo do ICMS ST'
          },
          includeExpenses: {
            label: 'Incluir Despesas no Cálculo do ICMS ST'
          },
          includeFreight: {
            label: 'Incluir Frete no Cálculo do ICMS ST'
          },
          aliquotFcp: {
            label: 'Alíquota de FCP',
            placeholder: 'Alíquota de FCP'
          },
          valueFecop: {
            label: 'FECOP',
            placeholder: 'FECOP'
          },
          numberFecop: {
            label: 'Número FECOP',
            placeholder: 'Número FECOP'
          },
          valuePmpf: {
            label: 'Valor de PMPF',
            placeholder: 'Valor de PMPF'
          }
        },
        final_consumer: {
          aliquotInterstate: {
            label: '% ICMS Interestadual',
            placeholder: '% ICMS Interestadual'
          },
          aliquotIcmsInternal: {
            label: 'Alíquota ICMS Interno',
            placeholder: 'Alíquota ICMS Interno'
          },
          icmsInternalBase: {
            label: 'Incluir ICMS Interno na Base'
          },
          icmsNotUfOrigin: {
            label: 'Incluir ICMS Interno na Base'
          },
          aliquotFcp: {
            label: 'Alíquota de FCP',
            placeholder: 'Alíquota de FCP'
          },
          aliquotOperation: {
            label: 'Alíquota ICMS da Operação',
            placeholder: 'Alíquota ICMS da Operação'
          },
        },
        ipi: {
          cst: {
            label: 'CST'
          },
          aliquot: {
            label: 'Alíquota',
            placeholder: 'Alíquota',
          },
          classFramework: {
            label: 'Classe de Enquadramento',
            placeholder: 'Clase de Enquadramento'
          },
          codeFramework: {
            label: 'Código de Enquadramento',
            placeholder: 'Código de Enquadramento'
          },
          includeDiscounts: {
            label: 'Incluir Desconto no Cálculo do IPI'
          },
          includeExpenses: {
            label: 'Incluir Despesas no Cálculo do IPI'
          },
          includeFreight: {
            label: 'Incluir Frete no Cálculo do IPI'
          },
        },
        pis: {
          cst: {
            label: 'CST'
          },
          aliquot: {
            label: 'Alíquota',
            placeholder: 'Alíquota',
          },
          percentReduceBase: {
            label: '% Redução da Base',
            placeholder: '% Redução da Base'
          },
          reduceBase: {
            label: 'Reduzir Base do PIS'
          },
          includeDiscounts: {
            label: 'Incluir Desconto no Cálculo do PIS'
          },
          includeIpi: {
            label: 'Incluir IPI no Cálculo do PIS'
          },
          includeExpenses: {
            label: 'Incluir Despesas no Cálculo do PIS'
          },
          includeFreight: {
            label: 'Incluir Frete no Cálculo do PIS'
          },
        },
        cofins: {
          cst: {
            label: 'CST'
          },
          aliquot: {
            label: 'Alíquota',
            placeholder: 'Alíquota',
          },
          percentReduceBase: {
            label: '% Redução da Base',
            placeholder: '% Redução da Base'
          },
          reduceBase: {
            label: 'Reduzir Base do COFINS'
          },
          includeDiscounts: {
            label: 'Incluir Desconto no Cálculo do COFINS'
          },
          includeIpi: {
            label: 'Incluir IPI no Cálculo do COFINS'
          },
          includeExpenses: {
            label: 'Incluir Despesas no Cálculo do COFINS'
          },
          includeFreight: {
            label: 'Incluir Frete no Cálculo do COFINS'
          },
        },
        additionalInfo: {
          infoContributor: {
            label: 'Informações do Contribuinte',
            placeholder: 'Informações do Contribuinte'
          },
          infoTax: {
            label: 'Informações do Fisco',
            placeholder: 'Informações do Fisco'
          }
        }
      }
    },
  },
};
