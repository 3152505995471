import { formatCurrency } from '@/utils';

export default {
  beforeList: ({ form, schema }) => {
    form.total_value_receivable = formatCurrency(form.total_value_receivable);
    form.value_receivable_pending = formatCurrency(form.value_receivable_pending);
    form.total_value_receivable_paid = formatCurrency(form.total_value_receivable_paid);
    form.total_value_payment = formatCurrency(form.total_value_payment);
    form.value_payment_pending = formatCurrency(form.value_payment_pending);
    form.total_value_payment_paid = formatCurrency(form.total_value_payment_paid);

    schema.footer = schema.footer.filter((item) => {
      return item.type === window.location.pathname.replace(/^\/+/g, '');
    });
  },
};
