<template>
  <div>
    <PageHeader :schema="$schemas.report" :create-route-name="createRouteName"
      :create-btn="createBtn" :titleCustom="title" :descriptionCustom="description" />
    <PageContent>
      <DynamicListPage :schema="$schemas.report" :service="$api.reports" fixed-filter
        :fixedFilterParams="fixedFilterParams" :slots="slotsItems"
        :key="keyComponent">
      </DynamicListPage>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';

const CREATE_BTN = {
  'report-client-create': 'Novo Relatório Cliente',
};

export default {
  name: 'ListReports',
  components: { PageHeader, PageContent, DynamicListPage },
  data () {
    return {
      slotsItems: [{ field: 'value' }],
      keyComponent: 0,
    };
  },
  computed: {
    createRouteName() {
      return this.$schemas.report.routes[this.$route.meta.type].create.name;
    },
    createBtn () {
      return CREATE_BTN[this.$schemas.report.routes[this.$route.meta.type].create.name];
    },
    fixedFilterParams () {
      return { name: this.$route.meta.payloadFixed.filter, value: this.$route.meta.payloadFixed.value, fixedFilter: true };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    },
  },
  watch: {
    title () {
      this.keyComponent++;
    }
  },
  methods: {
  }
};
</script>
