<template>
  <div class="content-login" style="background-color: #3B5998;">
    <div class="content-login--form">
      <div class="content-login--form---left">
        <img src="@/assets/ilustration-register.png" width="350" alt="">
        <p>Seu negócio na palma de sua mão.</p>
      </div>
      <div class="content-login--form---right">
        <div class="content-login--form---right----title">
          <h3>Faça o seu cadasdro</h3>
          <p>Preencha o formuário abaixo de cadastre-se gratuitamente.</p>
        </div>

        <v-divider class="my-5"></v-divider>

        <v-form v-model="valid" ref="form" lazy-validation v-on:submit.prevent="register()">
          <div class="content-login--form---right----form">
            <TextField v-model="form.company.name" label="Razão Social" />
            <TextFieldSimpleMask v-model="form.company.cnpj" label="CNPJ"
              :input-mask="masks.cnpj" output-mask="##############" />
            <TextField v-model="form.user.name" label="Nome do Contato" />
            <TextField v-model="form.user.email" label="E-mail do Contato" />
            <TextFieldSimpleMask v-model="form.company.cell_phone" label="Celular/Whatsapp"
              :input-mask="masks.cell_phone" output-mask="###########" />
            <TextField v-model="form.user.password" label="Senha"
            type="password" v-on:keyup.enter="register()" />
            <Button label="Cadastrar" color="primary" class="btn-login"
              :loading="loading" @click="register()" :disabled="!valid" />
          </div>
        </v-form>

        <p>Já tenho cadastro. <router-link to="/login">Fazer login</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import { google, facebook } from '@/utils/icons';
import { isLogged, messageErrors } from '@/utils';
import { mask } from 'vue-the-mask';
import { cell_phone, cnpj } from '@/utils/masks';
import { TextFieldSimpleMask, TextField,Button } from '@/components/vuetify';

export default {
  name: 'Login',
  components: { Button, TextField, TextFieldSimpleMask },
  directives: { mask },
  data() {
    return {
      icons: {
        google: google,
        facebook: facebook
      },
      masks: {
        cell_phone: cell_phone,
        cnpj: cnpj
      },
      form: {
        company: {
          name: '',
          cnpj: '',
          cell_phone: '',
        },
        user: {
          name: '',
          email: '',
          password: '',
          first: true,
        }
      },
      loading: false,
    };
  },
  mounted() {
    if (isLogged)
      this.$router.push({ name: 'home' });
  },
  computed: {
    l () {
      return this.$locales.pt.auth.register;
    },
    valid: {
      get() {
        return this.form.company.name &&
        this.form.company.cnpj &&
        this.form.company.cell_phone &&
        this.form.user.name &&
        this.form.user.email &&
        this.form.user.password ? true : false;
      },
      set() {
        return true;
      }
    }
  },
  methods: {
    register() {
      if (this.valid) {
        this.loading = true;
        this.$api.auth.register(this.form).then(() => {
          this.loading = false;
        }).catch((err) => {
          this.loading = false;

          if (err.response.status === 422) {
            this.$noty.error(messageErrors(err.response));
          } else if (err.response.status === 400) {
            this.$noty.error(err.response.data.error);
          } else {
            this.$noty.error(this.l.noty.errorRegister);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles.scss';
</style>
