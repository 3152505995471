<template>
  <Row>
    <Col>
      <div class="pdv-top">
        <!-- <div class="pdv-top--logo">
          <p>Logo</p>
        </div> -->
        <div class="pdv-top--infos">
          <p class="mt-1">Nome da Empresa</p>
        </div>
      </div>
    </Col>
  </Row>
</template>

<script>
import { Row, Col } from '@/components/vuetify';

export default {
  name: 'PdvTop',
  components: { Row, Col }
};
</script>

<style lang="scss" scoped>
.pdv-top {
  display: flex;
  width: 100%;

  .pdv-top--logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 20px;
    background-color: #069;
    color: #FFF;
    border-bottom-right-radius: 25px;
    -webkit-box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
    -moz-box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
    box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
  }

  .pdv-top--infos {
    position: relative;
    height: 30px;
    width: 100%;
    background-color: #069;
    border-radius: 0 0 25px 0;
    -webkit-box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
    -moz-box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);
    box-shadow: 2px 10px 5px -7px rgba(0,0,0,0.6);

    p {
      text-align: center;
      color: #FFF;
    }
  }

  .pdv-top--infos::before {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: -50px;
    height: 50px;
    width: 25px;
    border-top-left-radius: 25px;
    box-shadow: 0 -25px 0 0 #069;
  }
}
</style>
