<template>
  <div>
    <v-form v-model="validFormCofins" ref="validFormCofins" lazy-validation>
      <Row class="mt-5">
        <Col>
          <Select v-model="model.cofins_cst" :label="l.cst.label"
            :items="cofinsCST" itemText="text" itemValue="value"
            :rules="[rules.required]" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <TextFieldPercent v-model="model.cofins_aliquot"
            :placeholder="l.aliquot.placeholder"
            :label="l.aliquot.label" />
        </Col>
        <Col md="4">
          <TextFieldPercent v-model="model.cofins_percent_reduce_base"
            :placeholder="l.percentReduceBase.placeholder"
            :label="l.percentReduceBase.label" />
        </Col>
        <Col md="4">
          <CheckBox v-model="model.cofins_reduce_base"
            @setCheckBox="setCheckBox($event, 'reduce_base')"
          :label="l.reduceBase.label" class="mt-0"/>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <CheckBox v-model="model.cofins_include_discounts"
            @setCheckBox="setCheckBox($event, 'cofins_include_discounts')"
            :label="l.includeDiscounts.label" class="mt-0"/>
        </Col>
        <Col md="3">
          <CheckBox v-model="model.cofins_include_ipi"
            @setCheckBox="setCheckBox($event, 'cofins_include_ipi')"
            :label="l.includeIpi.label" class="mt-0"/>
        </Col>
        <Col md="3">
          <CheckBox v-model="model.cofins_include_expenses"
            @setCheckBox="setCheckBox($event, 'cofins_include_expenses')"
            :label="l.includeExpenses.label" class="mt-0"/>
        </Col>
        <Col md="3">
          <CheckBox v-model="model.cofins_include_freight"
            @setCheckBox="setCheckBox($event, 'cofins_include_freight')"
            :label="l.includeFreight.label" class="mt-0"/>
        </Col>
      </Row>
    </v-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextFieldPercent,
} from '@/components/vuetify';
import CheckBox from '@/components/vuetify/CheckBox';
import { required } from '@/utils/rules';
import { cofinsCST } from '@/utils/options';

export default {
  name: 'Pis',
  components: {
    Row,
    Col,
    Select,
    TextFieldPercent,
    CheckBox
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: { required: required },
      validFormCofins: false,
      cofinsCST: cofinsCST
    };
  },
  mounted () {
  },
  computed: {
    l () {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState.form.cofins;
    },
  },
  methods: {
    setCheckBox(value, type) {
      this.model[type] = value || false;
    }
  }
};
</script>
