import PdvSchema from './schemas/PdvSchema';
import Pdv from './pages/Pdv';

export default [
  {
    path: PdvSchema.routes.pdv.path,
    name: PdvSchema.routes.pdv.name,
    component: Pdv,
    meta: PdvSchema.routes.pdv.meta,
  },
];
