/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { typePage } from '@/utils/enums';

export default {
  beforeSave: ({ form, noty }) => {
    let result = true;

    if (form.password.length < 8) {
      noty.error('A senha deve ter no mínimo 8 caracteres');
      result = 'error';
    }

    if (form.password !== form.password_confirmation) {
      noty.error('As senhas não conferem');
      result = 'error';
    }

    return result;
  },
};
