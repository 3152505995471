import { getTypeUser } from '@/utils';
import { TYPE_USER } from '@/utils/enums';
import { menuItemsHome } from '@/views/Home/';
import { menuItemsAllTypes } from '@/views/AllTypes/';
import { menuItemsGeneralRegisters } from '@/views/GeneralRegisters/';
import { menuItemsProducts } from '@/views/Products/';
import { menuItemsMovements } from '@/views/Movements/';
import { menuItemsOperations } from '@/views/Operations/';
import { menuItemsFinancial } from '@/views/Financial/';
import { menuItemsTaxOperations } from '@/views/TaxOperations/';
import { menuItemsPlanning } from '@/views/Planning/';
import { menuItemsReports } from '@/views/Reports/';

const typeUser = getTypeUser();
const menus = [];

if (typeUser === TYPE_USER.DISTRIBUTOR || typeUser === TYPE_USER.RESELLER) {
  menus.push(...menuItemsHome);
} else {
  menus.push(
    ...menuItemsHome,
    ...menuItemsAllTypes,
    ...menuItemsGeneralRegisters,
    ...menuItemsProducts,
    ...menuItemsMovements,
    ...menuItemsOperations,
    ...menuItemsTaxOperations,
    ...menuItemsFinancial,
    ...menuItemsPlanning,
    ...menuItemsReports,
  );
}

export default menus;
