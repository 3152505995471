<template>
  <div style="padding: 0 15px;">
    <Danfe55Header :model="model" />
    <Danfe55GeneralInfos :model="model" class="mt-1" />
    <Danfe55Client :model="model" class="mt-1" />
    <Danfe55Bill :model="model" class="mt-1" />
    <Danfe55BillDuplicates v-if="model.bill.duplicates.length > 0"
      :model="model" class="mt-1" />
    <Danfe55Taxes :model="model" class="mt-1" />
    <Danfe55Transporter :model="model" class="mt-1" />
    <Danfe55Items :model="model" class="mt-1" />
    <Danfe55AdditionalData :model="model" class="mt-1" />
  </div>
</template>

<script>
import Danfe55Header from './components/Danfe55Header';
import Danfe55GeneralInfos from './components/Danfe55GeneralInfos';
import Danfe55Client from './components/Danfe55Client';
import Danfe55Bill from './components/Danfe55Bill';
import Danfe55BillDuplicates from './components/Danfe55BillDuplicates';
import Danfe55Taxes from './components/Danfe55Taxes';
import Danfe55Transporter from './components/Danfe55Transporter';
import Danfe55Items from './components/Danfe55Items';
import Danfe55AdditionalData from './components/Danfe55AdditionalData';

export default {
  name: 'Danfe55',
  components: {
    Danfe55Header,
    Danfe55GeneralInfos,
    Danfe55Client,
    Danfe55Bill,
    Danfe55BillDuplicates,
    Danfe55Taxes,
    Danfe55Transporter,
    Danfe55Items,
    Danfe55AdditionalData
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
};
</script>
