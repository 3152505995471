<template>
  <ModalCardDefault :title="'Vendas PDV'" @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" :title="'Lista de Todas as Vendas PDV'" :icon="$icons.box" readonly>
      <DataTable ref="salesDataTable" no-sync :items="sales"
        :headers="headers" :slots="slots" :loading="loading"
        :items-per-page="7">
        <template v-slot:emission_date_formatted="{ props: { item } }">
          {{ item.emission_date_formatted }}
        </template>
        <template v-slot:name="{ props: { item } }">
          {{ getClient(item) }}
        </template>
        <template v-slot:total_order_formatted="{ props: { item } }">
          {{ item.total_order_formatted }}
        </template>

        <template v-slot:actions="{ props: { item } }">
          <v-menu :nudge-width="230" :nudge-left="35" :nudge-top="10" bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ $icons.dotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list dense>
                <v-list-item link @click="emitterNFCe(item)" :disabled="disabledEmitterNFCeDanfe(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon color="secondary" small class="icon-side-bar" :disabled="disabledEmitterNFCeDanfe(item)">{{ $icons.printer }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-side-bar">Gerar NFC-e</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item link @click="printForSale(item.product_invoice, 'printMe65Danfe')" :disabled="disabledPrintDanfe(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon color="secondary" small class="icon-side-bar" :disabled="disabledPrintDanfe(item)">{{ $icons.printer }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-side-bar">Imprimir NFC-e</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item link @click="printForSale(item, 'printMeCoupon')">
                  <v-list-item-icon class="mr-2">
                    <v-icon color="secondary" small class="icon-side-bar">{{ $icons.printer }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-side-bar">Imprimir Cupom</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import { DataTable, ExpansionPanel } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import InputFocusMixin from '@/mixins/InputFocusMixin';
import { getCurrentPeriod } from '@/utils';

export default {
  name: 'PdvSales',
  components: { ModalCardDefault, DataTable, ExpansionPanel },
  data () {
    return {
      panel: [0],
      slots: [
        { field: 'emission_date_formatted' },
        { field: 'name' },
        { field: 'total_order_formatted' },
        { field: 'actions' },
      ],
      sales: [],
      loading: false,
    };
  },
  computed: {
    headers () {
      return [
        { text: 'Data', value: 'emission_date_formatted' },
        { text: 'Cliente', value: 'name' },
        { text: 'Chave NFC-e', value: 'product_invoice.key_nfe' },
        { text: 'Situação NFC-e', value: 'product_invoice.status_formatted' },
        { text: 'Sub Total', value: 'sub_total_formatted' },
        { text: 'Disconto', value: 'discount_formatted' },
        { text: 'Valor Total', value: 'total_order_formatted' },
        { text: 'Ações', value: 'actions' },
      ];
    },
  },
  mixins: [InputFocusMixin],
  methods: {
    getSales () {
      this.loading = true;
      this.$api.orders.filters({ url: `filters?domain=order&filter[type]=order-sale-pdv&page=1&per_page=-1&initial_date=${getCurrentPeriod().currentPeriod.start}&final_date=${getCurrentPeriod().currentPeriod.end}` })
        .then(({ data: { orders } }) => {
          this.sales = orders;
        }).finally(() => {
          this.loading = false;
        });
    },
    getClient (item) {
      return item.client ? item.client.name : 'Não Informado';
    },
    printForSale(item, printable) {
      this.$emit('printForSale', { item, printable});
    },
    emitterNFCe(item) {
      this.$emit('emitterNFCeForSale', item);
    },
    disabledPrintDanfe(item) {
      return item.product_invoice?.status !== this.$enums.productInvoiceStatus.AUTHORIZED;
    },
    disabledEmitterNFCeDanfe(item) {
      return item.product_invoice?.status === this.$enums.productInvoiceStatus.AUTHORIZED;
    }
  },
  mounted () {
    this.getSales();
    this.focusInputs(this.$refs.salesDataTable.$refs.searchDataTable.$refs.inputDataTable);
  }
};
</script>
