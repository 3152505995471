import { typePageOptions } from '@/utils';
import { accountTie } from '@/utils/icons';
import { required } from '@/utils/rules';
import { maps } from '@/utils/icons';
import { typeNatureOperation, typeNationalSimpleTable } from '@/utils/options';

export default {
  domain: 'nature_operations',
  domainSingle: 'nature_operation',
  title: 'Natureza de Operações',
  description: 'Lista de toda as natureza de operações cadastradas no sistema',
  createBtn: 'Nova Natureza de Operações',
  routes: {
    list: {
      name: 'settings-nature-operations',
      path: '/configuracoes/natureza-operacoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true, }
    },
    create: {
      name: 'settings-nature-operation-create',
      path: '/configuracoes/natureza-operacoes/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true, }
    },
    show: {
      name: 'settings-nature-operation-show',
      path: '/configuracoes/natureza-operacoes/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Estados',
          icon: maps,
          action: 'listStates'
        }
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Natureza de Operação',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '6',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'select',
          name: 'type',
          formattedName: 'type_formatted',
          label: 'Tipo',
          align: '',
          md: '6',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          items: typeNatureOperation,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'select',
          name: 'national_simple_table',
          formattedName: 'national_simple_table_formatted',
          label: 'Table Simples Nacional',
          align: '',
          md: '6',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          items: typeNationalSimpleTable,
          clearable: true,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'select',
          name: 'accounting_accounts_id',
          formattedName: 'accounting_account.name',
          label: 'Conta Contábil',
          align: '',
          md: '6',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
          items: [],
          clearable: true,
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'accounting_accounts',
            endpoint: 'accounting_accounts',
            queryParams: '',
          },
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'checkBox',
          name: 'is_pdv',
          formattedName: 'is_pdv_formatted',
          label: 'PDV',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'checkBox',
          name: 'movement_stock',
          formattedName: 'movement_stock_formatted',
          label: 'Movimenta Estoque',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false
        },
        {
          type: 'checkBox',
          name: 'is_invoicing',
          formattedName: 'is_invoicing_formatted',
          label: 'Considerar como faturamento',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: false,
          disabled: false
        },
        {
          type: 'checkBox',
          name: 'is_total_approximate',
          formattedName: 'is_total_approximate_formatted',
          label: 'Total Aproximados dos Tributos',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [],
  states: {
    list: {
      headers: [
        {
          text: '',
          value: 'image',
        },
        {
          text: 'UF',
          value: 'uf',
        },
        {
          text: 'CFOP',
          value: 'cfop',
        },
        {
          text: 'Descrição',
          value: 'description',
        },
        {
          text: 'Local de Destino',
          value: 'destiny_location_formatted',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ]
    }
  }
};
