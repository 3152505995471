<template>
  <ModalCardDefault :title="l.title" @close="close">
    <ExpansionPanel v-model="panel" :title="l.certificateData.title"
      :icon="$icons.box" readonly>
      <Skeleton v-if="loading" types="list-item, list-item, list-item"  />
      <template v-else>
        <Row>
          <Col md="6">
            <TextField v-model="certificate.company_name" :label="l.certificateData.name" readonly
              :placeholder="l.certificateData.name" />
          </Col>
          <Col md="6">
            <TextField v-model="certificate.cnpj" :label="l.certificateData.cnpj" readonly
              :placeholder="l.certificateData.cnpj" />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <TextField v-model="certificate.initial_date" :label="l.certificateData.initialDate" readonly
              :placeholder="l.certificateData.initialDate" />
          </Col>
          <Col md="4">
            <TextField v-model="certificate.final_date" :label="l.certificateData.finalDate" readonly
              :placeholder="l.certificateData.finalDate" />
          </Col>
          <Col md="4">
            <TextField v-model="certificateSituation" :label="l.certificateData.situation" readonly
              :placeholder="l.certificateData.situation" />
          </Col>
        </Row>
      </template>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button :label="l.actions.upload" color="secondary" rounded class="mr-3"
        @click="openDialog" />
    </template>

    <Dialog :dialog="dialogModal" :max-width="700">
      <template v-slot:content>
        <CertificateUpload @update:dialogModal="dialogModal = $event"
          @uploadSuccess="uploadSuccess" />
      </template>
    </Dialog>
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Skeleton,
  Row,
  Col,
  TextField,
  Dialog,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import CertificateUpload from './CertificateUpload';
import { HTTP_CODE } from '@/utils/enums';

export default {
  name: 'Certificate',
  components: {
    ExpansionPanel,
    Skeleton,
    Row,
    Col,
    TextField,
    Dialog,
    Button,
    ModalCardDefault,
    CertificateUpload
  },
  data () {
    return {
      panel: [0],
      loading: true,
      dialogModal: false,
      certificate: {},
      certificateNotFound: false,
      certificateUploadSuccess: false,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.invoiceSettings.list.dialogCertificate;
    },
    certificateSituation () {
      let situation = '';

      if (!this.certificateNotFound) {
        situation = this.certificate.is_expired ? this.l.certificateData.expired :
          this.l.certificateData.active;
      }

      return situation;
    }
  },
  methods: {
    async getCertificateData () {
      this.loading = true;
      this.$api.invoiceSettings.getCertificateData().then((res) => {
        this.certificate = res;
      }).catch((error) => {
        if (error.response.status === HTTP_CODE.NOT_FOUND) {
          this.certificateNotFound = true;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    openDialog () {
      this.dialogModal = !this.dialogModal;
    },
    async uploadSuccess () {
      this.dialogModal = !this.dialogModal;
      this.certificateNotFound = false;
      this.certificateUploadSuccess = true;
      await this.getCertificateData();
    },
    close () {
      this.$emit('closeModal', this.certificateUploadSuccess);
    }
  },
  async mounted () {
    this.getCertificateData();
  }
};
</script>
