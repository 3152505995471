import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('stockMovement', {
    movement_date: '2022-07-01',
    stock_name: 'Principal',
    movement_type: 'exit',
    quantity: 12,
    ...baseFields,
  });
};
