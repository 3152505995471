import { typePageOptions, getUserSuper } from '@/utils';
import { accountTie, users } from '@/utils/icons';
import { required } from '@/utils/rules';
import { cell_phone, cnpj } from '@/utils/masks';
import { typeTaxRegime } from '@/utils/options';
import CompaniesBusiness from '@/business/CompaniesBusiness';

const isSuper = () => {
  const isSuper = getUserSuper();

  return isSuper.type === 'super';
};

export default {
  domain: 'companies',
  domainSingle: 'company',
  business: CompaniesBusiness,
  title: 'Empresas',
  description: 'Lista de todos os clientes cadastrados no sistema',
  createBtn: false,
  routes: {
    list: {
      name: 'settings-companies',
      path: '/configuracoes/empresas',
      meta: { typePage: typePageOptions.list, requiresAuth: true, }
    },
    create: {
      name: 'settings-company-create',
      path: '/configuracoes/empresas/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true, }
    },
    show: {
      name: 'settings-company-show',
      path: '/configuracoes/empresas/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: true,
    more: {
      has: isSuper(),
      items: [
        {
          label: 'Logar com Usuário:',
          icon: users,
          action: 'loginAsUser'
        },
        {
          label: 'Logout com Usuário:',
          icon: users,
          action: 'logoutAsUser'
        }
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados Principais',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '6',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'simpleMask',
          name: 'cnpj',
          formattedName: 'cnpj_formatted',
          label: 'CNPJ',
          align: '',
          md: '3',
          rules: [required],
          inputMask: cnpj,
          outputMask: '##############',
          list: true,
          readonly: true,
          clearable: false,
          disabled: false,
        },
        {
          type: 'integer',
          name: 'ie',
          formattedName: 'ie',
          label: 'Inscrição Estadual',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'social_name',
          formattedName: 'social_name',
          label: 'Nome Fantasia',
          align: '',
          md: '6',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'integer',
          name: 'im',
          formattedName: 'im',
          label: 'Inscrição Municipal',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'suframa',
          formattedName: 'suframa',
          label: 'Suframa',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        }
      ],
    },
    {
      title: 'Dados Fiscais',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'select',
          name: 'tax_regime',
          formattedName: 'tax_regime_formatted',
          label: 'Regime Tributário',
          align: '',
          md: '6',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          items: typeTaxRegime,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'text',
          name: 'cnae',
          formattedName: 'cnae',
          label: 'CNAE',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'activity_code',
          formattedName: 'activity_code',
          label: 'Código de Atividade Principal',
          align: '',
          md: '3',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        }
      ],
    },
    {
      type: 'address',
      readonly: true,
      itemsDisplayList: [],
      items: [],
    },
    {
      title: 'Dados de Contato',
      icon: accountTie,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'contact_name',
          formattedName: 'contact_name',
          label: 'Contato',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'email',
          formattedName: 'email',
          label: 'Email',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'simpleMask',
          name: 'cell_phone',
          formattedName: 'cell_phone_formatted',
          label: 'Celular',
          align: '',
          md: '4',
          rules: [required],
          inputMask: cell_phone,
          outputMask: '###########',
          list: false,
          readonly: false,
          clearable: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
