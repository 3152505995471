<template>
  <div>
    <PageHeader :schema="$schemas.subscription" />
    <PageContent>
      <div class="d-flex flex-column align-center w-100">
        <Picture :src="require('@/assets/contact-us.svg')" width="400" />
        <h3>Ops! Algo deu errado, entre em contato com o suporte.</h3>
      </div>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import { Picture } from '@/components/vuetify';

export default {
  name: 'IllustrationInformation',
  components: { PageHeader, PageContent, Picture },
  data() {
    return {};
  }
};
</script>
