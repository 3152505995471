<template>
  <ModalCardDefault :title="'Vendedores'" @close="$emit('update:dialogModal', false)">
    <ExpansionPanel v-model="panel" :title="'Lista de Todas os Vendedores'" :icon="$icons.box" readonly>
      <DataTable ref="sellersDataTable" no-sync :items="clients"
        :headers="headers" :slots="slots" show-select single-select :loading="loading"
        @setSelected="setSelected">
        <template v-slot:name="{ props: { item } }">
          {{ item.name }}
        </template>
      </DataTable>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button color="secondary"
        rounded @click="confirm" class="mr-3">
        <span class="text-button">Confirmar
            <span class="text-button font-weight-black yellow--text">(Enter)</span>
          </span>
      </Button>
    </template>
  </ModalCardDefault>
</template>

<script>
import { DataTable, Button, ExpansionPanel } from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import InputFocusMixin from '@/mixins/InputFocusMixin';

export default {
  name: 'PdvSellers',
  components: { ModalCardDefault, DataTable, Button, ExpansionPanel },
  data () {
    return {
      panel: [0],
      slots: [
        { field: 'name' },
      ],
      clients: [],
      selected: null,
      loading: false,
    };
  },
  created() {
    window.addEventListener('keydown', this.actionListener);
  },
  destroyed() {
    window.removeEventListener('keydown', this.actionListener);
  },
  computed: {
    headers () {
      return [
        { text: 'Nome', value: 'name' },
      ];
    }
  },
  mixins: [InputFocusMixin],
  methods: {
    getClients () {
      this.loading = true;
      this.$api.registers.filters({ url: 'filters?domain=register&filter[type]=seller&page=1&per_page=-1' })
        .then(({ data: { registers } }) => {
          this.clients = registers;
        }).finally(() => {
          this.loading = false;
        });
    },
    setSelected (item) {
      this.selected = item[0];
    },
    confirm () {
      if (!this.selected) {
        this.$noty.error('Selecione um Vendedor');

        return;
      }
      this.$emit('confirmDialog', { action: 'setSeller', data: this.selected });
    },
    actionListener(event) {
      if (event.key === 'Enter') {
        this.confirm();
      }
    }
  },
  mounted () {
    this.getClients();
    this.focusInputs(this.$refs.sellersDataTable.$refs.searchDataTable.$refs.inputDataTable);
  }
};
</script>

<style lang="scss" scoped>

</style>
