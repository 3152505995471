import ProductSchema from './schemas/ProductSchema';
import ListProducts from './pages/ListProducts';
import CreateProduct from './pages/CreateProduct';

export default [
  {
    path: ProductSchema.routes.list.path,
    name: ProductSchema.routes.list.name,
    component: ListProducts,
    meta: ProductSchema.routes.list.meta,
  },
  {
    path: ProductSchema.routes.create.path,
    name: ProductSchema.routes.create.name,
    component: CreateProduct,
    meta: ProductSchema.routes.create.meta
  },
  {
    path: ProductSchema.routes.show.path,
    name: ProductSchema.routes.show.name,
    component: CreateProduct,
    meta: ProductSchema.routes.show.meta
  },
];