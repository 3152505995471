<template>
  <div>
    <v-img v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script>
export default {
  name: 'Picture',
};
</script>

<style>

</style>
