import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, swapHorizontal, printer, close, edit, download, list, calendar, numeric } from '@/utils/icons';
import { required } from '@/utils/rules';
import ProductInvoiceBusiness from '@/business/ProductInvoiceBusiness';
import { modelNf, nfStatus } from '@/utils/options';

export default {
  domain: 'product_invoices',
  domainSingle: 'product_invoice',
  title: 'NF-e de Produto',
  description: 'Lista de todos os Nota Fiscal de Produto do sistema',
  business: ProductInvoiceBusiness,
  createBtn: 'Nova NF-e de Produto',
  showInactive: false,
  showExpand: true,
  routes: {
    list: {
      name: 'product-invoices',
      path: '/nota-fiscal-produtos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'product-invoice-create',
      path: '/nota-fiscal-produtos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'product-invoice-show',
      path: '/nota-fiscal-produtos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Transmitir para Sefaz',
          icon: swapHorizontal,
          iconColor: 'success',
          name: 'openConfirmationModal',
          action: 'openConfirmationModal',
          component: null
        },
        {
          label: 'Imprimir DANFE',
          icon: printer,
          iconColor: 'secondary',
          name: 'printDanfe',
          action: 'printDanfe',
          component: null
        },
        {
          label: 'Download DANFE',
          icon: download,
          iconColor: 'secondary',
          name: 'downloadDanfe',
          action: 'downloadDanfe',
          component: null
        },
        {
          label: 'Alterar Número NFe',
          icon: numeric,
          iconColor: 'secondary',
          name: 'changeNumberNFe',
          action: 'changeNumberNFe',
          component: 'ChangeNumberNFe'
        },
        {
          label: 'Cancelar NFe',
          icon: close,
          iconColor: 'red',
          name: 'cancelNFe',
          action: 'cancelNFe',
          component: 'CancelNFe'
        },
        {
          label: 'Carta Correção',
          icon: edit,
          iconColor: 'warning',
          name: 'correctionLetters',
          action: 'correctionLetters',
          component: 'ListCorrectionLetters'
        },
        {
          label: 'Eventos',
          icon: list,
          iconColor: 'secondary',
          name: 'events',
          action: 'events',
          component: 'ListEvents'
        },
        {
          label: 'Download Xml',
          icon: download,
          iconColor: 'secondary',
          name: 'downloadXml',
          action: 'downloadXml',
          component: null
        }
      ]
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'text',
        name: 'nf_number',
        label: 'N° NF-e',
        icon: text,
        md: 6,
      },
      {
        type: 'select',
        name: 'model',
        label: 'Modelo',
        icon: text,
        md: 6,
        items: {
          data: modelNf
        }
      },
      {
        type: 'text',
        name: 'client$$name',
        label: 'Cliente',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'order$$seller$$name',
        label: 'Vendedor',
        icon: text,
        md: 6,
      },
      {
        type: 'select',
        name: 'status',
        label: 'Status',
        icon: text,
        md: 6,
        items: {
          data: nfStatus
        }
      },
    ]
  },
  fields: [
    {
      title: 'Dados da Nota Fiscal de Produto',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'emission_date',
          formattedName: 'emission_date_formatted',
          label: 'Emissão',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'operation_type',
          formattedName: 'operation_type_formatted',
          label: 'Tipo',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'nf_number',
          formattedName: 'nf_number',
          label: 'Número',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'model',
          formattedName: 'model_formatted',
          label: 'Modelo',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'client_id',
          formattedName: 'client_name_formatted',
          label: 'Cliente',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'total.nf_value',
          formattedName: 'total.nf_value_formatted',
          label: 'Total',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'environment',
          formattedName: 'environment_formatted',
          label: 'Ambiente',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'key_nfe',
          formattedName: 'key_nfe',
          label: 'Chave',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
      ],
    },
  ],
  footer: [
    {
      name: 'value_total_pending',
      label: 'Vlr. Total Pendentes',
      color: 'primary',
      md: 3,
    },
    {
      name: 'value_total_authorized',
      label: 'Vlr. Total Autorizadas',
      color: 'primary',
      md: 3,
    },
    {
      name: 'value_total_rejected',
      label: 'Vlr. Total Rejeitadas',
      color: 'primary',
      md: 3,
    },
  ],
};
