<template>
  <div>
    <ModalCardDefault :title="title" @close="close"
      :actions="{ close: 'Cancelar' }">
      <ExpansionPanel v-model="panel" :title="'Dados da Mesa'" :icon="$icons.box" readonly>
        <v-form v-model="validFormTableSale" ref="validFormTableSale" lazy-validation>
          <Row>
            <Col md="3">
              <DataPicker v-model="tableSale.date_hour_open" :placeholder="'Data Abertura'"
                :label="'Data Abertura'" :rules="[rules.required]" :disabled="disableDateHourOpen" />
            </Col>
            <Col md="3">
              <TextFieldSimpleMask v-model="tableSale.hour_open" :input-mask="masks.hour"
                :placeholder="'Hora Abertura'" :label="'Hora Abertura'" :output-mask="masks.hour"
                :rules="[rules.required]" :disabled="disableDateHourOpen" />
            </Col>
            <Col md="3">
              <DataPicker v-model="tableSale.date_hour_closed" :placeholder="'Data Fechamento'"
                :label="'Data Fechamento'" :rules="[]" :disabled="disableDateHourClosed" />
            </Col>
            <Col md="3">
              <TextFieldSimpleMask v-model="tableSale.hour_closed" :input-mask="masks.hour"
                :placeholder="'Hora Fechamento'" :label="'Hora Fechamento'" :output-mask="masks.hour"
                :rules="[]" :disabled="disableDateHourClosed" />
            </Col>
            <Col md="2">
              <TextFieldInteger v-model="tableSale.client_qtd" label='Qtd Clientes'
                placeholder='Qtd Clientes' :rules="[rules.biggerZero]" />
            </Col>
            <Col md="5">
              <AutoComplete v-model="tableSale.client_id" :placeholder="'Pesquise o Cliente'"
                  :label="'Cliente'" :rules="[]" domain="register" resultDomain="registers"
                  :search-by="['name', 'cpf', 'cnpj', 'phone', 'cell_phone']" item-text-data-serve="name"
                  title="Pesquise por: Nome, E-mail, Telefone, CPF e CNPJ"
                  item-value-data-serve="id" clearable identify="client" return-object
                  @change="changeClient($event)" />
            </Col>
            <Col md="5">
              <AutoComplete v-model="tableSale.employee_id" :placeholder="'Pesquise o Garçom'"
                  :label="'Garçom'" :rules="[rules.required]" domain="register" resultDomain="registers"
                  :search-by="['name']" item-text-data-serve="name"
                  title="Pesquise por: Nome, E-mail, Telefone, CPF e CNPJ"
                  item-value-data-serve="id" clearable identify="employee" return-object
                  @change="changeEmployee($event)" />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Alert color="primary" class="py-2">
                <h5 class="font-weight-bold">Total da Mesa</h5>
                <h4>{{ table.total_value_formatted }}</h4>
              </Alert>
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

        <template v-slot:actions="{ }">
          <Button :label="labelButton" color="secondary"
            :icon="$icons.check" rounded class="mr-2" @click="confirm" />
        </template>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Button,
  ExpansionPanel,
  Row,
  Col,
  AutoComplete,
  TextFieldInteger,
  DataPicker,
  TextFieldSimpleMask,
  Alert,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import TableSale from '../../model/TableSale';
import { required, biggerZero, } from '@/utils/rules';
import { hour } from '@/utils/masks';
import { getCurrentPeriod, getHour } from '@/utils';
import { clone } from 'lodash';

export default {
  name: 'OpenTable',
  components: {
    ModalCardDefault,
    Button,
    ExpansionPanel,
    Row,
    Col,
    AutoComplete,
    TextFieldInteger,
    DataPicker,
    TextFieldSimpleMask,
    Alert,
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      validFormTableSale: false,
      tableSale: TableSale.model,
      rules: {
        required,
        biggerZero
      },
      masks: {
        hour,
      },
    };
  },
  computed: {
    title () {
      return this.type === this.$enums.tableStatusType.OPEN_TABLE ?
        `Abrir: ${this.table.description}` : `Fechar: ${this.table.description}`;
    },
    disableDateHourOpen () {
      return this.type === this.$enums.tableStatusType.CLOSE_TABLE ? true : false;
    },
    disableDateHourClosed () {
      return this.type === this.$enums.tableStatusType.OPEN_TABLE ? true : false;
    },
    labelButton () {
      return this.type === this.$enums.tableStatusType.CLOSE_TABLE ? 'Fechar Mesa' : 'Abrir Mesa';
    },
  },
  methods: {
    changeClient (client) {
      if (!client) return;
      this.tableSale.client_id = client.value;
    },
    changeEmployee (employee) {
      if (!employee) return;
      this.tableSale.employee_id = employee.value;
    },
    close () {
      this.tableSale = TableSale.reset;
      this.$emit('close', false);
    },
    confirm () {
      if (!this.$refs.validFormTableSale.validate()) return;

      if (this.type === this.$enums.tableStatusType.OPEN_TABLE) {
        this.$emit('confirm', { type: this.type, tableSale: this.tableSale });
      } else if (this.type === this.$enums.tableStatusType.CLOSE_TABLE &&
        this.tableSale.items.length > 0) {
        this.$emit('openPayment', { type: this.type, tableSale: this.tableSale });
      } else {
        this.$emit('confirm', { type: this.type, tableSale: this.tableSale });
      }
    },
  },
  mounted () {
    this.tableSale.table_id = this.table.id;

    if (this.type === this.$enums.tableStatusType.CLOSE_TABLE) {
      this.tableSale = clone(this.table.table_sale);
      this.tableSale.date_hour_closed = getCurrentPeriod().currentDateTimeEN;
      this.tableSale.hour_open = this.table.table_sale.date_hour_open.substring(11, 16);
      this.tableSale.date_hour_open = this.table.table_sale.date_hour_open.substring(0, 10);
      this.tableSale.hour_closed = getHour();
    }
  },
};
</script>

<style>

</style>
