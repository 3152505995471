import TableSchema from './schemas/TableSchema';
import ListTables from './pages/ListTables';
import CreateTable from './pages/CreateTable';

export default [
  {
    path: TableSchema.routes.list.path,
    name: TableSchema.routes.list.name,
    component: ListTables,
    meta: TableSchema.routes.list.meta,
  },
  {
    path: TableSchema.routes.create.path,
    name: TableSchema.routes.create.name,
    component: CreateTable,
    meta: TableSchema.routes.create.meta
  },
  {
    path: TableSchema.routes.show.path,
    name: TableSchema.routes.show.name,
    component: CreateTable,
    meta: TableSchema.routes.show.meta
  },
];
