import CostCenterSchema from './schemas/CostCenterSchema';
import ListCostCenters from './pages/ListCostCenters';
import CreateCostCenter from './pages/CreateCostCenter';

export default [
  {
    path: CostCenterSchema.routes.list.path,
    name: CostCenterSchema.routes.list.name,
    component: ListCostCenters,
    meta: CostCenterSchema.routes.list.meta,
  },
  {
    path: CostCenterSchema.routes.create.path,
    name: CostCenterSchema.routes.create.name,
    component: CreateCostCenter,
    meta: CostCenterSchema.routes.create.meta
  },
  {
    path: CostCenterSchema.routes.show.path,
    name: CostCenterSchema.routes.show.name,
    component: CreateCostCenter,
    meta: CostCenterSchema.routes.show.meta
  },
];