<template>
  <div>
    <ModalCardDefault :title="`Vender: ${table.description}`" @close="close"
      :actions="{ close: 'Cancelar' }">
      <ExpansionPanel v-model="panel" :title="'Dados do Produto'" :icon="$icons.box" readonly>
        <v-form v-model="validFormTableSale" ref="validFormTableSale" lazy-validation>
          <Row>
            <Col md="6">
              <AutoComplete v-model="localForm.product" :placeholder="'Pesquise o Produto'"
                :label="'Produto'" :rules="[rules.required]" return-object
                domain="product" resultDomain="products" item-value-data-serve="id"
                item-text-data-serve="name" :search-by="['name', 'code', 'bar_code']"
                title="Pesquise por: Nome, Código ou Código de Barras"
                @change="changeProduct($event)" identify="product" />
            </Col>
            <Col md="3">
              <TextField v-model="localForm.code" placeholder="Código"
                label="Código" readonly />
            </Col>
            <Col md="3">
              <TextFieldMoney v-model="localForm.unit_value"
                placeholder="Vlr. Unitário" label="Vlr. Unitário" readonly
                :rules="[rules.required]" />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <TextFieldPercent v-model="localForm.quantity"
                placeholder="Quantidade"
                :length="1000" label="Quantidade"
                :rules="[rules.required]" />
            </Col>
            <Col md="3">
              <TextFieldMoney v-model="localForm.sub_total"
                placeholder="Sub Total" label="Sub Total" readonly
                :rules="[rules.required]" />
            </Col>
            <Col md="3">
              <TextFieldMoney v-model="localForm.discount_value"
                placeholder="Vlr. Desconto" label="Vlr. Desconto" readonly
                :rules="[rules.required]" />
            </Col>
            <Col md="3">
              <TextFieldMoney v-model="localForm.total_value"
                placeholder="Vlr. Total" label="Vlr. Total" readonly
                :rules="[rules.required]" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

        <template v-slot:actions="{}">
          <Button :label="'Confirmar'" color="secondary"
            :icon="$icons.check" rounded class="mr-2" @click="confirm" />
        </template>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Button,
  ExpansionPanel,
  Row,
  Col,
  AutoComplete,
  TextField,
  TextFieldMoney,
  TextFieldPercent,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { required, biggerZero, } from '@/utils/rules';

export default {
  name: 'ProductTable',
  components: {
    ModalCardDefault,
    Button,
    ExpansionPanel,
    Row,
    Col,
    AutoComplete,
    TextField,
    TextFieldMoney,
    TextFieldPercent,
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      panel: [0],
      validFormTableSale: true,
      rules: {
        required,
        biggerZero
      },
      localForm: {
        product_id: '',
        product_variation_id: '',
        code: '',
        name: '',
        unit_value: 0,
        quantity: 1,
        sub_total: 0,
        discount_value: 0,
        total_value: 0,
      }
    };
  },
  computed: {
  },
  methods: {
    changeProduct (item) {
      if (!item.has_variation) {
        this.localForm = {
          product_id: item.id,
          product_variation_id: item.product_variations[0].id,
          code: item.code,
          name: item.name,
          unit_value: item.price,
          quantity: 1,
          sub_total: item.price * 1,
          discount_value: 0,
          total_value: item.price * 1 - 0,
        };
      }
    },
    close () {
      this.$emit('close', false);
    },
    confirm () {
      if (!this.$refs.validFormTableSale.validate()) {
        return;
      }

      this.$api.tables.createTableSaleItem(this.table.id, this.table.table_sale.id, this.localForm).then(() => {
      }).catch(() => {
        this.$noty.error('Erro ao adicionar produto');
      }).finally(() => {
        this.$emit('close', true);
      });
    }
  },
};
</script>

<style>

</style>
