import MovementSchema from './schemas/MovementSchema';
import ListMovements from './pages/ListMovements';

export default [
  {
    path: MovementSchema.routes.list.path,
    name: MovementSchema.routes.list.name,
    component: ListMovements,
    meta: MovementSchema.routes.list.meta,
  },
];
