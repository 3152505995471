import OrderSchema from './schemas/OrderSchema';
import ListOrders from './pages/ListOrders';
import CreateOrder from './pages/CreateOrder';

let routes = [];

Object.keys(OrderSchema.routes).forEach((key) => {
  Object.keys(OrderSchema.routes[key]).forEach((itemKey) => {
    routes.push({
      path: OrderSchema.routes[key][itemKey].path,
      name: OrderSchema.routes[key][itemKey].name,
      component: itemKey === 'list' ? ListOrders : CreateOrder,
      meta: OrderSchema.routes[key][itemKey].meta,
    });
  });
});

export default routes;
