<template>
  <div>
    <h4 class="title-section" v-text="l.title" />
    <Row class="mt-0 border-all-row">
      <Col md="2" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.icmsBase" />
        <p v-if="isAuthorized" v-text="model.total.icms_base_calculate_formatted" />
        <TextFieldPercent v-else v-model="model.total.icms_base_calculate" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center border-x-col">
        <p class="caption" v-text="l.icmsValue" />
        <p v-if="isAuthorized" v-text="model.total.icms_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.icms_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.icmsSubsBase" />
        <p v-if="isAuthorized" v-text="model.total.icms_st_base_calculate_formatted" />
        <TextFieldPercent v-else v-model="model.total.icms_st_base_calculate" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center border-x-col">
        <p class="caption" v-text="l.icmsSubsValue" />
        <p v-if="isAuthorized" v-text="model.total.icms_st_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.icms_st_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.productsValue" />
        <p v-if="isAuthorized" v-text="model.total.products_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.products_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center border-left-col">
        <p class="caption" v-text="l.freightValue" />
        <p v-if="isAuthorized" v-text="model.transport.freight_value_formatted" />
        <TextFieldMoney v-else v-model="model.transport.freight_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
    </Row>
    <Row class="border-all-row no-border-row-top">
      <Col md="2" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.safeValue" />
        <p v-if="isAuthorized" v-text="model.transport.safe_value_formatted" />
        <TextFieldMoney v-else v-model="model.transport.safe_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center border-x-col">
        <p class="caption" v-text="l.discount" />
        <p v-if="isAuthorized" v-text="model.total.discount_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.discount_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.otherExpenses" />
        <p v-if="isAuthorized" v-text="model.transport.other_expenses_formatted" />
        <TextFieldMoney v-else v-model="model.transport.other_expenses" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center border-x-col">
        <p class="caption" v-text="l.approximatesTaxes" />
        <p v-if="isAuthorized" v-text="model.total.tax_approximates_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.tax_approximates_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center">
        <p class="caption" v-text="l.ipiValue" />
        <p v-if="isAuthorized" v-text="model.total.ipi_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.ipi_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
      <Col md="2" class="py-0 d-flex flex-column justify-center border-left-col">
        <p class="caption" v-text="l.nfeTotal" />
        <p v-if="isAuthorized" v-text="model.total.nf_value_formatted" />
        <TextFieldMoney v-else v-model="model.total.nf_value" :outlined="false"
          readonly class="textfield-money-custom" />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
  TextFieldMoney,
  TextFieldPercent,
} from '@/components/vuetify';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';

export default {
  name: 'Taxes',
  components: {
    Row,
    Col,
    TextFieldMoney,
    TextFieldPercent,
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.taxes;
    },
  },
  mixins: [CreateUpdateProductInvoiceMixin],
};
</script>

<style lang="scss" scoped>
.textfield-money-custom {
  margin: -12px 0 0 0 !important;
  padding: 0 !important;
  height: 32px !important;
}

.select-custom {
  margin-top: -10px !important;
  height: 30px !important;
}
</style>
