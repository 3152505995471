import StockSchema from './schemas/StockSchema';
import ListStocks from './pages/ListStocks';
import CreateStock from './pages/CreateStock';

export default [
  //client
  {
    path: StockSchema.routes.list.path,
    name: StockSchema.routes.list.name,
    component: ListStocks,
    meta: StockSchema.routes.list.meta,
  },
  {
    path: StockSchema.routes.create.path,
    name: StockSchema.routes.create.name,
    component: CreateStock,
    meta: StockSchema.routes.create.meta
  },
  {
    path: StockSchema.routes.show.path,
    name: StockSchema.routes.show.name,
    component: CreateStock,
    meta: StockSchema.routes.show.meta
  },
];