import DynamicService from '@/service/DynamicService';
import StockMovementSchema from '../schemas/StockMovementSchema';
import { formatDatePT, formatCurrency, routeName } from '@/utils';

const formatResponse = (item) => {
  item.movement_date_formatted = formatDatePT(item.movement_date),
  item.previous_balance_stock_formatted = formatCurrency(item.previous_balance_stock, { type: 'decimal' }),
  item.quantity_formatted = formatCurrency(item.quantity, { type: 'decimal' }),
  item.current_balance_stock_formatted = formatCurrency(item.current_balance_stock, { type: 'decimal' }),
  item.description_formatted = item.description,
  item.origin = {
    ...item.origin,
    route_name: routeName[item.origin.type],
  };
};

const StockMovementsService = DynamicService('stock_movements', StockMovementSchema, { formatResponse });

export default StockMovementsService;
