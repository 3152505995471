import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import StockSchema from '../schemas/StockSchema';
import { formatCurrency, formatDatePT, routeName, getText } from '@/utils';
import { typeYesNoBoolean } from '@/utils/options';

const formatResponse = (item) => {
  item.main_formatted = getText(typeYesNoBoolean, item.main);
  item.total_available_formatted = formatCurrency(item.total_available, { type: 'decimal' });
};

const formatResponseGetAllMovementsByStockId = ({ data: { stock_movements } }) => {
  return stock_movements.map((movement) => {
    return {
      ...movement,
      movement_date_formatted: formatDatePT(movement.movement_date),
      previous_balance_stock_formatted: formatCurrency(movement.previous_balance_stock, { type: 'decimal' }),
      previous_balance_product_variation_formatted: formatCurrency(movement.previous_balance_product_variation, { type: 'decimal' }),
      quantity_formatted: formatCurrency(movement.quantity, { type: 'decimal' }),
      current_balance_stock_formatted: formatCurrency(movement.current_balance_stock, { type: 'decimal' }),
      current_balance_product_variation_formatted: formatCurrency(movement.current_balance_product_variation, { type: 'decimal' }),
      origin: {
        ...movement.origin,
        route_name: routeName[movement.origin.type],
      },
    };
  });
};

const formatResponseGetAllProductsByStockId = ({ data: { product_variations } }) => {
  return product_variations.map((productVariation) => {
    return {
      ...productVariation,
      price_formatted: formatCurrency(productVariation.price),
      price_wholesale_formatted: formatCurrency(productVariation.price_wholesale),
    };
  });
};

const StocksService = DynamicService('stocks', StockSchema, { formatResponse });

const StocksCommands = () => ({
  async getAllMovementsByStockId(stockId, pagination) {
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page :1;
    const result = await axios.get(`filters?domain=stock_movement&search_global=true&filter[stock_id]=${stockId}&sort=asc&sort_by=movement_timestamp&per_page=${itemsPerPage || 5}&page=${page || 1}`);

    return {
      ...result.data,
      data: formatResponseGetAllMovementsByStockId(result)
    };
  },
  async getAllMovementsByProductVariationId(productVariationId, pagination) {
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page : 1;
    const result = await axios.get(`filters?domain=stock_movement&search_global=true&sort=asc&sort_by=movement_timestamp&per_page=${itemsPerPage || 5}&page=${page || 1}`);

    return {
      ...result.data,
      data: formatResponseGetAllMovementsByStockId(result)
    };
  },
  async createStockMovement(payload) {
    return await axios.post('stock_movements', payload);
  },
  async getAllProductVariationsByStockId(data) {
    const { stockId, pagination, searchColumn, search } = data;
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page : 1;
    let url = `filters?domain=product_variation&filter[productVariationStocks.stock_id]=${stockId}&page=${page}&per_page=${itemsPerPage}`;

    if (search) {
      url += `&filter[${searchColumn}]=${search}`;
    }
    const result = await axios.get(url);

    return {
      ...result.data,
      data: formatResponseGetAllProductsByStockId(result)
    };
  },
});

export default StocksService;
export { StocksService, StocksCommands };
