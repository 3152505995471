import TextField from '@/components/vuetify/TextField';
import TextFieldPassword from '@/components/vuetify/TextFieldPassword';
import TextFieldPercent from '@/components/vuetify/TextFieldPercent';
import TextFieldMoney from '@/components/vuetify/TextFieldMoney';
import TextFieldInteger from '@/components/vuetify/TextFieldInteger';
import TextFieldSimpleMask from '@/components/vuetify/TextFieldSimpleMask';
import Select from '@/components/vuetify/Select';
import DataPicker from '@/components/vuetify/DataPicker';
import CheckBox from '@/components/vuetify/CheckBox';
import AutoComplete from '@/components/vuetify/AutoComplete';

export default {
  text: TextField,
  password: TextFieldPassword,
  select: Select,
  dataPicker: DataPicker,
  percent: TextFieldPercent,
  money: TextFieldMoney,
  integer: TextFieldInteger,
  simpleMask: TextFieldSimpleMask,
  checkBox: CheckBox,
  autoComplete: AutoComplete,
};
