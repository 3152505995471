import { typePage } from '@/utils/enums';
import { getText } from '@/utils';
import { creditLetterStatus } from '@/utils/options';

export default {
  beforeForm: ({ form, route }) => {
    if (route.meta.typePage === typePage.SHOW) {
      form.status = getText(creditLetterStatus, form.status);
    }
  },
};
