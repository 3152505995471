export { default as routes } from './routes';

export const menuItemsReports = [
  {
    active: false,
    menuGroupName: 'reports',
    icon: 'fileChart',
    title: 'Relatórios',
    items: [
      { icon: 'accountTie', title: 'Clientes', route: 'report-clients', active: false },
    ],
  },
];
