<template>
  <div>
    <v-form v-model="validFormIpi" ref="validFormIpi" lazy-validation>
      <Row class="mt-5">
        <Col>
          <Select v-model="model.ipi_cst" :label="l.cst.label"
            :items="ipiCST" itemText="text" itemValue="value"
            :rules="[rules.required]" />
        </Col>
      </Row>
      <Row class="mt-5">
        <Col md="4">
          <TextFieldPercent v-model="model.ipi_aliquot"
            :placeholder="l.aliquot.placeholder"
            :label="l.aliquot.label" />
        </Col>
        <Col md="4">
          <TextField v-model="model.ipi_class_framework"
            :placeholder="l.classFramework.placeholder"
            :label="l.classFramework.label" />
        </Col>
        <Col md="4">
          <TextField v-model="model.ipi_code_framework"
            :placeholder="l.codeFramework.placeholder"
            :label="l.codeFramework.label" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <CheckBox v-model="model.ipi_include_discounts"
            @setCheckBox="setCheckBox($event, 'ipi_include_discounts')"
            :label="l.includeDiscounts.label" class="mt-0"/>
        </Col>
        <Col md="4">
          <CheckBox v-model="model.ipi_include_expenses"
            @setCheckBox="setCheckBox($event, 'ipi_include_expenses')"
            :label="l.includeExpenses.label" class="mt-0"/>
        </Col>
        <Col md="4">
          <CheckBox v-model="model.ipi_include_freight"
            @setCheckBox="setCheckBox($event, 'ipi_include_freight')"
            :label="l.includeFreight.label" class="mt-0"/>
        </Col>
      </Row>
    </v-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextFieldPercent,
  TextField
} from '@/components/vuetify';
import CheckBox from '@/components/vuetify/CheckBox';
import { required } from '@/utils/rules';
import { ipiCST } from '@/utils/options';

export default {
  name: 'Ipi',
  components: {
    Row,
    Col,
    Select,
    TextFieldPercent,
    TextField,
    CheckBox
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: { required: required },
      validFormIpi: false,
      ipiCST: ipiCST,
    };
  },
  computed: {
    l () {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState.form.ipi;
    },
  },
  methods: {
    setCheckBox(value, type) {
      this.model[type] = value || false;
    }
  }
};
</script>
