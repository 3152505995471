import { typePageOptions } from '@/utils';
import { text, users } from '@/utils/icons';
import { required } from '@/utils/rules';
import { cell_phone } from '@/utils/masks';

export default {
  domain: 'resellers',
  domainSingle: 'reseller',
  title: 'Meus Revendedores',
  description: 'Lista de todas os Meus Revendedores do sistema',
  createBtn: 'Novo Revendedor',
  showInactive: false,
  routes: {
    list: {
      name: 'resellers',
      path: '/revendedor-cartoes',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'reseller-create',
      path: '/revendedor-cartoes/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'reseller-show',
      path: '/revendedor-cartoes/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Clientes',
          icon: users,
          name: 'openModalListCustomers',
          action: 'openModalListCustomers',
        },
        // {
        //   label: 'Novo Cliente',
        //   icon: accountTie,
        //   name: 'openModalCreateCustomer',
        //   action: 'openModalCreateCustomer',
        // },
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Revendedor',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'email',
          formattedName: 'email',
          label: 'E-mail',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'simpleMask',
          name: 'phone',
          formattedName: 'phone_formatted',
          label: 'Contato',
          align: '',
          md: '3',
          rules: [required],
          inputMask: cell_phone,
          outputMask: '###########',
          list: true,
          readonly: false,
          clearable: false,
          disabled: false,
        },
        {
          type: 'password',
          name: 'password',
          formattedName: 'password',
          label: 'Senha',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [],
};
