import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import InvoiceSettingSchema from '../schemas/InvoiceSettingSchema';
import { getText, formatCpfCnpj, formatDatePT } from '@/utils';
import { typeNf, environmentNf, modelNf } from '@/utils/options';

const formatResponse = (item) => {
  item.environment_formatted = getText(environmentNf, item.environment);
  item.invoice_type_formatted = getText(typeNf, item.invoice_type);
  item.model_formatted = getText(modelNf, item.model);
  item.main_formatted = item.main ? 'Sim' : 'Não';
};

const formatResponseCertificateData = (data) => {
  data.cnpj = formatCpfCnpj(data.cnpj);
  data.initial_date = formatDatePT(data.initial_date);
  data.final_date = formatDatePT(data.final_date);

  return data;
};

const formatResponseEvents = (data) => {
  return data;
};

const InvoiceSettingsService = DynamicService('invoice_settings', InvoiceSettingSchema, { formatResponse });

const InvoiceSettingsCommands = () => ({
  async getStatusSefaz() {
    const { data } = await axios.post(`${InvoiceSettingSchema.domain}/status-sefaz`);

    return data;
  },
  async getCertificateData() {
    const { data } = await axios.post(`${InvoiceSettingSchema.domain}/certificate-data`);

    return formatResponseCertificateData(data);
  },
  async uploadCertificate(form) {
    let payload = new FormData();
    payload.append('file', true);
    payload.append('certificate', form.certificate);
    payload.append('password', form.password);

    const { data } = await axios.post(`${InvoiceSettingSchema.domain}/certificate-upload`, payload);

    return data;
  },
  async disableNumbers(payload) {
    const { data } = await axios.post(`${InvoiceSettingSchema.domain}/disable-numbers`, payload);

    return data;
  },
  async getAllEvents(payload) {
    const { data } = await axios.post(`${InvoiceSettingSchema.domain}/events`, payload);

    return formatResponseEvents(data);
  },
});

export default InvoiceSettingsService;
export { InvoiceSettingsService, InvoiceSettingsCommands };
