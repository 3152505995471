import DynamicService from '@/service/DynamicService';
import AccountingAccountSchema from '../schemas/AccountingAccountSchema';
import { accountingAccountType } from '@/utils/options';
import { getText } from '@/utils';

const formatResponse = (item) => {
  item.type_formatted = getText(accountingAccountType, item.type);
};

const formatRequest = (item) => {
  return item;
};

const AccountingAccountsService = DynamicService('accounting_accounts',
  AccountingAccountSchema, { formatResponse, formatRequest });

export default AccountingAccountsService;
