<script>
export default {
  methods: {
    hasCts (type) {
      const hasCst = ['icms', 'ipi', 'pis', 'cofins'];

      return hasCst.includes(type);
    }
  }
};
</script>
