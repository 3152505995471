<template>
  <div>
    <Row class="border-all-row">
      <Col md="6" class="py-0 text-center d-flex flex-column justify-center">
        <h3 v-text="model.emitter.name" />
        <p class="caption"
          v-text="`${model.emitter.street} ${model.emitter.number || ''} ${model.emitter.complement || ''}`" />
        <p class="caption" v-text="`${model.emitter.neighborhood} - ${model.emitter.city}/${model.emitter.uf}`" />
        <p class="caption" v-text="`CEP: ${zipCode} - Fone: ${phone}`" />
      </Col>
      <Col class="py-0 border-x-col text-center d-flex flex-column justify-center">
        <h4 v-text="l.danfe" />
        <p class="caption" v-text="l.danfeDescription"  />
        <Row>
          <Col class="py-2 text-right">
            <p class="caption" v-text="l.input" />
            <p class="caption" v-text="l.output" />
          </Col>
          <Col class="py-2 text-left d-flex align-center">
            <p class="caption border-all">&nbsp;{{ operationType }}&nbsp;</p>
          </Col>
        </Row>
        <h4 v-text="`${l.number} ${model.nf_number}`" />
        <h4 v-text="`${l.series} ${model.series}`" />
      </Col>
      <Col class="py-0 text-center d-flex flex-column justify-center">
        <p class="caption" v-text="`${l.key} ${model.key_nfe || ''}`" />
        <p class="caption" v-text="l.consult" />
        <p class="caption" v-text="l.site" />
      </Col>
    </Row>
  </div>
</template>

<script>
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import {
  Row,
  Col,
} from '@/components/vuetify';
import { formatPhone, formatZipCode } from '@/utils';

export default {
  name: 'Header',
  components: {
    Row,
    Col,
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.header;
    },
    phone() {
      return formatPhone(this.model.emitter.phone || '99999999999');
    },
    zipCode() {
      return formatZipCode(this.model.emitter.zip_code || '99999999999');
    },
    operationType () {
      return this.model.operation_type === this.$enums.operationType.INPUT ? '0' : '1';
    }
  },
  mixins: [CreateUpdateProductInvoiceMixin],
};
</script>
