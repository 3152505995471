import RegisterSchema from './schemas/RegisterSchema';
import ListRegisters from './pages/ListRegisters';
import CreateRegister from './pages/CreateRegister';

let routes = [];

Object.keys(RegisterSchema.routes).forEach((key) => {
  Object.keys(RegisterSchema.routes[key]).forEach((itemKey) => {
    routes.push({
      path: RegisterSchema.routes[key][itemKey].path,
      name: RegisterSchema.routes[key][itemKey].name,
      component: itemKey === 'list' ? ListRegisters : CreateRegister,
      meta: RegisterSchema.routes[key][itemKey].meta,
    });
  });
});

export default routes;
