import { Response } from 'miragejs';
import { baseFields } from '../../utils/baseFields';

export const create = (server) => {
  server.post('/stocks', (schema, { requestBody }) => {
    const attrs = JSON.parse(requestBody);
    const { name, description } = attrs;

    if (!name || !description) {
      return new Response(422, {}, { errors: {
        name: 'The field name is required',
        description: 'The field description is required',
      } });
    }

    const stock = {
      ...attrs,
      ...baseFields,
    };

    const response = schema.stocks.create(stock);

    return { stock: response.attrs };
  });
};