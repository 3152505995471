<template>
  <div>
    <ModalCardDefault :title="titleCard" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Informações Gerais'" :icon="$icons.box" readonly>
        <v-form v-model="validForm" ref="validForm" lazy-validation>
          <Row class="mt-0">
            <Col md="3">
              <DataPicker v-model="bankMovement.movement_date"
                :label="l.form.movement_date.label"
                :rules="[rules.required]"
                :readonly="isReadonly" />
            </Col>
            <Col md="3">
              <Select v-model="bankMovement.type_transaction"
                :label="l.form.type_transaction.label" :items="typeTransactionBankMovements"
                :rules="[rules.required]"
                :readonly="isReadonly" @change="changeType($event)" />
            </Col>
            <Col md="3">
              <Select v-model="bankMovement.payment_method_id"
                :placeholder="l.form.paymentMethod.placeholder"
                :label="l.form.paymentMethod.label" :items="paymentMethods"
                item-text="name" item-value="id"
                :rules="[rules.required]"
                :readonly="isReadonly" />
            </Col>
            <Col md="3">
              <Select v-model="bankMovement.movement_type"
                :label="l.form.movement_type.label" :items="typeInputOutput"
                :rules="[rules.required]" :readonly="isReadonly" />
            </Col>
          </Row>
          <Row class="mt-0">
            <Col md="9">
              <TextField v-model="bankMovement.description"
                :label="l.form.description.label"
                :placeholder="l.form.description.placeholder"
                :readonly="isReadonly" />
            </Col>
            <Col md="3">
              <TextFieldMoney v-model="bankMovement.value"
                :label="l.form.value.label"
                :placeholder="l.form.value.placeholder"
                :rules="[rules.money]" :readonly="isReadonly" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

      <div slot="actions">
        <Button :label="l.actions.save" class="mr-3" rounded color="secondary"
        @click="save()" :disabled="isReadonly"/>
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextField,
  TextFieldMoney,
  DataPicker,
  Button,
  ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { typeInputOutput, typeTransactionBankMovements } from '@/utils/options';
import { messageErrors } from '@/utils';
import { required, money } from '@/utils/rules';
import BankMovement from '../../model/BankMovement';
import { typePage, typeMovementsInputOutput } from '@/utils/enums';

export default {
  name: 'CreateBankMovement',
  components: {
    ModalCardDefault,
    Row,
    Col,
    Select,
    TextField,
    TextFieldMoney,
    DataPicker,
    Button,
    ExpansionPanel
  },
  props: {
    typePage: {
      type: String,
      required: true
    },
    bankId: {
      type: String,
      default: ''
    },
    bankMovementSelected: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      panel: [0],
      titleCard: '',
      validForm: false,
      bankMovement: BankMovement.model,
      typeInputOutput: typeInputOutput,
      typeTransactionBankMovements: typeTransactionBankMovements,
      rules: { required, money },
      paymentMethods: [],
      isReadonly: false
    };
  },
  computed: {
    l() {
      return this.$locales.pt.banks.list.modalMovements.modalCreateMovement;
    },
  },
  watch: {
    bankMovementSelected() {
      this.find();
    },
    typePage () {
      if (this.typePage === typePage.CREATE) {
        this.resetForm();
      } else {
        this.titleCard = this.l.title.view;
        this.isReadonly = true;
      }
    }
  },
  methods: {
    async find() {
      this.bankMovement = this.bankMovementSelected;
    },
    async save() {
      if (!this.$refs.validForm.validate()) {
        return false;
      }

      if (this.typePage === typePage.CREATE) {
        this.bankMovement = {
          ...this.bankMovement,
          bank_id: this.bankId
        };
        this.$api.banks.createMovement(this.bankId, this.bankMovement).then(() => {
          this.$noty.success(this.l.actions.createMovement);
          this.$emit('closeDialogCreateBankMovement');
          this.resetForm();
        }).catch((err) => {
          this.$noty.error(messageErrors(err.response));
        });
      }
    },
    getPaymentMethods() {
      this.$api.allTypes.filters({ url: 'filters?domain=all_type&filter[type]=payment-method&page=1&per_page=-1' }).then(({ data: { all_types } }) => {
        this.paymentMethods = all_types.map((item) => {
          return {
            name: item.name,
            id: item.id,
            code: item.value
          };
        });
      });
    },
    resetForm() {
      this.bankMovement = BankMovement.model;
      this.titleCard = this.l.title.new;
      this.isReadonly = false;
    },
    changeType(item) {
      switch (item) {
      case 'cash', 'check', 'pix_withdraw': this.bankMovement.movement_type = typeMovementsInputOutput.INPUT; break;
      default: this.bankMovement.movement_type = typeMovementsInputOutput.OUTPUT;
      }
    }
  },
  mounted() {
    this.getPaymentMethods();

    if (this.typePage === typePage.CREATE) {
      this.titleCard = this.l.title.new;
    } else {
      this.titleCard = this.l.title.view;
      this.isReadonly = true;
      this.find();
    }
  }
};
</script>

<style>

</style>
