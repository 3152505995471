<template>
  <ModalCardDefault :title="`Alterar Parcela: ${creditCardControlParcelSelected.number}`"
    @close="$emit('closeParcel')">
    <ExpansionPanel v-model="panel" :title="`Dados da Parcela`" :icon="$icons.box" readonly>
      <v-form v-model="validForm" ref="validForm" lazy-validation>
        <Row>
          <Col>
            <DataPicker v-model="localData.compensation_date" placeholder="Compensação"
              label="Compensação" :rules="[rules.required]" :readonly="isPaid" />
          </Col>
          <Col>
            <Select v-model="localData.bank_id" placeholder="Banco"
              label="Banco" :items="banks" :rules="[rules.required]"
              item-text="description" item-value="id" :readonly="isPaid" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localData.value" placeholder="Valor da Parcela"
              label="Valor da Parcela" :readonly="isPaid" />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextFieldMoney v-model="localData.fee_value" placeholder="Valor Tarifa"
              label="Valor Tarifa" :readonly="isPaid" />
          </Col>
          <Col>
            <TextFieldMoney v-model="localData.final_value" placeholder="Vlr. Final (Vlr. da Parcela - Vlr. Tarifa)"
              label="Vlr. Final (Vlr. da Parcela - Vlr. Tarifa)" :readonly="isPaid" />
          </Col>
          <Col>
            <Select v-model="localData.status" placeholder="Status"
              label="Status" :items="creditCardControlParcelsStatus"
              :rules="[rules.required]" :readonly="isPaid" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>

    <template v-slot:actions>
      <Button label="Confirmar" rounded color="secondary" class="mr-2"
        :disabled="isPaid" @click="modalDialogConfirm()" />
      <Button label="Antecipar" rounded color="light" class="mr-2"
        :disabled="isPaid" @click="modalDialogConfirm(true)" />
    </template>

    <Dialog :dialog="dialogModalParcelAnticipation" no-title :maxWidth="750">
      <ListParcelEditAnticipation slot="content"
        :credit-card-control-parcel-selected="creditCardControlParcelSelected"
        @confirmAnticipation="confirmAnticipation"
        @closeParcel="closeParcelEditAnticipation" />
    </Dialog>

    <DialogConfirmation :dialog="dialogConfirm" :loading="loadingConfirm"
      :title="titleModalConfirm"
      :message="messageModalConfirm"
      @noActionDialogConfirmation="modalDialogConfirm()"
      @yesActionDialogConfirmation="confirm" />
  </ModalCardDefault>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  DataPicker,
  Select,
  TextFieldMoney,
  Button,
  Dialog,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import DialogConfirmation from '@/components/DialogConfirmation';
import { required } from '@/utils/rules';
import { now } from '@/utils';
import { creditCardControlParcelsStatus } from '@/utils/options';
import ListParcelEditAnticipation from './ListParcelEditAnticipation';

export default {
  name: 'ListParcelEdit',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    Row,
    Col,
    DataPicker,
    Select,
    TextFieldMoney,
    Button,
    DialogConfirmation,
    Dialog,
    ListParcelEditAnticipation,
  },
  props: {
    creditCardControlParcelSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panel: [0],
      validForm: false,
      localData: {},
      rules: {
        required
      },
      banks: [],
      dialogConfirm: false,
      dialogModalParcelAnticipation: false,
      loadingConfirm: false,
      titleModalConfirm: '',
      messageModalConfirm: '',
    };
  },
  computed: {
    isPaid () {
      return this.creditCardControlParcelSelected.status === this.$enums.statusCreditCardControlParcel.PAID;
    },
    creditCardControlParcelsStatus () {
      return creditCardControlParcelsStatus.filter(status => status.value !== this.$enums.statusCreditCardControlParcel.REVERSED);
    }
  },
  watch: {
    creditCardControlParcelSelected () {
      this.setLocalData();
    }
  },
  methods: {
    setLocalData () {
      this.localData = {
        number: this.creditCardControlParcelSelected.number,
        compensation_date: this.creditCardControlParcelSelected.compensation_date,
        bank_id: this.creditCardControlParcelSelected.bank_id,
        value: this.creditCardControlParcelSelected.value,
        fee_value: this.creditCardControlParcelSelected.fee_value,
        anticipation_fee_value: this.creditCardControlParcelSelected.anticipation_fee_value,
        final_value: this.creditCardControlParcelSelected.final_value,
        status: this.creditCardControlParcelSelected.status === this.$enums.statusCreditCardControlParcel.REVERSED ? null : this.creditCardControlParcelSelected.status,
        is_anticipation: false,
      };
    },
    getBanks () {
      this.$api.banks.index().then(res => {
        this.banks = res.data.banks;
      }).catch(() => {
        this.$noty.error('Erro ao buscar bancos');
      });
    },
    modalDialogConfirm (isAnticipation = false) {
      if (!this.$refs.validForm.validate()) {
        this.$noty.error('Preencha os dados Obrigatórios');

        return false;
      }

      if (this.localData.status === this.$enums.statusCreditCardTransactionParcel.PAID) {
        this.titleModalConfirm = 'Receber Parcela';
        new Date(this.localData.compensation_date) > new Date(now()) ?
          this.messageModalConfirm = 'Deseja Receber o Valor da Parcela? A data de compensação será alterada para Hoje.' :
          this.messageModalConfirm = 'Deseja Receber o Valor da Parcela?';
      } else {
        this.titleModalConfirm = 'Atualizar Parcela';
        this.messageModalConfirm = 'Deseja Atualizar os Dados da Parcela?';
      }

      isAnticipation ? this.dialogModalParcelAnticipation = !this.dialogModalParcelAnticipation :
        this.dialogConfirm = !this.dialogConfirm;
    },
    confirm () {
      this.loadingConfirm = true;
      this.$api.creditCardControls.updateParcel(
        this.creditCardControlParcelSelected.credit_card_control_id,
        this.creditCardControlParcelSelected.id,
        this.localData).then(() => {
        this.$noty.success('Parcela alterada com sucesso');
      }).catch(() => {
        this.$noty.error('Erro ao alterar parcela');
      }).finally(() => {
        this.loadingConfirm = false;
        this.dialogConfirm = false;
        this.$emit('closeParcel');
      });
    },
    confirmAnticipation (data) {
      this.localData = {
        ...this.localData,
        ...data,
      };
      this.dialogModalParcelAnticipation = false;
      this.confirm();
    },
    closeParcelEditAnticipation () {
      this.dialogModalParcelAnticipation = false;
    }
  },
  mounted () {
    this.getBanks();
    this.setLocalData();
  }
};
</script>

<style>

</style>
