<template>
  <Menu :width="200" style="z-index: 10">
    <h3 v-text="companyLogged.social_name || 'Empresa'" />
    <p v-text="companyLogged.email || 'empresa@mail.com'" />
    <v-divider class="mb-"></v-divider>

    <v-list dense>
      <v-list-item link v-for="(item, index) in items" :key="index" @click="menuNavigator(item.route)">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-action>{{ item.label }}</v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider class="mb-5"></v-divider>

    <Button label="Sair" color="secondary" style="width: 100%" :icon="$icons.logout" @click="logout()" />
  </Menu>
</template>

<script>
import Menu from '@/components/vuetify/Menu';
import Button from '@/components/vuetify/Button';
import { getTypeUser, getCompany, getUser } from '@/utils';
import { TYPE_USER } from '@/utils/enums';

export default {
  name: 'NavBarSettings',
  components: { Menu, Button },
  data() {
    return {
    };
  },
  computed: {
    companyLogged () {
      let company = getCompany();
      let user = getUser();

      if (getTypeUser() === TYPE_USER.DISTRIBUTOR || getTypeUser() === TYPE_USER.RESELLER) {
        company = {
          ...user,
          social_name: user.name
        };
      }

      return company;
    },
    items () {
      const typeUser = getTypeUser();
      let items = [
        { label: 'Cliente', route: 'settings-customers', icon: this.$icons.accountTie },
        { label: 'Empresas', route: 'settings-companies', icon: this.$icons.industry },
        { label: 'Usuários', route: 'settings-users', icon: this.$icons.users },
        { label: 'Assinatura', route: 'settings-subscriptions', icon: this.$icons.cardAccountDetailsFavoriteOutline },
        { label: 'Natureza de Operações', route: 'settings-nature-operations', icon: this.$icons.officeBuildingCogOutline },
        { label: 'Configurações Fiscais', route: 'invoice-settings', icon: this.$icons.cogOutline },
        { label: 'Configurações da Empresa', route: 'settings-company-settings', icon: this.$icons.companySettings },
      ];

      if (typeUser === TYPE_USER.DISTRIBUTOR || typeUser === TYPE_USER.RESELLER) {
        items = [
          // { label: 'Assinatura', route: 'settings-subscriptions', icon: this.$icons.cardAccountDetailsFavoriteOutline },
        ];

        // if (typeUser === TYPE_USER.DISTRIBUTOR) {
        //   items.push({ label: 'Meus Revendedores', route: 'settings-subscriptions', icon: this.$icons.accountTie });
        // }
        // if (typeUser === TYPE_USER.RESELLER) {
        //   items.push({ label: 'Meus Clientes', route: 'settings-subscriptions', icon: this.$icons.users });
        // }
      }

      return items;
    }
  },
  methods:  {
    menuNavigator(routeName) {
      this.$router.push({ name: routeName }).catch(() => {
      });
    },
    logout () {
      this.$api.auth.logout().then(() =>  {
        this.$router.push({ name: 'login' });
      });
    }
  }
};
</script>

<style>

</style>
