import PriceListSchema from './schemas/PriceListSchema';
import ListPriceLists from './pages/ListPriceLists';
import CreatePriceList from './pages/CreatePriceList';

export default [
  {
    path: PriceListSchema.routes.list.path,
    name: PriceListSchema.routes.list.name,
    component: ListPriceLists,
    meta: PriceListSchema.routes.list.meta,
  },
  {
    path: PriceListSchema.routes.create.path,
    name: PriceListSchema.routes.create.name,
    component: CreatePriceList,
    meta: PriceListSchema.routes.create.meta
  },
  {
    path: PriceListSchema.routes.show.path,
    name: PriceListSchema.routes.show.name,
    component: CreatePriceList,
    meta: PriceListSchema.routes.show.meta
  },
];