import { typePageOptions } from '@/utils';
import { text, history, cancel } from '@/utils/icons';
import { required, money } from '@/utils/rules';
import CreditLetterBusiness from '@/business/CreditLetterBusiness';
import UsageHistoric from '@/views/Financial/CreditLetters/pages/components/UsageHistoric';

export default {
  domain: 'credit_letters',
  domainSingle: 'credit_letter',
  business: CreditLetterBusiness,
  title: 'Carta de Créditos',
  description: 'Lista de todas as Carta de Créditos do sistema',
  createBtn: 'Nova Carta de Créditos',
  showInactive: false,
  showExpand: false,
  routes: {
    list: {
      name: 'creditLetters',
      path: '/carta-de-creditos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'creditLetterCreate',
      path: '/carta-de-creditos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'creditLetterShow',
      path: '/carta-de-creditos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          label: 'Histórico de Uso',
          icon: history,
          iconColor: 'secondary',
          name: 'usageHistoric',
          action: 'usageHistoric',
          component: UsageHistoric,
          version: 'v2',
          maxWidth: 600,
        },
        {
          label: 'Cancelar Crédito',
          icon: cancel,
          iconColor: 'primary',
          name: 'openCancelCreditLetter',
          action: 'openCancelCreditLetter',
          component: null
        },
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados da Carta de Créditos',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'number',
          formattedName: 'number',
          label: 'Nº',
          align: '',
          md: '2',
          rules: [],
          list: true,
          readonly: true,
          disabled: false,
        },
        {
          type: 'autoComplete',
          name: 'register_id',
          formattedName: 'register.name',
          label: 'Cliente',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          domain: 'register',
          resultDomain: 'registers',
          searchBy: ['name', 'social_name', 'cpf', 'cnpj', 'cell_phone'],
          itemTextDataServe: 'social_name',
          title: 'Pesquise por: Nome, E-mail, Telefone, CPF e CNPJ',
          itemValueDataServe: 'id',
          conditionOr: true,
        },
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'money',
          name: 'value',
          formattedName: 'value_formatted',
          label: 'Valor',
          align: '',
          md: '2',
          rules: [required, money],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'usage_value',
          formattedName: 'usage_value_formatted',
          label: 'Valor Usado',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'money',
          name: 'balance',
          formattedName: 'balance_formatted',
          label: 'Saldo',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: true,
          disabled: false,
          clearable: false,
          prefix: 'R$',
          length: 11,
          precision: 2,
          empty: null,
        },
        {
          type: 'text',
          name: 'status',
          formattedName: 'status_formatted',
          label: 'Status',
          align: '',
          md: '3',
          rules: [],
          list: true,
          readonly: true,
          disabled: false,
        },
      ],
    },
  ],
  footer: [],
};
