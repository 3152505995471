<template>
  <div>
    <PageHeader :schema="$schemas.table" />
    <PageContent>
      <DynamicFormPage :schema="$schemas.table" :service="$api.tables" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicFormPage from '@/components/dynamics/DynamicFormPage';

export default {
  name: 'CreateTable',
  components: { PageHeader, PageContent, DynamicFormPage },
};
</script>
