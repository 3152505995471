import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import NatureOperationSchema from '../schemas/NatureOperationSchema';
import {
  typeNatureOperation, typeNationalSimpleTable, typeYesNoBoolean,
  destinationLocationIdentifier
} from '@/utils/options';
import { getText } from '@/utils';

const formatResponse = (item) => {
  item.type_formatted = getText(typeNatureOperation, item.type);
  item.national_simple_table_formatted = getText(typeNationalSimpleTable, item.national_simple_table);
  item.movement_stock_formatted = getText(typeYesNoBoolean, item.movement_stock);
  item.is_invoicing_formatted = getText(typeYesNoBoolean, item.is_invoicing);
  item.is_total_approximate_formatted = getText(typeYesNoBoolean, item.is_total_approximate);
  item.is_pdv_formatted = item.is_pdv ? 'Sim' : 'Não';
};

const formatResponseListStates = (item) => {
  item.nature_operation_states.forEach((item) => {
    item.destiny_location_formatted = getText(destinationLocationIdentifier, item.destiny_location);
    delete item.code_state;
  });

  return item;
};

const NatureOperationsService = DynamicService('nature_operations', NatureOperationSchema, { formatResponse, removeNullable: false });

const NatureOperationStatesCommands = () => ({
  async createState(nature_operation_id, nature_operation_states) {
    return await axios.post(`nature_operations/${nature_operation_id}/states`, nature_operation_states);
  },
  async listStates(nature_operation_id) {
    const { data } = await axios.get(`nature_operations/${nature_operation_id}/states`);

    return formatResponseListStates(data);
  },
  async deleteState(nature_operation_id, nature_operation_states_id) {
    return await axios.delete(`nature_operations/${nature_operation_id}/states/${nature_operation_states_id}`);
  },
  async getState(nature_operation_id, nature_operation_states_id) {
    return await axios.get(`nature_operations/${nature_operation_id}/states/${nature_operation_states_id}`);
  },
  async updateState(nature_operation_states) {
    const nature_operation_id = nature_operation_states.nature_operation_id;
    const nature_operation_states_id = nature_operation_states.id;
    const payload = nature_operation_states;
    delete payload.code_state;

    return await axios.put(`nature_operations/${nature_operation_id}/states/${nature_operation_states_id}`, payload);
  },
  async duplicateState(natureOperationId,
    natureOperationStateId, uf) {
    const payload = { uf };

    return await axios.post(`nature_operations/${natureOperationId}/states/${natureOperationStateId}/duplicate`, payload);
  }
});

export default NatureOperationsService;
export { NatureOperationsService, NatureOperationStatesCommands };
