import { typeStockMovements } from './enums';

export const typeRegister = [
  { id: 1, text: 'Funcionário', value: 'employee' },
  { id: 2, text: 'Cliente', value: 'client' },
  { id: 3, text: 'Fornecedor', value: 'provider' },
  { id: 3, text: 'Vendedor', value: 'seller' },
];

export const typeAllType = [
  { id: 1, text: 'Tipo de Certificado', value: 'certificate-type' },
  { id: 2, text: 'Período do Certificado', value: 'certificatePeriod' },
  { id: 3, text: 'Forma de Pagamento', value: 'payment-method' },
  { id: 4, text: 'Tipo de Agendamento', value: 'type-schedule' },
];

export const typeYesNoBoolean = [
  { id: 1, text: 'Sim', value: true },
  { id: 2, text: 'Não', value: false }
];

export const typeValuePriceList = [
  { id: 1, text: 'Valor em Reais', value: 'real' },
  { id: 2, text: 'Porcentagem', value: 'percentage' }
];

export const typeNatureOperation = [
  { id: 1, text: 'Vendas', value: 'sales' },
  { id: 2, text: 'Compras', value: 'purchases' },
  { id: 3, text: 'Devolução de Vendas', value: 'devolution-sales' },
  { id: 4, text: 'Devolução de Compras', value: 'devolution-purchases' },
];

export const typeUser = [
  { id: 1, text: 'Administrador', value: 'admin' },
  { id: 2, text: 'Gerente', value: 'manager' },
  { id: 3, text: 'Padrão', value: 'default' },
  { id: 4, text: 'Caixa', value: 'box' },
];

export const typeYesNo = [
  { id: 1, text: 'Sim', value: 'yes' },
  { id: 2, text: 'Não', value: 'no' }
];

export const typeSituation = [
  { id: 1, text: 'Ativo', value: 'active' },
  { id: 2, text: 'Inativo', value: 'inactive' }
];

export const typeMovement = [
  { id: 1, text: 'Entrada', value: 'open' },
  { id: 2, text: 'Saída', value: 'exit' },
];

export const statusSubscriptions = [
  { id: 1, text: 'Ativa', value: 'active' },
  { id: 2, text: 'Suspensa', value: 'suspended' },
  { id: 2, text: 'Cancelada', value: 'canceled' },
  { id: 2, text: 'Falha', value: 'failed' },
];

export const UF = [
  { id: 1, text: 'AC', value: 'AC', code_state: '12' },
  { id: 2, text: 'AL', value: 'AL', code_state: '27' },
  { id: 3, text: 'AP', value: 'AP', code_state: '16' },
  { id: 4, text: 'AM', value: 'AM', code_state: '13' },
  { id: 5, text: 'BA', value: 'BA', code_state: '29' },
  { id: 6, text: 'CE', value: 'CE', code_state: '23' },
  { id: 7, text: 'DF', value: 'DF', code_state: '53' },
  { id: 8, text: 'ES', value: 'ES', code_state: '32' },
  { id: 9, text: 'GO', value: 'GO', code_state: '52' },
  { id: 10, text: 'MA', value: 'MA', code_state: '21' },
  { id: 11, text: 'MT', value: 'MT', code_state: '51' },
  { id: 12, text: 'MS', value: 'MS', code_state: '50' },
  { id: 13, text: 'MG', value: 'MG', code_state: '31' },
  { id: 14, text: 'PA', value: 'PA', code_state: '15' },
  { id: 15, text: 'PB', value: 'PB', code_state: '25' },
  { id: 16, text: 'PR', value: 'PR', code_state: '41' },
  { id: 17, text: 'PE', value: 'PE', code_state: '26' },
  { id: 18, text: 'PI', value: 'PI', code_state: '22' },
  { id: 20, text: 'RN', value: 'RN', code_state: '24' },
  { id: 21, text: 'RS', value: 'RS', code_state: '43' },
  { id: 19, text: 'RJ', value: 'RJ', code_state: '33' },
  { id: 22, text: 'RO', value: 'RO', code_state: '11' },
  { id: 23, text: 'RR', value: 'RR', code_state: '14' },
  { id: 24, text: 'SC', value: 'SC', code_state: '42' },
  { id: 25, text: 'SP', value: 'SP', code_state: '35' },
  { id: 26, text: 'SE', value: 'SE', code_state: '28' },
  { id: 27, text: 'TO', value: 'TO', code_state: '17' },
  { id: 28, text: 'EX', value: 'EX', code_state: '999' }
];

export const months = [
  { id: 1, text: 'Janeiro', value: '01' },
  { id: 2, text: 'Fevereiro', value: '02' },
  { id: 3, text: 'Março', value: '03' },
  { id: 4, text: 'Abril', value: '04' },
  { id: 5, text: 'Maio', value: '05' },
  { id: 6, text: 'Junho', value: '06' },
  { id: 7, text: 'Julho', value: '07' },
  { id: 8, text: 'Agosto', value: '08' },
  { id: 9, text: 'Setembro', value: '09' },
  { id: 10, text: 'Outubro', value: '10' },
  { id: 11, text: 'Novembro', value: '11' },
  { id: 12, text: 'Dezembro', value: '12' },
];

export const checkControlStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Pago', value: 'paid' },
];

export const billetControlStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Pago', value: 'paid' },
];

export const accountPaymentsStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Cancelado', value: 'canceled' },
  { id: 2, text: 'Pago', value: 'paid' },
];

export const accountTypes = [
  { id: 1, text: 'Recebimentos', value: 'receivable' },
  { id: 2, text: 'Pagamentos', value: 'payment' },
];

export const accountReceivablesStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Cancelado', value: 'canceled' },
  { id: 2, text: 'Pago', value: 'paid' },
];

export const productInvoicesStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Processando', value: 'processing' },
  { id: 2, text: 'Autorizada', value: 'authorized' },
  { id: 2, text: 'Rejeitada', value: 'rejected' },
  { id: 2, text: 'Cancelada', value: 'canceled' },
];

export const serviceInvoicesStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Processando', value: 'processing' },
  { id: 2, text: 'Autorizada', value: 'authorized' },
];

export const presenceIndicator = [
  { text: 'Não se aplica', value: 'not_apply' },
  { text: 'Operação presencial', value: 'presential' },
  { text: 'Operação não presencial, pela Internet', value: 'not_presential_internet' },
  { text: 'Operação não presencial, Teleatendimento', value: 'not_presential_call_center' },
  { text: 'NFC-e em operação com entrega a domicílio', value: 'nfce' },
  { text: 'Operação não presencial, outros', value: 'not_presential_others' },
];

export const freights = [
  { text: 'Por conta do Emitente', value: 'issuer' },
  { text: 'Por conta do Destinatário', value: 'recipient' },
  { text: 'Por conta do Terceiro', value: 'third_party' },
  { text: 'Próprio, Por conta do Emitente', value: 'own_issuer' },
  { text: 'Próprio, por conta do Destinatário', value: 'own_recipient' },
  { text: 'Sem frete', value: 'no_freight' },
];

export const typeHasVariation = [
  { text: 'Com Variação', value: true },
  { text: 'Único', value: false },
];

export const orientationReport = [
  { text: 'Retrato', value: 'portrait' },
  { text: 'Paisagem', value: 'landscape' },
];

export const typeOperationStock = [
  { text: 'Entrada', value: typeStockMovements.INPUT },
  { text: 'Saída', value: typeStockMovements.OUTPUT },
];

// export const filterOriginStockMovements = [
//   { text: 'Manual', value: 'manually' },
//   { text: 'Pedido de Vendas', value: orderTypes.ORDER_SALE },
//   { text: 'Pedido de Compras', value: orderTypes.ORDER_PURCHASE },
//   { text: 'Devolução Pedido de Vendas', value: orderTypes.ORDER_SALE_DEVOLUTION },
//   { text: 'Devolução Pedido de Compras', value: orderTypes.ORDER_PURCHASE_DEVOLUTION },
// ];

export const typeDocument = [
  { text: 'Entrada', value: 'input' },
  { text: 'Saída', value: 'output' },
];

export const printerType = [
  { text: 'Sem DANFE', value: 'no_generate' },
  { text: 'DANFE Retrato', value: 'portrait' },
  { text: 'DANFE Paisagem', value: 'landscape' },
  { text: 'DANFE Simplificado', value: 'simplified' },
  { text: 'NFC-e', value: 'nfce' },
  { text: 'NFC-e Men. Eletrônica', value: 'nfce_msg' },
];

export const emissionFinality = [
  { text: 'Normal', value: 'normal' },
  { text: 'Complementar', value: 'complementary' },
  { text: 'Ajuste', value: 'adjustment' },
  { text: 'Devolução', value: 'devolution' },
];

export const emissionType = [
  { text: 'Normal', value: 'normal' },
];

export const typeNf = [
  { text: 'NF-e', value: 'nfe' },
  { text: 'NFC-e', value: 'nfce' },
  { text: 'NFS-e', value: 'nfse' },
];

export const environmentNf = [
  { text: 'Homologação', value: 'homologation' },
  { text: 'Produção', value: 'production' },
];

export const modelNf = [
  { text: 'NF-e', value: '55' },
  { text: 'NFC-e', value: '65' },
];

export const statusAccounts = [
  { text: 'Aberto', value: 'pending' },
  { text: 'Pago', value: 'paid' },
  { text: 'Parcial', value: 'pair' },
];

export const statusBalance = [
  { text: 'Pendente', value: 'pending' },
  { text: 'Finalizado', value: 'finished' },
  { text: 'Cancelado', value: 'canceled' },
];

export const destinationLocationIdentifier = [
  { text: '1 - Operação Interna', value: 'internal' },
  { text: '2 - Operação Interestadual', value: 'interstate' },
  { text: '3 - Operação Externa', value: 'external' },
];

export const icmsCSTSimple = [
  { text: '101 - Tributada com permissão de crédito', value: '101' },
  { text: '102 - Tributada sem permissão de crédito', value: '102' },
  { text: '103 - Isenção do ICMS para faixa de receita bruta', value: '103' },
  { text: '201 - Tributada com permissão de crédito e com cobrança de ICMS por ST', value: '201' },
  { text: '202 - Tributada sem permissão de crédito e com cobrança de ICMS por ST', value: '202' },
  { text: '203 - Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por ST', value: '203' },
  { text: '300 - Imune', value: '300' },
  { text: '400 - Não Tributada', value: '400' },
  { text: '500 - ICMS cobrado anteriormente por ST ou por antecipação', value: '500' },
  { text: '900 - Outros', value: '900' },
];

export const icmsCSTNormal = [
  { text: '00 - Tributado Integralmente', value: '00' },
  { text: '10 - Tributada com Cobrança do ICMS por ST', value: '10' },
  { text: '10 - Tributadada com Cobrança do ICMS (com partilha do ICMS entre a UF de origem e a UF de destino ou a UF definida na legislação', value: '10b' },
  { text: '20 - Com Redução da base de cálculo', value: '20' },
  { text: '30 - Isenta ou não Tributada e com Cobrança do ICMS por ST', value: '30' },
  { text: '40 - Isenta', value: '40' },
  { text: '41 - Não Tributada', value: '41' },
  { text: '41 - Não Tributada (ICMS ST devido para UF de destino, nas operações Interestaduais de produto que tiverem retenção antecipada ICMS por ST', value: '41b' },
  { text: '50 - Suspensão', value: '50' },
  { text: '51 - Diferimento', value: '51' },
  { text: '60 - Cobrado anteriormente por ST', value: '60' },
  { text: '70 - Com redução da base de cálculo e cobrança do ICMS por ST', value: '70' },
  { text: '90 - Outras (com partilha do ICMS entre a UF de origem e a UF de destino ou a UF definida na legislação)', value: '90b' },
  { text: '90 - Outras', value: '90' },
];

export const imcsOrigin = [
  { text: '0 - Nacional, exceto as indicadas nos códigos 3 a 5', value: '0' },
  { text: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: '1' },
  { text: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: '2' },
  { text: '3 - Nacional, mercadoria ou bem com conteúdo de importação superior a  40% (quarenta por cento)', value: '2' },
  { text: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/67, e as Leis nºs 8.248/91, 8.387/91, 10.176/01 e 11.484/07', value: '4', },
  { text: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% (quarenta por cento)', value: '5' },
  { text: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX', value: '6' },
  { text: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX', value: '7' },
  { text: '8 - Nacional, mercadoria ou bem com conteúdo de importação superior a 70% (setenta por cento)', value: '8' },
];

export const icmsDeterminatonMode = [
  { text: '0 - Margem de valor agregado', value: 'value_added_margin' },
  { text: '1 - Pauta (valor)', value: 'tariff_value' },
  { text: '2 - Preço tabelado Max(valor)', value: 'max_tabulated_price' },
  { text: '3 - Valor da operação', value: 'operation_value' },
];

export const icmsExemptionReason = [
  { text: '1 - Táxi', value: 'taxi' },
  { text: '2 - Deficiente físico', value: 'handicapped' },
  { text: '3 - Produtor agropecuário', value: 'agricultural_producer' },
  { text: '4 - Frotista/Locadora', value: 'fleet_rental' },
  { text: '5 - Diplomático/Consular', value: 'diplomatic' },
  { text: '6 - Utilitários e motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (resolução 714/88 e 790/94 - CONTRAN e suas alterações)', value: 'amazon_714' },
  { text: '7 - SUFRAMA', value: 'suframa' },
  { text: '8 - Venda a Orgãos Públicos', value: 'sale_public' },
  { text: '9 - Outros (NT 2011/004)', value: 'others' },
];

export const icmsStDeterminatonMode = [
  { text: '0 - Preço tabelado ou Máximo Sugerido', value: 'tabulated_price' },
  { text: '1 - Lista Negativa (valor)', value: 'negative_list' },
  { text: '2 - Lista Positiva (valor)', value: 'positive_list' },
  { text: '3 - Lista Neutra (valor)', value: 'neutral_list' },
  { text: '4 - Margem de valor Agregado (%)', value: 'value_added_margin' },
  { text: '5 - Pauta (valor)', value: 'tariff_value' },
  { text: '6 - Valor da Operação', value: 'operation_value' },
  { text: '7 - Nenhum', value: 'none' },
];

export const ipiCST = [
  { text: '00 - Entrada com recuperação de crédito', value: '00' },
  { text: '01 - Entrada tributada com alíquota zero', value: '01' },
  { text: '02 - Entrada isenta', value: '02' },
  { text: '03 - Entrada não-tributada', value: '03' },
  { text: '04 - Entrada imune', value: '04' },
  { text: '05 - Entrada com suspensão', value: '05' },
  { text: '49 - Outras entradas', value: '49' },
  { text: '50 - Saída tributada', value: '50' },
  { text: '51 - Saída tributada com alíquota zero', value: '51' },
  { text: '52 - Saída isenta', value: '52' },
  { text: '53 - Saída não-tributada', value: '53' },
  { text: '54 - Saída imune', value: '54' },
  { text: '55 - Saída com suspensão', value: '55' },
  { text: '99 - Outras saídas', value: '99' },
];

export const pisCST = [
  { text: '01 - Operação tributável - Base de cálculo = Valor da operação alíquota normal (Cumulativo/não Cumulativo)', value: '01' },
  { text: '02 - Operação tributável - Base de cálculo - Valor da operação (Alíquota Diferenciada)', value: '02' },
  { text: '03 - Operação tributável - Base de cálculo = Quantidade vendida x Alíquota por unidade de produto', value: '03' },
  { text: '04 - Operação tributável - Tributação Monofásica - (Alíquota zero)', value: '04' },
  { text: '05 - Operação tributável (Substituição tributária)', value: '05' },
  { text: '06 - Operação tributável - Alíquota zero', value: '06' },
  { text: '07 - Operação isenta de contribuição', value: '07' },
  { text: '08 - Operação sem incidência de Contribuição', value: '08' },
  { text: '09 - Operação com suspensão de Contribuição', value: '09' },
  { text: '49 - Outras operações de saída', value: '49' },
  { text: '50 - Operação com direito a crédito - Vinculada exclusivamente a receita tributária no mercado interno', value: '50' },
  { text: '51 - Operação com direito a crédito - Vinculada exclusivamente a receita não tributária no mercado interno', value: '51' },
  { text: '52 - Operação com direito a crédito - vinculada exclusivamente a Receita de Exportação', value: '52' },
  { text: '53 - Operação com direito a crédito - vinculada a Receita tributária e não Tributada no Mercado Interno', value: '53' },
  { text: '54 - Operação com direito a crédito - Vinculada a receita tributária no Mercado Interno e de Exportação', value: '54' },
  { text: '55 - Operação com direito a crédito - Vinculada a receita não-tributária no Mercado Interno e de Exportação', value: '55' },
  { text: '56 - Operação com direito a crédito - Vinculada a receita Tributaria e não-tributária no Mercado Interno e de Exportação', value: '56' },
  { text: '60 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita Tributada no Mercado Interno', value: '60' },
  { text: '61 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita não-Tributada no Mercado Interno', value: '61' },
  { text: '62 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita de Exportação', value: '62' },
  { text: '63 - Crédito Presumido - Operação de aquisição vinculada a receitas não-Tributada no Mercado Interno', value: '63' },
  { text: '64 - Crédito Presumido - Operação de aquisição vinculada a receitas Tributada no Mercado Interno e de Exportação', value: '64' },
  { text: '65 - Crédito Presumido - Operação de aquisição vinculada a receitas não-Tributada no Mercado Interno e de Exportação', value: '65' },
  { text: '66 - Crédito Presumido - Operação de aquisição vinculada a receitas Tributada e não-Tributada no Mercado Interno e de Exportação', value: '66' },
  { text: '67 - Crédito Presumido - Outras operações', value: '67' },
  { text: '70 - Operação de aquisição sem direito a crédito', value: '70' },
  { text: '71 - Operação de aquisição com Insenção', value: '71' },
  { text: '72 - Operação de aquisição com Suspensão', value: '72' },
  { text: '73 - Operação de aquisição a Alíquota Zero', value: '73' },
  { text: '74 - Operação de aquisição sem Incidência da Contribuição', value: '74' },
  { text: '75 - Operação de aquisição por Substituição tributária', value: '75' },
  { text: '98 - Outras operações de Entrada', value: '98' },
  { text: '99 - Outras operações', value: '99' },
];

export const cofinsCST = [
  { text: '01 - Operação tributável - Base de cálculo = Valor da operação alíquota normal (Cumulativo/não Cumulativo)', value: '01' },
  { text: '02 - Operação tributável - Base de cálculo - Valor da operação (Alíquota Diferenciada)', value: '02' },
  { text: '03 - Operação tributável - Base de cálculo = Quantidade vendida x Alíquota por unidade de produto', value: '03' },
  { text: '04 - Operação tributável - Tributação Monofásica - (Alíquota zero)', value: '04' },
  { text: '05 - Operação tributável (Substituição tributária)', value: '05' },
  { text: '06 - Operação tributável - Alíquota zero', value: '06' },
  { text: '07 - Operação isenta de contribuição', value: '07' },
  { text: '08 - Operação sem incidência de Contribuição', value: '08' },
  { text: '09 - Operação com suspensão de Contribuição', value: '09' },
  { text: '49 - Outras operações de saída', value: '49' },
  { text: '50 - Operação com direito a crédito - Vinculada exclusivamente a receita tributária no mercado interno', value: '50' },
  { text: '51 - Operação com direito a crédito - Vinculada exclusivamente a receita não tributária no mercado interno', value: '51' },
  { text: '52 - Operação com direito a crédito - vinculada exclusivamente a Receita de Exportação', value: '52' },
  { text: '53 - Operação com direito a crédito - vinculada a Receita tributária e não Tributada no Mercado Interno', value: '53' },
  { text: '54 - Operação com direito a crédito - Vinculada a receita tributária no Mercado Interno e de Exportação', value: '54' },
  { text: '55 - Operação com direito a crédito - Vinculada a receita não-tributária no Mercado Interno e de Exportação', value: '55' },
  { text: '56 - Operação com direito a crédito - Vinculada a receita Tributaria e não-tributária no Mercado Interno e de Exportação', value: '56' },
  { text: '60 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita Tributada no Mercado Interno', value: '60' },
  { text: '61 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita não-Tributada no Mercado Interno', value: '61' },
  { text: '62 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita de Exportação', value: '62' },
  { text: '63 - Crédito Presumido - Operação de aquisição vinculada a receitas não-Tributada no Mercado Interno', value: '63' },
  { text: '64 - Crédito Presumido - Operação de aquisição vinculada a receitas Tributada no Mercado Interno e de Exportação', value: '64' },
  { text: '65 - Crédito Presumido - Operação de aquisição vinculada a receitas não-Tributada no Mercado Interno e de Exportação', value: '65' },
  { text: '66 - Crédito Presumido - Operação de aquisição vinculada a receitas Tributada e não-Tributada no Mercado Interno e de Exportação', value: '66' },
  { text: '67 - Crédito Presumido - Outras operações', value: '67' },
  { text: '70 - Operação de aquisição sem direito a crédito', value: '70' },
  { text: '71 - Operação de aquisição com Insenção', value: '71' },
  { text: '72 - Operação de aquisição com Suspensão', value: '72' },
  { text: '73 - Operação de aquisição a Alíquota Zero', value: '73' },
  { text: '74 - Operação de aquisição sem Incidência da Contribuição', value: '74' },
  { text: '75 - Operação de aquisição por Substituição tributária', value: '75' },
  { text: '98 - Outras operações de Entrada', value: '98' },
  { text: '99 - Outras operações', value: '99' },
];

export const taxPayer = [
  { text: '1 - Contribuinte ICMS (informar a IE)', value: 'taxpayer' },
  { text: '2 - Contribuinte isento de inscrição no cadastro de contribuintes do ICMS', value: 'exempt_taxpayer' },
  { text: '9 - Não contribuinte (que pode ou não possuir inscrição estadual no cadastro de contribuintes do ICMS)', value: 'non_taxpayer' },
];

export const taxPayerShort = [
  { text: '1 - Contribuinte ICMS', value: 'taxpayer' },
  { text: '2 - Contribuinte isento de inscrição', value: 'exempt_taxpayer' },
  { text: '9 - Não contribuinte', value: 'non_taxpayer' },
];

export const intermediaryIndicator = [
  { text: 'Operação sem intermediador (em site ou plataforma própria).', value: 'non_intermediary' },
  { text: 'Operação em site ou plataforma de terceiros (intermediadores / marketplace).', value: 'intermediary_in_site' },
];

export const intermediaryIndicatorShort = [
  { text: 'Sem intermediador', value: 'non_intermediary' },
  { text: 'Em site ou outros', value: 'intermediary_in_site' },
];

export const typeNationalSimpleTable = [
  { id: 1, text: 'Comércio', value: 'business' },
  { id: 2, text: 'Indústria', value: 'industry' }
];

export const typeTaxRegime = [
  { id: 1, text: 'Simples Nacional', value: 'national_simple' },
  { id: 2, text: 'Simples Nacional - excesso de sublimite da receita bruta', value: 'national_simple_excess' },
  { id: 3, text: 'Regime Normal', value: 'normal_regime' }
];

export const typeTaxPayer = [
  { text: '1 - Contribuinte ICMS (informar a IE)', value: 'taxpayer' },
  { text: '2 - Contribuinte isento de inscrição no cadastro de contribuintes do ICMS', value: 'exempt_taxpayer' },
  { text: '9 - Não contribuinte (que pode ou não possuir inscrição estadual no cadastro de contribuintes do ICMS)', value: 'non_taxpayer' }
];

export const typePersonType = [
  { text: 'Pessoa Física', value: 'F' },
  { text: 'Pessoa Jurídica', value: 'J' }
];

export const typeInputOutput = [
  { text: 'Entrada', value: 'input' },
  { text: 'Saída', value: 'output' }
];

export const typeInputOutputBox = [
  { text: 'Suprimento', value: 'input' },
  { text: 'Sangria', value: 'output' }
];

export const statusBoxMovements = [
  { text: 'Aberto', value: 'open' },
  { text: 'Fechado', value: 'closed' }
];

export const originTypeBankMovements = [
  { id: 1, text: 'Manual', value: 'App\\Models\\BankMovement' },
  { id: 2, text: 'Pagamentos', value: 'App\\Models\\AccountPayment' },
  { id: 3, text: 'Recebimentos', value: 'App\\Models\\AccountReceive' },
  { id: 4, text: 'Controle de Cartão', value: 'App\\Models\\CreditCardTransactionParcel' },
];

export const originTypeBoxMovements = [
  { id: 1, text: 'Manual', value: 'App\\Models\\BoxMovementItem' }
];

export const accountingAccountType = [
  { id: 1, text: 'Ativos', value: 'active' },
  { id: 1, text: 'Passivos', value: 'passive' },
  { id: 1, text: 'Receitas Fixas', value: 'fixed_revenues' },
  { id: 1, text: 'Receitas Variáveis', value: 'variable_revenues' },
  { id: 1, text: 'Despesas Fixas', value: 'fixed_expenses' },
  { id: 1, text: 'Depesas Variáveis', value: 'variable_expenses' },
];

export const typeTransactionBankMovements = [
  { id: 1, text: 'Depósito Cheque', value: 'deposit_check' },
  { id: 2, text: 'Depósito Espécie', value: 'deposit_cash' },
  { id: 2, text: 'Espécie', value: 'cash' },
  { id: 3, text: 'Pagamento Boleto', value: 'billet' },
  { id: 4, text: 'Pix Transferência', value: 'pix_transfer' },
  { id: 5, text: 'Pix Recebe', value: 'pix_withdraw' },
  { id: 6, text: 'Saque', value: 'withdraw' },
  { id: 7, text: 'Transferência', value: 'transfer' },
];

export const typeAdjustmentPriceList = [
  { id: 1, text: 'Acréscimo', value: 'addition' },
  { id: 2, text: 'Desconto', value: 'discount' },
];

export const qtdParcels = [
  { id: 1, text: '1X', value: '1' },
  { id: 2, text: '2X', value: '2' },
  { id: 3, text: '3X', value: '3' },
  { id: 4, text: '4X', value: '4' },
  { id: 5, text: '5X', value: '5' },
  { id: 6, text: '6X', value: '6' },
  { id: 7, text: '7X', value: '7' },
  { id: 8, text: '8X', value: '8' },
  { id: 9, text: '9X', value: '9' },
  { id: 10, text: '10X', value: '10' },
  { id: 11, text: '11X', value: '11' },
  { id: 12, text: '12X', value: '12' },
  { id: 13, text: '13X', value: '13' },
  { id: 14, text: '14X', value: '14' },
  { id: 15, text: '15X', value: '15' },
  { id: 16, text: '16X', value: '16' },
  { id: 17, text: '17X', value: '17' },
  { id: 18, text: '18X', value: '18' },
  { id: 19, text: '19X', value: '19' },
  { id: 20, text: '20X', value: '20' },
  { id: 21, text: '21X', value: '21' },
  { id: 22, text: '22X', value: '22' },
  { id: 23, text: '23X', value: '23' },
  { id: 24, text: '24X', value: '24' },
];

export const creditCardControlStatus = [
  { id: 1, text: 'Aberto', value: 'pending' },
  { id: 2, text: 'Pago', value: 'paid' },
  { id: 3, text: 'Parcial', value: 'partial' },
];

export const creditCardControlParcelsStatus = [
  { id: 1, text: 'Aberto', value: 'pending' },
  { id: 2, text: 'Pago', value: 'paid' },
  { id: 3, text: 'Estornada', value: 'reversed' },
];

export const tableStatus = [
  { id: 1, text: 'Disponivel', value: 'available' },
  { id: 2, text: 'Indisponível', value: 'unavailable' },
];

export const creditLetterStatus = [
  { id: 1, text: 'Não Usada', value: 'not_usage' },
  { id: 2, text: 'Usada Parcial', value: 'partial_usage' },
  { id: 3, text: 'Usada', value: 'usage' },
  { id: 4, text: 'Cancelada', value: 'cancelled' },
];

export const nfStatus = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Autorizada', value: 'authorized' },
  { id: 3, text: 'Rejeitada', value: 'rejected' },
];

export const cashFlowTypes = [
  { id: 1, text: 'Pendente', value: 'pending' },
  { id: 2, text: 'Pago', value: 'paid' },
];
