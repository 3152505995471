<template>
  <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
    <Row>
      <Col md="12">
        <DataTable no-search no-sync hide-default-footer :items="model.payments"
          :headers="l.headers" :loading="false">
        </DataTable>
      </Col>
    </Row>
  </ExpansionPanel>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  DataTable
} from '@/components/vuetify';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';

export default {
  name: 'OrderPayments',
  components: {
    ExpansionPanel,
    Row,
    Col,
    DataTable
  },
  mixins: [UpdateInfosOrderSaleMixin],
  data () {
    return {
      panel: [0],
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.payments;
    },
  },
};
</script>
