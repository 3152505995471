var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"schema":_vm.$schemas.cashFlow}}),_c('PageContent',[_c('DynamicListPage',{attrs:{"schema":_vm.$schemas.cashFlow,"service":_vm.$api.cashFlows,"slots":_vm.slotItems},scopedSlots:_vm._u([{key:"start_balance_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{class:[_vm.getClassValues(item, 'start_balance')]},[_c('b',[_vm._v(_vm._s(item.start_balance_formatted))])])]}},{key:"total_entry_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{class:[_vm.getClassValues(item, 'total_entry')]},[_c('b',[_vm._v(_vm._s(item.total_entry_formatted))])])]}},{key:"total_exit_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{class:[_vm.getClassValues(item, 'total_exit')]},[_c('b',[_vm._v(_vm._s(item.total_exit_formatted))])])]}},{key:"end_balance_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{class:[_vm.getClassValues(item, 'end_balance')]},[_c('b',[_vm._v(_vm._s(item.end_balance_formatted))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }