<template>
  <div>
    <ModalCardDefault :title="titleCard" @close="$emit('close')">
      <ExpansionPanel v-model="panel" :title="'Informações Gerais'" :icon="$icons.box" readonly>
        <v-form v-model="validForm" ref="validForm" lazy-validation>
          <Row class="mt-0">
            <Col md="4">
            <DataPicker v-model="boxMovementItems.movement_date"
              :label="l.form.movement_date.label"
              :rules="[rules.required]"
              :readonly="isReadonly" />
            </Col>
            <Col md="4">
            <Select v-model="boxMovementItems.payment_method_id"
              :placeholder="l.form.paymentMethod.placeholder"
              :label="l.form.paymentMethod.label" :items="paymentMethods"
              item-text="name" item-value="id"
              :rules="[rules.required]"
              :readonly="isReadonly" />
            </Col>
            <Col md="4">
            <Select v-model="boxMovementItems.movement_type"
              :label="l.form.movement_type.label" :items="typeInputOutputBox"
              :rules="[rules.required]" :readonly="isReadonly" />
            </Col>
          </Row>
          <Row class="mt-0">
            <Col md="8">
            <TextField v-model="boxMovementItems.description"
              :label="l.form.description.label"
              :placeholder="l.form.description.placeholder"
              :readonly="isReadonly" />
            </col>
            <Col md="4">
              <TextFieldMoney v-model="boxMovementItems.value"
                :label="l.form.value.label"
                :placeholder="l.form.value.placeholder"
                :rules="[rules.required]" :readonly="isReadonly" />
            </Col>
          </Row>
        </v-form>
      </ExpansionPanel>

      <div slot="actions">
        <Button :label="l.actions.save" class="mr-3" rounded color="secondary"
        @click="save()" :disabled="isReadonly"/>
      </div>
    </ModalCardDefault>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  TextField,
  TextFieldMoney,
  DataPicker,
  Button,
  ExpansionPanel
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import { typeInputOutputBox } from '@/utils/options';
import { messageErrors } from '@/utils';
import { required } from '@/utils/rules';
import BoxMovementItem from '../../model/BoxMovementItem';
import { typePage, typeAllTypes } from '@/utils/enums';

export default {
  name: 'CreateBoxMovement',
  components: {
    ModalCardDefault,
    Row,
    Col,
    Select,
    TextField,
    TextFieldMoney,
    DataPicker,
    Button,
    ExpansionPanel
  },
  props: {
    typePage: {
      type: String,
      required: true
    },
    boxMovement: {
      type: Object,
      required: true
    },
    boxMovementSelected: {
      type: Object,
      required: false

    }
  },
  data() {
    return {
      panel: [0],
      titleCard: '',
      validForm: false,
      boxMovementItems: BoxMovementItem.model,
      typeInputOutputBox: typeInputOutputBox,
      rules: { required: required },
      paymentMethods: [],
      isReadonly: false
    };
  },
  computed: {
    l() {
      return this.$locales.pt.boxes.list.modalMovementItems.modalCreateMovementItem;
    },
  },
  watch: {
    boxMovementSelected() {
      this.find();
    },
    typePage () {
      if (this.typePage === typePage.CREATE) {
        this.resetForm();
      } else {
        this.titleCard = this.l.title.view;
        this.isReadonly = true;
      }
    }
  },
  methods: {
    async find() {
      this.boxMovementItems = this.boxMovementSelected;
    },
    async save() {
      if (!this.$refs.validForm.validate()) {
        return false;
      }

      if (this.typePage === typePage.CREATE) {
        this.boxMovementItems = {
          ...this.boxMovementItems,
          box_movement_id: this.boxMovement.id
        };
        this.$api.boxes.createMovementItem(
          this.boxMovement, this.boxMovementItems).then(() => {
          this.$noty.success(this.l.actions.createMovementItem);
          this.$emit('closeDialogCreateBoxMovement');
          this.resetForm();
        }).catch((err) => {
          this.$noty.error(messageErrors(err.response));
        });
      }
    },
    getPaymentMethods() {
      this.$api.allTypes.index({ filters: { type: typeAllTypes.PAYMENT_METHOD }, per_page: 100 }).then((res) => {
        this.paymentMethods = res.data.all_types;
      });
    },
    resetForm() {
      this.boxMovementItems = BoxMovementItem.model;
      this.titleCard = this.l.title.new;
      this.isReadonly = false;
    }
  },
  mounted() {
    this.getPaymentMethods();

    if (this.typePage === typePage.CREATE) {
      this.titleCard = this.l.title.new;
    } else {
      this.titleCard = this.l.title.view;
      this.isReadonly = true;
      this.find();
    }
  }
};
</script>

<style>

</style>
