<template>
  <div>
    <PageHeader :schema="$schemas.financialMovement" />
    <PageContent>
      <Row>
        <Col>
          <Button color="primary" label="Resumo Financeiro" rounded
            @click="openModal({ typeComponent: 'financialSummary' })" />
        </Col>
      </Row>
      <DynamicListPage ref="ListMovements" :schema="$schemas.financialMovement"
      :service="$api.financialMovements" />
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps"
        @close="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { Row, Col, Button, Dialog, } from '@/components/vuetify';
import FinancialSummary from './components/FinancialSummary';

const TYPE_COMPONENT_MODAL = Object.freeze({
  financialSummary: FinancialSummary,
});

export default {
  name: 'ListMovements',
  components: { PageHeader, PageContent, DynamicListPage, Row, Col, Button, Dialog, },
  data () {
    return {
      componentModal: FinancialSummary,
      dialogModal: false,
      modalProps: {},
    };
  },
  methods: {
    openModal({ typeComponent }) {

      this.componentModal = TYPE_COMPONENT_MODAL[typeComponent];
      this.dialogModal = true;
      this.modalProps = {
        maxWidth: 500,
        summary: this.$refs.ListMovements.localItems.summary,
      };
    },
    closeModal() {
      this.dialogModal = false;
    },
  }
};
</script>
