import VariationSchema from './schemas/VariationSchema';
import ListVariations from './pages/ListVariations';
import CreateVariation from './pages/CreateVariation';

export default [
  {
    path: VariationSchema.routes.list.path,
    name: VariationSchema.routes.list.name,
    component: ListVariations,
    meta: VariationSchema.routes.list.meta,
  },
  {
    path: VariationSchema.routes.create.path,
    name: VariationSchema.routes.create.name,
    component: CreateVariation,
    meta: VariationSchema.routes.create.meta
  },
  {
    path: VariationSchema.routes.show.path,
    name: VariationSchema.routes.show.name,
    component: CreateVariation,
    meta: VariationSchema.routes.show.meta
  },
];