var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalCardDefault',{attrs:{"title":'Adicionar Produto'},on:{"close":function($event){return _vm.$emit('close', false)}}},[_c('ExpansionPanel',{attrs:{"title":'Pesquise o Produto',"icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('Row',[_c('Col',{attrs:{"md":"8"}},[_c('TextField',{attrs:{"label":"Pesquise o Produto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('Col',{attrs:{"md":"4"}},[_c('Button',{staticClass:"mr-3",attrs:{"label":'Pesquisar',"rounded":"","color":"secondary"},on:{"click":_vm.searchItem}})],1)],1)],1),_c('ExpansionPanel',{staticClass:"mt-3",attrs:{"title":'Items a serem devolvidos',"icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('Row',[_c('Col',[_c('DataTable',{staticClass:"mt-5",attrs:{"no-search":"","no-sync":"","items":_vm.itemsDevolution,"headers":_vm.headersOrderSaleItems,"show-expand":"","slots":_vm.slotsItems,"item-key":"key"},on:{"update:items":function($event){_vm.itemsDevolution=$event}},scopedSlots:_vm._u([{key:"quantity_devolution",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatCurrency(item.quantity_devolution, { type: 'decimal' })))])]}},{key:"quantity",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatCurrency(item.quantity, { type: 'decimal' })))])]}},{key:"total_value",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatCurrency(item.total_value)))])]}},{key:"content-expanded",fn:function(ref){
var item = ref.props.item;
return [_c('Row',{staticClass:"py-10"},[_c('Col',{attrs:{"md":"3"}},[_c('span',{staticClass:"d-block font-weight-bold subtitle-1",domProps:{"textContent":_vm._s('Valor Uni.:')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.utils.formatCurrency(item.unit_value))}})]),_c('Col',{attrs:{"md":"3"}},[_c('span',{staticClass:"d-block font-weight-bold subtitle-1",domProps:{"textContent":_vm._s('Sub Total:')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.utils.formatCurrency(item.sub_total))}})])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"},attrs:{"slot":"actions"},slot:"actions"},[_c('Button',{staticClass:"mr-3",attrs:{"label":'Inserir',"rounded":"","color":"secondary"},on:{"click":_vm.addItems}})],1)],1),_c('Dialog',{attrs:{"dialog":_vm.dialogItems,"max-width":1150}},[_c('ItemsDataListItemsProductOrderDevolutionItems',{key:_vm.keyComponent,attrs:{"slot":"content","items":_vm.searchOrderItemsDevolution,"itemsDevolutions":_vm.itemsDevolution},on:{"addItemsOrderSale":_vm.addItemsOrderSale,"update:dialogItems":function($event){_vm.dialogItems = $event}},slot:"content"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }