<template>
  <div>
    <Row class="mt-5">
      <Col>
        <TextArea v-model="model.additional_information_info_contributor"
          :placeholder="l.infoContributor.placeholder"
          :label="l.infoContributor.label"
          :rows="4" :outlined="false" />
      </Col>
    </Row>
    <Row class="mt-5">
      <Col>
        <TextArea v-model="model.additional_information_info_tax"
          :placeholder="l.infoTax.placeholder"
          :label="l.infoTax.label"
          :rows="4" :outlined="false" />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Row,
  Col,
  TextArea
} from '@/components/vuetify';
import { required } from '@/utils/rules';

export default {
  name: 'AdditionalInformation',
  components: {
    Row,
    Col,
    TextArea
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      rules: { required: required }
    };
  },
  computed: {
    l () {
      return this.$locales.pt.natureOperations.list.modalStates.modalCreateState.form.additionalInfo;
    },
  }
};
</script>
