<template>
  <Row>
    <Col>
      <h4 class="client-seller">
        Cliente: <span>{{ clientName }}</span>
        Vendedor: <span>{{ sellerName }}</span>
      </h4>
      <div class="pdv-header">
        <h2><b>{{ utils.limitCharacters(description, 45) }}</b></h2>
      </div>
    </Col>
  </Row>
</template>

<script>
import { Row, Col } from '@/components/vuetify';
import { limitCharacters } from '@/utils';

export default {
  name: 'PdvHeader',
  components: { Row, Col },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      utils: {
        limitCharacters
      },
      description: ''
    };
  },
  computed: {
    clientName() {
      return this.model.client.only_document ? this.model.client.document : this.model.client?.name || 'Não Informado';
    },
    sellerName () {
      return this.model.seller?.name || 'Não Informado';
    }
  },
};
</script>

<style lang="scss" scoped>
.client-seller {
  color: #FFF;
  color: #F5DEB3;
  font-weight: bold;

  span {
    display: inline-block;
    min-width: 200px;
    color: #FFF;
    margin-right: 35px;
  }
}

.pdv-header {
  width: 100%;
  padding: 5px;
  background-color: #069;
  border-radius: 10px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  h2 {
    font-size: 45px;
    font-weight: 800;
    text-transform: uppercase;
    min-height: 68px;
  }

  @media only screen and (max-width: 1024px) {
  }

  @media only screen and (max-width: 1280px) {
    h2 {
      font-size: 35px;
      min-height: 45px;
    }
  }
}
</style>
