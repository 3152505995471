<template>
  <div>
    <PageHeader :schema="$schemas.productInvoice">
      <Button :label="'DANFE'" rounded color="secondary" :icon="$icons.printer"
            @click="$htmlToPaper('printMe')" :disabled="disabledDanfe"
            class="mr-2" />
    </PageHeader>
    <PageContent>
      <Header :model="productInvoice" />
      <InfosGenerals ref="infoGenerals" class="mt-4" :model="productInvoice"
        :nature-operations="natureOperations" @setNatureOperation="setNatureOperation" />
      <Client ref="client"  class="mt-10" :model="productInvoice"
        :clients="clients" :nature-operation-selected="natureOperationSelected" />
      <Bills :model="productInvoice" :payment-methods="paymentMethods"
        class="mt-10" ref="bills" />
      <Taxes :model="productInvoice" class="mt-10" />
      <Transporter :model="productInvoice" :transporters="transporters"
        class="mt-10" ref="transporter" />
      <Items :model="productInvoice" class="mt-10" />
      <AdditionalData :model="productInvoice" class="mt-10" />

      <Row class="mt-6">
        <Col md="12">
          <Button :label="l.actions.save" rounded color="primary" @click="save()"
            :disabled="false" :icon="$icons.save" />
          <Button :label="'DANFE'" rounded color="secondary" :icon="$icons.printer"
            @click="$htmlToPaper('printMe')" :disabled="disabledDanfe"
            class="ml-2" />
        </Col>
      </Row>

      <div id="printMe" class="d-none">
        <component :is="danfeComponent" :model="productInvoice" />
      </div>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import {
  Row,
  Col,
  Button,
} from '@/components/vuetify';
import Header from '../components/CreateProductInvoice/Header';
import InfosGenerals from '../components/CreateProductInvoice/InfosGenerals';
import Client from '../components/CreateProductInvoice/Client';
import Bills from '../components/CreateProductInvoice/Bills';
import Taxes from '../components/CreateProductInvoice/Taxes';
import Transporter from '../components/CreateProductInvoice/Transporter';
import Items from '../components/CreateProductInvoice/Items';
import AdditionalData from '../components/CreateProductInvoice/AdditionalData';
import ProductInvoice from '../model/ProductInvoice';
import TypePageMixin from '@/mixins/TypePageMixin';
import { typePage } from '@/utils/enums';
import VerifySumDuplicatesMixin from '../mixins/VerifySumDuplicatesMixin';
import Danfe65 from '@/components/Danfe/Danfe65';
import Danfe55 from '@/components/Danfe/Danfe55';

export default {
  name: 'CreateProductInvoice',
  components: {
    Row,
    Col,
    Button,
    PageHeader,
    PageContent,
    Header,
    InfosGenerals,
    Client,
    Bills,
    Taxes,
    Transporter,
    Items,
    AdditionalData,
    Danfe65,
    Danfe55,
  },
  data () {
    return {
      productInvoice: ProductInvoice.model,
      danfeComponent: Danfe55,
      emitter: {},
      natureOperations: [],
      clients: [],
      transporters: [],
      paymentMethods: [],
      products: [],
      unitMeasures: [],
      stocks: [],
      ncms: [],
      cests: [],
      natureOperationSelected: {},
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form;
    },
    disabledDanfe () {
      return this.productInvoice.status !==
        this.$enums.productInvoiceStatus.AUTHORIZED || false;
    },
  },
  mixins: [TypePageMixin, VerifySumDuplicatesMixin],
  methods: {
    async getEmitter () {
      const user = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}.user`));
      this.$api.companies.show(user.company_id).then(({ company }) => {
        this.productInvoice.emitter = {
          ...company,
          ...company.address
        };
        this.productInvoice.uf_code = company.address.code_state;
        this.productInvoice.municipality_code = company.address.code_city;
      }).catch(() => {
      });
    },
    async find () {
      const { id } = this.$route.params;
      this.$api.productInvoices.show(id).then(({ product_invoice }) => {
        this.productInvoice = product_invoice;
        this.danfeComponent = this.productInvoice.model === '55' ? Danfe55 : Danfe65;
      });
    },
    setNatureOperation (natureOperationId) {
      this.natureOperationSelected = this.natureOperations.find((natureOperation) => {
        return natureOperation.id === natureOperationId;
      });
    },
    async getListSelects () {
      this.$api.productInvoices.getListSelects().then(({ data }) => {
        this.emitter = data.emitter;
        this.natureOperations = data.nature_operations;
        this.clients = data.clients;
        this.transporters = data.transporters;
        this.paymentMethods = data.payment_methods;

        if (this.natureOperations.length === 1) {
          this.natureOperationSelected = this.natureOperations[0];
          this.productInvoice.nature_operation_id = this.natureOperations[0].id;
          this.productInvoice.nature_operation = this.natureOperations[0].name;
        }
      }).catch(() => {
      });
    },
    save () {
      let error = false;

      if (!this.$refs.infoGenerals.$refs.formInfoGenerals.validate()) {
        this.$noty.error('Preencha os Gerais');
        error = true;
      }

      if (!this.$refs.client.$refs.formClient.validate()) {
        this.$noty.error('Preencha os dados do Cliente');
        error = true;
      }

      if (!this.$refs.bills.$refs.formBill.validate()) {
        this.$noty.error('Preencha os dados da Fatura');
        error = true;
      }

      if (!this.$refs.transporter.$refs.formTransporter.validate()) {
        this.$noty.error('Preencha os dados da Transportadora');
        error = true;
      }

      if (this.productInvoice.items.length === 0) {
        this.$noty.error('Insira pelo menos 1 item na NFe');
        error = true;
      }

      if (error) {
        return false;
      }

      if (this.verifyTotalDuplicates(this.productInvoice.bill.duplicates,
        this.productInvoice.total.nf_value, 0)) {
        return false;
      }

      if (this.typePage === typePage.CREATE) {
        this.$api.productInvoices.create(this.productInvoice).then(() => {
          this.$router.push({ name: this.$schemas.productInvoice.routes.list.name });
        });
      } else {
        const { id } = this.$route.params;
        this.$api.productInvoices.update(id, this.productInvoice).then(() => {
          this.$noty.success('NFe Atualizada com sucesso.');
          this.find();
        }).catch((err) => {
          this.$noty.error(err);
        });
      }
    }
  },
  async mounted () {
    await this.getEmitter();
    await this.getListSelects();

    if (this.typePage === typePage.SHOW) {
      await this.find();
    }
  }
};
</script>

<style lang="scss">
.border-all {
  border: 1px solid #999 !important;
  border-radius: 0 !important;
}

.border-all-row {
  border: 1px solid #999 !important;
  border-radius: 0 !important;

  p {
    margin: 0px !important;
    padding: 0px !important;
  }
}

.border-right-col {
  border-right: 1px solid #999 !important;
}

.border-left-col {
  border-left: 1px solid #999 !important;
}

.border-bottom-col {
  border-bottom: 1px solid #999 !important;
}

.border-x-col {
  border-left: 1px solid #999 !important;
  border-right: 1px solid #999 !important;
}

.border-y-col {
  border-top: 1px solid #999 !important;
  border-bottom: 1px solid #999 !important;
}

.no-border-row-top {
  border-top: none !important;
}

.no-border-row-left {
  border-left: none !important;
}

.title-section {
  margin-left: -12px !important;
  margin-top: 25px !important;
}

.checkbox-custom {
  margin: 0 !important;
  padding: 0 !important;
  height: 22px;
}

.select-custom {
  margin-top: -7px !important;
}

.margin-top-custom-1 {
  margin-top: -8px;
}

.margin-top-custom-2 {
  margin-top: -10px;
}

.margin-top-custom-3 {
  margin-top: -12px;
}
</style>
