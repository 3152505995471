import { statusCheckControl } from '@/utils/enums';
import { formatCurrency } from '@/utils';

export default {
  beforeList: ({ form }) => {
    form.credit_card_controls.forEach((credit_card_control) => {
      credit_card_control.destroyDisabled = credit_card_control.status === statusCheckControl.PAID;
    });

    const calculateValues = (items, status) => {
      let total = 0;
      items.forEach((item) => {
        total += item.parcels.reduce((acc, item) => {
          if (item.status === status) {
            acc += item.value;
          }

          return acc;
        }, 0);
      });

      return total;
    };

    form.total_transactions = formatCurrency(form.credit_card_controls.length, { type: 'decimal' });
    form.value_total_transactions_available = formatCurrency(calculateValues(
      form.credit_card_controls, statusCheckControl.PENDING));
    form.value_total_transactions_receivable = formatCurrency(calculateValues(
      form.credit_card_controls, statusCheckControl.PAID));
  },
};
