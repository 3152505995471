import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('natureOperation', {
    name: 'Venda Estadual',
    type: 'sales',
    movement_stock: true,
    ...baseFields,
  });
  server.create('natureOperation', {
    name: 'Compra Estadual',
    type: 'purchases',
    movement_stock: false,
    ...baseFields,
  });
};
