<template>
  <div>
    <ModalCardDefault :title="title" @close="$emit('update:dialogModal', false)">
      <ExpansionPanel v-model="panel" :title="'Selecione as informações do(s) Produto(s)'"
        :icon="$icons.box" readonly>
        <Row>
          <Col>
            <Select v-model="form.category_ids" label="Selecione uma Categoria"
              placeholder="Categorias" :items="categories" multiple
              item-text="description" item-value="id" clearable
              @change="changeSelect" />
          </Col>
          <Col>
            <Select v-model="form.sub_category_ids" label="Selecione uma Sub Categoria"
              placeholder="Sub Categorias" :items="subCategories" multiple
              item-text="description" item-value="id" clearable
              @change="changeSelect" />
          </Col>
          <Col>
            <AutoComplete v-model="form.product_ids" placeholder="Produtos"
              label="Selecione um Produto" domain="product" resultDomain="products"
              :search-by="['name', 'reference', 'code', 'bar_code']"
              item-text-data-serve="name" item-value-data-serve="id"
              title="Pesquise por: Nome, Referência, Código e Código de Barras"
              multiple clearable
              @change="changeAutoComplete($event)" />
          </Col>
        </Row>
      </ExpansionPanel>

      <div slot="actions" class="d-flex justify-end" style="width: 100%">
        <Button label="Confirmar" class="mr-3" rounded color="secondary"
          :disabled="disabledAction" @click="openDialogConfirmation()" />
      </div>
    </ModalCardDefault>

    <DialogConfirmation :dialog="dialogConfirmation"
      :loading="loadingDialogConfirmation" title="Adicionar Produto(s)"
      message="Deseja realmente adicionar o(s) Produto(s)?"
      @yesActionDialogConfirmation="yesActionConfirmation"
      @noActionDialogConfirmation="dialogConfirmation = false" />
  </div>
</template>

<script>
import {
  ExpansionPanel,
  Select,
  Row,
  Col,
  AutoComplete,
  Button,
} from '@/components/vuetify';
import ModalCardDefault from '@/components/ModalCardDefault';
import DialogConfirmation from '@/components/DialogConfirmation';

export default {
  name: 'AddProduct',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    DialogConfirmation,
    Select,
    Row,
    Col,
    AutoComplete,
    Button,
  },
  props: {
    priceList: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      form: {
        category_ids: [],
        sub_category_ids: [],
        product_ids: [],
      },
      dialogConfirmation: false,
      loadingDialogConfirmation: false,
      categories: [],
      subCategories: []
    };
  },
  computed: {
    l () {
      return this.$locales.pt.variations.list.dialogVariationValues;
    },
    title () {
      return 'Adicionar Novo(s) Produto(s)';
    },
    disabledAction() {
      return this.form.category_ids.length === 0 && this.form.sub_category_ids.length === 0
        && this.form.product_ids.length === 0;
    }
  },
  methods: {
    getAllTypes () {
      this.$api.allTypes.filters({ url: 'filters?domain=all_type&filter[type]=category,sub-category&page=1&per_page=-1' }).then(({ data: { all_types } }) => {
        all_types.forEach((item) => {
          item.type === this.$enums.typeAllTypes.CATEGORY ? this.categories.push(item) : this.subCategories.push(item);
        });
      });
    },
    changeSelect() {
      this.form.product_ids = [];
    },
    changeAutoComplete(item) {
      this.form.category_ids = [];
      this.form.sub_category_ids = [];
      this.form.product_ids = item;
    },
    openDialogConfirmation() {
      if (this.form.category_ids.length > 0 || this.form.sub_category_ids.length > 0
        || this.form.product_ids.length > 0) {
        this.dialogConfirmation = true;
      }
    },
    yesActionConfirmation () {
      this.loadingDialogConfirmation = true;
      this.$api.priceLists.createPriceListProductVariationBatch(
        this.priceList.id, this.form).then(() => {
        this.$noty.success('Registro(s) Realizado(s) com Sucesso');
      }).catch(() => {
        this.$noty.error('Erro ao Cadastrar o(s) Produtos(s)');
      }).finally(() => {
        this.loadingDialogConfirmation = false;
        this.dialogConfirmation = false;
      });
    },
  },
  async mounted () {
    this.getAllTypes();
  },
};
</script>

<style>

</style>
