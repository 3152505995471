<template>
  <v-skeleton-loader
    v-bind="$attrs"
    :type="types"
    :loading="true">
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    types: {
      type: String,
      required: true
    }
  }
};
</script>

<style>

</style>
