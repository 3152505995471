<script>
import { kebabCase } from 'lodash';

export default {
  computed: {
    isDevolution () {
      return kebabCase(this.$route.meta.type) === this.$enums.orderTypes.ORDER_SALE_DEVOLUTION ||
        kebabCase(this.$route.meta.type) === this.$enums.orderTypes.ORDER_PURCHASE_DEVOLUTION;
    }
  },
};
</script>
