import { getTypeUser } from '@/utils';
import { TYPE_USER } from '@/utils/enums';

const typeUser = getTypeUser();
let items = [{ icon: 'chart', title: 'Dashboard', route: 'home', active: false }];

if (typeUser === TYPE_USER.DISTRIBUTOR) {
  items.push({ icon: 'accountTie', title: 'Meus Revendedores', route: 'resellers', active: false });
}

if (typeUser === TYPE_USER.RESELLER) {
  items.push({ icon: 'accountTie', title: 'Meus Clientes', route: 'settings-customers', active: false });
}

export const menuItemsHome = [
  {
    active: false,
    menuGroupName: 'home',
    icon: 'home',
    title: 'Início',
    items,
  },
];
