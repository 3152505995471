<template>
  <div>
    <h4 class="title-section" v-text="l.title" />
    <v-form v-model="validClient" ref="formClient" lazy-validation>
      <Row class="mt-0 border-all-row">
        <Col md="6" class="py-0">
          <p class="caption mt-2" v-text="l.name" />
          <p v-if="isAuthorized" v-text="model.client.name" />
          <Select v-else v-model="clientId" :items="clients"
            :outlined="false" @change="changeClient" item-value="id"
            item-text="name" :rules="[rules.required]"
            :readonly="changeClientReadonly" class="margin-top-custom-1" />
        </Col>
        <Col md="4" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.cnpj" />
          <p v-if="isAuthorized" v-text="model.client.document_formatted " />
          <TextFieldSimpleMask v-else v-model="model.client.document" :outlined="false"
            :input-mask="getMaskDocument('input', model.client.person_type)"
            :output-mask="getMaskDocument('output', model.client.person_type)"
            :rules="[rules.required]" class="margin-top-custom-3" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.emissionDate" />
          <p v-if="isAuthorized" v-text="model.emission_date_formatted" />
          <DataPicker v-else v-model="model.emission_date" :outlined="false"
            :rules="[rules.required]" class="margin-top-custom-2" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.address" />
          <p v-if="isAuthorized" v-text="model.client.street" />
          <TextField v-else v-model="model.client.street" :outlined="false"
            :rules="[rules.required]" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.number" />
          <p v-if="isAuthorized" v-text="model.client.number" />
          <TextField v-else v-model="model.client.number" :outlined="false"
            class="margin-top-custom-1"  />
        </Col>
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.neighborhood" />
          <p v-if="isAuthorized" v-text="model.client.neighborhood" />
          <TextField v-else v-model="model.client.neighborhood" :outlined="false"
            :rules="[rules.required]" class="margin-top-custom-1"  />
        </Col>
        <Col class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.zipCode" />
          <p v-if="isAuthorized" v-text="model.client.zip_code_formatted" />
          <TextFieldSimpleMask v-else v-model="model.client.zip_code" :outlined="false"
            :input-mask="masks.zipCode" :rules="[rules.required]" output-mask="########"
            class="margin-top-custom-3"  />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.emissionHour" />
          <p v-if="isAuthorized" v-text="model.input_output_emission_hour" />
          <TextFieldSimpleMask v-else v-model="model.input_output_emission_hour" :outlined="false"
            :input-mask="masks.hour" :rules="[rules.required]" :output-mask="masks.hour"
            class="margin-top-custom-2"  />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="3" class="py-0">
          <p class="caption mt-2" v-text="l.city" />
          <p v-if="isAuthorized" v-text="model.client.city" />
          <TextField v-else v-model="model.client.city" :rules="[rules.required]"
            :outlined="false" class="margin-top-custom-1"  />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.phone" />
          <p v-if="isAuthorized" v-text="model.client.phone_formatted" />
          <TextFieldSimpleMask v-else v-model="model.client.phone" :outlined="false"
            :input-mask="masks.cell_phone" :rules="[]"
            output-mask="###########" class="margin-top-custom-3"  />
        </Col>
        <Col md="1" class="py-0">
          <p class="caption mt-2" v-text="l.uf" />
          <p v-if="isAuthorized" v-text="model.client.uf" />
          <Select v-else v-model="model.client.uf" :rules="[rules.required]"
            :items="options.UF" :outlined="false" class="margin-top-custom-1" />
        </Col>
        <Col md="2" class="py-0 border-x-col">
          <p class="caption mt-2" v-text="l.ie" />
          <p v-if="isAuthorized" v-text="model.client.rg_ie" />
          <TextField v-else v-model="model.client.rg_ie" :outlined="false"
            class="margin-top-custom-1"  />
        </Col>
        <Col md="2" class="py-0 border-right-col">
          <p class="caption mt-2" v-text="l.finalConsumer" />
          <p v-if="isAuthorized" v-text="model.final_consumer ? 'Sim' : 'Não'" />
          <CheckBox v-else v-model="model.final_consumer"
            @setCheckBox="setFinalConsumer" class="my-0 py-0" style="height: 22px"
            :readonly="isConsumerFinalReadonly" />
        </Col>
        <Col md="2" class="py-0">
          <p class="caption mt-2" v-text="l.inputOutputDate" />
          <p v-if="isAuthorized" v-text="model.receipt_date_formatted" />
          <DataPicker v-else v-model="model.receipt_date" :rules="[rules.required]"
          :outlined="false" class="margin-top-custom-2" />
        </Col>
      </Row>
      <Row class="border-all-row no-border-row-top">
        <Col md="5" class="py-0 d-flex flex-column justify-center">
          <p class="caption mt-2" v-text="l.typeTaxPayer" />
          <p v-if="isAuthorized" v-text="model.client.taxpayer_type_formatted" />
          <Select v-else v-model="model.client.taxpayer_type" :items="options.taxPayer"
            :outlined="false" :rules="[rules.required]" class="margin-top-custom-1" />
        </Col>
        <Col md="3" class="py-0 d-flex flex-column justify-center border-x-col">
          <p class="caption mt-2" v-text="l.presenceIndicator" />
          <p v-if="isAuthorized" v-text="model.presence_indicator_formatted" />
          <Select v-else v-model="model.presence_indicator" :items="options.presenceIndicator"
            :outlined="false" class="margin-top-custom-1" :rules="[rules.required]" />
        </Col>
        <Col md="2" class="py-0 d-flex flex-column justify-center border-right-col">
          <p class="caption mt-2" v-text="l.intermediaryIndicator" />
          <p v-if="isAuthorized" v-text="model.intermediary_indicator_formatted" />
          <Select v-else v-model="model.intermediary_indicator" :items="options.intermediaryIndicator"
            :outlined="false" class="margin-top-custom-1" :rules="[rules.required]" />
        </Col>
        <Col md="2" class="py-0 d-flex flex-column justify-center">
          <p class="caption mt-2" v-text="l.inputOutputHour" />
          <p v-if="isAuthorized" v-text="model.input_output_receipt_hour" />
          <TextFieldSimpleMask v-else v-model="model.input_output_receipt_hour" :outlined="false"
            :input-mask="masks.hour" :output-mask="masks.hour"
            :rules="[rules.required]" class="margin-top-custom-2" />
        </Col>
      </Row>
    </v-form>
  </div>
</template>

<script>
import {
  Row,
  Col,
  CheckBox,
  Select,
  TextField,
  TextFieldSimpleMask,
  DataPicker
} from '@/components/vuetify';
import { taxPayer, presenceIndicator, intermediaryIndicator, UF } from '@/utils/options';
import CreateUpdateProductInvoiceMixin from '../../mixins/CreateUpdateProductInvoiceMixin';
import { personType } from '@/utils/enums';
import TypePageMixin from '@/mixins/TypePageMixin';

export default {
  name: 'Client',
  components: {
    Row,
    Col,
    CheckBox,
    Select,
    TextField,
    TextFieldSimpleMask,
    DataPicker,
  },
  props: {
    clients: {
      type: Array,
      required: true
    },
    natureOperationSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validClient: false,
      options: {
        taxPayer,
        presenceIndicator,
        intermediaryIndicator,
        UF
      },
      clientId: '',
    };
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.client;
    },
    isConsumerFinalReadonly () {
      return this.model.client?.taxpayer_type === this.$enums.taxPayer.NON_TAXPAYER;
    },
    changeClientReadonly () {
      return this.typePage === this.$enums.typePage.SHOW;
    }
  },
  watch: {
    'model.client.client_id' () {
      this.clientId = this.model.client.client_id;
    }
  },
  mixins: [CreateUpdateProductInvoiceMixin, TypePageMixin],
  methods: {
    setFinalConsumer (value) {
      if (this.model.client?.taxpayer_type === this.$enums.taxPayer.NON_TAXPAYER) {
        this.model.final_consumer = true;
        this.isConsumerFinalReadonly = true;

        return;
      }

      this.isConsumerFinalReadonly = false;
      value ? this.model.final_consumer = true : this.model.final_consumer = false;
    },
    changeClient () {
      const client = this.clients.find((client) => {
        return client.id === this.clientId;
      });
      const taxes = this.natureOperationSelected.states.find((state) => {
        return state.code_state == client.address.code_state;
      });

      if (!taxes) {
        this.$noty.error('Não existe configuração de impostos para o estado do cliente selecionado.');
        this.clientId = null;
        this.$refs.formClient.reset();

        return;
      }

      client.person_type === personType.PHYSICAL ?
        client.document = client.cpf : client.document = client.cnpj;
      this.model.client = {
        ...client,
        ...client.address,
        client_id: client.id,
        phone: client.cell_phone || client.phone
      };
      this.model.client_id = client.id;
      this.model.destination_location_identifier = taxes.destiny_location;
      this.setFinalConsumer(this.model.client?.taxpayer_type === this.$enums.taxPayer.NON_TAXPAYER);
    }
  },
};
</script>
