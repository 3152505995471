var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalCardDefault',{attrs:{"title":_vm.l.title},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('Button',{staticClass:"btn-actions mr-3",attrs:{"label":_vm.l.actions.create,"icon":_vm.$icons.plus,"color":"secondary","rounded":""},on:{"click":function($event){return _vm.openCreateBankMovement(_vm.typePage.CREATE)}}})]},proxy:true}])},[_c('ExpansionPanel',{attrs:{"title":'Extrato',"icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('DataTable',{attrs:{"no-search":"","items-per-page":6,"items":_vm.movements,"slots":_vm.slotItems,"headers":this.schema.movements.list.headers,"disable-sort":"","loading":_vm.loadingTable},on:{"getItems":function($event){return _vm.$emit('listMovements', $event)}},scopedSlots:_vm._u([{key:"movement_type_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('Chip',{attrs:{"label":item.movement_type_formatted,"small":"","color":_vm.getColorChip(item.movement_type)}})]}},{key:"value_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{class:['font-weight-bold', {'success--text': item.movement_type === _vm.$enums.typeStockMovements.INPUT,
            'red--text': item.movement_type === _vm.$enums.typeStockMovements.OUTPUT}]},[_vm._v(" "+_vm._s(item.value_formatted)+" ")])]}},{key:"actions",fn:function(ref){
            var props = ref.props;
return [_c('ButtonIcon',{staticClass:"mr-3",attrs:{"color":"secondary","x-small":"","icon":_vm.$icons.view,"tooltip":_vm.l.tooltip.view},on:{"click":function($event){return _vm.openShowBankMovement(props.item, _vm.typePage.SHOW)}}})]}}])}),_c('Row',[_c('Col',[_c('Alert',{attrs:{"color":"primary"}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Banco")]),_c('h4',{domProps:{"textContent":_vm._s(this.bank.description)}})])],1),_c('Col',[_c('Alert',{attrs:{"color":"primary"}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Agência")]),_c('h4',{domProps:{"textContent":_vm._s(this.bank.agency)}})])],1),_c('Col',[_c('Alert',{attrs:{"color":"primary"}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Conta")]),_c('h4',{domProps:{"textContent":_vm._s(this.bank.account)}})])],1),_c('Col',[_c('Alert',{attrs:{"color":"primary"}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Saldo")]),_c('h4',{domProps:{"textContent":_vm._s(this.bank.balance_formatted)}})])],1)],1)],1)],1),_c('Dialog',{attrs:{"dialog":_vm.dialogCreateBankMovement,"no-title":"","maxWidth":1250}},[_c('CreateBankMovement',{attrs:{"slot":"content","type-page":_vm.typePageSelected,"bank-movement-selected":_vm.bankMovementSelected,"bank-id":this.bank.id,"title":_vm.l.title},on:{"closeDialogCreateBankMovement":_vm.closeDialogCreateBankMovement,"close":_vm.closeDialogCreateBankMovement},slot:"content"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }