export default {
  title: 'Informações da Venda',
  form: {
    client: {
      label: 'Cliente',
      placeholder: 'Cliente',
    },
    seller: {
      label: 'Vendedor',
      placeholder: 'Vendedor',
    },
    provider: {
      label: 'Fornecedor',
      placeholder: 'Fornecedor',
    },
    employee: {
      label: 'Funcionário',
      placeholder: 'Funcionário',
    },
    orderNumber: {
      label: 'N° Pedido',
      placeholder: 'N° Pedido',
    },
    paymentMethod: {
      label: 'Forma de Pagamento',
      placeholder: 'Forma de Pagamento',
    },
    presenceIndicator: {
      label: 'Indicação de Presença',
      placeholder: 'Indicação de Presença',
    },
    solicitationNumber: {
      label: 'N° da Ordem',
      placeholder: 'N° da Ordem',
    },
    typeInstallment: {
      label: 'Tipo de Parcelamento',
      placeholder: 'Tipo de Parcelamento',
    },
    stock: {
      label: 'Estoque',
      placeholder: 'Estoque',
    },
    priceList: {
      label: 'Lista de Preços',
      placeholder: 'Lista de Preços',
    },
    invoiceNumber: {
      label: 'N° da NF-e',
      placeholder: 'N° da NF-e',
    },
    emissionDate: {
      label: 'Date de Emissão',
      placeholder: 'Date de Emissão',
    },
    previsionDate: {
      label: 'Data de Previsão',
      placeholder: 'Data de Previsão',
    },
    deliveryDate: {
      label: 'Data de Entrega',
      placeholder: 'Data de Entrega',
    },
    natureOperation: {
      label: 'Natureza de Operação',
      placeholder: 'Natureza de Operação',
    },
    finalConsumer: {
      label: 'Consumidor Final',
      placeholder: 'Consumidor Final',
    },
    wholesale: {
      label: 'Tabela de Atacado',
      placeholder: 'Tabela de Atacado',
    }
  },
};
