var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalCardDefault',{attrs:{"title":'Adicionar Produto'},on:{"close":function($event){return _vm.$emit('update:dialogItems', false)}}},[_c('ExpansionPanel',{attrs:{"title":'Informações do Produto',"icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('Row',[_c('Col',[_c('DataTable',{staticClass:"mt-5",attrs:{"no-search":"","no-sync":"","items":_vm.itemsTable,"headers":_vm.headersOrderSaleItems,"slots":_vm.slotsItems,"show-expand":""},on:{"update:items":function($event){_vm.itemsTable=$event}},scopedSlots:_vm._u([{key:"qtd_devolution",fn:function(ref){
var ref_props = ref.props;
var index = ref_props.index;
var item = ref_props.item;
return [_c('TextFieldPercent',{staticClass:"item-input",attrs:{"outlined":false,"readonly":_vm.setReadonlyQtdDevolution(item)},model:{value:(_vm.items[index].qtd_devolution),callback:function ($$v) {_vm.$set(_vm.items[index], "qtd_devolution", $$v)},expression:"items[index].qtd_devolution"}})]}},{key:"qtd_returned",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatCurrency(item.qtd_returned, { type: 'decimal' })))])]}},{key:"quantity",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatCurrency(item.quantity, { type: 'decimal' })))])]}},{key:"created_at",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatDatePT(item.created_at)))])]}},{key:"total_value",fn:function(ref){
var item = ref.props.item;
return [_c('span',[_vm._v(_vm._s(_vm.utils.formatCurrency(item.total_value)))])]}},{key:"content-expanded",fn:function(ref){
var item = ref.props.item;
return [_c('Row',{staticClass:"py-10"},[_c('Col',[_c('span',{staticClass:"d-block font-weight-bold subtitle-1",domProps:{"textContent":_vm._s('Código do Produto:')}}),_c('span',{domProps:{"textContent":_vm._s(item.code)}})]),_c('Col',[_c('span',{staticClass:"d-block font-weight-bold subtitle-1",domProps:{"textContent":_vm._s('Valor Uni.:')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.utils.formatCurrency(item.unit_value))}})]),_c('Col',[_c('span',{staticClass:"d-block font-weight-bold subtitle-1",domProps:{"textContent":_vm._s('Sub Total:')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.utils.formatCurrency(item.sub_total))}})])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"},attrs:{"slot":"actions"},slot:"actions"},[_c('Button',{staticClass:"mr-3",attrs:{"label":'Inserir',"rounded":"","color":"secondary"},on:{"click":_vm.addItems}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }