<template>
  <v-tabs v-model="model">
    <slot />
  </v-tabs>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tab: {
      type: Number
    }
  },
  data () {
    return {
      model: this.$attrs.value
    };
  },
  watch: {
    model () {
      this.$emit('setTab', this.model);
    }
  }
};
</script>
