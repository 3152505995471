import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('company', {
    name: 'MGE Tech',
    email: 'contato@mgetech.com.br',
    cell_phone: '84988481941',
    ...baseFields,
  });
  server.create('company', {
    name: 'Alpha Code',
    email: 'contato@alphacode.com.br',
    cell_phone: '84988589885',
    ...baseFields,
  });
};
