<template>
  <ModalCardDefault :title="l.title" @close="$emit('close')">
    <ExpansionPanel v-model="panel" :title="'Lista'" :icon="$icons.box" readonly>
      <DataTable no-search no-sync hide-default-footer :items="productVariationStocks"
        :headers="this.schema.variations.list.headersViewProductVariations" :loading="loadingDataTable">
      </DataTable>
    </ExpansionPanel>
  </ModalCardDefault>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  DataTable,
  ExpansionPanel
} from '@/components/vuetify';

export default {
  name: 'ListVariationStocks',
  components: {
    DataTable,
    ExpansionPanel,
    ModalCardDefault
  },
  props: {
    productVariationId: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      panel: [0],
      loadingDataTable: false,
      productVariationStocks: [],
    };
  },
  computed: {
    schema () {
      return this.$schemas.product;
    },
    l () {
      return this.$locales.pt.products.list.modalVariations.viewVariationStocks;
    },
  },
  watch: {
    productVariationId: {
      handler () {
        this.getProductVariationStocks();
      },
      deep: true,
    },
  },
  methods: {
    getProductVariationStocks () {
      this.loadingDataTable = !this.loadingDataTable;
      this.$api.products.getProductVariationStocks(this.productVariationId).then((res) => {
        this.productVariationStocks = res;
      }).catch(() => {
        this.$noty.error(this.l.noty.errorGetProductVariationStocks);
      }).finally(() => {
        this.loadingDataTable = !this.loadingDataTable;
      });
    },
  },
  mounted () {
    this.getProductVariationStocks();
  }
};
</script>

<style>

</style>
