<template>
  <div>
    <v-row>
      <v-col v-if="!noSearch">
        <TextField
          ref="searchDataTable"
          refs="inputDataTable"
          v-model="search"
          label='pesquisar'
          class="content-appointments--boddy---right----customers-----search-customer" />
      </v-col>
      <v-col>
        <slot name="custom-header" />
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :class="[{'row-pointer': singleSelect}]"
      v-if="noSync"
      :dense="dense"
      :item-key="itemKey"
      :loading="loading"
      :search="search"
      :headers="headers"
      :items="localItems"
      :single-select="singleSelect"
      :show-select="showSelect"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
      :items-per-page="itemsPerPage"
      :show-expand="showExpand"
      :footer-props="{ 'items-per-page-options': [5, 7, 10, 20, 50]}"
      @click:row="clickRow"
      @input="handleAction">

      <template v-for="slot in slotsHeaders" v-slot:[`header.${slot.field}`]="header">
        <slot :name="`${slot.field}Header`" v-bind="header" />
      </template>

      <template v-for="(slot) in slots" v-slot:[`item.${slot.field}`]="{ item, index }">
        <slot :name="slot.field" :props="{ item, index }" />
      </template>

      <template v-slot:[`item.actions`]="props" style="width: 200px">
        <slot name="actions" :props="props" />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="px-1 py-1">
            <slot name="content-expanded" :props="{ headers, item }" class="px-5 py-5" />
          </div>
        </td>
      </template>
    </v-data-table>

    <v-data-table
      v-model="selected"
      v-else
      :class="['data-table', {'row-pointer': singleSelect}]"
      :dense="dense"
      :item-key="itemKey"
      :loading="loading"
      :search="search"
      :headers="headers"
      :items="localItems"
      :options.sync="options"
      :server-items-length="totalLocalItems"
      :single-select="singleSelect"
      :show-select="showSelect"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
      :items-per-page="itemsPerPage"
      :show-expand="showExpand"
      :disable-sort="disableSort"
      :footer-props="{ 'items-per-page-options': [5, 7, 10, 20, 50]}"
      @click:row="clickRow"
      @input="handleAction">

      <template v-for="(slot) in slotsHeaders" v-slot:[`header.${slot.field}`]="{ header }">
        <slot :name="`${slot.field}Header`" v-bind="header" />
      </template>

      <template v-for="(slot) in slots" v-slot:[`item.${slot.field}`]="{ item, index }">
        <slot :name="slot.field" :props="{ item, index }" />
      </template>

      <template v-slot:[`item.actions`]="props" style="width: 200px">
        <slot name="actions" :props="props" />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="px-1 py-1">
            <slot name="content-expanded" :props="{ headers, item }" class="px-5 py-5" />
          </div>
        </td>
      </template>
    </v-data-table>

    <v-row>
      <v-col>
        <slot name="custom-footer" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import TextField from './TextField';

export default {
  name: 'DataTable',
  components: { TextField },
  props: {
    noSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noSync: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: [Object, Array],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: () => []
    },
    slotsHeaders: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: () => 10
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    keyData: {
      type: String,
      default: 'data',
    },
  },
  data() {
    return {
      search: '',
      selected: [],
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
    search: _.debounce(function() {
      this.options.page = 1;
      this. getItems();
    }, 700),
    selected: {
      handler() {
        this.$emit('setSelected', this.selected);
      },
      deep: true,
    }
  },
  computed: {
    localItems: {
      get() {
        return this.noSync ? this.items : this.items[this.keyData];
      },
      set(value) {
        this.localItems = value;
      }
    },
    totalLocalItems: {
      get() {
        return this.items.total;
      },
      set(value) {
        this.items = value;
      }
    },
  },
  methods: {
    getItems() {
      this.$emit('getItems', {
        ...this.options,
        per_page: this.options.itemsPerPage,
        filter: this.search,
        search_global: true
      });
    },
    handleAction() {
      this.$emit('selected', this.selected);
    },
    clickRow(data) {
      if (this.singleSelect) {
        this.selected.length && data.id === this.selected[0].id ? this.selected = [] :  this.selected = [data];
      } else {
        const filter = this.selected.find((item) => {
          return item.id === data.id;
        });

        if (!filter) {
          this.selected.push(data);
        } else {
          this.selected.forEach((item, index) => {
            if (item.id === data.id) {
              this.selected.splice(index, 1);
            }
          });
        }
      }
      this.$emit('selected', this.selected);
    },
  },
  mounted () {}
};
</script>

<style lang="scss" scoped>
::v-deep {
  ::-webkit-scrollbar {
    width: 24px;
    height: 8px;
    border-radius: 20px;
    background-color: #ee9ea9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #FF3D57;
    border-radius: 20px;
  }

  .row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
    cursor: pointer;
  }
}
</style>
