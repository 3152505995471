import CreditCardAdministratorSchema from './schemas/CreditCardAdministratorSchema';
import ListCreditCardAdministrators from './pages/ListCreditCardAdministrators';
import CreateCreditCardAdministrator from './pages/CreateCreditCardAdministrator';

export default [
  {
    path: CreditCardAdministratorSchema.routes.list.path,
    name: CreditCardAdministratorSchema.routes.list.name,
    component: ListCreditCardAdministrators,
    meta: CreditCardAdministratorSchema.routes.list.meta,
  },
  {
    path: CreditCardAdministratorSchema.routes.create.path,
    name: CreditCardAdministratorSchema.routes.create.name,
    component: CreateCreditCardAdministrator,
    meta: CreditCardAdministratorSchema.routes.create.meta
  },
  {
    path: CreditCardAdministratorSchema.routes.show.path,
    name: CreditCardAdministratorSchema.routes.show.name,
    component: CreateCreditCardAdministrator,
    meta: CreditCardAdministratorSchema.routes.show.meta
  },
];
