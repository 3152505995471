import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import ProductSchema from '../schemas/ProductSchema';
import { formatCurrency, formatProductVariations, getText } from '@/utils';
import { typeHasVariation } from '@/utils/options';
import { typePage } from '@/utils/enums';

const formatResponse = (item, type = typePage.LIST) => {
  const formatShow = (item) => {
    let priceListIds = [];
    item.product.product_variations.forEach((item) => {
      priceListIds = item.price_list_product_variations.map(i => i.price_list_id);
    });
    item.product.price_list_ids = priceListIds;
  };

  const formatList = (item) => {
    item.price_formatted = formatCurrency(item.price);
    item.price_wholesale_formatted = formatCurrency(item.price_wholesale);
    item.cost_formatted = formatCurrency(item.cost);
    item.quantity_formatted = formatCurrency(item.quantity, { type: 'decimal' });
    item.has_variation_formatted = getText(typeHasVariation, item.has_variation);
  };

  type === typePage.LIST ? formatList(item) : formatShow(item);
};

const formatResponseGetAllProductVariations = ({ data: { product_variations } }) => {
  return formatProductVariations(product_variations);
};

const formatResponseGetAllProductVariationsByProductId = ({ data: { product_variations } }) => {
  return product_variations.map((item) => {
    return {
      id: item.product_variation_id,
      code: item.code,
      bar_code: item.bar_code,
      price: item.price,
      price_formatted: formatCurrency(item.price),
      price_wholesale: item.price_wholesale,
      price_wholesale_formatted: formatCurrency(item.price_wholesale),
      quantity_formatted: formatCurrency(item.quantity, { type: 'decimal' }),
      name: `${item.name}:<br><small class="red--text">${item.description_variations}</small>`,
      has_variation: item.has_variation,
      variation_ids: item.variation_ids.split(','),
      variation_value_ids: item.variation_values_ids.split(','),
    };
  });
};

const formatResponseGetProductVariationStocks = ({ data: { product_variation_stocks } }) => {
  return product_variation_stocks.map((item) => {
    return {
      description: item.stock.name,
      quantity: formatCurrency(item.quantity, { type: 'decimal' }),
    };
  });
};

const ProductsService = DynamicService('products', ProductSchema, { formatResponse, removeNullable: false });

const ProductsCommands = () => ({
  async getAllProductVariations() {
    return formatResponseGetAllProductVariations(await axios.get('/product_variations'));
  },
  async getPriceByPriceListIdProductVariation(params) {
    return await axios.post('/product_variations/get-price', params);
  },
  async getByBarCodeOrCodeProductVariation(barCode) {
    return formatResponseGetAllProductVariations(await axios.get(`/filters?domain=product_variation&search_global=true&filter[bar_code]=${barCode}&filter[code]=${barCode}&condition=or`));
  },
  async createProductVariation(payload) {
    return await axios.post('/product_variations', payload);
  },
  async deleteProductVariation(productVariationId) {
    return await axios.delete(`/product_variations/${productVariationId}`);
  },
  async getProductVariationStocks(productVariationId) {
    return formatResponseGetProductVariationStocks(await axios.post(`/product_variations/${productVariationId}/stocks`));
  },
  async getAllProductVariationsByProductId(productId, filters, pagination) {
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page : 1;

    function arrayToQueryParams(array) {
      let params = '';
      array.forEach((item, index) => {
        const keys = Object.keys(item);
        keys.forEach((key, innerIndex) => {
          if (key === 'id') {
            params += `${index === 0 && innerIndex === 0 ? '' : '&'}filter[]=${encodeURIComponent(item[key])}`;
          }
        });
      });

      return params;
    }

    const queryParams = arrayToQueryParams(filters);
    const result = await axios.get(`products/${productId}/product_variations?per_page=${itemsPerPage || 5}&page=${page || 1}&${queryParams}`);

    return {
      ...result.data,
      data: formatResponseGetAllProductVariationsByProductId(result)
    };
  },
  async getProductVariationByBarCode(barCode) {
    return await axios.get(`filters?domain=product_variation&search_global=true&filter[bar_code]=${barCode}`);
  },
  async getProductVariationById(id) {
    const { data:  { product_variations } } = await axios.get(`filters?domain=product_variation&search_global=true&filter[id]=${id}`);

    return product_variations[0];
  },
  async getProductVariationStockMovementsByProductVariationId(productVariationId, pagination) {
    const itemsPerPage = pagination ? pagination.itemsPerPage : 5;
    const page = pagination ? pagination.page : 1;

    const { data } = await axios.get(`filters?domain=product_variation_stock_movement&search_global=true&filter[product_variation_id]=${productVariationId}&per_page=${itemsPerPage}&page=${page}`);

    return {
      ...data,
      data: data.product_variation_stock_movements,
    };
  },
  async getProductVariationValuesByProductVariationId(productVariationId) {
    const { data: { product_variation_values } } = await axios.get(`filters?domain=product_variation_value&search_global=true&filter[product_variation_id]=${productVariationId}&per_page=-1&page=1`);

    return product_variation_values;
  },
});

export default ProductsService;
export { ProductsService, ProductsCommands };
