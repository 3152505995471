<template>
  <div>
    <PageHeader :schema="$schemas.orderService" />
    <PageContent>
      Order Services
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';

export default {
  name: 'CreateOrderService',
  components: { PageHeader, PageContent },
};
</script>
