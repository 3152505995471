/* eslint-disable no-unused-vars */
export default {
  beforeList: ({ form }) => {
    form.balances.forEach((balance) => {
      if (balance.is_finished || balance.is_canceled) {
        balance.destroyDisabled = true;
        balance.disableItemMoreActions = {
          finishBalance: true,
          cancelBalance: true,
        };
      } else {
        balance.destroyDisabled = false;
        balance.disableItemMoreActions = {
          finishBalance: false,
          cancelBalance: false,
        };
      }
    });
  },
};
