<template>
  <div class="danfe">
    <div class="danfe-header my-2">
      <p v-text="companyName" />
      <p v-text="`${model.company.address.street}
        ${model.company.address.number || ''} ${model.company.address.neighborhood}
        ${model.company.address.city}/${model.company.address.uf}`" />
      <p v-text="`Fone: ${model.company.cell_phone}`" />
      <p v-text="`CNPJ: ${model.company.cnpj} - IE: ${model.company.ie}`" />
    </div>
    <div class="line" />
    <div class="danfe-description my-2">
      <p><b>Cupom não Fiscal</b></p>
    </div>
    <div class="line" />
    <div class="danfe-items my-2">
      <table>
        <thead>
          <tr>
            <th width="50"><p><b>Item</b></p></th>
            <th width="50"><p><b>Cod.</b></p></th>
            <th width="320"><p><b>Descrição</b></p></th>
          </tr>
          <tr>
            <th colspan="2">
              <p>
                <span class="ml-5"><b>Qtdo</b></span>&nbsp;
                <span><b>UN</b></span>
              </p>
            </th>
            <th align="right">
              <p>
                &nbsp;&nbsp;&nbsp;
                <span><b>Vlr. Uni.</b></span>
                &nbsp;
                <span><b>Vlr. Total</b></span>
              </p>
            </th>
          </tr>
        </thead>
        <tr><td colspan="3"><div class="line my-2" /></td></tr>
        <tbody v-for="(item, index) in model.items" :key="index" class="my-2">
          <tr>
            <td><p>{{ index + 1 }}</p></td>
            <td><p>{{ item.code }}</p></td>
            <td><p>{{ utils.limitCharacters(item.name, 38) }}</p></td>
          </tr>
          <tr>
            <td colspan="2">
              <p>
                <span class="ml-5">{{ item.quantity }}</span>&nbsp;
                <span>{{ item.unit_measure }}</span>
              </p>
            </td>
            <td align="right">
              <p>
                &nbsp;&nbsp;&nbsp;
                <span>{{ utils.formatCurrency(item.unit_value, { type: 'decimal' }) }}</span>
                &nbsp;
                <span>{{ utils.formatCurrency(item.total_value, { type: 'decimal' }) }}</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="line" />
    <div class="danfe-resume-payment my-2">
      <table>
        <tr>
          <td align="left"><p>Qtd. Itens</p></td>
          <td align="right"><p>{{ quantityItems }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Sub Total R$</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total_products,) }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Deconto R$</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total_discount_value,) }}</p></td>
        </tr>
        <tr>
          <td align="left"><p>Valor Total R$</p></td>
          <td align="right"><p>{{ utils.formatCurrency(model.total_order,) }}</p></td>
        </tr>
      </table>
    </div>
    <div class="line" />
    <div class="danfe-client my-2">
      <p class="title"><b>Informações do Cliente</b></p>
      <p><b>Nome</b>: {{ clientName }}</p>
      <p><b>CPF</b>: {{ clientDocument }}</p>
    </div>
  </div>
</template>

<script>
import {  limitCharacters, formatCurrency, formatDatePT, getHourInDateTime } from '@/utils';

export default {
  name: 'Coupon',
  components: {
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    l () {
      return this.$locales.pt.productInvoices.create.form.header;
    },
    quantityItems () {
      return this.model.items.length;
    },
    emissionDate () {
      return `${formatDatePT(this.model.emission_date)} - ${getHourInDateTime(this.model.emission_date)}`;
    },
    companyName () {
      return this.model.company?.name || 'Não Informado';
    },
    clientName () {
      return this.model.client?.name || 'Não Informado';
    },
    clientDocument () {
      if (!this.model.client) {
        return 'Não Informado';
      }

      if (this.model.client?.person_type === this.$enums.personType.PHYSICAL) {
        return this.model.client?.cpf || 'Não Informado';
      }

      return this.model.client?.cnpj || 'Não Informado';
    },
  },
  data () {
    return {
      utils: {
        limitCharacters,
        formatCurrency,
      },
    };
  }
};
</script>

<style lang="scss" scoped>
.danfe {
  text-align: center;

  .line {
    height: 1px;
    border-top: 1px #000 dashed;
  }

  p {
    line-height: 18px;
    margin: 3px 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    p {
      font-size: 10px;
      margin: 0 2px;
    }
  }

  .danfe-items {
    width: 100%;
    text-align: left;
    padding: 0 1px;

    table {
      tbody {
        margin-top: 15px;
      }
    }
  }

  .danfe-resume-payment, .danfe-taxes {
    width: 100%;
    text-align: left;
    padding: 0 1px;
  }

  .danfe-client {
    width: 100%;
    text-align: left;
    padding: 0 1px;

    .title {
      font-size: 12px;
      margin-bottom: 5px;
      text-align: center;
    }
  }
}
</style>
