<template>
  <div>
    <PageHeader :schema="$schemas.costCenter" />
    <PageContent>
      <DynamicFormPage :schema="$schemas.costCenter" :service="$api.costCenters" />
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicFormPage from '@/components/dynamics/DynamicFormPage';

export default {
  name: 'CreateCostCenter',
  components: { PageHeader, PageContent, DynamicFormPage },
};
</script>
