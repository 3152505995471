import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';
import TypeInstallmentBusiness from '@/business/TypeInstallmentBusiness';

export default {
  domain: 'type_installments',
  domainSingle: 'type_installment',
  business: TypeInstallmentBusiness,
  title: 'Parcelamentos',
  description: 'Lista de todos os Parcelamentos do sistema',
  createBtn: 'Novo Parcelamento',
  showInactive: false,
  routes: {
    list: {
      name: 'type-installments',
      path: '/parcelamentos',
      meta: { typePage: typePageOptions.list, requiresAuth: true },
    },
    create: {
      name: 'type-installment-create',
      path: '/parcelamentos/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true },
    },
    show: {
      name: 'type-installment-show',
      path: '/parcelamentos/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true },
    }
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: [
      ]
    },
  },
  filters: {
    has: false,
    items: []
  },
  fields: [
    {
      title: 'Dados do Parcelamento',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'day_between_parcels',
          formattedName: 'day_between_parcels',
          label: 'Dias Fixo entre Parcelas (0;30;60)',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'money',
          name: 'input_percentage',
          formattedName: 'input_percentage_formatted',
          label: 'Percentual da Entrada',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          clearable: false,
          prefix: '',
          suffix: '%',
          length: 11,
          precision: 2,
          empty: null,
          itemValueDefault: true,
          default: 100,
        },
        {
          type: 'text',
          name: 'observation',
          formattedName: 'observation',
          label: 'Descrição',
          align: '',
          md: '5',
          rules: [],
          list: false,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
