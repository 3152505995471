import { getCurrentPeriod } from '@/utils';

class BankMovement {
  get model() {
    return {
      movement_date: getCurrentPeriod().currentDateEN,
      movement_type: '',
      type_transaction: '',
      payment_method_id: '',
      value: 0,
      description: ''
    };
  }
  get validations () {
    return {};
  }
  get validationsErrors () {
    return {};
  }
}

export default new BankMovement();
