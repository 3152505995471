/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { clone } from 'lodash';
import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import AccountSchema from '../schemas/AccountSchema';
import { statusAccounts } from '@/utils/options';
import { getText, formatDatePT, formatCurrency } from '@/utils';
import { typePage, statusAccounts as statusAccountsEnum } from '@/utils/enums';

const transformArrayToListSelect = (arr, params = ['name', 'id']) => {
  return arr.map((item) => {
    return {
      text: item[params[0]],
      value: item[params[1]],
      ...item.value && { item_value: item.value },
      ...params.includes('all') && { item },
    };
  });
};

const formatResponse = (item, type = typePage.LIST) => {
  const formatList = (item) => {
    const status = item.qtd_parcels_paid > 0 && item.status != statusAccountsEnum.PAID ? statusAccountsEnum.PAIR : item.status;
    item.status = status;
    item.status_formatted = getText(statusAccounts, status);
    item.emission_date_formatted = formatDatePT(item.emission_date);
    item.value_formatted = formatCurrency(item.value);
    item.difference_formatted = formatCurrency(item.difference);
    item.total_value_formatted = formatCurrency(item.total_value);
    item.paid_value_parcels_formatted = formatCurrency(item.paid_value_parcels);
    item.qtd_parcels_paid_formatted = `${item.qtd_parcels_paid}/${item.parcels.length}`;
    item.paid_value_parcels_pending_formatted = formatCurrency(item.total_value - item.paid_value_parcels);
  };

  const formatShow = (account) => {
    const mountSelect = (key, data, textLabel, valueLabel) => {
      if (!data) return null;

      return {
        [key]: {
          text: data[textLabel],
          value: data[valueLabel],
        }
      };
    };

    const paidParcels = account.parcels.filter((parcel) => {
      return parcel.status === statusAccountsEnum.PAID;
    });

    item.account = {
      ...account,
      status: paidParcels.length > 0 && account.status != statusAccountsEnum.PAID ? statusAccountsEnum.PAIR : account.status,
      recalculate_parcels: false,
      ...mountSelect('register', account.register, 'name', 'id'),
      ...mountSelect('type_installment', account.type_installment, 'name', 'id'),
      ...mountSelect('accounting_account', account.accounting_account, 'name', 'id'),
      ...mountSelect('cost_center', account.cost_center, 'description', 'id'),
      parcels: account.parcels.map((parcel) => {
        return {
          ...parcel,
          due_date_formatted: formatDatePT(parcel.due_date) || '-',
          paid_date_formatted: formatDatePT(parcel.paid_date) || '-',
          value_formatted: formatCurrency(parcel.value),
          difference_formatted: formatCurrency(parcel.difference),
          total_value_formatted: formatCurrency(parcel.total_value),
          total_paid_value_formatted: formatCurrency(parcel.total_value - parcel.total_paid_value),
          payments: parcel.payments.map((payment) => {
            return {
              ...payment,
              paid_date_formatted: formatDatePT(payment.paid_date) || '-',
              due_date_formatted: formatDatePT(payment.due_date) || '-',
              compensation_date_formatted: formatDatePT(payment.compensation_date) || '-',
              value_formatted: formatCurrency(payment.value),
              status_formatted: getText(statusAccounts, payment.status),
            };
          }),
        };
      })
    };
  };

  type === typePage.LIST ? formatList(item) : formatShow(item.account);
};

const formatRequest = (data, type = typePage.CREATE) => {
  const payload = clone(data);
  type;
  payload.register_id = payload.register?.value;
  payload.type_installment_id = payload.type_installment?.value;
  payload.accounting_account_id = payload.accounting_account?.value;
  payload.cost_center_id = payload.cost_center?.value;

  delete payload.id;
  delete payload.company_id;
  delete payload.register;
  delete payload.type_installment;
  delete payload.accounting_account;
  delete payload.cost_center;
  delete payload.created_at;
  delete payload.updated_at;
  delete payload.deleted_at;
  delete payload.created_by;
  delete payload.updated_by;
  delete payload.deleted_by;
  delete payload.paid_parcels;
  delete payload.paid_value_parcels;
  delete payload.situation;
  delete payload.initial_parcel;

  payload.parcels.forEach((parcel) => {
    delete parcel.company_id;
    delete parcel.delayed_days;
    delete parcel.situation;
    delete parcel.created_at;
    delete parcel.updated_at;
    delete parcel.deleted_at;
    delete parcel.created_by;
    delete parcel.updated_by;
    delete parcel.deleted_by;
  });

  return payload;
};

const formatResponseGetSelectItems = (res) => {
  res.registers = transformArrayToListSelect(res.registers);
  res.type_installments = transformArrayToListSelect(res.type_installments);
  res.accounting_accounts = transformArrayToListSelect(res.accounting_accounts);
  res.cost_centers = transformArrayToListSelect(res.cost_centers, ['description', 'id']);

  return res;
};

const formatResponseGenerateInstallments = (res) => {
  res = res.map((parcel) => {
    return {
      ...parcel,
      due_date_formatted: parcel.due_date_formatted = formatDatePT(parcel.due_date),
      value_formatted: parcel.value_formatted = formatCurrency(parcel.value),
      difference_formatted: parcel.difference_formatted = formatCurrency(parcel.difference),
      total_value_formatted: parcel.total_value_formatted = formatCurrency(parcel.total_value),
      total_paid_value: parcel.total_value,
      total_paid_value_formatted: formatCurrency(parcel.total_value),
    };
  });

  return res;
};

const formatRequestUpdateStatusParcel = (data) => {
  const payload = clone(data);
  delete payload.company_id;
  delete payload.title;
  delete payload.situation;
  delete payload.created_by;
  delete payload.updated_by;
  delete payload.deleted_by;
  delete payload.deleted_at;
  delete payload.created_at;
  delete payload.updated_at;
  delete payload.delayed_days;

  return payload;
};

const AccountsService = DynamicService('accounts', AccountSchema, { formatResponse, formatRequest, removeNullable: false });
const AccountsCommands = () => ({
  async getSelectItems() {
    const { data } = await axios.post(`${AccountSchema.domain}/get_list_selects`);

    return formatResponseGetSelectItems(data);
  },
  async generateInstallments(payload) {
    const { data } = await axios.post(`${AccountSchema.domain}/generate_installments`, payload);

    return formatResponseGenerateInstallments(data);
  },
  async generateInstallmentsV2(payload) {
    const { data } = await axios.post(`${AccountSchema.domain}/generate_installments_v2`, payload);

    return formatResponseGenerateInstallments(data);
  },
  async updateStatusParcel(payload) {
    const { data } = await axios.post(`${AccountSchema.domain}/update_status_parcel`, formatRequestUpdateStatusParcel(payload));

    return formatResponseGenerateInstallments(data);
  },
  async updateParcel(accountId, accountParcelId, payload) {
    await axios.put(`${AccountSchema.domain}/${accountId}/parcels/${accountParcelId}`, payload);
  },
  async createParcelPayment(accountId, accountParcelId, payload) {
    await axios.post(`${AccountSchema.domain}/${accountId}/parcels/${accountParcelId}/payments`, payload);
  }
});

export default AccountsService;
export { AccountsService, AccountsCommands };
