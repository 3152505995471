var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalCardDefault',{attrs:{"title":'Vendas PDV'},on:{"close":function($event){return _vm.$emit('update:dialogModal', false)}}},[_c('ExpansionPanel',{attrs:{"title":'Lista de Todas as Vendas PDV',"icon":_vm.$icons.box,"readonly":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('DataTable',{ref:"salesDataTable",attrs:{"no-sync":"","items":_vm.sales,"headers":_vm.headers,"slots":_vm.slots,"loading":_vm.loading,"items-per-page":7},scopedSlots:_vm._u([{key:"emission_date_formatted",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(item.emission_date_formatted)+" ")]}},{key:"name",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(_vm.getClient(item))+" ")]}},{key:"total_order_formatted",fn:function(ref){
var item = ref.props.item;
return [_vm._v(" "+_vm._s(item.total_order_formatted)+" ")]}},{key:"actions",fn:function(ref){
var item = ref.props.item;
return [_c('v-menu',{attrs:{"nudge-width":230,"nudge-left":35,"nudge-top":10,"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.dotsVertical))])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.disabledEmitterNFCeDanfe(item)},on:{"click":function($event){return _vm.emitterNFCe(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"icon-side-bar",attrs:{"color":"secondary","small":"","disabled":_vm.disabledEmitterNFCeDanfe(item)}},[_vm._v(_vm._s(_vm.$icons.printer))])],1),_c('v-list-item-title',{staticClass:"text-side-bar"},[_vm._v("Gerar NFC-e")])],1)],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","disabled":_vm.disabledPrintDanfe(item)},on:{"click":function($event){return _vm.printForSale(item.product_invoice, 'printMe65Danfe')}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"icon-side-bar",attrs:{"color":"secondary","small":"","disabled":_vm.disabledPrintDanfe(item)}},[_vm._v(_vm._s(_vm.$icons.printer))])],1),_c('v-list-item-title',{staticClass:"text-side-bar"},[_vm._v("Imprimir NFC-e")])],1)],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.printForSale(item, 'printMeCoupon')}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"icon-side-bar",attrs:{"color":"secondary","small":""}},[_vm._v(_vm._s(_vm.$icons.printer))])],1),_c('v-list-item-title',{staticClass:"text-side-bar"},[_vm._v("Imprimir Cupom")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }