import { baseFields } from '../utils/baseFields';

export default (server) => {
  server.create('variation', {
    description: 'Tamanho',
    ...baseFields,
  });
  server.create('variation', {
    description: 'Cor',
    ...baseFields,
  });
};
