<template>
  <v-form v-model="valid" ref='formAddress' lazy-validation>
    <Row>
      <Col cols="12" sm="12" md="2" lg="3">
        <TextFieldSimpleMask v-model="address.zip_code" label="CEP" v-bind="propsZipCode" :loading="loading"
          :rules="[rules.required]" :length="10" :counter="10" />
      </Col>
      <Col cols="12" sm="12" md="2" lg="2">
        <Select v-model="address.uf" label="UF" :items="options.UF" itemText="text" itemValue="value" :loading="loading"
          :rules="[rules.required]" return-object />
      </Col>
      <Col cols="12" sm="12" md="4" lg="4">
        <TextField v-model="address.city" label="Cidade" :loading="loading"
          :rules="[rules.required]" :maxlength="60" :counter="60" />
      </Col>
      <Col cols="12" sm="12" md="4" lg="3">
        <TextField v-model="address.neighborhood" label="Bairro" :loading="loading"
          :rules="[rules.required]" :maxlength="60" :counter="60" />
      </Col>
    </Row>

    <Row>
      <Col cols="12" sm="12" md="4" lg="6">
        <TextField v-model="address.street" label="Endereço" :loading="loading"
          :rules="[rules.required]" :maxlength="60" :counter="60"  />
      </Col>
      <Col cols="12" sm="12" md="2" lg="2">
        <TextField v-model="address.number" label="Número" :maxlength="60" :counter="60" />
      </Col>
      <Col cols="12" sm="12" md="3" lg="4">
        <TextField v-model="address.complement" label="Complemento" :maxlength="60" :counter="60" />
      </Col>
      <Col cols="12" sm="12" md="3" lg="4">
        <TextField v-model="address.reference" label="Ponto Rerferência" :maxlength="60" :counter="60" />
      </Col>
      <Col v-if="cepNotExistent" cols="12" sm="12" md="3" lg="4">
        <TextField v-model="address.code_city" label="Código IBGE" :rules="[rules.required]" />
        <small><a href="https://www.ibge.gov.br/explica/codigos-dos-municipios.php" target="_blank">Clique aqui</a> para pesquisar código do IBGE</small>
      </Col>
    </Row>
  </v-form>
</template>

<script>
import { mask } from 'vue-the-mask';
import { required } from '@/utils/rules';
import { zipCode } from '@/utils/masks';
import { UF } from '@/utils/options';
import { debounce } from '@/plugins/debounce.js';
import { Row, Col, Select, TextField, TextFieldSimpleMask } from '@/components/vuetify';
import TypePageMixin from '@/mixins/TypePageMixin';
import axios from 'axios';

export default {
  name: 'AddressFormPages',
  components: { Row, Col, TextField, Select, TextFieldSimpleMask },
  props: {
    address: {
      type: Object,
      required: true,
    },
    addressCurrent: {
      type: Object,
      required: true,
    }
  },
  directives: { mask },
  data() {
    return {
      masks: {
        zipCode: zipCode,
      },
      rules: {
        required: required
      },
      options: {
        UF: UF
      },
      panel: [0],
      valid: true,
      loading: false,
      cepNotExistent: false,
    };
  },
  computed: {
    propsZipCode() {
      return {
        required: true,
        list: true,
        readonly: false,
        disabled: false,
        inputMask: '##.###-###',
        outputMask: '########',
        applyAfter: false,
        empty: null,
      };
    }
  },
  watch: {
    ['address.zip_code']: debounce(function () {
      if (!this.address.zip_code) {
        this.$emit('setAddressByZipCode', {
          code_country: 1058,
          country: '',
          zip_code: '',
          street: '',
          code_state: '',
          uf: null,
          code_city: '',
          city: '',
          neighborhood: '',
          number: '',
          complement: '',
          reference: '',
        });
      } else if (this.addressCurrent.zip_code != this.address.zip_code && this.address.zip_code.length === 8) {
        this.getAddress();
      } else if (this.addressCurrent.zip_code == this.address.zip_code && this.address.zip_code.length === 8) {
        this.$emit('setAddressByZipCode', this.addressCurrent);
      }
    }, 1000),
  },
  mixins: [TypePageMixin],
  methods: {
    getAddress() {
      this.loading = true;
      axios.get(`zip-code-search?zip_code=${this.address.zip_code}`).then((res) => {
        this.loading = false;
        res.data.code_city ? this.cepNotExistent = false : this.cepNotExistent = true;
        this.$emit('setAddressByZipCode', res.data);
      }).catch(() => {
        this.$noty.error('Erro ao localizar o Endereço');
        this.loading = false;
        this.$emit('setAddressByZipCode', null);
      });
    }
  }
};
</script>

<style>

</style>
